import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, CURRENCY, ImagesUrl, STAFF_CODE, ServerUrl, config, selectStyles, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts, CustomLoader } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'

import { useQuery } from 'react-query'
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { FormatNumber, getMonthYear } from '../../components/GlobalFunction';
import { FetchProfile } from '../../api/ReactQuery';
import { Link } from 'react-router-dom';

const  PaySlip =()=> {
   
    const [payment, setPayment] = useState({
        staff:[] as any,
        pay_month:'',
    })


    const profile = useQuery(["profile"], FetchProfile)
    const [salaryStructure, setSalaryStructure]=useState([] as any);

    const [title, setTitle] = useState([] as any);
    const [staff, setStaff] = useState([] as any);
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        pay_month:''
       
     })
    

     const fetchStaff =async()=>{



        let sql = "SELECT e.code, e.title,  e.first_name, e.gender, e.last_name, e.middle_name, e.bank_name, e.account_number, j.title as job_title, d.department_name from tbl_employee e left join tbl_job_title j on j.code = e.job_title inner join  tbl_departments d on   d.code = e.department where e.termination_reason = ''"

        const fd = new FormData();
        fd.append('sql', sql); 
        let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
      await  axios.post(url, fd, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){
          setStaff(result.data.data)

window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
          }else{
            setStaff([])
            Alerts('default', 'No record found', 'Info')
          }
        })
        .catch((error)=>{
            Alerts('danger',error.message)
        })
        }
      
   
     const handleSelect = (option:any, action:any)=>{
        setPayment({...payment, [action.name]:option});
          setErrors({...errors, [action.name]:''})

           }

           

           const handleChange = (event:ChangeEvent<HTMLInputElement>)=>{
           
            let {name, value} = event.target;	
            setPayment({...payment, [name]:value});
           setErrors({...errors, [name]:''});
        }



const handleReset =()=>{
    setPayment({
        staff:[] as any,
        pay_month:'',
    })
setContent([])
    setSalaryStructure([])
}





const getPayment =(code:string)=>{
    let scr =  [...content];
    const rs = scr.filter((item:any)=>item.staff_code===code)

    return rs;

}


const fetchContent = async()=>{
    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!payment.pay_month){
    error.pay_month =msg;
    formIsValid = false;
}  

if(!formIsValid){
    setErrors(error) 
    
    }


if(formIsValid) {
  LoadingModal()

    let sql = "SELECT t.title, p.staff_code, p.payment_mode, p.payment_date, p.remarks, p.item_code,  p.item_type, p.amount, amount_topay, p.balance, p.is_approved from tbl_salary_payment p, tbl_payroll_title t where t.item_code = p.item_code and p.company_code = '"+COMPANY_CODE+"' and p.payment_month = '"+payment.pay_month+"'"

    if(payment.staff.length!==0){
        sql = sql + " and p.staff_code = '"+payment.staff.value+"'"

        setStaff(staff.filter((item:any)=>item.code===payment.staff.value))
       
    }

                 const fd = new FormData();
                 fd.append('sql', sql); 
                 let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
               await  axios.post(url, fd, config).then((result:any)=>{
              
      if(Array.isArray(result.data.data) && result.data.data.length!==0){
        setContent(result.data.data)

window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
        }else{
            setContent([])
        }
      })
         .catch((error)=>{
             Alerts('danger',error.message)
         }).finally(()=>{
            closeModal()
         })
         } 
        }


        const getTotalDeduction =(code:string)=>{
            var amount =0
            let scr =  [...content];
            const rs = scr.filter((item:any)=>item.staff_code===code)
            rs.map((sl:any)=>{
                if(sl.item_type==='DEDUCTION'){
                    amount += Number(sl.amount_topay)
                }
            } )
            return amount
        }


           const getTotalAmount =(code:string)=>{
            var amount =0
            let scr =  [...content];
            const rs = scr.filter((item:any)=>item.staff_code===code)
        
            rs.map((sl:any)=>{
                if(sl.item_type==='EARNING'){
                    amount += Number(sl.amount_topay)
                }
            } )
            return amount
        }


    useEffect(()=>{
        fetchStaff() 
      }, [])


    

  return ( <>
  <Breadcrumb 
   title="Pay Slip"
   summary="Get Employee Payslip "
   >
    <li className="breadcrumb-item">
    <Link to="/payroll/staff_salary">Payroll</Link>
                    </li>
   <li className="breadcrumb-item">
    <Link to="#!">Pay Slip</Link>
                    </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Employee Payslip</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                              
                                    
                                    
         <div className="content-wrappers ">
                            
         <div className=''>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Search Payslip</h5>
                <span>Add all offcie department</span>
              
            </div>
            <div className="card-block">
    
        
                        <div className="row">
                  
                       

                     <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Payment Month <span className='text-danger'>*</span></label>
           
            <input type="month" className={errors.pay_month?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='pay_month' value={payment.pay_month} onChange={handleChange}  />
          

            {errors.pay_month&&
    <div className="col-form-label has-danger">{errors.pay_month&&errors.pay_month} </div>}
             </div>

             </div>



             <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Select Employee </label>


                 
                <Select 
                options={
                               staff&&staff.map((list:any, idx:number)=> {
                                return {key:idx, value: list.code, label: list.first_name, last_name:list.last_name, middle_name:list.middle_name, title:list.title }
                                })}
                     getOptionLabel={option =>`${option.title} ${option.label} ${option.last_name}`}
    onChange={handleSelect}  name="staff" value={payment.staff} 
    styles={styles} 
    /> 
                    
                    </div>
    
                     </div>


                    </div>



<hr />
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-outline-danger btn-round pr-5 pl-5 mr-3" type='button' onClick={handleReset}>Reset</button>
<button className="btn btn-inverse btn-round pr-5 pl-5" type='button' onClick={fetchContent}>Get Payslip</button>
</div>
</div>
    
            </div>
        </div>
    </div>
    </div>
    
    
    

    
                </div>

        </div>
                       
                    </section>
                </div>
            </div>
        </div>
    </div>
                                    </div>
                                </div>
                            </div>



{content.length!==0?

<div className="row justify-content-center">
{staff&&staff.map((list:any, index:number)=><div key={index} className="col-md-8">
    <div className="card">
       
        <div className="card-block">
        <div className="table-responsive ">
        <table className="table table-bordered table-sm mb-0 ">
<thead>
 <tr className='text-center'>
                        <td colSpan={2} >   
                        <div className="row  justify-content-center">
                            
                            <div >
                                      
                            <img 
           src={ImagesUrl+'/about/'+profile.data.logo}  alt="Click to change" 
           className="m-b-10 img-100" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/logo.png'}} />


                        <h4>{profile.data.company_name} </h4>
                        <h5>{profile.data.company_address}</h5>
                        <h6 className='m-b-5'>{profile.data.email}, {profile.data.phone}</h6>
</div>
</div>  
<div className="alert alert-info text-center mb-1 mt-2">

<h5> PAYSLIP FOR: {getMonthYear(payment.pay_month).toUpperCase()} </h5>
</div> 
</td>

</tr> 
<tr>
    <td colSpan={2} className='text-center'><h5>{list.first_name+' '+list.middle_name+' '+list.last_name}</h5></td>
</tr>
<tr>
    <td>Department: <b>{list.department_name}</b></td>
    <td >Bank Name: <b>{list.bank_name}</b></td>
</tr>
<tr>
    <td>Position: <b>{list.job_title}</b></td>
    <td >Account No: <b>{list.account_number}</b></td>
</tr>
</thead>
</table>

<table className="table table-bordered table-sm ">
<thead>

<tr className='bg-inverse' >
<th >Description</th>
<th >Earnings ({CURRENCY})</th>
<th >Deductions ({CURRENCY})</th>
</tr>
</thead>
<tbody>

    {getPayment(list.code).map((data:any, id:number)=>data.amount_topay>0?
<tr key={id}>
<th className="text-center">{data.title}</th>
<td>{data.item_type==='EARNING'?FormatNumber(data.amount_topay):''}</td>
<td>{data.item_type==='DEDUCTION'?FormatNumber(data.amount_topay):''}</td>
</tr>:[])}
</tbody>
<tfoot>

<tr className='bg-inverse' >
<th >Total</th>
<th >{FormatNumber(getTotalAmount(list.code))}</th>

<th >{FormatNumber(getTotalDeduction(list.code))}</th>
</tr>
<tr className='bg-success' >
<th colSpan={2} className='text-right'>YOUR NETPAY FOR THIS MONTH IS: </th>
<th  > <h5>{FormatNumber(getTotalAmount(list.code) - getTotalDeduction(list.code))}</h5> </th>

</tr>
</tfoot>
</table>

<div className="rows">
&nbsp;
<button type="button"  className="btn  btn-primary btn-icon " ><i className='fa fa-print'></i></button>&nbsp;

<button type="button"  className=" btn btn-info btn-icon" ><i className='fa fa-file-pdf'></i></button>


</div>
</div>



        </div>
    </div>
</div>)}



</div>:[]}
    </div>

    
    </div>

                      
                         
                                </div>
                            </div>  
        </>     
  )
}

export default PaySlip