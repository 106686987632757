import React, { useCallback, useEffect, useState, ChangeEvent} from 'react'
import { COMPANY_CODE, ServerUrl, config, configDownload, styles } from '../../components/Includes';
import axios from 'axios';
import { Alerts, CustomLoader } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'
import { useQuery } from 'react-query'
import { usePaginated, DOTS } from '../../components/usePagination';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { getBritishDate } from '../../components/GlobalFunction';
import { fetchDepartment, fetchJobs, takeScreenshot } from '../../api/ReactQuery';
import { Link } from 'react-router-dom';
import { DownloadDocument, ViewDocument } from './LeaveListPDF';

import { PDFDownloadLink} from '@react-pdf/renderer';
const  LeaveList =()=> {
   
    const [director, setDirector] = useState({
       
        isName:false,
        isType:false,
        isStartDate:false,
        isEndDate:false,
        isDays:false,
        isDateApplied:true,
        isReason:true,
        
        isApproved:false,
        isAction:false,
        
    });
    const Jobs = useQuery(["jobs"], fetchJobs)
    const department = useQuery(["department"], fetchDepartment)
    const [template, setTemplate] = useState([] as any);
    const [search, setSearch] = useState({
        employee:[] as any,
        leave_type:[] as any,
        job_title:[] as any,
        department:[] as any,
        start_date:'',
        approve_status:{value:'All', label:'All'},
        rowperpage:{value:'10', label:'10'},
        filterText:'',
        selectAll:false,
        totalSelected:0,
     })


    const [filterContent, setFilterContent] = useState([] as any);
    const [staff, setStaff] = useState([] as any);
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
         staff:'',
         pay_mode:'',
         pay_date:'',
         pay_month:''
       
     })
    
     const handleResetColumn =()=>{
        setDirector({
           
            isName:false,
            isType:false,
            isStartDate:false,
            isEndDate:false,
            isDays:false,
            isDateApplied:true,
            isReason:true,
            
            isApproved:false,
            
            isAction:false,
        })
    }
     const fetchStaff =async()=>{
        let url = ServerUrl+'/api/employees/view/'+COMPANY_CODE
        const {data} = await axios.get(url, config)
        setStaff(data.data)
        }
      
   
     const handleSelect = (option:any, action:any)=>{
        setSearch({...search, [action.name]:option});
          setErrors({...errors, [action.name]:''})

           }


           const handleChange = (event:ChangeEvent<HTMLInputElement>)=>{
           
            let {name, value} = event.target;	
            setSearch({...search, [name]:value});
           setErrors({...errors, [name]:''});
        }

        
        const fetchLeave = async()=>{
            let url = ServerUrl+'/api/leave/view_leave_type/'+COMPANY_CODE
          await  axios.get(url, config).then((result:any)=>{
            if(Array.isArray(result.data.data) && result.data.data.length!==0){
              setTemplate(result.data.data)
              }
            })
            .catch((error)=>{
                Alerts('danger', error.message)
            })
            } 


const handleReset =()=>{
    setSearch({
        employee:[] as any,
        leave_type:[] as any,
        job_title:[] as any,
        department:[] as any,
        start_date:'',
        approve_status:{value:'All', label:'All'},
        rowperpage:{value:'10', label:'10'},
        filterText:'',
        selectAll:false,
        totalSelected:0,
    })
}





  const handleSelectAll =(list:[])=>{
      
    let is_check = list.every((element:any) => {
     return element.is_check === 'true'
    });
    var i = 0;
        list.forEach((element:any) => {
          if(element.is_check === 'true')
         i++
        });
    setSearch({...search, selectAll:is_check, totalSelected:i})
    
       }


const handleCheck =(code:string)=>{
const newContent = filterContent.map((item:any)=>{
   
if(item.code === code){
return {...item, 
    is_check:item.is_check==='false'?'true':'false',
}
};
return item;
})
setFilterContent(newContent)
handleSelectAll(newContent)
}


const handleSelectChange =()=>{
    let status = search.selectAll ===false?'true':'false';
    const newContent = filterContent.map((item:any)=>{
        return {...item, 
            is_check:status,
        }
  })

  var i = 0;
  newContent.forEach((element:any) => {
      if(element.is_check=== 'true')
     i++
    });

    setFilterContent(newContent)

    setSearch({...search, selectAll:!search.selectAll, totalSelected:i})

   }



  const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	
    setSearch({...search, [name]:value});

    if(value!==''){
        const filteredItems = content.filter(
            (item:any) => item.staff_name.toLowerCase().includes(value.toLowerCase()) || item.leave_name.toLowerCase().includes(value.toLowerCase()) ||
            item.reason.toLowerCase().includes(value.toLowerCase()),
        );

        setFilterContent(filteredItems)
    }else{
        setFilterContent(content)
    } 

 }


const handleClear =()=>{
setSearch({...search, filterText:''});
setFilterContent(content)
}




        const TableData =()=>{

            

            const PaginationWrapper =(props:any)=>{
            
                const handleRowsPerPage = useCallback(
                    (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
                    [props.currentPage, props.onChangeRowsPerPage],
                );
                const handleSelectRowsPerPage = useCallback((option:any, action:any)=> props.onChangeRowsPerPage(Number(option.value), props.currentPage),
                [props.currentPage, props.onChangeRowsPerPage],
                )
               
                function getNumberOfPages(rowCount:number, rowsPerPage:number) {
                    return Math.ceil(rowCount / rowsPerPage);
                  }
                const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
                const lastIndex = props.currentPage * props.rowsPerPage;
                const firstIndex = lastIndex - props.rowsPerPage + 1;
            
            
                    const range =
                    props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
            
                    `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
            
                
               
                const Pagination = () => {
                   
                    const pageSize = props.rowsPerPage;
                    const totalCount = content.length
                    const siblingCount = 1
                    const currentPage = props.currentPage
                    
                    const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
                    
                    if (currentPage === 0 ) {
                      return null;
                    }
                    
                    let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
                    return (
                    <nav className="" aria-label="Page navigation sample ">
                      <ul  className="pagination pagination-sm"  >
                        <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
                         <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Previous</a>
                        </li>
                        {paginationRange&&paginationRange.map((pageNumber, index) => {
                          if (pageNumber === DOTS) {
                            return <li className="pagination-item dots" key={index}>
                             <a className="page-link pointer"  >&#8230;</a>
                            </li>
                          }
                          return (
                            <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                              <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                              
                            </li>
                          );
                        })}
                    
                    <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
                    <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
                        </li>
                    
                      </ul> </nav>
                    );
                    
                        }
            
                      
            
                      
            
             return <div className="row mt-3">
                                
            <div className="col-md-5 ">
            <div className="row ml-3" >
            
               Rows per page: &nbsp; <select name="rowPerPage" id="" className='w-40 h-30 ' onChange={handleRowsPerPage}>
                    <option value="10"> 10</option>
                    <option value="20"> 20</option>
                    <option value="30"> 30</option>
                    <option value="40"> 40</option>
                    <option value="50"> 50</option>
                    <option value={content.length}> All</option>
                </select> &nbsp;&nbsp;
                {/*  {range} */} </div>
            </div>
              
            <div className="col-md-7  justify-content-end d-flex">
               <Pagination  />
                </div>
                
            </div>}
            
            
            
const CheckboxAll =()=>{ 

    return <div className="chk-option">
    <div className="checkbox-fade fade-in-primary">
        <label className="check-task">
    
        <input type="checkbox"  id="selectAll" checked={search.selectAll} onChange={handleSelectChange} />
                    
        <span className="cr">
            <i className="cr-icon fa fa-check txt-default"></i>
        </span>
        </label>
    </div>
    </div>}


const Checkbox =({code, is_check}:{code:string, is_check:string})=>{ 

return <div className="chk-option">
<div className="checkbox-fade fade-in-primary">
    <label className="check-task">

    <input type="checkbox"  name={code} id={code} checked={is_check==='true'?true:false} onChange={()=>handleCheck(code)} />
                
    <span className="cr">
        <i className="cr-icon fa fa-check txt-default"></i>
    </span>
    </label>
</div>
</div>}
            
            
            
                        const columns = [
            
                          
                            {
                                name: <CheckboxAll />,
                                cell: (row:any) => <Checkbox code={row.code} is_check={row.is_check} />, 
                                width:'60px'
                            },
                            {
                                name: 'Staff',
                                selector: (row:any) =>row.staff_name,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isName,
                            },
                            {
                                name: 'Leave Type',
                                selector: (row:any) =>row.leave_name,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isType,
                            },
                            {
                                name: 'Leave Date',
                                selector: (row:any) => getBritishDate(row.start_date)+' to '+getBritishDate(row.end_date),
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                width:'200px',
                                omit: director.isStartDate,
                                
                            },
                            {
                                name: 'Days',
                                selector: (row:any) => row.no_days,
                                sortable: true,
                                reorder:true,
                                omit: director.isDays,
                                wrap:true,
                            },
                            {
                                name: 'Reason',
                                selector: (row:any) => row.reason,
                                sortable: true,
                                reorder:true,
                                omit: director.isReason,
                                wrap:true,
                            },
                            {
                                name: 'Date Applied',
                                selector: (row:any) => getBritishDate(row.created_at),
                                sortable: true,
                                reorder:true,
                                omit: director.isDateApplied,
                                wrap:true,
                            },
                            {
                              name: 'Approval',
                              cell: (row:any) => row.is_approved,
                            width:'100px',
                            omit: director.isApproved,
                          },
                            {
                              name: 'Approved',
                              cell: (row:any) => <button className="btn btn-success btn-round btn-sm" type='button' >Approved</button>,
                            width:'100px',
                            omit: director.isApproved,
                          },
                            {
                                name: 'Reject',
                                cell: (row:any) => <button className="btn btn-warning btn-round btn-sm text-dark" type='button' >Reject </button>,
                              width:'100px',
                              omit: director.isApproved,
                            },
                            {
                                name: 'Action',
                                cell: (row:any, index:any, column:any, id:any) => 
                                
                                <div className="dropdown-info dropdown">
                    <button className="btn btn-info dropdown-toggle  btn-sm" type="button" id="dropdown-4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">Action</button>
                    <div className="dropdown-menu z-index-100" aria-labelledby="dropdown-4" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut" x-placement="bottom-start" >
                        <a className="dropdown-item " href="#">Add Comment</a>
                        <a className="dropdown-item " href="#">View Details</a>
                        <a className="dropdown-item " href="#">Cancel Leave</a>
                        <a className="dropdown-item " href={"/employee/profile/"+row.staff_code}>View Profile</a>
                    <div className="dropdown-divider"></div>
                      <a className="dropdown-item " href="#!" >Delete Leave</a>
                    </div>
                </div>,
                                    left:true,
                                    omit: director.isAction,
                                    width:'150px'
                            },
                        ];
                        
                    
                    
                    
                        return <DataTable
                        columns={columns}
                        data={filterContent}
                        highlightOnHover
                        pagination
                        paginationComponent={PaginationWrapper}
                        selectableRowsHighlight
                        striped
                        responsive
                        fixedHeader
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                    />
                    }
            


                    const sqlQuery = ()=>{


                      let sql = "SELECT 'false' as is_check, a.id, a.code, CONCAT(e.first_name, ' ', e.last_name)  as staff_name, a.created_at, a.staff_code, a.no_days, a.leave_code, a.reason, a.is_approved, a.start_date, a.end_date, a.current_year, a.approved_by, l.time_cycle, l.leave_name FROM tbl_employee e, tbl_leave_application a, tbl_leave_type l WHERE l.code = a.leave_code and e.code = a.staff_code and a.company_code = '"+COMPANY_CODE+"'"
                 
                 
     
                      if(search.employee.length!==0){
                         sql = sql + " and a.staff_code = '"+search.employee.value+"'"
                     }
     
     
                     if(search.leave_type.length!==0){
                         sql = sql + " and a.leave_code= '"+search.leave_type.value+"'"
                     }
     
                     if(search.approve_status.value!=='All'){
                         sql = sql + " and a.is_approved = '"+search.approve_status.value+"'"
                     }
     
                     if(search.department.length!==0){
                       sql = sql + " and e.department = '"+search.department.value+"'"
                   }
     
                   if(search.job_title.length!==0){
                       sql = sql + " and e.job_title = '"+search.job_title.value+"'"
                   }
     
                     if(search.start_date!==''){
                         sql = sql + " and a.start_date = '"+search.start_date+"'"
                     }

                     return sql
                    }

         const fetchContent = async()=>{
            setLoading(true)
     
        
            let sql = sqlQuery() 
     
                 const fd = new FormData();
                 fd.append('sql', sql); 

                 let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE

                 
               await  axios.post(url, fd, config).then((result:any)=>{

                 if(Array.isArray(result.data.data) && result.data.data.length!==0){
                    setFilterContent(result.data.data)
                   setContent(result.data.data)
                   }else{

                    setContent([])
                    setFilterContent([])
                   }
                 })
                 .catch((error)=>{
                     Alerts('danger',error.message)
                 }).finally(()=>{
                     setLoading(false)
                 })
                 } 





                 const handleDeleteAll =()=>{

                    if(search.totalSelected<1){
                      Alerts('danger','Please select at least one record to delete ')
                    }else{
                  
                      let employeeList =[] as any;
                      let selectedEmployee = filterContent.filter((item:any)=>item.is_check ==='true')
                  
                      if(Array.isArray(selectedEmployee)){
                        selectedEmployee.forEach((list:any) => {
                            employeeList.push(list.code)
                        });
                      }
                  
                      const fd = new FormData();
                      fd.append('employeeList', JSON.stringify(employeeList, null, 2));
                      let url = ServerUrl+'/api/leave/delete_all/'+COMPANY_CODE;
                      Swal.fire({
                        title: 'Are you really sure?',
                        text: "This is very dangerous, you shouldn't do it!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        showLoaderOnConfirm: true,
                      preConfirm: async() => {
                        return await axios.post(url, fd, config).then(response=>{
                         if(response.data.status!==200){
                              throw new Error(response.statusText)
                            }
                            return response.data
                          })
                          .catch(error => {
                            Swal.showValidationMessage(
                              `Request failed: ${error}`
                            )
                          })
                      },
                      allowOutsideClick: () => !Swal.isLoading(),
                        confirmButtonText: 'Yes, I am!'
                      }).then((result:any) => {
                        if (result.isConfirmed && result.value.status===200) {
                  
                          let rs =filterContent.filter((item:any)=>item.is_check ==='false')
                         setContent(rs)
                         setFilterContent(rs)
                           Swal.fire(
                                      'Deleted!',
                                      'Record has been deleted.',
                                      'success'
                                    ) 
                        }
                      })
                  
                    }
                  
                   }

                   const handleDownload = async(extension:string)=>{
             
                    let sql = sqlQuery()
                const fd = new FormData();
                fd.append('sql', sql); 
                fd.append('extension', extension); 
                let url = ServerUrl+'/api/download_file/leave'
                await  axios.post(url, fd, configDownload).then((response:any)=>{
                 const url = window.URL.createObjectURL(new Blob([response.data]));
                   
                     const link = document.createElement('a');
                     link.href = url;
                     link.download = '_leave_list.'+extension
                     link.click();
                     })
                }


    useEffect(()=>{
        fetchLeave()
        fetchContent()
        fetchStaff() 
      }, [])


    

  return ( <>
 <Breadcrumb 
   title="Leave"
   summary=" Search Staff leave usage here"
   >
     <li className="breadcrumb-item">
    <Link to="#">Leave List</Link>
                    </li>

   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Search Leave Application</h5>
                <span>Use the below criteria to filter result</span>
              
                <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
            </div>
            
            <div className="card-block">
    
        
                        <div className="row">
                  
                        <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Search By Employee </label>


                 
                <Select 
                options={
                               staff&&staff.map((list:any, idx:number)=> {
                                return {key:idx, value: list.code, label: list.first_name, last_name:list.last_name, middle_name:list.middle_name, title:list.title }
                                })}
                                 
                     getOptionLabel={option =>` ${option.label} ${option.last_name}`}
    onChange={handleSelect}  name="employee" value={search.employee} 
    styles={styles} 
    /> 
                    </div>
    
                     </div>

                     <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Search by Department</label>
    
    
    <Select  options={
            department.data&&department.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.department_name}
                    })}   
                    styles={styles} 
			onChange={handleSelect}    
            name="department" 
            value={search.department} 
               />
    
    </div>
    
    </div>


                     <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Search by Job Title</label>
    
    
    <Select options={
            Jobs.data&&Jobs.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.title}
                    })}   
                  
			onChange={handleSelect} 
            styles={styles} 
            name="job_title" value={search.job_title} 
              
              />
    
    </div>
    
    </div>

                     <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Search by Leave Type </label>


                 
                    <Select  options={
                                template&&template.map((list:any, idx:number)=> {
                                    return {key:idx, value: list.leave_type_code, label: list.leave_name, effective_month:list.effective_month, no_days:list.no_days, time_cycle:list.time_cycle}
                                    })}
                                    
                     onChange={handleSelect} 
                      name="leave_type"
                      value={search.leave_type}
    
                    styles={styles} 
                    />       
                    
                    </div>
    
                     </div>


                     <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Search by Start Date</label>
           
            <input type="date"   className="form-control form-control-lg"  name='start_date' value={search.start_date} onChange={handleChange}  />


          
             </div>

             </div>



             <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Search by  Status</label>
    
    <Select  options={[
                    { value: 'Approved', label: 'Approved' },
                    { value: 'Pending', label: 'Pending'},
                    { value: 'Rejected', label: 'Rejected'},
                    { value: 'Canceled', label: 'Canceled'},
                    { value: 'All', label: 'All'}
                                ]} 
                onChange={handleSelect}  
                name="approve_status"
                 value={search.approve_status} 
                 styles={styles} 
                 
                 />
    
    </div>
    
    </div>

   </div>

          
<hr />
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-outline-danger btn-round pr-5 pl-5 mr-3" type='button' onClick={handleReset}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={fetchContent}>Search</button>
</div>
</div>
    
            </div>
        </div>
    </div>
    </div>
    
    
    

    


    <div className="modal fade " id="viewpdf" tabIndex={-1} role="dialog" data-backdrop="static" >
       <div className="modal-dialog modal-xl" role="document">
           <div className="modal-content">
               <div className="modal-header bg-dark">
                   <h4 className="modal-title text-white">Print Preview</h4>
                 

           <button type="button" className="close" data-dismiss="modal" aria-label="Close">
           <i className='fa fa-ban text-danger'></i>
           </button>

               </div>
               <div className="modal-body">
               <div className="row">

               <ViewDocument data={filterContent} />


</div>
               </div>
               <div className="modal-footer">
                   <button type="button" className="btn btn-default waves-effect " data-dismiss="modal" id="closeEdit">Close</button>
               </div>
           </div>
       </div>
   </div>




                            <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Leave Application History</h5>
                <span>You can aproove or disapproove leave application here</span>
              
                <div className="card-header-right">

                <div className="dropdown-info dropdown">
        <button type="button" className="btn btn-success active dropdown-toggle dropdown-icon btn-sm " data-toggle="dropdown"><i className='fas fa-eye text-white'></i> Export</button>
                    <div className="dropdown-menu" aria-labelledby="dropdown-4" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut" x-placement="bottom-start" >

                    <PDFDownloadLink document={<DownloadDocument data={filterContent} />} fileName="Employee_Leave_Application.pdf"
                    className="dropdown-item waves-light waves-effect" 
                    >
                  {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <span  ><i className="fas fa-file-pdf "></i> Export as PDF</span>)}
                </PDFDownloadLink>

                        <a className="dropdown-item waves-light waves-effect" href="#!" onClick={()=>handleDownload('xlsx')}><i className="fas fa-file-excel "></i> Export as Excel</a>

                        <a className="dropdown-item waves-light waves-effect" href="#!" onClick={()=>takeScreenshot('takeshot')}><i className="fas fa-image "></i> Export as Image</a>
                        <a className="dropdown-item waves-light waves-effect" href="#!" data-toggle="modal" data-target="#viewpdf" ><i className="fas fa-print "></i> Print Preview</a>
                    </div>
                </div>&nbsp;

        <a href='/leave/leave_application' className="btn btn-primary btn-sm "><i className='fa fa-circle-plus text-white'></i> Add New</a>
        </div> 
            </div>
            <div className="card-block">
    




            <div className="row">

<div className=" col-md-8 ">



                  <button type="button" className="btn btn-info  btn-sm" ><i className="fas fa-check"></i> Approve Selected ({search.totalSelected})</button> &nbsp;

  <button type="button" className="btn btn-outline-danger btn-sm " onClick={handleDeleteAll} ><i className="fas fa-trash"></i> Delete Selected ({search.totalSelected})</button>&nbsp;

  <div className="btn-group ">
                   
                    <button type="button" className="btn btn-inverse dropdown-toggle dropdown-icon  btn-sm" data-toggle="dropdown">
                      <span className="sr-only">Toggle Dropdown</span> <i className='fas fa-fw fa-filter'></i> Visibility &nbsp;
                    </button>
                    <div className="dropdown-menu" role="menu">

                      <a className={!director.isName?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isName:!director.isName})}>Staff</a>

                      <a className={!director.isType?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isType:!director.isType})}>Leave Type</a>

                      <a className={!director.isStartDate?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isStartDate:!director.isStartDate})}>Leave Type</a>


                      <a className={!director.isDays?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isDays:!director.isDays})}>Days</a>

                      <a className={!director.isDateApplied?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isDateApplied:!director.isDateApplied})}>Date Applied</a>


                      <a className={!director.isReason?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isReason:!director.isReason})}>Reason</a>


                      <a className={!director.isApproved?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isApproved:!director.isApproved})}>Approval</a>

                      <a className={!director.isAction?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isAction:!director.isAction})}>Action</a>

                    <div className="dropdown-divider"></div>
                      <a className={"dropdown-item"} href="#!" onClick={handleResetColumn}>Reset Column</a>
                   
                    </div>
                  </div>
                  </div>

    <div className=" col-md-4 ">
    <div className="input-group  " >
                    <input type="text" name="filterText" id="filterText" key="filterText"  onChange={handleSearch} value={search.filterText} className="form-control float-right form-control-sm" placeholder="Type to search" />
                   
                    <div className="input-group-append">
                        <button type="button" onClick={handleClear} className="btn btn-default btn-sm" >
                          <i className="fas fa-close"></i>
                        </button>
                      </div>
                  </div>
      

    </div>
</div>

    <div className="row" id='takeshot'>
        <div className="table-responsive ">
        <TableData />
        </div>
        </div>

            </div>
        </div>
    </div>
    </div>


                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default LeaveList