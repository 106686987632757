import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, ServerUrl, config, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, CustomLoader, Alerts, closeModal } from '../../components/notify';
import Select from 'react-select'
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'

import { useSelector } from 'react-redux'

const LoginReport =()=>{


    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState([]);
   

    const fetchContent = async()=>{


        LoadingModal()
     
             
        var final = 'SELECT e.first_name, e.last_name, l.browser, l.message, l.ip, l.others, l.location, l.created_at FROM tbl_employee e, tbl_logs l where l.staff_code = e.code and event_type ="Login" order by l.id DESC LIMIT 20'
    
              const fd = new FormData();
              fd.append('sql', final); 
              let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
            await  axios.post(url, fd, config).then((result:any)=>{
              if(Array.isArray(result.data.data) && result.data.data.length!==0){
                setContent(result.data.data)
    
    
                }else{
                    setContent([]) 
                   
                }
              })
              .catch((error)=>{
                  Alerts('danger',error.message)
              }).finally(()=>{
                  closeModal()
              })
            }
     





        const TableData =()=>{

        
            const columns = [
                {
                    name: 'SN',
                    cell: (row:any, index:number) => index+1, 
                    sortable: false,
                    width:'50px'
                },
                {
                    name: 'Employee',
                    cell: (row:any) => row.first_name+' '+row.last_name, 
                    sortable: true,
                },
                {
                    name: 'Date Login',
                    selector: (row:any) => row.created_at,
                    sortable: true,
                },
                {
                    name: 'Device & Browser',
                    selector: (row:any) => row.browser + ' - '+row.others,
                    sortable: true,
                    wrap:true
                },
                {
                    name: 'IP',
                    selector: (row:any) => row.ip,
                    sortable: true,
                    wrap:true
                },
                {
                    name: 'Location',
                    selector: (row:any) => row.message,
                    sortable: true,
                    wrap:true
                }
            ];
            
        
          
        
            return <DataTable
            columns={columns}
            data={content}
            dense
            highlightOnHover
            pagination
            selectableRowsHighlight
            striped
            progressPending={loading}
            progressComponent={<CustomLoader />}
        />
        
        }



            useEffect(()=>{
                 fetchContent()
                 }, [])

  return (
   <div>

    
<div className="card z-depth-0">


<div className="card-block">


<div className="table-responsive m-t-10">
<TableData />
</div>

</div>
</div>
   </div>

  )
}

export default LoginReport