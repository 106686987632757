import { useCallback, useState} from 'react'
import Breadcrumb from '../../components/Breadcrumb'

import Title from './Structure/Title';
import StructureList from './Structure/StructureList';
import { Link } from 'react-router-dom';

const  Structure =()=> {
    const [loading, setLoading] = useState(false);
   

    const [activeItem, setActiveItem] = useState(1);
    
    const handleOnClickStepper = (step:number) => {
        setActiveItem(step);        
        
    }
    const handleOnClickNext = useCallback(() => {
        setActiveItem(activeItem + 1);
    }, [activeItem])
    

    const handleOnClickBack = useCallback(() => {
        setActiveItem(activeItem - 1);
       
    }, [activeItem])

   

    const steps = [{title: 'Salary Title'}, {title: 'Salary Structure'}]

    

  return ( <>
 <Breadcrumb 
   title="Salary Structure"
   summary="Create Salary Title and Setup Salary Structure "
   >
    <li className="breadcrumb-item">
    <Link to="/payroll/staff_salary">Payroll</Link>
                    </li>
   <li className="breadcrumb-item">
    <Link to="#!">Salary Structure</Link>
                    </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Salary Structure</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                  


                        <form action='#'>
                            
                <div className="steps-container">
                               
                     <ul className='steps'>

                     {steps.map((item, idx)=>{

            return  <li key={idx}  role="tab" onClick={()=>handleOnClickStepper(idx+1)} className={activeItem===(idx+1)?'current pointer':'pointer'} >
            <a  href="#!"  ><span className="number">{idx+1}.</span>  {item.title} </a>
            </li>
            })}

                                    
        </ul>
          </div>
                                    
                                    
                                    
         <div className="content-wrappers mt-3">
                            
            {activeItem===1?
            <Title />
            :
              <StructureList />
            }

        </div>
                        
                        <div className=" f-right">

                            <ul className='footer-actions mt-2'>


{activeItem ===1 ? '' : <li className='btn btn-secondary btn-sm ' onClick={handleOnClickBack} >
                                    <a href="#!" className='text-white'>Previous</a>
                                    </li>}

 <li className='btn btn-primary btn-sm text-white ml-1' onClick={activeItem === steps.length ? ()=>()=>{} : handleOnClickNext}><a href="#!" className='text-white'> {activeItem === steps.length ?'Finish':'Next'} </a></li>
                                
                            </ul>
                                </div>
                            </form>
                    </section>
                </div>
            </div>
        </div>
    </div>
                                    </div>
                                </div>
                            </div>





                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default Structure