import { useEffect, useState} from 'react'
import { COMPANY_CODE, ImagesUrl, ServerUrl, config, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, closeModal, Alerts } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'


import Swal from 'sweetalert2'
import { useQuery } from 'react-query'
import Select from 'react-select'
import { fetchDepartment, FetchProfile} from '../../api/ReactQuery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertDate, getBritishDate } from '../../components/GlobalFunction';
import { Link } from 'react-router-dom';

const Allocation =()=> {
  const [staff, setStaff] = useState([] as any);
  const [assets, setAssets] = useState([] as any);
    const [content, setContent] = useState([] as any);
    const profile = useQuery(["profile"], FetchProfile)
    const [search, setSearch] = useState({
     
      asset_name:[] as any,
     })

    
           const [errors, setErrors] = useState({
            start_date:'',
            end_date:''
           
         })

       
         const handleSelect = (option:any, action:any)=>{
            setSearch({...search, [action.name]:option});
              setErrors({...errors, [action.name]:''})
              fetchHistory(option.usage_history)
    
               }

               const fetchHistory =(usage_history:string)=>{

                try{
                  
                  let options = JSON.parse(usage_history)
                  var value = [];
                  for (var i = 0, l = options.length; i < l; i++) {
                    value.push({
                      ...options[i],
                      
                      staff_name:getStaff(options[i].staff_code),
                      asset:getAsset(options[i].asset),
                    });
                  }
                  setContent(value)

                }catch(e){

                }
               }


             const handleFetchAsset =async()=>{

              let url = ServerUrl+'/api/asset/view_asset/'+COMPANY_CODE
             await  axios.get(url, config).then(result =>{
              if(Array.isArray(result.data.data) && result.data.data.length!==0){
                          setAssets(result.data.data)
                  }
            
              })
        
           }
    
           const getStaff =(code:string)=>{
            const result =staff.filter((item:any)=>item.code===code);
           const answer = result.length!==0?result[0].first_name+' '+result[0].last_name:''
           return  answer
         }


           const getAsset =(code:string)=>{
           const result =assets.filter((item:any)=>item.code===code);
          const answer = result.length!==0?result[0].asset_name:''
          return  answer
        }

        const fetchStaff =async()=>{
          let url = ServerUrl+'/api/employees/view/'+COMPANY_CODE
          const {data} = await axios.get(url, config)
          setStaff(data.data)
          }



useEffect(()=>{
  fetchStaff()
  handleFetchAsset()
}, [])

  return ( <>
   <Breadcrumb 
   title="Allocation"
   summary="Asset Allocation History"
   >
    <li className="breadcrumb-item">
    <Link to="/assets/display_assets">Assets</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Report</a> </li>
         <li className="breadcrumb-item"><a href="#!">Allocation</a> </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
<div className="main-body">
<div className="page-wrapper">
<div className="page-body">


<div className="row">
<div className=" col-md-12">
<div className="card z-depth-0">
    <div className="card-header">
        <h5>AASET ALLOCATION HISTORY</h5>
        <span  ><a href="#">View Asset History</a></span>
        <div className="card-header-right">

            <ul className="list-unstyled card-option">
                <li><i className="fa fa fa-wrench open-card-option"></i></li>
                <li><i className="fa fa-window-maximize full-card"></i></li>
                <li><i className="fa fa-minus minimize-card"></i></li>
                <li><i className="fa fa-refresh reload-card"></i></li>
                <li><i className="fa fa-trash close-card"></i></li>
            </ul>
            
        </div>
    </div>
    <div className="card-block ">
       

        <div className="row">
        <div className="col-md-12">
    <div className="form-group">
    <label className="col-form-label">Search by Asset</label>
    
    
    <Select  options={
            assets&&assets.map((list:any, id:number)=>{
                return {key:id,value: list.code, label: list.category + ' - '+list.asset_name, usage_history:list.usage_history}
                    })}   
                    styles={styles} 
			onChange={handleSelect}    
            name="asset_name" 
            value={search.asset_name} 
               />
    
    </div>
    
    </div>


        </div>

    </div>
</div>
</div>
</div>






{content.length!==0?
  <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-block">
    

    <div className="row">
        <div className="table-responsive ">
       
        <table className="table table-sm table-bordered table-hover">
                <thead>
                    <tr>
                        <th colSpan={6} className='text-center '>

                        <img 
           src={ImagesUrl+'/about/'+profile.data.logo}  alt="Click to change" 
           className="m-b-10 img-100" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/logo.png'}} />


                        <h4>{profile.data.company_name} </h4>
                        <h5>{profile.data.company_address}</h5>
                        <h6 className='m-b-5'>{profile.data.email}, {profile.data.phone}</h6>

                        <div className="alert alert-info text-center mb-1 mt-2">

                        <h5> ASSET ALLOCATION HISORY</h5>
                        </div>
                        </th>
                    </tr>
                  



                    <tr style={{backgroundColor:'#E7E0EE'}}>
                <th >S/N</th>
                    <th  >Staff Name</th>
                    <th>Asset</th>
                    <th>Date Assigned</th>
                    <th>Date Returned</th>
                    <th>Action</th>
                </tr>

                </thead>
                <tbody>
                    {content&&content.map((st:any, index:number)=>
                    <tr key={index}>
                        <td >{index+1}</td>
                        <td >{st.staff_name}</td>
                        <td>{st.asset}</td>
                        <td>{st.date_assign}</td> 
                        <td>{st.date_returned}</td> 
                        <td>{st.date_returned ===''? <button className="btn btn-sm btn-info"> Return Asset <i className="fa fa-external-link"></i></button>:''}</td> 
                </tr>)} 
                          

                </tbody>

                   
            </table>

        </div>


        <div className="row">
        <div className="col-md-12">
        <button type="button" className="btn btn-success  no-print" > <i className="fa fa-print"></i> Print</button>
        </div></div>


        </div>

            </div>
        </div>
    </div>
    </div>:[]}

</div>
</div>


</div>
</div>  
      </>               
 
  )
}

export default Allocation