import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo} from 'react'
import { COMPANY_CODE, ImagesUrl, STAFF_CODE, ServerUrl, config, configFLW } from '../../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal, CustomLoader, closeModal } from '../../../components/notify';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { useQuery } from 'react-query'
import { fetchEmployee } from '../../../api/ReactQuery';

import Select, { components } from 'react-select'
const Bank =({code}:{code:any})=> {


    const [disabled, setDisabled] = useState(false);

    const {data} = useQuery(["employee", code], fetchEmployee)
    
    const [bankList, setBankList] = useState([] as any);
    const [rlga, setRLga] = useState([] as any);
    const [isResidence, setIsResidence] = useState('Yes');
    const [content, setContent] = useState([] as any);

    const [errors, setErrors] = useState({
       
        bank_name:'',
        account_number:'',
        account_name:''
        
     })


     const handleSelect = (option:any, action:any)=>{
        const newProfile = content.map((item:any)=>{
            return {...item, 
                account_type:option.value,
                [action.name]:option.label}
          })
          setContent(newProfile)

          setErrors({...errors, [action.name]:''});
           }


    const fetchBank =async()=>{
        let url = ServerUrl+"/api/flutterwave/bank_list/"+COMPANY_CODE

        const {data} = await axios.get(url, config)

        try{
            const rs = JSON.parse(data.data)
            setBankList(rs.data)
        }catch (err){

        }
       
        }



        const fetchAccountName =async()=>{
            LoadingModal()
            const fd = new FormData();
            fd.append('account_number', content[0].account_number);
            fd.append('account_bank', content[0].account_type);

        let url = ServerUrl+'/api/flutterwave/account_name';

            const {data} = await axios.post(url, fd, config).finally(()=>{
                closeModal()
            })

    
            try{
                const rs = JSON.parse(data.data)
                if(rs.length!==0){
                const newProfile = content.map((item:any)=>{
                    return {...item, account_name:rs.data.account_name}
                  })
                  setContent(newProfile)
                }

            }catch (err){
    
            }
            }



        const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
          const newProfile = content.map((item:any)=>{
            return {...item, [name]:value}
          })
          setContent(newProfile)
           setErrors({...errors, [name]:''});
         }




   
const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!content[0].bank_name){
    error.bank_name =msg;
    formIsValid = false;
} 


if(!content[0].account_number){
    error.account_number =msg;
    formIsValid = false;
} 


if(!content[0].account_name){
    error.account_name =msg;
    formIsValid = false;
} 


if(!formIsValid){
setErrors(error) 
}


if(formIsValid) {

    LoadingModal()
 const fd = new FormData();
    Object.entries(content[0]).forEach(([key, value]) => {
            fd.append(key, String(value));
        });

        const log = JSON.parse(content[0].update_log)
        const item = log.concat( {
            code:STAFF_CODE,
            action:'Updated contact details',
            date_time: new Date()
        })


const logs = JSON.stringify(item,null, 2)

        fd.append('logs', logs);
       

   let url = ServerUrl+'/api/employee/update/'+code;
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                ErrorModal('', response.data.message)
              }   
          })
          .catch((error)=>{
           ErrorModal('oops', error.message)
          }) 
    }

}




        useEffect(()=>{
            fetchBank()
        }, [])



 useEffect(()=>{
    if (data!==undefined)
    setContent(data)
    window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
         
    }, [data])



  return (
   
    <div>
    <div className="card z-depth-0">
  
  <div className="card-header">
      <h5>Bank Account Details</h5>
  
  </div>
  {content.length!==0? content.map((profile:any, index:number)=>  <div key={index}  className="card-block">
 
  
  
           <div className="row">
           <div className="col-md-6">
           <div className="form-group">
          <label className="col-form-label">Select Bank <span className='text-danger'>*</span></label>
         
           
<Select options={
                              bankList&&bankList.map((list:any, idx:number)=> {
                                return {key:idx, value: list.code, label: list.name }
                                })}
                                className={errors.bank_name?"form-control form-control-danger":""} 
    onChange={handleSelect}  name="bank_name" value={{label:profile.bank_name, value:profile.bank_name}} 
    isDisabled={disabled}
    /> 
           </div>
  
           </div>
           <div className="col-md-6">
           <div className="form-group">
          <label className="col-form-label">Account Number <span className='text-danger'>*</span></label>
         
          <input type="number" className={errors.account_number?"form-control form-control-danger":"form-control"} onBlur={fetchAccountName} name='account_number' value={profile.account_number} onChange={handleChange} disabled={disabled} />
          
    {errors.account_number&&
    <div className="col-form-label has-danger">{errors.account_number&&errors.account_number} </div>}
        
           </div>
  
           </div>
  
  
           <div className="col-md-6">
           <div className="form-group">
          <label className="col-form-label">Account Name <span className='text-danger'>*</span></label>
         
          <input type="text" className={errors.account_name?"form-control form-control-danger":"form-control"}   name='account_name' value={profile.account_name} onChange={handleChange} disabled={true} />
        
          {errors.account_name&&
    <div className="col-form-label has-danger">{errors.account_name&&errors.account_name} </div>}
           </div>
  
           </div>
  
  
  
  
  
          </div><hr/>
  
  
          <div className="row">
  <div className="col-md-12 text-right">
  <button className="btn btn-success btn-round" type='button' onClick={handleSubmit}>Save Details</button>
  </div>
  </div>
  
  
  
             </div>):''}
             </div>
      </div>
  )
}

export default Bank