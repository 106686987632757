import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, ServerUrl, config } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, Alerts, SuccessModal, closeModal } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'
import { Link } from 'react-router-dom';



const  SMSSetup =()=> {
  

    const [content, setContent] = useState({
        message:'',
        receiver_number:'',
        sender:'',
           api_token:'',
           api_email:'',
           api_password:'',
           api_endpoint:'',
            status:'Active',
    });

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        message:'',
        receiver_number:'',
        sender:'',
        api_token:'',
        api_email:'',
           api_password:'',
           api_endpoint:'',
       
     })
    

       
                     
     const handleChangeText =(event:ChangeEvent<HTMLTextAreaElement>)=>{


        let {name, value} = event.target;	
        setContent({...content, [name]:value})
        setErrors({...errors, [name]:''});
        
        }
                        


const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{


let {name, value} = event.target;	

if(name==='status'){
    value = value==='Active'?'Inactive':'Active'
}
if(name==='sender'){
    value = value.slice(0,9)
}
setContent({...content, [name]:value})
setErrors({...errors, [name]:''});

}
             

const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!content.api_email){
    error.api_email=msg;
    formIsValid = false;
} 

if(!content.sender){
    error.sender=msg;
    formIsValid = false;
} 

if(!content.api_endpoint){
    error.api_endpoint =msg;
    formIsValid = false;
} 

let email = content.api_email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

if(!content.api_email){
    error.api_email =msg;
    formIsValid = false;
} 

if(!email){
    error.api_email ='Please enter valid email';
    formIsValid = false;
} 

if(!content.api_password){
    error.api_password =msg;
    formIsValid = false;
} 

if(!content.api_token){
    error.api_token =msg;
    formIsValid = false;
} 


if(!formIsValid){
setErrors(error) 
}

if(formIsValid) {

    LoadingModal()
   

    

    const fd = new FormData();
   
    Object.entries(content).forEach(([key, value]) => {
      fd.append(key, String(value));
  });

    let url = ServerUrl+'/api/setup/sms_setup/update';
   
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
              Alerts('danger', response.data.message)
              closeModal()
              }   
          })
          .catch((error)=>{
            Alerts('danger', error.message)
            closeModal()
          })
    }
}


    
const fetchContent =()=>{
    LoadingModal()
    let url = ServerUrl+'/api/setup/sms_setup/show_one/smstp'
    axios.get(url, config).then(result=>{
      if(result.data.status===200){
        try {
         
            setContent({...content, ...result.data.data})
  
        }catch(e){
  
        }
      }
    })
    .catch((error)=>{
     Alerts('danger', error.message)
    }).finally(()=>{
        closeModal()
    })
    }

  
  useEffect(()=>{
      fetchContent()
  }, [])



  return ( <>
  <Breadcrumb 
   title="SMS Setup"
   summary="Update SMS Sender details"
   >
     <li className="breadcrumb-item">
    <Link to="/setup/configure/message_template">Template</Link>
                    </li>

   <li className="breadcrumb-item">
    <Link to="#!">Setup</Link>
                    </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">



                        <div className="modal fade " id="testsms" tabIndex={-1} role="dialog" data-backdrop="static" >
        <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
                <div className="modal-header bg-dark">
                    <h4 className="modal-title text-white">Send Test SMS</h4>
                  
    
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i className='fa fa-ban text-danger'></i>
            </button>
    
                </div>
                <div className="modal-body">
                
     <div className="form-group row">
            <label className="col-sm-3 col-form-label">Receiver Number <span className='text-danger'>*</span></label>
            <div className="col-sm-9">
                <input type="text" className={errors.receiver_number?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  value={content.receiver_number} name="receiver_number" onChange={handleChange} placeholder='Use comma to seperate numbers' />


                {errors.receiver_number&&
    <div className="col-form-label has-danger">{errors.receiver_number&&errors.receiver_number} </div>}


            </div>  
            
            </div> 

            <div className="form-group row">
            <label className="col-sm-3 col-form-label">Message <span className='text-danger'>*</span></label>
            <div className="col-sm-9">
                <textarea rows={5}  className={errors.message?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  value={content.message} name="message" onChange={handleChangeText} ></textarea>


                <span style={{color:'green'}}>

{content.message.length} Characters,  {(Math.ceil(Number(content.message.length)/160))}  Pages, Remaining {(160 -(Number(content.message.length)%160))}</span> 
                {errors.message&&
    <div className="col-form-label has-danger">{errors.message&&errors.message} </div>}

            </div>  
            
            </div> 



                </div>
                <div className="modal-footer">
                    <button type="button" id='closeModal' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>

                    <button type="button" className="btn btn-outline-success " onClick={handleSubmit}  >Send</button>
                </div>
            </div>
        </div>
    </div>



    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>SMS Setup</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                              
                                    
                                    
         <div className="content-wrappers ">
                            
         <div className=''>



         <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
        <div className="card-block">
        <a href='#!' data-toggle="modal" id='openEdit' data-target="#testsms" className="btn btn-inverse btn-round pr-5 pl-5"> <i className='fa fa-envelope'></i> Send Test</a>

<button className="btn btn-success btn-round pr-5 pl-5 ml-3" type='button' onClick={handleSubmit} >Update</button>
</div>
</div>
</div> 
</div> 

    


    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Third Party API</h5>
                <span>Add all offcie department</span>
              
            </div>
            <div className="card-block">

    

            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">API Token </label>
            <div className="col-sm-10">
                <input type="text" className={errors.api_token?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  value={content.api_token} name="api_token" onChange={handleChange} />


                {errors.api_token&&
    <div className="col-form-label has-danger">{errors.api_token&&errors.api_token} </div>}


            </div>  
            
            </div> 



            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">Sender </label>
            <div className="col-sm-10">
                <input type="text" className={errors.sender?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} max={9}  value={content.sender} name="sender" onChange={handleChange} />


                {errors.sender&&
    <div className="col-form-label has-danger">{errors.sender&&errors.sender} </div>}


            </div>  
            
            </div> 
      

            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">API Email </label>
            <div className="col-sm-10">
                <input type="text" className={errors.api_email?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  value={content.api_email} name="api_email" onChange={handleChange} />


                {errors.api_email&&
    <div className="col-form-label has-danger">{errors.api_email&&errors.api_email} </div>}


            </div>  
            
            </div> 


            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">API Password </label>
            <div className="col-sm-10">
                <input type="password" className={errors.api_password?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  value={content.api_password} name="api_password" onChange={handleChange} />


                {errors.api_password&&
    <div className="col-form-label has-danger">{errors.api_password&&errors.api_password} </div>}


            </div>  
            
            </div> 


            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">API End Point </label>
            <div className="col-sm-10">
                <input type="text" className={errors.api_endpoint?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  value={content.api_endpoint} name="api_endpoint" onChange={handleChange} />


                {errors.api_endpoint&&
    <div className="col-form-label has-danger">{errors.api_endpoint&&errors.api_endpoint} </div>}


            </div>  
            
            </div> 

    
            </div>
        </div>
    </div>
    </div>

    
                </div>

        </div>
                       
                    </section>
                </div>
            </div>
        </div>
    </div>
                                    </div>
                                </div>
                            </div>





                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default SMSSetup