import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo} from 'react'
import { COMPANY_CODE, STAFF_CODE, ServerUrl, config, selectStyles, styles } from '../../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal, CustomLoader, Alerts, closeModal } from '../../../components/notify';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'

import Select, { components } from 'react-select'

const PerformanceAssessment =()=> {

    const [item, setItem] = useState({
        criteria:[] as any,
        title:'',
    });

    const [editItem, setEditItem] = useState({
      criteria:'',
      title:'',
        code:'',
        id:''
    });

    const [criteria, setCriteria] = useState([] as any);
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        title:'',
        criteria:'',

        edit_title:'',
        edit_criteria:'',
     })
    
     const handleSelect = (option:any, action:any)=>{
        setItem({...item, [action.name]:option});
          setErrors({...errors, [action.name]:''})
           }


    const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;		
    setItem({...item, [name]:value})
   setErrors({...errors, [name]:''});
     }


const handleReset =()=>{
    setItem({
        criteria:[] as any,
        title:'',
    })
}


const getTitle = (code:string) =>{ 
    const result = criteria.filter((list:any)=>list.code===code);
   const answer = result.map((c:any)=>` ${c.title}`); 
     return  <span>{answer}</span>;
   }


const HandleCriteria=({cell}:{cell:any})=>{
    

    try{
        let rs = JSON.parse(cell)
    let answer = rs.map((itm:any, idx:number)=><div  key={idx}>{getTitle(itm.criteria_code)}</div> ); 

    return answer;
    }catch(e){

    }
    
}


const getTitleName = (code:string, field:string) =>{ 
    const result = criteria.filter((list:any)=>list.code===code);
   const answer = result.map((c:any)=>c[field] ); 
     return  String(answer);
   }
   
const getTemplate=(temp:any)=>{

    try{

   
    let value:any = [];
 JSON.parse(temp).map((itm:any, idx:number)=>{
        value.push({value:itm.criteria_code, label:getTitleName(itm.criteria_code, 'title'), key:idx});
    } );
    
return value;

}catch(e){
        
}

}



const TableData =()=>{


    const columns = [
        {
            name: 'Title',
            selector: (row:any) => row.title,
            sortable: true,
            wrap:true
        },
        {
            name: 'Criteria',
            cell: (row:any) =><div><HandleCriteria cell={row.criteria} /> </div>,
            wrap:true
        },
        {
            name: 'Action',
            cell: (row:any, index:any, column:any, id:any) => <div className='row'>  
                <label className="label label-info pointer" onClick={()=>EditItem(row)} ><i className='ti-pencil'></i> Edit</label>
            <label className="label label-danger pointer" onClick={()=>DeleteModal(row.id)}><i className='ti-trash'></i> Delete</label></div>,
                left:true
                      

        },
    ];
    
  

    return <DataTable
    columns={columns}
    data={content}
    dense
    highlightOnHover
    pagination
    selectableRowsHighlight
    striped
    progressPending={loading}
	progressComponent={<CustomLoader />}
/>

}



const handleChangeEdit =(event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	
    setEditItem({...editItem, [name]:value})
   setErrors({...errors, [name]:''});
 }



const EditItem =(data:any)=>{
    setEditItem({
        criteria:data.criteria,
        title:data.title,
        code:data.code,
        id:data.id
    }); 
    let click = document.getElementById('openEdit')?.click()

}



const handleSelectEditTemplate = (option:any, action:any)=>{
   // const std =JSON.parse(editItem.template)  

  var value = [];
        for (var i = 0, l = option.length; i < l; i++) {
          value.push({title_code:option[i].value});
        }

        setEditItem({...editItem, criteria:JSON.stringify(value, null, 1)});
        setErrors({...errors, [action.name]:''})
}



  const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!item.title){
    error.title =msg;
    formIsValid = false;
} 


if(item.criteria.length===0){
    error.criteria=msg;
    formIsValid = false;
} 




if(!formIsValid){
setErrors(error) 
}

if(formIsValid) {

    LoadingModal()
   
    const fd = new FormData();
    var options = item.criteria;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
        value.push({
          criteria_code:options[i].value
        });
    }


    let code = 'PA'+Math.random().toString(36).substring(2, 9)
  
    fd.append('company_code', COMPANY_CODE);
    fd.append('code', code);

    fd.append('title', item.title);
    fd.append('criteria', JSON.stringify(value, null, 2));


let url = ServerUrl+'/api/performance/assessment/add';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                Alerts('danger', response.data.message)
              }   
          })
          .catch((error)=>{
            Alerts('danger', error.message)
          }).finally(()=>{
       fetchData()
         handleReset()

          })
    }

}



const handleUpdate =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

 if(!editItem.title){
    error.edit_title =msg;
    formIsValid = false;
}  


if(!editItem.criteria){
    error.criteria =msg;
    formIsValid = false;
}  



if(!formIsValid){
setErrors(error) 
}


if(formIsValid){
 LoadingModal()
   
    const fd = new FormData();
    Object.entries(editItem).forEach(([key, value]) => {
        fd.append(key, String(value));
    });
    let url = ServerUrl+'/api/performance/assessment/update_assessment';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                Alerts('danger', response.data.message)
              }   
          })
          .catch((error)=>{
            Alerts('danger', error.message)
          }).finally(()=>{
         let click = document.getElementById('closeModal')?.click()
         fetchData()
        
          })  
    }

}

const DeleteModal =(id:number)=>{
    let url = ServerUrl+'/api/performance/assessment/delete_one/'+id;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =content.filter((item:{id:number})=>item.id !==id)
            setContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }

const fetchCriteria = async()=>{
  let url = ServerUrl+'/api/performance/settings/criteria/view/'+COMPANY_CODE
  await  axios.get(url, config).then((result:any)=>{
    if(Array.isArray(result.data.data) && result.data.data.length!==0){
      setCriteria(result.data.data)
      }
    })
    .catch((error)=>{
        Alerts('danger', error.message)
    })
    } 



    const fetchData = async()=>{
        setLoading(true)
        let url = ServerUrl+'/api/performance/assessment/criteria/view/'+COMPANY_CODE
      await  axios.get(url, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){
          setContent(result.data.data)
          }
        })
        .catch((error)=>{
            Alerts('danger', error.message)
        }).finally(()=>{
            setLoading(false)
        })
        } 



      


    useEffect(()=>{
        fetchCriteria()
        fetchData()
      }, [])






  return (
    <div className=''>


<label data-toggle="modal" data-target="#editItem" id='openEdit' ></label>

<div className="modal fade " id="editItem" tabIndex={-1} role="dialog" data-backdrop="static" >
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header bg-dark">
                    <h4 className="modal-title text-white">Update Assessment </h4>
                  
    
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i className='fa fa-ban text-danger'></i>
            </button>
    
                </div>
                <div className="modal-body">
                
    
                 

                <div className="form-group row">
            <label className="col-sm-2 col-form-label">Assessment Title</label>
            <div className="col-sm-10">


                <input type="text" className={errors.edit_title?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='title' value={editItem.title} onChange={handleChange} />

                {errors.edit_title&&
    <div className="col-form-label has-danger">{errors.edit_title&&errors.edit_title} </div>}
            </div>  
            
            </div> 


            <div className="form-group row">
            <label className="col-sm-2 col-form-label">Select Criteria</label>
            <div className="col-sm-10">

                               
                                <Select isMulti options={
                                criteria&&criteria.map((list:any, idx:number)=> {
                                    return {key:idx, value: list.code, label: list.title}
                                    })}
                                    
                          onChange={handleSelect} 
                          className={errors.edit_criteria ? 'form-control form-control-danger' : ''} 
                          name="criteria" 
                          value={getTemplate(editItem.criteria)} 
                          
                          styles={selectStyles} 
                          /> 
                              

                {errors.edit_criteria&&
    <div className="col-form-label has-danger">{errors.edit_criteria&&errors.edit_criteria} </div>}

                                 </div>
                                 </div>

         
                </div>
                <div className="modal-footer">
                    <button type="button" id='closeModal' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-outline-success " onClick={handleUpdate} >Update</button>
                </div>
            </div>
        </div>
    </div>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Assessment Type</h5>
                <span>Add all offcie department</span>
              
            </div>
            <div className="card-block">
    
        
                     

                        <div className="form-group row">
            <label className="col-sm-2 col-form-label">Assessment Title</label>
            <div className="col-sm-10">


                <input type="text" className={errors.title?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='title' value={item.title} onChange={handleChange} />

                {errors.title&&
    <div className="col-form-label has-danger">{errors.title&&errors.title} </div>}
            </div>  
            
            </div> 


            <div className="form-group row">
            <label className="col-sm-2 col-form-label">Select Criteria</label>
            <div className="col-sm-10">

                               
                                <Select isMulti options={
                                criteria&&criteria.map((list:any, idx:number)=> {
                                    return {key:idx, value: list.code, label: list.title}
                                    })}
                                    
                          onChange={handleSelect} 
                          className={errors.criteria ? 'form-control form-control-danger' : ''} 
                          name="criteria" 
                          value={item.criteria} 
                          
                          styles={selectStyles} 
                          /> 
                              

                {errors.criteria&&
    <div className="col-form-label has-danger">{errors.criteria&&errors.criteria} </div>}

                                 </div>
                                 </div>
              


<hr/>
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-outline-danger btn-round pr-5 pl-5 mr-3" type='button' onClick={handleReset}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={handleSubmit}>Save</button>
</div>
</div>
    
            </div>
        </div>
    </div>
    </div>
    
    
    

    
    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>All Assessment</h5>
                <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
              
            </div>
            <div className="card-block">
    
        <div className="table-responsive m-t-10">
        <TableData />
        </div>
    
            </div>
        </div>
    </div>
    </div>
    
                </div>
  )
}

export default PerformanceAssessment