
import { useEffect, useState} from 'react'
import { COMPANY_CODE, ServerUrl, config, group_styles } from '../../components/Includes';
import axios from 'axios';
import {Chart,ArcElement, registerables } from 'chart.js';
import {Bar, Doughnut, Pie} from 'react-chartjs-2'
import { convertDate, getMonthYear } from '../../components/GlobalFunction';

import { useQuery } from 'react-query'
import { fetchSettings } from '../../api/ReactQuery';
import Breadcrumb from '../../components/Breadcrumb';
import { Link } from 'react-router-dom';
import { ASSET_CATEGORY, ASSET_CONDITION, DateList } from '../../components/Data';
import Select from 'react-select'

const AssetsDashboard =()=> {
  
    let thisMonth = getMonthYear(new Date().toISOString().slice(0,10));
let month = new Date().toISOString().slice(0,7)
    Chart.register(ArcElement, ...registerables)

    var today = new Date().toISOString().slice(0,10);
    var date = new Date()
    var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1, 23, 59, 59)
    var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)

    var firstDayOfYear = new Date(date.getFullYear(), 0, 1, 23, 59, 59)
    var lastDayOfYear = new Date(date.getFullYear()+1, 0, 0, 23, 59, 59)

    const [search, setSearch] = useState({
      date_range:  {label:'2023-01-01 - 2023-12-31', value:'2023-01-01 - 2023-12-31', start_date:'2023-01-01', end_date:'2023-12-31'},
   })

      const [assetList, setAssetList] = useState([] as any);
      const [yearList, setYearList] = useState([] as any);
    const [event, setEvent] = useState(0);
    const [assetCondition, setAssetCondition] = useState([])
    const [department, setDepartment] = useState([])
    const [statistics, setStatistics] = useState({
       
        apply:0,
        approved:0,
        pending:0,
        reject:0,
        leave_type:0,
        holidays:0,
        events:0,
        onleave:0,
        staff:0,

        assets:0,
        movable:0,
        allocated:0,
        insured:0
    });



    const fetchEvent =async()=>{
      var sql ="SELECT title, IFNULL(count(id),0) as data FROM tbl_holidays WHERE event_type = 'Event' and start_date >=  '"+convertDate(firstDayOfMonth)+"' and end_date  <= '"+convertDate(lastDayOfMonth)+"' group by title " ;

      const fd = new FormData();
      fd.append('sql', sql); 
      
      let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
    await  axios.post(url, fd, config).then((result:any)=>{

      if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
        setEvent(result.data.data[0].data)
        }else{
          setEvent(0)
        }
      })
  }

    const fetchDepartment =async()=>{


        var sql ="SELECT d.department_name, IFNULL(sum(a.id), 0) as data from tbl_departments d left join tbl_assets a on a.department = d.code group by d.department_name " ;

        const fd = new FormData();
        fd.append('sql', sql); 
        let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
      await  axios.post(url, fd, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
          setDepartment(result.data.data)

          }else{
            setDepartment([])
          }
        })
    }



    const fetchStatistics=async()=>{
       
        var sql =" SELECT (SELECT count(*) from tbl_assets) as assets, (SELECT count(*) from tbl_assets where is_insured ='Yes') as insured,  (SELECT count(*) from tbl_assets where is_movable ='Yes') as movable,(SELECT count(*) from tbl_assets where assign_to <> '') as allocated"

        const fd = new FormData();
        fd.append('sql', sql); 
        let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
   await axios.post(url, fd, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
          setStatistics({
            ...statistics,
            assets:result.data.data[0].assets,
            insured:result.data.data[0].insured,
            movable:result.data.data[0].movable,
            allocated:result.data.data[0].allocated
        
        })
          }
        })
             }




    const fetchCondition=()=>{
       
      let sqlString = ASSET_CONDITION.map(data=>" max(case when c.condition  = '"+data+"'  then 1 else 0 end) AS '"+data+"'")

      var sql = 'SELECT '+String(sqlString)+ ' FROM tbl_assets c'
       
        const fd = new FormData();
        fd.append('sql', sql); 
        let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
    axios.post(url, fd, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
          
          setAssetCondition(result.data.data[0])
          }else{
            setAssetCondition([])
          }
        })
             }


             const fetchAssetList=()=>{
       

            let sqlString = ASSET_CATEGORY.map(data=>" max(case when c.category  = '"+data+"'  then 1 else 0 end) AS '"+data+"'")

              var sql = 'SELECT '+String(sqlString)+ ' FROM tbl_assets c'
              
              const fd = new FormData();
              fd.append('sql', sql); 
              let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
          axios.post(url, fd, config).then((result:any)=>{
              if(Array.isArray(result.data.data) && result.data.data.length!==0){ 

                setAssetList(result.data.data[0])

                }else{
                  setAssetList([])
                }
              })
                   }

                   const assetListStark = {
                    labels: ASSET_CATEGORY.map((data:any)=>data),
                    datasets: [
                      {
                        label: 'Total',
                        backgroundColor: [ '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22'],
                        hoverBackgroundColor: [ '#adc71b', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000',],
                        data: ASSET_CATEGORY.map((data:any)=>assetList[data]), //[65, 59, 80, 81, 56]
                      }
                    ]
                  };



             const fetchYearly=()=>{
       
              var sql = "SELECT current_year as label, sum(case when is_approved = 'Pending'   then 1 else 0 end) AS 'Pending', sum(case when is_approved = 'Approved'  then 1 else 0 end) AS 'Approved', sum(case when is_approved = 'Reject'  then 1 else 0 end) AS 'Reject' FROM tbl_leave_application  group by current_year"
              
              const fd = new FormData();
              fd.append('sql', sql); 
              let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
          axios.post(url, fd, config).then((result:any)=>{
              if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
                setYearList(result.data.data)
                }
              })
                   }


                   const yearListStark = {
                    labels: yearList.map((data:any)=>data.label),
                    datasets: [
                      {
                        label: '# of Pending',
                        data: yearList.map((data:any)=>data.Pending),
                        backgroundColor: 'rgb(25, 43, 234)',
                      },
                      
                      {
                        label: '# of Approved',
                        data: yearList.map((data:any)=>data.Approved),
                        backgroundColor: 'rgb(12, 178, 12)',
                      },
                      {
                        label: '# of Reject',
                        data: yearList.map((data:any)=>data.Reject),
                        backgroundColor: 'rgb(255, 99, 132)',
                      }
                    ],
                  };



    const departmentList= {
        labels: department.map((data:any)=>data.department_name),
        datasets: [
          {
            label: 'Total',
            backgroundColor: [ '#99CC00', '#3C3C3D','#00000', '26164d', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70',],
            hoverBackgroundColor: [ '#3a8300', '#CACACA', '#3C3C3D','#00000', '26164d', '#501800',],
            data: department.map((data:any)=>data.data), //[65, 59, 80, 81, 56]
          }
        ]
      }



      const assetConditionList = {
        labels: ASSET_CONDITION.map((data:any)=>data),
        datasets: [
          {
            label: 'Total',
            backgroundColor: [ '#35014F', '#fe5d70','#99CC00', '#3C3C3D', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22'],
            hoverBackgroundColor: [ '#adc71b', '#99CC00', '#3C3C3D','#00000', '26164d', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000',],
            data: ASSET_CONDITION.map((data:any)=>assetCondition[data]), //[65, 59, 80, 81, 56]
          }
        ]
      }


     


      const optionStark = {
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      };

      const handleSelect = (option:any, action:any)=>{
       setSearch({...search, [action.name]:option});
         

           }


           
           const  fetchSearch =()=>{
            fetchAssetList()
            fetchStatistics()
            fetchYearly()
            fetchDepartment()
            fetchCondition()
           }


      useEffect(()=>{
        fetchEvent()
        fetchCondition()
        fetchDepartment()
        fetchAssetList()
        fetchStatistics()
        fetchYearly()
    }, [])

  return ( <>
   <Breadcrumb 
   title="Asset Dashboard"
   summary="Statistical display of Organization Asset"
   >
     <li className="breadcrumb-item">
    <Link to="/assets/display_assets">Assets</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Dashboard</a> </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
                                <div className="main-body">
                                    <div className="page-wrapper">
                                        <div className="page-body">




                                        <div className="row">


                                       
                                        <div className="col-md-3">
                                                <div className="card text-center bg-inverse text-white">
                                                    <div className="card-block">
                                                        <i className='fa fa-building fa-2x text-white'></i>
                                                        <h5 className="m-b-15 m-t-15">{statistics.assets}</h5>
                                                        <h6 className="m-b-0">Total Assets</h6>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-3">
                                                <div className="card text-center bg-c-purple text-white">
                                                    <div className="card-block">
                                                        <i className='fas fa-users fa-2x text-white'></i>
                                                        <h5 className="m-b-15 m-t-15">{statistics.allocated}</h5>
                                                        <h6 className="m-b-0">Total Allocated</h6>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-3">
                                                <div className="card text-center bg-c-green text-white">
                                                    <div className="card-block">
                                                        <i className='fa fa-car fa-2x text-white'></i>
                                                        <h5 className="m-b-15 m-t-15">{statistics.movable}</h5>
                                                        <h6 className="m-b-0">Total Movable</h6>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-3">
                                                <div className="card text-center bg-c-blue text-white">
                                                    <div className="card-block">
                                                        <i className='fa fa-suitcase fa-2x text-white'></i>
                                                        <h5 className="m-b-15 m-t-15">{statistics.insured}</h5>
                                                        <h6 className="m-b-0">Total Insured</h6>
                                                    </div>
                                                </div>
                                            </div>


</div>

<div className="row ">




<div className="col-md-12 ">
                <div className="card ">
                    <div className="card-header">
                        <h5>Assets Category </h5>
                    </div>
                    <div className="card-block">

                   
<Bar
          data={assetListStark}
        /> 

                    </div>
                </div>
            </div>



</div>

<div className="row">



  


<div className="col-md-6 ">
<div className="card ">
<div className="card-header">
    <h5>Department Asset</h5>
</div>
<div className="card-block">
<Doughnut data={departmentList} 


/>


</div>
</div>
</div>

<div className="col-md-6 ">
<div className="card feed-card">
<div className="card-header">
    <h5>Asset Status</h5>
</div>
<div className="card-block">

   
<Pie
          data={assetConditionList}
        />  


</div>
</div>
</div>

</div>





                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
                            </>               
 
  )
}

export default AssetsDashboard