import { useEffect, useState} from 'react'
import { COMPANY_CODE, ImagesUrl, ServerUrl, config, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, closeModal, Alerts } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'


import { useQuery } from 'react-query'
import Select from 'react-select'
import { FetchProfile, fetchDepartment} from '../../api/ReactQuery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertDate } from '../../components/GlobalFunction';
import { Link } from 'react-router-dom';

const AttendanceSummary =()=> {
    
    const profile = useQuery(["profile"], FetchProfile)
    const [content, setContent] = useState([] as any);
    const [staff, setStaff] = useState([] as any);
    const [sqlQuery, setSqlQuery] = useState('');
    const department = useQuery(["department"], fetchDepartment)
    const [search, setSearch] = useState({
     
        department:[] as any,
        staff:[] as any,
        start_date:null,
        end_date:null
     })

    
           const [errors, setErrors] = useState({
            start_date:'',
            end_date:''
           
         })

         const fetchStaff =async()=>{
            let url = ServerUrl+'/api/employees/view/'+COMPANY_CODE
            const {data} = await axios.get(url, config)
            setStaff(data.data)
            }
         const handleSelect = (option:any, action:any)=>{
            setSearch({...search, [action.name]:option});
              setErrors({...errors, [action.name]:''})
              setContent([])
    
               }


               const handleChangeDate = (data:any, name:string)=>{
                setSearch({...search, [name]:data});
                setErrors({...errors, [name]:''})
                setContent([])

                if(name==='start_date'){
                   getDaysBetween(data, search.end_date)
                   }else{
                    getDaysBetween(search.start_date, data)
                   } 

             }


        
         

            const getDaysBetween =(startDate:any, endDate:any)=>{
                const start = new Date(startDate)
                const end =  new Date(endDate)
            
                var result = []
                while (start <= end){
                    result.push(new Date(start).toISOString().slice(0,10))
                    start.setDate(start.getDate()+1)
                }
        
               
            let final =   'SELECT e.first_name, e.last_name,  sum(case when a.attendance  = "P" then 1 else 0 end) as P, sum(case when a.attendance  = "A" then 1 else 0 end) as A, sum(case when a.attendance  = "H" then 1 else 0 end) as H  from tbl_employee e, tbl_employee_attendance a where e.code = a.staff_code and e.termination_reason = "" and a.attendance_date >= "'+convertDate(startDate)+'" and a.attendance_date <= "'+convertDate(endDate)+'" '


               
    
                setSqlQuery(final)
            }

    

               

const fetchContent = async()=>{


    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';
              

        if(!search.start_date){
            error.start_date =msg;
            formIsValid = false;
        }  

        if(!search.end_date){
            error.end_date =msg;
            formIsValid = false;
        }  

        if(!formIsValid){
        setErrors(error) 
        }else{
    LoadingModal()


  var final = sqlQuery

    if(search.department.length!==0){
        final = final + " and e.department = '"+search.department.value+"'"
    }

    if(search.staff.length!==0){
        final = final + " and e.code = '"+search.staff.value+"'"
    }
 
    final = final + '  group by a.staff_code, e.first_name, e.last_name order by e.first_name ASC'

          const fd = new FormData();
          fd.append('sql', final); 
          let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
        await  axios.post(url, fd, config).then((result:any)=>{
          if(Array.isArray(result.data.data) && result.data.data.length!==0){
            setContent(result.data.data)
            window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
            }else{
                setContent([]) 
                Alerts('danger',' There are no attendance found for the selected date range')
            }
          })
          .catch((error)=>{
              Alerts('danger',error.message)
          }).finally(()=>{
              closeModal()
          })
        }
          } 


          
          useEffect(()=>{
            fetchStaff() 
          }, [])

  return ( <>
   <Breadcrumb 
title="Attendance Summary"
   summary="Remove any wrongly mark attendance"
   >
    <li className="breadcrumb-item">
    <Link to="/attendance/display_attendance">Attendance</Link>
                    </li>
                    <li className="breadcrumb-item">
    <Link to="#!">Attendance Summary</Link>
                    </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
<div className="main-body">
<div className="page-wrapper">
<div className="page-body">


<div className="row">
<div className=" col-md-12">
<div className="card z-depth-0">
    <div className="card-header">
        <h5>ATTENDANCE SUMMARY</h5>
        <span  ><a href="#"> Employee Attendnace Summary</a></span>
        <div className="card-header-right">

            <ul className="list-unstyled card-option">
                <li><i className="fa fa fa-wrench open-card-option"></i></li>
                <li><i className="fa fa-window-maximize full-card"></i></li>
                <li><i className="fa fa-minus minimize-card"></i></li>
                <li><i className="fa fa-refresh reload-card"></i></li>
                <li><i className="fa fa-trash close-card"></i></li>
            </ul>
            
        </div>
    </div>
    <div className="card-block ">
       


        <div className="row">
        <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Search by Department</label>
    
    
    <Select  options={
            department.data&&department.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.department_name}
                    })}   
                    styles={styles} 
			onChange={handleSelect}    
            name="department" 
            value={search.department} 
               />
    
    </div>
    
    </div>

    <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Select Employee</label>


                 
                <Select 
                options={
                               staff&&staff.map((list:any, idx:number)=> {
                                return {key:idx, value: list.code, label: list.first_name, last_name:list.last_name, middle_name:list.middle_name, title:list.title }
                                })}
                            
                     getOptionLabel={option =>` ${option.label} ${option.last_name}`}
    onChange={handleSelect}  name="staff" value={search.staff} 
   styles={styles}
    /> 
                    </div>
    
                     </div>
    <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Attendance Start Date <span className='text-danger'>*</span></label>
           
            <DatePicker 
            selected={search.start_date}      
             isClearable
             dateFormat="yyyy-MM-dd"
             className={errors.start_date?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}
             placeholderText="Select date"
           
 onChange={(date:any) => handleChangeDate(date, 'start_date')} />


        
{errors.start_date&&
    <div className="col-form-label has-danger">{errors.start_date&&errors.start_date} </div>}
             </div>

             </div>

             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Attendance End Date <span className='text-danger'>*</span></label>
           
            <DatePicker 
            selected={search.end_date}      
             isClearable
             dateFormat="yyyy-MM-dd"
             className={errors.end_date?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}
             placeholderText="Select date"
           
 onChange={(date:any) => handleChangeDate(date, 'end_date')} />


        
{errors.end_date&&
    <div className="col-form-label has-danger">{errors.end_date&&errors.end_date} </div>}
             </div>

             </div>




        </div>


<hr/>
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={fetchContent}>Display Attendnace</button>
</div>
</div>





    </div>
</div>
</div>
</div>






{content.length!==0?
  <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-block">
    

    <div className="row">
        <div className="table-responsive ">
       
        <table className="table table-sm table-bordered table-hover">
                <thead>
                    <tr>
                        <th colSpan={9} className='text-center '>
                        <img 
           src={ImagesUrl+'/about/'+profile.data.logo}  alt="Click to change" 
           className="m-b-10 img-100" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/logo.png'}} />


                        <h4>{profile.data.company_name} </h4>
                        <h5>{profile.data.company_address}</h5>
                        <h6 className='m-b-5'>{profile.data.email}, {profile.data.phone}</h6>
                        <div className="alert alert-info text-center mb-1 mt-2">

                        <h5> EMPLOYEE ATTENDANCE SUMMARY</h5>
                        </div>
                        </th>
                    </tr>
                  

                  <tr>
                        <td colSpan={4}>  <h6>Date Range: { convertDate(search.start_date) + ' - ' +convertDate(search.end_date) } </h6></td>
                        <td colSpan={5}>  <h6>Date Printed: {/* {getBritishDate(new Date()) } */}</h6></td>
                    </tr> 


                    <tr style={{backgroundColor:'#E7E0EE'}}>
                <th >S/N</th>
                    <th className='fixed-column' style={{backgroundColor:'#E7E0EE'}}>Staff Name</th>
                    <th className="vertical-header">Total Attendance</th>
                    <th className="vertical-header">Total Present</th>
                    <th className="vertical-header">Total Half Day</th>
                    <th className="vertical-header">Total Absent</th>
                    <th className="vertical-header">Present (%)</th>
                    <th className="vertical-header">Half Day (%)</th>
                    <th className="vertical-header">Absent (%)</th>
                </tr>

                </thead>
                <tbody>
                    {content&&content.map((st:any, index:number)=>
                    <tr key={index}>
                        <td >{index+1}</td>
                        <td className='fixed-column'>{st.first_name+ ' '+ st.last_name}</td>
                        <td>{Number(st.P) + Number(st.A) + Number(st.H)}</td>
                    <td>{st.P}</td>
                    <td>{st.H}</td>
                    <td>{st.A}</td>
                    <td>{((100*Number(st.P)) / (Number(st.P) + Number(st.A)  + Number(st.H) )).toFixed(2)}</td>
                    <td>{((100*Number(st.H)) / (Number(st.P) + Number(st.A)  + Number(st.H) )).toFixed(2)}</td>
                    <td>{((100*Number(st.A)) / (Number(st.P) + Number(st.A)  + Number(st.H) )).toFixed(2)}</td>
                </tr>)}
                          

                </tbody>

                   
            </table>

        </div>

        <button className="btn btn-primary btn-sm text-right" type='button' ><i className='fa fa-print'></i> Print</button>


        </div>

            </div>
        </div>
    </div>
    </div>:[]}

</div>
</div>


</div>
</div>  
      </>               
 
  )
}

export default AttendanceSummary