import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, CURRENCY, STAFF_CODE, ServerUrl, config, selectStyles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts, CustomLoader } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'

import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { FormatNumber, convertDateTime, getBritishDate, getMonthYear, getNumWorkDays, getTime, toWords } from '../../components/GlobalFunction';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
//https://reactdatepicker.com/

const  Holidays =()=> {
    const [item, setItem] = useState({
      start_date:null,
      end_date:null,
      no_days:0,
      color:'',
      event_type:'Holiday',
      title:'',
      is_notice:'Yes'
    });

   
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        start_date:'',
        end_date:'',
        no_days:'',
        color:'',
        event_type:'',
        title:''
       
     })
    

   
   

           const handleChange = (event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
            setItem({...item, [name]:value});
           setErrors({...errors, [name]:''});
        }


        const handleChangeDate = (data:any, name:string)=>{
           let no:any  = 0;
            
           
           if(name==='start_date'){
            no= getNumWorkDays(data, item.end_date)
            //getDateBetween(data, item.end_date)
           }else{
            no = getNumWorkDays(item.start_date, data)
            //getDateBetween(item.start_date, data)
           }
           setItem({...item, [name]:data, no_days:no});
           setErrors({...errors, [name]:''});
        }

      
        const getDateBetween =(startDate:any, endDate:any)=>{
            let dates:any = [];
            const theDate = new Date(startDate)
            const theEnd =  new Date(endDate)
            while (theDate <= theEnd){

                if (theDate.getDay() !== 0 && theDate.getDay() !== 6) {
                    
                dates = [...dates, new Date(theDate).toISOString().slice(0,10)]
            }
            theDate.setDate(theDate.getDate()+1)
            }
           return JSON.stringify(dates, null, 2) 
    }


const handleReset =()=>{
    setItem({
        start_date:null,
      end_date:null,
      no_days:0,
      color:'',
      event_type:'Holiday',
      title:'',
      is_notice:'Yes'
    })
}




  const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!item.start_date){
    error.start_date =msg;
    formIsValid = false;
} 

if(!item.end_date){
    error.end_date =msg;
    formIsValid = false;
} 

if(!item.title){
    error.title =msg;
    formIsValid = false;
} 
 
if(Number(item.no_days)<=0){
    error.no_days ="You have selected a wrong date range"
    formIsValid = false;
}

if(!formIsValid){
setErrors(error) 
}

 if(formIsValid) {


    LoadingModal()
   
  

    const fd = new FormData();
    let code = 'HD'+Math.random().toString(36).substring(2, 9)
   
    Object.entries(item).forEach(([key, value]) => {
        fd.append(key, String(value));
    });
    fd.append('company_code', COMPANY_CODE);
    fd.append('code', code);
    fd.append('added_by', STAFF_CODE);
    fd.append('date_list', getDateBetween(item.start_date, item.end_date));

let url = ServerUrl+'/api/leave/create_holidays';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                Alerts('danger', response.data.message)
                closeModal()
              }   
          })
          .catch((error)=>{
            Alerts('danger', error.message)
            closeModal()
          }).finally(()=>{
         handleReset()
fetchData()
          }) 
    } 

}



const DeleteModal =(code:string)=>{
    let url = ServerUrl+'/api/leave/delete_holiday/'+code;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =content.filter((item:{code:string})=>item.code !==code)
            setContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }

const fetchData = async()=>{
    setLoading(true)
    let url = ServerUrl+'/api/leave/view_holidays/'+COMPANY_CODE
  await  axios.get(url, config).then((result:any)=>{
    if(Array.isArray(result.data.data) && result.data.data.length!==0){
      setContent(result.data.data)
      }
    })
    .catch((error)=>{
        Alerts('danger', error.message)
    }).finally(()=>{
        setLoading(false)
    })
    } 


    let handleColor = (time:any) => {
        return time.getHours() > 12 ? "text-success" : "text-error";
      };

const TableData =()=>{


    const columns = [
        {
            name: 'SN',
            cell: (row:any, id:number) =>id+1,
            sortable: true,
            width:'70px'
        },
        {
            name: 'Type',
            selector: (row:any) =>row.event_type,
            sortable: true,
            reorder:true,
            wrap:true,
        },
        {
            name: 'Title',
            selector: (row:any) => row.title,
            sortable: true,
            reorder:true,
            wrap:true,
            
        },
        {
            name: 'Start Date',
            cell: (row:any) => <div>{ getBritishDate(row.start_date)}</div>,
            sortable: true,
            reorder:true,
            wrap:true,
        },
        {
            name: 'End Date',
            cell: (row:any) => <div>{ getBritishDate(row.end_date)}</div>,
            sortable: true,
            reorder:true,
            wrap:true,
        },
        {
            name: 'No of Days',
            selector: (row:any) => row.no_days,
            sortable: true,
            reorder:true,
        },
        {
            name: 'Color',
            cell: (row:any) => <div style={{backgroundColor:row.color, width:'100px', height:'50px'}}></div>,
            sortable: true,
            reorder:true,
        },
        {
            name: 'Action',
            cell: (row:any, index:any, column:any, id:any) => <div className='row'>  

               <label className="label label-primary pointer" ><i className='fa fa-edit'></i> Edit</label>

               <label className="label label-danger pointer" onClick={()=>DeleteModal(row.code)}><i className='ti-trash'></i> Delete</label>
            </div>,
                left:true,
                width:'150px'
        },
    ];
    
  

    return <DataTable
    columns={columns}
    data={content}
    dense
    highlightOnHover
    pagination
    selectableRowsHighlight
    striped
    progressPending={loading}
	progressComponent={<CustomLoader />}
/>

}

const getWorkingDays =()=>{

    const wd:any = getNumWorkDays(item.start_date, item.end_date);
    setItem({...item, no_days:wd})
}

    useEffect(()=>{
        fetchData()
      }, [])


    

  return ( <>
   <Breadcrumb 
   title="Holidays & Events"
   summary="Select the valid criteria to create Holidays and Event  "
   >
    <li className="breadcrumb-item">
    <Link to="/leave/leave_list">Leave</Link>
                    </li>
   <li className="breadcrumb-item">
    <Link to="#!">Holidays & Events</Link>
                    </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Setup Holidays</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                              
                                    
                                    
         <div className="content-wrappers ">
                            
         <div className=''>
         <div className="alert alert-info background-info">Employee Will Be Notified Immediately. Please Be Sure Before You Enter The Details !!</div>


    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Add Event or Holiday</h5>
                <span><a href="#!" className='text-info'><i className="fa fa-flash fa-hand"></i> Click Here To Configure Notification Settings</a></span>
              
            </div>
            <div className="card-block">
    
        
                        <div className="row">
                  

                        <div className="col-md-6">
        <h4 className="sub-title">Type</h4>
        <div className="form-radio">
                <div className="radio radio-inline">
                    <label>
                        <input type="radio" name="event_type" value="Event" onChange={handleChange} checked={item.event_type ==='Event'?true:false}  />
                        <i className="helper"></i>Event
                    </label>
                </div>
                <div className="radio radio-inline">
                    <label>
                        <input type="radio" name="event_type" value="Holiday" onChange={handleChange} checked={item.event_type ==='Holiday'?true:false} />
                        <i className="helper"></i>Holiday
                    </label>
                </div>
        </div>
        </div>


        <div className="col-md-6">
        <h4 className="sub-title"> Notification Will Be Sent</h4>
        <div className="form-radio">
                <div className="radio radio-inline">
                    <label>
                        <input type="radio" name="is_notice" value={'Yes'} onChange={handleChange} checked={item.is_notice==='Yes'?true:false}  />
                        <i className="helper"></i>Yes
                    </label>
                </div>
                <div className="radio radio-inline">
                    <label>
                    <input type="radio" name="is_notice" value={'No'} onChange={handleChange} checked={item.is_notice==='Yes'?true:false}  />
                        <i className="helper"></i>No
                    </label>
                </div>
        </div>
        </div>


                   


             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Start Date <span className='text-danger'>*</span></label>
           
          
            <DatePicker 
            selected={item.start_date}      
             isClearable
             dateFormat="yyyy-MM-dd"
             className='form-control form-control-lg '
             placeholderText="Select date"
            withPortal
 onChange={(date:any) =>handleChangeDate(date, 'start_date')}>
 {item.event_type==='Event'?
   <div style={{ color: "red" }}>Don't forget to check the weather!</div>:''}
    </DatePicker>

          

            {errors.start_date&&
    <div className="col-form-label has-danger">{errors.start_date&&errors.start_date} </div>}
             </div>

             </div>


             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">End Date <span className='text-danger'>*</span></label>
           
            <DatePicker 
            selected={item.end_date}      
             isClearable
             dateFormat="yyyy-MM-dd"
             className='form-control form-control-lg'
             placeholderText="Select date"
             withPortal
 onChange={(date:any) => handleChangeDate(date, 'end_date')}>
    {item.event_type==='Event'?
      <div style={{ color: "red" }}>Don't forget to check the weather!</div>:''}
    </DatePicker>
          

            {errors.end_date&&
    <div className="col-form-label has-danger">{errors.end_date&&errors.end_date} </div>}
             </div>

             </div>
                       
             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Title <span className='text-danger'>*</span></label>
           
            <input type="text" className={errors.title?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} onBlur={getWorkingDays}  name='title' value={item.title} onChange={handleChange}  />
          
            {errors.title&&
    <div className="col-form-label has-danger">{errors.title&&errors.title} </div>}
             </div>

             </div>

             {item.event_type==='Event'?
             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Event Color </label>
           
            <input type="color" className={errors.color?"form-control form-control-lg form-control-danger":"form-control form-control-lg "}  name='color' value={item.color} onChange={handleChange}  />
          

             </div>

             </div>:''}

             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">No of Days (Exc. weekends)<span className='text-danger'>*</span></label>
           
            <input type="number" disabled className={errors.no_days?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='no_days' value={item.no_days} onChange={handleChange}  />
          

            {errors.no_days&&
    <div className="col-form-label has-danger">{errors.no_days&&errors.no_days} </div>}
             </div>

             </div>
            


                    </div>



<hr />
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-outline-danger btn-round pr-5 pl-5 mr-3" type='button' onClick={handleReset}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={handleSubmit}>Save</button>
</div>
</div>
    
            </div>
        </div>
    </div>
    </div>
    
    
    
    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Holidays</h5>
                <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
              
            </div>
            <div className="card-block">
    
        <div className="table-responsive ">
        <TableData />
        </div>
    
            </div>
        </div>
    </div>
    </div>
    
                </div>

        </div>
                       
                    </section>
                </div>
            </div>
        </div>
    </div>
                                    </div>
                                </div>
                            </div>





                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default Holidays