import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo} from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { COMPANY_CODE, ServerUrl, config } from '../../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal, CustomLoader } from '../../../components/notify';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
//https://codesandbox.io/s/react-data-table-sandbox-z6gtg?file=/src/index.js:259-487
//https://react-data-table-component.netlify.app/?path=/docs/api-custom-conditional-formatting--page

const Jobs =()=> {
    const [ quill, setQuill ] = useState('');
    const [ title, setTitle ] = useState('');
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        title:'',
        editTitle:'',
       
     })
    const handleChangeQuill = (html:any) =>{		
        setQuill(html);
    }
    const [ item, setItem ] = useState({
        title:'',
        description:'',
        id:'',
        code:''
    });

    const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	
        setTitle(value);
       setErrors({...errors, [name]:''});
     }



const handleReset =()=>{
    setTitle('')
    setQuill('')
}


const TableData =()=>{


    const columns = [
        {
            name: 'Title',
            selector: (row:any) => row.title,
            sortable: true,
        },
        {
            name: 'Description',
            wrap:true,
            cell:(row:any)=><div dangerouslySetInnerHTML={{__html:row.description}}></div>
        },
        {
            name: 'Action',
            cell: (row:any, index:any, column:any, id:any) => <div className='row'>  
                <label className="label label-info pointer" onClick={()=>EditItem(row)} ><i className='ti-pencil'></i> Edit</label>
            <label className="label label-danger pointer" onClick={()=>DeleteModal(row.id)}><i className='ti-trash'></i> Delete</label></div>,
                left:true
                      

        },
    ];
    

    const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const BootyPagination = ({
        rowsPerPage,
        rowCount,
        onChangePage,
        onChangeRowsPerPage, // available but not used here
        currentPage
      }:{rowsPerPage:number, rowCount:number, onChangePage:any, onChangeRowsPerPage:any, currentPage:number}) => {
        const handleBackButtonClick = () => {
          onChangePage(currentPage - 1);
        };
      
        const handleNextButtonClick = () => {
          onChangePage(currentPage + 1);
        };
      
        const handlePageNumber = (e:any) => {
          onChangePage(Number(e.target.value));
        };
      
        function getNumberOfPages(rowCount:number, rowsPerPage:number) {
            return Math.ceil(rowCount / rowsPerPage);
          }
          
          function toPages(pages:number) {
            const results = [];
          
            for (let i = 1; i < pages; i++) {
              results.push(i);
            }
          
            return results;
          }

        const pages = getNumberOfPages(rowCount, rowsPerPage);
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === pageItems.length;
        const previosDisabled = currentPage === 1;
      
        return (
          <nav>
            <ul className="pagination">
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={handleBackButtonClick}
                  disabled={previosDisabled}
                  aria-disabled={previosDisabled}
                  aria-label="previous page"
                >
                  Previous
                </button>
              </li>
              {pageItems.map((page) => {
                const className =
                  page === currentPage ? "page-item active" : "page-item";
      
                return (
                  <li key={page} className={className}>
                    <button
                      className="page-link"
                      onClick={handlePageNumber}
                      value={page}
                    >
                      {page}
                    </button>
                  </li>
                );
              })}
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={handleNextButtonClick}
                  disabled={nextDisabled}
                  aria-disabled={nextDisabled}
                  aria-label="next page"
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        );
      };


    const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

       /*  <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} /> */

		return (
			<input type='text' className='form-control' value={filterText} onChange={(e)=>setFilterText(e.target.value)}  />
		);
	}, [filterText, resetPaginationToggle]);

  

    return <DataTable
    columns={columns}
    data={content}
    dense
    highlightOnHover
    pagination
    selectableRowsHighlight
    striped
    progressPending={loading}
	progressComponent={<CustomLoader />}
/>

}

const handleQuill = (html:any) =>{		
    setItem({...item, description:html});
}

const handleChangeEdit =(event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	
    setItem({...item, title:value})
   setErrors({...errors, [name]:''});
 }

const EditItem = (data:any)=>{
    setItem({
        id:data.id,
        code:data.code,
        title:data.title,
        description:data.description
    });
    
    let click = document.getElementById('openEdit')?.click()

}

  const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!title){
    error.title =msg;
    formIsValid = false;
} 


if(!formIsValid){
setErrors(error) 
}

if(formIsValid) {

    LoadingModal()
   
    const fd = new FormData();
    let code = Math.random().toString(36).substring(2, 9)
    fd.append('company_code', COMPANY_CODE);
    fd.append('code', code);
    fd.append('title', title); 
    fd.append('description', String(quill)); 

let url = ServerUrl+'/api/employees/settings/jobs/add';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                ErrorModal('', response.data.message)
              }   
          })
          .catch((error)=>{
           ErrorModal('oops', error.message)
          }).finally(()=>{
            fetchData()
         handleReset()

          })
    }

}



const handleUpdate =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!item.title){
    error.editTitle =msg;
    formIsValid = false;
} 


if(!formIsValid){
setErrors(error) 
}


if(formIsValid){

     LoadingModal()
   
    const fd = new FormData();
    Object.entries(item).forEach(([key, value]) => {
            fd.append(key, String(value));
        });

let url = ServerUrl+'/api/employees/settings/jobs/update';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                ErrorModal('', response.data.message)
              }   
          })
          .catch((error)=>{
           ErrorModal('oops', error.message)
          }).finally(()=>{
         let click = document.getElementById('closeModal')?.click()
         fetchData()
          }) 
    }

}

const DeleteModal =(id:number)=>{
    let url = ServerUrl+'/api/employees/settings/jobs/'+id;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =content.filter((item:{id:number})=>item.id !==id)
            setContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }

const fetchData = async()=>{
    setLoading(true)
    
    let sql = " "
    const fd = new FormData();
    fd.append('sql', sql); 
    let url = ServerUrl+'/api/employees/settings/jobs/view/'+COMPANY_CODE
  await  axios.get(url, config).then((result:any)=>{
    if(Array.isArray(result.data.data) && result.data.data.length!==0){
      setContent(result.data.data)
      }else{
        setContent([])
      }
    })
    .catch((error)=>{
     // ErrorModal('error', error.message)
    }).finally(()=>{
        setLoading(false)
    })
    } 


    useEffect(()=>{
        fetchData()
      }, [])

  return (  <div className=''>

<label data-toggle="modal" data-target="#editItem" id='openEdit' ></label>

<div className="modal fade " id="editItem" tabIndex={-1} role="dialog" data-backdrop="static" >
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header bg-dark">
                    <h4 className="modal-title text-white">Update Job Roles</h4>
                  
    
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i className='fa fa-ban text-danger'></i>
            </button>
    
                </div>
                <div className="modal-body">
                
     <div className="form-group row">
            <label className="col-sm-2 col-form-label">Job Title</label>
            <div className="col-sm-10">
                <input type="text" className={errors.editTitle?"form-control form-control-danger":"form-control"}  value={item.title} name="editTitle" onChange={handleChangeEdit} />

            <div className="col-form-label has-danger">{errors.editTitle&&errors.editTitle} </div>
            </div>  
            
            </div> 
    
    
            <div className="form-group row">
            <label className="col-sm-2 col-form-label">Job Description</label>
            <div className="col-sm-10">
    
            <div className="mb-3" style={{paddingBottom:'10px'}}>
    
                            <ReactQuill 
                            theme="snow" 
                            value={item.description} 
                            onChange={handleQuill}
                            placeholder={'Write something ..'}
                            />
    
      </div>
    </div>  
            
    
    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" id='closeModal' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-outline-success " onClick={handleUpdate} >Update</button>
                </div>
            </div>
        </div>
    </div>



    <div className="row">
    <div className="col-sm-12">
    <div className="card z-depth-0">
    <div className="card-header">
    <h5>Job Roles</h5>
    <span>Add all Job Title and Description</span>
    
    </div>
    
    <div className="card-block">
    
    <div className="form-group row">
            <label className="col-sm-2 col-form-label">Job Title</label>
            <div className="col-sm-10">
                <input type="text" className={errors.title?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  value={title} name="title" onChange={handleChange} />

            <div className="col-form-label has-danger">{errors.title&&errors.title} </div>
            </div>  
            
            </div> 
    
    
    
            <div className="form-group row">
            <label className="col-sm-2 col-form-label">Job Description</label>
            <div className="col-sm-10">
    
            <div className="mb-3" style={{paddingBottom:'10px'}}>
    
                            <ReactQuill 
                            theme="snow" 
                            value={quill} 
                            onChange={handleChangeQuill}
                            placeholder={'Write something ..'}
                            />
    
                            
                          
      </div>
    </div>  
            
            </div> 
    
    
            <hr />
            <div className="row">
        <div className="col-md-12 text-right">
    
        <button type='button' onClick={handleSubmit} className="btn btn-success btn-round pr-5 pl-5">Save</button>
        </div>
        </div>
    
    </div>
    </div>
    </div>
    </div>
    
    
    
    
    
    
    
    <div className="row">
    <div className="col-sm-12">
    <div className="card z-depth-0">
    <div className="card-header">
    <h5>All Job Roles</h5>
    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
    
    </div>
    <div className="card-block">
    
    <div className="table-responsive m-t-10">

    <TableData />

    </div>
    
    
    </div>
    </div>
    </div>
    </div>
    
    </div>
  )
}

export default Jobs