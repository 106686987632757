import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, STAFF_CODE, ServerUrl, config } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, Alerts } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'

import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
const  BulkIncome =()=> {
   


  const Initials = 
  { 
    income_date:'',
    income_head:'',
    amount:0,
    remark:'' 
  }

     const [criteria, setCriteria] = useState([]);
    const [errors, setErrors] = useState({
      income_date:'',
      income_head:'',
      amount:'',
      incomeList:'',
      errorMessage:''
       
     })
     const [incomeList, setIncomeList]= useState([] as any)
    

     const  GenerateRow =()=>{  
      let value:any = [];
      for (var i = 1; i <= 10; i++) {
        value.push({...Initials,  code:Math.random().toString(36).substr(2, 9)});
      }      
      setIncomeList(value)

  } 

  const createNewRow =()=>{
    setIncomeList(incomeList.concat({...Initials, code:Math.random().toString(36).substr(2, 9)}))
    setErrors({...errors, incomeList:'', errorMessage:''})

}

const removeRow=(index:number)=>{

  if(incomeList.length!==1){
    let item = incomeList.filter((_:any,idx:number)=>idx!==index)
    setIncomeList(item)
    }
   }


   const handleChangeIncome =(event:ChangeEvent<HTMLInputElement>, code:string)=>{
    const {name, value} = event.target;

    const newIncome = incomeList.map((data:any)=>{
        if(data.code === code){
            return {...data, 
                [name]:value
            }
            };
            return data;
    })
    setIncomeList(newIncome)
}

const handleChangeIncomeSelect =(event:ChangeEvent<HTMLSelectElement>, code:string)=>{
  const {name, value} = event.target;

  const newIncome = incomeList.map((data:any)=>{
      if(data.code === code){
          return {...data, 
              [name]:value
          }
          };
          return data;
  })
  setIncomeList(newIncome)
}

   
        const fetchCriteria =async()=>{
    let url = ServerUrl+'/api/finance/income_head/view/'+COMPANY_CODE
          const {data} = await axios.get(url, config)
          setCriteria(data.data)
          }

   



      const handleSubmit =(event: FormEvent<HTMLFormElement>)=>{
        event.preventDefault();
    
    
        LoadingModal()
       
        const fd = new FormData();
        fd.append('added_by', STAFF_CODE);
        fd.append('company_code', COMPANY_CODE);
        fd.append('incomeList',  JSON.stringify(incomeList, null, 2))
    
    let url = ServerUrl+'/api/finance/income/bulk_income';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.status === 200){
         SuccessModal(response.data.message)
       
                  } else{
                    Alerts('danger', response.data.message)
                  }   
              })
              .catch((error)=>{
                Alerts('danger', error.message)
              }).finally(()=>{
                GenerateRow()
    
              })
        
    
    }
    


    useEffect(()=>{
  
      GenerateRow()
        fetchCriteria()
      }, [])


    

  return ( <>
   <Breadcrumb 
   title="Bulk Income"
   summary="Record Bulk Income"
   >
    <li className="breadcrumb-item">
    <Link to="/finance/account_statement">Finance</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Income</a> </li>
         <li className="breadcrumb-item"><a href="#!">Bulk Income</a> </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Record Income</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">

          
<div className="well">You can use this page to add bulk income. Please be careful that you select the right Income Head and Income Account. You can enter as many rows as you like by clicking on Add more.</div>


            <div className="col-md-12">
                   
    
        
{incomeList.length!==0?

<div  className="card z-depth-0" >
		<div  className="card-block">
        <div className="row">
       
     
        <div className="table-responsive">
        <form action="#" onSubmit={handleSubmit}>
        <table className="table">
            <thead>
                <tr style={{background:'#404E67', color:'#fff'}}>
                <th>SNo</th>
                    <th>Income Type</th>
                    <th>Amount</th>
                    <th>Date of Income</th>  
                    <th>Remark</th> 
                    
    <th ><a onClick={createNewRow} href="#!" style={{color:'#fff'}}><i className="fa fa-plus"></i> Add More</a></th>
                </tr>
            </thead>
            <tbody>

            
                {incomeList&&incomeList.map((pl:any, id:number)=>
            <tr key={id}>
                
                    <td style={{backgroundColor:'#d2d6de'}}>{id+1}</td>
                    <td > 
                        <select onChange={(e)=>handleChangeIncomeSelect(e, pl.code)} required name="income_head"  value={pl.income_head} className="form-control">

<option value="">Select</option>
{ criteria&&criteria.map((list:any, idx:number)=> {
           return  <option key={idx} value={list.code} >{list.title}</option> })}
    </select>
                     </td>

                    <td><input type="number" pattern="\d*" required className="form-control" name='amount' value={pl.amount}  onChange={(e)=>handleChangeIncome(e, pl.code)} /></td>

                  

                      <td>
                          
                          <input type="date" required className="form-control" name='income_date' autoComplete="false" value={pl.income_date}  onChange={(e)=>handleChangeIncome(e, pl.code)} />
                      
                      </td>

                      <td>
                      <input type="text" className="form-control" name='remark' autoComplete="false" value={pl.remark} onChange={(e)=>handleChangeIncome(e, pl.code)} />
                      
                      </td>
                   
                    <td > <button type="button" onClick={()=>removeRow(id)} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></button></td>
                </tr>)}
               
            </tbody> 
            <tfoot>
                <tr >
                    <td colSpan={9}>

                    
                <div className="pull-right">
	
	<button type="submit" id="submit"   className="btn btn-success"><i className="fa fa-save"></i> Submit </button>
								</div>
                                </td>
                </tr>
            </tfoot>
           
        </table>
        </form>
        </div>
       
        </div>
        </div>
</div>:''}
                       

    
    </div>
</div>
</div>
                            </div>
                        </div>
                    </div>
      </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default BulkIncome