import React, { useCallback, useEffect, useState, ChangeEvent} from 'react'
import { COMPANY_CODE, ImagesUrl, STAFF_CODE, ServerUrl, config, configDownload, styles } from '../../components/Includes';
import axios from 'axios';
import { CustomLoader, Alerts } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'
import { useQuery } from 'react-query'

import { usePaginated, DOTS } from '../../components/usePagination';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { fetchDepartment, fetchJobs, takeScreenshot } from '../../api/ReactQuery';
import { Link } from 'react-router-dom';
import { WORK_STATUS } from '../../components/Data';

import { useSelector } from 'react-redux'
import {ViewEmployeeDocument, DownloadEmployee} from './EmployeePDF';
import { PDFDownloadLink, renderToFile } from '@react-pdf/renderer';
const Employees =()=> {

    const [director, setDirector] = useState({
       
        isName:false,
        isWorkPhone:false,
        isEmail:false,
        isEmployeeID:false,
        isDepartment:false,
        isJobTitle:false,
        isEmployment:false,
        isFileNumber:false,
        isAccount:false,
        isAction:false,
        isPhoto:true
    });
    
  const group  = useSelector((state:any) => state.rootReducer.group);
    const department = useQuery(["department"], fetchDepartment)
    const [loading, setLoading] = useState(false);
    const [filterContent, setFilterContent] = useState([] as any);
    const [content, setContent] = useState([] as any);
    const Jobs = useQuery(["jobs"], fetchJobs)


    const [search, setSearch] = useState({
       
        gender:{value:'', label:''},
        includes:{value:'', label:''},
        file_number:'',
        work_station:'',
        job_title:{value:'', label:''},
        department:{value:'', label:''},
        first_name:'',
        confirmation_date:'',
        employment_status:{value:'', label:''},
        account_status:{value:'', label:''},
        rowperpage:{value:'10', label:'10'},
        filterText:'',
        selectAll:false,
        totalSelected:0,
     })


     const handleSelect = (option:any, action:any)=>{
        setSearch({...search, [action.name]:option});

           }
const handleClear =()=>{
    setSearch({...search, filterText:''});
    setFilterContent(content)
}

const handleResetColumn =()=>{
    setDirector({
       
        isName:false,
        isWorkPhone:false,
        isEmail:false,
        isEmployeeID:false,
        isDepartment:false,
        isJobTitle:false,
        isEmployment:false,
        isFileNumber:false,
        isAccount:false,
        isAction:false,
        isPhoto:true
    })
}


const handleResetSearch =()=>{

    setSearch({
       
        gender:{value:'', label:''},
        includes:{value:'', label:''},
        file_number:'',
        work_station:'',
        job_title:{value:'', label:''},
        department:{value:'', label:''},
        first_name:'',
        confirmation_date:'',
        employment_status:{value:'', label:''},
        account_status:{value:'', label:''},
        rowperpage:{value:'10', label:'10'},
        filterText:'',
        selectAll:false,
        totalSelected:0,
     })
}

           const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
            setSearch({...search, [name]:value});
         }

         const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
            setSearch({...search, [name]:value});

            if(value!==''){
                const filteredItems = content.filter(
                    (item:any) => item.first_name.toLowerCase().includes(value.toLowerCase()) || 
                    item.last_name.toLowerCase().includes(value.toLowerCase()) ||
                    item.work_email.toLowerCase().includes(value.toLowerCase()) ||
                    item.title.toLowerCase().includes(value.toLowerCase()) ||
                    item.gender.toLowerCase().includes(value.toLowerCase()) ||
                    item.mobile_phone.toLowerCase().includes(value.toLowerCase()),
                );

                setFilterContent(filteredItems)
            }else{
                setFilterContent(content)
            } 

         }

const  handleChangeStatus=(row:any)=>{


    const fd = new FormData();
   let status = row.account_status ==='Enabled'?'Disabled':'Enabled'

        const log = JSON.parse(row.update_log)
        const item = log.concat( {
            code:STAFF_CODE,
            action:'Updated account status',
            date_time: new Date()
        })


const logs = JSON.stringify(item,null, 2)

        fd.append('logs', logs);
       fd.append('company_code', row.company_code);
       fd.append('code', row.code);
       fd.append('gender', row.gender);
       fd.append('last_name', row.last_name);
       fd.append('first_name', row.first_name);
       fd.append('account_status', status);
       fd.append('id', row.id);
        

    let url = ServerUrl+'/api/employee/update/'+row.code;
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     
        const newContent = filterContent.map((item:any)=>{
   
            if(item.code === row.code){
            return {...item, 
                account_status:status,
            }
            };
            return item;
            })
            setContent(newContent)
            setFilterContent(newContent)

   
              } else{
                Alerts('danger',response.data.message)
              }   
          })
          .catch((error)=>{
            Alerts('danger',error.message)
          })  
}


const handleChangeEnable =()=>{


    if(search.totalSelected<1){
        Alerts('danger','Please select at least one record to enable ')
      }else{
    
        let selectedEmployee = filterContent.filter((item:any)=>item.is_check ==='true')
    
        let status = search.selectAll ===false?'Enabled':'Disabled';

        const newContent = selectedEmployee.map((item:any)=>{
            const log = JSON.parse(item.update_log)
            const items = log.concat({
                code:STAFF_CODE,
                action:'Updated account status',
                date_time: new Date()
            })
    
    const logs = JSON.stringify(items,null, 2)
    
            return {...item, 
                account_status:'Enabled',
                update_log:logs
            }
      })
    
        const fd = new FormData();
        fd.append('employeeList', JSON.stringify(newContent, null, 2));
        fd.append('company_code', COMPANY_CODE);
    
    let url = ServerUrl+'/api/employees/account_status/'+COMPANY_CODE;
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     
      window.location.reload()
            //setContent(newContent)
            //setFilterContent(newContent)

              } else{
                Alerts('danger',response.data.message)
              }   
          })
          .catch((error)=>{
            Alerts('danger',error.message)
          })  
        }
}

const DeleteModal =(row:any)=>{

    let url = ServerUrl+'/api/employee/delete/'+row.id+'/'+row.code;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =filterContent.filter((item:{id:number})=>item.id !==row.id)
            setContent(rs)
            setFilterContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }


  const handleSelectAll =(list:[])=>{
      
    let is_check = list.every((element:any) => {
     return element.is_check === 'true'
    });
    var i = 0;
        list.forEach((element:any) => {
          if(element.is_check === 'true')
         i++
        });
    setSearch({...search, selectAll:is_check, totalSelected:i})
    
       }


const handleCheck =(code:string)=>{
const newContent = filterContent.map((item:any)=>{
   
if(item.code === code){
return {...item, 
    is_check:item.is_check==='false'?'true':'false',
}
};
return item;
})
setFilterContent(newContent)
handleSelectAll(newContent)
}


const handleSelectChange =()=>{
    let status = search.selectAll ===false?'true':'false';
    const newContent = filterContent.map((item:any)=>{
        return {...item, 
            is_check:status,
        }
  })

  var i = 0;
  newContent.forEach((element:any) => {
      if(element.is_check=== 'true')
     i++
    });

    setFilterContent(newContent)

    setSearch({...search, selectAll:!search.selectAll, totalSelected:i})

   }



        const TableData =()=>{

            
          
        

const PaginationWrapper =(props:any)=>{

    const handleRowsPerPage = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
        [props.currentPage, props.onChangeRowsPerPage],
    );
    const handleSelectRowsPerPage = useCallback((option:any, action:any)=> props.onChangeRowsPerPage(Number(option.value), props.currentPage),
    [props.currentPage, props.onChangeRowsPerPage],
    )
   
    function getNumberOfPages(rowCount:number, rowsPerPage:number) {
        return Math.ceil(rowCount / rowsPerPage);
      }
    const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
    const lastIndex = props.currentPage * props.rowsPerPage;
    const firstIndex = lastIndex - props.rowsPerPage + 1;


        const range =
        props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:

        `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`

    
   
    const Pagination = () => {
       
        const pageSize = props.rowsPerPage;
        const totalCount = content.length
        const siblingCount = 1
        const currentPage = props.currentPage
        
        const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
        
        if (currentPage === 0 ) {
          return null;
        }
        
        let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
        return (
        <nav className="" aria-label="Page navigation sample ">
          <ul  className="pagination pagination-sm"  >
            <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
             <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Previous</a>
            </li>
            {paginationRange&&paginationRange.map((pageNumber, index) => {
              if (pageNumber === DOTS) {
                return <li className="pagination-item dots" key={index}>
                 <a className="page-link pointer"  >&#8230;</a>
                </li>
              }
              return (
                <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                  <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                  
                </li>
              );
            })}
        
        <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
        <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
            </li>
        
          </ul> </nav>
        );
        
            }

          

          

 return <div className="row mt-3">
                    
<div className="col-md-5 ">
<div className="row ml-3" >

   Rows per page: &nbsp; <select name="rowPerPage" id="" className='w-40 h-30 ' onChange={handleRowsPerPage}>
        <option value="10"> 10</option>
        <option value="20"> 20</option>
        <option value="30"> 30</option>
        <option value="40"> 40</option>
        <option value="50"> 50</option>
        <option value={content.length}> All</option>
    </select> &nbsp;&nbsp;
     {range}  </div>
</div>
  
<div className="col-md-7  justify-content-end d-flex">
   <Pagination  />
    </div>
    
</div>}



const CheckboxAll =()=>{ 

    return <div className="chk-option">
    <div className="checkbox-fade fade-in-primary">
        <label className="check-task">
    
        <input type="checkbox"  id="selectAll" checked={search.selectAll} onChange={handleSelectChange} />
                    
        <span className="cr">
            <i className="cr-icon fa fa-check txt-default"></i>
        </span>
        </label>
    </div>
    </div>}


const Checkbox =({code, is_check}:{code:string, is_check:string})=>{ 

return <div className="chk-option">
<div className="checkbox-fade fade-in-primary">
    <label className="check-task">

    <input type="checkbox"  name={code} id={code} checked={is_check==='true'?true:false} onChange={()=>handleCheck(code)} />
                
    <span className="cr">
        <i className="cr-icon fa fa-check txt-default"></i>
    </span>
    </label>
</div>
</div>}



            const columns = [

                {
                    name: <CheckboxAll />,
                    cell: (row:any) => <Checkbox code={row.code} is_check={row.is_check} />, 
                    width:'60px'
                },
                 {
                    name: 'Photo',
                    cell: (row:any) => <div className="d-inline-block align-middle">
                    <img src={ImagesUrl+'/employees/passport/'+row.passport} alt="user image" className=" img-40" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+row.gender+'.png'}} />
                </div>, 
                    sortable: true,
                    reorder:true,
                    omit: director.isPhoto,
                    width:'100px'
                }, 
                {
                  name: 'Name',
                  cell: (row:any) => row.title + ' '+ row.first_name + ' ' + row.last_name, 
                  sortable: true,
                  reorder:true,
                  omit: director.isName,

                  width:'200px'
              },
                {
                    name: 'Mobile Phone',
                    selector: (row:any) =>row.mobile_phone,
                    sortable: true,
                    reorder:true,
                    omit: director.isWorkPhone,
                    width:'150px'
                },
                {
                    name: 'Email Address',
                    selector: (row:any) => row.work_email,
                    sortable: true,
                    reorder:true,
                    omit: director.isEmail,
                    wrap:true,
                },
                {
                    name: 'Department',
                    selector: (row:any) => row.department_name,
                    sortable: true,
                    reorder:true,
                    omit: director.isDepartment,
                    wrap:true,
                },
                {
                    name: 'Job Title',
                    selector: (row:any) => row.job_title,
                    sortable: true,
                    reorder:true,
                    omit: director.isJobTitle,
                    wrap:true,
                },
                {
                    name: 'Employment Status',
                    selector: (row:any) => row.employment_status,
                    sortable: true,
                    reorder:true,
                    omit: director.isEmployment,
                    wrap:true,
                },
                {
                    name: 'Is Enable',
                    cell: (row:any) => <label className="switch-md">
                    <input type="checkbox" checked={row.account_status==='Enabled'?true:false} onChange={()=>handleChangeStatus(row)}   />
                    <span className="round slider-md"></span>
                  </label>,
                  omit: director.isAccount,
                  width:'100px'
                },
                {
                    name: 'Action',
                    cell: (row:any, index:any, column:any, id:any) => <div className='row'>  
                       
                       {group.allowEdit==='Yes'?   <a href={"/employee/profile/"+row.code} className="label label-info pointer"   ><i className='ti-pencil'></i> Modify</a>:[]}


                  {group.allowDelete==='Yes'?  <label className="label label-danger pointer" onClick={()=>DeleteModal(row)}><i className='ti-trash'></i> Delete</label>:[]} 
                    
                    </div>,
                        left:true,
                        omit: group.allowDelete==='Yes'&& group.allowEdit==='Yes'?director.isAction:true,
                        width:'150px'
                              
    
                },
            ];
            
        
        
        
            return <>
            

            <DataTable
            columns={columns}
            data={filterContent}
            highlightOnHover
            pagination
            paginationComponent={PaginationWrapper}
            selectableRowsHighlight
            striped
            responsive
            fixedHeader
            progressPending={loading}
            progressComponent={<CustomLoader />}
        />
        </>
        }




        const handleDeleteAll =()=>{

            if(search.totalSelected<1){
              Alerts('danger','Please select at least one record to delete ')
            }else{
          
              let employeeList =[] as any;
              let selectedEmployee = filterContent.filter((item:any)=>item.is_check ==='true')
          
              if(Array.isArray(selectedEmployee)){
                selectedEmployee.forEach((list:any) => {
                    employeeList.push(list.code)
                });
              }
          
              const fd = new FormData();
              fd.append('employeeList', JSON.stringify(employeeList, null, 2));
              let url = ServerUrl+'/api/employees/delete_all/'+COMPANY_CODE;
              Swal.fire({
                title: 'Are you really sure?',
                text: "This is very dangerous, you shouldn't do it!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                showLoaderOnConfirm: true,
              preConfirm: async() => {
                return await axios.post(url, fd, config).then(response=>{
                 if(response.data.status!==200){
                      throw new Error(response.statusText)
                    }
                    return response.data
                  })
                  .catch(error => {
                    Swal.showValidationMessage(
                      `Request failed: ${error}`
                    )
                  })
              },
              allowOutsideClick: () => !Swal.isLoading(),
                confirmButtonText: 'Yes, I am!'
              }).then((result:any) => {
                if (result.isConfirmed && result.value.status===200) {
          
                  let rs =filterContent.filter((item:any)=>item.is_check ==='false')
                 setContent(rs)
                 setFilterContent(rs)
                   Swal.fire(
                              'Deleted!',
                              'Record has been deleted.',
                              'success'
                            ) 
                }
              })
          
            }
          
           }

           const sqlQuery = ()=>{

            let sql = "SELECT  e.id, e.code, IFNULL(e.title, '') as title, e.first_name,  IFNULL(e.middle_name, '') as middle_name, e.last_name, e.gender, e.mobile_phone, e.work_email, d.department_name, j.title as job_title, e.employment_status, e.account_status, e.passport, e.update_log,  'false' as is_check from tbl_employee e left join tbl_job_title j on j.code = e.job_title  left JOIN tbl_departments d on d.code = e.department WHERE e.id <> '' "


            if(search.gender.value!==''){
                sql = sql + " and e.gender = '"+search.gender.value+"'"
            }

            if(search.employment_status.value!==''){
                sql = sql + " and e.employment_status = '"+search.employment_status.value+"'"
            }

            if(search.file_number!==''){
                sql = sql + " and e.file_number = '"+search.file_number+"'"
            }

            if(search.first_name!==''){
                sql = sql + " and e.first_name Like  '%"+search.first_name+"%' OR  e.last_name Like  '%"+search.first_name+"%'"
            }

            if(search.department.value!==''){
                sql = sql + " and e.department = '"+search.department.value+"'"
            }

            if(search.job_title.value!==''){
                sql = sql + " and e.job_title = '"+search.job_title.value+"'"
            }

            if(search.confirmation_date!==''){
                sql = sql + " and e.confirmation_date = '"+search.confirmation_date+"'"
            }

            if(search.account_status.value!==''){
                sql = sql + " and e.account_status = '"+search.account_status.value+"'"
            }


            if(search.includes.value!==''){

                if(search.includes.value==='Current'){
                    sql = sql + " and e.termination_reason = ''"
                }else if(search.includes.value==='Past'){
                    sql = sql + " and e.termination_reason <> ''"
                }
               
            }
            
            return sql
           }

         const fetchContent = async()=>{
       setLoading(true)

                let sql = sqlQuery()

            const fd = new FormData();
            fd.append('sql', sql); 
            let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
          await  axios.post(url, fd, config).then((result:any)=>{
            if(Array.isArray(result.data.data) && result.data.data.length!==0){
                setFilterContent(result.data.data)
              setContent(result.data.data)
              }
            })
            .catch((error)=>{
                Alerts('danger',error.message)
            }).finally(()=>{
                setLoading(false)
            })
            } 
  

            const handleDownload = async(extension:string)=>{
             
              let sql = sqlQuery()
          const fd = new FormData();
          fd.append('sql', sql); 
          fd.append('extension', extension); 
          let url = ServerUrl+'/api/download_file/employee'
          await  axios.post(url, fd, configDownload).then((response:any)=>{
           const url = window.URL.createObjectURL(new Blob([response.data]));
             
               const link = document.createElement('a');
               link.href = url;
               link.download = '_employee.'+extension
               link.click();
               })
          }


useEffect(()=>{
    fetchContent()
}, [])

  return ( <>
   <Breadcrumb 
   title="Employee"
   summary="You can use the criteria below to filter record."
   >
   <li className="breadcrumb-item">
    <Link to="#!">Employees</Link>
                    </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
<div className="main-body">
<div className="page-wrapper">
<div className="page-body">




<div className="row">
<div className=" col-md-12">
<div className="card z-depth-0">
    <div className="card-header">
        <h5>Search Employees</h5>
        <div className="card-header-right">

            <ul className="list-unstyled card-option">
                <li><i className="fa fa fa-wrench open-card-option"></i></li>
                <li><i className="fa fa-window-maximize full-card"></i></li>
                <li><i className="fa fa-minus minimize-card"></i></li>
                <li><i className="fa fa-refresh reload-card"></i></li>
                <li><i className="fa fa-trash close-card"></i></li>
            </ul>
            
        </div>
    </div>
    <div className="card-block ">
       


        <div className="row">
            
    <div className="col-md-4">
    <div className="form-group">
    <label className="col-form-label">Search by Gender</label>
    
  <Select  options={[
                    { value: 'Male', label: 'Male' },
                    { value: 'Female', label: 'Female'}
                                ]} 
                onChange={handleSelect}  
                name="gender"
                 value={search.gender} 
                 styles={styles} 
                 
                 />


    </div>
    
    </div>
    
    <div className="col-md-4">
    <div className="form-group">
    <label className="col-form-label">Search by Employment Status</label>
    
    
<Select  
options={
    WORK_STATUS.map((list:any, id:number)=>{
        return {key:id, value:list, label:list}
            })} 
                              
                                onChange={handleSelect}  name="employment_status"
                        value={search.employment_status} 
                        styles={styles} 
                 />
    
    </div>
    
    </div>
    
    
    <div className="col-md-4">
    <div className="form-group">
    <label className="col-form-label">Search By Employee File Number</label>
    <input type="text" className="form-control form-control-lg" name='file_number' value={search.file_number} onChange={handleChange} />
    </div>
    
    </div>
    
    <div className="col-md-4">
    <div className="form-group">
    <label className="col-form-label">Search by Employee Name</label>
    
    <input type="text" className="form-control form-control-lg" name='first_name' value={search.first_name} onChange={handleChange} />
    
    </div>
    
    </div>
    

    <div className="col-md-4">
    <div className="form-group">
    <label className="col-form-label">Search by Department</label>
    
    
    <Select  options={
            department.data&&department.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.department_name}
                    })}   
                    styles={styles} 
			onChange={handleSelect}    
            name="department" 
            value={search.department} 
               />
    
    </div>
    
    </div>


    
    <div className="col-md-4">
    <div className="form-group">
    <label className="col-form-label">Search by Job Title</label>
    
    
    <Select options={
            Jobs.data&&Jobs.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.title}
                    })}   
                  
			onChange={handleSelect} 
            styles={styles} 
            name="job_title" value={search.job_title} 
              
              />
    
    </div>
    
    </div>

    <div className="col-md-4">
    <div className="form-group">
    <label className="col-form-label">Search by Confirmation Date</label>
    
    <input type="date" className="form-control form-control-lg" name='confirmation_date' value={search.confirmation_date} onChange={handleChange} />
    
    </div>
    
    </div>

    <div className="col-md-4">
    <div className="form-group">
    <label className="col-form-label">Search by Account Status</label>
    
    <Select  options={[
                    { value: 'Enabled', label: 'Active' },
                    { value: 'Disabled', label: 'Inactive'}
                                ]} 
                onChange={handleSelect}  
                name="account_status"
                 value={search.account_status} 
                 styles={styles} 
                 
                 />
    
    </div>
    
    </div>
           
    <div className="col-md-4">
    <div className="form-group">
    <label className="col-form-label">Includes</label>
    
    
  <Select  options={[
                    { value: 'Current', label: 'Current Employee Only' },
                    { value: 'Past', label: 'Past Employee Only'},
                    { value: 'All', label: 'All Employee'}
                                ]} 
                onChange={handleSelect}  
                name="includes"
                 value={search.includes} 
                 styles={styles} 
                 
                 />
    
    </div>
    
    </div>

        </div>


<hr/>
<div className="row">
<div className="col-md-12 ">

<button className="btn btn-outline-danger btn-round pr-5 pl-5 mr-3" type='button' onClick={handleResetSearch}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={fetchContent} >Search</button>
</div>
</div>



    </div>
</div>
</div>
</div>







<div className="row" >
<div className=" col-md-12">
<div className="card z-depth-0">


    <div className="card-header  ">
        <h5>Employee List</h5>
        <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
        <div className="card-header-right">

       

        <div className="dropdown-info dropdown">
        <button type="button" className="btn btn-success active dropdown-toggle dropdown-icon btn-sm " data-toggle="dropdown"><i className='fas fa-eye text-white'></i> Export</button>
                    <div className="dropdown-menu" aria-labelledby="dropdown-4" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut" x-placement="bottom-start" >


                    <PDFDownloadLink document={<DownloadEmployee data={filterContent} />} fileName="employee_record.pdf"
                    className="dropdown-item waves-light waves-effect" 
                    >
                  {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <span  ><i className="fas fa-file-pdf "></i> Export as PDF</span>)}
                </PDFDownloadLink>


                        <a className="dropdown-item waves-light waves-effect" href="#!" onClick={()=>handleDownload('xlsx')}><i className="fas fa-file-excel "></i> Export as Excel</a>

                        <a className="dropdown-item waves-light waves-effect" href="#!" onClick={()=>takeScreenshot('takeshot')}><i className="fas fa-image "></i> Export as Image</a>
                        <a className="dropdown-item waves-light waves-effect" href="#!" data-toggle="modal" data-target="#viewpdf"><i className="fas fa-print "></i> Print Preview</a>
                    </div>
                </div>&nbsp;


        <a href='/employees/add' className="btn btn-primary btn-sm "><i className='fa fa-circle-plus text-white'></i> Add New</a>
        </div>   
    </div>
    <div className="card-block">


    <div className="row">

<div className=" col-md-8 ">


                  &nbsp;

  
    <button type="button" className="btn btn-info  btn-sm" onClick={handleChangeEnable} ><i className="fas fa-check"></i> Enable Selected ({search.totalSelected})</button>&nbsp;
  
    {group.allowDelete==='Yes'?<button type="button" className="btn btn-outline-danger btn-sm " onClick={handleDeleteAll} ><i className="fas fa-trash"></i> Delete Selected ({search.totalSelected})</button>:[]}
  
  &nbsp;
  <div className="btn-group ">
                   
                    <button type="button" className="btn btn-inverse dropdown-toggle dropdown-icon btn-sm" data-toggle="dropdown">
                      <span className="sr-only">Toggle Dropdown</span> <i className='fas fa-fw fa-filter'></i> Visibility &nbsp;
                    </button>
                    <div className="dropdown-menu" role="menu">

                    <a className={director.isPhoto?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isPhoto:!director.isPhoto})}> Photo</a>

                      <a className={!director.isName?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isName:!director.isName})}>Employee Name </a>

                      <a className={!director.isWorkPhone?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isWorkPhone:!director.isWorkPhone})}>Mobile Phone</a>

                      <a className={!director.isEmail?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isEmail:!director.isEmail})}>Email Address</a>


                      <a className={!director.isDepartment?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isDepartment:!director.isDepartment})}>Department</a>

                      <a className={!director.isJobTitle?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isJobTitle:!director.isJobTitle})}>Job Title</a>

                      <a className={!director.isEmployment?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isEmployment:!director.isEmployment})}>Employment Status</a>


                      <a className={!director.isAccount?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isAccount:!director.isAccount})}>Account</a>

                      <a className={!director.isAction?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isAction:!director.isAction})}>Action</a>

                    <div className="dropdown-divider"></div>
                      <a className={"dropdown-item"} href="#!" onClick={handleResetColumn}>Reset Column</a>
                   
                    </div>
                  </div>
                  </div>

    <div className=" col-md-4 ">
    <div className="input-group  " >
                    <input type="text" name="filterText" id="filterText" key="filterText"  onChange={handleSearch} value={search.filterText} className="form-control float-right form-control-sm" placeholder="Type to search" />
                   
                    <div className="input-group-append">
                        <button type="button" onClick={handleClear} className="btn btn-default btn-sm" >
                          <i className="fas fa-close"></i>
                        </button>
                      </div>
                  </div>
      

    </div>
</div>

<div className="row" id="takeshot">


        <div className="col-md-12">

<TableData />
      
        </div></div>
    </div>
</div>
</div>
</div>






<div className="modal fade " id="viewpdf" tabIndex={-1} role="dialog" data-backdrop="static" >
       <div className="modal-dialog modal-xl" role="document">
           <div className="modal-content">
               <div className="modal-header bg-dark">
                   <h4 className="modal-title text-white">Print Preview</h4>
                 

           <button type="button" className="close" data-dismiss="modal" aria-label="Close">
           <i className='fa fa-ban text-danger'></i>
           </button>

               </div>
               <div className="modal-body">
               <div className="row">

               <ViewEmployeeDocument data={filterContent} />


              </div>
               </div>
               <div className="modal-footer">
                   <button type="button" className="btn btn-default waves-effect " data-dismiss="modal" id="closeEdit">Close</button>
               </div>
           </div>
       </div>
   </div>


</div>
</div>


</div>
</div>  
        </>               
 
  )
}

export default Employees