import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo} from 'react'
import { COMPANY_CODE, ImagesUrl, STAFF_CODE, ServerUrl, config, selectStyles } from '../../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal, CustomLoader, closeModal } from '../../../components/notify';
import Select, { components } from 'react-select'
import { fetchEmployee, fetchCountry, handleFetchState } from '../../../api/ReactQuery';
import { useQuery } from 'react-query'
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'

const Family =({code}:{code:any})=>{


    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState([] as any);
    const [contact, setContact] = useState([] as any);
    const {data} = useQuery(["employee", code], fetchEmployee)

    const [errors, setErrors] = useState({
        full_name:'',
        relationship:''
     })
    const [profile, setProfile] = useState({
        full_name:'',
        relationship:'',
        nin:'',
        code:Math.random().toString(36).substring(2, 9),
        date: new Date()
    });

    const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	
        setProfile({...profile, [name]:value});
       setErrors({...errors, [name]:''});
     }


     const handleSelect = (option:any, action:any)=>{

        setProfile({...profile, [action.name]:option.value});
       setErrors({...errors, [action.name]:''});
           }

   
        const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
            event.preventDefault();
        
            let error = {...errors}; 
            let formIsValid = true;
        
        let msg ='This field is required';
        

        if(!profile.full_name){
            error.full_name =msg;
            formIsValid = false;
        } 
        
    
        
        if(profile.relationship.length===0){
            error.relationship =msg;
            formIsValid = false;
        } 

        if(!formIsValid){
        setErrors(error) 
        }
        
        
        if(formIsValid) {
           LoadingModal()

                const family_contact =  JSON.parse(content[0].family)
                const contacts = family_contact.concat(profile)

                const log = JSON.parse(content[0].update_log)
                const item = log.concat( {
                    code:STAFF_CODE,
                    action:'Added one family ',
                    date_time: new Date()
                })
    
             const logs = JSON.stringify(item,null, 2)


                const newProfile = content.map((item:any)=>{
                const famil = JSON.stringify(contacts,null, 2)


                    return {...item, 
                        family:famil
                    }
                  })
                  
                  setContent(newProfile)
                  setContact(contacts)

                      const fd = new FormData();
               fd.append('logs', logs);
                Object.entries(newProfile[0]).forEach(([key, value]) => {
                                    fd.append(key, String(value));
                                }); 

        let click = document.getElementById('close')?.click();

           let url = ServerUrl+'/api/employee/update/'+code;
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status === 200){
             SuccessModal(response.data.message)
           
                      } else{
                        ErrorModal('', response.data.message)
                      }   
                  })
                  .catch((error)=>{
                   ErrorModal('oops', error.message)
                  }).finally(()=>{

                    setProfile({
                        full_name:'',
                        relationship:'',
                       nin:'',
                        code:Math.random().toString(36).substring(2, 9),
                        date: new Date()
                    })

                  })  
            }
        
        }



const DeleteModal =(code:string)=>{
    
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading(),
    confirmButtonText: 'Yes, I am!',

    preConfirm: async() => {

const newContact = contact.filter((item:any)=>item.code !==code)



const log = JSON.parse(content[0].update_log)
const item = log.concat( {
    code:STAFF_CODE,
    action:'Removed one  family',
    date_time: new Date()
})

const logs = JSON.stringify(item,null, 2)
const newProfile = content.map((item:any)=>{
    return {...item, 
        family:JSON.stringify(newContact,null, 2)
    }
  })
  
  setContent(newProfile)
  setContact(newContact)

      const fd = new FormData();
fd.append('logs', logs);
Object.entries(newProfile[0]).forEach(([key, value]) => {
                    fd.append(key, String(value));
                }); 

let url = ServerUrl+'/api/employee/update/'+code;
return await axios.post(url, fd, config)
.then(response =>{

if(response.data.status !== 200){
    throw new Error(response.statusText)

      } else{
        return response.data
      }   
  })
  .catch((error)=>{
    Swal.showValidationMessage(
        `Request failed: ${error}`
      )
  })
    }
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }






        const TableData =()=>{

        
            const columns = [
                {
                    name: 'Fullname',
                    cell: (row:any) => row.full_name, 
                    sortable: true,
                },
                {
                    name: 'Relationship',
                    selector: (row:any) => row.relationship,
                    sortable: true,
                },
                {
                    name: 'NIN',
                    selector: (row:any) => row.nin,
                    sortable: true,
                },
                
                {
                    name: 'Action',
                    cell: (row:any, index:any, column:any, id:any) => <div className='row'>  
                        <label className="label label-info pointer"  onClick={()=>EditItem(row)} ><i className='ti-pencil'></i> Edit</label>
                    <label className="label label-danger pointer" onClick={()=>DeleteModal(row.code)}><i className='ti-trash'></i> Delete</label></div>,
                        left:true
                              
        
                },
            ];
            
        
          
        
            return <DataTable
            columns={columns}
            data={contact}
            dense
            highlightOnHover
            pagination
            selectableRowsHighlight
            striped
            progressPending={loading}
            progressComponent={<CustomLoader />}
        />
        
        }


        const handleUpdate =(event: FormEvent<HTMLButtonElement>)=>{
            event.preventDefault();
        
            let error = {...errors}; 
            let formIsValid = true;
        
        let msg ='This field is required';
        

        if(!profile.full_name){
            error.full_name =msg;
            formIsValid = false;
        } 
        
        
        if(!profile.relationship){
            error.relationship =msg;
            formIsValid = false;
        } 

        if(!formIsValid){
        setErrors(error) 
        }
        
        
        if(formIsValid) {
           LoadingModal()

              
                const newEmergency = contact.map((item:any)=>{
                   
                    if(item.code ===profile.code){
                        return profile
                    }
    
                     return item
                      })


                const log = JSON.parse(content[0].update_log)
                const item = log.concat( {
                    code:STAFF_CODE,
                    action:'Updated one family contact',
                    date_time: new Date()
                })
    
             const logs = JSON.stringify(item,null, 2)


                const newProfile = content.map((item:any)=>{
                const famil = JSON.stringify(newEmergency,null, 2)


                    return {...item, 
                        familyt:famil
                    }
                  })
                  
                  setContent(newProfile)
                  setContact(newEmergency)

                      const fd = new FormData();
               fd.append('logs', logs);
                Object.entries(newProfile[0]).forEach(([key, value]) => {
                                    fd.append(key, String(value));
                                }); 

        let click = document.getElementById('closeEdit')?.click();

           let url = ServerUrl+'/api/employee/update/'+code;
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status === 200){
             SuccessModal(response.data.message)
           
                      } else{
                        ErrorModal('', response.data.message)
                      }   
                  })
                  .catch((error)=>{
                   ErrorModal('oops', error.message)
                  }).finally(()=>{

                    setProfile({
                        full_name:'',
                        relationship:'',
                       nin:'',
                        code:Math.random().toString(36).substring(2, 9),
                        date: new Date()
                    })

                  })  
            }
        
        }


        const EditItem =(data:any)=>{
            setProfile({
                code:data.code,
                full_name:data.full_name,
                nin:data.nin,
                relationship:data.relationship,
                date:data.date
            });
            setErrors({
                full_name:'',
                relationship:''
            })
            let click = document.getElementById('openEdit')?.click()
        
        }

       


               const handleReset =()=>{
                setProfile({
                    full_name:'',
                    relationship:'',
                    nin:'',
                    code:Math.random().toString(36).substring(2, 9),
                    date: new Date()
                })
                let click = document.getElementById('openAdd')?.click()
            }


useEffect(()=>{

   try{

    if (data!==undefined && data.length!==0){
    setContent(data)
    setContact(JSON.parse(data[0].family))
    window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
         
    }

   }catch(err) {

   }
    
    }, [data])

  return (
   <div>

    
<div className="card z-depth-0">

<div className="card-header">
<h5>Family</h5>

<div className="card-header-right">
<label className="label label-primary pointer " onClick={handleReset}> <i className='fa fa-plus text-white'></i> Add Family</label>
<label  id='openAdd' data-toggle="modal" data-target="#large-Modal"> </label>        
</div>

</div>




<label data-toggle="modal" data-target="#editFamily" id='openEdit' ></label>

<div className="modal fade push-modal-right" id="editFamily" tabIndex={-1} role="dialog" data-backdrop="static" >
       <div className="modal-dialog modal-lg" role="document">
           <div className="modal-content">
               <div className="modal-header bg-dark">
                   <h4 className="modal-title text-white">Update Family</h4>
                 

           <button type="button" className="close" data-dismiss="modal" aria-label="Close">
           <i className='fa fa-ban text-danger'></i>
           </button>

               </div>
               <div className="modal-body">
               <div className="row">
<div className="col-md-6">
<div className="form-group">
          <label className="col-form-label">Fullname <span className='text-danger'>*</span></label>
         
              
    <input type="text" className={errors.full_name?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='full_name' value={profile.full_name} onChange={handleChange} />
    
    {errors.full_name&&
    <div className="col-form-label has-danger">{errors.full_name&&errors.full_name} </div>}
        
           </div>

</div>
<div className="col-md-6">
<div className="form-group">

<label className="col-form-label">Relationship <span className='text-danger'>*</span></label>


<Select  options={[
                    { value: 'Spouse', label: 'Spouse' },
                    { value: 'Child', label: 'Child'},
                    { value: 'Other', label: 'Other'},
                                ]} 
                       

            className={errors.relationship?"form-control form-control-danger":""}
                                onChange={handleSelect}  name="relationship"
                        value={{value: profile.relationship, label: profile.relationship}} 
                        styles={selectStyles} 
                 />

{errors.relationship&&
    <div className="col-form-label has-danger">{errors.relationship&&errors.relationship} </div>}

</div>

</div>



<div className="col-md-6">
<div className="form-group">
<label className="col-form-label">NIN</label>

<input type="text" className="form-control form-control-lg"  name='nin' value={profile.nin} onChange={handleChange} />

</div>

</div>


</div>
               </div>
               <div className="modal-footer">
                   <button type="button" className="btn btn-default waves-effect " data-dismiss="modal" id="closeEdit">Close</button>
                   <button type="button" className="btn btn-outline-success " onClick={handleUpdate} >Update</button>
               </div>
           </div>
       </div>
   </div>



<div className="modal fade push-modal-right" id="large-Modal" tabIndex={-1} role="dialog" data-backdrop="static" >
       <div className="modal-dialog modal-lg" role="document">
           <div className="modal-content">
               <div className="modal-header bg-dark">
                   <h4 className="modal-title text-white">Assigned Family</h4>
                 

           <button type="button" className="close" data-dismiss="modal" aria-label="Close">
           <i className='fa fa-ban text-danger'></i>
           </button>

               </div>
               <div className="modal-body">
               <div className="row">
<div className="col-md-6">
<div className="form-group">
          <label className="col-form-label">Fullname <span className='text-danger'>*</span></label>
         
              
    <input type="text" className={errors.full_name?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='full_name' value={profile.full_name} onChange={handleChange} />
    
    {errors.full_name&&
    <div className="col-form-label has-danger">{errors.full_name&&errors.full_name} </div>}
        
           </div>

</div>
<div className="col-md-6">
<div className="form-group">

<label className="col-form-label">Relationship <span className='text-danger'>*</span></label>


<Select  options={[
                                    { value: 'Spouse', label: 'Spouse' },
                                    { value: 'Child', label: 'Child'},
                                    { value: 'Other', label: 'Other'},
                                ]} 
                       
            className={errors.relationship?"form-control form-control-danger":""} 
                                onChange={handleSelect}  name="relationship"
                        value={{value: profile.relationship, label: profile.relationship}} 
                        styles={selectStyles} 
                 />

{errors.relationship&&
    <div className="col-form-label has-danger">{errors.relationship&&errors.relationship} </div>}
</div>

</div>



<div className="col-md-6">
<div className="form-group">
<label className="col-form-label">NIN</label>

<input type="text" className="form-control form-control-lg"  name='nin' value={profile.nin} onChange={handleChange} />

</div>

</div>


</div>
               </div>
               <div className="modal-footer">
                   <button type="button" className="btn btn-default waves-effect " data-dismiss="modal" id="close">Close</button>
                   <button type="button" className="btn btn-primary waves-effect waves-light " onClick={handleSubmit}>Save Family</button>
               </div>
           </div>
       </div>
   </div>





<div className="card-block">


<div className="table-responsive m-t-10">
<TableData />
</div>

</div>
</div>
   </div>

  )
}

export default Family