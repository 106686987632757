import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, STAFF_CODE, ServerUrl, config, configDownload } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'



import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertDate, convertDateTime, convertTime, getTime } from '../../components/GlobalFunction';
import { Link } from 'react-router-dom';

const UploadAttendance =()=> {
   
    const [content, setContent] = useState({
        csv_file:'' as any,
        attendance_date:new Date(),
        file_url:''
     })

    

           const [errors, setErrors] = useState({
            csv_file:'',
            attendance_date:''
           
         })


         const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
            setContent({...content, [name]:value});
           setErrors({...errors, [name]:''});
         }

         const handleChangeDate = (data:any, name:string)=>{
          setContent({...content, [name]:data});
            setErrors({...errors, [name]:''});
         }






         const  handleFile = function(fieldName:any){
            return function(newValue:any){                 
           readURL(newValue);
           }
          } 
        
         
        
        const readURL = (input:any)=>{
         
        let displayMessage = '';
        
        const PreviewImage = input.target.name;
        const inputElement = document.getElementById('photo') as HTMLInputElement
        const fileName = inputElement.value
        
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substring(idxDot, fileName.length).toLowerCase();
        
        if (extFile==="csv" || extFile==="xlsx" ){ 
            //TO DO    
            
             var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
        if (FileSize > 2) {
            displayMessage = ' File uploaded is larger than maximum size Allow (5 mb).';
            inputElement.value = '';
            setContent({...content, file_url:''})
        } else {
            if (input.target.files && input.target.files[0]) { //Check if input has files.
                var reader = new FileReader();//Initialize FileReader.
                reader.onload = function (e:any) {
                    
                //Check if input has files.
                let Id = Math.random().toString(36).substr(2, 9)
                let fileUrl = Id+'_'+input.target.files[0].name
    
                setContent({
                    ...content,
                   csv_file:input.target.files[0],
                    file_url:fileUrl, 
                    
                })
                
                  setErrors({...errors, csv_file:''}); 
                }
                reader.readAsDataURL(input.target.files[0]);
                
            }
        }
         }else{
        displayMessage = 'Only csv|xlsx  file format are allowed with maximum of 2 mb'
        inputElement.value = '';
        setContent({...content, file_url:'', csv_file:''})
        }    
        
        if(displayMessage.length > 0){
        Alerts("danger", displayMessage, 'Error')
        }
        }
       






         



const handleDownload =async()=>{

    let url = ServerUrl+'/api/download_file/sample_attendance'
    await  axios.get(url, configDownload).then((response:any)=>{
     const url = window.URL.createObjectURL(new Blob([response.data]));
       
         const link = document.createElement('a');
         link.href = url;
         link.download = 'template_attendance.xlsx'
         link.click();
         })
 
 }


 const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!content.file_url){
    error.csv_file ='Please select file to upload';
    formIsValid = false;
} 

if(!content.attendance_date){
    error.attendance_date =msg;
    formIsValid = false;
} 




if(!formIsValid){
setErrors(error) 
}


if(formIsValid) {

    LoadingModal()


 const fd = new FormData();

 if(content.csv_file){
    fd.append('imageFile', content.csv_file, content.file_url); 
} 
fd.append('added_by', STAFF_CODE);
   fd.append('company_code', COMPANY_CODE);
        fd.append('attendance_date', convertDate(content.attendance_date)); 
      
        
let url = ServerUrl+'/api/attendance/import_registration';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                Alerts('danger',response.data.message)
                closeModal()
              }   
          })
          .catch((error)=>{
            Alerts('danger',error.message)
            closeModal()
          }).finally(()=>{

let inputElement = document.getElementById('photo') as HTMLInputElement
inputElement.value = ''
          })
    }

}



  return ( <>
   <Breadcrumb 
   title="Import Attendance"
   summary="Upload attendance from csv file"
   >
    <li className="breadcrumb-item">
    <Link to="/attendance/display_attendance">Attendance</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Import Attendance</a> </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
<div className="main-body">
<div className="page-wrapper">
<div className="page-body">


<div className="row">
<div className=" col-md-12">
<div className="card z-depth-0">
    <div className="card-header">
        <h5>IMPORT ATTENDANCE </h5>
        <div className="card-header-right">

            <ul className="list-unstyled card-option">
                <li><i className="fa fa fa-wrench open-card-option"></i></li>
                <li><i className="fa fa-window-maximize full-card"></i></li>
                <li><i className="fa fa-minus minimize-card"></i></li>
                <li><i className="fa fa-refresh reload-card"></i></li>
                <li><i className="fa fa-trash close-card"></i></li>
            </ul>
            
        </div>
    </div>
    <div className="card-block ">
       


        <div className="row">
        <div className="well">
                        <div className="box-body">
                        <div className="row">
                            <div className="col-sm-3 text-right  text-danger"><u><b>Required Columns</b></u></div>
                            <div className="col-sm-7"><u><b>Allowed Values</b></u></div>                           
                        <div className="col-sm-3 text-right text-bold"><b>Portal Code</b></div>
                    <div className="col-sm-7">Employee code<br/>                     </div>          								 <div className="col-sm-3 text-right text-bold"><b>Employee Name</b></div>
                    <div className="col-sm-7">Employee Name<br/>                     </div> 
                            <div className="col-sm-3 text-right text-bold"><b>inTime(HH:MM:00)</b></div>
                        <div className="col-sm-7">Time In (24 Hrs)     <br/>  </div>    
                        <div className="col-sm-3 text-right text-bold"><b>OutTime(HH:MM:00)</b></div>
                        <div className="col-sm-7">Time Out (24 hrs)    <br/>  </div>                        
                            <div className="col-sm-3 text-right text-bold"><b>Indicator</b></div>
                            <div className="col-sm-7">Attendance(P-Present||H-Halfday||A-Absent)<br/>                    <br/></div>                                               <br/>      
                                </div>                       
                                 
                                </div>
                        </div>

                        


          





        </div>


    </div>
</div>
</div>
</div>






<div className="row  ">
								<div className="col-sm-6">
	<div className="card z-depth-0">
		<div className="card-block">
		<fieldset>
			<div className="row">
									
			<section className="col-md-12 ">
			<div className="well well-sm alert  border-success  ">
					<h4 className="alert-heading ">Employee Attendance Template</h4>
					
                
<p><span >The first line in downloaded csv file should remain as it is. Please do not change the order of columns.</span><br/>
<span >The correct column order is (<b>
    Portal Code, Employee Name,  inTime(HH:MM a), OutTime(HH:MM a), Attendance(P-Present||H-HalfdayAll||A-AbsentAll)</b>)</span> <span >&amp; you must follow this.</span></p>
					<hr className="simple" /><p className="text-align-left">
						
                
        <button type="button" onClick={handleDownload}  className="btn  btn-inverse"> <i className="fa fa-download"></i> Download Sample</button>
        
					</p>
				</div>					
				</section>
										</div>
										
				</fieldset>
				
							</div>
							</div>
							</div>
		
	<div className="col-sm-6">
	<div className="card z-depth-0">
	
		<div className="card-block">


		<div className="alert alert-success border-success">
						<fieldset className="well well-sm">
                        <h4 className="alert-heading ">Upload file with .csv or .xlsx  extention</h4>
							<section>
                            <div className="form-group">
   <label >Select Date</label>

  <DatePicker 
            selected={content.attendance_date}      
             isClearable
             dateFormat="yyyy-MM-dd"
             className="form-control form-control-lg"
             placeholderText="Select date"
           
 onChange={(date:any) => handleChangeDate(date, 'auto_absent')} />
 
   <span style={{color:'red'}}>{errors.attendance_date && errors.attendance_date}</span> 
               </div>


                            <div className="form-group">
							<label> Choose File Max(2mb)</label>
                    <input  className={errors.csv_file ? 'form-control form-control-danger' : 'form-control'}  id="photo" onChange={handleFile('photo')}  name="photo" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />

					<span className="note" style={{color:'red'}}>{errors.csv_file}</span>	 
										</div>          
                                        
							</section>
						</fieldset>
                     
                        </div>
						<footer>
							<button type="button" id="submit" onClick={handleSubmit} className="btn  btn-primary pull-right"><i className="fa fa-save"></i> Upload Attendance</button>
						</footer>

	</div>

</div>

</div></div>

</div>
</div>


</div>
</div>  
      </>               
 
  )
}

export default UploadAttendance