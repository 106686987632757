import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo} from 'react'
import { COMPANY_CODE, STAFF_CODE, ServerUrl, config, selectStyles, styles } from '../../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal, CustomLoader, Alerts, closeModal } from '../../../components/notify';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'

import Select, { components } from 'react-select'

const Title =()=> {

    const [item, setItem] = useState({
        title:'',
        item_type:{value:'', label:''},
        item_code:'',
        code:''
    });

    const [editItem, setEditItem] = useState({
        title:'',
        item_type:'',
        item_code:'',
        code:'',
        id:''
    });


    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
         title:'',
         item_type:'',
        item_code:'',

        edit_title:'',
        type:'',
      code:'',
       
     })
    

   
     const handleSelect = (option:any, action:any)=>{
        setItem({...item, [action.name]:option});
          setErrors({...errors, [action.name]:''})
           }


    const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;		
    setItem({...item, [name]:value})
   setErrors({...errors, [name]:''});
     }


const handleReset =()=>{
    setItem({
        title:'',
        item_type:{value:'', label:''},
        item_code:'',
        code:''
    })
}


const TableData =()=>{


    const columns = [
        {
            name: 'Salary Code',
            selector: (row:any) => row.item_code,
            sortable: true,
        },
        {
            name: 'Salary Type',
            selector: (row:any) => row.item_type,
            sortable: true,
        },
        {
            name: 'Salary Title',
            selector: (row:any) => row.title,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row:any, index:any, column:any, id:any) => <div className='row'>  
                <label className="label label-info pointer" onClick={()=>EditItem(row)} ><i className='ti-pencil'></i> Edit</label>
            <label className="label label-danger pointer" onClick={()=>DeleteModal(row.id)}><i className='ti-trash'></i> Delete</label></div>,
                left:true
                      

        },
    ];
    
  

    return <DataTable
    columns={columns}
    data={content}
    dense
    highlightOnHover
    pagination
    selectableRowsHighlight
    striped
    progressPending={loading}
	progressComponent={<CustomLoader />}
/>

}



const handleChangeEdit =(event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	
    setEditItem({...editItem, [name]:value})
   setErrors({...errors, [name]:''});
 }


 const handleSelectEdit = (option:any, action:any)=>{
    setItem({...item, [action.name]:option.value});
      setErrors({...errors, [action.name]:''})
       }


const EditItem =(data:any)=>{
    setEditItem({
        item_type:data.item_type,
        item_code:data.item_code,
        title:data.title,
        code:data.code,
        id:data.id
    });
    let click = document.getElementById('openEdit')?.click()

}



  const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!item.title){
    error.title =msg;
    formIsValid = false;
} 


if(!item.item_code){
    error.item_code=msg;
    formIsValid = false;
} 


if(!item.item_type.value){
    error.item_type =msg;
    formIsValid = false;
} 


content.map((data:any)=>{
    if(data.item_code === item.item_code){
        error.item_code='The code already exist';
        formIsValid = false;
        }
  })

if(!formIsValid){
setErrors(error) 
}

if(formIsValid) {

    LoadingModal()
   
    const fd = new FormData();
    let code = 'PT'+Math.random().toString(36).substring(2, 9)
  
    fd.append('company_code', COMPANY_CODE);
    fd.append('code', code);


    fd.append('added_by', STAFF_CODE);
    fd.append('title', item.title);
    fd.append('item_code', item.item_code);
    fd.append('item_type', item.item_type.value);


let url = ServerUrl+'/api/payroll/structure/add_title';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                Alerts('danger', response.data.message)
              }   
          })
          .catch((error)=>{
            Alerts('danger', error.message)
          }).finally(()=>{
        fetchData()
         handleReset()

          })
    }

}



const handleUpdate =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!editItem.title){
    error.edit_title =msg;
    formIsValid = false;
} 



if(!editItem.item_code){
    error.code =msg;
    formIsValid = false;
} 


if(!editItem.item_type){
    error.type =msg;
    formIsValid = false;
} 



if(!formIsValid){
setErrors(error) 
}


if(formIsValid){

     LoadingModal()
   
    const fd = new FormData();
    Object.entries(editItem).forEach(([key, value]) => {
        fd.append(key, String(value));
    });
    let url = ServerUrl+'/api/payroll/structure/update_title';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                Alerts('danger', response.data.message)
              }   
          })
          .catch((error)=>{
            Alerts('danger', error.message)
          }).finally(()=>{
         let click = document.getElementById('closeModal')?.click()
         fetchData()
        
          }) 
    }

}

const DeleteModal =(id:number)=>{
    let url = ServerUrl+'/api/payroll/structure/delete_title/'+id;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =content.filter((item:{id:number})=>item.id !==id)
            setContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }

const fetchData = async()=>{
    setLoading(true)
    let url = ServerUrl+'/api/payroll/structure/view_title/'+COMPANY_CODE
  await  axios.get(url, config).then((result:any)=>{
    if(Array.isArray(result.data.data) && result.data.data.length!==0){
      setContent(result.data.data)
      }
    })
    .catch((error)=>{
        Alerts('danger', error.message)
    }).finally(()=>{
        setLoading(false)
    })
    } 




    useEffect(()=>{
        fetchData()
      }, [])






  return (
    <div className=''>


<label data-toggle="modal" data-target="#editItem" id='openEdit' ></label>

<div className="modal fade " id="editItem" tabIndex={-1} role="dialog" data-backdrop="static" >
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header bg-dark">
                    <h4 className="modal-title text-white">Update Salary Title </h4>
                  
    
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i className='fa fa-ban text-danger'></i>
            </button>
    
                </div>
                <div className="modal-body">
                
    
                <div className="row">
                  
                  <div className="col-md-6">
              <div className="form-group">
              <label className="col-form-label">Salary Code</label>

              <input type="text" className={errors.code?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='item_code' value={editItem.item_code} onChange={handleChangeEdit} />
              
              {errors.code&&
<div className="col-form-label has-danger">{errors.code&&errors.code} </div>}
              </div>

               </div>


              <div className="col-md-6">
              <div className="form-group">
              <label className="col-form-label">Salary Title</label>

              <input type="text" className={errors.edit_title?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='title' value={editItem.title} onChange={handleChangeEdit} />

              {errors.edit_title&&
<div className="col-form-label has-danger">{errors.edit_title&&errors.edit_title} </div>}
              </div>

               </div>

               <div className="col-md-6">
                           <div className="form-group">
                          <label className="col-form-label">Salary Type</label>
                         
                  <Select  options={[
                      { value: 'DEDUCTION', label: 'DEDUCTION' },
                      { value: 'EARNING', label: 'EARNING'},
                  ]} 
                  className={errors.type?"form-control form-control-danger":""} 
                  onChange={handleSelectEdit}  name="item_type"
          value={{value:editItem.item_type, label:editItem.item_type}} 
          styles={styles} 
      />
                        
                           </div>
                           </div>
              </div> 
                </div>
                <div className="modal-footer">
                    <button type="button" id='closeModal' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-outline-success " onClick={handleUpdate} >Update</button>
                </div>
            </div>
        </div>
    </div>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Salary Title</h5>
                <span>Add all offcie department</span>
              
            </div>
            <div className="card-block">
    
        
                        <div className="row">
                  
                        <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Salary Code</label>


                    <input type="text" className={errors.item_code?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='item_code' value={item.item_code} onChange={handleChange} />
                    
                    {errors.item_code&&
    <div className="col-form-label has-danger">{errors.item_code&&errors.item_code} </div>}
                    </div>
    
                     </div>


                    <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Salary Title</label>

                    <input type="text" className={errors.title?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='title' value={item.title} onChange={handleChange} />

                    {errors.title&&
    <div className="col-form-label has-danger">{errors.title&&errors.title} </div>}
                    </div>
    
                     </div>

                     <div className="col-md-6">
                                 <div className="form-group">
                                <label className="col-form-label">Salary Type</label>
                               
                        <Select  options={[
                            { value: 'DEDUCTION', label: 'DEDUCTION' },
                            { value: 'EARNING', label: 'EARNING'},
                        ]} 
                        className={errors.item_type?"form-control form-control-danger":""} 
                        onChange={handleSelect}  name="item_type"
                value={item.item_type} 
                styles={styles} 
            />
                              
                                 </div>
                                 </div>
                    </div>


<hr/>
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-outline-danger btn-round pr-5 pl-5 mr-3" type='button' onClick={handleReset}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={handleSubmit}>Save</button>
</div>
</div>
    
            </div>
        </div>
    </div>
    </div>
    
    
    

    
    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>All Structure</h5>
                <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
              
            </div>
            <div className="card-block">
    
        <div className="table-responsive m-t-10">
        <TableData />
        </div>
    
            </div>
        </div>
    </div>
    </div>
    
                </div>
  )
}

export default Title