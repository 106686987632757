import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, ServerUrl, config } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, Alerts, SuccessModal, closeModal } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'
import { Link } from 'react-router-dom';



const  EmailSetup =()=> {
  

    const [content, setContent] = useState({
        message:'',
        port:'',
        receiver_mail:'',
           sender_name:'',
           sender_email:'',
           password:'',
           host:'',
           username:'',
           api_token:'',
           api_username:'',
           api_password:'',
            status:'Active',
        is_edit:true
    });

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        message:'',
        port:'',
        receiver_mail:'',
        sender_name:'',
           sender_email:'',
           password:'',
           host:'',
           username:'',
           api_token:'',
           api_username:'',
           api_password:'',
       
     })
    

       

                        
     const handleChangeText =(event:ChangeEvent<HTMLTextAreaElement>)=>{


        let {name, value} = event.target;	
        setContent({...content, [name]:value})
        setErrors({...errors, [name]:''});
        
        }

const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{


let {name, value} = event.target;	

if(name==='status'){
    value = value==='Active'?'Inactive':'Active'
}

setContent({...content, [name]:value})
setErrors({...errors, [name]:''});

}
             
const handleSubmitMail =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

let email = content.receiver_mail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);


if(!content.receiver_mail){
    error.receiver_mail =msg;
    formIsValid = false;
} 

if(!email){
    error.receiver_mail ='Please enter valid email';
    formIsValid = false;
} 

if(!content.message){
    error.message =msg;
    formIsValid = false;
} 


if(!formIsValid){
setErrors(error) 
}

if(formIsValid) {

    
    document.getElementById('closeModal')?.click()
    LoadingModal()
    const fd = new FormData();
   
    fd.append('email', content.receiver_mail);
    fd.append('message', content.message);

    let url = ServerUrl+'/api/setup/send_email/send_test';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
              Alerts('danger', response.data.message)
              closeModal()
              }   
          })
          .catch((error)=>{
            Alerts('danger', error.message)
            closeModal()
          }).finally(()=>{
            setContent({
                ...content,
            message:'',
            receiver_mail:''
    })
          })
    }
}


const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!content.sender_name){
    error.sender_name =msg;
    formIsValid = false;
} 

if(!content.username){
    error.username =msg;
    formIsValid = false;
} 

let email = content.sender_email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

if(!content.sender_email){
    error.sender_email =msg;
    formIsValid = false;
} 

if(!email){
    error.sender_email ='Please enter valid email';
    formIsValid = false;
} 

if(!content.host){
    error.host =msg;
    formIsValid = false;
} 

if(!content.password){
    error.password =msg;
    formIsValid = false;
} 


if(!formIsValid){
setErrors(error) 
}

if(formIsValid) {

    LoadingModal()
   

    

    const fd = new FormData();
   
    Object.entries(content).forEach(([key, value]) => {
      fd.append(key, String(value));
  });

    let url = ServerUrl+'/api/setup/email_setup/update';
   
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
              Alerts('danger', response.data.message)
              closeModal()
              }   
          })
          .catch((error)=>{
            Alerts('danger', error.message)
            closeModal()
          })
    }
}


    
const fetchContent =()=>{
    LoadingModal()
    let url = ServerUrl+'/api/setup/email_setup/show_one/emst'
    axios.get(url, config).then(result=>{
      if(result.data.status===200){
        try {
         setContent({...content, ...result.data.data})
  
        }catch(e){
  
        }
      }
    })
    .catch((error)=>{
     Alerts('danger', error.message)
    }).finally(()=>{
        closeModal()
    })
    }

  
  useEffect(()=>{
      fetchContent()
  }, [])



  return ( <>
  <Breadcrumb 
   title="Email Setup"
   summary="Update Email Sender details"
   >
     <li className="breadcrumb-item">
    <Link to="/setup/configure/message_template">Template</Link>
                    </li>

   <li className="breadcrumb-item">
    <Link to="#!">Setup</Link>
                    </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">









                        <div className="modal fade " id="testmail" tabIndex={-1} role="dialog" data-backdrop="static" >
        <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
                <div className="modal-header bg-dark">
                    <h4 className="modal-title text-white">Send Test Mail</h4>
                  
    
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i className='fa fa-ban text-danger'></i>
            </button>
    
                </div>
                <div className="modal-body">
                
     <div className="form-group row">
            <label className="col-sm-3 col-form-label">Receiver Mail <span className='text-danger'>*</span></label>
            <div className="col-sm-9">
                <input type="text" className={errors.receiver_mail?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  value={content.receiver_mail} name="receiver_mail" onChange={handleChange} />


                {errors.receiver_mail&&
    <div className="col-form-label has-danger">{errors.receiver_mail&&errors.receiver_mail} </div>}


            </div>  
            
            </div> 

            <div className="form-group row">
            <label className="col-sm-3 col-form-label">Message <span className='text-danger'>*</span></label>
            <div className="col-sm-9">
                <textarea rows={5}  className={errors.message?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  value={content.message} name="message"  onChange={handleChangeText} ></textarea>


                {errors.message&&
    <div className="col-form-label has-danger">{errors.message&&errors.message} </div>}

            </div>  
            
            </div> 




                </div>
                <div className="modal-footer">
                    <button type="button" id='closeModal' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>

                    <button type="button" className="btn btn-outline-success " onClick={handleSubmitMail}  >Send</button>
                </div>
            </div>
        </div>
    </div>









    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Email Setup</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                              
                                    
                                    
         <div className="content-wrappers ">
                            
         <div className=''>



         <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
        <div className="card-block">


        <a href='#!' data-toggle="modal" id='openEdit' data-target="#testmail" className="btn btn-inverse btn-round pr-5 pl-5"> <i className='fa fa-envelope'></i> Send Test</a>



<button className="btn btn-success btn-round pr-5 pl-5 ml-3" type='button' disabled={content.is_edit} onClick={handleSubmit} >Update</button>
</div>
</div>
</div> 
</div> 

    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>SMTP Credentials </h5>
                <span>Create new email template here</span>
              
            <div className="card-header-right">
                        {content.is_edit?  <button onClick={()=>setContent({...content, is_edit:!content.is_edit})} type="button" className="btn btn-sm btn-primary active ">
                            <i className="fa fa-edit text-white"></i> Edit
                        </button>:

                        <button  type="button" onClick={()=>setContent({...content, is_edit:!content.is_edit})} className="btn btn-sm btn-primary active ">
                            <i className="fa fa-times text-white"></i> 
                        </button>}

                        </div>

            </div>
            <div className="card-block">

    

            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">Sender Name <span className='text-danger'>*</span></label>
            <div className="col-sm-10">
                <input type="text" className={errors.sender_name?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} disabled={content.is_edit}  value={content.sender_name} name="sender_name" onChange={handleChange} />


                {errors.sender_name&&
    <div className="col-form-label has-danger">{errors.sender_name&&errors.sender_name} </div>}


            </div>  
            
            </div>


            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">Sender Email Address <span className='text-danger'>*</span></label>
            <div className="col-sm-10">
                <input type="text" className={errors.sender_email?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} disabled={content.is_edit} value={content.sender_email} name="sender_email" onChange={handleChange} />


                {errors.sender_email&&
    <div className="col-form-label has-danger">{errors.sender_email&&errors.sender_email} </div>}


            </div>  
            
            </div> 


            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">SMTP Username <span className='text-danger'>*</span></label>
            <div className="col-sm-10">
                <input type="text" className={errors.username?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} disabled={content.is_edit} value={content.username} name="username" onChange={handleChange} />


                {errors.username&&
    <div className="col-form-label has-danger">{errors.username&&errors.username} </div>}


            </div>  
            
            </div> 

            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">SMTP Password <span className='text-danger'>*</span></label>
            <div className="col-sm-10">
                <input type="text" className={errors.password?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} disabled={content.is_edit}  value={content.password} name="password" onChange={handleChange} />


                {errors.password&&
    <div className="col-form-label has-danger">{errors.password&&errors.password} </div>}


            </div>  
            
            </div> 



            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">Host <span className='text-danger'>*</span></label>
            <div className="col-sm-10">
                <input type="text" className={errors.host?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} disabled={content.is_edit} value={content.host} name="host" onChange={handleChange} />


                {errors.host&&
    <div className="col-form-label has-danger">{errors.host&&errors.host} </div>}


            </div>  
            
            </div>
          
            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">Port <span className='text-danger'>*</span></label>
            <div className="col-sm-10">
                <input type="text" className={errors.port?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} disabled={content.is_edit} value={content.port} name="port" onChange={handleChange} />


                {errors.port&&
    <div className="col-form-label has-danger">{errors.host&&errors.port} </div>}


            </div>  
            
            </div>

            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">Is Default</label>
            <div className="col-sm-10">
              
            <div className="checkbox-fade fade-in-primary ">
                                    <label className="check-task">
              <input type="checkbox" value={content.status} name="status" checked={content.status ==='Active'?true:false}  onChange={handleChange} />
    <span className="cr">
    <i className="cr-icon fa fa-check txt-default"></i>
    </span> 
</label>
                                </div>
            </div>  
            
            </div> 
    
            </div>
        </div>
    </div>
    </div>
    
    







    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Third Party API</h5>
                <span>Add all offcie department</span>
              
            </div>
            <div className="card-block">

    

            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">API Token </label>
            <div className="col-sm-10">
                <input type="text" className={errors.api_token?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} disabled={content.is_edit} value={content.api_token} name="api_token" onChange={handleChange} />


                {errors.api_token&&
    <div className="col-form-label has-danger">{errors.api_token&&errors.api_token} </div>}


            </div>  
            
            </div> 



        
      

            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">Username </label>
            <div className="col-sm-10">
                <input type="text" className={errors.api_username?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} disabled={content.is_edit}  value={content.api_username} name="api_username" onChange={handleChange} />


                {errors.api_username&&
    <div className="col-form-label has-danger">{errors.api_username&&errors.api_username} </div>}


            </div>  
            
            </div> 


            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">Password </label>
            <div className="col-sm-10">
                <input type="password" className={errors.api_password?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  disabled={content.is_edit} value={content.api_password} name="api_password" onChange={handleChange} />


                {errors.api_password&&
    <div className="col-form-label has-danger">{errors.api_password&&errors.api_password} </div>}


            </div>  
            
            </div> 

    
            </div>
        </div>
    </div>
    </div>

    
                </div>

        </div>
                       
                    </section>
                </div>
            </div>
        </div>
    </div>
                                    </div>
                                </div>
                            </div>





                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default EmailSetup