import { useEffect, useState} from 'react'
import { COMPANY_CODE, ImagesUrl, ServerUrl, config } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, closeModal, Alerts } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'
import { useQuery } from 'react-query'

import { useParams} from 'react-router'
import { FetchProfile, fetchTermination } from '../../api/ReactQuery';
import { Link } from 'react-router-dom';

const PreviewEmployee =()=> {
  let params = useParams()
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [criteria, setCriteria] =useState('');
    const [group, setGroup] =useState([] as any);
    const [title, setTitle] = useState([] as any);

    const {data} = useQuery(["profile"], FetchProfile)
    const [profile, setProfile] = useState([] as any);
  const termination = useQuery(["termination"], fetchTermination)
  

const fetchReport =()=>{

  LoadingModal()
  
  let url = ServerUrl+'/api/employees/edit_report/'+COMPANY_CODE+'/'+params.code
  axios.get(url, config).then(result=>{
    if(result.data.status===200){
      try {
          
          let group_item  = JSON.parse(result.data.data.group)
          setGroup(group_item)
          setTitle(result.data.data.title)
          setCriteria(result.data.data.criteria_sql)

      }catch(e){

      }
     
    }
  })
  .catch((error)=>{
   Alerts('danger', error.message)
  }).finally(()=>{
      closeModal()
  })
  }


  const getTitleName = (code:string, field:string) =>{ 
    const result = termination.data&&termination.data.filter((list:any)=>list.code===code);
   const answer = result.length !==0?result[0][field]:''
     return  answer;
   }




  const fetchContent = async()=>{
    setLoading(true)

         let sql = "SELECT e.id, e.code, e.company_code, IFNULL(e.title, '') as title, e.first_name, e.last_name, IFNULL(e.middle_name, '') as middle_name, e.nickname, e.file_number, e.passport_no, e.nin, e.birth_cert, e.driver_license, e.other_id, e.gender, e.tribe, e.marital_status, e.religion, e.blood_group, e.language, e.date_of_birth, e.place_of_birth, e.is_smoker, e.country, e.state, e.lga, e.city, e.house_no, e.r_country, e.r_state, e.r_lga, e.r_city, e.r_house_no, e.mobile_phone, e.home_phone, e.work_phone, e.other_phone, e.personal_email, e.work_email, e.joined_date, e.confirmation_date, e.leaving_date, e.department, e.employment_status, e.job_title, e.work_station, e.contract_details, e.emergency_contact, e.family, e.documents, e.passport_visa, e.supervisor, e.subordinate, e.bank_name, e.account_number, e.account_type, e.account_name, e.education_history, e.work_history, e.membership_awards, e.passport, e.signature,  e.account_status, e.termination_reason, e.termination_note, j.title as job_title, d.department_name  FROM tbl_employee e left join tbl_job_title j on j.code = e.job_title  left JOIN tbl_departments d on d.code = e.department WHERE e.id <> '' "



         if(criteria !== null){
          sql =  sql + criteria;
         }
         
         const fd = new FormData();
         fd.append('sql', sql); 
         let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
       await  axios.post(url, fd, config).then((result:any)=>{
         if(Array.isArray(result.data.data) && result.data.data.length!==0){
          
           setContent(result.data.data)
           }
         })
         .catch((error)=>{
            Alerts('danger',error.message)
         }).finally(()=>{
             setLoading(false)
         })
         } 

         useEffect(()=>{
          fetchContent()
      },[criteria])

      const getHeaderName = (name:string)=>{
        const field = group.map((item:any)=>item.field_name)
        const header = group.filter((item:any)=>item.field_name == name)
        let isHeader = header.length!==0?header[0].isHeader:false

        if(isHeader && field.includes(name)){
          return true
        }else{
          return false
        }
          
      }


      const getSubHeaderName = (field_name:string, name:string)=>{
        const field = group.filter((item:any)=>item.field_name == field_name)
        let data = field.length!==0?field[0].data:[]
        const all_data = data.map((item:any)=>item.label)

       return all_data.includes(name)
          
      }




      const PrintMe = () =>{     
      let printContents:any = document.getElementById('printablediv')?.innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
       document.body.innerHTML = originalContents; 
      }



      useEffect(()=>{

        try{
     
         if (data!==undefined && data.length!==0){
         setProfile(data)
             
         }
     
        }catch(err) {
     
        }
         
         }, [data]) 


  useEffect(()=>{
      fetchReport()
  },[])

  return ( <>
   <Breadcrumb 
   title=" Template"
   summary={title}
   >
    <li className="breadcrumb-item">
    <Link to="#!">Report</Link>
                    </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
<div className="main-body">
<div className="page-wrapper">
<div className="page-body">



<div className="row">
<div className=" col-md-12">
<div className="card z-depth-0">
    <div className="card-block " id='printablediv'>
       


<div className="table-responsives m-t-10">




          <div className="row">
            
            <div className="col-md-12">
            
<div className="table-responsive ">

<div className='text-center '>

<img 
src={ImagesUrl+'/about/'+profile.logo}  alt="Click to change" 
className="m-b-10 img-100" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/logo.png'}} />


<h4>{profile.company_name} </h4>
<h5>{profile.company_address}</h5>
<h6 className='m-b-5'>{profile.email}, {profile.phone}</h6>

<div className="alert alert-info text-center mb-1 mt-2">

<h5> {title}</h5>
</div>
</div>

              <table id="simpletable" className="table  table-bordered table-sm" role="grid" >
                
            <thead>
            <tr >
          {getHeaderName('Personal Details')?
                  <th colSpan={20} className='text-center'>Personal Details</th>:[]}
                  {getHeaderName('Contact Address')?
                  <th colSpan={16} className='text-center'>Contact Address</th>:[]}
                  {getHeaderName('Emergency Contact')?
                  <th colSpan={5} rowSpan={2} className='text-center' >Emergency Contact</th>:[]}
                  {getHeaderName('Family')?
                  <th colSpan={3} rowSpan={2} className='text-center' >Family</th>:[]}
                  {getHeaderName('Passport, Visa & Licences')?
                  <th colSpan={7} rowSpan={2} className='text-center'>Passport, Visa & Licences</th>:[]}
                  {getHeaderName('Education History')?
                  <th colSpan={5} rowSpan={2}  className='text-center'>Education History</th>:[]}
                  {getHeaderName('Work Experience')?
                  <th colSpan={5} rowSpan={2}  className='text-center'>Work Experience</th>:[]}
                  {getHeaderName('Membership & Awards')?
                  <th colSpan={3} rowSpan={2}  className='text-center'>Membership & Awards</th>:[]}
                  {getHeaderName('Job Details')?
                  <th colSpan={7} className='text-center'>Job Details</th>:[]}
                  {getHeaderName('Document & Files')?
                  
                  <th colSpan={2} rowSpan={2} className='text-center'>Document & Files</th>:[]}
                  {getHeaderName('Bank Account Details')?
                  <th colSpan={3} className='text-center'>Bank Account Details</th>:[]}
                  {getHeaderName('Account Setup')?
                  <th colSpan={2} className='text-center'>Account Setup</th>:[]}
                  {getHeaderName('Terminations')?
                  <th colSpan={3} className='text-center'>Terminations</th>:[]}
                 
                  
                  </tr>

                <tr style={{backgroundColor:'#E7E0EE'}}>
          {getSubHeaderName('Personal Details', 'Title')?
                  <th >Title</th>:[]}

        {getSubHeaderName('Personal Details', 'First Name')?
                  <th >First Name</th>:[]}

                  {getSubHeaderName('Personal Details', 'Last Name')?
                  <th >Last Name</th>:[]}

                  {getSubHeaderName('Personal Details', 'Middle Name')?
                  <th>Middle Name</th>:[]}

                  {getSubHeaderName('Personal Details', 'Nickname')?
                  <th >Nickname</th>:[]}

                  {getSubHeaderName('Personal Details', 'File Number')?
                  <th >File Number</th>:[]}

                  {getSubHeaderName('Personal Details', 'Passport ID')?
                  <th >Passport ID</th>:[]}

                  {getSubHeaderName('Personal Details', 'NIN')?
                  <th >NIN</th>:[]}

                  {getSubHeaderName('Personal Details', 'Birth Certificate No.')?
                  <th>Birth Cert. No.</th>:[]}

                  {getSubHeaderName('Personal Details', 'Driving License No')?
                  <th >Driving License No</th>:[]}

                  {getSubHeaderName('Personal Details', 'Other ID')?
                  <th >Other ID</th>:[]}

                  {getSubHeaderName('Personal Details', 'Gender')?
                  <th >Gender</th>:[]}

                  {getSubHeaderName('Personal Details', 'Is Smoker')?
                  <th >Smoker</th>:[]}
                  {getSubHeaderName('Personal Details', 'Tribe')?
                  <th>Tribe</th>:[]}

                  {getSubHeaderName('Personal Details', 'Marital Status')?
                  <th >Marital Status</th>:[]}

                  {getSubHeaderName('Personal Details', 'Religion')?
                  <th >Religion</th>:[]}

                  {getSubHeaderName('Personal Details', 'Language Spoken')?
                  <th >Language Spoken</th>:[]}

                  {getSubHeaderName('Personal Details', 'Blood Group')?
                  <th >Blood Group</th>:[]}

                  {getSubHeaderName('Personal Details', 'Date of Birth')?
                  <th>Date of Birth</th>:[]}

                  {getSubHeaderName('Personal Details', 'Place of Birth')?
                  <th >Place of Birth</th>:[]}





            {getSubHeaderName('Contact Address', 'Country')?
                  <th >Country</th>:[]}

                  {getSubHeaderName('Contact Address', 'State')?
                  <th >State</th>:[]}

                  {getSubHeaderName('Contact Address', 'Lga')?
                  <th >Local Area</th>:[]}

                  {getSubHeaderName('Contact Address', 'City')?
                  <th>City</th>:[]}

                  {getSubHeaderName('Contact Address', 'House No')?
                  <th >Street & House No.</th>:[]}

                  {getSubHeaderName('Contact Address', 'Resident Country')?
                  <th >Resident Country</th>:[]}

                  {getSubHeaderName('Contact Address', 'Resident State')?
                  <th >Resident State</th>:[]}

                  {getSubHeaderName('Contact Address', 'Resident Lga')?
                  <th >Resident Local Area</th>:[]}

                  {getSubHeaderName('Contact Address', 'Resident City')?
                  <th>Resident City</th>:[]}

                  {getSubHeaderName('Contact Address', 'Resident House No')?
                  <th >Resident Street & House No.</th>:[]}

                  {getSubHeaderName('Contact Address', 'Home Phone')?
                  <th >Home Phone</th>:[]}

                  {getSubHeaderName('Contact Address', 'Mobile Phone')?
                  <th >Mobile Phone</th>:[]}

                  {getSubHeaderName('Contact Address', 'Work Phone')?
                  <th>Work Phone</th>:[]}

                  {getSubHeaderName('Contact Address', 'Other Phone')?
                  <th >Other Phone</th>:[]}

                  {getSubHeaderName('Contact Address', 'Personal Email')?
                  <th >Personal Email</th>:[]}

                  {getSubHeaderName('Contact Address', 'Work Email')?
                  <th >Work Email</th>:[]}





                  





                  
                










{getSubHeaderName('Job Details', 'Joined Date')?
                  <th>Joined Date</th>:[]}

                  {getSubHeaderName('Job Details', 'Confirmation Date')?
                  <th >Confirmation Date</th>:[]}

                  {getSubHeaderName('Job Details', 'Date of Leaving')?
                  <th >Date of Leaving</th>:[]}

                  {getSubHeaderName('Job Details', 'Current Department/Unit')?
                  <th>Current Department/Unit</th>:[]}

                  {getSubHeaderName('Job Details', 'Employment Status')?
                  <th >Employment Status </th>:[]}

                  {getSubHeaderName('Job Details', 'Job Title')?
                  <th >Job Title</th>:[]}

                  {getSubHeaderName('Job Details', 'Work Station')?
                  <th>Work Station</th>:[]}
                

                

                  {getSubHeaderName('Bank Account Details', 'Bank')?
                  <th >Bank Name</th>:[]}

                  {getSubHeaderName('Bank Account Details', 'Account Name')?
                  <th >Account Name</th>:[]}

                  {getSubHeaderName('Bank Account Details', 'Account Number')?
                  <th >Account Number</th>:[]}


                  {getSubHeaderName('Account Setup', 'Passport')?
                  <th >Passport</th>:[]}

                  {getSubHeaderName('Account Setup', 'Signature')?
                  <th >Signature</th>:[]}



                  {getSubHeaderName('Terminations', 'Termination Reason')?
                  <th >Termination Reason</th>:[]}

                  {getSubHeaderName('Terminations', 'Termination Note')?
                  <th >Termination Note</th>:[]}

                  {getSubHeaderName('Terminations', 'Account Status')?
                  <th >Account Status</th>:[]}

                  
                  </tr>
            </thead>
            <tbody>
                
            {content.length!==0? content.map((list:any, index:number)=>  <tr key={index} >



          {getSubHeaderName('Personal Details', 'Title')?
                  <td >{list.title}</td>:[]}

        {getSubHeaderName('Personal Details', 'First Name')?
                  <td>{list.first_name}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Last Name')?
                 <td>{list.last_name}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Middle Name')?
                  <td >{list.middle_name}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Nickname')?
                  <td>{list.nickname}</td>:[]}

                  {getSubHeaderName('Personal Details', 'File Number')?
                 <td>{list.file_number}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Passport ID')?
                  <td >{list.passport_no}</td>:[]}

                  {getSubHeaderName('Personal Details', 'NIN')?
                   <td>{list.nin}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Birth Certificate No.')?
                  <td>{list.birth_cert}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Driving License No')?
                   <td >{list.driver_license}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Other ID')?
                   <td>{list.other_id}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Gender')?
                 <td>{list.gender}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Is Smoker')?
                    <td >{list.is_smoker}</td>:[]}
                  {getSubHeaderName('Personal Details', 'Tribe')?
                  <td>{list.tribe}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Marital Status')?
                  <td>{list.marital_status}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Religion')?
                   <td >{list.religion}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Language Spoken')?
                   <td>{list.language}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Blood Group')?
                 <td>{list.blood_group}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Date of Birth')?
                <td >{list.date_of_birth}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Place of Birth')?
                 <td >{list.place_of_birth}</td>:[]}

                    
     

        {getSubHeaderName('Contact Address', 'Country')?
                 <td>{list.country}</td>:[]}

                  {getSubHeaderName('Contact Address', 'State')?
                 <td>{list.state}</td>:[]}

                  {getSubHeaderName('Contact Address', 'Lga')?
                   <td>{list.lga}</td>:[]}

                  {getSubHeaderName('Contact Address', 'City')?
                 <td>{list.city}</td>:[]}

                  {getSubHeaderName('Contact Address', 'House No')?
                  <td>{list.house_no}</td>:[]}

                  {getSubHeaderName('Contact Address', 'Resident Country')?
                  <td>{list.r_country}</td>:[]}

                  {getSubHeaderName('Contact Address', 'Resident State')?
                   <td>{list.r_state}</td>:[]}

                  {getSubHeaderName('Contact Address', 'Resident Lga')?
                  <td>{list.r_lga}</td>:[]}

                  {getSubHeaderName('Contact Address', 'Resident City')?
                  <td>{list.r_city}</td>:[]}

                  {getSubHeaderName('Contact Address', 'Resident House No')?
                  <td>{list.r_house_no}</td>:[]}

                  {getSubHeaderName('Contact Address', 'Home Phone')?
                  <td>{list.home_phone}</td>:[]}

                  {getSubHeaderName('Contact Address', 'Mobile Phone')?
                  <td>{list.mobile_phone}</td>:[]}

                  {getSubHeaderName('Contact Address', 'Work Phone')?
                   <td>{list.work_phone}</td>:[]}

                  {getSubHeaderName('Contact Address', 'Other Phone')?
                 <td>{list.other_phone}</td>:[]}

                  {getSubHeaderName('Contact Address', 'Personal Email')?
                  <td>{list.personal_email}</td>:[]}

                  {getSubHeaderName('Contact Address', 'Work Email')?
                <td>{list.work_email}</td>:[]}


                    
                    

                   
                   
                    
                   
                    

                    
                   
                   
                    
                   
                    

{getHeaderName('Emergency Contact')?  <td colSpan={5}>

                  
                    

                      {JSON.parse(list.emergency_contact).length!==0?
                      <thead>

             {getSubHeaderName('Emergency Contact', 'Fullname')?
                  <th >Fullname</th>:[]}


                {getSubHeaderName('Emergency Contact', 'Relationship')?
                      <th>Relationship</th>:[]}

                      {getSubHeaderName('Emergency Contact', 'Mobile')?
                      <th >Mobile</th>:[]}

                      {getSubHeaderName('Emergency Contact', 'Email Address')?
                      <th >Email Address</th>:[]}

                      {getSubHeaderName('Emergency Contact', 'Home Telephone')?
                      <th >Home Telephone</th>:[]}



                    </thead>:[]}
             
                    
                     
                      {
                        JSON.parse(list.emergency_contact).map((data:any, index:number)=><tr key={index}>


          {getSubHeaderName('Emergency Contact', 'Fullname')?
                  <td>{data.full_name}</td>:[]}

                {getSubHeaderName('Emergency Contact', 'Relationship')?
                     <td>{data.relationship}</td>:[]}

                      {getSubHeaderName('Emergency Contact', 'Mobile')?
                     <td>{data.mobile}</td>:[]}

                      {getSubHeaderName('Emergency Contact', 'Email Address')?
                       <td>{data.email}</td>:[]}

                      {getSubHeaderName('Emergency Contact', 'Home Telephone')?
                      <td>{data.home_phone}</td>:[]}
                  
                        </tr>)
                      }


                    </td>:[]}


                    {getHeaderName('Family')? <td colSpan={3}>

                    {JSON.parse(list.family).length!==0?
                      <thead>
                       {getSubHeaderName('Family', 'Fullname')?
                  <th >Fullname</th>:[]}

              {getSubHeaderName('Family', 'Relationship')?
                      <th>Relationship</th>:[]}

                      {getSubHeaderName('Family', 'NIN')?
                      <th >NIN</th>:[]}
                    </thead>:[]}

                

                    {
                        JSON.parse(list.family).map((data:any, index:number)=><tr key={index}>


          {getSubHeaderName('Family', 'Fullname')?
                    <td>{data.full_name}</td>:[]}

                {getSubHeaderName('Family', 'Relationship')?
                        <td>{data.relationship}</td>:[]}

                        {getSubHeaderName('Family', 'NIN')?
                        <td>{data.nin}</td>:[]}

                 
                        </tr>)
                      }
                    </td> :[]}



                    {getHeaderName('Passport, Visa & Licences')?  <td colSpan={7}>
                   
                    {JSON.parse(list.passport_visa).length!==0?
                      <thead>
                     
                     {getSubHeaderName('Passport, Visa & Licences', 'Document Type')?

                  <th >Document Type</th>:[]}

                  {getSubHeaderName('Passport, Visa & Licences', 'Document Number')?
                  <th>Document Number</th>:[]}

                  {getSubHeaderName('Passport, Visa & Licences', 'Issuing Authority')?
                  <th >Issuing Authority</th>:[]}

                  {getSubHeaderName('Passport, Visa & Licences', 'Issued Date')?
                  <th >Issued Date</th>:[]}

                  {getSubHeaderName('Passport, Visa & Licences', 'Expiry Date')?
                  <th>Expiry Date</th>:[]}

                  {getSubHeaderName('Passport, Visa & Licences', 'Renew Date')?
                  <th >Renew Date</th>:[]}

                  {getSubHeaderName('Passport, Visa & Licences', 'Issuing Country')?
                  <th >Issuing Country</th>:[]}

                    </thead>:[]}


                        {
                          JSON.parse(list.passport_visa).map((data:any, index:number)=><tr key={index}>
                    {getSubHeaderName('Passport, Visa & Licences', 'Document Type')?
                    <td>{data.document_type}</td>:[]}

                    {getSubHeaderName('Passport, Visa & Licences', 'Document Number')?
                    <td>{data.document_number}</td>:[]}

                    {getSubHeaderName('Passport, Visa & Licences', 'Issuing Authority')?
                    <td>{data.issuer}</td>:[]}

                    {getSubHeaderName('Passport, Visa & Licences', 'Issued Date')?
                    <td>{data.issued_date}</td>:[]}

                    {getSubHeaderName('Passport, Visa & Licences', 'Expiry Date')?
                    <td>{data.expiry_date}</td>:[]}

                    {getSubHeaderName('Passport, Visa & Licences', 'Renew Date')?
                    <td>{data.renewed_date}</td>:[]}

                    {getSubHeaderName('Passport, Visa & Licences', 'Issuing Country')?
                    <td>{data.country}</td>:[]}
                          </tr>)
                        
                      }
                    </td> :[]}



                    {getHeaderName('Education History')? <td colSpan={5}>
                   
                    {JSON.parse(list.education_history).length!==0?
                      <thead>
                        {getSubHeaderName('Education History', 'Certificate')?
                      <th>Certificate</th>:[]}

                      {getSubHeaderName('Education History', 'Institution')?
                  <th >Institution</th>:[]}

                  {getSubHeaderName('Education History', 'Grade')?
                  <th >Grade</th>:[]}

                  {getSubHeaderName('Education History', 'Year Start')?
                  <th>Year Start</th>:[]}

                  {getSubHeaderName('Education History', 'Year End')?
                  <th >Year End</th>:[]}


                    </thead>:[]}


                    {
                          JSON.parse(list.education_history).map((data:any, index:number)=><tr key={index}>
                    {getSubHeaderName('Education History', 'Certificate')?
                    <td>{data.certificate}</td>:[]}

                    {getSubHeaderName('Education History', 'Institution')?
                    <td>{data.institution}</td>:[]}

                    {getSubHeaderName('Education History', 'Grade')?
                    <td>{data.grade}</td>:[]}

                    {getSubHeaderName('Education History', 'Year Start')?
                    <td>{data.year_start}</td>:[]}

                    {getSubHeaderName('Education History', 'Year End')?
                    <td>{data.year_end}</td>:[]}
                          </tr>)

                      }
                    </td>:[]}



                    {getHeaderName('Work Experience')? <td colSpan={5}>
                      
                    {JSON.parse(list.work_history).length!==0?
                      <thead>

{getSubHeaderName('Work Experience', 'Company')?
                     <th>Company</th>:[]}


{getSubHeaderName('Work Experience', 'Job Title')?
                  <th >Job Title</th>:[]}


{getSubHeaderName('Work Experience', 'Start Date')?
                  <th >Start Date</th>:[]}


{getSubHeaderName('Work Experience', 'End Date')?
                  <th>End Date</th>:[]}


{getSubHeaderName('Work Experience', 'Responsibilities')?
                  <th >Responsibilities</th>:[]}


                    </thead>:[]}

                      {
                          JSON.parse(list.work_history).map((data:any, index:number)=><tr key={index}>
                    
{getSubHeaderName('Work Experience', 'Company')?
                    <td>{data.company}</td>:[]}


{getSubHeaderName('Work Experience', 'Job Title')?
                    <td>{data.job_title}</td>:[]}

{getSubHeaderName('Work Experience', 'Start Date')?
                    <td>{data.start_date}</td>:[]}

{getSubHeaderName('Work Experience', 'End Date')?
                    <td>{data.end_date}</td>:[]}

{getSubHeaderName('Work Experience', 'Responsibilities')?
                    <td>{data.responsibility}</td>:[]}
                          </tr>)

                      }


                    </td>:[]}


                    {getHeaderName('Membership & Awards')?  <td colSpan={3}>
                     
                      {JSON.parse(list.membership_awards).length!==0?
                      <thead>

{getSubHeaderName('Membership & Awards', 'Award Type')?
                       <th>Type</th>:[]}
                       {getSubHeaderName('Membership & Awards', 'Title')?
                  <th >Title</th>:[]}
                  {getSubHeaderName('Membership & Awards', 'Year')?
                  <th >Year</th>:[]}


                    </thead>:[]}

                      {
                          JSON.parse(list.membership_awards).map((data:any, index:number)=><tr key={index}>
                    {getSubHeaderName('Membership & Awards', 'Award Type')?
                    <td>{data.award}</td>:[]}

                    {getSubHeaderName('Membership & Awards', 'Title')?
                    <td>{data.title}</td>:[]}
                    {getSubHeaderName('Membership & Awards', 'Year')?
                    <td>{data.year}</td>:[]}
                          </tr>)

                      }
                    </td>:[]}



                    {getSubHeaderName('Job Details', 'Joined Date')?
                  <td>{list.joined_date}</td>:[]}

                  {getSubHeaderName('Job Details', 'Confirmation Date')?
                  <td>{list.confirmation_date}</td>:[]}

                  {getSubHeaderName('Job Details', 'Date of Leaving')?
                 <td>{list.leaving_date}</td>:[]}

                  {getSubHeaderName('Job Details', 'Current Department/Unit')?
                 <td>{list.department_name}</td>:[]}

                  {getSubHeaderName('Job Details', 'Employment Status')?
                 <td>{list.employment_status}</td>:[]}

                  {getSubHeaderName('Job Details', 'Job Title')?
                  <td>{list.job_title}</td>:[]}

                  {getSubHeaderName('Job Details', 'Work Station')?
                  <td>{list.work_station}</td>:[]}
                

                
                   
                    
                    
                    
                    
                   


                  







                   
                   

                {getHeaderName('Document & Files')?    <td colSpan={2}>
                     
                    {JSON.parse(list.documents).length!==0?
                      <thead>
                        {getSubHeaderName('Document & Files', 'Title')?
                     <th >Title</th>:[]}
                     {getSubHeaderName('Document & Files', 'Description')?
                  <th >Description</th>:[]}


                    </thead>:[]}

                   

                      {
                          JSON.parse(list.documents).map((data:any, index:number)=><tr key={index}>
                    
                    {getSubHeaderName('Document & Files', 'Title')?
                     <td>{data.title}</td>:[]}
                     {getSubHeaderName('Document & Files', 'Description')?
                  <td>{data.description}</td>:[]}


                   
                   
                          </tr>)

                      }

                    </td>:[]}


                    {getSubHeaderName('Bank Account Details', 'Bank')?
                  <td>{list.bank_name}</td>:[]}

                  {getSubHeaderName('Bank Account Details', 'Account Name')?
                  <td>{list.account_name}</td>:[]}

                  {getSubHeaderName('Bank Account Details', 'Account Number')?
                   <td>{list.account_number}</td>:[]}



                   
                   
                  {getSubHeaderName('Account Setup', 'Passport')?
                  <td>
                  <img src={ImagesUrl+'/employees/passport/'+list.passport} alt="user image" className="img-radius img-40 " onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+list.gender+'.png'}} />
                  </td>:[]}

                  {getSubHeaderName('Account Setup', 'Signature')?
                  <td>
                  <img src={ImagesUrl+'/employees/signature/'+list.signature} alt="user image" className=" img-30" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/no.jpeg'}} />
                  </td>:[]}
                  

                     

                     
                  {getSubHeaderName('Terminations', 'Termination Reason')?
                 <td>{ getTitleName(list.termination_reason, 'reason')}</td>:[]}

                  {getSubHeaderName('Terminations', 'Termination Note')?
                 <td>{list.termination_note}</td>:[]}

                  {getSubHeaderName('Terminations', 'Account Status')?
                   <td>{list.account_status}</td>:[]}


                      
                    
                   


                </tr>):[]}
                
               
               
                </tbody>

        </table>
        
        </div>
        </div></div>

    </div>

    <div className="row">
        <div className="col-md-12">
        <button type="button" className="btn btn-success  no-print" onClick={PrintMe} > <i className="fa fa-print"></i> Print</button>
        </div></div>
    </div>
</div>
</div>
</div>


</div>
</div>


</div>
</div>  
        </>               
 
  )
}

export default  PreviewEmployee