
export const WORK_STATUS =[
    'Full-Time Contract',
    'Full-Time Permanent',
    'Full-Time Probation',
    'Part-Time Contract',
    'Part-Time Internship',
    'Freelance',
    'Volunteer',
    'On Leave'
                ]


    export const ASSET_CATEGORY =[
        'Funitures & Fittings',
        'Office Equipment',
        'Office Equipment',
        'Computers',
        'Automobile',
        'Others'
    ]


    export const ASSET_CONDITION =[
        'New',
        'Old',
        'Used',
        'Missing',
        'Out for Repair',
        'Lost / Stolen',
        'Broken - Not Repairable',
        'Damage - awaiting Service'
    ]
 export  const DateList = [
                    {label:'2023-01-01 - 2023-12-31', value:'2023-01-01 - 2023-12-31', start_date:'2023-01-01', end_date:'2023-12-31'},
                    {label:'2024-01-01 - 2024-12-31', value:'2024-01-01 - 2024-12-31',  start_date:'2024-01-01', end_date:'2023-12-31'},
                    {label:'2025-01-01 - 2025-12-31', value:'2025-01-01 - 2025-12-31', start_date:'2025-01-01', end_date:'2023-12-31'},
                    {label:'2026-01-01 - 2026-12-31', value:'2026-01-01 - 2026-12-31', start_date:'2026-01-01', end_date:'2023-12-31'},
                ]

