import { useEffect, useState} from 'react'
import { COMPANY_CODE, ServerUrl, config } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, CustomLoader, closeModal, Alerts } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'

import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';

const MessageTemplate =()=> {

    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);



    const DeleteModal =(row:any)=>{
        let url = ServerUrl+'/api/employees/report/remove/'+row.id
      
        Swal.fire({
          title: 'Are you really sure?',
          text: "This is very dangerous, you shouldn't do it!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          showLoaderOnConfirm: true,
        preConfirm: async() => {
          return await axios.get(url, config).then(response=>{
           if(response.data.status!==200){
                throw new Error(response.statusText)
              }
              return response.data
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Request failed: ${error}`
              )
            })
        },
        allowOutsideClick: () => !Swal.isLoading(),
          confirmButtonText: 'Yes, I am!'
        }).then((result:any) => {
          if (result.isConfirmed && result.value.status===200) {
           
            let rs =content.filter((item:{id:number})=>item.id !==row.id)
                setContent(rs)
             Swal.fire(
                        'Deleted!',
                        'Record has been deleted.',
                        'success'
                      ) 
          }
        })
      }


const  handleChangeStatus=(row:any, field:string)=>{


  const fd = new FormData();
 let status = row[field] ==='Active'?'Inactive':'Active'

     fd.append('code', row.code);
     fd.append('status', status);
     fd.append('id', row.id);
     fd.append('field', field);
  let url = ServerUrl+'/api/setup/message_template/change_status';
axios.post(url, fd, config)
.then(response =>{
  if(response.data.status === 200){
   
      const newContent = content.map((item:any)=>{
 
          if(item.code === row.code){
          return {...item, 
              [field]:status,
          }
          };
          return item;
          })
          setContent(newContent)
            } else{
              Alerts('danger',response.data.message)
            }   
        })
        .catch((error)=>{
          Alerts('danger',error.message)
        })  
}

const TableData =()=>{


    const columns = [
      {
        name: 'SN',
        selector: (row:any, index:any) => index+1,
        sortable: true,
        wrap:true,
        width:'100px'
    },
        {
            name: 'Template Name',
            selector: (row:any) => row.template_name,
            sortable: true,
            wrap:true
        },
        {
          name: 'SMS',
          selector: (row:any) => <label className="switch-md">
          <input type="checkbox" checked={row.is_sms==='Active'?true:false} onChange={()=>handleChangeStatus(row, 'is_sms')}  />
          <span className="round slider-md"></span>
        </label>,
          sortable: true,
          wrap:true
      },
      {
        name: 'Email',
        selector: (row:any) => <label className="switch-md">
        <input type="checkbox" checked={row.is_email==='Active'?true:false} onChange={()=>handleChangeStatus(row, 'is_email')}  />
        <span className="round slider-md"></span>
      </label>,
        sortable: true,
        wrap:true
    },
    {
      name: 'Mobile',
      selector: (row:any) => <label className="switch-md">
      <input type="checkbox" checked={row.is_mobile==='Active'?true:false} onChange={()=>handleChangeStatus(row, 'is_mobile')}  />
      <span className="round slider-md"></span>
    </label>,
      sortable: true,
      wrap:true
  },
        {
            name: 'Action',
            cell: (row:any, index:any, column:any, id:any) => <a href={`/setup/configure/message_template/edit/${row.code}`} className="btn btn-info btn-sm"  ><i className='ti-pencil'></i> Modify</a>,
                left:true
                      
        },
    ]
    
  

    return <DataTable
    columns={columns}
    data={content}
    dense
    highlightOnHover
    pagination
    selectableRowsHighlight
    striped
    progressPending={loading}
	progressComponent={<CustomLoader />}
/>

}

        const fetchReport =()=>{

            LoadingModal()
            setLoading(true)
            let url = ServerUrl+'/api/setup/message_template/view/'+COMPANY_CODE
            axios.get(url, config).then(result=>{
              if(result.data.status===200){
                setContent(result.data.data)
             
              }
            })
            .catch((error)=>{
              Alerts('danger', error.message)
            }).finally(()=>{
                closeModal()
                setLoading(false)
            })
            }


            useEffect(()=>{
                fetchReport()
            },[])

  return ( <>
   <Breadcrumb 
   title="Message Template"
   summary="Configure communication template here"
   >
   <li className="breadcrumb-item">
    <Link to="#!">Template</Link>
                    </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
<div className="main-body">
<div className="page-wrapper">
<div className="page-body">



<div className="row">
<div className=" col-md-12">
<div className="card z-depth-0">
    <div className="card-header">
        <h5>Email & SMS  Templates</h5>
        <span className="text-muted">Description on  <a href="#!" target="_blank">amCharts</a> how and what this page does here.</span>
       
       
       
        <div className="card-header-right">

<a href='/setup/configure/message_template/create' className="btn btn-primary  "><i className='fa fa-circle-plus text-white'></i> Add New</a>
            
        </div>
    </div>
    <div className="card-block ">
       


<div className="table-responsive m-t-10">
    <TableData />
    </div>


    </div>
</div>
</div>
</div>


</div>
</div>


</div>
</div>  
        </>               
 
  )
}

export default MessageTemplate 