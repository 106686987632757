import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, STAFF_CODE, ServerUrl, config } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, Alerts } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'

import { useQuery } from 'react-query'
import { fetchDepartment } from '../../api/ReactQuery';
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import { ASSET_CATEGORY, ASSET_CONDITION } from '../../components/Data';
const  BulkAssets =()=> {
   


  const department = useQuery(["department"], fetchDepartment)
  const Initials = 
  { 
    asset_name:'',
    category:'',
    condition:'',
    department:'',
    model:'' 
  }

    const [errors, setErrors] = useState({
      asset_name:'',
      assets:'',
      errorMessage:''
       
     })
     const [assets, setAssets]= useState([] as any)
    

     const  GenerateRow =()=>{  
      let value:any = [];
      for (var i = 1; i <= 10; i++) {
        value.push({...Initials,  code:Math.random().toString(36).substr(2, 9)});
      }      
      setAssets(value)

  } 

  const createNewRow =()=>{
    setAssets(assets.concat({...Initials, code:Math.random().toString(36).substr(2, 9)}))
    setErrors({...errors, assets:'', errorMessage:''})

}

const removeRow=(index:number)=>{

  if(assets.length!==1){
    let item = assets.filter((_:any,idx:number)=>idx!==index)
    setAssets(item)
    }
   }


   const handleChange =(event:ChangeEvent<HTMLInputElement>, code:string)=>{
    const {name, value} = event.target;

    const newassets = assets.map((data:any)=>{
        if(data.code === code){
            return {...data, 
                [name]:value
            }
            };
            return data;
    })
    setAssets(newassets)
}

const handleChangeSelect =(event:ChangeEvent<HTMLSelectElement>, code:string)=>{
  const {name, value} = event.target;

  const newassets = assets.map((data:any)=>{
      if(data.code === code){
          return {...data, 
              [name]:value
          }
          };
          return data;
  })
  setAssets(newassets)
}

   


      const handleSubmit =(event: FormEvent<HTMLFormElement>)=>{
        event.preventDefault();
    
    
        LoadingModal()
       
        const fd = new FormData();
        fd.append('added_by', STAFF_CODE);
        fd.append('company_code', COMPANY_CODE);
        fd.append('assetsList',  JSON.stringify(assets, null, 2))
    
    let url = ServerUrl+'/api/assets/bulk_registration';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.status === 200){
         SuccessModal(response.data.message)
       
                  } else{
                    Alerts('danger', response.data.message)
                  }   
              })
              .catch((error)=>{
                Alerts('danger', error.message)
              }).finally(()=>{
                GenerateRow()
    
              })
        
    
    }
    


    useEffect(()=>{
  
      GenerateRow()
      }, [])


    

  return ( <>
  <Breadcrumb 
   title="Bulk Registration"
   summary="Create Multiple Asset"
   >
    <li className="breadcrumb-item">
    <Link to="/assets/display_assets">Assets</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Bulk Registration</a> </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Record Bulk Assets</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">

          
<div className="well">You can use this page to add bulk assets. Please be careful that you select the right condition, department and category. You can enter as many rows as you like by clicking on Add more.</div>


            <div className="col-md-12">
                   
    
        
{assets.length!==0?

<div  className="card z-depth-0" >
		<div  className="card-block">
        <div className="row">
       
     
        <div className="table-responsive">
        <form action="#" onSubmit={handleSubmit}>
        <table className="table">
            <thead>
                <tr style={{background:'#404E67', color:'#fff'}}>
                <th>SNo</th>
                    <th>Asset Name</th>
                    <th>Category</th>
                    <th>Condition</th>  
                    <th>Department</th> 
                    <th>Model</th>
                    
    <th ><a onClick={createNewRow} href="#!" style={{color:'#fff'}}><i className="fa fa-plus"></i> Add More</a></th>
                </tr>
            </thead>
            <tbody>

            
                {assets&&assets.map((pl:any, id:number)=>
            <tr key={id}>
                
                    <td style={{backgroundColor:'#d2d6de'}}>{id+1}</td>


                    <td><input type="text"  required className="form-control" name='asset_name' 
                    value={pl.asset_name}  onChange={(e)=>handleChange(e, pl.code)} /></td>



                   <td > 
                        <select onChange={(e)=>handleChangeSelect(e, pl.code)} required name="category"  value={pl.category} className="form-control">

                <option value="">Select</option>
                {ASSET_CATEGORY.map((data:any, id:number)=>
                <option key={id} value={data}>{data}</option>)}
                    </select>
                     </td>



                     <td > 
                        <select onChange={(e)=>handleChangeSelect(e, pl.code)} required name="condition"  value={pl.condition} className="form-control">

                <option value="">Select</option>

                {ASSET_CONDITION.map((data:any, id:number)=>
                <option key={id} value={data}>{data}</option>)}
               
                    </select>
                     </td>

                    <td > 
                        <select onChange={(e)=>handleChangeSelect(e, pl.code)}  name="department"  value={pl.department} className="form-control">

<option value="">Select</option>
{  department.data&&department.data.map((list:any, idx:number)=> {
           return  <option key={idx} value={list.code} >{list.department_name}</option> })}
    </select>
                     </td>


                      <td>
                      <input type="text" className="form-control" name='model' autoComplete="false" value={pl.model} onChange={(e)=>handleChange(e, pl.code)} />
                      
                      </td>
                   
                    <td > <button type="button" onClick={()=>removeRow(id)} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></button></td>
                </tr>)}
               
            </tbody> 
            <tfoot>
                <tr >
                    <td colSpan={9}>

                    
                <div className="pull-right">
	
	<button type="submit" id="submit"   className="btn btn-success"><i className="fa fa-save"></i> Submit </button>
								</div>
                                </td>
                </tr>
            </tfoot>
           
        </table>
        </form>
        </div>
       
        </div>
        </div>
</div>:''}
                       

    
    </div>
</div>
</div>
                            </div>
                        </div>
                    </div>
      </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default BulkAssets