import { useState, ChangeEvent, FormEvent, useEffect} from 'react'
import { COMPANY_CODE, STAFF_CODE, ServerUrl, config, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, Alerts, closeModal } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'
import { useQuery } from 'react-query'

import { useParams } from 'react-router'
import "react-datepicker/dist/react-datepicker.css";
import { fetchDepartment } from '../../api/ReactQuery';
import { Link } from 'react-router-dom';
import { ASSET_CATEGORY, ASSET_CONDITION } from '../../components/Data';
const  AssetUpdate =()=> {
   
  const department = useQuery(["department"], fetchDepartment)
  let params = useParams();



    const [asset, setAsset] = useState([] as any)
const [photo, setPhoto] = useState('')

    const [errors, setErrors] = useState({
      asset_name:'',
      category:'',
      image:'',
      condition:''
    })
    
    
  

     const handleSelect = (option:any, action:any)=>{
         setAsset({...asset, [action.name]:option.value});
          setErrors({...errors, [action.name]:''})
           }



           const handleChangeArea = (event:ChangeEvent<HTMLTextAreaElement>)=>{
           
            let {name, value} = event.target;	
            setAsset({...asset, [name]:value});
           setErrors({...errors, [name]:''});
        }


        const handleChange = (event:ChangeEvent<HTMLInputElement>)=>{
           
          let {name, value} = event.target;	
          setAsset({...asset, [name]:value});
         setErrors({...errors, [name]:''});
      }
        
      

      const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
        event.preventDefault();
    
        let error = {...errors}; 
        let formIsValid = true;
    
    let msg ='This field is required';
  
    if(!asset.asset_name){
        error.asset_name=msg;
        formIsValid = false;
      }

      if(!asset.category){
        error.category=msg;
        formIsValid = false;
      }


      if(!asset.condition){
        error.condition=msg;
        formIsValid = false;
      }


    
    if(!formIsValid){
    setErrors(error) 

    window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
    }
    
    if(formIsValid) {
    
        LoadingModal()

       

        const fd = new FormData();
        Object.entries(asset).forEach(([key, value]) => {
            fd.append(key, String(value));
        });
       

        if(photo){
            fd.append('imageFile', photo, asset.image_url); 
        } 

    
    let url = ServerUrl+'/api/assets/update_asset';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.status === 200){
         SuccessModal(response.data.message)
        window.open("/assets/display_assets", '_self')
                  } else{
                    Alerts('danger', response.data.message)
                  }   
              })
              .catch((error)=>{
                Alerts('danger', error.message)
              }).finally(()=>{
         
    
              })
        }
    
    }
    

    const handleCancel =()=>{
        window.open("/assets/display_assets", '_self')
    }
    
    const getDepartment =(code:string)=>{
        if( department.data&&department.data.length!==0){

       const result =department.data.filter((item:any)=>item.code===code);
      const answer = result.length!==0?result[0].departmentName:''
      return  answer
        }else{
            return ''
        }
    }



const  handleFile = function(fieldName:any){
  return function(newValue:any){                 
 readURL(newValue);
 }
} 



const readURL = (input:any)=>{

let displayMessage = '';

const PreviewImage = input.target.name;
const inputElement = document.getElementById('photo') as HTMLInputElement
const fileName = inputElement.value

var idxDot = fileName.lastIndexOf(".") + 1;
var extFile = fileName.substring(idxDot, fileName.length).toLowerCase();

/* if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){ */
  //TO DO    
  
   var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
if (FileSize > 1) {
  displayMessage = ' File uploaded is larger than maximum size Allow (1 mb).';
  inputElement.value = '';
  setPhoto('')
} else {
  if (input.target.files && input.target.files[0]) { //Check if input has files.
      var reader = new FileReader();//Initialize FileReader.
      reader.onload = function (e:any) {
          
      //Check if input has files.
      let Id = Math.random().toString(36).substr(2, 9)
      let fileUrl = Id+'_'+input.target.files[0].name
      setPhoto(input.target.files[0])
      setAsset({...asset, image_url:fileUrl })
        setErrors({...errors, image:''}); 
      }
      reader.readAsDataURL(input.target.files[0]);
      
  }
}
/* }else{
displayMessage = 'Only JPEG|PNG|JPG  file format are allowed with maximum of 5 mb'
inputElement.value = '';
setItem({...item, file_url:'', file_name:''})
}    */

if(displayMessage.length > 0){
Alerts("danger", displayMessage)
}
}


const fetchAsset =()=>{

  LoadingModal()
  
  let url = ServerUrl+'/api/asset/edit_asset/'+COMPANY_CODE+'/'+params.code
  axios.get(url, config).then(result=>{
    if(result.data.status===200){
      try {
          setAsset(result.data.data[0])

      }catch(e){

      }
     
    }
  })
  .catch((error)=>{
   Alerts('danger', error.message)
  }).finally(()=>{
      closeModal()
  })
  }

useEffect(()=>{
    fetchAsset()
}, [])
    

  return ( <>
   <Breadcrumb 
   title="Edit Asset"
   summary="Modify Asset Created"
   >
    <li className="breadcrumb-item">
    <Link to="/assets/display_assets">Assets</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Edit Asset</a> </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Update Asset</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                              
                                    
                                    
         <div className="content-wrappers ">
                            
         <div className=''>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Asset Information</h5>
                <span>Add all offcie department</span>
              
            </div>
            <div className="card-block">
    
        
                       

                <div className="row">



                <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Asset Name  <span className='text-danger'>*</span></label>



                               
            <input type="text" className={errors.asset_name?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  value={asset.asset_name} name="asset_name" onChange={handleChange} />
                              

                {errors.asset_name&&
    <div className="col-form-label has-danger">{errors.asset_name&&errors.asset_name} </div>}

                                 </div>
                                 </div>


                               <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Barcode</label>



                               
            <input type="text" className="form-control form-control-lg"  value={asset.barcode} name="barcode" onChange={handleChange} />
                              


                                 </div>
                                 </div>



                                 <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Select Category <span className='text-danger'>*</span></label>


                                <Select  options={
                                    ASSET_CATEGORY.map((list:any, id:number)=>{
                                        return {key:id, value:list, label:list}
                                            })} 
                                    
                          onChange={handleSelect} 
                          className={errors.category? 'form-control form-control-danger' : ''} 
                          name="category" 
                          value={{label:asset.category, value:asset.category}} 
                          
                          styles={styles}  
                          /> 
                        

                {errors.category&&
    <div className="col-form-label has-danger">{errors.category&&errors.category} </div>}

                                 </div>
                                 </div>


                                 <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Registered / Serial Number</label>

            <input type="text" className="form-control form-control-lg"  value={asset.serial_no} name="serial_no" onChange={handleChange} />
                              


                                 </div>
                                 </div>


                                 <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Select Condition <span className='text-danger'>*</span></label>


                                <Select   options={
                                    ASSET_CONDITION.map((list:any, id:number)=>{
                                        return {key:id, value:list, label:list}
                                            })}              
                          onChange={handleSelect} 
                          name="condition" 
                          value={{label:asset.condition, value:asset.condition}} 
                          className={errors.condition? 'form-control form-control-danger' : ''} 
                          styles={styles}  
                          /> 
                              

                              {errors.condition&&
    <div className="col-form-label has-danger">{errors.condition&&errors.condition} </div>}
                                 </div>
                                 </div>



                                 <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Model</label>
                               
            <input type="text" className="form-control form-control-lg"  value={asset.model} name="model" onChange={handleChange} />
                              

                                 </div>
                                 </div>


    </div>

    
            </div>
        </div>
    </div>
    </div>
    



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Asset Location</h5>
                <span>Add all offcie department</span>
              
            </div>
            <div className="card-block">
    
        
                       

                <div className="row">

                <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Select Department</label>
    
    
    <Select  options={
            department.data&&department.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.department_name}
                    })}   
                    styles={styles} 
			onChange={handleSelect}    
            name="department" 
            value={{label:getDepartment(asset.department), value:asset.department}}
         
               />
    
    </div>
    
    </div>


             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Purchase Date </label>



            <input type="date" className="form-control form-control-lg" value={asset.purchase_date} name="purchase_date" onChange={handleChange} />




          
            </div>  
            
            </div> 




                                 <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Current Location </label>



                               
            <input type="text" className="form-control form-control-lg" value={asset.current_location} name="current_location" onChange={handleChange} />
                              

                                 </div>
                                 </div>

                                 <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Purchase Amount </label>


            <input type="number" className="form-control form-control-lg"  value={asset.purchase_amount} name="purchase_amount" onChange={handleChange} />
                              


                                 </div>
                                 </div>

                                 <div className="col-md-6">
  <div className="form-group">
  <label className="col-form-label">Description</label>

  <textarea name="description" value={asset.description} onChange={handleChangeArea}  className="form-control"  placeholder="Maximum 100 words" >{asset.description}

</textarea>
  </div>
</div>


<div className="col-md-6  m-b-30">
        <h4 className="sub-title">Is Movable</h4>
        <div className="form-radio">
            <form>
                <div className="radio radio-inline">
                    <label>
                        <input type="radio" name="is_movable" value="Yes" onChange={handleChange} checked={asset.is_movable ==='Yes'?true:false} />
                        <i className="helper"></i>Yes
                    </label>
                </div>
                <div className="radio radio-inline">
                    <label>
                        <input type="radio" name="is_movable" value="No" onChange={handleChange} checked={asset.is_movable==='No'?true:false} />
                        <i className="helper"></i>No
                    </label>
                </div>
            </form>
        </div>
        </div>


    </div>


    
            </div>
        </div>
    </div>
    </div>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Fair Usage</h5>
              
            </div>
            <div className="card-block">
    
        
                       

                <div className="row">
             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Waranty Expiry Date </label>



            <input type="date" className="form-control form-control-lg" value={asset.waranty_expiry_date} name="waranty_expiry_date" onChange={handleChange} />


            </div>  
            
            </div> 



            <div className="col-md-6  m-b-30">
        <h4 className="sub-title">Is Insured?</h4>
        <div className="form-radio">
            <form>
                <div className="radio radio-inline">
                    <label>
                        <input type="radio" name="is_insured" value="Yes" onChange={handleChange} checked={asset.is_insured ==='Yes'?true:false} />
                        <i className="helper"></i>Yes
                    </label>
                </div>
                <div className="radio radio-inline">
                    <label>
                        <input type="radio" name="is_insured" value="No" onChange={handleChange} checked={asset.is_insured ==='No'?true:false} />
                        <i className="helper"></i>No
                    </label>
                </div>
            </form>
        </div>
        </div>


        <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Expected Life Span </label>
        
            <input type="number" className="form-control form-control-lg"  value={asset.life_span} name="life_span" onChange={handleChange} />
                              


                                 </div>
                                 </div>



                                 <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Insurance Date </label>


            <input type="date" className="form-control form-control-lg" value={asset.insurance_date} name="insurance_date" onChange={handleChange} />


            </div>  
            
            </div> 



            <div className="col-md-6">
  <div className="form-group">
  <label className="col-form-label">Description</label>

  <textarea name="description" value={asset.description} onChange={handleChangeArea}  className="form-control"  placeholder="Maximum 100 words" >{asset.description}

</textarea>
  </div>
</div>
<div className="col-md-6">
<div className="form-group">
                           <label >Asset Image</label><br/>
                  <input id="image"  onChange={handleFile('image')} className='form-control' name="image" type="file" accept="image/*" /> <span style={{color:'red'}}>{errors.image && errors.image}</span>
          <div className="note" style={{color:'red'}}>
          <strong>Size 900 kb,</strong>gif|jpg|png|jpeg Only Allow
      </div>	   	
              </div>
					
              </div>
    </div>



    
            </div>
        </div>
    </div>
    </div>

    <div className="row">
        <div className="col-md-12 text-right">
    
        <button className="btn btn-outline-info btn-round pr-5 pl-5 mr-3" type='button' onClick={handleCancel}>Cancel</button>&nbsp;
             <button type='button' onClick={handleSubmit} className="btn btn-success btn-round pr-5 pl-5">Save</button>
       
         </div>
        </div>

                              
    </div>

</div>
               
            </section>
        </div>
    </div>
</div>
</div>
                            </div>
                        </div>
                    </div>
      </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default AssetUpdate 