import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, CURRENCY, STAFF_CODE, ServerUrl, config, selectStyles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts, CustomLoader } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'

import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { FormatNumber, getMonthYear, toWords } from '../../components/GlobalFunction';
import { Link } from 'react-router-dom';

const  AssignLeave =()=> {
   
    const [item, setItem] = useState({
       staff:[] as any,
       effective_month:''
    });


    const [salaryStructure, setSalaryStructure]=useState({
        template_name:'',
        id:0,
        structure:[] as any
    });

const [salary, setSalary]=useState([]);
   

    const [template, setTemplate] = useState([] as any);
    const [staff, setStaff] = useState([] as any);
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
         staff:'',
         effective_month:''
       
     })
    

     const fetchStaff =async()=>{
        let url = ServerUrl+'/api/employees/view/'+COMPANY_CODE
        const {data} = await axios.get(url, config)
        setStaff(data.data)
        }

        const fetchTemplate =async()=>{
            
    let url = ServerUrl+'/api/leave/view_leave_type/'+COMPANY_CODE
            const {data} = await axios.get(url, config)
            setTemplate(data.data)
            }

   
     const handleSelect = (option:any, action:any)=>{
        setItem({...item, [action.name]:option});
          setErrors({...errors, [action.name]:''})

           }

           const handleChange = (event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
            setItem({...item, [name]:value});
           setErrors({...errors, [name]:''});
        }


           const handleChangeItem = (e:ChangeEvent<HTMLInputElement>, code:string)=>{
            let  value = e.target.value.replace(/[^0-9.]/g, '');
            let newTemplate:any = template.map((item:any)=>{
                if(item.code === code){
                   /*  if(Number(value)>item.no_days){
                        Alerts('danger', 'Number of days to assign cannot be more than Days Setup')
                        value= item.no_days
                    } */

                    return {...item, 
                        no_days:value,
                    }
                    };
                    return item;
              })
              setTemplate(newTemplate); 
        }

      


const handleReset =()=>{
    setItem({
        staff:[] as any,
        effective_month:''
    })
    setSalary([])
    setSalaryStructure({
        template_name:'',
        id:0,
        structure:[] as any
    })
}




  const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(item.staff.length===0){
    error.staff =msg;
    formIsValid = false;
} 


if(!item.effective_month){
    error.effective_month =msg;
    formIsValid = false;
} 
 
if(template.length===0){
    error.effective_month ='There are no leave type created yet';
    formIsValid = false;
} 

if(!formIsValid){
setErrors(error) 
}

if(formIsValid) {

    LoadingModal()
   
    var options = item.staff
                    var value = [];
                    for (var i = 0, l = options.length; i < l; i++) {

                        for (var k = 0, m = template.length; k < m; k++) {
                      value.push({
                        staff_code:options[i].value,
                        leave_type_code:template[k].code,
                        no_days:template[k].no_days,
                        total_used:0,
                        unique_code:Math.random().toString(36).substring(2, 9)

                    })
                } }

    const fd = new FormData();
    let code = 'AS'+Math.random().toString(36).substring(2, 9)
    fd.append('effective_month', item.effective_month);
    fd.append('company_code', COMPANY_CODE);
    fd.append('code', code);
    fd.append('added_by', STAFF_CODE);
    fd.append('template', JSON.stringify(value, null, 2)); 

let url = ServerUrl+'/api/leave/assign_leave';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                Alerts('danger', response.data.message)
                closeModal()
              }   
          })
          .catch((error)=>{
            Alerts('danger', error.message)
            closeModal()
          }).finally(()=>{
         handleReset()
fetchData()
          })
    }

}



const DeleteModal =(staff_code:string)=>{
    let url = ServerUrl+'/api/leave/delete_assigned_leave/'+staff_code;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =content.filter((item:{staff_code:string})=>item.staff_code !==staff_code)
            setContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }

const fetchData = async()=>{
    setLoading(true)
    let url = ServerUrl+'/api/leave/staff_assign_leave/'+COMPANY_CODE
  await  axios.get(url, config).then((result:any)=>{
    if(Array.isArray(result.data.data) && result.data.data.length!==0){
      setContent(result.data.data)
      }else{
        setContent([])
      }
    })
    .catch((error)=>{
        Alerts('danger', error.message)
    }).finally(()=>{
        setLoading(false)
    })
    } 


    
const TableData =()=>{


    const columns = [
        {
            name: 'Employee',
            selector: (row:any) =>row.staff_name,
            sortable: true,
            reorder:true,
            wrap:true,
        },
        {
            name: 'Effective Month',
            selector: (row:any) => getMonthYear(row.effective_month),
            sortable: true,
            reorder:true,
        },
        
        {
            name: 'Action',
            cell: (row:any, index:any, column:any, id:any) => <div className='row'>  

               <label className="label label-inverse pointer" ><i className='fa fa-eye'></i> View</label>

               <label className="label label-danger pointer" onClick={()=>DeleteModal(row)}><i className='ti-trash'></i> Delete</label>
            </div>,
                left:true,
                width:'150px'
        },
    ];
    
  

    return <DataTable
    columns={columns}
    data={content}
    dense
    highlightOnHover
    pagination
    selectableRowsHighlight
    striped
    progressPending={loading}
	progressComponent={<CustomLoader />}
/>

}




    useEffect(()=>{
        fetchData()
        fetchTemplate()
        fetchStaff() 
      }, [])


    

  return ( <>
   <Breadcrumb 
   title="Assign Leave"
   summary="Assign leave to employee "
   >
    <li className="breadcrumb-item">
    <Link to="#!">Configure</Link>
                    </li>
   <li className="breadcrumb-item">
    <Link to="#!">Assign Leave</Link>
                    </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Assign Leave</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                              
                                    
                                    
         <div className="content-wrappers ">
                            
         <div className=''>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Employee Leave</h5>
                <span>Select staff and setup no of days of each leave assigned</span>
              
            </div>
            <div className="card-block">
    
        
                        <div className="row">
                  
                        <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Select Employee</label>


                 
                <Select 
                isMulti
                options={
                               staff&&staff.map((list:any, idx:number)=> {
                                return {key:idx, value: list.code, label: list.first_name, last_name:list.last_name, middle_name:list.middle_name, title:list.title }
                                })}
                                className={errors.staff?"form-control form-control-danger":""} 
                     getOptionLabel={option =>` ${option.label} ${option.last_name}`}
    onChange={handleSelect}  name="staff" value={item.staff} 
   styles={selectStyles}
    /> 
                    
                    {errors.staff&&
    <div className="col-form-label has-danger">{errors.staff&&errors.staff} </div>}
                    </div>
    
                     </div>


                     <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Effective Month <span className='text-danger'>*</span></label>
           
            <input type="month" className={errors.effective_month?"form-control form-control-danger":"form-control "}  name='effective_month' value={item.effective_month} onChange={handleChange}  />
          

            {errors.effective_month&&
    <div className="col-form-label has-danger">{errors.effective_month&&errors.effective_month} </div>}
             </div>

             </div>



                    </div>


                <div style={{display:!item.effective_month?'none':'block'}}className="row">
               
                    <div className="col-md-10">
                        <div className="table-responsive text-center">
                            <table className="table m-0 table-sm">
                                <tbody>

                                <tr>
                                    <th>Title</th>
                                    <th>No. of Days</th>
                                </tr>
     {template&&template.map((tit:any, id:number)=>

            <tr key={id} className='text-success'>
                <th scope="row" >{ tit.leave_name + ' - '+ tit.time_cycle}</th>
                <td> 
                
        <input type="number"
        pattern='[0-9]'
        className="form-control form-control-bold" placeholder='Days' min="0"  value={tit.no_days} name='no_days' onFocus={(e)=>e.target.select()}  onChange={(e)=>handleChangeItem(e, tit.code)} />
                                   
            </td>
            </tr>)}
         
                </tbody>
                </table>
            </div>
        </div>
                 
                   
</div>

<hr />
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-outline-danger btn-round pr-5 pl-5 mr-3" type='button' onClick={handleReset}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={handleSubmit}>Assign</button>
</div>
</div>
    
            </div>
        </div>
    </div>
    </div>
    
    
    
    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Staff Assigned Leave</h5>
                <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
              
            </div>
            <div className="card-block">
    
        <div className="table-responsive ">
        <TableData />
        </div>
    
            </div>
        </div>
    </div>
    </div>
    
                </div>

        </div>
                       
                    </section>
                </div>
            </div>
        </div>
    </div>
                                    </div>
                                </div>
                            </div>





                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default AssignLeave