import { useEffect, useState, FormEvent, ChangeEvent} from 'react'
import { COMPANY_CODE, ImagesUrl, STAFF_CODE, ServerUrl, config, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, closeModal, Alerts, SuccessModal } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'


import { useQuery } from 'react-query'
import Select from 'react-select'
import { FetchProfile, fetchCriteria, fetchDepartment} from '../../api/ReactQuery';
import "react-datepicker/dist/react-datepicker.css";
import { FormatNumber, getMonthYear } from '../../components/GlobalFunction';
import { Link } from 'react-router-dom';

const Appraisal =()=> {
    
    const {data} = useQuery(["criteria"], fetchCriteria)

  const settings = useQuery(["profile"], FetchProfile)
  const [profile, setProfile] = useState([] as any);

    const [indicator, setIndicator] = useState([] as any);
    const [assessment, setAssessment] = useState([] as any);
    const [staff, setStaff] = useState([] as any);
    const [search, setSearch] = useState({
     
        staff:[] as any,
        appraisal_month:'',
        employee_comment:'',
        maximum:0,
        score:0,
        total_score:0,
        percentage:0
     })

     const [errors, setErrors] = useState({
        staff:'',
        appraisal_month:'',
        employee_comment:''
       
     })



const handleReset =()=>{

    setSearch({
     ...search,
        staff:[] as any,
        employee_comment:'',
        score:0,
        percentage:0
     })
     setGoals(
        [{ 
            code:Math.random().toString(36).substring(2, 9),
            goal:'',
            period:''
     }])
     setDevelopment(
        [{ 
            code:Math.random().toString(36).substring(2, 9),
            improvement:'',
            outcome:'',
            person:''

        
     }]);
}



     const [goals, setGoals] = useState(
        [{ 
            code:Math.random().toString(36).substring(2, 9),
            goal:'',
            period:''

        
     }]);


     const [development, setDevelopment] = useState(
        [{ 
            code:Math.random().toString(36).substring(2, 9),
            improvement:'',
            outcome:'',
            person:''

        
     }]);

     const removeDevelopment =(index:number)=>{
        if(development.length!==1){
        let item = development.filter((_:any,idx:number)=>idx!==index)
        setDevelopment(item)
        }
      }
     const addDevelopment =()=>{
        setDevelopment(development.concat(
            { 
                code:Math.random().toString(36).substring(2, 9),
                improvement:'',
                outcome:'',
                person:''}
            ))
      }


      const removeGoal =(index:number)=>{
        if(goals.length!==1){
        let item = goals.filter((_:any,idx:number)=>idx!==index)
        setGoals(item)
        }
      }
     const addGoal =()=>{
        setGoals(goals.concat(
            { 
                code:Math.random().toString(36).substring(2, 9),
            goal:'',
            period:''
            }
            ))
      }

          

         const getCriteria =(code:string)=>{
          
            const rs =  data&&data.filter((item:any)=>item.code===code)
            let result = rs.length !==0? rs[0].title:''
            
            
            return result
         }



         const fetchAssessment =async(maximum:any)=>{
            let url = ServerUrl+'/api/performance/assessment/criteria/view/'+COMPANY_CODE


             await  axios.get(url, config).then((result:any)=>{

                 if(Array.isArray(result.data.data) && result.data.data.length!==0){

            const rs = result.data.data

            try{
                var total = []
                var itemList = [];
                for (var i = 0; i< rs.length;  i++) {
                    var list = [];
                    let temp = JSON.parse(rs[i].criteria)
                    total.push(temp.length * maximum)

                    for (var j = 0; j< temp.length;  j++) {
                        list.push(
                            {
                                criteria_code:temp[j].criteria_code,
                                title:getCriteria(temp[j].criteria_code),
                                score:0,
                                comment:'',
                                indicator_code:'',
                                indicator_point:0

                            }
                        )
                    }

                    itemList.push(
                        {  
                           ...rs[i],
                            criteria:list
                        });
                        }
                        setAssessment(itemList)
                        var score = total.reduce((acc:number, item:any)=>Number(item)+Number(acc), 0)
                        setSearch({...search, total_score:score, maximum:maximum})
                       
                    }catch(e){

                    }
                 }
                })
                .catch((error)=>{
                    Alerts('danger',error.message)
                })
            }
        

         const fetchIndicator =async()=>{
            let url = ServerUrl+'/api/performance/settings/indicator/view/'+COMPANY_CODE
            const {data} = await axios.get(url, config)
            let max = data.data.reduce((prev:any, current:any)=>(Number(prev.point)>Number(current.point))?prev.point:current.point, 1);
            
            setIndicator(data.data.sort((a:any, b:any)=>a.order_no > b.order_no ? 1: -1))
            fetchAssessment(max)
            }

         
           


         const fetchStaff =async()=>{
            let url = ServerUrl+'/api/employees/view/'+COMPANY_CODE
            const {data} = await axios.get(url, config)
            setStaff(data.data)
            }

         const handleSelect = (option:any, action:any)=>{
            setSearch({...search, [action.name]:option});
              setErrors({...errors, [action.name]:''})
    
    
               }

const handleChnageText =(event:ChangeEvent<HTMLTextAreaElement>)=>{
    const {name, value} = event.target;
    setSearch({...search, [name]:value});
    setErrors({...errors, [name]:''})
}


const getScore =(table:any)=>{

let total = []
try{

    
for (var i = 0; i< table.length;  i++) {
    let temp = table[i].criteria
    for (var j = 0; j< temp.length;  j++) {
    total.push(temp[j].score)
    }
        }

        
        var score = total.reduce((acc:number, item:any)=>Number(item)+Number(acc), 0)
        let percentage:any = (score/ search.total_score) * 100
        setSearch({...search, score:score, percentage:percentage.toFixed(2)})
       
    }catch(e){
    
    }

}

const handleChangeRadio =(event:ChangeEvent<HTMLInputElement>, assessment_code:string, criteria_code:string, point:number)=>{

    const {name, value} = event.target;
    const newAssessment = assessment.map((data:any)=>{
        if(data.code === assessment_code){
            return {...data, 
                criteria:data.criteria.map((item:any)=>{
                    if(item.criteria_code=== criteria_code){
                        return {
                            ...item,
                            score:Number(point),
                            indicator_point:Number(point)
                        }
                    }
                    return item
                }),
            }
            };
            return data;
    })
    
    setAssessment(newAssessment)
    getScore(newAssessment)
}

const handleDevelopment =(event:ChangeEvent<HTMLTextAreaElement>, code:string)=>{
    const {name, value} = event.target;

    const newDevelopment = development.map((data:any)=>{
        if(data.code === code){
            return {...data, 
                [name]:value
            }
            };
            return data;
    })
    setDevelopment(newDevelopment)
}

const handleGoals =(event:ChangeEvent<HTMLTextAreaElement>, code:string)=>{
    const {name, value} = event.target;

    const newGoals = goals.map((data:any)=>{
        if(data.code === code){
            return {...data, 
                [name]:value
            }
            };
            return data;
    })
    setGoals(newGoals)
}

const handleGoalsDate =(event:ChangeEvent<HTMLInputElement>, code:string)=>{
    const {name, value} = event.target;

    const newGoals = goals.map((data:any)=>{
        if(data.code === code){
            return {...data, 
                period:value
            }
            };
            return data;
    })
    setGoals(newGoals)
}


const handleComment =(event:ChangeEvent<HTMLTextAreaElement>, code:string)=>{
    const {name, value} = event.target;

    const newAssessment = assessment.map((data:any)=>{
        if(data.code === code){
            return {...data, 
                criteria:data.criteria.map((item:any)=>{
                    if(item.criteria_code=== name){
                        return {
                            ...item,
                            comment:value
                        }
                    }
                    return item
                }),
            }
            };
            return data;
    })
    setAssessment(newAssessment)
}

         const handleChangeDate = (event:ChangeEvent<HTMLInputElement>)=>{
            const {name, value} = event.target;
            let data:any = value
            setSearch({...search, appraisal_month:data});
            setErrors({...errors, appraisal_month:''});

         }


         const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
            event.preventDefault();
        
            let error = {...errors}; 
            let formIsValid = true;
        
        let msg ='This field is required';
        
        if(search.staff.length===0){
            error.staff =msg;
            formIsValid = false;
        } 
        
        
        if(!search.appraisal_month){
            error.appraisal_month =msg;
            formIsValid = false;
        } 

        if(!search.employee_comment){
            error.employee_comment =msg;
            formIsValid = false;
        }


        if(!formIsValid){
        setErrors(error) 

window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
        }
        
        if(formIsValid) {
        
            LoadingModal()
           
            const fd = new FormData();
            let code = Math.random().toString(36).substring(2, 9)
            Object.entries(search).forEach(([key, value]) => {
              fd.append(key, String(value));
          });
            fd.append('company_code', COMPANY_CODE);
            fd.append('code', code); 

            fd.append('added_by', STAFF_CODE);
    fd.append('staff_code', search.staff.value);
    fd.append('assessment', JSON.stringify(assessment, null, 2)); 
    fd.append('development', JSON.stringify(development, null, 2));
    fd.append('goals', JSON.stringify(goals, null, 2));
    fd.append('indicator', JSON.stringify(indicator, null, 2));

        
        let url = ServerUrl+'/api/performance/appraisal/add';
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status === 200){
             SuccessModal(response.data.message)
           
                      } else{
                        Alerts('danger', response.data.message)
                        closeModal()
                      }   
                  })
                  .catch((error)=>{
                    Alerts('danger', error.message)
                    closeModal()
                  }).finally(()=>{
                  
                 handleReset()
        
window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
                  })
            }
        
        } 
       

         useEffect(()=>{
            
            fetchIndicator()
          }, [data])

          useEffect(()=>{

            try{
         
             if (settings.data!==undefined && settings.data.length!==0){
             setProfile(settings.data)
                 
             }
         
            }catch(err) {
         
            }
             
             }, [settings]) 

             

          useEffect(()=>{
            fetchIndicator()
            fetchStaff() 
          }, [])
       
  return ( <>
   <Breadcrumb 
   title="Employee Appraisal"
   summary="Create performance appraisal for employee"
   >
    <li className="breadcrumb-item">
    <Link to="/performance/manage_appraisal">Performance</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Employee Appraisal</a> </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
<div className="main-body">
<div className="page-wrapper">
<div className="page-body">


<div className="row">
<div className=" col-md-12">
<div className="card z-depth-0">
    <div className="card-header">
        <h5>PERFORMANCE APPRAISAL</h5>
        <span  ><a href="#"> Display Employee</a></span>
        <div className="card-header-right">

            <ul className="list-unstyled card-option">
                <li><i className="fa fa fa-wrench open-card-option"></i></li>
                <li><i className="fa fa-window-maximize full-card"></i></li>
                <li><i className="fa fa-minus minimize-card"></i></li>
                <li><i className="fa fa-refresh reload-card"></i></li>
                <li><i className="fa fa-trash close-card"></i></li>
            </ul>
            
        </div>
    </div>
    <div className="card-block ">
       


        <div className="row">
        <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Search by Employee</label>
    
    
    <Select 
                options={
                               staff&&staff.map((list:any, idx:number)=> {
                                return {key:idx, value: list.code, label: list.first_name, last_name:list.last_name, middle_name:list.middle_name, title:list.title }
                                })}
                                className={errors.staff?"form-control form-control-danger":""} 
                     getOptionLabel={option =>` ${option.label} ${option.last_name}`}
    onChange={handleSelect}  name="staff" value={search.staff} 
    styles={styles}
    /> 
                    
                    {errors.staff&&
    <div className="col-form-label has-danger">{errors.staff&&errors.staff} </div>}
    
    </div>
    
    </div>



    <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Appraisal Month </label>
           
            <input type="month"   className={errors.appraisal_month?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} name="appraisal_month"  onChange={handleChangeDate} value={search.appraisal_month} />

        
{errors.appraisal_month&&
    <div className="col-form-label has-danger">{errors.appraisal_month&&errors.appraisal_month} </div>}
             </div>

             </div>

        </div>


      


    </div>
</div>
</div>
</div>






  <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-block">
    

    <div className="row">
        <div className="col-md-12 table-responsive ">
       
        <div className='text-center '>
                       
<img 
src={ImagesUrl+'/about/'+profile.logo}  alt="Click to change" 
className="m-b-10 img-100" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/logo.png'}} />


<h4>{profile.company_name} </h4>
<h5>{profile.company_address}</h5>
<h6 className='m-b-5'>{profile.email}, {profile.phone}</h6>

                        <div className="alert alert-info text-center mb-1 mt-2">

                        <h5> PERFOMANCE APPRAISAL FORM</h5>
                        </div>
            </div>

            <h5 className='m-t-20 mb-20 text-muted' > <i> Using the following rating scale, please give a critical evaluation of the employee's performance throughout the review period. The appropriate instances, give illustrations. If necessary, please use a separate sheet.</i></h5>

            <table className="table table-bordered table-xs w-65">
<thead>
<tr>
    {indicator&&indicator.map((item:any, index:number)=><th key={index}>{item.indicator}</th>)}

</tr>
</thead>
<tbody>
<tr>
{indicator&&indicator.map((item:any, index:number)=><td key={index}>{item.title}</td>)}

</tr>
</tbody>
</table>

{assessment&&assessment.map((item:any, id:number)=><div key={id}>
<h5 className="mb-20 text-primary">{id+1}. {item.title.toUpperCase()}</h5>

<table className="table table-bordered table-sm table-css  ">
<thead>
<tr>
<th className='w-30'>Criteria</th>
{indicator&&indicator.map((item:any, index:number)=><th className='w-8 text-center' key={index}>{item.indicator} <br/> ({item.point})</th>)}
<th >Score</th>
<th className='w-20'>Comments</th>
</tr>
</thead>
<tbody>

     {item.criteria.map((data:any, key:number)=>
<tr key={key}>

<td  ><p >{data.title}</p>
</td>


{indicator&&indicator.map((itx:any, index:number)=>
<td className="text-center" key={index}> <input type="radio" checked={data.indicator_point ===Number(itx.point)?true:false} name={data.criteria_code}  value={data.indicator_point} onChange={(e)=>handleChangeRadio(e,  item.code, data.criteria_code, itx.point)} /></td>
)}



<td><b>{data.score}</b></td>

<td>
<textarea name={data.criteria_code} className="form-control mb-20" value={data.comment} onChange={(e)=>handleComment(e, item.code)} rows={2}>
{data.comment}
</textarea>
    
    
    </td>
</tr>)} 


<tr>
<td></td>
<td colSpan={5}>Total Score ( Maximum = {Number(search.maximum)* item.criteria.length} )</td>

<td colSpan={2}><b>{FormatNumber(item.criteria.reduce((acc:number, item:any)=>Number(item.score)+Number(acc), 0))}</b></td>
</tr>
</tbody>
</table>

</div>)}

<h5 className="mb-20 text-primary">OVERALL PERCENTAGE SCORE  : {search.percentage}%</h5>

<hr/>

<h5 className="mb-20 ">A. COMMENTS BY EMPLOYEE</h5>
<textarea name="employee_comment" value={search.employee_comment} onChange={handleChnageText}  className={errors.employee_comment?"form-control form-control-danger mb-20":"form-control mb-20"}  placeholder="Maximum 500 words" rows={4}>{search.employee_comment}

</textarea>


{errors.employee_comment&&
    <div className="col-form-label has-danger">{errors.employee_comment&&errors.employee_comment} </div>}


<h5 className="mb-20 ">B. DEVELOPMENT PLAN</h5>

<table className="table table-bordered table-css table-sm" id="request_table_dev_plan">
<thead>
<tr>
<th  className='w-30'> <p> Recommended Areas for Improvement / Development</p></th>
 <th  className='w-30'>Expected Outcome(s)</th>
 <th  className='w-20'> <p>Responsible Person(s) to assist in the achievement of the Plan</p></th>

<th  className="text-center w-13">Action</th>
</tr>
</thead>
<tbody>
{development.map((item:any, idx:number)=>
<tr key={idx}>
<td>
<textarea name="improvement" value={item.improvement} className="form-control mb-20"  onChange={(e)=>handleDevelopment(e, item.code)}   >{item.improvement}</textarea>
</td>
<td>
<textarea name="outcome" className="form-control" onChange={(e)=>handleDevelopment(e, item.code)} value={item.outcome} >{item.outcome} </textarea>
</td>
<td>
    
<textarea name="person" className="form-control" onChange={(e)=>handleDevelopment(e, item.code)} value={item.person} >{item.person} </textarea>
</td>
<td >
<button type='button' onClick={addDevelopment} className="btn btn-info btn-sm"  ><i className="fa fa-plus-square" ></i></button>&nbsp;
<button type='button' onClick={()=>removeDevelopment(idx)} className="btn btn-danger btn-sm" ><i className="fa fa-trash" ></i></button>
</td>
</tr>)}
</tbody>
</table>

<h5 className="mb-20 ">C. KEY GOALS FOR NEXT REVIEW PERIOD</h5>

<table className="table table-bordered table-sm" id="request_table">
<thead>
<tr>
<th>Goal (s) Set and Agreed on with Employee</th>
<th className='w-20'>Proposed Completion Period</th>
<th className="text-center w-10">Action <i className="text-danger"></i></th>
</tr>
</thead>
<tbody >

{goals.map((item:any, idx:number)=>
<tr key={idx}>

<td>
    
<textarea name="goal" value={item.goal} className="form-control" onChange={(e)=>handleGoals(e, item.code)}  >{item.goal}</textarea>
    </td>
<td>
    <input type="date" value={item.period}  name="period" className="form-control" onChange={(e)=>handleGoalsDate(e, item.code)}  />

    </td>
<td >

<button type='button' onClick={addGoal} className="btn btn-info btn-sm"  ><i className="fa fa-plus-square" ></i></button>&nbsp;
<button type='button' onClick={()=>removeGoal(idx)} className="btn btn-danger btn-sm" ><i className="fa fa-trash" ></i></button>
</td>
</tr>)}
</tbody>
</table>

        </div>

      


        </div>
        <hr/>
        <div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={handleSubmit} >Submit</button>
</div>
</div>
            </div>
        </div>
    </div>
    </div>

</div>
</div>


</div>
</div>  
      </>               
 
  )
}

export default Appraisal 