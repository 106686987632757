import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, CURRENCY, ImagesUrl, STAFF_CODE, ServerUrl, config, styles } from '../../components/Includes';
import axios from 'axios';
import { Alerts, CustomLoader, LoadingModal, SuccessModal, closeModal } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'
import { useQuery } from 'react-query'
import { usePaginated, DOTS } from '../../components/usePagination';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { FormatNumber, getBritishDate, getRank, shortText } from '../../components/GlobalFunction';
import { fetchDepartment, fetchJobs } from '../../api/ReactQuery';
import { Link } from 'react-router-dom';

const  ProjectReport =()=> {
   


  const [staff, setStaff] = useState([] as any);
   
    const [search, setSearch] = useState({
        
        rowperpage:{value:'10', label:'10'},
        filterText:'',
        selectAll:false,
        totalSelected:0,
     })


    const [filterContent, setFilterContent] = useState([] as any);
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
         
         client_name:'',
         email_address:''
       
     })

  const fetchStaff =async()=>{
    let url = ServerUrl+'/api/employees/view/'+COMPANY_CODE
    const {data} = await axios.get(url, config)
    setStaff(data.data)
    }

     



  const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	
    setSearch({...search, [name]:value});

    if(value!==''){
        const filteredItems = content.filter(
            (item:any) => item.client_name.toLowerCase().includes(value.toLowerCase()) || item.title.toLowerCase().includes(value.toLowerCase()) ||
            item.work_station.toLowerCase().includes(value.toLowerCase()),
        );

        setFilterContent(filteredItems)
    }else{
        setFilterContent(content)
    } 

 }


const handleClear =()=>{
setSearch({...search, filterText:''});
setFilterContent(content)
}


const getTitleName = (code:string, field:string) =>{ 
  const result = staff&&staff.filter((list:any)=>list.code===code);
 const answer = result.length !==0?result[0][field]:''
   return  answer;
 }


  

 const getTeam=(temp:string)=>{

  try{
  if(temp!=='[]'){
 let value:any = [];
JSON.parse(temp).map((itm:any, idx:number)=>{
      value.push({
        code:itm.staff_code, 
         passport:getTitleName(itm.staff_code, 'passport'), 
      gender:getTitleName(itm.staff_code, 'gender'),
      middle_name:getTitleName(itm.staff_code, 'middle_name'), title:getTitleName(itm.staff_code, 'title'), key:idx});
  } );
return value;
}
else{
return []
}
}catch(err){
      
}
}



         const fetchContent = async()=>{
            setLoading(true)
     

                 let sql = "SELECT p.code, c.client_name, p.added_by, p.title, p.team_lead, p.team_member, p.project_summary, p.start_date, p.complete_date, p.deadline, p.status, p.work_station, p.department, p.duration, p.banner_url, p.budget, p.rank, p.description, p.completion_rate	 FROM tbl_clients c left join tbl_projects p on p.client = c.code where p.company_code = '"+COMPANY_CODE+"'"
                 
                 
                 const fd = new FormData();
                 fd.append('sql', sql); 

                 let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE

               await  axios.post(url, fd, config).then((result:any)=>{

                 if(Array.isArray(result.data.data) && result.data.data.length!==0){
                    setFilterContent(result.data.data)
                   setContent(result.data.data)
                   }else{

                    setContent([])
                    setFilterContent([])
                   }
                 })
                 .catch((error)=>{
                     Alerts('danger',error.message)
                 }).finally(()=>{
                     setLoading(false)
                 })
                 } 





    useEffect(()=>{
       fetchContent()
       fetchStaff()
      }, [])


    

  return ( <>
    <Breadcrumb 
   title="Project List"
   summary="Create new project"
   >
    <li className="breadcrumb-item">
    <Link to="/project/reports">Project</Link>
                    </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">




                        <div className="row"> 
                        <div className=" col-md-12"> 
                        <div className="card"> <div className="card-body"> <div className="row"> <div className="col-md-10">
                          
                           <div className="input-group"> 
                        <input type="text" className="form-control br-tl-3  br-bl-3" name="filterText" id="filterText" key="filterText"  onChange={handleSearch} value={search.filterText} placeholder="Search" /> 
                        <div className="input-group-append " >
                           <button type="button" onClick={handleClear} className="btn btn-primary br-tr-3  br-br-3"> Clear </button> </div> 
                        </div> 


                        </div> 
                        <div className="col-xl-2"> <div className="mt-4 mt-xl-0"> <a href='/project/new_project' className="btn btn-success btn-block pt-2 pb-2" >ADD<i className="fa fa-plus ml-2"></i></a> </div> </div> </div> </div> </div> </div> </div>




                        <div className="row"> 
                      
{filterContent.length!==0?filterContent.map((data:any, id:number)=>
<div className=" col-md-6 " key={id}> 


<div className="card z-depth-0">
   <div className="card-header"> 
                <h5>{data.client_name}</h5>
                <span>{data.title}</span>
              
   </div>
   
    <div className="card-body"> 
    
    <div className="ibox teams mb-30 bg-boxshadow">

   <div className="ibox-content teams"> 
    <h5 className="font-weight-semibold">Team</h5> 
    
    <div className="mb-3 avatar-list avatar-list-stacked"> 
  
     {getTeam(data.team_member).slice(0, 5).map((list:any, index:number)=><span><img key={index} className="avatar brround" src={ImagesUrl+'/employees/passport/'+list.passport}   alt="image" /></span>)}

     <span className="avatar brround">+{getTeam(data.team_member).slice(5, getTeam(data.team_member).length)}</span> 
     
     </div> 

    <div className="teams-board-details mt-2"> 
    <h5>Description : </h5> 
    <p className="text-muted" dangerouslySetInnerHTML={{__html:data.description.length!==0? shortText(data.description, 200):''}} ></p></div> <div className="row mb-3 mt-0"> <div className="col"> 
    
    <span className="mb-0 mt-1 font-weight-semibold">Deadline:</span> </div> 
    <div className="col col-auto text-muted fs-15"> {data.deadline} </div> </div>  

    <p className="mb-0 h5">{data.work_station}</p>


    <div className="d-flex justify-content-between align-items-center"> 

    <div className="progress h-1 w-100 mt-2 flex-grow-1 mr-4"> 
    <div  className= {`progress-bar progress-bar-success `} style={{width: `${data.completion_rate}%`}}></div>

   
     </div> <p className="text-muted mt-1 mb-0">{data.completion_rate}%</p>

     </div> 
     
     <div className="row mt-2">
       <div className="col-xl-4 col-sm-12 col-md-4"> <div className="teams-rank">Status</div> <span className="text-muted">{data.status}</span> </div> <div className="col-xl-4 col-sm-12 col-md-4"> <div className="teams-rank">Ranking</div> <span className="text-muted"> {getRank(data.rank)}</span> </div> <div className="col-xl-4 col-sm-12 col-md-4"> <div className="teams-rank">Budget</div> <span className="text-muted">{CURRENCY+FormatNumber(data.budget)}</span> </div> 
       </div> 
    <a href={"/project/details/"+data.code} className="btn btn-block mt-4 btn-primary">Read More</a> 
    </div> </div> 
    </div> 
    </div>
     </div>):[]}
</div>


                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default ProjectReport