import React from 'react'
import { ServerUrl,  config, COMPANY_CODE } from '../components/Includes'
import axios from 'axios'
import { LoadingModal, closeModal } from '../components/notify'
import html2canvas from 'html2canvas'

export const fetchQrcode = async({queryKey}:any)=>{
    //ensure the url or content is not more than 80 characters
    const [_, qrcode] = queryKey
    let url = ServerUrl+'/api/generate_qrcode'
    const fd = new FormData();
    fd.append("qrcode", qrcode)
    const {data} = await axios.post(url, fd, config)
    return data
  }


export  const fetchDepartment = async()=>{
    let url = ServerUrl+'/api/employees/settings/department/view/'+COMPANY_CODE
    const {data} = await axios.get(url, config)
    return data.data
  }

  export  const fetchJobs = async()=>{

    let url = ServerUrl+'/api/employees/settings/jobs/view/'+COMPANY_CODE
    const {data} = await axios.get(url, config)
    return data.data
  }

  export const fetchEmployee =async({queryKey}:any)=>{
    const [_, code] = queryKey

   // LoadingModal()
    
    let url = ServerUrl+'/api/employee/view/'+COMPANY_CODE+'/'+code
    const {data} = await axios.get(url, config)
   // closeModal()
    return data.data
    }


    export  const handleFetchLga =async({queryKey}:any)=>{

      const [_, alias] = queryKey
      let url = 'https://locus.fkkas.com/api/regions/'+alias
     await  axios.get(url, config).then(result =>{
        if(result.data.data.length!==0){
            let response =result.data.data.sort((a:any, b:any) =>(a.name > b.name) ? 1 : -1)
            return response
          }
    
      })

   }



   export const fetchCountry = async ()=>{

    let url = 'https://restcountries.com/v3.1/all'
    const {data} = await axios.get(url, config)

    let response =data.sort((a:any, b:any) =>(a.name.common > b.name.common) ? 1 : -1)
            return response

    }
    
   
   export const handleFetchState = async()=>{
        let url = 'https://locus.fkkas.com/api/states'
        const {data} = await axios.get(url, config)
        let response =data.data.sort((a:any, b:any) =>(a.alias > b.alias) ? 1 : -1)
            return response

     }




     export const fetchTermination = async()=>{
      
      let url = ServerUrl+'/api/employees/settings/termination/view/'+COMPANY_CODE

      const {data} = await axios.get(url, config)
      let response =data.data
          return response

   }

   export const fetchCriteria =async()=>{
                
    let url = ServerUrl+'/api/performance/settings/criteria/view/'+COMPANY_CODE
    const {data} = await axios.get(url, config)
    let response =data.data
        return response
       
       }

       export  const fetchSettings = async()=>{
          
           let url = ServerUrl+'/api/settings/view/'+COMPANY_CODE
           const {data} = await axios.get(url, config)
        let response =data.data
        return response
           } 

export  const FetchProfile = async()=>{
    let url = ServerUrl+'/api/settings/view/'+COMPANY_CODE
    const {data} = await axios.get(url, config)
        let response =data.data[0]
        return response
    } 




export  const takeScreenshot =(fileName:string)=>{
  const element: any =  document.getElementById(fileName);
    html2canvas(element, {allowTaint: true, useCORS: true, logging: true}).then((canvas) => {
      const base64image = canvas.toDataURL("image/png", 1.0);
    let a = document.createElement('a');
      a.href = base64image;
      a.download = '...';
      a.click(); 

     /*  
     This work well in firefox but dont like the output in chrome
     var string = base64image;
      var iframe = "<iframe width='100%'   height='100%' src='" + string + "'></iframe>"
      var x:any = window.open();
      x.document.open();
      x.document.write(iframe);
      x.document.close(); */




 /*      for multiple

      var data = document.getElementsByClassName('htmlToCanvasVis')

    for (var i = 0; i < data.length; ++i){
        html2canvas(data[i]).then((canvas)=>{
            var image = canvas.toDataURL('image/png', 1.0);
            saveAs(image, 'exported-vis.png') 
        })
    } */


  });
  } 