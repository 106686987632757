import { useState, ChangeEvent, FormEvent, useEffect} from 'react'
import { COMPANY_CODE, ImagesUrl, STAFF_CODE, ServerUrl, config, group_styles, styles } from '../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal, Alerts, closeModal } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'

import { useParams} from 'react-router'
import { useQuery } from 'react-query'
import Select from 'react-select'
import { fetchDepartment, fetchEmployee, fetchJobs, fetchSettings } from '../../api/ReactQuery';
import { Link } from 'react-router-dom';
import { WORK_STATUS } from '../../components/Data';

const MyProfile =()=> {

    let params = useParams()
    const {data} = useQuery(["employee", STAFF_CODE], fetchEmployee)
   
    const Jobs = useQuery(["jobs"], fetchJobs)
    const department = useQuery(["department"], fetchDepartment)
    const [staff, setStaff] = useState([] as any);
    const settings = useQuery(["settings"], fetchSettings, {
        staleTime: 10 * (60 * 1000), // 10 mins
      })

    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        
        password:'',
        confirm_password:'',
     })
const Initial = {
    password:'',
    confirm_password:'',

 }

 

     const [profile, setProfile] = useState(Initial);
     const [usergroup, setUsergroup]=useState([])


           const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
            setProfile({...profile, [name]:value});
           setErrors({...errors, [name]:''});
         }



const handleReset =()=>{
    setProfile(Initial)
}

const fetchGroup = async()=>{
    LoadingModal()
    let url = ServerUrl+'/api/setup/group/'+COMPANY_CODE
  await  axios.get(url, config).then((result:any)=>{
    if(Array.isArray(result.data.data) && result.data.data.length!==0){
      setUsergroup(result.data.data)
      }
    })
    .catch((error)=>{
        Alerts('danger', error.message)
    }).finally(()=>{
        closeModal()
    })
    } 

const fetchStaff =async()=>{
    let url = ServerUrl+'/api/employees/view/'+COMPANY_CODE
    const {data} = await axios.get(url, config)
   
    setStaff(data.data)
    }

const getTitleName = (code:string, field:string) =>{ 
    const result = staff&&staff.filter((list:any)=>list.code===code);
   const answer = result.length !==0?result[0][field]:''
     return  answer;
   }

const getSupervisor=(temp:string)=>{

    try{
      
   let value:any = [];
 JSON.parse(temp).map((itm:any, idx:number)=>{
        value.push(
            {first_name:getTitleName(itm, 'first_name'), 
            last_name:getTitleName(itm, 'first_name'), 
            middle_name:getTitleName(itm, 'middle_name'), 
            title:getTitleName(itm, 'title')
        });
    } );
    
return value;

}catch(err){
        
}
}



const getFieldName =(table:any, field:string, code:string)=>{

    if(Array.isArray(table)){
    const rs = table&&table.filter((item:any)=>item.code===code)
    return rs.length!==0?rs[0][field]:''
    }else{
        return ''
    }
   
 }


const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';


if(profile.password.length < 6){
    error.password ='Password not strong enough, minimum of 6 letters required';
    formIsValid = false;
} 


if(profile.confirm_password !== profile.password){
    error.confirm_password ='Password do not match';
    formIsValid = false;
} 


if(!profile.password){
    error.password =msg;
    formIsValid = false;
} 


if(!profile.confirm_password){
    error.confirm_password =msg;
    formIsValid = false;
} 


if(!formIsValid){
setErrors(error) 
}


if(formIsValid) {

    LoadingModal()
 const fd = new FormData();
   
        fd.append('code', STAFF_CODE);
        fd.append('password', profile.password);
        fd.append('logs', content.logs);
        fd.append('id', content.id);
        


let url = ServerUrl+'/api/employee/change_password';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                Alerts('danger',response.data.message)
              }   
          })
          .catch((error)=>{
            Alerts('danger',error.message)
          }).finally(()=>{
        handleReset()

          }) 
    }

}




 useEffect(()=>{

    try{
     if (data!==undefined && data.length!==0){
     setContent(data[0])
     }
 
    }catch(err) {
 
    }
     
     }, [data]) 


     useEffect(()=>{
        fetchStaff()
        fetchGroup()
     }, [])
  return ( <>
<Breadcrumb 
   title="Profile"
   summary="You can update your profile details and view other information about you when you click on edit profile."
   >
         <li className="breadcrumb-item"><a href="#!">My Profile</a> </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">


                        {content.length!==0?<div>


                        <div className="row"> 
                        <div className="col-md-12"> 
                        <div className="card"> 
                        <div className="card-body"> 
                        <div className="d-md-flex m-0"> 

                        

                        <div className="wideget-user-img">

                    <img className="img-80 img-radius align-top m-r-4 fit-content" 

                    src={ImagesUrl+'/employees/passport/'+content.passport} onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+content.gender+'.png'}}

                    alt="img" />

                    </div> 

 
                        <div className="my-md-auto ml-4"> 
                        <div> 
                            <h4 className="media-heading fw-600">Hi, {content.title+' '+content.first_name+' '+content.last_name} 

                            <span className="text-muted "> ({getFieldName(Jobs.data, 'title', content.job_title)})</span> </h4> 


                            <p className="text-muted">When you are asked if you can do a job, tell ’em, ‘Certainly I can! ‘ Then get busy and find out how to do it.</p>
                            </div> 


                            <div className="progress h-1 w-100 mt-2 flex-grow-1 mr-4"> 
    <div  className= {`progress-bar progress-bar-emrald progress-bar-striped `} style={{width: `25%`}}></div>

   
     </div>


                            </div> 

                            <div className="ms-auto my-auto ml-4"> 
                            <Link to='/employee/update_profile' className="btn btn-pill btn-primary"><i className="ti-pencil"></i>Edit Profile</Link> 
                            </div> 
                            
                            </div> </div> 
                            
                            </div> </div> 
                            </div>






<div className="card"> 
<div className="card-block p-0 border-0"> 


 <div className="p-4"> 
 <h5 className=" text-uppercase text-bold mb-3">Telephone</h5> 
 <hr/>

 <div className="d-md-flex"> 



<div className="d-flex align-items-center "> 
  <span className="avatar rounded-circle bg-success">
    <i className="fa fa-phone"></i></span> 

<div className="ml-3"> <p className="mb-1 text-bold">Mobile Phone</p>
<a href="#!" className="text-muted">{content.mobile_phone}</a> 
</div> 
</div> 



  <div className="d-flex align-items-center ml-5"> 
  <span className="avatar rounded-circle bg-secondary-transparent">
    <i className="fa fa-phone"></i></span> 

<div className="ml-3"> <p className="mb-1 text-bold">Work Phone</p>
<a href="#!" className="text-muted">{content.work_phone}</a> 
</div> 
</div> 


<div className="d-flex align-items-center ml-5"> 
  <span className="avatar rounded-circle bg-primary">
    <i className="fa fa-phone"></i></span> 

<div className="ml-3"> <p className="mb-1 text-bold">Home Phone</p>
<a href="#!" className="text-muted">{content.home_phone}</a> 
</div> 
</div> 


<div className="d-flex align-items-center ml-5"> 
  <span className="avatar rounded-circle bg-info">
    <i className="fa fa-phone"></i></span> 

<div className="ml-3"> <p className="mb-1 text-bold">Other Phone</p>
<a href="#!" className="text-muted">{content.other_phone}</a> 
</div> 
</div> 

 </div> 

</div> 


  
 <div className="p-4"> 
 <h5 className=" text-uppercase text-bold mb-3">Contact Address</h5> 
 <hr/>
 
 <div className="d-md-flex"> 



<div className="d-flex align-items-center "> 
  <span className="avatar rounded-circle bg-primary">
    <i className="ti-email"></i></span> 

<div className="ml-3"> <p className="mb-1 text-bold">Personal Email</p>
<a href="#!" className="text-muted">{content.personal_email}</a> 
</div> 
</div> 



  <div className="d-flex align-items-center ml-5"> 
  <span className="avatar rounded-circle bg-secondary-transparent">
    <i className="ti-email"></i></span> 

<div className="ml-3"> <p className="mb-1 text-bold">Work Email</p>
<a href="#!" className="text-muted">{content.work_email}</a> 
</div> 
</div> 


<div className="d-flex align-items-center ml-5"> 
  <span className="avatar rounded-circle bg-primary">
    <i className="ti-home"></i></span> 

<div className="ml-3"> <p className="mb-1 text-bold">State</p>
<a href="#!" className="text-muted">{content.state}</a> 
</div> 
</div> 

<div className="d-flex align-items-center ml-5"> 
  <span className="avatar rounded-circle bg-info">
    <i className="ti-home"></i></span> 

<div className="ml-3"> <p className="mb-1 text-bold">Local Area</p>
<a href="#!" className="text-muted">{content.lga}</a> 
</div> 
</div> 

 </div> 

</div> 


</div> </div>








<div className="row"> 
                        <div className="col-md-12"> 
                        <div className="card"> 
                        <div className="card-body"> 




                        <div className="row">

        <div className="col-md-3">
            <div className="badge-box">
                <div className="sub-title text-bold">Department

                </div>

                <p>{getFieldName(department.data, 'department_name', content.department)} </p>
            </div>
        </div>
        <div className="col-md-3">
            <div className="badge-box">
                <div className="sub-title text-bold">Employment Status
                </div>
                <p>{content.employment_status} </p>
            </div>
        </div>
        <div className="col-md-3">
            <div className="badge-box">
                <div className="sub-title text-bold">Work Station

                </div>
                <p>{content.work_station} </p>
            </div>
        </div>
        <div className="col-md-3">
            <div className="badge-box">
                <div className="sub-title text-bold">Access Group

                </div>

                <p> {getFieldName(usergroup, 'group_name', content.group_code)} </p>
            </div>
        </div>
    </div>




    <hr/>
<h4 className="fs-15 text-uppercase fw-semibold mb-3">Suboardinates</h4> 


{getSupervisor(content.subordinate).map((data:any, id:number)=>
<span className="badge badge-lg badge-light  mb-3 mr-2" key={id}>{data.title+' '+data.first_name+' '+data.last_name}</span> )}

<hr/>
<h4 className="fs-15 text-uppercase fw-semibold mb-3">Supervisor</h4> 
{getSupervisor(content.supervisor).map((data:any, id:number)=>
<span className="badge badge-lg badge-light  mb-3 mr-2" key={id}>{data.title+' '+data.first_name+' '+data.last_name}</span> )}

                        </div>
                        </div> 
                        </div> </div>



                        <div className="row">
<div className=" col-md-12">
<div className="card z-depth-0">
    <div className="card-header">
        <h5>Update Password</h5>
        <div className="card-header-right">

<ul className="list-unstyled card-option">
    <li><i className="fa fa fa-wrench open-card-option"></i></li>
    <li><i className="fa fa-window-maximize full-card"></i></li>
    <li><i className="fa fa-minus minimize-card"></i></li>
    <li><i className="fa fa-refresh reload-card"></i></li>
    <li><i className="fa fa-trash close-card"></i></li>
</ul>

</div>
    </div>
    <div className="card-block ">
       


        <div className="row " style={{backgroundColor: 'rgba(68, 138, 255, 0.1)'}} >
           
            <div className="col-md-12">



<div className="row " >


        <div className="col-md-6">
         <div className="form-group">
        <label className="col-form-label">New Password <span className='text-danger'>*</span></label>
       
          

    <input type="password" className={errors.password?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='password' value={profile.password} onChange={handleChange} />

    {errors.password&&
    <div className="col-form-label has-danger">{errors.password&&errors.password} </div>}
      
         </div>


         </div>
     


         <div className="col-md-6">
         <div className="form-group">
        <label className="col-form-label">Confirm New Password <span className='text-danger'>*</span></label>
       
           
    <input type="password" className={errors.confirm_password?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='confirm_password' value={profile.confirm_password} onChange={handleChange} />

    {errors.confirm_password&&
    <div className="col-form-label has-danger">{errors.confirm_password&&errors.confirm_password} </div>}
      
         </div>

         </div>

         <div className="col-md-12">
            <span > Please choose a difficult-to-guess combination of text containing upper- and lowercase letters, symbols, and digits for the password.</span>
         </div>
         

        </div>


    </div>
        </div>




<hr/>
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-outline-danger btn-round pr-5 pl-5 mr-3" type='button' onClick={handleReset}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={handleSubmit}>Save</button>
</div>
</div>





    </div>
</div>
</div>
</div>





                                        </div>:[]}
                                    </div></div>

                         
                                </div>
                            </div>  
                            </>               
 
  )
}

export default MyProfile