import { useEffect, useState} from 'react'
import { COMPANY_CODE, CURRENCY, ImagesUrl, ServerUrl, config, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, closeModal, Alerts } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'
import { Link } from 'react-router-dom';


import { PDFDownloadLink} from '@react-pdf/renderer';
import { useQuery } from 'react-query'
import { FetchProfile } from '../../api/ReactQuery';
import { DateList } from '../../components/Data';
import { DownloadDocument } from './StaffLeavePDF';
const  StaffLeave =()=> {
  
    const profile = useQuery(["profile"], FetchProfile)
    const [staff, setStaff] = useState([] as any);
    const [template, setTemplate] = useState([] as any);
    const [search, setSearch] = useState({
    
        employee:[] as any,
        date_range:  {label:'2023-01-01 - 2023-12-31', value:'2023-01-01 - 2023-12-31', start_date:'2023-01-01', end_date:'2023-12-31'},
     })


    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        
        employee:'',
         date_range:''
       
     })
     const fetchStaff =async()=>{
        let url = ServerUrl+'/api/employees/view/'+COMPANY_CODE
        const {data} = await axios.get(url, config)
        setStaff(data.data)
        }
   
      
        const PrintMe = () =>{     
            let printContents:any = document.getElementById('printablediv')?.innerHTML;
              let originalContents = document.body.innerHTML;
              document.body.innerHTML = printContents;
              window.print();
             document.body.innerHTML = originalContents; 
            }

     const handleSelect = (option:any, action:any)=>{
        setSearch({...search, [action.name]:option});
          setErrors({...errors, [action.name]:''})
setContent([])
          if(action.name==='employee'){
          fetchTemplate(option.value)
          }

           }


       

        
const handleReset =()=>{
    setSearch({
        employee:[] as any,
        date_range:  {label:'2023-01-01 - 2023-12-31', value:'2023-01-01 - 2023-12-31', start_date:'2023-01-01', end_date:'2023-12-31'},
    })
    setContent([])
}

            

                       const fetchTemplate =async(staff_code:string)=>{
                        LoadingModal()
                        let url = ServerUrl+'/api/leave/show_assign_leave/'+staff_code+'/'+COMPANY_CODE
                      await  axios.get(url, config).then((result:any)=>{
                        if(Array.isArray(result.data.data) && result.data.data.length!==0){
                            setTemplate(result.data.data)
                          }else{
                            setTemplate([])
                            setContent([])
                            setErrors({...errors, employee:'There are no leave entitlements assigned to this employee yet'})
                          }
                        })
                        .catch((error)=>{
                            Alerts('danger', error.message)
                        }).finally(()=>{
                            closeModal()
                        })
                        } 




                    const fetchContent= async()=>{
                        
                   
                            
                let error = {...errors}; 
                let formIsValid = true;

            let msg ='This field is required';
                          
            if(template.length==0){
                error.employee ='There are no leave assigned to the selected employee';
                formIsValid = false;
            } 

                    if(search.employee.length===0){
                        error.employee=msg;
                        formIsValid = false;
                    } 


                    if(search.date_range.value===''){
                        error.date_range =msg;
                        formIsValid = false;
                    } 


                  

                    if(!formIsValid){
                    setErrors(error) 
                    }else{

                    
                         LoadingModal()                           

               let sqlString = template.map((item:any)=>"  IFNULL((SELECT sum(r.no_days) from tbl_leave_application r where  r.staff_code = '"+search.employee.value+"' and  r.company_code = '"+COMPANY_CODE+"' and r.leave_code = '"+item.leave_type_code+"' and r.start_date >= '"+search.date_range.start_date+"' and r.end_date <= '"+search.date_range.end_date+"' and r.is_approved = 'Pending' ),0) as  '"+item.leave_name+"Pending' ")


               let sqlStringb = template.map((item:any)=>"  IFNULL((SELECT sum(r.no_days) from tbl_leave_application r where  r.staff_code = '"+search.employee.value+"' and  r.company_code = '"+COMPANY_CODE+"' and r.leave_code = '"+item.leave_type_code+"' and r.start_date >= '"+search.date_range.start_date+"' and r.end_date <= '"+search.date_range.end_date+"' and r.is_approved = 'Approved' ),0) as  '"+item.leave_name+"Approved' ")

                let  final =   "SELECT   l.code, l.leave_name, "+String(sqlString)+ ", "+String(sqlStringb)+ ", t.no_days AS assigned_days FROM tbl_leave_application a, tbl_assign_leave t, tbl_leave_type l WHERE l.code = t.leave_type_code and a.staff_code = t.staff_code and a.staff_code = '"+search.employee.value+"' and  a.company_code = '"+COMPANY_CODE+"' group by l.code, t.no_days, l.leave_name "

                const fd = new FormData();
                 fd.append('sql', final); 
                 let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
               await  axios.post(url, fd, config).then((result:any)=>{

                 if(Array.isArray(result.data.data) && result.data.data.length!==0){

                    let answer = result.data.data
                   
                    let list = []

                    for (var i = 0; i< answer.length;  i++) {

                        list.push({
                            code:answer[i].code,
                            leave_name:answer[i].leave_name,
                            assigned_days: answer[i].assigned_days,
                            pending:answer[i][answer[i].leave_name+'Pending'],
                            approved:answer[i][answer[i].leave_name+'Approved'],
                            balance:Number(answer[i].assigned_days)- Number(answer[i][answer[i].leave_name+'Approved']),

                        })
                    }

                    setContent(list)

window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
                   }else{

                    setContent([])
                   }
                 })
                 .catch((error)=>{
                     Alerts('danger',error.message)
                 }).finally(()=>{
                     closeModal()
                 })

                    }
                             }
                             
                      

    useEffect(()=>{
        fetchStaff()
       
      }, [])



  return ( <>
  <Breadcrumb 
   title="Employee Leave"
   summary=" Search Staff leave usage here"
   >
     <li className="breadcrumb-item">
    <Link to="/leave/leave_list">Leave</Link>
                    </li>

   <li className="breadcrumb-item">
    <Link to="#!">Employee Leave</Link>
                    </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Leave Usage Report</h5>
                    <span>Select an employee to see his/her leave allocation and usage history </span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                              
                                    
                                    
         <div className="content-wrappers ">
                            
         <div className=''>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Search Report</h5>
                <span>Add all offcie department</span>
              
            </div>
            <div className="card-block">
    
        
                        <div className="row">
                  

                        <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Select Employee <span className='text-danger'>*</span></label>

                    <Select 
                options={
                               staff&&staff.map((list:any, idx:number)=> {
                                return {key:idx, value: list.code, label: list.first_name, last_name:list.last_name, middle_name:list.middle_name, title:list.title }
                                })}
                                className={errors.employee?"form-control form-control-danger":""} 
                     getOptionLabel={option =>` ${option.label} ${option.last_name}`}
    onChange={handleSelect}  name="employee" value={search.employee} 
    styles={styles} 
    />       
                

                {errors.employee&&
    <div className="col-form-label has-danger">{errors.employee&&errors.employee} </div>}
                    </div>
    
                     </div>


                    
    <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Date Range </label>
           
            <Select  options={
                               DateList&&DateList.map((list:any, idx:number)=> {
                                return {key:idx, value: list.value, label: list.label, start_date:list.start_date, end_date:list.end_date}
                                })} 
                        className={errors.date_range?"form-control form-control-danger":""} 
                        onChange={handleSelect}  name="date_range"
                value={search.date_range} 
                styles={styles} 
            />
          
          {errors.date_range&&
    <div className="col-form-label has-danger">{errors.date_range&&errors.date_range} </div>}

             </div>

             </div>

                    




           

   </div>

          
<hr />
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-outline-danger btn-round pr-5 pl-5 mr-3" type='button' onClick={handleReset}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={fetchContent}>Search</button>
</div>
</div>
    
            </div>
        </div>
    </div>
    </div>
    
    
    
                </div>

        </div>
                       
                    </section>
                </div>
            </div>
        </div>
    </div>
                                    </div>
                                </div>
                            </div>




{content.length!==0?
  <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-block" id='printablediv'>
    

    <div className="row">
        <div className="table-responsive ">
       
        <table className="table table-sm table-bordered table-hover">
                <thead>
                    <tr>
                        <th colSpan={6} className='text-center '>
                        <img 
           src={ImagesUrl+'/about/'+profile.data.logo}  alt="Click to change" 
           className="m-b-10 img-100" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/logo.png'}} />


                        <h4>{profile.data.company_name} </h4>
                        <h5>{profile.data.company_address}</h5>
                        <h6 className='m-b-5'>{profile.data.email}, {profile.data.phone}</h6>
                        <div className="alert alert-info text-center mb-1 mt-2">

                        <h5> LEAVE USAGE REPORT</h5>
                        </div>
                        </th>
                    </tr>
                    <tr>
                        <td colSpan={4}>  <h6>Staff Name: {search.employee.label +' ' + search.employee.last_name}</h6></td>
                        <td colSpan={2}>  <h6>Date Range: {search.date_range.label}</h6></td>
                    </tr>
                    <tr style={{backgroundColor:'#E7E0EE'}}>
                    <th >SN</th>
                        <th >Leave Type</th>
                        <th >Assigned Days</th>
                        <th >Pending Approval</th>
                        <th >Approved Leave</th>
                        <th >Balance</th>

                    </tr>
                </thead>
                <tbody>
                    {content&&content.map((list:any, index:number)=>
                    <tr key={index}>
                        <td >{index+1}</td>
                        <td >{list.leave_name}</td>
                        <td >{list.assigned_days}</td>
                        <td >{list.pending}</td>
                        <td >{list.approved}</td>
                        <td >{list.balance}</td>
                    </tr>)}

                </tbody>

                   
            </table>

        </div>


        <PDFDownloadLink 
        
        document={<DownloadDocument 
        
    dateRange={search.date_range.label}
    data={content} 
    staff={search.employee.label +' ' + search.employee.last_name}

        />} 
        fileName={search.employee.label +'_' + search.employee.last_name+'.pdf'}
        className="btn btn-primary btn-sm text-right no-print"  
                    >
                  {({ blob, url, loading, error }) => (loading ? 'Loading document...' :  <span   ><i className='fa fa-download'></i> Download</span>)}
                </PDFDownloadLink>
                
        </div>

            </div>
        </div>
    </div>
    </div>:[]}

                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default StaffLeave 