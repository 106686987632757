import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo} from 'react'
import { COMPANY_CODE, ImagesUrl, STAFF_CODE, ServerUrl, config, selectStyles } from '../../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal, CustomLoader, closeModal } from '../../../components/notify';
import Select, { components } from 'react-select'
import { fetchEmployee, fetchCountry, handleFetchState } from '../../../api/ReactQuery';
import { useQuery } from 'react-query'
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'

const MemberAward =({code}:{code:any})=> {

    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState([] as any);
    const [contact, setContact] = useState([] as any);
    const {data} = useQuery(["employee", code], fetchEmployee)
    const [errors, setErrors] = useState({
        title:'',
        award:''
       
     })
    const [profile, setProfile] = useState({
        award:'',
       title:'',
        year:'',
        code:Math.random().toString(36).substring(2, 9),
        date: new Date()
    });



    const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	
        setProfile({...profile, [name]:value});
       setErrors({...errors, [name]:''});
     }


     const handleChangeSelect =(event:ChangeEvent<HTMLSelectElement>)=>{
        let {name, value} = event.target;	
        setProfile({...profile, [name]:value});
       setErrors({...errors, [name]:''});
     }
   
        const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
            event.preventDefault();
        
            let error = {...errors}; 
            let formIsValid = true;
        
        let msg ='This field is required';
        

        if(!profile.title){
            error.title =msg;
            formIsValid = false;
        } 
        
        
        if(!profile.award){
            error.award =msg;
            formIsValid = false;
        } 
        
        

        if(!formIsValid){
        setErrors(error) 
        }
        
        
        if(formIsValid) {
           LoadingModal()

                const emergency =  JSON.parse(content[0].membership_awards)
                const contacts = emergency.concat(profile)

                const log = JSON.parse(content[0].update_log)
                const item = log.concat( {
                    code:STAFF_CODE,
                    action:'Award: Added one award',
                    date_time: new Date()
                })
    
             const logs = JSON.stringify(item,null, 2)


                const newProfile = content.map((item:any)=>{
                const membership_awards = JSON.stringify(contacts,null, 2)


                    return {...item, 
                        membership_awards:membership_awards
                    }
                  })
                  
                  setContent(newProfile)
                  setContact(contacts)

                      const fd = new FormData();
               fd.append('logs', logs);
                Object.entries(newProfile[0]).forEach(([key, value]) => {
                                    fd.append(key, String(value));
                                }); 

        let click = document.getElementById('close')?.click();

           let url = ServerUrl+'/api/employee/update/'+code;
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status === 200){
             SuccessModal(response.data.message)
           
                      } else{
                        ErrorModal('', response.data.message)
                      }   
                  })
                  .catch((error)=>{
                   ErrorModal('oops', error.message)
                  }).finally(()=>{

                    setProfile({
                        award:'',
                        title:'',
                         year:'',
                         code:Math.random().toString(36).substring(2, 9),
                         date: new Date()
                    })

                  })  
            }
        
        }



const DeleteModal =(code:string)=>{
    
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading(),
    confirmButtonText: 'Yes, I am!',

    preConfirm: async() => {

const newContact = contact.filter((item:any)=>item.code !==code)



const log = JSON.parse(content[0].update_log)
const item = log.concat( {
    code:STAFF_CODE,
    action:'Awards: Removed one award',
    date_time: new Date()
})

const logs = JSON.stringify(item,null, 2)
const newProfile = content.map((item:any)=>{
    return {...item, 
        membership_awards:JSON.stringify(newContact,null, 2)
    }
  })
  
  setContent(newProfile)
  setContact(newContact)

      const fd = new FormData();
fd.append('logs', logs);
Object.entries(newProfile[0]).forEach(([key, value]) => {
                    fd.append(key, String(value));
                }); 

let url = ServerUrl+'/api/employee/update/'+code;
return await axios.post(url, fd, config)
.then(response =>{

if(response.data.status !== 200){
    throw new Error(response.statusText)

      } else{
        return response.data
      }   
  })
  .catch((error)=>{
    Swal.showValidationMessage(
        `Request failed: ${error}`
      )
  })
    }
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }




        const TableData =()=>{

        
            const columns = [
                {
                    name: 'Type',
                    cell: (row:any) => row.award, 
                    sortable: true,
                },
                {
                    name: 'Title',
                    selector: (row:any) =>row.title,
                    sortable: true,
                },
                {
                    name: 'year',
                    selector: (row:any) => row.year,
                    sortable: true,
                },
               
                {
                    name: 'Action',
                    cell: (row:any, index:any, column:any, id:any) => <div className='row'>  
                        <label className="label label-info pointer"  onClick={()=>EditItem(row)} ><i className='ti-pencil'></i> Edit</label>
                    <label className="label label-danger pointer" onClick={()=>DeleteModal(row.code)}><i className='ti-trash'></i> Delete</label></div>,
                        left:true
                              
        
                },
            ];
            
        
          
        
            return <DataTable
            columns={columns}
            data={contact}
            dense
            highlightOnHover
            pagination
            selectableRowsHighlight
            striped
            progressPending={loading}
            progressComponent={<CustomLoader />}
        />
        
        }


        const handleUpdate =(event: FormEvent<HTMLButtonElement>)=>{
            event.preventDefault();
        
            let error = {...errors}; 
            let formIsValid = true;
        
        let msg ='This field is required';
        

        if(!profile.title){
            error.title =msg;
            formIsValid = false;
        } 
        
        
        if(!profile.award){
            error.award =msg;
            formIsValid = false;
        } 
        

        if(!formIsValid){
        setErrors(error) 
        }
        
        
        if(formIsValid) {
           LoadingModal()

              
                const newEmergency = contact.map((item:any)=>{
                   
                    if(item.code ===profile.code){
                        return profile
                    }
    
                     return item
                      })


                const log = JSON.parse(content[0].update_log)
                const item = log.concat( {
                    code:STAFF_CODE,
                    action:'Award: Updated one award',
                    date_time: new Date()
                })
    
             const logs = JSON.stringify(item,null, 2)


                const newProfile = content.map((item:any)=>{
                const membership_awards = JSON.stringify(newEmergency,null, 2)


                    return {...item, 
                        membership_awards:membership_awards
                    }
                  })
                  
                  setContent(newProfile)
                  setContact(newEmergency)

                      const fd = new FormData();
               fd.append('logs', logs);
                Object.entries(newProfile[0]).forEach(([key, value]) => {
                                    fd.append(key, String(value));
                                }); 

        let click = document.getElementById('closeEdit')?.click();

           let url = ServerUrl+'/api/employee/update/'+code;
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status === 200){
             SuccessModal(response.data.message)
           
                      } else{
                        ErrorModal('', response.data.message)
                      }   
                  })
                  .catch((error)=>{
                   ErrorModal('oops', error.message)
                  }).finally(()=>{

                    setProfile({
                        award:'',
                        title:'',
                         year:'',
                         code:Math.random().toString(36).substring(2, 9),
                         date: new Date()
                    })

                  })  
            }
        
        }


        const EditItem =(data:any)=>{
            setProfile({
                code:data.code,
                title:data.title,
                award:data.award,
                year:data.year,
                date:data.date
            });
            setErrors({
                title:'',
                award:''
              
            })
            let click = document.getElementById('openEdit')?.click()
        
        }

        const handleReset =()=>{
            setProfile({
                award:'',
                        title:'',
                         year:'',
                         code:Math.random().toString(36).substring(2, 9),
                         date: new Date()
            })
            let click = document.getElementById('openAdd')?.click()
        }

useEffect(()=>{

   try{

    if (data!==undefined && data.length!==0){
    setContent(data)
    setContact(JSON.parse(data[0].membership_awards))
    window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
         
    }

   }catch(err) {

   }
    
    }, [data])


  return (                             
    <div>
    
     
    <div className="card z-depth-0">
    
    <div className="card-header">
    <h5>Membership & Awards</h5>
    
    <div className="card-header-right">
    
<label className="label label-primary pointer " onClick={handleReset}> <i className='fa fa-plus text-white'></i> Add </label>
<label  id='openAdd' data-toggle="modal" data-target="#large-Modal"> </label>   
                   
    </div>
    
    </div>
    



    <label data-toggle="modal" data-target="#editAward" id='openEdit' ></label>
    <div className="modal fade push-modal-right" id="editAward" tabIndex={-1} role="dialog" data-backdrop="static" >
        <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
                <div className="modal-header bg-dark">
                    <h4 className="modal-title text-white">Update Membership</h4>
                  
    
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i className='fa fa-ban text-danger'></i>
            </button>
    
                </div>
                <div className="modal-body">
                <div className="row">
    <div className="col-md-12">
    <div className="form-group">
    <label className="col-form-label">Select Type <span className='text-danger'>*</span></label>
    
    <select className={errors.award?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} name='award' value={profile.award} onChange={handleChangeSelect}>
    <option value='' selected disabled>--Select--</option>
        <option value='Award'>Award</option>
        <option value='Membership'>Membership</option>
    </select>
    </div>
    
    </div>
    
    
    <div className="col-md-12">
    <div className="form-group">
    <label className="col-form-label">Title <span className='text-danger'>*</span></label>
    
  
    <input type="text" className={errors.title?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='title' value={profile.title} onChange={handleChange} />
    
    {errors.title&&
    <div className="col-form-label has-danger">{errors.title&&errors.title} </div>}
    </div>
    
    </div>
    
    
    <div className="col-md-12">
    <div className="form-group">
    <label className="col-form-label">Year</label>
    
   
<input type="number" min='1900' max='2099' step='1' className="form-control form-control-lg"  name='year' value={profile.year} onChange={handleChange} />
    </div>
    
    </div>
    
    
    
    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-default waves-effect " data-dismiss="modal" id='closeEdit' >Close</button>
                    <button type="button" className="btn btn-outline-success " onClick={handleUpdate} >Update</button>
                </div>
            </div>
        </div>
    </div>
    


    
    <div className="modal fade push-modal-right" id="large-Modal" tabIndex={-1} role="dialog" data-backdrop="static" >
        <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
                <div className="modal-header bg-dark">
                    <h4 className="modal-title text-white">Add Membership</h4>
                  
    
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i className='fa fa-ban text-danger'></i>
            </button>
    
                </div>
                <div className="modal-body">
                <div className="row">
    <div className="col-md-12">
    <div className="form-group">
    <label className="col-form-label">Select Type <span className='text-danger'>*</span></label>
    
    <select className={errors.award?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} name='award' value={profile.award} onChange={handleChangeSelect}>
    <option value='' selected disabled>--Select--</option>
        <option value='Award'>Award</option>
        <option value='Membership'>Membership</option>
    </select>
    </div>
    
    </div>
    
    
    <div className="col-md-12">
    <div className="form-group">
    <label className="col-form-label">Title <span className='text-danger'>*</span></label>
    
  
    <input type="text" className={errors.title?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='title' value={profile.title} onChange={handleChange} />
    
    {errors.title&&
    <div className="col-form-label has-danger">{errors.title&&errors.title} </div>}
    </div>
    
    </div>
    
    
    <div className="col-md-12">
    <div className="form-group">
    <label className="col-form-label">Year</label>
    
   
<input type="number" min='1900' max='2099' step='1' className="form-control form-control-lg"  name='year' value={profile.year} onChange={handleChange} />
    </div>
    
    </div>
    
    
    
    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-default waves-effect " data-dismiss="modal" id='close' >Close</button>
                    <button type="button" className="btn btn-primary waves-effect waves-light " onClick={handleSubmit}>Save </button>
                </div>
            </div>
        </div>
    </div>
    
    
    
    
    
    <div className="card-block">
    
    
    <div className="table-responsive m-t-10">
        <TableData />
   
    </div>
    
    </div>
    </div>
    </div>
                  
 
  )
}

export default MemberAward 