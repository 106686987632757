import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, CURRENCY, ImagesUrl, STAFF_CODE, ServerUrl, config, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'


import { useQuery } from 'react-query'
import Select from 'react-select'
import { FetchProfile, fetchDepartment} from '../../api/ReactQuery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormatNumber, convertDate, convertDateTime, convertTime, getBritishDate, getMonthYear, getTime, shortText } from '../../components/GlobalFunction';
import { Link } from 'react-router-dom';

const AccountStatement =()=> {
    const profile = useQuery(["profile"], FetchProfile)
    const [content, setContent] = useState([] as any);
    const [sqlQuery, setSqlQuery] = useState('');

    const [search, setSearch] = useState({

        start_date:null,
        end_date:null,
        report_type: 'Income',
        debit:0,
        credit:0
     })

    
           const [errors, setErrors] = useState({
            start_date:'',
            end_date:''
           
         })

         const handleChangeBox =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
            setSearch({...search, [name]:value});
            setErrors({...errors, [name]:''})
         }

        
               const handleChangeDate = (data:any, name:string)=>{
                setSearch({...search, [name]:data});
                setErrors({...errors, [name]:''})
                setContent([])

             }



               

const fetchContent = async()=>{


    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';
              

        if(!search.start_date){
            error.start_date =msg;
            formIsValid = false;
        }  

        if(!search.end_date){
            error.end_date =msg;
            formIsValid = false;
        }  

        if(!formIsValid){
        setErrors(error) 
        }else{
    LoadingModal()


    var sql = "Select h.title, i.amount, i.income_date, i.remark, i.created_at, (case when i.income_head=h.code then 'credit' else '' end) as type  from tbl_income i, tbl_income_head h where h.code =  i.income_head  and i.company_code = '"+COMPANY_CODE+"' and i.income_date >= '"+convertDate(search.start_date)+"' and i.income_date <= '"+convertDate(search.end_date)+"' union Select p.title, e.amount,  e.expenses_date, e.remark, e.created_at, (case when e.expenses_head =p.code then 'debit' else ' ' end) as type from tbl_expenses e, tbl_expenses_head p where e.expenses_head = p.code and e.company_code = '"+COMPANY_CODE+"' and e.expenses_date  >= '"+convertDate(search.start_date)+"' and e.expenses_date  <= '"+convertDate(search.end_date)+"'"




          const fd = new FormData();
          fd.append('sql', sql); 
          let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
        await  axios.post(url, fd, config).then((result:any)=>{
          if(Array.isArray(result.data.data) && result.data.data.length!==0){
            setContent(result.data.data)
            var credit = 0, debit = 0;
            result.data.data.map((data:any)=>data.type==='debit'?debit+=Number(data.amount):credit+=Number(data.amount))
            setSearch({...search, debit:debit, credit:credit})

            window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
            }else{
                setContent([]) 
                Alerts('default',' There are no record found')
            }
          })
          .catch((error)=>{
              Alerts('danger',error.message)
          }).finally(()=>{
              closeModal()
          })
        }
          } 


      

  return ( <>
<Breadcrumb 
   title="Account Statement"
   summary="Record  Expenses"
   >
    <li className="breadcrumb-item">
    <Link to="/finance/account_statement">Finance</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Report</a> </li>
         <li className="breadcrumb-item"><a href="#!">Account Statement</a> </li>
   </Breadcrumb>


      <div className="pcoded-inner-content">
<div className="main-body">
<div className="page-wrapper">
<div className="page-body">


<div className="row">
<div className=" col-md-12">
<div className="card z-depth-0">
    <div className="card-header">
        <h5>FINANCE REGISTER</h5>
        <span  ><a href="#"> Display Register</a></span>
        <div className="card-header-right">

            <ul className="list-unstyled card-option">
                <li><i className="fa fa fa-wrench open-card-option"></i></li>
                <li><i className="fa fa-window-maximize full-card"></i></li>
                <li><i className="fa fa-minus minimize-card"></i></li>
                <li><i className="fa fa-refresh reload-card"></i></li>
                <li><i className="fa fa-trash close-card"></i></li>
            </ul>
            
        </div>
    </div>
    <div className="card-block ">
       


        <div className="row">
       

    <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Start Date <span className='text-danger'>*</span></label>
           
            <DatePicker 
            selected={search.start_date}      
             isClearable
             dateFormat="yyyy-MM-dd"
             className={errors.start_date?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}
             placeholderText="Select date"
           
 onChange={(date:any) => handleChangeDate(date, 'start_date')} />


        
{errors.start_date&&
    <div className="col-form-label has-danger">{errors.start_date&&errors.start_date} </div>}
             </div>

             </div>

             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">End Date <span className='text-danger'>*</span></label>
           
            <DatePicker 
            selected={search.end_date}      
             isClearable
             dateFormat="yyyy-MM-dd"
             className={errors.end_date?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}
             placeholderText="Select date"
           
 onChange={(date:any) => handleChangeDate(date, 'end_date')} />


        
{errors.end_date&&
    <div className="col-form-label has-danger">{errors.end_date&&errors.end_date} </div>}
             </div>

             </div>




        </div>



<hr/>
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={fetchContent}>Search</button>
</div>
</div>





    </div>
</div>
</div>
</div>






{content.length!==0?
  <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-block">
    

    <div className="row">
        <div className="table-responsive ">
       
        <table className="table table-sm table-bordered table-hover">
                <thead>
                    <tr>
                        <th colSpan={7} className='text-center '>
                        <img 
           src={ImagesUrl+'/about/'+profile.data.logo}  alt="Click to change" 
           className="m-b-10 img-100" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/logo.png'}} />


                        <h4>{profile.data.company_name} </h4>
                        <h5>{profile.data.company_address}</h5>
                        <h6 className='m-b-5'>{profile.data.email}, {profile.data.phone}</h6>

                        <div className="alert alert-info text-center mb-1 mt-2">

                        <h5> ACCOUNT STATEMENT</h5>
                        </div>
                        </th>
                    </tr>
                  

                  <tr>
                        <td colSpan={4}>  <h6>Date Range: { convertDate(search.start_date) + ' To ' +convertDate(search.end_date) } </h6></td>
                        <td colSpan={3}>  <h6>Date Printed: {/* {getBritishDate(new Date()) } */}</h6></td>
                    </tr> 


                    <tr style={{backgroundColor:'#E7E0EE'}}>
          
                <th>S/N</th>
                <th>Record Date</th>
                <th>Txn Date</th>
                    <th>Head Name</th>
                    <th>Income ({CURRENCY})</th>
                    <th>Expenses ({CURRENCY})</th>
                    <th>Remark</th>
                </tr>

                </thead>
                <tbody>
                    {content&&content.map((st:any, index:number)=>
                    <tr key={index}>
                        <td >{index+1}</td>
                        <td>{st.created_at}</td>
                        <td>{getBritishDate(st.income_date)}</td>
                        <td>{st.title}</td>
                        
                <td>{st.type==='credit'?FormatNumber(st.amount):''}</td>
                <td>{st.type==='debit'?FormatNumber(st.amount):''}</td>

                    <td>{st.remark}</td>
                       
                   
                </tr>)}
                          

                </tbody>
<tfoot>
                <tr style={{backgroundColor:'#E7E0EE'}}>
          
          <th>S/N</th>
          <th>Record Date</th>
          <th>Txn Date</th>
              <th>Head Name</th>
              <th> {FormatNumber(search.credit)} </th>
              <th> {FormatNumber(search.debit)} </th>
              <th>Remark</th>
          </tr>

          </tfoot>
            </table>

        </div>

        <button className="btn btn-primary btn-sm text-right" type='button' ><i className='fa fa-print'></i> Print</button>


        </div>

            </div>
        </div>
    </div>
    </div>:[]}

</div>
</div>


</div>
</div>  
      </>               
 
  )
}

export default AccountStatement