import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo, useRef} from 'react'
import { COMPANY_CODE, CURRENCY, ImagesUrl, STAFF_CODE, ServerUrl, config, configDownload, selectStyles, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts, CustomLoader } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'

import { useQuery } from 'react-query'
import { fetchDepartment, takeScreenshot } from '../../api/ReactQuery';
import { usePaginated, DOTS } from '../../components/usePagination';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { FormatNumber, getMonthYear } from '../../components/GlobalFunction';
import { Link } from 'react-router-dom';

const  DisplayAssets =()=> {
   
  const department = useQuery(["department"], fetchDepartment)
    const [director, setDirector] = useState({
       
        isName:false,
        isMonth:false,
        isPayDate:false,
        isEarning:false,
        isDeduction:false,

        isNetPay:false,
        isApproved:false,
        
        isAction:false,
    });
    const [search, setSearch] = useState({

        asset_name:'',
        department:[] as any,
        category:[] as any,
        condition:[] as any,
        allocation:[] as any,
        purchase_date:'',

        rowperpage:{value:'10', label:'10'},
        filterText:'',
        selectAll:false,
        totalSelected:0,
     })


    const [filterContent, setFilterContent] = useState([] as any);
    const [staff, setStaff] = useState([] as any);
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
         staff:'',
        cost:'',
        date_repair:'',
        reason:'',
        date_assign:''
       
     })
    

     const [assign, setAssign]= useState({
        code:'',
        asset_name:'',
        staff:[] as any,
        staffName:'',
        date_assign:''
    })
    const [repair, setRepair]= useState({
        asset_code:'',
        reason:'',
        asset_name:'',
        cost:'',
       date_repair:''
    })

     const handleResetColumn =()=>{
        setDirector({
           
            isName:false,
            isMonth:false,
            isPayDate:false,
            isEarning:false,
            isDeduction:false,
    
            isNetPay:false,
            isApproved:false,
            
            isAction:false,
        })
    }
     const fetchStaff =async()=>{
        let url = ServerUrl+'/api/employees/view/'+COMPANY_CODE
        const {data} = await axios.get(url, config)
        setStaff(data.data)
        }
      
   
     const handleSelect = (option:any, action:any)=>{
        setSearch({...search, [action.name]:option});
          setErrors({...errors, [action.name]:''})

           }

           const handleSelectAssign = (option:any, action:any)=>{
            setAssign({...assign, [action.name]:option});
              setErrors({...errors, [action.name]:''})
    
               }
           
     const handleAssignChange = (event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;
        setAssign({...assign, [name] : value });
        setErrors({...errors, [name]:''})
         }



         const handleRepairChange = (event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;
            setRepair({...repair, [name] : value });
            setErrors({...errors, [name]:''})
             }


           const handleChange = (event:ChangeEvent<HTMLInputElement>)=>{
           
            let {name, value} = event.target;	
            setSearch({...search, [name]:value});
           setErrors({...errors, [name]:''});
        }

        
const handleReset =()=>{
    setSearch({
        asset_name:'',
        department:[] as any,
        category:[] as any,
        condition:[] as any,
        allocation:[] as any,
        purchase_date:'',

        rowperpage:{value:'10', label:'10'},
        filterText:'',
        selectAll:false,
        totalSelected:0,
    })
}



  const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	
    setSearch({...search, [name]:value});

    if(value!==''){
        const filteredItems = content.filter(
            (item:any) => item.first_name.toLowerCase().includes(value.toLowerCase()) || item.last_name.toLowerCase().includes(value.toLowerCase()) ||
            item.payment_month.toLowerCase().includes(value.toLowerCase()),
        );

        setFilterContent(filteredItems)
    }else{
        setFilterContent(content)
    } 

 }


const handleClear =()=>{
setSearch({...search, filterText:''});
setFilterContent(content)
}


const handleAllocate =(row:any)=>{
    setAssign({...assign, code : row.code, asset_name:row.asset_name });
    let open =   document.getElementById('openAssetDialog');
    open?.click()
}


const handleRepair =(row:any)=>{
    setRepair({...repair, 
        asset_code : row.code, 
        asset_name:row.asset_name 
    });
    let open =   document.getElementById('openRepairDialog')?.click();
 
}



const handleSubmitRepair =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!repair.cost){
    error.cost =msg;
    formIsValid = false;
} 


if(!repair.reason){
    error.reason =msg;
    formIsValid = false;
} 

if(!repair.date_repair){
    error.date_repair=msg;
    formIsValid = false;
} 


if(!formIsValid){
setErrors(error) 
}

if(formIsValid) {
    LoadingModal()
   


    const fd = new FormData();

    Object.entries(repair).forEach(([key, value]) => {
        fd.append(key, String(value));
    });

    fd.append('code', 'R'+Math.random().toString(36).substring(2, 9));
    fd.append('company_code',  COMPANY_CODE)
    fd.append('added_by', STAFF_CODE);

    let open =   document.getElementById('closeRepairDialog')?.click();


let url = ServerUrl+'/api/assets/repair_asset';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              }else if(response.data.status === 201){
                Alerts('default', response.data.message)
                closeModal()
              }else{
                Alerts('danger', response.data.message)
                closeModal()
              }   
          })
          .catch((error)=>{
            Alerts('danger', error.message)
            closeModal()
          }).finally(()=>{
            setRepair({
                asset_code:'',
                reason:'',
                asset_name:'',
                cost:'',
               date_repair:''
            })
          })
    
        }
}


const handleSubmitAssign =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!assign.date_assign){
    error.date_assign =msg;
    formIsValid = false;
} 


if(!assign.staff.value){
    error.staff =msg;
    formIsValid = false;
} 



if(!formIsValid){
setErrors(error) 
}

if(formIsValid) {
    LoadingModal()
   

    const newContent = content.filter((item:any)=>item.code===assign.code)
    const newHistory = JSON.parse(newContent[0].usage_history);


    let usage = {
        code:Math.random().toString(36).substring(2,9), 
        staff_name:assign.staff.label + ' '+ assign.staff.last_name, 
        staff_code:assign.staff.value, 
        asset:assign.code, 
        date_assign:assign.date_assign, 
        date_returned:'',
        assigned_by:STAFF_CODE
    }
    const hisortyList = newHistory.concat(usage)

    const assignAsset = JSON.stringify(hisortyList,null, 2)

    const fd = new FormData();
    fd.append('code', assign.code);
    fd.append('usage_history',  assignAsset)
    fd.append('assign_to', assign.staff.value);

    let open =   document.getElementById('closeAddDialog')?.click();


let url = ServerUrl+'/api/assets/assign_asset';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                Alerts('danger', response.data.message)
              }   
          })
          .catch((error)=>{
            Alerts('danger', error.message)
          }).finally(()=>{
            setAssign({
                code:'',
                asset_name:'',
                staff:[] as any,
                staffName:'',
                date_assign:''
            })
          })
    
        }
}





const DeleteModal =(row:any)=>{

    let url = ServerUrl+'/api/assets/delete/'+row.id+'/'+row.code;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =filterContent.filter((item:{id:number})=>item.id !==row.id)
            setContent(rs)
            setFilterContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }


const handleSelectChange =()=>{
    let status = search.selectAll ===false?'true':'false';
    const newContent = filterContent.map((item:any)=>{
        return {...item, 
            is_check:status,
        }
  })

  var i = 0;
  newContent.forEach((element:any) => {
      if(element.is_check=== 'true')
     i++
    });

    setFilterContent(newContent)

    setSearch({...search, selectAll:!search.selectAll, totalSelected:i})

   }
   
   

   const handleSelectAll =(list:[])=>{
      
    let is_check = list.every((element:any) => {
     return element.is_check === 'true'
    });
    var i = 0;
        list.forEach((element:any) => {
          if(element.is_check === 'true')
         i++
        });
    setSearch({...search, selectAll:is_check, totalSelected:i})
    
       }



const handleCheck =(code:string)=>{
    const newContent = filterContent.map((item:any)=>{
       
    if(item.code === code){
    return {...item, 
        is_check:item.is_check==='false'?'true':'false',
    }
    };
    return item;
    })
    setFilterContent(newContent)
    handleSelectAll(newContent)
    }
    
            
const CheckboxAll =()=>{ 

    return <div className="chk-option">
    <div className="checkbox-fade fade-in-primary">
        <label className="check-task">
    
        <input type="checkbox"  id="selectAll" checked={search.selectAll} onChange={handleSelectChange} />
                    
        <span className="cr">
            <i className="cr-icon fa fa-check txt-default"></i>
        </span>
        </label>
    </div>
    </div>}


const Checkbox =({code, is_check}:{code:string, is_check:string})=>{ 

return <div className="chk-option">
<div className="checkbox-fade fade-in-primary">
    <label className="check-task">

    <input type="checkbox"  name={code} id={code} checked={is_check==='true'?true:false} onChange={()=>handleCheck(code)} />
                
    <span className="cr">
        <i className="cr-icon fa fa-check txt-default"></i>
    </span>
    </label>
</div>
</div>}

        const TableData =()=>{

            

            const PaginationWrapper =(props:any)=>{
            
                const handleRowsPerPage = useCallback(
                    (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
                    [props.currentPage, props.onChangeRowsPerPage],
                );
                const handleSelectRowsPerPage = useCallback((option:any, action:any)=> props.onChangeRowsPerPage(Number(option.value), props.currentPage),
                [props.currentPage, props.onChangeRowsPerPage],
                )
               
                function getNumberOfPages(rowCount:number, rowsPerPage:number) {
                    return Math.ceil(rowCount / rowsPerPage);
                  }
                const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
                const lastIndex = props.currentPage * props.rowsPerPage;
                const firstIndex = lastIndex - props.rowsPerPage + 1;
            
            
                    const range =
                    props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
            
                    `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
            
                
               
                const Pagination = () => {
                   
                    const pageSize = props.rowsPerPage;
                    const totalCount = content.length
                    const siblingCount = 1
                    const currentPage = props.currentPage
                    
                    const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
                    
                    if (currentPage === 0 ) {
                      return null;
                    }
                    
                    let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
                    return (
                    <nav className="" aria-label="Page navigation sample ">
                      <ul  className="pagination pagination-sm"  >
                        <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
                         <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Previous</a>
                        </li>
                        {paginationRange&&paginationRange.map((pageNumber, index) => {
                          if (pageNumber === DOTS) {
                            return <li className="pagination-item dots" key={index}>
                             <a className="page-link pointer"  >&#8230;</a>
                            </li>
                          }
                          return (
                            <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                              <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                              
                            </li>
                          );
                        })}
                    
                    <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
                    <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
                        </li>
                    
                      </ul> </nav>
                    );
                    
                        }
            
                      
            
                      
            
             return <div className="row mt-3">
                                
            <div className="col-md-5 ">
            <div className="row ml-3" >
            
               Rows per page: &nbsp; <select name="rowPerPage" id="" className='w-40 h-30 ' onChange={handleRowsPerPage}>
                    <option value="10"> 10</option>
                    <option value="20"> 20</option>
                    <option value="30"> 30</option>
                    <option value="40"> 40</option>
                    <option value="50"> 50</option>
                    <option value={content.length}> All</option>
                </select> &nbsp;&nbsp;
                {/*  {range} */} </div>
            </div>
              
            <div className="col-md-7  justify-content-end d-flex">
               <Pagination  />
                </div>
                
            </div>}
            
            
            
            
            
            
                        const columns = [
            
                            {
                                name: <CheckboxAll />,
                                cell: (row:any) => <Checkbox code={row.code} is_check={row.is_check} />, 
                                width:'60px'
                            },
                         
                            {
                                name: 'Image',
                                cell: (row:any) =><img src={ImagesUrl+'/assets/'+row.image_url} alt="user image" className=" img-60" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/no.jpeg'}} />,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isName,
                            },
                            {
                                name: 'Name',
                                selector: (row:any) => row.asset_name,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isMonth,
                                
                            },
                            {
                                name: 'Purchase Date',
                                selector: (row:any) => row.purchase_date,
                                sortable: true,
                                reorder:true,
                                omit: director.isPayDate,
                                wrap:true,
                            },
                            {
                                name: `Purchase Amount (${CURRENCY})`,
                                selector: (row:any) => FormatNumber(row.purchase_amount),
                                sortable: true,
                                reorder:true,
                                omit: director.isEarning,
                            },
                            {
                                name: 'Is Movable',
                                selector: (row:any) => row.is_movable,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isMonth,
                                
                            },
                            {
                                name: 'Category',
                                selector: (row:any) => row.category,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isMonth,
                                
                            },
                            {
                                name: 'Condition',
                                selector: (row:any) => row.condition,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isMonth,
                                
                            },
                            {
                                name: 'Life Span',
                                selector: (row:any) => row.life_span,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isMonth,
                                
                            },
                            {
                                name: 'Action',
                                cell: (row:any, index:any, column:any, id:any) => 
                                
                                <div className="dropdown-info dropdown">
                    <button className="btn btn-info dropdown-toggle  btn-sm" type="button" id="dropdown-4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">Action</button>
                    <div className="dropdown-menu z-index-100" aria-labelledby="dropdown-4" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut" x-placement="bottom-start" >
                        <a className="dropdown-item " href="#"><i className='fa fa-print'></i> Preview Asset</a>
                        <a className="dropdown-item " href="#!" onClick={()=>handleRepair(row)}> <i className='fa fa-cog'></i> Repair Asset</a>
                        <a className="dropdown-item " href="#!" onClick={()=>handleAllocate(row)} ><i className='fa fa-user'></i> Allocate Asset</a>
                        <a className="dropdown-item " href={"/assets/update/"+row.code}><i className='fa fa-edit'></i> Edit Asset</a>
                    <div className="dropdown-divider"></div>
                      <a className={"dropdown-item "} href="#!" onClick={()=>DeleteModal(row)}> <i className='fa fa-delete'></i> Delete Asset</a>
                    </div>
                </div>,
                                    left:true,
                                    omit: director.isAction,
                                    width:'150px'
                            },
                        ];
                        
                    
                    
                    
                        return <DataTable
                        columns={columns}
                        data={filterContent}
                        highlightOnHover
                        pagination
                        paginationComponent={PaginationWrapper}
                        selectableRowsHighlight
                        striped
                        responsive
                        fixedHeader
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                    />
                    }
            



                    const sqlQuery = ()=>{

                      let sql = " SELECT 'false' as is_check, a.code, a.asset_name, a.category, a.model, d.department_name, a.condition, a.image_url, a.usage_history, a.purchase_date, a.purchase_amount, a.is_movable, a.life_span FROM tbl_assets a LEFT JOIN tbl_departments d on d.code = a.department WHERE  a.asset_name <> '' "
     
                      if(search.department.length!==0){
                        sql = sql + " and a.department = '"+search.department.value+"'"
                    }
    
                    if(search.purchase_date!==''){
                        sql = sql + " and a.purchase_date = '"+search.purchase_date+"'"
                    }
    
                    if(search.condition.length!==0){
                        sql = sql + " and a.condition = '"+search.condition.value+"'"
                    }
    
                    if(search.category.length!==0){
                        sql = sql + " and a.category = '"+search.category.value+"'"
                    }
    
    
                    if(search.asset_name!==''){
         
                        sql = sql + " and a.asset_name LIKE '%"+search.asset_name+"%'"
                       
                    }
    
    
                    if(search.allocation.length!==0){
                        sql = sql + " and a.allocation = '"+search.allocation.value+"'"
                    } 


                      return sql
                    }


         const fetchContent = async()=>{
            setLoading(true)
     
                 let sql = sqlQuery()

                 const fd = new FormData();
                 fd.append('sql', sql); 
                 let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
               await  axios.post(url, fd, config).then((result:any)=>{

                 if(Array.isArray(result.data.data) && result.data.data.length!==0){
                    setFilterContent(result.data.data)
                   setContent(result.data.data)

                   }else{

                    setContent([])
                    setFilterContent([])
                   }
                 })
                 .catch((error)=>{
                     Alerts('danger',error.message)
                 }).finally(()=>{
                     setLoading(false)
                 })
                 } 





                 const handleDeleteAll =()=>{

                    if(search.totalSelected<1){
                      Alerts('danger','Please select at least one record to delete ')
                    }else{
                  
                      let assetsList =[] as any;
                      let selectedAssets = filterContent.filter((item:any)=>item.is_check ==='true')
                  
                      if(Array.isArray(selectedAssets)){
                        selectedAssets.forEach((list:any) => {
                            assetsList.push(list.code)
                        });
                      }
                  
                      const fd = new FormData();
                      fd.append('assetsList', JSON.stringify(assetsList, null, 2));
                      let url = ServerUrl+'/api/assets/destroy_multiple/'+COMPANY_CODE;
                      Swal.fire({
                        title: 'Are you really sure?',
                        text: "This is very dangerous, you shouldn't do it!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        showLoaderOnConfirm: true,
                      preConfirm: async() => {
                        return await axios.post(url, fd, config).then(response=>{
                         if(response.data.status!==200){
                              throw new Error(response.statusText)
                            }
                            return response.data
                          })
                          .catch(error => {
                            Swal.showValidationMessage(
                              `Request failed: ${error}`
                            )
                          })
                      },
                      allowOutsideClick: () => !Swal.isLoading(),
                        confirmButtonText: 'Yes, I am!'
                      }).then((result:any) => {
                        if (result.isConfirmed && result.value.status===200) {
                  
                          let rs =filterContent.filter((item:any)=>item.is_check ==='false')
                         setContent(rs)
                         setFilterContent(rs)
                           Swal.fire(
                                      'Deleted!',
                                      'Record has been deleted.',
                                      'success'
                                    ) 
                        }
                      })
                  
                    }
                  
                   }


                   const handleDownload = async(extension:string)=>{
             
                    let sql = sqlQuery()
                const fd = new FormData();
                fd.append('sql', sql); 
                fd.append('extension', extension); 
                let url = ServerUrl+'/api/download_file/assets'
                await  axios.post(url, fd, configDownload).then((response:any)=>{
                 const url = window.URL.createObjectURL(new Blob([response.data]));
                   
                     const link = document.createElement('a');
                     link.href = url;
                     link.download = '_assets.'+extension
                     link.click();
                     })
                }


    useEffect(()=>{
        fetchStaff()
       fetchContent()
      }, [])


    

  return ( <>
  <Breadcrumb 
   title="Display Assets"
   summary="List of all assest"
   >
    <li className="breadcrumb-item">
    <Link to="#!">Assets</Link>
                    </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">




 <label data-toggle="modal" data-target='#assignAsset' id='openAssetDialog' ></label>


<div className="modal fade " id='assignAsset' tabIndex={-1} role="dialog">
<div className="modal-dialog" role="document">
<div className="modal-content ">
<div className="modal-header bg-dark">
  <h6 className="modal-title text-white"><i className='fa fa-info'></i> Assign Asset to Staff</h6>
  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
           <i className='fa fa-ban text-danger'></i>
           </button>
</div>
<div className="modal-body">


<div className="row">

<section className="col-md-12">


<div className="form-group">
						<label className="bold ">Assets</label>
				<input type="text" name="asset_name" value={assign.asset_name} readOnly={true} onChange={handleAssignChange} className='form-control' />
												
			</div>	



<div className="form-group">
<label className="bold "> Select Staff <span className='text-danger'>*</span></label>

<Select 
                options={
                               staff&&staff.map((list:any, idx:number)=> {
                                return {key:idx, value: list.code, label: list.first_name, last_name:list.last_name, middle_name:list.middle_name, title:list.title }
                                })}
                                className={errors.staff?"form-control form-control-danger":""} 
                     getOptionLabel={option =>` ${option.label} ${option.last_name}`}
    onChange={handleSelectAssign}  name="staff" value={assign.staff} 
   styles={styles}
    /> 
                    
                    {errors.staff&&
    <div className="col-form-label has-danger">{errors.staff&&errors.staff} </div>}
</div>


<div className="form-group">
						<label className="bold ">Allocation Date <span className='text-danger'>*</span></label>
					<input type="date" name="date_assign" value={assign.date_assign} onChange={handleAssignChange} className={errors.date_assign  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.date_assign  && errors.date_assign}</span>
											</div>



</section>

</div>


</div>
<div className="modal-footer">
  <button type="button"  id='closeAddDialog' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
  <button type="button" onClick={handleSubmitAssign} className="btn btn-inverse">Allocate Asset</button>
</div>
</div>
</div>
</div>




<label data-toggle="modal" id='openRepairDialog'   data-target='#repairAsset' ></label>


<div className="modal fade " id='repairAsset' tabIndex={-1} role="dialog">
<div className="modal-dialog" role="document">
<div className="modal-content ">
<div className="modal-header bg-dark">
  <h6 className="modal-title text-white"><i className='fa fa-info'></i> Repair Asset</h6>
  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
           <i className='fa fa-ban text-danger'></i>
           </button>
</div>
<div className="modal-body">

<div className="row">

<section className="col-md-12">


<div className="form-group">
						<label className="bold ">Assets</label>
				<input type="text" name="asset_name" value={repair.asset_name} readOnly={true} onChange={handleRepairChange} className='form-control' />
												
			</div>	


<div className="form-group">
						<label className="bold ">Cost of Repair <span className='text-danger'>*</span></label>
					<input type="number" name="cost" value={repair.cost} onChange={handleRepairChange} className={errors.cost  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.cost  && errors.cost}</span>
											</div>

<div className="form-group">
						<label className="bold ">Repair Date <span className='text-danger'>*</span></label>
					<input type="date" name="date_repair" value={repair.date_repair} onChange={handleRepairChange} className={errors.date_repair  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.date_repair  && errors.date_repair}</span>
											</div>


                                            <div className="form-group">
						<label className="bold ">Reason For Repair <span className='text-danger'>*</span></label>
					<input type="reason" name="reason" value={repair.reason} onChange={handleRepairChange} className={errors.reason  ? 'form-control form-control-danger' : 'form-control'} /><span style={{color:'red'}}>{errors.reason  && errors.reason}</span>
											</div>
</section>

</div>


</div>
<div className="modal-footer">
  <button type="button"  id='closeRepairDialog' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
  <button type="button" onClick={handleSubmitRepair} className="btn btn-inverse">Submit</button>
</div>
</div>
</div>
</div>



    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Payment History</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                              
                                    
                                    
         <div className="content-wrappers ">
                            
         <div className=''>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Search Assets</h5>
                <span>Add all offcie department</span>
              
            </div>
            <div className="card-block">
    
        
                        <div className="row">
                  

                        <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Search by Department</label>
    
    
    <Select  options={
            department.data&&department.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.department_name}
                    })}   
                    styles={styles} 
			onChange={handleSelect}    
            name="department" 
            value={search.department} 
               />
    
    </div>
    
    </div>


    <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Search By Category </label>


                                <Select  options={[
                                  { value: 'Funitures & Fittings', label: 'Funitures & Fittings' },
                                  { value: 'Office Equipment', label: 'Office Equipment' },
                        { value: 'Computers', label: 'Computers' },
                        { value: 'Automobile', label: 'Automobile' },
                        { value: 'Others', label: 'Others' }
                              ]}
                                    
                          onChange={handleSelect} 
                          name="category" 
                          value={search.category} 
                          
                          styles={styles}  
                          /> 
                        

                                 </div>
                                 </div>

                                 <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Search By Allocation </label>


                                <Select  options={[
                                    { value: 'All', label: 'All' },
                                  { value: 'Unallocated', label: 'Unallocated' },
                                  { value: 'Allocatted', label: 'Allocatted' }
                              ]}
                                    
                          onChange={handleSelect} 
                          name="category" 
                          value={search.category} 
                          
                          styles={styles}  
                          /> 
                        

                                 </div>
                                 </div>


                                 <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Search By Condition </label>


                                <Select   options={[
                    { value: 'New', label: 'New' },
                    { value: 'Old', label: 'Old' },
					{ value: 'Used', label: 'Used' },
					{ value: 'Missing', label: 'Missing' },
					{ value: 'Out for Repair', label: 'Out for Repair' },
					{ value: 'Lost / Stolen', label: 'Lost / Stolen' },
					{ value: 'Broken - Not Repairable', label: 'Broken - Not Repairable' },
					{ value: 'Damage - awaiting Service', label: 'Damage - awaiting Service' }
					

                ]} 
                                    
                          onChange={handleSelect} 
                          name="condition" 
                          value={search.condition} 
                          styles={styles}  
                          /> 
                              
                                 </div>
                                 </div>

                                 <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Purchase Date</label>
           
            <input type="date"   className="form-control form-control-lg"  name='purchase_date' value={search.purchase_date} onChange={handleChange}  />

             </div>

             </div>



                     <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Search By Asset Name </label>
           
<input type="text"  className="form-control form-control-lg"   name='asset_name' value={search.asset_name} onChange={handleChange}  />
          

             </div>

             </div>

   </div>

          
<hr />
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-outline-danger btn-round pr-5 pl-5 mr-3" type='button' onClick={handleReset}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={fetchContent}>Search</button>
</div>
</div>
    
            </div>
        </div>
    </div>
    </div>
    
    
    

    
                </div>

        </div>
                       
                    </section>
                </div>
            </div>
        </div>
    </div>
                                    </div>
                                </div>
                            </div>







                            <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Assets History</h5>
                <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
              
                <div className="card-header-right">

<div className="dropdown-info dropdown">
        <button type="button" className="btn btn-success active dropdown-toggle dropdown-icon btn-sm " data-toggle="dropdown"><i className='fas fa-eye text-white'></i> Export</button>
                    <div className="dropdown-menu" aria-labelledby="dropdown-4" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut" x-placement="bottom-start" >

                        <a className="dropdown-item waves-light waves-effect" onClick={()=>handleDownload('pdf')} href="#!"><i className="fas fa-file-pdf "></i> Export as PDF</a>

                        <a className="dropdown-item waves-light waves-effect" href="#!" onClick={()=>handleDownload('xlsx')}><i className="fas fa-file-excel "></i> Export as Excel</a>

                        <a className="dropdown-item waves-light waves-effect" href="#!" onClick={()=>takeScreenshot('takeshot')}><i className="fas fa-image "></i> Export as Image</a>
                        <a className="dropdown-item waves-light waves-effect" href="#!"><i className="fas fa-print "></i> Print Preview</a>
                    </div>
                </div>&nbsp;

        <a href='/assets/registration' className="btn btn-primary btn-sm "><i className='fa fa-circle-plus text-white'></i> Add New</a>
        </div> 
            </div>
            <div className="card-block">
    




            <div className="row">

<div className=" col-md-8 ">


                  &nbsp;

                  <a href='#!' className="btn btn-primary btn-sm "><i className='fa fa-print text-white'></i></a>&nbsp;

                  <button type="button" className="btn btn-outline-danger btn-sm " onClick={handleDeleteAll} ><i className="fas fa-trash"></i> Delete Selected ({search.totalSelected})</button>&nbsp;

  <div className="btn-group ">
                   
                    <button type="button" className="btn btn-inverse dropdown-toggle dropdown-icon  btn-sm" data-toggle="dropdown">
                      <span className="sr-only">Toggle Dropdown</span> <i className='fas fa-fw fa-filter'></i> Visibility &nbsp;
                    </button>
                    <div className="dropdown-menu" role="menu">

                      <a className={!director.isName?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isName:!director.isName})}>Staff</a>

                      <a className={!director.isMonth?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isMonth:!director.isMonth})}>Payment Month</a>

                      <a className={!director.isPayDate?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isPayDate:!director.isPayDate})}>Payment Date</a>


                      <a className={!director.isEarning?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isEarning:!director.isEarning})}>Earning</a>

                      <a className={!director.isDeduction?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isDeduction:!director.isDeduction})}>Deduction</a>

                      <a className={!director.isNetPay?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isNetPay:!director.isNetPay})}>Net Pay</a>



                      <a className={!director.isAction?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isAction:!director.isAction})}>Action</a>

                    <div className="dropdown-divider"></div>
                      <a className={"dropdown-item"} href="#!" onClick={handleResetColumn}>Reset Column</a>
                   
                    </div>
                  </div>
                  </div>

    <div className=" col-md-4 ">
    <div className="input-group  " >
                    <input type="text" name="filterText" id="filterText" key="filterText"  onChange={handleSearch} value={search.filterText} className="form-control float-right form-control-sm" placeholder="Type to search" />
                   
                    <div className="input-group-append">
                        <button type="button" onClick={handleClear} className="btn btn-default btn-sm" >
                          <i className="fas fa-close"></i>
                        </button>
                      </div>
                  </div>
      

    </div>
</div>

    <div className="row">
        <div className="table-responsive ">
        <TableData />
        </div>
        </div>

            </div>
        </div>
    </div>
    </div>


                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default DisplayAssets