
import { useEffect, useState} from 'react'
import { COMPANY_CODE, ServerUrl, config, group_styles } from '../../components/Includes';
import axios from 'axios';
import {Chart,ArcElement, registerables } from 'chart.js';
import {Bar, Pie} from 'react-chartjs-2'
import { convertDate, getMonthYear } from '../../components/GlobalFunction';

import { useQuery } from 'react-query'
import { fetchSettings } from '../../api/ReactQuery';
import Breadcrumb from '../../components/Breadcrumb';
import { Link } from 'react-router-dom';
import { DateList } from '../../components/Data';
import Select from 'react-select'

const LeaveDashboard =()=> {
  
    let thisMonth = getMonthYear(new Date().toISOString().slice(0,10));
let month = new Date().toISOString().slice(0,7)
    Chart.register(ArcElement, ...registerables)

    var today = new Date().toISOString().slice(0,10);
    var date = new Date()
    var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1, 23, 59, 59)
    var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)

    var firstDayOfYear = new Date(date.getFullYear(), 0, 1, 23, 59, 59)
    var lastDayOfYear = new Date(date.getFullYear()+1, 0, 0, 23, 59, 59)

    const [search, setSearch] = useState({
      date_range:  {label:'2023-01-01 - 2023-12-31', value:'2023-01-01 - 2023-12-31', start_date:'2023-01-01', end_date:'2023-12-31'},
   })

      const [staffList, setStaffList] = useState([] as any);
      const [yearList, setYearList] = useState([] as any);
    const [event, setEvent] = useState(0);
    const [leaveType, setLeaveType] = useState([])
    const [holiday, setHoliday] = useState([])
    const [statistics, setStatistics] = useState({
       
        apply:0,
        approved:0,
        pending:0,
        reject:0,
        leave_type:0,
        holidays:0,
        events:0,
        onleave:0,
        staff:0
    });



    const fetchEvent =async()=>{
      var sql ="SELECT title, IFNULL(count(id),0) as data FROM tbl_holidays WHERE event_type = 'Event' and start_date >=  '"+convertDate(firstDayOfMonth)+"' and end_date  <= '"+convertDate(lastDayOfMonth)+"' group by title " ;

      const fd = new FormData();
      fd.append('sql', sql); 
      
      let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
    await  axios.post(url, fd, config).then((result:any)=>{

      if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
        setEvent(result.data.data[0].data)
        }else{
          setEvent(0)
        }
      })
  }

    const fetchHoliday =async()=>{
        var sql ="SELECT title, sum(no_days) as data FROM tbl_holidays WHERE event_type = 'Holiday' and start_date >=  '"+search.date_range.start_date+"' and end_date  <= '"+search.date_range.end_date+"' group by title " ;

        const fd = new FormData();
        fd.append('sql', sql); 
        let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
      await  axios.post(url, fd, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
          setHoliday(result.data.data)

          }else{
            setHoliday([])
          }
        })
    }



    const fetchStatistics=async()=>{
       
        var sql =" SELECT (SELECT count(*) from tbl_employee where termination_reason = '') as staff, (SELECT  count(*) from  (SELECT CONCAT(e.first_name, ' ', e.last_name) as staff_name, sum(l.no_days) as total, l.effective_month FROM tbl_assign_leave l, tbl_employee e WHERE e.code = l.staff_code  GROUP BY l.staff_code, l.effective_month, e.first_name,  e.last_name) as total) as data, (select count(*) from tbl_employee where employment_status = 'On Leave' and termination_reason = '') as onLeave, (select count(*) from tbl_leave_application where start_date >=  '"+convertDate(firstDayOfYear)+"' and end_date  <= '"+convertDate(lastDayOfYear)+"' and is_approved = 'Pending') as pending, (select count(*) from tbl_leave_application where start_date >=  '"+convertDate(firstDayOfYear)+"' and end_date  <= '"+convertDate(lastDayOfYear)+"' and is_approved = 'Approved') as approved, (select count(*) from tbl_leave_application where start_date >=  '"+convertDate(firstDayOfYear)+"' and end_date  <= '"+convertDate(lastDayOfYear)+"' and is_approved = 'Reject') as reject, (select count(*) from tbl_leave_application where start_date >=  '"+convertDate(firstDayOfYear)+"' and end_date  <= '"+convertDate(lastDayOfYear)+"') as apply,   (select count(*) from tbl_holidays where start_date >=  '"+convertDate(firstDayOfYear)+"' and end_date  <= '"+convertDate(lastDayOfYear)+"' and event_type = 'Event') as event, (select count(*) from tbl_holidays where start_date >=  '"+convertDate(firstDayOfYear)+"' and end_date  <= '"+convertDate(lastDayOfYear)+"' and event_type = 'Holiday') as holiday, (select count(*) from tbl_leave_type) as leave_type"

        const fd = new FormData();
        fd.append('sql', sql); 
        let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
   await axios.post(url, fd, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
          setStatistics({
            events:result.data.data[0].event,
            onleave:result.data.data[0].onLeave,
            pending:result.data.data[0].pending,
            approved:result.data.data[0].approved,
            reject:result.data.data[0].reject,
            apply:result.data.data[0].apply,
            holidays:result.data.data[0].holiday,
            leave_type:result.data.data[0].leave_type,
            staff:result.data.data[0].staff - result.data.data[0].data
        
        })
          }
        })
             }




    const fetchLeaveType=()=>{
       
        var sql = "SELECT l.leave_name as label, count(a.id) as data FROM tbl_leave_application a, tbl_leave_type l  WHERE l.code = a.leave_code and a.start_date >=  '"+search.date_range.start_date+"' and a.end_date  <= '"+search.date_range.end_date+"'  group by l.leave_name "
        
        const fd = new FormData();
        fd.append('sql', sql); 
        let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
    axios.post(url, fd, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
          setLeaveType(result.data.data)
          }else{
            setLeaveType([])
          }
        })
             }


             const fetchStaffList=()=>{
       
              var sql = "SELECT e.first_name as label, count(a.id) as apply, sum(case when a.is_approved = 'Pending'   then 1 else 0 end) AS 'Pending', sum(case when a.is_approved = 'Approved'  then 1 else 0 end) AS 'Approved' FROM tbl_leave_application a, tbl_employee e WHERE e.code = a.staff_code and a.start_date >=  '"+search.date_range.start_date+"' and a.end_date  <= '"+search.date_range.end_date+"'  group by a.staff_code, e.first_name "
              
              const fd = new FormData();
              fd.append('sql', sql); 
              let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
          axios.post(url, fd, config).then((result:any)=>{
              if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
                setStaffList(result.data.data)

                }else{
                  setStaffList([])
                }
              })
                   }

                   const staffListStark = {
                    labels: staffList.map((data:any)=>data.label),
                    datasets: [
                      {
                        label: '# of Application',
                        data: staffList.map((data:any)=>data.apply),
                        backgroundColor: '#5555ff',
                      },
                      
                      {
                        label: '# of Approved',
                        data: staffList.map((data:any)=>data.Approved),
                        backgroundColor: '#3a8300',
                      }
                    ],
                  };



             const fetchYearly=()=>{
       
              var sql = "SELECT current_year as label, sum(case when is_approved = 'Pending'   then 1 else 0 end) AS 'Pending', sum(case when is_approved = 'Approved'  then 1 else 0 end) AS 'Approved', sum(case when is_approved = 'Reject'  then 1 else 0 end) AS 'Reject' FROM tbl_leave_application  group by current_year"
              
              const fd = new FormData();
              fd.append('sql', sql); 
              let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
          axios.post(url, fd, config).then((result:any)=>{
              if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
                setYearList(result.data.data)
                }
              })
                   }


                   const yearListStark = {
                    labels: yearList.map((data:any)=>data.label),
                    datasets: [
                      {
                        label: '# of Pending',
                        data: yearList.map((data:any)=>data.Pending),
                        backgroundColor: 'rgb(25, 43, 234)',
                      },
                      
                      {
                        label: '# of Approved',
                        data: yearList.map((data:any)=>data.Approved),
                        backgroundColor: 'rgb(12, 178, 12)',
                      },
                      {
                        label: '# of Reject',
                        data: yearList.map((data:any)=>data.Reject),
                        backgroundColor: 'rgb(255, 99, 132)',
                      }
                    ],
                  };



    const holidayList= {
        labels: holiday.map((data:any)=>data.title),
        datasets: [
          {
            label: 'Total',
            backgroundColor: [  '#5555ff', '#404E67',],
            hoverBackgroundColor: [ '#3a8300', '#CACACA'],
            data: holiday.map((data:any)=>data.data), //[65, 59, 80, 81, 56]
          }
        ]
      }



      const leaveTypeList = {
        labels: leaveType.map((data:any)=>data.label),
        datasets: [
          {
                     
            label: 'Total Application',
            backgroundColor: [
              '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507', '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b'],
            hoverBackgroundColor: [
            '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507', '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b', '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '#26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507'],
            data: leaveType.map((data:any)=>data.data)
          }
        ]
      }


     


      const optionStark = {
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      };

      const handleSelect = (option:any, action:any)=>{
       setSearch({...search, [action.name]:option});
         

           }


           
           const  fetchSearch =()=>{
            fetchStaffList()
            fetchStatistics()
            fetchYearly()
            fetchHoliday()
            fetchLeaveType()
           }


      useEffect(()=>{
        fetchEvent()
        fetchLeaveType()
        fetchHoliday()
        fetchStaffList()
        fetchStatistics()
        fetchYearly()
    }, [])

  return ( <>
   <Breadcrumb 
   title="Leave Dashboard"
   summary="Statistical display of leave event"
   >
    <li className="breadcrumb-item">
    <Link to="/leave/leave_list">Leave</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Dashboard</a> </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
                                <div className="main-body">
                                    <div className="page-wrapper">
                                        <div className="page-body">




                                        <div className="row">


                                        <div className="col-md-3">
                                                <div className="card">

                                                <div className="card-footer bg-c-purple">
                                                        <div className="row align-items-center">
                                                            <div className="col-9">
                                                                <p className="text-white m-b-0">Total Application</p>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-block">
                                                        <div className="row align-items-center">
                                                            <div className="col-12 text-center">
                                                                <h4 className="text-c-purple">{statistics.apply}</h4>
                                                                <h6 className="text-muted m-b-0">Year {date.getFullYear()}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="col-md-3">
                                                <div className="card">

                                                <div className="card-footer bg-c-green">
                                                        <div className="row align-items-center">
                                                            <div className="col-9">
                                                                <p className="text-white m-b-0">Total Approved</p>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-block">
                                                        <div className="row align-items-center">
                                                            <div className="col-12 text-center">
                                                                <h4 className="text-c-green">{statistics.approved}</h4>
                                                                <h6 className="text-muted m-b-0">Year {date.getFullYear()}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="card">

                                                <div className="card-footer bg-c-red">
                                                        <div className="row align-items-center">
                                                            <div className="col-9">
                                                                <p className="text-white m-b-0">Total Pending</p>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-block">
                                                        <div className="row align-items-center">
                                                            <div className="col-12 text-center">
                                                                <h4 className="text-c-red">{statistics.pending}</h4>
                                                                <h6 className="text-muted m-b-0">Year {date.getFullYear()}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="col-md-3">
                                                <div className="card">

                                                <div className="card-footer bg-c-blue">
                                                        <div className="row align-items-center">
                                                            <div className="col-9">
                                                                <p className="text-white m-b-0">Total Reject</p>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-block">
                                                        <div className="row align-items-center">
                                                            <div className="col-12 text-center">
                                                                <h4 className="text-c-blue">{statistics.reject}</h4>
                                                                <h6 className="text-muted m-b-0">Year {date.getFullYear()}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        <div className="col-md-3">
                                                <div className="card text-center bg-c-purple text-white">
                                                    <div className="card-block">
                                                        <i className='fa fa-cogs fa-2x text-white'></i>
                                                        <h5 className="m-b-15 m-t-15">{statistics.leave_type}</h5>
                                                        <h6 className="m-b-0">Leave Type</h6>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-3">
                                                <div className="card text-center bg-c-red text-white">
                                                    <div className="card-block">
                                                        <i className='fas fa-glass-cheers fa-2x text-white'></i>
                                                        <h5 className="m-b-15 m-t-15">{statistics.holidays}</h5>
                                                        <h6 className="m-b-0">Total Holidays</h6>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-3">
                                                <div className="card text-center bg-c-green text-white">
                                                    <div className="card-block">
                                                        <i className='fa fa-calendar fa-2x text-white'></i>
                                                        <h5 className="m-b-15 m-t-15">{statistics.events}</h5>
                                                        <h6 className="m-b-0">Total Events</h6>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-3">
                                                <div className="card text-center bg-c-blue text-white">
                                                    <div className="card-block">
                                                        <i className='fa fa-sign-out fa-2x text-white'></i>
                                                        <h5 className="m-b-15 m-t-15">{statistics.onleave}</h5>
                                                        <h6 className="m-b-0">Staff On Leave</h6>
                                                    </div>
                                                </div>
                                            </div>


</div>

<div className="row ">
<div className="col-md-6 text-right">

<label className="col-form-label text-bold">Date Range </label>
</div>

  <div className="col-md-6 ">
    
             <div className="form-group">
             <div className="input-group input-group-inverse ">
            <Select  options={
                               DateList&&DateList.map((list:any, idx:number)=> {
                                return {key:idx, value: list.value, label: list.label, start_date:list.start_date, end_date:list.end_date}
                                })} 
                        onChange={handleSelect}  name="date_range"
                value={search.date_range} 
                styles={group_styles} 
            />
          

          <div className="input-group-append  " onClick={fetchSearch} >
                    <span className="input-group-text "><a href='#!' data-tip="Search" className='text-white' title="Add Job Title"  ><i className="fa  fa-search text-white" id="addIcon"></i> Search</a></span>
                  </div>

            	</div>

             </div>  </div>
</div>
<div className="row ">




<div className="col-md-12 ">
                <div className="card ">
                    <div className="card-header">
                        <h5>Staff Leave Applications ({search.date_range.label})</h5>
                    </div>
                    <div className="card-block">

                   
<Bar
          data={staffListStark}
        /> 

                    </div>
                </div>
            </div>



</div>

<div className="row">



       


    <div className="col-md-4 ">
<div className="card feed-card">
<div className="card-header">
    <h5>To Do List</h5>
</div>
<div className="card-block">

{statistics.pending>=1? <div className="row m-b-30">
        <div className="col-auto p-r-0">
            <i className="fa fa-one bg-c-blue feed-icon">{statistics.pending}</i>
        </div>
        <div className="col">
            <h6 className="m-b-5"> <Link to="/leave/leave_list"> Leave awaiting approval </Link></h6>
        </div>
    </div>:[]}


   {statistics.staff>=1? <div className="row m-b-30">
        <div className="col-auto p-r-0">
            <i className="fa fa-two bg-c-red feed-icon">{statistics.staff}</i>
        </div>
        <div className="col">
        <h6 className="m-b-5"> <a href="/leave/assign_leave"> Employee awaiting to assign  leave </a></h6>
        </div>
    </div>:[]}

    {event>=1?  <div className="row m-b-30">
        <div className="col-auto p-r-0">
            <i className="fa fa-one bg-c-green feed-icon">{event}</i>
        </div>
        <div className="col">
           
        <h6 className="m-b-5"> <a href="/leave/event_calendar"> Days events upcoming</a></h6>
        </div>
    </div>:[]}

   
</div>
</div>
</div>


<div className="col-md-8 ">
<div className="card feed-card">
<div className="card-header">
    <h5>Leave Type Application ({search.date_range.label})</h5>
</div>
<div className="card-block">

   
<Bar
          data={leaveTypeList}
        />  


</div>
</div>
</div>

</div>




<div className="row">
<div className="col-md-7 ">
<div className="card ">
<div className="card-header">
    <h5>Yearly Leave Application</h5>
</div>
<div className="card-block">

   
<Bar
          data={yearListStark}
          options={optionStark}
        /> 


</div>
</div>
</div>




<div className="col-md-5 ">
<div className="card ">
<div className="card-header">
    <h5>Holidays ({search.date_range.label})</h5>
</div>
<div className="card-block">
<Pie data={holidayList} 


/>


</div>
</div>
</div>

         </div>


                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
                            </>               
 
  )
}

export default LeaveDashboard