import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo, useRef} from 'react'
import { COMPANY_CODE, CURRENCY, STAFF_CODE, ServerUrl, config, configDownload, selectStyles, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts, CustomLoader } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'
import { useQuery } from 'react-query'
import { usePaginated, DOTS } from '../../components/usePagination';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { FormatNumber, convertDate, getBritishDate, getMonthYear } from '../../components/GlobalFunction';
import { fetchDepartment, fetchJobs, takeScreenshot } from '../../api/ReactQuery';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
const  Disciplenary =()=> {
   
    const [director, setDirector] = useState({
       
        isName:false,
        isSpotType:false,
        isDate:false,
        isDescription:false,
        isCriteria:false,
        isPublic:false,
        isPoint:false,
        isAction:false,
        
    });

    const [review, setReview]=useState({
      employee:[] as any,
      description:'',
      date_review:null,
      review_by:STAFF_CODE,
      is_visible:'Yes',
      spot_type:[] as any,
      criteria:[] as any,
      alloted_point:[] as any,
  });



  const handleChangeDate = (data:any, name:string)=>{
   
   setReview({...review, [name]:data});
    setErrors({...errors, [name]:''});
 }


    const [search, setSearch] = useState({
        rowperpage:{value:'10', label:'10'},
        filterText:'',
        selectAll:false,
        totalSelected:0,
     })

     const [sqlQuery, setSqlQuery] = useState('');

     const [allCriteria, setAllCriteria] = useState([]);
     const [criteria, setCriteria] = useState([]);
    const [filterContent, setFilterContent] = useState([] as any);
    const [staff, setStaff] = useState([] as any);
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
      description:'',
      date_review:'',
      review_by:'',
      visible:'Yes',
      spot_type:'',
      criteria:'',
      alloted_point:''
       
     })
    
     const handleResetColumn =()=>{
        setDirector({
          isName:false,
          isSpotType:false,
          isDate:false,
          isDescription:false,
          isCriteria:false,
          isPublic:false,
          isPoint:false,
          isAction:false,
        })
    }
     const fetchStaff =async()=>{
        let url = ServerUrl+'/api/employees/view/'+COMPANY_CODE
        const {data} = await axios.get(url, config)
        setStaff(data.data)
        }
      
   
        const fetchCriteria =async()=>{
          let url = ServerUrl+'/api/performance/review/view/'+COMPANY_CODE
          const {data} = await axios.get(url, config)
          setAllCriteria(data.data)
          }

     const handleSelect = (option:any, action:any)=>{
        setReview({...review, [action.name]:option});
          setErrors({...errors, [action.name]:''})
          
          if(action.name ==='spot_type'){
      setCriteria(allCriteria.filter((item:any)=>item.criteria===option.value))
          }
          if(action.name ==='employee'){
          fetchContent(option.value)
          }
           }


           const handleChangeText = (event:ChangeEvent<HTMLTextAreaElement>)=>{
           
            let {name, value} = event.target;	
            setReview({...review, [name]:value});
           setErrors({...errors, [name]:''});
        }

        const handleChange = (event:ChangeEvent<HTMLInputElement>)=>{
           
          let {name, value} = event.target;	
          setReview({...review, [name]:value});
         setErrors({...errors, [name]:''});
      }
        
      

      const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
        event.preventDefault();
    
        let error = {...errors}; 
        let formIsValid = true;
    
    let msg ='This field is required';
  
    
  if(review.spot_type.length===0){
    error.spot_type=msg;
    formIsValid = false;
} 

if(!review.description){
  error.description=msg;
  formIsValid = false;
}
    

if(!review.date_review){
  error.date_review=msg;
  formIsValid = false;
}


    if(review.criteria.length===0){
        error.criteria=msg;
        formIsValid = false;
    } 
    
    
    if(review.alloted_point.length===0){
      error.alloted_point=msg;
      formIsValid = false;
  } 
    
    if(!formIsValid){
    setErrors(error) 
    }
    
    if(formIsValid) {
    
        LoadingModal()
       
        const fd = new FormData();
       
        let code = 'ER'+Math.random().toString(36).substring(2, 9)
        fd.append('added_by', STAFF_CODE);
        fd.append('company_code', COMPANY_CODE);
        fd.append('code', code);
        fd.append('staff_code', review.employee.value);
        fd.append('description', review.description);
        fd.append('date_review', convertDate(review.date_review));
        fd.append('is_visible', review.is_visible);
        fd.append('spot_type', review.spot_type.value);
        fd.append('criteria', review.criteria.value);
        fd.append('alloted_point', review.alloted_point.value);
    
    
    let url = ServerUrl+'/api/performance/disciplinary/add';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.status === 200){
         SuccessModal(response.data.message)
       
         let click = document.getElementById('closeEdit')?.click();
                  } else{
                    Alerts('danger', response.data.message)
                  }   
              })
              .catch((error)=>{
                Alerts('danger', error.message)
              }).finally(()=>{
           fetchContent(review.employee.value)
             handleReset()
    
              })
        }
    
    }
    

const handleReset =()=>{
    setReview({
      ...review,
      description:'',
      date_review:null,
      review_by:'',
      is_visible:'YES',
      spot_type:[] as any,
      criteria:[] as any,
      alloted_point:[] as any,
  });
}




const DeleteModal =(row:any)=>{
    let url = ServerUrl+'/api/performance/disciplinary/delete_one/'+row.id;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =content.filter((item:{code:string})=>item.code !==row.code)
            setFilterContent(rs)
            setContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }



  const handleSelectAll =(list:[])=>{
      
    let is_check = list.every((element:any) => {
     return element.is_check === 'true'
    });
    var i = 0;
        list.forEach((element:any) => {
          if(element.is_check === 'true')
         i++
        });
    setSearch({...search, selectAll:is_check, totalSelected:i})
    
       }


const handleCheck =(code:string)=>{
const newContent = filterContent.map((item:any)=>{
   
if(item.code === code){
return {...item, 
    is_check:item.is_check==='false'?'true':'false',
}
};
return item;
})
setFilterContent(newContent)
handleSelectAll(newContent)
}


const handleSelectChange =()=>{
    let status = search.selectAll ===false?'true':'false';
    const newContent = filterContent.map((item:any)=>{
        return {...item, 
            is_check:status,
        }
  })

  var i = 0;
  newContent.forEach((element:any) => {
      if(element.is_check=== 'true')
     i++
    });

    setFilterContent(newContent)

    setSearch({...search, selectAll:!search.selectAll, totalSelected:i})

   }



  const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	
    setSearch({...search, [name]:value});

    if(value!==''){
        const filteredItems = content.filter(
            (item:any) => item.staff_name.toLowerCase().includes(value.toLowerCase()) || item.spot_type.toLowerCase().includes(value.toLowerCase()) ||
            item.date_review.toLowerCase().includes(value.toLowerCase()),
        );

        setFilterContent(filteredItems)
    }else{
        setFilterContent(content)
    } 

 }


const handleClear =()=>{
setSearch({...search, filterText:''});
setFilterContent(content)
}




        const TableData =()=>{

            

            const PaginationWrapper =(props:any)=>{
            
                const handleRowsPerPage = useCallback(
                    (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
                    [props.currentPage, props.onChangeRowsPerPage],
                );
                const handleSelectRowsPerPage = useCallback((option:any, action:any)=> props.onChangeRowsPerPage(Number(option.value), props.currentPage),
                [props.currentPage, props.onChangeRowsPerPage],
                )
               
                function getNumberOfPages(rowCount:number, rowsPerPage:number) {
                    return Math.ceil(rowCount / rowsPerPage);
                  }
                const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
                const lastIndex = props.currentPage * props.rowsPerPage;
                const firstIndex = lastIndex - props.rowsPerPage + 1;
            
            
                    const range =
                    props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
            
                    `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
            
                
               
                const Pagination = () => {
                   
                    const pageSize = props.rowsPerPage;
                    const totalCount = content.length
                    const siblingCount = 1
                    const currentPage = props.currentPage
                    
                    const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
                    
                    if (currentPage === 0 ) {
                      return null;
                    }
                    
                    let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
                    return (
                    <nav className="" aria-label="Page navigation sample ">
                      <ul  className="pagination pagination-sm"  >
                        <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
                         <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Previous</a>
                        </li>
                        {paginationRange&&paginationRange.map((pageNumber, index) => {
                          if (pageNumber === DOTS) {
                            return <li className="pagination-item dots" key={index}>
                             <a className="page-link pointer"  >&#8230;</a>
                            </li>
                          }
                          return (
                            <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                              <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                              
                            </li>
                          );
                        })}
                    
                    <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
                    <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
                        </li>
                    
                      </ul> </nav>
                    );
                    
                        }
            
                      
            
                      
            
             return <div className="row mt-3">
                                
            <div className="col-md-5 ">
            <div className="row ml-3" >
            
               Rows per page: &nbsp; <select name="rowPerPage" id="" className='w-40 h-30 ' onChange={handleRowsPerPage}>
                    <option value="10"> 10</option>
                    <option value="20"> 20</option>
                    <option value="30"> 30</option>
                    <option value="40"> 40</option>
                    <option value="50"> 50</option>
                    <option value={content.length}> All</option>
                </select> &nbsp;&nbsp;
                {/*  {range} */} </div>
            </div>
              
            <div className="col-md-7  justify-content-end d-flex">
               <Pagination  />
                </div>
                
            </div>}
            
            
            
const CheckboxAll =()=>{ 

    return <div className="chk-option">
    <div className="checkbox-fade fade-in-primary">
        <label className="check-task">
    
        <input type="checkbox"  id="selectAll" checked={search.selectAll} onChange={handleSelectChange} />
                    
        <span className="cr">
            <i className="cr-icon fa fa-check txt-default"></i>
        </span>
        </label>
    </div>
    </div>}


const Checkbox =({code, is_check}:{code:string, is_check:string})=>{ 

return <div className="chk-option">
<div className="checkbox-fade fade-in-primary">
    <label className="check-task">

    <input type="checkbox"  name={code} id={code} checked={is_check==='true'?true:false} onChange={()=>handleCheck(code)} />
                
    <span className="cr">
        <i className="cr-icon fa fa-check txt-default"></i>
    </span>
    </label>
</div>
</div>}
            
            
            
                        const columns = [
            
                          
                            {
                                name: <CheckboxAll />,
                                cell: (row:any) => <Checkbox code={row.code} is_check={row.is_check} />, 
                                width:'60px'
                            },
                            {
                                name: 'Employee',
                                selector: (row:any) =>row.staff_name,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isName,
                            },
                            {
                                name: 'Spot Type',
                                selector: (row:any) =>row.spot_type,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isSpotType,
                            },
                            {
                              name: 'Criteria',
                              selector: (row:any) => row.criteria_name,
                              sortable: true,
                              reorder:true,
                              wrap:true,
                              omit: director.isCriteria,
                              
                          },
                            {
                                name: 'Point',
                                selector: (row:any) => row.alloted_point,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isPoint,
                                
                            },
                            {
                                name: 'Description',
                                selector: (row:any) => row.description,
                                sortable: true,
                                reorder:true,
                                omit: director.isDescription,
                                wrap:true,
                            },
                            {
                                name: 'Reveiw Date',
                                selector: (row:any) => getBritishDate(row.date_review),
                                sortable: true,
                                reorder:true,
                                omit: director.isDate,
                                wrap:true,
                            },
                            
                            {
                              name: 'Is Public',
                              cell: (row:any) => <label className="switch-md">
                              <input type="checkbox" checked={row.is_visible==='Yes'?true:false}   />
                              <span className="round slider-md"></span>
                            </label>,
                            omit: director.isPublic,
                            width:'100px'
                          },
                            {
                              name: 'Action',
                              cell: (row:any, index:any, column:any, id:any) => <div className='row'>  
                                  <label className="label label-info pointer"  ><i className='ti-pencil'></i> Edit</label>
                              <label className="label label-danger pointer" onClick={()=>DeleteModal(row.id)}><i className='ti-trash'></i> Delete</label></div>,
                                  left:true,
                                  width:'200px',
                                  omit: director.isAction,
                                        
                  
                          },
                        ];
                        
                    
                    
                    
                        return <DataTable
                        columns={columns}
                        data={filterContent}
                        highlightOnHover
                        pagination
                        paginationComponent={PaginationWrapper}
                        selectableRowsHighlight
                        striped
                        responsive
                        fixedHeader
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                    />
                    }
            


                 

         const fetchContent = async(staff_code:string)=>{
            setLoading(true)
     
                 let sql = "SELECT 'false' as is_check, r.id, r.code, CONCAT(e.first_name, ' ', e.last_name)  as staff_name, r.date_review, r.description, r.spot_type, r.alloted_point, r.is_visible, c.title as criteria_name  FROM tbl_employee e, tbl_employee_review r left join tbl_performance_review c on c.code = r.criteria  WHERE e.code = r.staff_code and r.company_code = '"+COMPANY_CODE+"' and r.staff_code = '"+staff_code+"'"
                 
                 setSqlQuery(sql)
                 const fd = new FormData();
                 fd.append('sql', sql); 

                 let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE

                 
               await  axios.post(url, fd, config).then((result:any)=>{

                 if(Array.isArray(result.data.data) && result.data.data.length!==0){
                    setFilterContent(result.data.data)
                   setContent(result.data.data)
                   }else{

                    setContent([])
                    setFilterContent([])
                   }
                 })
                 .catch((error)=>{
                     Alerts('danger',error.message)
                 }).finally(()=>{
                     setLoading(false)
                 })
                 } 





                 const handleDeleteAll =()=>{

                    if(search.totalSelected<1){
                      Alerts('danger','Please select at least one record to delete ')
                    }else{
                  
                      let employeeList =[] as any;
                      let selectedEmployee = filterContent.filter((item:any)=>item.is_check ==='true')
                  
                      if(Array.isArray(selectedEmployee)){
                        selectedEmployee.forEach((list:any) => {
                            employeeList.push(list.code)
                        });
                      }
                  
                      const fd = new FormData();
                      fd.append('employeeList', JSON.stringify(employeeList, null, 2));
                      let url = ServerUrl+'/api/performance/disciplinary/destroy_multiple/'+COMPANY_CODE;
                      Swal.fire({
                        title: 'Are you really sure?',
                        text: "This is very dangerous, you shouldn't do it!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        showLoaderOnConfirm: true,
                      preConfirm: async() => {
                        return await axios.post(url, fd, config).then(response=>{
                         if(response.data.status!==200){
                              throw new Error(response.statusText)
                            }
                            return response.data
                          })
                          .catch(error => {
                            Swal.showValidationMessage(
                              `Request failed: ${error}`
                            )
                          })
                      },
                      allowOutsideClick: () => !Swal.isLoading(),
                        confirmButtonText: 'Yes, I am!'
                      }).then((result:any) => {
                        if (result.isConfirmed && result.value.status===200) {
                  
                          let rs =filterContent.filter((item:any)=>item.is_check ==='false')
                         setContent(rs)
                         setFilterContent(rs)
                           Swal.fire(
                                      'Deleted!',
                                      'Record has been deleted.',
                                      'success'
                                    ) 
                        }
                      })
                  
                    }
                  
                   }

                   const handleDownload = async(extension:string)=>{
             
                const fd = new FormData();
                fd.append('sql', sqlQuery); 
                fd.append('extension', extension); 
                let url = ServerUrl+'/api/download_file/review'
                await  axios.post(url, fd, configDownload).then((response:any)=>{
                 const url = window.URL.createObjectURL(new Blob([response.data]));
                   
                     const link = document.createElement('a');
                     link.href = url;
                     link.download = '_review.'+extension
                     link.click();
                     })
                }



    useEffect(()=>{
      // fetchContent()
        fetchStaff() 
        fetchCriteria()
      }, [])


    

  return ( <>
   <Breadcrumb 
   title="Employee Review"
   summary="Review employees action"
   >
    <li className="breadcrumb-item">
    <Link to="/performance/manage_appraisal">Performance</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Employee Review</a> </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Employee Review</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                              
                                    
                                    
         <div className="content-wrappers ">
                            
         <div className=''>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Select Employee</h5>
                <span>Add all offcie department</span>
              
            </div>
            <div className="card-block">
    
        
                        <div className="row">
                        


                        <div className="col-md-12">
                    <div className="form-group">
                    <label className="col-form-label">Select Employee </label>


                 
                <Select 
                options={
                               staff&&staff.map((list:any, idx:number)=> {
                                return {key:idx, value: list.code, label: list.first_name, last_name:list.last_name, middle_name:list.middle_name, title:list.title }
                                })}
                                 
                     getOptionLabel={option =>` ${option.label} ${option.last_name}`}
    onChange={handleSelect}  name="employee" value={review.employee} 
    styles={styles} 
    /> 
                    </div>
    
                     </div>

                    


   </div>

    
            </div>
        </div>
    </div>
    </div>
    
    
    

<div className="modal fade " id="editItem" tabIndex={-1} role="dialog"  >
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header bg-dark">
                    <h4 className="modal-title text-white"><i className=' fa fa-info'></i> Add a new Review </h4>
                  
    
            <button type="button" className="close" id='closeEdit' data-dismiss="modal" aria-label="Close">
            <i className='fa fa-ban text-danger'></i>
            </button>
    
                </div>
                <div className="modal-body">
                
    
                 


                <div className="row">
             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Review Date <span className='text-danger'>*</span></label>



            <DatePicker 
            selected={review.date_review}      
             isClearable
             dateFormat="yyyy-MM-dd"
             className={errors.date_review?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}
             placeholderText="Select date"
             withPortal
 onChange={(date:any) => handleChangeDate(date, 'date_review')} />


          

            {errors.date_review&&
    <div className="col-form-label has-danger">{errors.date_review&&errors.date_review} </div>}
            </div>  
            
            </div> 


            <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Review Type <span className='text-danger'>*</span></label>



                               
                                <Select options={[ 

                              {value:'Red Spot', label:'Red Spot (-1)'},
                              {value:'Blue Spot', label:'Blue Spot (+1)'}
                                        ]}  
                                    
                          onChange={handleSelect} 
                          className={errors.spot_type ? 'form-control form-control-danger' : ''} 
                          name="spot_type" 
                          value={review.spot_type} 
                          
                          styles={styles} 
                          /> 
                              

                {errors.spot_type&&
    <div className="col-form-label has-danger">{errors.spot_type&&errors.spot_type} </div>}

                                 </div>
                                 </div>


                                 <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Select Criteria <span className='text-danger'>*</span></label>



                               
                                <Select options={
                                criteria&&criteria.map((list:any, idx:number)=> {
                                    return {key:idx, value: list.code, label: list.title}
                                    })} 
                                    
                          onChange={handleSelect} 
                          className={errors.criteria? 'form-control form-control-danger' : ''} 
                          name="criteria" 
                          value={review.criteria} 
                          
                          styles={styles}  
                          /> 
                              

                {errors.criteria&&
    <div className="col-form-label has-danger">{errors.criteria&&errors.criteria} </div>}

                                 </div>
                                 </div>



                                 <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Allotted Point <span className='text-danger'>*</span></label>



                               
                                <Select options={[ 

                              {value:'1', label:'1'},
                              {value:'2', label:'2'},
                              {value:'3', label:'3'}
                                        ]}  
                                    
                          onChange={handleSelect} 
                          className={errors.alloted_point ? 'form-control form-control-danger' : ''} 
                          name="alloted_point" 
                          value={review.alloted_point} 
                          
                          styles={styles} 
                          /> 
                              

                {errors.alloted_point&&
    <div className="col-form-label has-danger">{errors.alloted_point&&errors.alloted_point} </div>}

                                 </div>
                                 </div>

<div className="col-md-12">
  <div className="form-group">
  <label className="col-form-label">Description <span className='text-danger'>*</span></label>

  <textarea name="description" value={review.description} onChange={handleChangeText}  className={errors.description?"form-control form-control-danger ":"form-control "}  placeholder="Maximum 500 words" rows={4}>{review.description}

</textarea>
  </div>
</div>



<div className="col-md-12  m-b-30">
        <h4 className="sub-title">Visible To Employee</h4>
        <div className="form-radio">
                <div className="radio radio-inline">
                    <label>
                        <input type="radio" name="is_visible" value="Yes" onChange={handleChange} checked={review.is_visible ==='Yes'?true:false}  />
                        <i className="helper"></i>Yes
                    </label>
                </div>
                <div className="radio radio-inline">
                    <label>
                        <input type="radio" name="is_visible" value="No" onChange={handleChange} checked={review.is_visible ==='No'?true:false} />
                        <i className="helper"></i>No
                    </label>
                </div>
        </div>
        </div>

                                 </div>


                </div>
                <div className="modal-footer">
                   
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={handleSubmit} >Save Reveiw</button>
                </div>
            </div>
        </div>
    </div>





              {review.employee.length!==0?              <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Review History</h5>
                <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
              
                <div className="card-header-right">


                <div className="dropdown-info dropdown">
        <button type="button" className="btn btn-success active dropdown-toggle dropdown-icon btn-sm " data-toggle="dropdown"><i className='fas fa-eye text-white'></i> Export</button>
                    <div className="dropdown-menu" aria-labelledby="dropdown-4" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut" x-placement="bottom-start" >

                        <a className="dropdown-item waves-light waves-effect" onClick={()=>handleDownload('pdf')} href="#!"><i className="fas fa-file-pdf "></i> Export as PDF</a>

                        <a className="dropdown-item waves-light waves-effect" href="#!" onClick={()=>handleDownload('xlsx')}><i className="fas fa-file-excel "></i> Export as Excel</a>

                        <a className="dropdown-item waves-light waves-effect" href="#!" onClick={()=>takeScreenshot('takeshot')}><i className="fas fa-image "></i> Export as Image</a>
                        <a className="dropdown-item waves-light waves-effect" href="#!"><i className="fas fa-print "></i> Print Preview</a>
                    </div>
                </div>&nbsp;

        <a href='#!' className="btn btn-primary btn-sm " data-toggle="modal" data-target="#editItem" id='openEdit'><i className='fa fa-circle-plus text-white'></i> Add New</a>
        </div> 
            </div>
            <div className="card-block">
    




            <div className="row">

<div className=" col-md-8 ">


                  &nbsp;

                  <a href='#!' className="btn btn-primary btn-sm "><i className='fa fa-print text-white'></i></a>&nbsp;


  <button type="button" className="btn btn-outline-danger btn-sm " onClick={handleDeleteAll} ><i className="fas fa-trash"></i> Delete Selected ({search.totalSelected})</button>&nbsp;

  <div className="btn-group ">
                   
                    <button type="button" className="btn btn-inverse dropdown-toggle dropdown-icon  btn-sm" data-toggle="dropdown">
                      <span className="sr-only">Toggle Dropdown</span> <i className='fas fa-fw fa-filter'></i> Visibility &nbsp;
                    </button>
                    <div className="dropdown-menu" role="menu">

                      <a className={!director.isName?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isName:!director.isName})}>Employee</a>

                    <a className={!director.isSpotType?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isSpotType:!director.isSpotType})}>Spot Type</a>

                      <a className={!director.isCriteria?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isCriteria:!director.isCriteria})}>Criteria</a>


                      <a className={!director.isPoint?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isPoint:!director.isPoint})}>Point</a>

                      <a className={!director.isDescription?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isDescription:!director.isDescription})}>Description</a>

                      <a className={!director.isDate?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isDate:!director.isDate})}>Review Date </a>


                      <a className={!director.isPublic?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isPublic:!director.isPublic})}>Is Public</a>

                      <a className={!director.isAction?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isAction:!director.isAction})}>Action</a>

                    <div className="dropdown-divider"></div>
                      <a className={"dropdown-item"} href="#!" onClick={handleResetColumn}>Reset Column</a>
                   
                    </div>
                  </div>
                  </div>

    <div className=" col-md-4 ">
    <div className="input-group  " >
                    <input type="text" name="filterText" id="filterText" key="filterText"  onChange={handleSearch} value={search.filterText} className="form-control float-right form-control-sm" placeholder="Type to search" />
                   
                    <div className="input-group-append">
                        <button type="button" onClick={handleClear} className="btn btn-default btn-sm" >
                          <i className="fas fa-close"></i>
                        </button>
                      </div>
                  </div>
      

    </div>
</div>

    <div className="row" id='takeshot'>
        <div className="table-responsive ">
        <TableData />
        </div>
        </div>

            </div>
        </div>
    </div>
    </div>:[]}


                          
    </div>

</div>
               
            </section>
        </div>
    </div>
</div>
</div>
                            </div>
                        </div>
                    </div>
      </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default Disciplenary