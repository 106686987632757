import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, STAFF_CODE, ServerUrl, config } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'



import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertDate, convertDateTime, convertTime, getTime } from '../../components/GlobalFunction';
import { Link } from 'react-router-dom';

const AttendanceSettings =()=> {
   
    const [search, setSearch] = useState({
        time_in:'',
        time_out:'',
        grace_time_in:'',
        grace_time_out:'',
        is_schedule:'Active',
        auto_absent:null,
        code:''
     })

    

           const [errors, setErrors] = useState({
            time_in:'',
            time_out:'',
            grace_time_in:'',
            grace_time_out:'',
           
         })

         const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
            setSearch({...search, [name]:value});
           setErrors({...errors, [name]:''});
         }

         const handleChangeDate = (data:any, name:string)=>{
           setSearch({...search, [name]:data});
            setErrors({...errors, [name]:''});
         }






         const fetchContent = async()=>{
            LoadingModal()
            let url = ServerUrl+'/api/attendance/settings/view/'+COMPANY_CODE
          await  axios.get(url, config).then((result:any)=>{
            if(Array.isArray(result.data.data) && result.data.data.length!==0){
               
              let absent:any = new Date(result.data.data[0].auto_absent)
            
             setSearch({
                time_out:result.data.data[0].time_out,
                time_in:result.data.data[0].time_in,
                grace_time_in:result.data.data[0].grace_time_in,
                grace_time_out:result.data.data[0].grace_time_out,
                is_schedule:result.data.data[0].is_schedule,
                auto_absent:absent,
                code:result.data.data[0].code,
              }) 
              }
            })
            .catch((error)=>{
                Alerts('danger', error.message)
            }).finally(()=>{
                closeModal()
            })
            } 






         const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
            event.preventDefault();
        
            let error = {...errors}; 
            let formIsValid = true;
        
        let msg ='This field is required';
        
        if(!search.time_in){
            error.time_in =msg;
            formIsValid = false;
        } 
        
        
        if(!search.time_out){
            error.time_out =msg;
            formIsValid = false;
        } 
        
        
        
        
        if(!formIsValid){
        setErrors(error) 
        }
        
        
        if(formIsValid) {
        
            LoadingModal()
         const fd = new FormData();
          

             /*    fd.append('company_code', COMPANY_CODE);
                fd.append('code', 'A'+Math.random().toString(36).substring(2, 9)); */
                fd.append('time_in', search.time_in);
                fd.append('time_out', search.time_out);
                fd.append('auto_absent', convertDate(search.auto_absent));
                fd.append('grace_time_in', search.grace_time_in);
                fd.append('grace_time_out', search.grace_time_out);
                fd.append('is_schedule', search.is_schedule);
                fd.append('code', search.code);
                
       // let url = ServerUrl+'/api/attendance/settings/add'; 
        let url = ServerUrl+'/api/attendance/update_settings';
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status === 200){
             SuccessModal(response.data.message)
           
                      } else{
                        Alerts('danger',response.data.message)
                        closeModal()
                      }   
                  })
                  .catch((error)=>{
                    Alerts('danger',error.message)
                    closeModal()
                  })
            }
        
        }

useEffect(()=>{
 fetchContent()
}, [])

  return ( <>
   <Breadcrumb 
   title="Settings"
   summary="Setup Attendance"
   >
    <li className="breadcrumb-item">
    <Link to="/attendance/display_attendance">Attendance</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Settings</a> </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
<div className="main-body">
<div className="page-wrapper">
<div className="page-body">


<div className="row">
<div className=" col-md-12">
<div className="card z-depth-0">
    <div className="card-header">
        <h5>ATTENDANCE SETTINGS</h5>
        <div className="card-header-right">

            <ul className="list-unstyled card-option">
                <li><i className="fa fa fa-wrench open-card-option"></i></li>
                <li><i className="fa fa-window-maximize full-card"></i></li>
                <li><i className="fa fa-minus minimize-card"></i></li>
                <li><i className="fa fa-refresh reload-card"></i></li>
                <li><i className="fa fa-trash close-card"></i></li>
            </ul>
            
        </div>
    </div>
    <div className="card-block ">
       


        <div className="row">
            

        <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Time IN <span className='text-danger'>*</span></label>
           

            <input type="time"  className={errors.time_in?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='time_in' value={search.time_in} onChange={handleChange}  />



          

            {errors.time_in&&
    <div className="col-form-label has-danger">{errors.time_in&&errors.time_in} </div>}
             </div>

             </div>

             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Staff Grace Period In Time In Minutes</label>
           
            <input type="number"  className={errors.grace_time_in?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='grace_time_in' value={search.grace_time_in} onChange={handleChange}  />
          

            {errors.grace_time_in&&
    <div className="col-form-label has-danger">{errors.grace_time_in&&errors.grace_time_in} </div>}
             </div>

             </div>

             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Time OUT <span className='text-danger'>*</span></label>
           

            <input type="time"  className={errors.time_out?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='time_out' value={search.time_out} onChange={handleChange}  />

            {errors.time_out&&
    <div className="col-form-label has-danger">{errors.time_out&&errors.time_out} </div>}
             </div>

             </div>


          
    

             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Staff Grace Period Out Time In Minutes</label>
           
            <input type="number"  className={errors.grace_time_out?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='grace_time_out' value={search.grace_time_out} onChange={handleChange}  />
          

            {errors.grace_time_out&&
    <div className="col-form-label has-danger">{errors.grace_time_out&&errors.grace_time_out} </div>}
             </div>

             </div>


    
             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Schedule Automatic Attendance Mark Absent </label>
           
            <DatePicker 
            selected={search.auto_absent}      
             isClearable
             dateFormat="yyyy-MM-dd"
             className="form-control form-control-lg"
             placeholderText="Select date"
           
 onChange={(date:any) => handleChangeDate(date, 'auto_absent')} />


        
             </div>

             </div>


             <div className="col-md-6  m-b-30">
        <h4 className="sub-title">Attendance Scheduler Status</h4>
        <div className="form-radio">
                <div className="radio radio-inline">
                    <label>
                        <input type="radio" name="is_schedule" value="Active" onChange={handleChange} checked={search.is_schedule ==='Active'?true:false}  />
                        <i className="helper"></i>Active
                    </label>
                </div>
                <div className="radio radio-inline">
                    <label>
                        <input type="radio" name="is_schedule" value="Inactive" onChange={handleChange} checked={search.is_schedule ==='Inactive'?true:false} />
                        <i className="helper"></i>Inactive
                    </label>
                </div>
        </div>
        </div>

        </div>


<hr/>
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={handleSubmit}>Update Settings</button>
</div>
</div>





    </div>
</div>
</div>
</div>







</div>
</div>


</div>
</div>  
      </>               
 
  )
}

export default AttendanceSettings