import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo} from 'react'
import { COMPANY_CODE, STAFF_CODE, ServerUrl, config, selectStyles, styles } from '../../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal, CustomLoader, Alerts, closeModal } from '../../../components/notify';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'

import Select, { components } from 'react-select'

const StructureList =()=> {

    const [item, setItem] = useState({
        fixed_salary:[] as any,
        level:'',
    });

    const [editItem, setEditItem] = useState({
        template:'',
        level:'',
        code:'',
        id:''
    });

    const [title, setTitle] = useState([] as any);
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        fixed_salary:'',
         level:'',
       

         template:'',
         edit_level:'',
     })
    

   
     const handleSelect = (option:any, action:any)=>{
        setItem({...item, [action.name]:option});
          setErrors({...errors, [action.name]:''})
           }


    const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;		
    setItem({...item, [name]:value})
   setErrors({...errors, [name]:''});
     }


const handleReset =()=>{
    setItem({
        fixed_salary:[],
        level:'',
    })
}


const getTitle = (code:string) =>{ 
    const result = title.filter((list:any)=>list.code===code);
   const answer = result.map((c:any)=>`(${c.item_code}) ${c.title}`); 
     return  <span>{answer}</span>;
   }


const HandleSalaryTitle=({cell}:{cell:any})=>{
    

    try{
        let rs = JSON.parse(cell)
    let answer = rs.map((itm:any, idx:number)=><div  key={idx}>{getTitle(itm.title_code)}</div> ); 

    return answer;
    }catch(e){

    }
    
}


const getTitleName = (code:string, field:string) =>{ 
    const result = title.filter((list:any)=>list.code===code);
   const answer = result.map((c:any)=>c[field] ); 
     return  String(answer);
   }
   
const getTemplate=(temp:any)=>{

    try{

   
    let value:any = [];
 JSON.parse(temp).map((itm:any, idx:number)=>{
        value.push({value:itm.title_code, label:getTitleName(itm.title_code, 'item_code'), title:getTitleName(itm.title_code, 'title'), key:idx});
    } );
    
return value;

}catch(e){
        
}

}



const TableData =()=>{


    const columns = [
        {
            name: 'Salary Template',
            selector: (row:any) => row.level,
            sortable: true,
        },
        {
            name: 'Fixed Salary',
            cell: (row:any) =><div><HandleSalaryTitle cell={row.template} /> </div>,
            wrap:true
        },
        {
            name: 'Action',
            cell: (row:any, index:any, column:any, id:any) => <div className='row'>  
                <label className="label label-info pointer" onClick={()=>EditItem(row)} ><i className='ti-pencil'></i> Edit</label>
            <label className="label label-danger pointer" onClick={()=>DeleteModal(row.id)}><i className='ti-trash'></i> Delete</label></div>,
                left:true
                      

        },
    ];
    
  

    return <DataTable
    columns={columns}
    data={content}
    dense
    highlightOnHover
    pagination
    selectableRowsHighlight
    striped
    progressPending={loading}
	progressComponent={<CustomLoader />}
/>

}



const handleChangeEdit =(event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	
    setEditItem({...editItem, [name]:value})
   setErrors({...errors, [name]:''});
 }



const EditItem =(data:any)=>{
    setEditItem({
        template:data.template,
        level:data.level,
        code:data.code,
        id:data.id
    }); 
    let click = document.getElementById('openEdit')?.click()

}



const handleSelectEditTemplate = (option:any, action:any)=>{
   // const std =JSON.parse(editItem.template)  

  var value = [];
        for (var i = 0, l = option.length; i < l; i++) {
          value.push({title_code:option[i].value});
        }

        setEditItem({...editItem, template:JSON.stringify(value, null, 1)});
        setErrors({...errors, [action.name]:''})
}



  const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!item.level){
    error.level =msg;
    formIsValid = false;
} 


if(item.fixed_salary.length===0){
    error.fixed_salary=msg;
    formIsValid = false;
} 




if(!formIsValid){
setErrors(error) 
}

if(formIsValid) {

    LoadingModal()
   
    const fd = new FormData();
    var options = item.fixed_salary;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
        value.push({title_code:options[i].value});
    }


    let code = 'ST'+Math.random().toString(36).substring(2, 9)
  
    fd.append('company_code', COMPANY_CODE);
    fd.append('code', code);

    fd.append('added_by', STAFF_CODE);
    fd.append('level', item.level);
    fd.append('template', JSON.stringify(value, null, 2));


let url = ServerUrl+'/api/payroll/structure/add_structure';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                Alerts('danger', response.data.message)
              }   
          })
          .catch((error)=>{
            Alerts('danger', error.message)
          }).finally(()=>{
       fetchData()
         handleReset()

          })
    }

}



const handleUpdate =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!editItem.level){
    error.edit_level =msg;
    formIsValid = false;
} 



if(!editItem.template){
    error.template =msg;
    formIsValid = false;
} 



if(!formIsValid){
setErrors(error) 
}


if(formIsValid){
 LoadingModal()
   
    const fd = new FormData();
    Object.entries(editItem).forEach(([key, value]) => {
        fd.append(key, String(value));
    });
    let url = ServerUrl+'/api/payroll/structure/update_structure';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                Alerts('danger', response.data.message)
              }   
          })
          .catch((error)=>{
            Alerts('danger', error.message)
          }).finally(()=>{
         let click = document.getElementById('closeModal')?.click()
         fetchData()
        
          })  
    }

}

const DeleteModal =(id:number)=>{
    let url = ServerUrl+'/api/payroll/structure/delete_structure/'+id;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =content.filter((item:{id:number})=>item.id !==id)
            setContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }

const fetchTitle = async()=>{
    let url = ServerUrl+'/api/payroll/structure/view_title/'+COMPANY_CODE
  await  axios.get(url, config).then((result:any)=>{
    if(Array.isArray(result.data.data) && result.data.data.length!==0){
      setTitle(result.data.data)
      }
    })
    .catch((error)=>{
        Alerts('danger', error.message)
    })
    } 

    const fetchData = async()=>{
        setLoading(true)
        let url = ServerUrl+'/api/payroll/structure/view_structure/'+COMPANY_CODE
      await  axios.get(url, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){
          setContent(result.data.data)
          }
        })
        .catch((error)=>{
            Alerts('danger', error.message)
        }).finally(()=>{
            setLoading(false)
        })
        } 



      


    useEffect(()=>{
        fetchTitle()
        fetchData()
      }, [])






  return (
    <div className=''>


<label data-toggle="modal" data-target="#editItem" id='openEdit' ></label>

<div className="modal fade " id="editItem" tabIndex={-1} role="dialog" data-backdrop="static" >
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header bg-dark">
                    <h4 className="modal-title text-white">Update Salary Structure </h4>
                  
    
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i className='fa fa-ban text-danger'></i>
            </button>
    
                </div>
                <div className="modal-body">
                
    
                <div className="row">
                  
                 


              <div className="col-md-6">
             <div className="form-group">
              <label className="col-form-label">Salary Template</label>

              <input type="text" className={errors.edit_level?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='level' value={editItem.level} onChange={handleChangeEdit} />

              {errors.edit_level&&
<div className="col-form-label has-danger">{errors.edit_level&&errors.edit_level} </div>}
              </div> 

               </div>

               <div className="col-md-6">
                           <div className="form-group">
                          <label className="col-form-label">Salary Type</label>
                         
                          <Select isMulti options={
                                title&&title.map((list:any, idx:number)=> {
                                    return {key:idx, value: list.code, label: list.item_code, title:list.title}
                                    })}
                                    
                     getOptionLabel={option =>`(${option.label}) ${option.title}`}
                     onChange={handleSelectEditTemplate} 
                     className={errors.template ? 'form-control form-control-danger' : ''} name="template" value={getTemplate(editItem.template)}
    
    styles={selectStyles} 
    />        
                           </div>
                           </div>
              </div> 
                </div>
                <div className="modal-footer">
                    <button type="button" id='closeModal' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-outline-success " onClick={handleUpdate} >Update</button>
                </div>
            </div>
        </div>
    </div>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Salary Structure</h5>
                <span>Add all offcie department</span>
              
            </div>
            <div className="card-block">
    
        
                        <div className="row">
                  
                        <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Salary Template</label>


                    <input type="text" className={errors.level?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='level' value={item.level} onChange={handleChange} />
                    
                    {errors.level&&
    <div className="col-form-label has-danger">{errors.level&&errors.level} </div>}
                    </div>
    
                     </div>


                     <div className="col-md-6">
                                 <div className="form-group">
                                <label className="col-form-label">Fixed Salary</label>
                               
                                <Select isMulti options={
                                title&&title.map((list:any, idx:number)=> {
                                    return {key:idx, value: list.code, label: list.item_code, title:list.title}
                                    })}
                                    
                     getOptionLabel={option =>`(${option.label}) ${option.title}`}
    onChange={handleSelect} className={errors.fixed_salary ? 'form-control form-control-danger' : ''} name="fixed_salary" value={item.fixed_salary} 
    
    styles={selectStyles} 
    /> 
                              
                                 </div>
                                 </div>
                    </div>


<hr/>
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-outline-danger btn-round pr-5 pl-5 mr-3" type='button' onClick={handleReset}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={handleSubmit}>Save</button>
</div>
</div>
    
            </div>
        </div>
    </div>
    </div>
    
    
    

    
    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>All Structure</h5>
                <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
              
            </div>
            <div className="card-block">
    
        <div className="table-responsive m-t-10">
        <TableData />
        </div>
    
            </div>
        </div>
    </div>
    </div>
    
                </div>
  )
}

export default StructureList