import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo} from 'react'
import { COMPANY_CODE, ImagesUrl, STAFF_CODE, ServerUrl, config } from '../../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal, CustomLoader } from '../../../components/notify';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { getFileSize } from '../../../components/GlobalFunction';
import { useQuery } from 'react-query'
import { fetchEmployee } from '../../../api/ReactQuery';

const Files =({code}:{code:any})=> {
    
    const [photo, setPhoto] = useState('');
    const [contact, setContact] = useState([] as any);
    const {data} = useQuery(["employee", code], fetchEmployee)
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        file:'',
        title:''
     })


    
    const [ item, setItem ] = useState({
        code:Math.random().toString(36).substring(2, 9),
        company_code:COMPANY_CODE,
        added_by:STAFF_CODE,
        title:'',
        file_name:'',
        description:'',
        size:'',
        type:'',
        file_url:''
    });


    const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	
        setItem({...item,[name]:value});
       setErrors({...errors, [name]:''});
     }
    
     const handleChangeText =(event:ChangeEvent<HTMLTextAreaElement>)=>{
        let {name, value} = event.target;	
        setItem({...item, description:value});
       setErrors({...errors, [name]:''});
     }
    

     const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
        event.preventDefault();
    
        let error = {...errors}; 
        let formIsValid = true;
    
    let msg ='This field is required';
    
    if(!item.file_url){
        error.file =msg;
        formIsValid = false;
    } 
    if(!item.title){
        error.title =msg;
        formIsValid = false;
    } 
    
    if(!formIsValid){
    setErrors(error) 
    }
    
    if(formIsValid){
    
        LoadingModal()
       
        const fd = new FormData();
    Object.entries(item).forEach(([key, value]) => {
            fd.append(key, String(value));
        });


        const log = JSON.parse(content[0].update_log)
        const items = log.concat( {
            code:STAFF_CODE,
            action:'Files: Added one document',
            date_time: new Date()
        })

         const logs = JSON.stringify(items,null, 2)


        const details = contact.concat(item)
        const docs = JSON.stringify(details,null, 2)



        const newProfile = content.map((item:any)=>{
                return {...item, 
                    documents:docs
                }
              })
              
              setContent(newProfile)
              setContact(details)


        if(photo){
            fd.append('imageFile', photo, item.file_url); 
        } 


        fd.append('id', content[0].id);
        fd.append('code', code);
        fd.append('logs', logs);
        fd.append('documents', docs);
        
           let url = ServerUrl+'/api/documents/employee/'+code;


      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.status === 200){
         SuccessModal(response.data.message)
       
                  } else{
                    ErrorModal('', response.data.message)
                  }   
              })
              .catch((error)=>{
               ErrorModal('oops', error.message)
              }).finally(()=>{
          
             handleReset()
    
              }) 
        }
    
    }




    const handleReset =()=>{
        setItem({...item,
            code:Math.random().toString(36).substring(2, 9),
            file_name:'',
            title:'',
            description:'',
            size:'',
            type:'',
            file_url:'',
        })
        setPhoto('')
        let inputElement = document.getElementById('photo') as HTMLInputElement
        inputElement.value = ''

        let click = document.getElementById('openAdd')?.click()
    }


    const  handleFile = function(fieldName:any){
        return function(newValue:any){                 
       readURL(newValue);
       }
      } 
    
     
    
    const readURL = (input:any)=>{
     
    let displayMessage = '';
    
    const PreviewImage = input.target.name;
    const inputElement = document.getElementById('photo') as HTMLInputElement
    const fileName = inputElement.value
    
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substring(idxDot, fileName.length).toLowerCase();
    
    /* if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){ */
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 1) {
        displayMessage = ' File uploaded is larger than maximum size Allow (5 mb).';
        inputElement.value = '';
        setPhoto('')
        setItem({...item, file_url:'', file_name:''})
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
            var reader = new FileReader();//Initialize FileReader.
            reader.onload = function (e:any) {
                
            //Check if input has files.
            let Id = Math.random().toString(36).substr(2, 9)
            let fileUrl = Id+'_'+input.target.files[0].name

            let f_ext = fileName.substring(fileName.lastIndexOf('\\')+1);

             setItem({...item, 
                type:extFile,
                file_name:f_ext.split(".")[0],
                file_url:fileUrl, 
                size:input.target.files[0].size
                
            })
            setPhoto(input.target.files[0])
              setErrors({...errors, file:''}); 
            }
            reader.readAsDataURL(input.target.files[0]);
            
        }
    }
    /* }else{
    displayMessage = 'Only JPEG|PNG|JPG  file format are allowed with maximum of 5 mb'
    inputElement.value = '';
    setItem({...item, file_url:'', file_name:''})
    }    */
    
    if(displayMessage.length > 0){
    ErrorModal("", displayMessage)
    }
    }
    
    const EditItem =(data:any)=>{
        setItem({
            code:data.code,
        company_code:data.company_code,
        added_by:data.added_by,
        title:data.title,
        file_name:data.file_name,
        description:data.description,
        size:data.size,
        type:data.type,
        file_url:data.file_url
        });
        setErrors({
            file:'',
            title:''
          
        })
        let click = document.getElementById('openEdit')?.click()
    
    }


const TableData =()=>{


    const columns = [
        {
            name: 'Title',
            selector: (row:any) => row.title,
            sortable: true,
            wrap:true
        },
        {
            name: 'Description',
            selector: (row:any) => row.description,
           wrap:true
        },
        {
            name: 'Size',
            selector: (row:any) =>getFileSize(row.size),
            sortable: true,
        },
        {
            name: 'Type',
            selector: (row:any) => row.type,
            sortable: true,
        },
      
       
        {
            name: 'Action',
            cell: (row:any, index:any, column:any, id:any) => <div className='row'> 

            <a href={ImagesUrl+'/employees/documents/'+row.file_url} target='_blank' className="label label-primary"  ><i className='ti-download'></i> View</a>

 <label className="label label-info pointer"  onClick={()=>EditItem(row)} ><i className='ti-pencil'></i> Edit</label>
            <label className="label label-danger pointer" onClick={()=>DeleteModal(row)}><i className='ti-trash'></i> Delete</label></div>,
                left:true
                      
        },
    ];
    
  

    return <DataTable
    columns={columns}
    data={contact}
    dense
    highlightOnHover
    pagination
    selectableRowsHighlight
    striped
    progressPending={loading}
	progressComponent={<CustomLoader />}
/>

}


const DeleteModal =(row:any)=>{

    //let url = ServerUrl+'/api/employee/update/'+code;
    let url = ServerUrl+'/api/employees/settings/onboarding/'+row.id+'/'+row.file_url;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =content.filter((item:{id:number})=>item.id !==row.id)
            setContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }
    



  const handleUpdate =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';


if(!item.file_name){
    error.file =msg;
    formIsValid = false;
} 
if(!item.title){
    error.title =msg;
    formIsValid = false;
} 


if(!formIsValid){
setErrors(error) 
}


if(formIsValid) {
   LoadingModal()

      
        const newDoc = contact.map((list:any)=>{
           
            if(item.code ===list.code){
                return item
            }

             return list
              })


        const log = JSON.parse(content[0].update_log)
        const items = log.concat( {
            code:STAFF_CODE,
            action:'Files: Updated one file',
            date_time: new Date()
        })

     const logs = JSON.stringify(items,null, 2)


        const newProfile = content.map((item:any)=>{
        const docs = JSON.stringify(newDoc,null, 2)


            return {...item, 
                documents:docs
            }
          })
          
          setContent(newProfile)
          setContact(newDoc)

              const fd = new FormData();
       fd.append('logs', logs);
        Object.entries(newProfile[0]).forEach(([key, value]) => {
                            fd.append(key, String(value));
                        }); 

let click = document.getElementById('closeEdit')?.click();

let url = ServerUrl+'/api/employee/update/'+code;
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                ErrorModal('', response.data.message)
              }   
          })
          .catch((error)=>{
           ErrorModal('oops', error.message)
          }).finally(()=>{

            setItem({
                code:Math.random().toString(36).substring(2, 9),
                company_code:COMPANY_CODE,
                added_by:STAFF_CODE,
                title:'',
                file_name:'',
                description:'',
                size:'',
                type:'',
                file_url:''
            })

          })  
    }

}

  useEffect(()=>{

    try{
 
     if (data!==undefined && data.length!==0){
     setContent(data)
     setContact(JSON.parse(data[0].documents))
     window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
         
     }
 
    }catch(err) {
 
    }
     
     }, [data])

  return (
                      
<div>

 
<div className="card z-depth-0">

<div className="card-header">
<h5>Document & Files</h5>

<div className="card-header-right">


               <label className="label label-primary pointer " onClick={handleReset}> <i className='fa fa-plus text-white'></i> Add Files </label>
<label  id='openAdd' data-toggle="modal" data-target="#large-Modal"> </label> 

</div>

</div>



<label data-toggle="modal" data-target="#editFile" id='openEdit' ></label>

<div className="modal fade push-modal-right" id="editFile" tabIndex={-1} role="dialog" data-backdrop="static" >
    <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
            <div className="modal-header bg-dark">
                <h4 className="modal-title text-white">Update Document & Files</h4>
              

        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <i className='fa fa-ban text-danger'></i>
        </button>

            </div>
            <div className="modal-body">
            <div className="row">

<div className="col-md-12">
<div className="form-group">
<label className="col-form-label">Title <span className='text-danger'>*</span></label>

<input type="text"  className={errors.title?"form-control form-control-danger":"form-control"}   value={item.title} name="title" onChange={handleChange} />

</div>

</div>
<div className="col-md-12">
<div className="form-group">
<label className="col-form-label">Description</label>

<textarea className='form-control' name="description" rows={5} value={item.description} onChange={handleChangeText}>{item.description}

</textarea>

</div>

</div>




</div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-default waves-effect " data-dismiss="modal" id='closeEdit'>Close</button>
                <button type="button" className="btn btn-outline-success " onClick={handleUpdate} >Update</button>
            </div>
        </div>
    </div>
</div>


<div className="modal fade push-modal-right" id="large-Modal" tabIndex={-1} role="dialog" data-backdrop="static" >
    <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
            <div className="modal-header bg-dark">
                <h4 className="modal-title text-white">Add Document & Files</h4>
              

        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <i className='fa fa-ban text-danger'></i>
        </button>

            </div>
            <div className="modal-body">
            <div className="row">

<div className="col-md-12">
<div className="form-group">
<label className="col-form-label">Title <span className='text-danger'>*</span></label>

<input type="text"  className={errors.title?"form-control form-control-danger":"form-control"}   value={item.title} name="title" onChange={handleChange} />

</div>

</div>
<div className="col-md-12">
<div className="form-group">
<label className="col-form-label">Description</label>

<textarea className='form-control' name="description" rows={5} value={item.description} onChange={handleChangeText}>{item.description}

</textarea>

</div>

</div>


<div className="col-md-12">
<div className="form-group">

<div className="col-form-label "> Accepts jpg, .png, .gif, .pdf, .xls, .doc up to 1 mb.</div>
                <input type="file" className={errors.file?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} id="photo" onChange={handleFile('photo')}  name="photo"  />
                <div className="col-form-label has-danger"> {errors.file&&errors.file}</div>

</div>

</div>




</div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-default waves-effect " data-dismiss="modal" id='close'>Close</button>
                <button type="button" className="btn btn-primary waves-effect waves-light "  onClick={handleSubmit}>Save File</button>
            </div>
        </div>
    </div>
</div>





<div className="card-block">


<div className="table-responsive m-t-10">
    <TableData />

</div>

</div>
</div>
</div>
  )
}

export default Files