import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo} from 'react'
import Breadcrumb from '../../components/Breadcrumb'
import { ImagesUrl } from '../../components/Includes';
import Qualification from './Profile/Qualification';
import Experience from './Profile/Experience';
import MemberAward from './Profile/MemberAward';
import Personal from './Profile/Personal';

import { useParams} from 'react-router'
import Contact from './Profile/Contact';
import Emergency from './Profile/Emergency';
import Family from './Profile/Family';
import Passport from './Profile/Passport';
import JobHistory from './Profile/JobHistory';
import ReportLine from './Profile/ReportLine';
import Files from './Profile/Files';
import Bank from './Profile/Bank';
import Setup from './Profile/Setup';
import Termination from './Profile/Termination';
import Logs from './Profile/Logs';


import { fetchEmployee, fetchCountry, handleFetchState } from '../../api/ReactQuery';
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom';

const Profile =()=> {

    let params = useParams()
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState([] as any);
    const [disabled, setDisabled] = useState(false);
    const {data} = useQuery(["employee", params.code], fetchEmployee)
    
    const [activeItem, setActiveItem] = useState(1);

    const steps = [
        {title: 'Personal Details'}, 
        {title: 'Contact Address'}, 
        {title: 'Emergency Contacts'}, 
        {title: 'Family'},
        {title: 'Passport, Visa & Licences'}, 
        {title: 'Education History'}, 
        {title: 'Work Experience'},
        {title: 'Membership & Awards'}, 
        {title: 'Job Details'},
        {title: 'Report Line'}, 
        {title: 'Document & Files'}, 
        {title: 'Bank Account Details'},
        {title: 'Photos & Password'}, 
        {title: 'Terminations'}, 
        {title: 'Action Logs'}
    
    ]
    useEffect(()=>{

        try{
     
         if (data!==undefined && data.length!==0){
         setContent(data)
         window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
         }
     
        }catch(err) {
     
        }
         
         }, [data])


  return (
    <>
<Breadcrumb 
   title="Update Employee"
   summary="Use this form to update employees details including contacts and change password."
   >
   <li className="breadcrumb-item">
    <Link to="/employees/view">Employees</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Update Employee</a> </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
<div className="main-body">
    <div className="page-wrapper">
        <div className="page-body">
                 


                {content.length!==0? content.map((list:any, index:number)=>  <div key={index}  className="row">
                    <div className="col-xl-3">
                        <div className="card">
                            <div className="card-header contact-user">

                                <img 
            src={ImagesUrl+'/employees/passport/'+list.passport}  alt="Click to change" 
            className='img-40  pointer img-radius' 
            onClick={()=>setActiveItem(13)} onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+list.gender+'.png'}} />

                                <h5 className="m-l-10">{ list.first_name + ' '+ list.last_name}</h5>
                            </div>
                            <div className="card-block">
                                <ul className="list-group list-contacts">

                                {steps.map((item, idx)=>{

return <li key={idx} onClick={()=>setActiveItem(idx+1)} className= {activeItem===(idx+1)?"list-group-item active pointer":"list-group-item pointer"} ><a href="#!" >{item.title}</a></li>

})}

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-9">




                                    {activeItem===1?
                                                
                                                
                                                <Personal code={params.code} />
                                                :activeItem===2?
                                                
                                            <Contact code={params.code} />
                                            :
                                                activeItem===3?
                                                <Emergency code={params.code} />:
                                                activeItem===4?
                                                
                                                <Family code={params.code} /> :

                                                activeItem===5?  
                                                
                                        <Passport code={params.code} />:
                                            
                                            activeItem===6?
                                                
                                        <Qualification code={params.code} />:
                                        activeItem===7?     

                                        <Experience code={params.code} />:
                                        activeItem===8?   
                                        
                                        
                                            <MemberAward code={params.code} />
                                            :
                                            activeItem===9?   
                            <JobHistory code={params.code} />


                    : activeItem===10?

                    <ReportLine code={params.code} />:



                    activeItem===11?
                                                
                                <Files code={params.code} />
                    :

                    activeItem===12?

                    <Bank code={params.code} />

                    :
                    activeItem===13?
                    <Setup code={params.code} />
                    :
                    activeItem===14?
                    <Termination code={params.code} />
                    :
                    <Logs code={params.code} />
                    }



                    </div>
                </div>):[]}
        

    </div>
                                    </div>
                                </div>
                            </div>  
                            </>               
  )
}

export default Profile