import { useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, STAFF_CODE, ServerUrl, config, selectStyles, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'
import { useQuery } from 'react-query'

import { fetchDepartment, fetchJobs } from '../../api/ReactQuery';
import { Link } from 'react-router-dom';

const CreateTemplate =()=> {

 
    const department = useQuery(["department"], fetchDepartment)
    const [loading, setLoading] = useState(false);
    const [criteria, setCriteria] =useState([] as any);
    const [group, setGroup] =useState([] as any);
    const [content, setContent] = useState([] as any);
    const Jobs = useQuery(["jobs"], fetchJobs)

    const [errors, setErrors] = useState({
        search_paramter:'',
        field_group:'',
        title:'',
        includes:'',
        employee_name:'',
        work_station:'',
        gender:'',
        department:'',
        job_title:'',
        confirmation_date:'',
        service_period:'',
        age_group:'',
        age_field:'',
        employment_status:'',
        service_field:''
     })
   

     const item_list = [
        "Employee Name",
        "Employment Status",
        "Confirmation Date",
        "Job Title",
        "Service Period",
        "Age Group",
        "Department",
        "Gender",
        "Work Station"

     ]

     const field_group = [
        { index:1, isSelected:false, title:'Personal Details', value:'Personal', section:[
            {isSelected:false, value:'title', label:'Title'},
            {isSelected:false, value:'first_name', label:'First Name'},
            {isSelected:false, value:'last_name', label:'Last Name'},
            {isSelected:false, value:'middle_name', label:'Middle Name'},
            {isSelected:false, value:'nickname', label:'Nickname'},
            {isSelected:false, value:'file_number', label:'Employee File Number'},
            {isSelected:false, value:'passport_no', label:'Passport ID'},
            {isSelected:false, value:'nin', label:'NIN'},

            {isSelected:false, value:'birth_cert', label:'Birth Certificate No.'},
            {isSelected:false, value:'driver_license', label:'Driving License No'},
            {isSelected:false, value:'other_id', label:'Other ID'},
            {isSelected:false, value:'gender', label:'Gender'},
            {isSelected:false, value:'is_smoker', label:'Is Smoker'},
            {isSelected:false, value:'tribe', label:'Tribe'},
            {isSelected:false, value:'marital_status', label:'Marital Status'},
            {isSelected:false, value:'religion', label:'Religion'},
            {isSelected:false, value:'date_of_birth', label:'Date of Birth'},
            {isSelected:false, value:'language', label:'Language'},
            {isSelected:false, value:'blood_group', label:'Blood Group'},
            {isSelected:false, value:'place_of_birth', label:'Place of Birth'},
            
        ]},
        { index:2, isSelected:false, title:'Contact Address', value:'Contact',  section:[
            {isSelected:false, value:'country', label:'Country'},
            {isSelected:false, value:'state', label:'State'},
            {isSelected:false, value:'lga', label:'Lga'},
            {isSelected:false, value:'city', label:'City'},
            {isSelected:false, value:'house_no', label:'House No'},
            {isSelected:false, value:'r_country', label:'Resident Country'},
            {isSelected:false, value:'r_state', label:'Resident State'},
            {isSelected:false, value:'r_lga', label:'Resident Lga'},
            {isSelected:false, value:'r_city', label:'Resident City'},
            {isSelected:false, value:'r_house_no', label:'Resident House No'},

            {isSelected:false, value:'home_phone', label:'Home Phone'},
            {isSelected:false, value:'mobile_phone', label:'Mobile phone'},
            {isSelected:false, value:'work_phone', label:'Work Phone'},
            {isSelected:false, value:'other_phone', label:'Other Phone'},
            {isSelected:false, value:'personal_email', label:'Personal Email'},
            {isSelected:false, value:'work_email', label:'Work Email'},
            
            
        ]},
        { index:3, isSelected:false, title:'Emergency Contact', value:'Emergency',  section:[
            {isSelected:false, value:'full_name', label:'Fullname'},
            {isSelected:false, value:'relationship', label:'Relationship'},
            {isSelected:false, value:'mobile', label:'Mobile'},
            {isSelected:false, value:'email', label:'Email Address'},
            {isSelected:false, value:'home_phone', label:'Home Telephone'},
        ]},

        { index:4, isSelected:false, title:'Family', value:'Family',  section:[
            {isSelected:false, value:'full_name', label:'Fullname'},
            {isSelected:false, value:'relationship', label:'Relationship'},
            {isSelected:false, value:'nin', label:'NIN'},
            
        ]},

        { index:5, isSelected:false, title:'Passport, Visa & Licences', value:'Passport' ,  section:[
            {isSelected:false, value:'document_type', label:'Document Type'},
            {isSelected:false, value:'document_number', label:'Document Number'},
            {isSelected:false, value:'issuer', label:'Issuing Authority'},
            {isSelected:false, value:'issued_date', label:'Issued Date'},
            {isSelected:false, value:'expiry_date', label:'Expiry Date'},
            {isSelected:false, value:'renewed_date', label:'Renew Date'},
            {isSelected:false, value:'country', label:'Issuing Country'}
        ]},
        { index:6, isSelected:false, title:'Education History', value:'Education',  section:[
            {isSelected:false, value:'certificate', label:'Certificate'},
            {isSelected:false, value:'institution', label:'Institution'},
            {isSelected:false, value:'grade', label:'Grade'},
            {isSelected:false, value:'year_start', label:'Year Start'},
            {isSelected:false, value:'year_end', label:'Year End'},
            
        ]},
        { index:7, isSelected:false, title:'Work Experience', value:'Work', section:[
            {isSelected:false, value:'company', label:'Company'},
            {isSelected:false, value:'job_title', label:'Job Title'},
            {isSelected:false, value:'start_date', label:'Start Date'},
            {isSelected:false, value:'end_date', label:'End Date'},
            {isSelected:false, value:'responsibility', label:'Responsibilities'},
            
        ]},
        { index:8, isSelected:false, title:'Membership & Awards', value:'Membership' ,  section:[
            {isSelected:false, value:'award', label:'Award Type'},
            {isSelected:false, value:'title', label:'Title'},
            {isSelected:false, value:'year', label:'Year'},
        ]},

        { index:9, isSelected:false, title:'Job Details', value:'Job',  section:[
            {isSelected:false, value:'joined_date', label:'Joined Date'},
            {isSelected:false, value:'confirmation_date', label:'Confirmation Date'},
            {isSelected:false, value:'leaving_date', label:'Date of Leaving'},
            {isSelected:false, value:'department_name', label:'Current Department/Unit'},
            {isSelected:false, value:'employment_status', label:'Employment Status'},
            {isSelected:false, value:'job_title', label:'Job Title'},
            {isSelected:false, value:'work_station', label:'Work Station'}
            
        ]},
        { index:10, isSelected:false, title:'Document & Files', value:'Files',  section:[
            {isSelected:false, value:'Title', label:'Title'},
            {isSelected:false, value:'Description', label:'Description'},
            
        ]},


        {index:11, isSelected:false, title:'Bank Account Details', value:'Bank' ,  section:[
            {isSelected:false, value:'bank_name', label:'Bank'},
            {isSelected:false, value:'account_number', label:'Account Number'},
            {isSelected:false, value:'account_name', label:'Account Name'},
        ]},

        { index:12, isSelected:false, title:'Account Setup', value:'Account',  section:[
            {isSelected:false, value:'passport', label:'Passport'},
            {isSelected:false, value:'signature', label:'Signature'},
            
        ]},

        {index:13, isSelected:false, title:'Terminations', value:'Terminations' ,  section:[
            {isSelected:false, value:'termination_reason', label:'Termination Reason'},
            {isSelected:false, value:'account_status', label:'Termination Note'},
            {isSelected:false, value:'termination_note', label:'Account Status'},
        ]},

     ]

     const [item, setItem] = useState(item_list.sort());
     const [item_group, setItem_group] = useState(field_group.sort((a:any, b:any)=>a.index > b.index?1:-1));
    const [report, setReport] = useState({
        title:'',
        search_paramter:{value:'', label:''},
        includes: { value: 'All', label: 'All Employees'},

        field_group:{value:'', label:'', section:[]},
        display_filed:[],
     })


    
const handleResetReport =()=>{

    setReport({
        title:'',
        search_paramter:{value:'', label:''},
        includes:  { value: 'All', label: 'All Employees'},

        field_group:{value:'', label:'', section:[]},
        display_filed:[],
     })

     setItem(item_list.sort())
     setItem_group(field_group.sort((a:any, b:any)=>a.index > b.index?1:-1))
     setContent([])
     setGroup([])
     setCriteria([])
}

     const handleAdd =()=>{
        if(report.search_paramter.value!==''){
        let newCriteria = criteria.concat({
            code:Math.random().toString(36).substring(2, 9),
            field_name:report.search_paramter.value,
            field_value:'',
            data:''
        })
        setCriteria(newCriteria.sort())

        setReport({...report,
            search_paramter:{value:'', label:''},
        })
        setErrors({...errors, search_paramter:''})
    }else{
        setErrors({...errors, search_paramter:'This field is required'})
    }
    }

    const handleRemove =(code:string, list:string)=>{
        let newCriteria = criteria.filter((item:any)=>item.code!==code)
        let new_item= item.concat(list)
        setItem(new_item.sort())
        setCriteria(newCriteria)
    }



 const handleAddGroup =()=>{
        if(report.field_group.value!==''){
            let newGroup =[]
            if(report.display_filed.length!==0){

            newGroup = group.concat({
            code:Math.random().toString(36).substring(2, 9),
            field_name:report.field_group.label,
            field_value:report.field_group.value,
            data:report.display_filed,
            isHeader:true,
        })
    }else{
        newGroup = group.concat({
            code:Math.random().toString(36).substring(2, 9),
            field_name:report.field_group.label,
            field_value:report.field_group.value,
            data:report.field_group.section,
            isHeader:true,
    })

}

        setGroup(newGroup.sort())

        setReport({...report,
            field_group:{value:'', label:'', section:[]},
            display_filed:[]
        })
 let newItem = item_group.filter((list:any)=>list.value!==report.field_group.value)
        //set field_group to selected
        setItem_group(newItem.sort((a:any, b:any)=>a.index > b.index?1:-1))
        setContent([])
        setErrors({...errors, field_group:''})
    }else{
        setErrors({...errors, field_group:'This field is required'})
    }

    
    }

    const handleRemoveField =(list:any, value:string)=>{

        const newGroup = group.map((item:any)=>{
            if(item.code === list.code){
                return {...item, 
                    data:item.data.filter((dt:any)=>dt.value!==value),
                }
                };
                return item;
          })
    
          setGroup(newGroup)
    }

const handleHeaderChange =(list:any)=>{
    const newGroup = group.map((item:any)=>{
        if(item.code === list.code){
            return {...item, 
                isHeader:!item.isHeader,
            }
            };
            return item;
      })

      setGroup(newGroup)

}


    const handleRemoveGroup =(code:string, value:string)=>{
        let newGroup = group.filter((item:any)=>item.code!==code)
        let ls = field_group.filter((data:any)=>data.value===value)
       let newItem= item_group.concat(ls)
       setItem_group(newItem.sort((a:any, b:any)=>a.index > b.index?1:-1))
        setGroup(newGroup)
    }



    const handleChangeCriteria =(event:ChangeEvent<HTMLInputElement>, field:string)=>{
        let {name, value} = event.target;	

        const newCriteria = criteria.map((item:any)=>{

            if(name === item.code){
                return {...item, field_value:value}
            }
            return item
          })

          setCriteria(newCriteria)
          setErrors({...errors, [field]:''})
       
     }



    const handleSelectCriteria = (option:any, action:any, name:any)=>{

        const newCriteria = criteria.map((item:any)=>{

            if(action.name === item.code){
                return {...item, data:option.value}
            }
            return item
          })

          setCriteria(newCriteria)
         setErrors({...errors, [name]:''})
           }


           const handleSelectIt = (option:any, action:any)=>{
            setReport({...report, [action.name]:option});
            setErrors({...errors, [action.name]:''})
    
               }


            const handleSelectField = (option:any, action:any)=>{
        setReport({...report, [action.name]:option, display_filed:[]});
        setErrors({...errors, [action.name]:''})

      setContent(option.section)
           }


     const handleSelect = (option:any, action:any)=>{
        let new_item = item.filter((list:any)=>list!==option.value)
        setReport({...report, [action.name]:option});
        setErrors({...errors, [action.name]:''})
        setItem(new_item)
           }




           const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
            setReport({...report, [name]:value});
            setErrors({...errors, [name]:''})
         }

       
         const getFieldName =(table:any, field:string, code:string)=>{

            if(Array.isArray(table)){
            const rs = table&&table.filter((item:any)=>item.code===code)
            return rs.length!==0?rs[0][field]:''
            }else{
                return ''
            }
           
         }



         const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
            event.preventDefault();
            let error = {...errors}; 
            let formIsValid = true;
        
        let msg ='This field is required';

        if(!report.title){
            error.title =msg;
            formIsValid = false;
        } 

        if(report.includes.value===''){
            error.includes =msg;
            formIsValid = false;
        } 



        if(group.length===0){
            formIsValid = false;
            error.field_group =msg;
            Alerts('danger', 'Please select at least one field group')
        } 



        let sql =" '' ";

      
        if(report.includes.value==='Current'){
            sql = sql + " and e.termination_reason = ''"
        }else if(report.includes.value==='Past'){
            sql = sql + " and e.termination_reason <> ''"
        }

        let sqlString = criteria.map((list:any)=>{

            if (list.field_name === 'Confirmation Date'){

                if(!list.field_value){
                    error.confirmation_date =msg;
                    formIsValid = false;
                } 

                sql = sql + " and e.confirmation_date = '"+list.field_value+"'"
            }

            if (list.field_name === 'Job Title'){

                if(!list.data){
                    error.job_title =msg;
                    formIsValid = false;
                }

                sql = sql + " and e.job_title = '"+list.data+"'"
            }


            if (list.field_name === 'Department'){

                if(!list.data){
                    error.department =msg;
                    formIsValid = false;
                }
                sql = sql + " and e.department = '"+list.data+"'"
                
            }

            if (list.field_name === 'Gender'){
                if(!list.data){
                    error.gender =msg;
                    formIsValid = false;
                }
                sql = sql + " and e.gender = '"+list.data+"'"   
             }


             if (list.field_name === 'Employment Status'){
                if(!list.data){
                    error.employment_status =msg;
                    formIsValid = false;
                }
                sql = sql + " and e.employment_status = '"+list.data+"'"  
             }



             if (list.field_name === 'Age Group'){


                if(!list.field_value){
                    error.age_group =msg;
                    formIsValid = false;
                }

                if(!list.data){
                    error.age_field =msg;
                    formIsValid = false;
                }

                if(list.data==='Less Than'){
                    sql = sql + " and date_part('year', age(e.date_of_birth))  < '"+list.field_value+"'" 
                    }  
    
                if(list.data==='Greater Than'){
                    sql = sql + " and date_part('year', age(e.date_of_birth)) > '"+list.field_value+"'" 
                } 

                if(list.data==='Equal To'){
                    sql = sql + " and date_part('year', age(e.date_of_birth)) = '"+list.field_value+"'" 
                    }
                       
             }

             

             if (list.field_name === 'Service Period'){


                if(!list.field_value){
                    error.service_field =msg;
                    formIsValid = false;
                }

                if(!list.data){
                    error.service_period =msg;
                    formIsValid = false;
                }
                if(list.data==='Less Than'){
                    sql = sql + " and date_part('year', age(e.joined_date)) < '"+list.field_value+"'" 
                    }  
    
                if(list.data==='Greater Than'){
                    sql = sql + " and date_part('year', age(e.joined_date)) > '"+list.field_value+"'" 
                } 

                if(list.data==='Equal To'){
                    sql = sql + " and date_part('year', age(e.joined_date)) = '"+list.field_value+"'" 
                    }
             }



             if (list.field_name === 'Work Station'){

                if(!list.field_value){
                    error.work_station =msg;
                    formIsValid = false;
                }
                sql = sql + " and e.work_station = '"+list.field_value+"'"   
             }


             if (list.field_name === 'Employee Name'){

                if(!list.field_value){
                    error.employee_name =msg;
                    formIsValid = false;
                }

                sql = sql + " and e.first_name Like  '%"+list.field_value+"%' OR  e.last_name Like  '%"+list.field_value+"%'"
   
             }

        }) 
        

if(!formIsValid){
    setErrors(error) 
    }
    
    if(formIsValid) {

        
  LoadingModal()
        const fd = new FormData();

        fd.append('added_by', STAFF_CODE);
        fd.append('company_code', COMPANY_CODE);
        fd.append('code', 'T'+Math.random().toString(36).substring(2, 9));

        fd.append('title', report.title);
        fd.append('includes', report.includes.value);
        fd.append('criteria_sql', sql);
        fd.append('group', JSON.stringify(group, null, 2));
        fd.append('criteria', JSON.stringify(criteria, null, 2));
        fd.append('item', JSON.stringify(item, null, 2));
        fd.append('item_group', JSON.stringify(item_group, null, 2));
        

let url = ServerUrl+'/api/employee/create_template';
axios.post(url, fd, config)
.then(response =>{
  if(response.data.status === 200){
   SuccessModal(response.data.message)
   Alerts('default', response.data.message)
   handleResetReport()
            } else{
              Alerts('danger',response.data.message)
            }   
        })
        .catch((error)=>{
          Alerts('danger',error.message)
        }).finally(()=>{
      closeModal()
        })   

         }

        }

  return ( <>
   <Breadcrumb 
   title="Template"
   summary="Define field and criterias of employee report here"
   >
    <li className="breadcrumb-item">
    <Link to="#!">Report</Link>
                    </li>
   <li className="breadcrumb-item">
    <Link to="#!">Create Template</Link>
                    </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
<div className="main-body">
<div className="page-wrapper">
<div className="page-body">



<div className="row">
<div className=" col-md-12">
<div className="card z-depth-0">
    <div className="card-header">
        <h5>Create Report Template</h5>
        <span className="text-muted">Description on  <a href="#!" target="_blank">amCharts</a> how and what this page does here.</span>
        <div className="card-header-right">

            <ul className="list-unstyled card-option">
                <li><i className="fa fa fa-wrench open-card-option"></i></li>
                <li><i className="fa fa-window-maximize full-card"></i></li>
                <li><i className="fa fa-minus minimize-card"></i></li>
                <li><i className="fa fa-refresh reload-card"></i></li>
                <li><i className="fa fa-trash close-card"></i></li>
            </ul>
            
        </div>
    </div>
    <div className="card-block ">
       


        <div className="row">
            
    <div className="col-md-12">
    <div className="form-group">
    <label className="col-form-label">Report Title <span className='text-danger'>*</span></label>
    
  
    <input type="text"  className={errors.title?"form-control form-control-danger":"form-control "}  name='title' placeholder='Enter Report Title' value={report.title} onChange={handleChange} />


    {errors.title&&
    <div className="col-form-label has-danger">{errors.title&&errors.title} </div>}
    </div>
    
    </div>
    </div>
    <hr/>
    
    <h6 className='mt-4'>Choose Search Criteria</h6>

    <div className="row">
    <div className="col-md-4">
    <div className="form-group">
    <label className="col-form-label">Select Searching Parameter</label>
    
    
<Select  options={
            item.map((list:any, id:number)=>{
                return {key:id, value:list, label:list}
                    })} 
                    
            className={errors.search_paramter?"form-control form-control-danger":""} 
            onChange={handleSelect}  name="search_paramter"
            value={report.search_paramter} 
            styles={styles} 
                 />
    
    </div>
    
    </div>
    
    <div className="col-md-4">
      
         <button type='button' onClick={handleAdd} className='btn btn-primary btn-round m-t-30'><i className='fa fa-plus'></i></button>
   
    
    </div>



    <div className="col-md-4">
    <div className="form-group">
    <label className="col-form-label">Includes</label>
    
    
  <Select  options={[
                    { value: 'Current', label: 'Current Employee Only' },
                    { value: 'Past', label: 'Past Employee Only'},
                    { value: 'All', label: 'All Employees'}
                                ]} 
                onChange={handleSelect}  
                name="includes"
                className={errors.includes?"form-control form-control-danger":""} 
                 value={report.includes} 
                 styles={styles} 
                 
                 />
    
    </div>
    
    </div></div>







{criteria.length!==0?criteria.map((list:any, index:number)=>
    <div key={index} className="row">
    
    <div className="col-md-3">
    
        <button className='btn btn-danger btn-sm' type='button' onClick={()=>handleRemove(list.code, list.field_name)}><i className='fa fa-trash'></i></button>

    <label className="col-form-label m-l-15 form-control-bold">{list.field_name}</label>
    
    
    
    </div>
    
    {list.field_name === 'Confirmation Date'?
    <div className="col-md-4">
    <div className="form-group">
    
    <input type="date" 
    
  
    
className={errors.confirmation_date?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} 
name={list.code} value={list.field_value} onChange={(e)=>handleChangeCriteria(e, 'confirmation_date')} />
    
    </div>
    
    </div>: 
    list.field_name === 'Job Title'?
    <div className="col-md-4">
    <div className="form-group">
    
    <Select options={
            Jobs.data&&Jobs.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.title}
                    })}   
                    className={errors.job_title?"form-control form-control-danger":""} 
			onChange={(option, action)=>handleSelectCriteria(option, action, 'job_title')} 
            styles={styles} 
            name={list.code} 
            value={{label:getFieldName(Jobs.data, 'title', list.data), value:list.data}} 
              
              />
    
    </div>
    
    </div>

    :
    list.field_name === 'Department'?


    <div className="col-md-4">
    <div className="form-group">
    
    <Select  options={
            department.data&&department.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.department_name}
                    })}   
                    styles={styles} 
                    onChange={(option, action)=>handleSelectCriteria(option, action, 'department')}  
                    className={errors.department?"form-control form-control-danger":""} 
            name={list.code} 
            value={{label:getFieldName(department.data, 'department_name', list.data), value:list.data}}

               />
    
    </div>
    
    </div>

    :

    list.field_name === 'Gender'?

    <div className="col-md-4">
    <div className="form-group">
    
    <Select  options={[
                    { value: 'Male', label: 'Male' },
                    { value: 'Female', label: 'Female'}
                                ]} 
                                className={errors.gender?"form-control form-control-danger":""} 
                                onChange={(option, action)=>handleSelectCriteria(option, action, 'gender')} 
                name={list.code} 
                value={{label:list.data, value:list.data}}
                 styles={styles} 
                 
                 />
    
    </div>
    
    </div>

    :
    list.field_name === 'Employment Status'?


    <div className="col-md-4">
    <div className="form-group">
    
  
<Select  options={[
                    { value: 'Full-Time Contract', label: 'Full-Time Contract' },
                    { value: 'Full-Time Permanent', label: 'Full-Time Permanent'},
                    { value: 'Full-Time Probation', label: 'Full-Time Probation'},

                    { value: 'Part-Time Contract', label: 'Part-Time Contract' },
                    { value: 'Part-Time Internship', label: 'Part-Time Internship'},
                    { value: 'Freelance', label: 'Freelance'},
                    { value: 'Volunteer', label: 'Volunteer'},
                                ]} 
                       
                                className={errors.employment_status?"form-control form-control-danger":""} 
                                onChange={(option, action)=>handleSelectCriteria(option, action, 'employment_status')} 
                                name={list.code} 
                                value={{label:list.data, value:list.data}}
                        styles={styles} 
                 />
    
    </div>
    
    </div>
    :


    list.field_name === 'Age Group'?



<>

<div className="col-md-4">
    <div className="form-group">
    
  
<Select  options={[
                    { value: 'Less Than', label: 'Less Than' },
                    { value: 'Greater Than', label: 'Greater Than'},
                    { value: 'Equal To', label: 'Equal To'},
                                ]} 
                       
                                className={errors.age_group?"form-control form-control-danger":""} 
                                onChange={(option, action)=>handleSelectCriteria(option, action, 'age_group')} 
                                name={list.code} 
                                value={{label:list.data, value:list.data}}
                        styles={styles} 
                 />
    
    </div>
    
    </div>
    
    <div className="col-md-4">
    <div className="form-group">
    
    <input type="number" 
    
 
className={errors.age_field?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} 
    
    name={list.code}
    value={list.field_value} 

    onChange={(e)=>handleChangeCriteria(e, 'age_field')}  />
    
    </div>
    
    </div>

</>

    :


    list.field_name === 'Service Period'?



    <>
    
    <div className="col-md-4">
        <div className="form-group">
        
      
    <Select  options={[
                        { value: 'Less Than', label: 'Less Than' },
                        { value: 'Greater Than', label: 'Greater Than'},
                        { value: 'Equal To', label: 'Equal To'},
                                    ]} 
                           
                                    className={errors.service_period?"form-control form-control-danger":""} 
                                    onChange={(option, action)=>handleSelectCriteria(option, action, 'service_period')} 
                                    name={list.code} 
                                    value={{label:list.data, value:list.data}}
                            styles={styles} 
                     />
        
        </div>
        
        </div>
        
        <div className="col-md-4">
        <div className="form-group">
        
        <input type="number" 
        

className={errors.service_field?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} 


        
        name={list.code}
        value={list.field_value}
        onChange={(e)=>handleChangeCriteria(e, 'service_field')} />
        
        </div>
        
        </div>
    
    </>:

list.field_name === 'Work Station'?


<div className="col-md-4">
<div className="form-group">

<input type="text" 


className={errors.work_station?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} 

name={list.code} 
value={list.field_value}
onChange={(e)=>handleChangeCriteria(e, 'work_station')}
 />

</div>

</div>
:

<div className="col-md-4">
<div className="form-group">

<input type="text" 

className={errors.employee_name?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} 


name={list.code} 
value={list.field_value}

onChange={(e)=>handleChangeCriteria(e, 'employee_name')}
 />

</div>

</div>}



        </div>):[]}
 

    <hr/>





    <h6 className='mt-4'>Display Fields</h6>

    <div className="row">
    <div className="col-md-4">
    <div className="form-group">
    <label className="col-form-label">Field Group</label>
    
    
<Select  options={
            item_group.map((list:any, id:number)=>{
                return {key:id, value:list.value, label:list.title, isSelected:list.isSelected, section:list.section}
                    })} 
                    
            className={errors.field_group?"form-control form-control-danger":""} 
            onChange={handleSelectField}  name="field_group"
            value={report.field_group} 
            styles={selectStyles}  
                 />
    
    </div>
    
    </div>
    

    <div className="col-md-4">
    <div className="form-group">
    <label className="col-form-label">Display Field</label>
    
    
    <Select  options={
            content.map((list:any, id:number)=>{
                return {key:id, value:list.value, label:list.label, isSelected:list.isSelected}
                    })} 
                    isMulti
            onChange={handleSelectIt}  name="display_filed"
            value={report.display_filed} 
            styles={selectStyles} 
                 />
    
    </div>
    
    </div>

    <div className="col-md-4">
      
         <button type='button' onClick={handleAddGroup} className='btn btn-primary btn-round m-t-30'><i className='fa fa-plus'></i></button>
   
    
    </div>
    
    </div>



   
{group.length!==0?group.map((list:any, index:number)=>
    <div key={index}  className="row">
    
    <div className="col-md-3">
    
        <button className='btn btn-danger btn-sm'  onClick={()=>handleRemoveGroup(list.code, list.field_value)} type='button'><i className='fa fa-trash'></i></button>

    <label className="col-form-label m-l-15 form-control-bold">{list.field_name}</label>
    
    
    
    </div>
    

    <div className="col-md-6">

    <div className="main-wrapper">
   
    {list.data.map((data:any, id:number)=><div key={id} className="wrapper"> 
   
   <span className='item'>{data.label}</span>
   <span className="item-icon pointer pl-2 pr-2" onClick={()=>handleRemoveField(list, data.value)}> <i className=' fa fa-times  '></i></span>
   
   
   </div>)}
   </div>

    
    </div>


    
    <div className="col-md-3">
    <div className="form-group">
    
    <label className="switch-md">
                    <input type="checkbox" name={list.code} checked={list.isHeader} onChange={()=>handleHeaderChange(list)}   />
                    <span className="round slider-md"></span>
                  </label>&nbsp;
                  Include header
    
    </div>
    
    </div>

        </div>):[]}



<hr/>
<div className="row">
<div className="col-md-12 ">

<button className="btn btn-outline-info btn-round pr-5 pl-5 mr-3" type='button' onClick={handleResetReport}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={handleSubmit} >Save</button>
</div>
</div>



    </div>
</div>
</div>
</div>


</div>
</div>


</div>
</div>  
        </>               
 
  )
}

export default CreateTemplate