import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo} from 'react'
import { COMPANY_CODE, ImagesUrl, STAFF_CODE, ServerUrl, config } from '../../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal, CustomLoader } from '../../../components/notify';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { getFileSize } from '../../../components/GlobalFunction';

const Documents =()=> {

    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        file:'',
     })


    
    const [ item, setItem ] = useState({
        code:Math.random().toString(36).substring(2, 9),
        company_code:COMPANY_CODE,
        added_by:STAFF_CODE,
        file_name:'',
        description:'',
        size:'',
        type:'',
        file_url:'',
        imageFile:'',
        status:'Active'
    });


    const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	
        setItem({...item, description:value});
       setErrors({...errors, [name]:''});
     }
    


     const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
        event.preventDefault();
    
        let error = {...errors}; 
        let formIsValid = true;
    
    let msg ='This field is required';
    
    if(!item.file_name){
        error.file =msg;
        formIsValid = false;
    } 
    
    
    if(!formIsValid){
    setErrors(error) 
    }
    
    if(formIsValid){
    
        LoadingModal()
       
        const fd = new FormData();
    Object.entries(item).forEach(([key, value]) => {
            fd.append(key, String(value));
        });

        if(item.imageFile){
            fd.append('imageFiles', item.imageFile, item.file_url); 
        } 
    
    let url = ServerUrl+'/api/employees/settings/onboarding/add';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.status === 200){
         SuccessModal(response.data.message)
       
                  } else{
                    ErrorModal('', response.data.message)
                  }   
              })
              .catch((error)=>{
               ErrorModal('oops', error.message)
              }).finally(()=>{
            fetchData()
             handleReset()
    
              }) 
        }
    
    }



    const fetchData = async()=>{
        setLoading(true)
        
        let sql = " "
        const fd = new FormData();
        fd.append('sql', sql); 
        let url = ServerUrl+'/api/employees/settings/onboarding/view/'+COMPANY_CODE
      await  axios.get(url, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){
          setContent(result.data.data)
          }else{
            setContent([])
          }
        })
        .catch((error)=>{
         // ErrorModal('error', error.message)
        }).finally(()=>{
            setLoading(false)
        })
        } 


    const handleReset =()=>{
        setItem({...item,
            code:Math.random().toString(36).substring(2, 9),
            file_name:'',
            description:'',
            size:'',
            type:'',
            file_url:'',
            imageFile:''
        })

        let inputElement = document.getElementById('photo') as HTMLInputElement
        inputElement.value = ''
    }


    const  handleFile = function(fieldName:any){
        return function(newValue:any){                 
       readURL(newValue);
       }
      } 
    
     
    
    const readURL = (input:any)=>{
     
    let displayMessage = '';
    
    const PreviewImage = input.target.name;
    const inputElement = document.getElementById('photo') as HTMLInputElement
    const fileName = inputElement.value
    
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substring(idxDot, fileName.length).toLowerCase();
    
    /* if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){ */
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 5) {
        displayMessage = ' File uploaded is larger than maximum size Allow (5 mb).';
        inputElement.value = '';
        setItem({...item, file_url:'', file_name:''})
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
            var reader = new FileReader();//Initialize FileReader.
            reader.onload = function (e:any) {
                
            //Check if input has files.
            let Id = Math.random().toString(36).substr(2, 9)
            let fileUrl = Id+'_'+input.target.files[0].name

            let f_ext = fileName.substring(fileName.lastIndexOf('\\')+1);

             setItem({...item, 
                type:extFile,
                file_name:f_ext.split(".")[0],
                imageFile:input.target.files[0],
                file_url:fileUrl, 
                size:input.target.files[0].size
                
            })
            
              setErrors({...errors, file:''}); 
            }
            reader.readAsDataURL(input.target.files[0]);
            
        }
    }
    /* }else{
    displayMessage = 'Only JPEG|PNG|JPG  file format are allowed with maximum of 5 mb'
    inputElement.value = '';
    setItem({...item, file_url:'', file_name:''})
    }    */
    
    if(displayMessage.length > 0){
    ErrorModal("", displayMessage)
    }
    }
    

const TableData =()=>{


    const columns = [
        {
            name: 'File Name',
            selector: (row:any) => row.file_name,
            sortable: true,
            wrap:true
        },
        {
            name: 'Description',
            selector: (row:any) => row.description,
           wrap:true
        },
        {
            name: 'Size',
            selector: (row:any) =>getFileSize(row.size),
            sortable: true,
        },
        {
            name: 'Type',
            selector: (row:any) => row.type,
            sortable: true,
        },
        {
            name: 'Status',
            cell: () => <label className="switch-md">
            <input type="checkbox" />
            <span className="round slider-md"></span>
          </label>,
        },
      
       
        {
            name: 'Action',
            cell: (row:any, index:any, column:any, id:any) => <div className='row'> 

            <a href={ImagesUrl+'/employees/onboarding/'+row.file_url} target='_blank' className="label label-primary"  ><i className='ti-download'></i> View</a>

            <label className="label label-danger pointer" onClick={()=>DeleteModal(row)}><i className='ti-trash'></i> Delete</label></div>,
                left:true
                      
        },
    ];
    
  

    return <DataTable
    columns={columns}
    data={content}
    dense
    highlightOnHover
    pagination
    selectableRowsHighlight
    striped
    progressPending={loading}
	progressComponent={<CustomLoader />}
/>

}


const DeleteModal =(row:any)=>{
    let url = ServerUrl+'/api/employees/settings/onboarding/'+row.id+'/'+row.file_url;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =content.filter((item:{id:number})=>item.id !==row.id)
            setContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }
    

    useEffect(()=>{
        fetchData()
      }, [])

  return (
    <div className=''>


    <div className="row">
    <div className="col-sm-12">
    <div className="card z-depth-0">
    <div className="card-header">
    <h5>Documents</h5>
    <span>All documents here will be sent to new employee upon creation of account</span>
    
    </div>
    <div className="card-block">
    
    <div className="form-group row">
            <label className="col-sm-2 col-form-label"></label>
            <div className="col-sm-10">
            <div className="col-form-label "> Accepts jpg, .png, .gif, .pdf, .xls, .doc up to 5 mb.</div>
                <input type="file" className={errors.file?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} id="photo" onChange={handleFile('photo')}  name="photo"  />
                <div className="col-form-label has-danger"> {errors.file&&errors.file}</div>
        
            </div>  
            </div> 


        <div className="form-group row">
        <label className="col-sm-2 col-form-label">Description</label>
        <div className="col-sm-10">
        <input type="text" className={'form-control form-control-lg'}  value={item.description} name="description" onChange={handleChange} />

        </div>  
        
        </div> 


            <hr />
            <div className="row">
        <div className="col-md-12 text-right">
    
        <button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={handleSubmit}>Upload</button>
        </div>
        </div>
    
    </div>
    </div>
    </div>
    </div>
    
    
    
    
    
    
    
    <div className="row">
    <div className="col-sm-12">
    <div className="card z-depth-0">
    <div className="card-header">
    <h5>All Documents</h5>
    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
    
    </div>
    <div className="card-block">
    
    
    
    
    <div className="table-responsive m-t-10">
    <TableData />
    </div>
    
    
    </div>
    </div>
    </div>
    </div>
    
    </div>
  )
}

export default Documents