import React, {useState} from 'react'
import { Link, useLocation } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { ImagesUrl } from './Includes';
const Navbar =(props:any)=> {

    const user = useSelector((state:any) => state.rootReducer.users);
    let location =  useLocation()
const [isDisplay, setIsDisplay] = useState({ } as any);
    const menu  = useSelector((state:any) => state.rootReducer.sidebar);
    const userRole  = useSelector((state:any) => state.rootReducer.roles);
   

    const [isDisplaySubmenu, setIsDisplaySubmenu] = useState({ [props.submenu]: true });


const handleToggleDisplaySubmenu=(main:any, sub:any)=>{
   // handleToggleDisplay(main)
   if(sub!=='0'){
   
    setIsDisplay({[main]: true});
    setIsDisplaySubmenu({[sub]: !isDisplaySubmenu[sub]});
}else{
    setIsDisplay({[main]: !isDisplay[main]})
}
}


let activeHasmenuOpen ='pcoded-hasmenu pcoded-trigger active'



  return (
    <nav className="pcoded-navbar " >
    <div className="sidebar_toggle"><a href="#"><i className="icon-close icons"></i></a></div>
    <div className="pcoded-inner-navbar main-menu "  style={{
        backgroundImage: `url(${process.env.PUBLIC_URL+'/assets/images/navbar-img-4.jpeg'})`
    }}>
        <div className="">
            <div className="main-menu-header">

            <img 
            src={ImagesUrl+'/employees/passport/'+user.passport}  alt={user.first_name} 
            className="img-80 img-radius" 
            onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+user.gender+'.png'}}  /> 

             
                <div className="user-details">
                    <span id="more-details">{user.first_name+ ' '+user.last_name}<i className="fa fa-caret-down"></i></span>
                </div>
            </div>

            <div className="main-menu-content">
                <ul>
                    <li className="more-details">
                        <Link to='/employee/view_profile'><i className="ti-user"></i>View Profile</Link>
                        <a href="#!"><i className="ti-settings"></i>Settings</a>
                        <a href="/logout"><i className="ti-layout-sidebar-left"></i>Logout</a>
                    </li>
                </ul>
            </div>
        </div>
      
        <div className="pcoded-navigation-label">Navigation</div>


        <ul className="pcoded-item pcoded-left-item no-print">

        {menu.length!==0?menu.map((data:any, index:number)=>userRole.includes(data.ID)&&data.isActive==='Yes'?


        <li  key={index}  className={data.submenu.length ===0 && location.pathname===data.menuUrl?'active':
           
        isDisplay[data.ID] && props.menu===data.menuName && data.submenu.length!==0?activeHasmenuOpen:
        props.menu===data.menuName && data.submenu.length!==0?'pcoded-hasmenu active':
        isDisplay[data.ID] && data.submenu.length !==0 ?'pcoded-hasmenu pcoded-trigger active':
    
    data.submenu.length !==0?'pcoded-hasmenu':  ''}
    
    dropdown-icon={data.submenu.length!==0?"style2":''} subitem-icon={data.submenu.length!==0?"style5":''}
    
    >

            
{data.submenu.length!==0?
    <>
<a href="#!" className="waves-effect waves-dark" onClick={()=>handleToggleDisplaySubmenu(data.ID, '0')}  >
<span className="pcoded-micon"><i className={data.menuIcon}></i><b>D</b></span>
<span className="pcoded-mtext">{data.menuName}</span>
<span className="pcoded-mcaret"></span>
</a>
<ul className="pcoded-submenu">


{data.submenu.map((smenu:any, sid:number)=>userRole.includes(smenu.ID)&&smenu.isActive==='Yes'?
          <li key ={sid} 


          dropdown-icon={smenu.subsubmenu.length!==0?"style2":''} subitem-icon={smenu.subsubmenu.length!==0?"style5":''}
    className={smenu.subsubmenu.length ===0 && location.pathname===smenu.subUrl?'active':
    
        isDisplaySubmenu[smenu.ID] && smenu.subsubmenu.length !==0 && props.submenu===smenu.subName?activeHasmenuOpen: 
        
        isDisplay[data.ID] && isDisplaySubmenu[smenu.ID] && smenu.subsubmenu.length !==0 && props.submenu===smenu.subName ?'pcoded-hasmenu pcoded-trigger active': 
        

        isDisplaySubmenu[smenu.ID] && smenu.subsubmenu.length !==0?'pcoded-hasmenu pcoded-trigger active ':
         smenu.subsubmenu.length !==0?'pcoded-hasmenu':
                        ''}  
                        
                        >

{smenu.subsubmenu.length!==0? <>
    <a href="#!" className="waves-effect waves-dark" onClick={()=>handleToggleDisplaySubmenu(data.ID, smenu.ID)}>
                            <span className="pcoded-micon"><i className="icon-pie-chart"></i></span>
                            <span className="pcoded-mtext">{smenu.subName}</span>
                            <span className="pcoded-mcaret"></span>
                        </a>
                        <ul className="pcoded-submenu">

                        {smenu.subsubmenu.map((subsub:any, k:number)=>userRole.includes(subsub.ID)&&subsub.isActive==='Yes'?
                                <li   className={location.pathname===subsub.subsubUrl?'active':'' } key={k}  >
                            <Link to={subsub.subsubUrl}  className="waves-effect waves-dark">
                                    <span className="pcoded-micon"><i className="icon-chart"></i></span>
                                    <span className="pcoded-mtext">{subsub.subsubName} </span>
                                    <span className="pcoded-mcaret"></span>
                                    </Link>
                        
                        {/* close level 3 li */}
                                </li>:'')}
 {/* close level 3 ul */}
 </ul></>:

<Link to={smenu.subUrl} className="waves-effect waves-dark">  
<span className="pcoded-micon"><i className="ti-angle-right"></i></span>
      <span className="pcoded-mtext">{smenu.subName}</span>
      <span className="pcoded-mcaret"></span>

</Link>
               
 
 }

</li>:'')}

</ul></>:

<Link to={data.menuUrl}className="waves-effect waves-dark">
                    <span className="pcoded-micon"><i className={data.menuIcon}></i><b>D</b></span>
                    <span className="pcoded-mtext">{data.menuName}</span>
                    <span className="pcoded-mcaret"></span>
                </Link>

}

</li>:'')

:<div>loading...</div>
}



  </ul>

        <ul className="pcoded-item pcoded-left-item">
        <li className="">
        <a href="https://api.whatsapp.com/send?phone=2348032950881" target="_blank"  className="waves-effect waves-dark">
                    <span className="pcoded-micon"><i className="ti-help-alt"></i><b>D</b></span>
                    <span className="pcoded-mtext">How Do I?</span>
                    <span className="pcoded-mcaret"></span>
                </a>
            </li>

            <li className="">
        <a href={'/logout'} className="waves-effect waves-dark">
                    <span className="pcoded-micon"><i className="ti-layout-sidebar-left"></i><b>D</b></span>
                    <span className="pcoded-mtext">Log Out</span>
                    <span className="pcoded-mcaret"></span>
                </a>
            </li>
            
        </ul>


    </div>
</nav>
  )
}

export default Navbar