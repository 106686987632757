import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo} from 'react'
import { COMPANY_CODE, ServerUrl, config } from '../../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal, CustomLoader } from '../../../components/notify';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'

const Termination =()=>{

    const [ title, setTitle ] = useState('');
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        title:'',
        editTitle:'',
       
     })
    

    const [ item, setItem ] = useState({
        title:'',
        id:'',
        code:''
    });

    const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	
        setTitle(value);
       setErrors({...errors, [name]:''});
     }



const handleReset =()=>{
    setTitle('')
}


const TableData =()=>{


    const columns = [
        {
            name: 'Reason',
            selector: (row:any) => row.reason,
            sortable: true,
        },
       
        {
            name: 'Action',
            cell: (row:any, index:any, column:any, id:any) => <div className='row'>  
                <label className="label label-info pointer" onClick={()=>EditItem(row)} ><i className='ti-pencil'></i> Edit</label>
            <label className="label label-danger pointer" onClick={()=>DeleteModal(row.id)}><i className='ti-trash'></i> Delete</label></div>,
                left:true
                      

        },
    ];
    
  

    return <DataTable
    columns={columns}
    data={content}
    dense
    highlightOnHover
    pagination
    selectableRowsHighlight
    striped
    progressPending={loading}
	progressComponent={<CustomLoader />}
/>

}



const handleChangeEdit =(event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	
    setItem({...item, title:value})
   setErrors({...errors, [name]:''});
 }

const EditItem =(data:any)=>{
    setItem({
        id:data.id,
        code:data.code,
        title:data.reason
    });
    let click = document.getElementById('openEdit')?.click()

}

  const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!title){
    error.title =msg;
    formIsValid = false;
} 


if(!formIsValid){
setErrors(error) 
}

if(formIsValid) {

    LoadingModal()
   
    const fd = new FormData();
    let code = Math.random().toString(36).substring(2, 9)
    fd.append('company_code', COMPANY_CODE);
    fd.append('code', code);
    fd.append('reason', title); 

let url = ServerUrl+'/api/employees/settings/termination/add';
  axios.post(url, fd, config)
  .then(response =>{

    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                ErrorModal('', response.data.message)
              }   
          })
          .catch((error)=>{
           ErrorModal('oops', error.message)
          }).finally(()=>{
        fetchData()
         handleReset()

          })
    }

}



const handleUpdate =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!item.title){
    error.editTitle =msg;
    formIsValid = false;
} 


if(!formIsValid){
setErrors(error) 
}


if(formIsValid){

     LoadingModal()
   
    const fd = new FormData();
    fd.append('reason', item.title);
    fd.append('code', item.code);
    fd.append('id', item.id);
let url = ServerUrl+'/api/employees/settings/termination/update';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                ErrorModal('', response.data.message)
              }   
          })
          .catch((error)=>{
           ErrorModal('oops', error.message)
          }).finally(()=>{
         let click = document.getElementById('closeModal')?.click()
         fetchData()
          }) 
    }

}

const DeleteModal =(id:number)=>{
    let url = ServerUrl+'/api/employees/settings/termination/'+id;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =content.filter((item:{id:number})=>item.id !==id)
            setContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }

const fetchData = async()=>{
    setLoading(true)
    
    let sql = " "
    const fd = new FormData();
    fd.append('sql', sql); 
    let url = ServerUrl+'/api/employees/settings/termination/view/'+COMPANY_CODE
  await  axios.get(url, config).then((result:any)=>{
    if(Array.isArray(result.data.data) && result.data.data.length!==0){
      setContent(result.data.data)
      }else{
        setContent([])
      }
    })
    .catch((error)=>{
     // ErrorModal('error', error.message)
    }).finally(()=>{
        setLoading(false)
    })
    } 


    useEffect(()=>{
        fetchData()
      }, [])





  return (
    <div className=''>


<label data-toggle="modal" data-target="#editItem" id='openEdit' ></label>

<div className="modal fade " id="editItem" tabIndex={-1} role="dialog" data-backdrop="static" >
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header bg-dark">
                    <h4 className="modal-title text-white">Update Reason</h4>
                  
    
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i className='fa fa-ban text-danger'></i>
            </button>
    
                </div>
                <div className="modal-body">
                
     <div className="form-group row">
            <label className="col-sm-2 col-form-label">Termination Reason</label>
            <div className="col-sm-10">
                <input type="text" className={errors.editTitle?"form-control form-control-danger":"form-control"}  value={item.title} name="editTitle" onChange={handleChangeEdit} />

            <div className="col-form-label has-danger">{errors.editTitle&&errors.editTitle} </div>
            </div>  
            
            </div> 
                </div>
                <div className="modal-footer">
                    <button type="button" id='closeModal' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-outline-success " onClick={handleUpdate} >Update</button>
                </div>
            </div>
        </div>
    </div>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
            <h5>Termination Reasons</h5>
<span>Reason for terminating an appointment</span>
              
            </div>
            <div className="card-block">
    
            <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Termination Reason</label>
                        <div className="col-sm-10">
                        <input type="text" className={errors.title?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  value={title} name="title" onChange={handleChange} />

                    <div className="col-form-label has-danger">{errors.title&&errors.title} </div>
                        </div>  
                        
                        </div> 
                        <hr />
                        <div className="row">
                    <div className="col-md-12 text-right">
    
                    <button className="btn btn-success btn-round pr-5 pl-5" onClick={handleSubmit}>Save</button>
                    </div>
                    </div>
    
            </div>
        </div>
    </div>
    </div>
    
    
    

    
    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>All Termination Reasons</h5>
                <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
              
            </div>
            <div className="card-block">
    
        <div className="table-responsive m-t-10">
        <TableData />
        </div>
    
            </div>
        </div>
    </div>
    </div>
    
                </div>
  )
}

export default Termination 