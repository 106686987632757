import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo, useRef} from 'react'
import { COMPANY_CODE, CURRENCY, STAFF_CODE, ServerUrl, config, configDownload, selectStyles, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts, CustomLoader } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'

import { DownloadDocument, ViewDocument } from './staffSalaryPDF';

import { PDFDownloadLink} from '@react-pdf/renderer';
import { usePaginated, DOTS } from '../../components/usePagination';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { FormatNumber, getMonthYear } from '../../components/GlobalFunction';
import { Link } from 'react-router-dom';
import { takeScreenshot } from '../../api/ReactQuery';

const  StaffSalary =()=> {
   
    const [director, setDirector] = useState({
       
        isName:false,
        isMonth:false,
        isPayDate:false,
        isEarning:false,
        isDeduction:false,

        isNetPay:false,
        isApproved:false,
        
        isAction:false,
    });
    const [search, setSearch] = useState({
        employee:[] as any,
        payment_date:'',
        payment_month:'',
        payment_status:{value:'All', label:'All'},
        rowperpage:{value:'10', label:'10'},
        filterText:'',
        selectAll:false,
        totalSelected:0,
     })


    const [filterContent, setFilterContent] = useState([] as any);
    const [staff, setStaff] = useState([] as any);
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
         staff:'',
         pay_mode:'',
         pay_date:'',
         pay_month:''
       
     })
    
     const handleResetColumn =()=>{
        setDirector({
           
            isName:false,
            isMonth:false,
            isPayDate:false,
            isEarning:false,
            isDeduction:false,
    
            isNetPay:false,
            isApproved:false,
            
            isAction:false,
        })
    }
     const fetchStaff =async()=>{
        let url = ServerUrl+'/api/employees/view/'+COMPANY_CODE
        const {data} = await axios.get(url, config)
        setStaff(data.data)
        }
      
   
     const handleSelect = (option:any, action:any)=>{
        setSearch({...search, [action.name]:option});
          setErrors({...errors, [action.name]:''})

           }


           const handleChange = (event:ChangeEvent<HTMLInputElement>)=>{
           
            let {name, value} = event.target;	
            setSearch({...search, [name]:value});
           setErrors({...errors, [name]:''});
        }

        
const handleReset =()=>{
    setSearch({
        employee:[] as any,
        payment_date:'',
        payment_month:'',
        payment_status:{value:'All', label:'All'},
        rowperpage:{value:'10', label:'10'},
        filterText:'',
        selectAll:false,
        totalSelected:0,
    })
}




const DeleteModal =(row:any)=>{
    let url = ServerUrl+'/api/payroll/salary_payment/delete/'+row.staff_code+'/'+row.payment_month;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =content.filter((item:{payment_month:string, staff_code:string})=>item.payment_month !==row.payment_month && item.staff_code !== row.staff_code)
            setFilterContent(rs)
            setContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }


  const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	
    setSearch({...search, [name]:value});

    if(value!==''){
        const filteredItems = content.filter(
            (item:any) => item.first_name.toLowerCase().includes(value.toLowerCase()) || item.last_name.toLowerCase().includes(value.toLowerCase()) ||
            item.payment_month.toLowerCase().includes(value.toLowerCase()),
        );

        setFilterContent(filteredItems)
    }else{
        setFilterContent(content)
    } 

 }


const handleClear =()=>{
setSearch({...search, filterText:''});
setFilterContent(content)
}




        const TableData =()=>{

            

            const PaginationWrapper =(props:any)=>{
            
                const handleRowsPerPage = useCallback(
                    (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
                    [props.currentPage, props.onChangeRowsPerPage],
                );
                const handleSelectRowsPerPage = useCallback((option:any, action:any)=> props.onChangeRowsPerPage(Number(option.value), props.currentPage),
                [props.currentPage, props.onChangeRowsPerPage],
                )
               
                function getNumberOfPages(rowCount:number, rowsPerPage:number) {
                    return Math.ceil(rowCount / rowsPerPage);
                  }
                const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
                const lastIndex = props.currentPage * props.rowsPerPage;
                const firstIndex = lastIndex - props.rowsPerPage + 1;
            
            
                    const range =
                    props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
            
                    `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
            
                
               
                const Pagination = () => {
                   
                    const pageSize = props.rowsPerPage;
                    const totalCount = content.length
                    const siblingCount = 1
                    const currentPage = props.currentPage
                    
                    const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
                    
                    if (currentPage === 0 ) {
                      return null;
                    }
                    
                    let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
                    return (
                    <nav className="" aria-label="Page navigation sample ">
                      <ul  className="pagination pagination-sm"  >
                        <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
                         <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Previous</a>
                        </li>
                        {paginationRange&&paginationRange.map((pageNumber, index) => {
                          if (pageNumber === DOTS) {
                            return <li className="pagination-item dots" key={index}>
                             <a className="page-link pointer"  >&#8230;</a>
                            </li>
                          }
                          return (
                            <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                              <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                              
                            </li>
                          );
                        })}
                    
                    <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
                    <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
                        </li>
                    
                      </ul> </nav>
                    );
                    
                        }
            
                      
            
                      
            
             return <div className="row mt-3">
                                
            <div className="col-md-5 ">
            <div className="row ml-3" >
            
               Rows per page: &nbsp; <select name="rowPerPage" id="" className='w-40 h-30 ' onChange={handleRowsPerPage}>
                    <option value="10"> 10</option>
                    <option value="20"> 20</option>
                    <option value="30"> 30</option>
                    <option value="40"> 40</option>
                    <option value="50"> 50</option>
                    <option value={content.length}> All</option>
                </select> &nbsp;&nbsp;
                {/*  {range} */} </div>
            </div>
              
            <div className="col-md-7  justify-content-end d-flex">
               <Pagination  />
                </div>
                
            </div>}
            
            
            
            
            
            
                        const columns = [
            
                          
                         
                            {
                                name: 'Staff',
                                selector: (row:any) =>row.staff_name,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isName,
                            },
                            {
                                name: 'Payment Month',
                                selector: (row:any) => getMonthYear(row.payment_month),
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isMonth,
                                
                            },
                            {
                                name: 'Date Paid',
                                selector: (row:any) => row.payment_date,
                                sortable: true,
                                reorder:true,
                                omit: director.isPayDate,
                                wrap:true,
                            },
                            {
                                name: `Earning (${CURRENCY})`,
                                selector: (row:any) => FormatNumber(row.earning),
                                sortable: true,
                                reorder:true,
                                omit: director.isEarning,
                            },
                            {
                                name: `Deduction (${CURRENCY})`,
                                selector: (row:any) => FormatNumber(row.deduction),
                                sortable: true,
                                reorder:true,
                                omit: director.isDeduction,
                            },
                            {
                                name: `Net Pay (${CURRENCY})`,
                                cell: (row:any) =>FormatNumber(row.net_pay),
                                sortable: true,
                                reorder:true,
                                omit: director.isNetPay,
                            },
                            {
                                name: 'Action',
                                cell: (row:any, index:any, column:any, id:any) => <div className='row'>  

                                   <label className="label label-primary pointer" ><i className='fa fa-print'></i> Print</label>

                                <label className="label label-danger pointer" onClick={()=>DeleteModal(row)}><i className='ti-trash'></i> Delete</label></div>,
                                    left:true,
                                    omit: director.isAction,
                                    width:'150px'
                            },
                        ];
                        
                    
                    
                    
                        return <DataTable
                        columns={columns}
                        data={filterContent}
                        highlightOnHover
                        pagination
                        paginationComponent={PaginationWrapper}
                        selectableRowsHighlight
                        striped
                        responsive
                        fixedHeader
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                    />
                    }
            


                    const sqlQuery = ()=>{

                        let sql = "SELECT p.staff_code, p.is_approved, CONCAT(e.first_name, ' ', e.last_name)  as staff_name, p.payment_month, p.payment_date, COALESCE(sum(case when p.item_type ='DEDUCTION' then p.amount_topay else 0 end), 0) as deduction,  COALESCE(sum(case when p.item_type ='EARNING' then p.amount_topay else 0 end),0) as earning, (COALESCE(sum(case when p.item_type ='EARNING' then p.amount_topay else 0 end),0)- COALESCE(sum(case when p.item_type ='DEDUCTION' then p.amount_topay else 0 end), 0)) as net_pay FROM tbl_salary_payment p, tbl_employee e  where p.company_code = '"+COMPANY_CODE+"' and p.staff_code = e.code  "
     
                 if(search.employee.length!==0){
                    sql = sql + " and p.staff_code = '"+search.employee.value+"'"
                }

                if(search.payment_date!==''){
                    sql = sql + " and p.payment_date = '"+search.payment_date+"'"
                }
                if(search.payment_month!==''){
     
                    sql = sql + " and p.payment_month = '"+search.payment_month+"'"
                   
                }


                if(search.payment_status.value!=='All'){
                    sql = sql + " and p.is_approved = '"+search.payment_status.value+"'"
                }


                sql = sql + " GROUP BY p.payment_month, p.payment_date, p.staff_code, e.first_name, e.last_name, p.is_approved "


                return sql;
                    }

         const fetchContent = async()=>{
            setLoading(true)
     
                 
            let sql = sqlQuery()
     
                 const fd = new FormData();
                 fd.append('sql', sql); 
                 let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
               await  axios.post(url, fd, config).then((result:any)=>{

                 if(Array.isArray(result.data.data) && result.data.data.length!==0){
                    setFilterContent(result.data.data)
                   setContent(result.data.data)
                   }else{

                    setContent([])
                    setFilterContent([])
                   }
                 })
                 .catch((error)=>{
                     Alerts('danger',error.message)
                 }).finally(()=>{
                     setLoading(false)
                 })
                 } 



                 const handleDownload = async(extension:string)=>{
             
                    let sql = sqlQuery()
                const fd = new FormData();
                fd.append('sql', sql); 
                fd.append('extension', extension); 
                let url = ServerUrl+'/api/download_file/payroll'
                await  axios.post(url, fd, configDownload).then((response:any)=>{
                 const url = window.URL.createObjectURL(new Blob([response.data]));
                   
                     const link = document.createElement('a');
                     link.href = url;
                     link.download = '_staff_salary.'+extension
                     link.click();
                     })
                }


    useEffect(()=>{
        
        fetchContent()
        fetchStaff() 
      }, [])


    

  return ( <>
 <Breadcrumb 
   title="Staff Salary"
   summary="Employee Payment History "
   >
    <li className="breadcrumb-item">
    <Link to="#">Payroll</Link>
                    </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Payment History</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                              
                                    
                                    
         <div className="content-wrappers ">
                            
         <div className=''>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Employee Salary</h5>
                <span>Add all offcie department</span>
              
            </div>
            <div className="card-block">
    
        
                        <div className="row">
                  
                        <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Search By Employee </label>


                 
                <Select 
                options={
                               staff&&staff.map((list:any, idx:number)=> {
                                return {key:idx, value: list.code, label: list.first_name, last_name:list.last_name, middle_name:list.middle_name, title:list.title }
                                })}
                                 
                     getOptionLabel={option =>`${option.title} ${option.label} ${option.last_name}`}
    onChange={handleSelect}  name="employee" value={search.employee} 
    styles={styles} 
    /> 
                    </div>
    
                     </div>

                     <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Search Payment Date</label>
           
            <input type="date"   className="form-control form-control-lg"  name='payment_date' value={search.payment_date} onChange={handleChange}  />


          
             </div>

             </div>


                     <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Search By Payment Month </label>
           
            <input type="month"  className="form-control form-control-lg"   name='payment_month' value={search.payment_month} onChange={handleChange}  />
          

             </div>

             </div>


             <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Search by  Status</label>
    
    <Select  options={[
                    { value: 'Yes', label: 'Approved' },
                    { value: 'No', label: 'Pending'},
                    { value: 'All', label: 'All'}
                                ]} 
                onChange={handleSelect}  
                name="payment_status"
                 value={search.payment_status} 
                 styles={styles} 
                 
                 />
    
    </div>
    
    </div>

   </div>

          
<hr />
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-outline-danger btn-round pr-5 pl-5 mr-3" type='button' onClick={handleReset}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={fetchContent}>Search</button>
</div>
</div>
    
            </div>
        </div>
    </div>
    </div>
    
    
    

    
                </div>

        </div>
                       
                    </section>
                </div>
            </div>
        </div>
    </div>
                                    </div>
                                </div>
                            </div>






                            <div className="modal fade " id="viewpdf" tabIndex={-1} role="dialog" data-backdrop="static" >
       <div className="modal-dialog modal-xl" role="document">
           <div className="modal-content">
               <div className="modal-header bg-dark">
                   <h4 className="modal-title text-white">Print Preview</h4>
                 

           <button type="button" className="close" data-dismiss="modal" aria-label="Close">
           <i className='fa fa-ban text-danger'></i>
           </button>

               </div>
               <div className="modal-body">
               <div className="row">

               <ViewDocument data={filterContent} />


</div>
               </div>
               <div className="modal-footer">
                   <button type="button" className="btn btn-default waves-effect " data-dismiss="modal" id="closeEdit">Close</button>
               </div>
           </div>
       </div>
   </div>


                            <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Payment History</h5>
                <span>List of all salary payments</span>
              
                <div className="card-header-right">

                <div className="dropdown-info dropdown">
        <button type="button" className="btn btn-success active dropdown-toggle dropdown-icon btn-sm " data-toggle="dropdown"><i className='fas fa-eye text-white'></i> Export</button>
                    <div className="dropdown-menu" aria-labelledby="dropdown-4" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut" x-placement="bottom-start" >

                    <PDFDownloadLink document={<DownloadDocument data={filterContent} />} fileName="Employee_Leave_Application.pdf"
                    className="dropdown-item waves-light waves-effect" 
                    >
                  {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <span  ><i className="fas fa-file-pdf "></i> Export as PDF</span>)}
                </PDFDownloadLink>

                        <a className="dropdown-item waves-light waves-effect" href="#!" onClick={()=>handleDownload('xlsx')}><i className="fas fa-file-excel "></i> Export as Excel</a>

                        <a className="dropdown-item waves-light waves-effect" href="#!" onClick={()=>takeScreenshot('takeshot')}><i className="fas fa-image "></i> Export as Image</a>
                        <a className="dropdown-item waves-light waves-effect" href="#!" data-toggle="modal" data-target="#viewpdf"><i className="fas fa-print "></i> Print Preview</a>
                    </div>
                </div>&nbsp;

        <a href='/payroll/modify_salary_payment' className="btn btn-primary btn-sm "><i className='fa fa-circle-plus text-white'></i> Add New</a>
        </div> 
            </div>
            <div className="card-block">
    




            <div className="row">

<div className=" col-md-8 ">


    <button type="button" className="btn btn-info btn-sm "  ><i className="fas fa-check"></i> Approved All ({filterContent.length})</button>&nbsp;
  <div className="btn-group ">
                   
                    <button type="button" className="btn btn-inverse dropdown-toggle dropdown-icon  btn-sm" data-toggle="dropdown">
                      <span className="sr-only">Toggle Dropdown</span> <i className='fas fa-fw fa-filter'></i> Visibility &nbsp;
                    </button>
                    <div className="dropdown-menu" role="menu">

                      <a className={!director.isName?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isName:!director.isName})}>Staff</a>

                      <a className={!director.isMonth?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isMonth:!director.isMonth})}>Payment Month</a>

                      <a className={!director.isPayDate?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isPayDate:!director.isPayDate})}>Payment Date</a>


                      <a className={!director.isEarning?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isEarning:!director.isEarning})}>Earning</a>

                      <a className={!director.isDeduction?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isDeduction:!director.isDeduction})}>Deduction</a>

                      <a className={!director.isNetPay?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isNetPay:!director.isNetPay})}>Net Pay</a>



                      <a className={!director.isAction?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isAction:!director.isAction})}>Action</a>

                    <div className="dropdown-divider"></div>
                      <a className={"dropdown-item"} href="#!" onClick={handleResetColumn}>Reset Column</a>
                   
                    </div>
                  </div>
                  </div>

    <div className=" col-md-4 ">
    <div className="input-group  " >
                    <input type="text" name="filterText" id="filterText" key="filterText"  onChange={handleSearch} value={search.filterText} className="form-control float-right form-control-sm" placeholder="Type to search" />
                   
                    <div className="input-group-append">
                        <button type="button" onClick={handleClear} className="btn btn-default btn-sm" >
                          <i className="fas fa-close"></i>
                        </button>
                      </div>
                  </div>
      

    </div>
</div>

    <div className="row" id='takeshot'>
        <div className="table-responsive ">
        <TableData />
        </div>
        </div>

            </div>
        </div>
    </div>
    </div>


                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default StaffSalary