import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, STAFF_CODE, ServerUrl, config, configDownload, styles } from '../../components/Includes';
import axios from 'axios';
import { Alerts, CustomLoader, LoadingModal, SuccessModal, closeModal } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'
import { useQuery } from 'react-query'
import { usePaginated, DOTS } from '../../components/usePagination';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { getBritishDate } from '../../components/GlobalFunction';
import { fetchDepartment, fetchJobs, takeScreenshot } from '../../api/ReactQuery';
import { Link } from 'react-router-dom';

const  Clients =()=> {
   
    const [director, setDirector] = useState({
       
        isName:false,
        isEmail:false,
        isPerson:false,
        isTelephone:false,
        isAddress:false,
        isProject:true,
        isAction:false,
        
    });
    const [search, setSearch] = useState({
        
        rowperpage:{value:'10', label:'10'},
        filterText:'',
        selectAll:false,
        totalSelected:0,
     })


    const [filterContent, setFilterContent] = useState([] as any);
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
         
         client_name:'',
         email_address:''
       
     })

     const [profile, setProfile] = useState({
      client_name:'',
      email_address:'',
      contact_person:'',
      office_address:'',
      telephone:'',
      isEdit:false,
      code:Math.random().toString(36).substring(2, 9)
  });


    
     const handleResetColumn =()=>{
        setDirector({
          isName:false,
          isEmail:false,
          isPerson:false,
          isTelephone:false,
          isAddress:false,
          isProject:true,
          isAction:false,
        })
    }
     

           const handleChangeArea = (event:ChangeEvent<HTMLTextAreaElement>)=>{
            let {name, value} = event.target;	
            setProfile({...profile, [name]:value});
           setErrors({...errors, [name]:''});
        }



        const handleChangeProfile = (event:ChangeEvent<HTMLInputElement>)=>{
           
          let {name, value} = event.target;	
          setProfile({...profile, [name]:value});
         setErrors({...errors, [name]:''});
      }

        


const handleReset =()=>{
    setProfile({
        client_name:'',
      email_address:'',
      contact_person:'',
      office_address:'',
      telephone:'',
      isEdit:false,
      code:Math.random().toString(36).substring(2, 9)
    }) 

    let click = document.getElementById('openAdd')?.click()
}





  const handleSelectAll =(list:[])=>{
      
    let is_check = list.every((element:any) => {
     return element.is_check === 'true'
    });
    var i = 0;
        list.forEach((element:any) => {
          if(element.is_check === 'true')
         i++
        });
    setSearch({...search, selectAll:is_check, totalSelected:i})
    
       }


const handleCheck =(code:string)=>{
const newContent = filterContent.map((item:any)=>{
   
if(item.code === code){
return {...item, 
    is_check:item.is_check==='false'?'true':'false',
}
};
return item;
})
setFilterContent(newContent)
handleSelectAll(newContent)
}


const handleSelectChange =()=>{
    let status = search.selectAll ===false?'true':'false';
    const newContent = filterContent.map((item:any)=>{
        return {...item, 
            is_check:status,
        }
  })

  var i = 0;
  newContent.forEach((element:any) => {
      if(element.is_check=== 'true')
     i++
    });

    setFilterContent(newContent)

    setSearch({...search, selectAll:!search.selectAll, totalSelected:i})

   }



  const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	
    setSearch({...search, [name]:value});

    if(value!==''){
        const filteredItems = content.filter(
            (item:any) => item.staff_name.toLowerCase().includes(value.toLowerCase()) || item.leave_name.toLowerCase().includes(value.toLowerCase()) ||
            item.reason.toLowerCase().includes(value.toLowerCase()),
        );

        setFilterContent(filteredItems)
    }else{
        setFilterContent(content)
    } 

 }


const handleClear =()=>{
setSearch({...search, filterText:''});
setFilterContent(content)
}




const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
  event.preventDefault();

  let error = {...errors}; 
  let formIsValid = true;

let msg ='This field is required';


if(!profile.client_name){
error.client_name =msg;
formIsValid = false;
} 


if(profile.email_address !==''){

let email = profile.email_address.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
if(!email){
  error.email_address ='Enter valid email address';
  formIsValid = false;
}
  } 

if(!formIsValid){
setErrors(error) 
}
if(formIsValid) {

  LoadingModal()
 

  const fd = new FormData();


  Object.entries(profile).forEach(([key, value]) => {
    fd.append(key, String(value));
});


let click = document.getElementById('close')?.click();
  fd.append('company_code', COMPANY_CODE);
  fd.append('added_by', STAFF_CODE);


  let url = ''
    if(profile.isEdit){

      
    url = ServerUrl+'/api/project/client/update';
    }else{
      url = ServerUrl+'/api/project/client/create';
    }


axios.post(url, fd, config)
.then(response =>{
  if(response.data.status === 200){
   SuccessModal(response.data.message)
 
            } else{
              Alerts('danger', response.data.message)
              closeModal()
            }   
        })
        .catch((error)=>{
          Alerts('danger', error.message)
          closeModal()
        }).finally(()=>{
          setProfile({
            client_name:'',
            email_address:'',
            contact_person:'',
            office_address:'',
            telephone:'',
            isEdit:false,
            code:Math.random().toString(36).substring(2, 9)
        });

        })
  }

}




const EditItem =(data:any)=>{
   setProfile({
      code:data.code,
      client_name:data.client_name,
      email_address:data.email_address,
      contact_person:data.contact_person,
      office_address:data.office_address,
      telephone:data.telephone,
      isEdit:true,
  });
  setErrors({
      client_name:'',
      email_address:''
  })
  
  let click = document.getElementById('openAdd')?.click()
}



        const TableData =()=>{

            

            const PaginationWrapper =(props:any)=>{
            
                const handleRowsPerPage = useCallback(
                    (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
                    [props.currentPage, props.onChangeRowsPerPage],
                );
                const handleSelectRowsPerPage = useCallback((option:any, action:any)=> props.onChangeRowsPerPage(Number(option.value), props.currentPage),
                [props.currentPage, props.onChangeRowsPerPage],
                )
               
                function getNumberOfPages(rowCount:number, rowsPerPage:number) {
                    return Math.ceil(rowCount / rowsPerPage);
                  }
                const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
                const lastIndex = props.currentPage * props.rowsPerPage;
                const firstIndex = lastIndex - props.rowsPerPage + 1;
            
            
                    const range =
                    props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
            
                    `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
            
                
               
                const Pagination = () => {
                   
                    const pageSize = props.rowsPerPage;
                    const totalCount = content.length
                    const siblingCount = 1
                    const currentPage = props.currentPage
                    
                    const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
                    
                    if (currentPage === 0 ) {
                      return null;
                    }
                    
                    let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
                    return (
                    <nav className="" aria-label="Page navigation sample ">
                      <ul  className="pagination pagination-sm"  >
                        <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
                         <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Previous</a>
                        </li>
                        {paginationRange&&paginationRange.map((pageNumber, index) => {
                          if (pageNumber === DOTS) {
                            return <li className="pagination-item dots" key={index}>
                             <a className="page-link pointer"  >&#8230;</a>
                            </li>
                          }
                          return (
                            <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                              <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                              
                            </li>
                          );
                        })}
                    
                    <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
                    <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
                        </li>
                    
                      </ul> </nav>
                    );
                    
                        }
            
                      
            
                      
            
             return <div className="row mt-3">
                                
            <div className="col-md-5 ">
            <div className="row ml-3" >
            
               Rows per page: &nbsp; <select name="rowPerPage" id="" className='w-40 h-30 ' onChange={handleRowsPerPage}>
                    <option value="10"> 10</option>
                    <option value="20"> 20</option>
                    <option value="30"> 30</option>
                    <option value="40"> 40</option>
                    <option value="50"> 50</option>
                    <option value={content.length}> All</option>
                </select> &nbsp;&nbsp;
                {/*  {range} */} </div>
            </div>
              
            <div className="col-md-7  justify-content-end d-flex">
               <Pagination  />
                </div>
                
            </div>}
            
            
            
const CheckboxAll =()=>{ 

    return <div className="chk-option">
    <div className="checkbox-fade fade-in-primary">
        <label className="check-task">
    
        <input type="checkbox"  id="selectAll" checked={search.selectAll} onChange={handleSelectChange} />
                    
        <span className="cr">
            <i className="cr-icon fa fa-check txt-default"></i>
        </span>
        </label>
    </div>
    </div>}


const Checkbox =({code, is_check}:{code:string, is_check:string})=>{ 

return <div className="chk-option">
<div className="checkbox-fade fade-in-primary">
    <label className="check-task">

    <input type="checkbox"  name={code} id={code} checked={is_check==='true'?true:false} onChange={()=>handleCheck(code)} />
                
    <span className="cr">
        <i className="cr-icon fa fa-check txt-default"></i>
    </span>
    </label>
</div>
</div>}
            
            
            
                        const columns = [
            
                          
                            {
                                name: <CheckboxAll />,
                                cell: (row:any) => <Checkbox code={row.code} is_check={row.is_check} />, 
                                width:'60px'
                            },
                            {
                                name: 'Client Name',
                                selector: (row:any) =>row.client_name,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isName,
                            },
                            {
                                name: 'Email Address',
                                selector: (row:any) =>row.email_address,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isEmail,
                            },
                            {
                              name: 'Contact Person',
                              selector: (row:any) =>row.contact_person,
                              sortable: true,
                              reorder:true,
                              wrap:true,
                              omit: director.isPerson,
                          },
                          {
                            name: 'Telephone',
                            selector: (row:any) =>row.telephone,
                            sortable: true,
                            reorder:true,
                            wrap:true,
                            omit: director.isTelephone,
                        },
                        {
                          name: 'Address',
                          selector: (row:any) =>row.address,
                          sortable: true,
                          reorder:true,
                          wrap:true,
                          omit: director.isAddress,
                      },
                      {
                        name: 'Project',
                        selector: (row:any) =>row.project,
                        sortable: true,
                        reorder:true,
                        wrap:true,
                        omit: director.isProject,
                    },
                           
                      {
                        name: 'Action',
                        cell: (row:any, index:any, column:any, id:any) => <div className='row'>  
                            <label className="label label-info pointer"  onClick={()=>EditItem(row)} ><i className='ti-pencil'></i> Edit</label></div>,
                            left:true
                                  
            
                    },
                        ];
                        
                    
                    
                    
                        return <DataTable
                        columns={columns}
                        data={filterContent}
                        highlightOnHover
                        pagination
                        paginationComponent={PaginationWrapper}
                        selectableRowsHighlight
                        striped
                        responsive
                        fixedHeader
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                    />
                    }
            
                    const sqlQuery = ()=>{
                      let sql = "SELECT 'false' as is_check, c.id, c.code, c.client_name, c.email_address, c.contact_person, c.office_address, c.telephone FROM tbl_clients c where c.company_code = '"+COMPANY_CODE+"'"
                      return sql;
                    }
         const fetchContent = async()=>{
            setLoading(true)
     
                
            let sql = sqlQuery()
                 
     
                 const fd = new FormData();
                 fd.append('sql', sql); 

                 let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE

               await  axios.post(url, fd, config).then((result:any)=>{

                 if(Array.isArray(result.data.data) && result.data.data.length!==0){
                    setFilterContent(result.data.data)
                   setContent(result.data.data)
                   }else{

                    setContent([])
                    setFilterContent([])
                   }
                 })
                 .catch((error)=>{
                     Alerts('danger',error.message)
                 }).finally(()=>{
                     setLoading(false)
                 })
                 } 





                 const handleDeleteAll =()=>{

                    if(search.totalSelected<1){
                      Alerts('danger','Please select at least one record to delete ')
                    }else{
                  
                      let employeeList =[] as any;
                      let selectedEmployee = filterContent.filter((item:any)=>item.is_check ==='true')
                  
                      if(Array.isArray(selectedEmployee)){
                        selectedEmployee.forEach((list:any) => {
                            employeeList.push(list.code)
                        });
                      }
                  
                      const fd = new FormData();
                      fd.append('clientList', JSON.stringify(employeeList, null, 2));
                      let url = ServerUrl+'/api/lproject/client/delete_multiple/'+COMPANY_CODE;
                      Swal.fire({
                        title: 'Are you really sure?',
                        text: "This is very dangerous, you shouldn't do it!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        showLoaderOnConfirm: true,
                      preConfirm: async() => {
                        return await axios.post(url, fd, config).then(response=>{
                         if(response.data.status!==200){
                              throw new Error(response.statusText)
                            }
                            return response.data
                          })
                          .catch(error => {
                            Swal.showValidationMessage(
                              `Request failed: ${error}`
                            )
                          })
                      },
                      allowOutsideClick: () => !Swal.isLoading(),
                        confirmButtonText: 'Yes, I am!'
                      }).then((result:any) => {
                        if (result.isConfirmed && result.value.status===200) {
                  
                          let rs =filterContent.filter((item:any)=>item.is_check ==='false')
                         setContent(rs)
                         setFilterContent(rs)
                           Swal.fire(
                                      'Deleted!',
                                      'Record has been deleted.',
                                      'success'
                                    ) 
                        }
                      })
                  
                    }
                  
                   }

                   const handleDownload = async(extension:string)=>{
                    let sql = sqlQuery()
                    const fd = new FormData();
                    fd.append('sql', sql); 
                    fd.append('extension', extension); 
                    let url = ServerUrl+'/api/download_file/clients'
                    await  axios.post(url, fd, configDownload).then((response:any)=>{
                     const url = window.URL.createObjectURL(new Blob([response.data]));
                       
                         const link = document.createElement('a');
                         link.href = url;
                         link.download = '_clients.'+extension
                         link.click();
                         })
                    }

    useEffect(()=>{
       fetchContent()
      }, [])


    

  return ( <>
<Breadcrumb 
   title="Clients"
   summary="Company Clients"
   >
    <li className="breadcrumb-item">
    <Link to="/project/reports">Project</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Clients</a> </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">



<div className="modal fade " id="large-Modal" tabIndex={-1} role="dialog" data-backdrop="static" >
       <div className="modal-dialog modal-lg" role="document">
           <div className="modal-content">
               <div className="modal-header bg-dark">
                   <h4 className="modal-title text-white">Client Form</h4>
                 

           <button type="button" className="close" data-dismiss="modal" aria-label="Close">
           <i className='fa fa-ban text-danger'></i>
           </button>

               </div>
               <div className="modal-body">
               <div className="row">
<div className="col-md-6">
<div className="form-group">
          <label className="col-form-label">Client Name <span className='text-danger'>*</span></label>
         
              
    <input type="text" className={errors.client_name?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='client_name' value={profile.client_name} onChange={handleChangeProfile} />
    
    {errors.client_name&&
    <div className="col-form-label has-danger">{errors.client_name&&errors.client_name} </div>}
        
           </div>

</div>





<div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Email Address</label>
    
    
    <input type="text" className={errors.email_address?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='email_address' value={profile.email_address} onChange={handleChangeProfile} />
    
    
    {errors.email_address&&
    <div className="col-form-label has-danger">{errors.email_address&&errors.email_address} </div>}
    </div>
    
    </div>


    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Contact Person </label>
    
    
    <input type="text" className="form-control form-control-lg"  name='contact_person' value={profile.contact_person} onChange={handleChangeProfile} />
    
    
    </div>
    
    </div>

    <div className="col-md-6">
<div className="form-group">
<label className="col-form-label">Telephone</label>

<input type="text" className="form-control form-control-lg"  name='telephone' value={profile.telephone} onChange={handleChangeProfile} />

</div>

</div>

    <div className="col-md-12">
             <div className="form-group">
            <label className="col-form-label">Office Address </label>
           
            <textarea className="form-control form-control-lg"  name='office_address' onChange={handleChangeArea} value={profile.office_address}  >{profile.office_address}</textarea>
          

             </div>

             </div>



</div>
               </div>
               <div className="modal-footer">
                   <button type="button" className="btn btn-default waves-effect " data-dismiss="modal" id="close">Close</button>
                   <button type="button" className="btn btn-primary waves-effect waves-light " onClick={handleSubmit}>{profile.isEdit?'Update': 'Save'}</button>
               </div>
           </div>
       </div>
   </div>









                            <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Client List</h5>
                <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
              
                <div className="card-header-right">

                <div className="dropdown-info dropdown">
        <button type="button" className="btn btn-success active dropdown-toggle dropdown-icon btn-sm " data-toggle="dropdown"><i className='fas fa-eye text-white'></i> Export</button>
                    <div className="dropdown-menu" aria-labelledby="dropdown-4" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut" x-placement="bottom-start" >

                        <a className="dropdown-item waves-light waves-effect" onClick={()=>handleDownload('pdf')} href="#!"><i className="fas fa-file-pdf "></i> Export as PDF</a>

                        <a className="dropdown-item waves-light waves-effect" href="#!" onClick={()=>handleDownload('xlsx')}><i className="fas fa-file-excel "></i> Export as Excel</a>

                        <a className="dropdown-item waves-light waves-effect" href="#!" onClick={()=>takeScreenshot('takeshot')}><i className="fas fa-image "></i> Export as Image</a>
                        <a className="dropdown-item waves-light waves-effect" href="#!"><i className="fas fa-print "></i> Print Preview</a>
                    </div>
                </div>&nbsp;
        <a href='#' className="btn btn-primary btn-sm "  onClick={handleReset}><i className='fa fa-circle-plus text-white'></i> Add New Client</a>

<label  id='openAdd' data-toggle="modal" data-target="#large-Modal"> </label>  

        </div> 
            </div>
            <div className="card-block">
    




            <div className="row">

<div className=" col-md-8 ">


                  &nbsp;

                  <a href='#!' className="btn btn-primary btn-sm "><i className='fa fa-print text-white'></i></a>&nbsp;


  <button type="button" className="btn btn-outline-danger btn-sm " onClick={handleDeleteAll} ><i className="fas fa-trash"></i> Delete Selected ({search.totalSelected})</button>&nbsp;

  <div className="btn-group ">
                   
                    <button type="button" className="btn btn-inverse dropdown-toggle dropdown-icon  btn-sm" data-toggle="dropdown">
                      <span className="sr-only">Toggle Dropdown</span> <i className='fas fa-fw fa-filter'></i> Visibility &nbsp;
                    </button>
                    <div className="dropdown-menu" role="menu">

                      <a className={!director.isName?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isName:!director.isName})}>Client Name</a>

                      <a className={!director.isEmail?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isEmail:!director.isEmail})}>Email Address</a>

                      <a className={!director.isPerson?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isPerson:!director.isPerson})}>Contact Person</a>


                      <a className={!director.isTelephone?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isTelephone:!director.isTelephone})}>Telephone</a>

                      <a className={!director.isAddress?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isAddress:!director.isAddress})}>Address</a>

                      <a className={!director.isProject?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isProject:!director.isProject})}>Project</a>


                      <a className={!director.isAction?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isAction:!director.isAction})}>Action</a>

                    <div className="dropdown-divider"></div>
                      <a className={"dropdown-item"} href="#!" onClick={handleResetColumn}>Reset Column</a>
                   
                    </div>
                  </div>
                  </div>

    <div className=" col-md-4 ">
    <div className="input-group  " >
                    <input type="text" name="filterText" id="filterText" key="filterText"  onChange={handleSearch} value={search.filterText} className="form-control float-right form-control-sm" placeholder="Type to search" />
                   
                    <div className="input-group-append">
                        <button type="button" onClick={handleClear} className="btn btn-default btn-sm" >
                          <i className="fas fa-close"></i>
                        </button>
                      </div>
                  </div>
      

    </div>
</div>

    <div className="row">
        <div className="table-responsive ">
        <TableData />
        </div>
        </div>

            </div>
        </div>
    </div>
    </div>


                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default Clients