import { useEffect, useState} from 'react'
import { COMPANY_CODE, ImagesUrl, ServerUrl, config, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, closeModal, Alerts } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'
import { useQuery } from 'react-query'

import Swal from 'sweetalert2'
import { fetchDepartment, fetchJobs } from '../../api/ReactQuery';
import { Link } from 'react-router-dom';

const Directory =()=> {
    const department = useQuery(["department"], fetchDepartment)
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState([] as any);
    const Jobs = useQuery(["jobs"], fetchJobs)


    const [search, setSearch] = useState({
       
        job_title:{value:'', label:''},
        department:{value:'', label:''},
        employment_status:{value:'', label:''},
     })

     const handleSelect = (option:any, action:any)=>{
        setSearch({...search, [action.name]:option});

           }

const handleClear =()=>{
    setSearch({
       
        job_title:{value:'', label:''},
        department:{value:'', label:''},
        employment_status:{value:'', label:''},
     })
}




const fetchContent = async()=>{
   LoadingModal()

         let sql = " SELECT 'false' as is_check, e.id, e.mobile_phone, e.work_email, e.update_log, e.code, e.passport, e.company_code, e.title, e.first_name, e.gender, e.last_name, e.middle_name, e.file_number,  e.employment_status, e.account_status, j.title as job_title, d.department_name from tbl_employee e left join tbl_job_title j on j.code = e.job_title  left JOIN tbl_departments d on d.code = e.department WHERE e.id <> '' "



             if(search.employment_status.value!==''){
                 sql = sql + " and e.employment_status = '"+search.employment_status.value+"'"
             }

             if(search.department.value!==''){
                 sql = sql + " and e.department = '"+search.department.value+"'"
             }

             if(search.job_title.value!==''){
                 sql = sql + " and e.job_title = '"+search.job_title.value+"'"
             }
             

         const fd = new FormData();
         fd.append('sql', sql); 
         let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
       await  axios.post(url, fd, config).then((result:any)=>{
         if(Array.isArray(result.data.data) && result.data.data.length!==0){
             
           setContent(result.data.data)
           }
         })
         .catch((error)=>{
             Alerts('danger',error.message)
         }).finally(()=>{
             closeModal()
         })
         } 



const DeleteModal =(row:any)=>{

    let url = ServerUrl+'/api/employee/delete/'+row.id+'/'+row.code;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =content.filter((item:{id:number})=>item.id !==row.id)
            setContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }

useEffect(()=>{
 fetchContent()
}, [])

  return ( <>
   <Breadcrumb 
   title="Directory"
   summary="You can use the criteria below to filter record."
   >
    <li className="breadcrumb-item">
    <Link to="/employees/view">Employees</Link>
                    </li>
                    
   <li className="breadcrumb-item">
    <Link to="#!">Directory</Link>
                    </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
<div className="main-body">
<div className="page-wrapper">
<div className="page-body">


<div className="row">
<div className=" col-md-12">
<div className="card z-depth-0">
    <div className="card-header">
        <h5>Search Employees</h5>
        <div className="card-header-right">

            <ul className="list-unstyled card-option">
                <li><i className="fa fa fa-wrench open-card-option"></i></li>
                <li><i className="fa fa-window-maximize full-card"></i></li>
                <li><i className="fa fa-minus minimize-card"></i></li>
                <li><i className="fa fa-refresh reload-card"></i></li>
                <li><i className="fa fa-trash close-card"></i></li>
            </ul>
            
        </div>
    </div>
    <div className="card-block ">
       


        <div className="row">
            
    <div className="col-md-4">
    <div className="form-group">
    <label className="col-form-label">Search by Employment Status</label>
    
    
<Select  options={[
                    { value: 'Full-Time Contract', label: 'Full-Time Contract' },
                    { value: 'Full-Time Permanent', label: 'Full-Time Permanent'},
                    { value: 'Full-Time Probation', label: 'Full-Time Probation'},

                    { value: 'Part-Time Contract', label: 'Part-Time Contract' },
                    { value: 'Part-Time Internship', label: 'Part-Time Internship'},
                    { value: 'Freelance', label: 'Freelance'},
                    { value: 'Volunteer', label: 'Volunteer'},
                                ]} 
                       
                              
                                onChange={handleSelect}  name="employment_status"
                        value={search.employment_status} 
                        styles={styles} 
                 />
    
    </div>
    
    </div>
    

    <div className="col-md-4">
    <div className="form-group">
    <label className="col-form-label">Search by Department</label>
    
    
    <Select  options={
            department.data&&department.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.department_name}
                    })}   
                    styles={styles} 
			onChange={handleSelect}    
            name="department" 
            value={search.department} 
               />
    
    </div>
    
    </div>


    
    <div className="col-md-4">
   <div className="form-group">
    <label className="col-form-label">Search by Job Title</label>
    
    
    <Select options={
            Jobs.data&&Jobs.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.title}
                    })}   
                  
			onChange={handleSelect} 
            styles={styles} 
            name="job_title" value={search.job_title} 
              
              />
    
    </div>
    
    </div>

        </div>


<hr/>
<div className="row">
<div className="col-md-12 ">

<button className="btn btn-outline-info btn-round pr-5 pl-5 mr-3" type='button' onClick={handleClear}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={fetchContent}>Search</button>
</div>
</div>





    </div>
</div>
</div>
</div>








<div className="row">
<div className=" col-md-12">
<div className="card z-depth-0">
   

    <div className="card-header  justify-content-between d-flex">
        <h5>Employee Directory</h5>
<div className="card-heare">

        <a href='/employees/add' className="btn btn-primary  "><i className='fa fa-circle-plus text-white'></i> Add New</a>
        </div>   
    </div>


    <div className="card-block" >




    <div className="row users-card"  >


      {content.length!==0?content.map((list:any, index:number)=>  <div key={index} className="col-md-4">
            <div className="card z-depth-1">
                <div className="card-block">
                    <div className="user-content">
                    <img src={ImagesUrl+'/employees/passport/'+list.passport} alt="user image" 
                    className="img-radius img-100 img-fluid staff-image" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+list.gender+'.png'}} />
                        <h4 className="">{ list.first_name + ' ' + list.last_name }</h4>
                        <p className="m-b-0 text-bold">{list.department_name}</p>
                        <p className="m-b-0 text-muted">{list.job_title}</p>
                    </div>
                    <div>
                </div>
                </div>
            </div>
        </div>):[]}

    </div>



    </div>
</div>
</div>
</div>



</div>
</div>


</div>
</div>  
      </>               
 
  )
}

export default Directory