
import { useEffect, useState} from 'react'
import { COMPANY_CODE, ServerUrl, config } from '../../components/Includes';
import axios from 'axios';
import {Chart,ArcElement, registerables } from 'chart.js';
import {Bar, Pie} from 'react-chartjs-2'
import { convertDate, getMonthYear } from '../../components/GlobalFunction';

import Breadcrumb from '../../components/Breadcrumb';
import { Link } from 'react-router-dom';

const FinanceDashboard =()=> {
  
    let thisMonth = getMonthYear(new Date().toISOString().slice(0,10));
let month = new Date().toISOString().slice(0,7)
    Chart.register(ArcElement, ...registerables)

    var today = new Date().toISOString().slice(0,10);
    var date = new Date()
    var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1, 23, 59, 59)
    var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)

    let prevMonth = date.setMonth(date.getMonth() - 1)
    let formatPrevMonth = new Date(prevMonth).toISOString().slice(0,7);


    var firstDayOfYear = new Date(date.getFullYear(), 0, 1, 23, 59, 59)
    var lastDayOfYear = new Date(date.getFullYear()+1, 0, 0, 23, 59, 59)

    const [search, setSearch] = useState({
      date_range:  {label:'2023-01-01 - 2023-12-31', value:'2023-01-01 - 2023-12-31', start_date:'2023-01-01', end_date:'2023-12-31'},
   })

      const [appraisalList, setAppraisal] = useState([] as any);
      const [yearList, setYearList] = useState([] as any);
    const [event, setEvent] = useState(0);
    const [review, setReview] = useState([])
    const [gender, setGender] = useState([])
    const [statistics, setStatistics] = useState({
       


        staff:0,
        pending:0,
        prevAppraisal:0,
        prevPending:0,
        thisAppraisal:0,
        thisPending:0,
    });



    const fetchEvent =async()=>{
      var sql ="SELECT title, IFNULL(count(id),0) as data FROM tbl_holidays WHERE event_type = 'Event' and start_date >=  '"+convertDate(firstDayOfMonth)+"' and end_date  <= '"+convertDate(lastDayOfMonth)+"' group by title " ;

      const fd = new FormData();
      fd.append('sql', sql); 
      
      let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
    await  axios.post(url, fd, config).then((result:any)=>{

      if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
        setEvent(result.data.data[0].data)
        }else{
          setEvent(0)
        }
      })
  }

    const fetchGender =async()=>{

      var sql = "SELECT e.gender as label, sum(a.percentage) as data   FROM tbl_employee e, tbl_appraisal a where a.staff_code = e.code and a.appraisal_month = '"+formatPrevMonth+"' group by e.gender "


        const fd = new FormData();
        fd.append('sql', sql); 
        let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
      await  axios.post(url, fd, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
          setGender(result.data.data)

          }else{
            setGender([])
          }
        })
    }



    const fetchStatistics=async()=>{
       
        var sql =" SELECT (SELECT count(*) from tbl_employee where termination_reason = '') as staff, (SELECT count(*) from tbl_appraisal where appraisal_month = '"+month+"' ) as thisAppraisal, (SELECT count(*) from tbl_appraisal where appraisal_month = '"+formatPrevMonth+"' ) as prevAppraisal"

       
        const fd = new FormData();
        fd.append('sql', sql); 
        let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
   await axios.post(url, fd, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
          setStatistics({
            ...statistics,
           staff:result.data.data[0].staff,
            thisAppraisal:result.data.data[0].thisAppraisal,
            prevAppraisal:result.data.data[0].prevAppraisal,
            thisPending:result.data.data[0].staff - result.data.data[0].thisAppraisal,
            prevPending:result.data.data[0].staff - result.data.data[0].prevAppraisal,
          })
        
          }
        })
             }




    const fetchReview=()=>{
       
      var sql = "SELECT concat(e.first_name, ' ', e.last_name) as staff_name, sum(case when r.spot_type ='Blue Spot' then r.alloted_point else 0 end ) as blue, sum(case when r.spot_type ='Red Spot' then r.alloted_point else 0 end ) as red FROM tbl_employee e, tbl_employee_review r where r.staff_code = e.code group by staff_name "
       

        const fd = new FormData();
        fd.append('sql', sql); 
        let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
    axios.post(url, fd, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
         
          setReview(result.data.data)
          }else{
            setReview([])
          }
        })
             }

             const reviewTypeList = {
              labels: review.map((data:any)=>data.staff_name),
              datasets: [
                {
                  label: '# of Blue Spot',
                  data: review.map((data:any)=>data.blue),
                  backgroundColor: 'blue',
                },
                
                {
                  label: '# of Red Spot',
                  data: review.map((data:any)=>data.red),
                 
                  backgroundColor: 'rgb(255, 99, 132)',
                },
              ],
            }
            const optionStark = {
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            };


             const fetchAppraisal=()=>{
       


              var sql = "SELECT concat(e.first_name, ' ', e.last_name) as staff_name, percentage as data   FROM tbl_employee e, tbl_appraisal a where a.staff_code = e.code and a.appraisal_month = '"+formatPrevMonth+"' "

              const fd = new FormData();
              fd.append('sql', sql); 
              let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
          axios.post(url, fd, config).then((result:any)=>{
              if(Array.isArray(result.data.data) && result.data.data.length!==0){ 

                setAppraisal(result.data.data)

                }else{
                  setAppraisal([])
                }
              })
                   }






                   const appraisalListStark = {
                    labels: appraisalList.map((data:any)=>data.staff_name),
                    datasets: [
                      {
                        label: 'Percentage',
                        backgroundColor: [ '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22', '#99CC00', '#3C3C3D','#00000', '26164d', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70'],
                        hoverBackgroundColor: [ '#99CC00', '#3C3C3D','#00000', '26164d', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#adc71b', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000'],
                        data: appraisalList.map((data:any)=>data.data), //[65, 59, 80, 81, 56]
                      }
                    ]
                  };



             const fetchYearly=()=>{
       
              var sql = "SELECT current_year as label, sum(case when is_approved = 'Pending'   then 1 else 0 end) AS 'Pending', sum(case when is_approved = 'Approved'  then 1 else 0 end) AS 'Approved', sum(case when is_approved = 'Reject'  then 1 else 0 end) AS 'Reject' FROM tbl_leave_application  group by current_year"
              
              const fd = new FormData();
              fd.append('sql', sql); 
              let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
          axios.post(url, fd, config).then((result:any)=>{
              if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
                setYearList(result.data.data)
                }
              })
                   }




    const genderList= {
        labels: gender.map((data:any)=>data.label),
        datasets: [
          {
            label: 'Total',
            backgroundColor: [ '#99CC00', '#3C3C3D','#00000', '26164d', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70',],
            hoverBackgroundColor: [ '#3a8300', '#CACACA', '#3C3C3D','#00000', '26164d', '#501800',],
            data: gender.map((data:any)=>data.data), //[65, 59, 80, 81, 56]
          }
        ]
      }


     




     

    

      useEffect(()=>{
        fetchEvent()
        fetchReview()
        fetchGender()
        fetchAppraisal()
        fetchStatistics()
        fetchYearly()
    }, [])

  return ( <>
   <Breadcrumb 
   title="Finance Dashboard"
   summary="Statistical display of Organization Finances"
   >
     <li className="breadcrumb-item">
    <Link to="/assets/display_assets">Assets</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Dashboard</a> </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
                                <div className="main-body">
                                    <div className="page-wrapper">
                                        <div className="page-body">




                                        <div className="row">



                                        <div className="col-md-3">
                                                <div className="card">

                                               
                                                    <div className="card-block">
                                                        <div className="row align-items-center">
                                                            <div className="col-12 text-center">
                                                                <h4 className="text-c-purple">{statistics.thisAppraisal}</h4>
                                                                <h6 className="text-muted m-b-0">{getMonthYear(month)}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer bg-c-purple">
                                                        <div className="row align-items-center">
                                                            <div className="col-9">
                                                                <p className="text-white m-b-0">Employee Appraised</p>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="col-md-3">
                                                <div className="card">

                                               
                                                    <div className="card-block">
                                                        <div className="row align-items-center">
                                                            <div className="col-12 text-center">
                                                                <h4 className="text-c-red">{statistics.thisPending}</h4>
                                                                <h6 className="text-muted m-b-0">{getMonthYear(month)}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer bg-c-red">
                                                        <div className="row align-items-center">
                                                            <div className="col-9">
                                                                <p className="text-white m-b-0">Employee Pending</p>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="card">

                                              
                                                    <div className="card-block">
                                                        <div className="row align-items-center">
                                                            <div className="col-12 text-center">
                                                                <h4 className="text-c-green">{statistics.prevAppraisal}</h4>
                                                                <h6 className="text-muted m-b-0">{getMonthYear(formatPrevMonth)}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer bg-c-green">
                                                        <div className="row align-items-center">
                                                            <div className="col-9">
                                                                <p className="text-white m-b-0">Employee Appraised</p>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="col-md-3">
                                                <div className="card">

                                              
                                                    <div className="card-block">
                                                        <div className="row align-items-center">
                                                            <div className="col-12 text-center">
                                                                <h4 className="text-c-red">{statistics.prevPending}</h4>
                                                                <h6 className="text-muted m-b-0">{getMonthYear(formatPrevMonth)}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer bg-c-red">
                                                        <div className="row align-items-center">
                                                            <div className="col-9">
                                                                <p className="text-white m-b-0">Employee Pending</p>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>


                                       
                                    

</div>

<div className="row ">




<div className="col-md-12 ">
                <div className="card ">
                    <div className="card-header">
                        <h5>Employee Appraisal ({getMonthYear(formatPrevMonth)}) </h5>
                    </div>
                    <div className="card-block">

                   
<Bar
          data={appraisalListStark}
        /> 

                    </div>
                </div>
            </div>



</div>

<div className="row">



  


<div className="col-md-6 ">
<div className="card ">
<div className="card-header">
    <h5>Gender Performance ({getMonthYear(formatPrevMonth)})</h5>
</div>
<div className="card-block">
<Pie data={genderList} 


/>


</div>
</div>
</div>

<div className="col-md-6 ">
<div className="card feed-card">
<div className="card-header">
    <h5>Employee Review</h5>
</div>
<div className="card-block">

   
<Bar
          data={reviewTypeList}
          options={optionStark}
        />  


</div>
</div>
</div>

</div>





                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
                            </>               
 
  )
}

export default FinanceDashboard