
import { useState, ChangeEvent, FormEvent, useEffect} from 'react'
import { ImagesUrl, ServerUrl, Token } from '../../components/Includes';
import axios from 'axios';

import Cookies from 'js-cookie'
import { encrypt } from '../../components/authentication';
import { Alerts } from '../../components/notify';
const Login =()=> {


	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	
	const [user, setUser] = useState({
		email:'',
		password:'',
		remember:'No',
		date:new Date().toISOString().slice(0,10),
		dateTime:new Date().toISOString().slice(0,19)
    })
    const [errors, setErrors] = useState({
        email:'',
        password:''

    });

    const handleChange = (event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	
        if(name==='remember'){
            value= value==='Yes'?'No':'Yes'
        }	
       setUser({...user, [name] : value });
       setErrors({...errors, [name]:''});
    }
    
	const config = {
        headers: { 
            "Access-Control-Allow-Origin": "*",
            'content-type': 'multipart/form-data'
    }
    } 
			
    const handleSubmit =(event: FormEvent<HTMLFormElement>)=>{
        event.preventDefault();
    
        let error = {...errors}; 
        let formIsValid = true;
    
    let msg ='This field is required';




if(!user.email){
    error.email =msg;
    formIsValid = false;
} 
if(!user.password){
    error.password =msg;
    formIsValid = false;
} 

    if(!navigator.onLine){
        Alerts('danger', 'No internet connection')
        formIsValid = false;
    } 


if(!formIsValid){
setErrors(error) 
}

if(formIsValid) {

		setNotice({...notice, isLoading: true}) 	
           
        const fd = new FormData();
        fd.append('email', user.email);
        fd.append('password', user.password);

           let url = ServerUrl+'/api/employee/login_staff';
		axios.post(url, fd, config)
		.then(response =>{
            if(response.data.status === 200){
			
			//1000 * 60 * SESSION_IDEL_MINUTES
			var inSixtyMinutes:any = Date.now() + 419 * 60 * 1000;
			var inOneMinutes:any = Date.now() + 418 * 60 * 1000;
			  var inOneHours = new Date(new Date().getTime() + 419 * 60 * 1000);
			  
			   localStorage.setItem('_expt', inSixtyMinutes)
				localStorage.setItem('_bexpt', inOneMinutes) 


				
			Cookies.set('hrucd', encrypt(response.data.code), {expires: inOneHours })
			Cookies.set('hrgpcd', encrypt(response.data.group_code), {expires: inOneHours })
			Cookies.set('hrtk', response.data.token, {expires: inOneHours })   
			
			Alerts('default', 'Welcome '+response.data.name, 'Successful')
			
			var queryString = new URLSearchParams(window.location.search).get("refferer")
                if(queryString!==null){
                    window.open(queryString, '_self')
                }else{
                    window.open('/dashboard', '_self')
                }   
			
			}else{
				Alerts('danger', response.data.message, 'Error')
			}    
		})
		.catch((error)=>{
			Alerts('danger', error.message, 'Error')
		}).finally(()=>{
            setNotice({...notice, 
				isLoading: false
			}) 
        })   
    }
 }


 useEffect(()=>{  
    
	 if(Token!==undefined){
		window.open('/dashboard', '_self')
       
		 }  
},[]); 



  return (<>

  
    <section className="login-block">
    <div className="container">
        <div className="row">
            <div className="col-sm-12">
                
                    <form className="md-float-material form-materials" onSubmit={handleSubmit} role={'presentation'} autoComplete='off' >
                        <div className="text-center">
                            <img src={ImagesUrl+'/greencode.jpeg'} alt="logo.png" className='img-100' />
                        </div>
                        <div className="auth-box card">
                            <div className="card-block">
                                <div className="row m-b-20">
                                    <div className="col-md-12">
                                        <h3 className="text-center">Sign In</h3>
                                    </div>
                                </div>
                                <div className="form-group form-primary">

                                <label className="float-label">Your Email Address</label>

                                <input type="email" id="email" name="email" required autoComplete='off' role={'presentation'} value={user.email} onChange={handleChange} className={errors.email  ? 'form-control formerror form-control-lg' : 'form-control form-control-lg'} /> 


                                {errors.email&&
                        <div className="col-form-label has-danger">{errors.email&&errors.email} </div>}
    
                                </div>
                                <div className="form-group form-primary">

                                <label className="float-label">Password</label>


                <input type="password" id="password" name="password" required autoComplete='off' role={'presentation'} value={user.password} onChange={handleChange} className={errors.password  ? 'form-control formerror form-control-lg' : 'form-control form-control-lg'} /> 


                                {errors.password&&
                        <div className="col-form-label has-danger">{errors.password&&errors.password} </div>}

                                </div>
                                <div className="row m-t-25 text-left">
                                    <div className="col-12">

                                <div className="checkbox-fade fade-in-primary ">
                                    <label className="check-task">
    <input type="checkbox" value={user.remember} name="remember" checked={user.remember ==='Yes'?true:false}  onChange={handleChange} />
    <span className="cr">
    <i className="cr-icon fa fa-check txt-default"></i>
    </span> Remember me 
</label>
                                </div>


                                        <div className="forgot-phone text-right f-right">
                                            <a href="#!" className="text-right f-w-600"> Forgot Password?</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-t-30">
                                    <div className="col-md-12">


                                    {notice.isLoading ?	<button type="button" disabled className="btn btn-primary btn-md btn-block waves-effect waves-light text-center m-b-20"><i className='fa fa-refresh fa-spin'></i> Please wait ... </button>:
				<button type="submit"  className="btn btn-primary btn-md btn-block waves-effect waves-light text-center m-b-20"><i className='fa fa-arrow-right '></i> Sign in</button>}

                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-10">
                                        <p className="text-inverse text-left m-b-0">Thank you.</p>
        <p className="text-inverse text-left"><a href="https://greencodeng.org/"><b>Back to website</b></a></p>
                                    </div>
                                    <div className="col-md-2">
                                        <img src={ImagesUrl+'/logo.png'} alt="logo" className='img-30' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
            </div>
        </div>
    </div>
</section>

<div className="footer">
        <p className="text-center m-b-0">Copyright © 2023 HURIS -GREENCODE, All rights reserved.</p>
    </div>
</>
  )
}

export default Login