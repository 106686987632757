import React from 'react'
//import '../../error_style.css'

const Error404 =()=> {
  return (
    <>
    <div className="image"></div>
    <div className="content">

            <div className="content-box">

                <div className="big-content">

                    <div className="list-square">
                        <span className="square"></span>
                        <span className="square"></span>
                        <span className="square"></span>
                    </div>

                    <div className="list-line">
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                    </div>

                    <i className="fa fa-search" aria-hidden="true"></i>

                    <div className="clear"></div>

                </div>

                <h1>Oops! Error 404 not found.</h1>

                <p>The page you were looking for doesn't exist.<br/>
                    We think the page may have moved.</p>

                    <div className="text-center m-t-10">
                    <a href="/dashboard" className="btn btn-primary">Back to home</a>
                    </div>
            </div>

        </div></>
  )
}

export default Error404