import { useState, ChangeEvent, FormEvent, useEffect} from 'react'
import { COMPANY_CODE, STAFF_CODE, ServerUrl, config, styles, group_styles, selectStyles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, Alerts, closeModal } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'
import { useQuery } from 'react-query'

import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

import { useParams } from 'react-router'
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import "react-datepicker/dist/react-datepicker.css";
import { fetchDepartment, fetchSettings } from '../../api/ReactQuery';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertDate } from '../../components/GlobalFunction';
import { Link } from 'react-router-dom';

const  ProjectUpdate =()=> {
    const theme = 'snow';
    const modules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'
          ]
      ],
    };
    let params = useParams();
    const placeholder = 'Enter important information about this project';
    const formats =  ['bold', 'italic', 'underline', 'strike'  ];
    const {quill, quillRef } = useQuill({} as any);
  const department = useQuery(["department"], fetchDepartment)
  const settings = useQuery(["settings"], fetchSettings, {
    staleTime: 10 * (60 * 1000), // 10 mins
  })

    const initial = {
	
      title:'',
      client:{label:'', value:''},
      team_lead:{label:'', value:'', title:'', last_name:''},
      team_member:[] as any,
      project_summary:'',
      start_date:null,
      complete_date:'',
      deadline:'',
      status:{label:'', value:''},
      work_station:{label:'', value:''},
      department:{label:'', value:''},
      duration:'',
      banner_url:'',
      budget:0,
      rank:0,
      description:'',
      completion_rate:''
    }

    const [staff, setStaff] = useState([] as any);
    const [clients, setClients] = useState([])

    const [station, setStation] = useState([] as any);
    const [project, setProject] = useState([] as any)
const [photo, setPhoto] = useState('')

    const [errors, setErrors] = useState({
      title:'',
      client:'',
      proposal:'',
      document:'',
      status:'',
      start_date:'',
      image:''
    })
    
    const fetchStaff =async()=>{
        let url = ServerUrl+'/api/employees/view/'+COMPANY_CODE
        const {data} = await axios.get(url, config)
        setStaff(data.data)
        }

    const fetchClient = async()=>{
        let url = ServerUrl+'/api/project/client/view/'+COMPANY_CODE
      await  axios.get(url, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){
          setClients(result.data.data)
          }
        })
        .catch((error)=>{
            Alerts('danger', error.message)
        })
        } 

     const handleSelect = (option:any, action:any)=>{
         setProject({...project, [action.name]:option.value});
          setErrors({...errors, [action.name]:''})
           }


           const handleChangeDate = (data:any, name:string)=>{
   
            setProject({...project, [name]:data});
             setErrors({...errors, [name]:''});
          }

          




        const handleChange = (event:ChangeEvent<HTMLInputElement>)=>{
           
          let {name, value} = event.target;	
          setProject({...project, [name]:value});
         setErrors({...errors, [name]:''});
      }
        
      

      const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
        event.preventDefault();
    
        let error = {...errors}; 
        let formIsValid = true;
    
    let msg ='This field is required';
  
    if(!project.title){
        error.title=msg;
        formIsValid = false;
      }

      if(!project.client){
        error.client=msg;
        formIsValid = false;
      }
      if(!project.status){
        error.status=msg;
        formIsValid = false;
      } 


      if(!project.start_date){
        error.start_date =msg;
        formIsValid = false;
      } 
    
    if(!formIsValid){
    setErrors(error) 

    window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
    }
    
    if(formIsValid) {
    
        LoadingModal()

       
        var options = project.team_member;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
        value.push({staff_code:options[i].value});
    }

        const fd = new FormData();
        Object.entries(project).forEach(([key, value]) => {
            fd.append(key, String(value));
        });

        if(photo){
            fd.append('imageFile', photo, project.banner_url); 
        } 

    
    let url = ServerUrl+'/api/project/update';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.status === 200){
         SuccessModal(response.data.message)
         window.open("/project/display_all", '_self')
       
                  } else{
                    Alerts('danger', response.data.message)
                  }   
              })
              .catch((error)=>{
                Alerts('danger', error.message)
              })
        }
    
    }
    


const getTitleName = (code:string, field:string) =>{ 
  const result = staff&&staff.filter((list:any)=>list.code===code);
 const answer = result.length !==0?result[0][field]:''
   return  answer;
 }


  const getTeam=(temp:string)=>{

      try{
      if(temp!=='[]'){
     let value:any = [];
   JSON.parse(temp).map((itm:any, idx:number)=>{
          value.push({value:itm, label:getTitleName(itm, 'first_name'), last_name:getTitleName(itm, 'first_name'), passport:getTitleName(itm, 'passport'), middle_name:getTitleName(itm, 'middle_name'), title:getTitleName(itm, 'title'), key:idx});
      } );
      
  return value;
    }
    else{
return []
  }
}catch(err){
          
}
  }



const  handleFile = function(fieldName:any){
  return function(newValue:any){                 
 readURL(newValue);
 }
} 



const readURL = (input:any)=>{

let displayMessage = '';

const PreviewImage = input.target.name;
const inputElement = document.getElementById('photo') as HTMLInputElement
const fileName = inputElement.value

var idxDot = fileName.lastIndexOf(".") + 1;
var extFile = fileName.substring(idxDot, fileName.length).toLowerCase();

 if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){ 
  //TO DO    
  
   var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
if (FileSize > 1) {
  displayMessage = ' File uploaded is larger than maximum size Allow (1 mb).';
  inputElement.value = '';
  setPhoto('')
} else {
  if (input.target.files && input.target.files[0]) { //Check if input has files.
      var reader = new FileReader();//Initialize FileReader.
      reader.onload = function (e:any) {
          
      //Check if input has files.
      let Id = Math.random().toString(36).substr(2, 9)
      let fileUrl = Id+'_'+input.target.files[0].name
      setPhoto(input.target.files[0])
      setProject({...project, banner_url:fileUrl })
        setErrors({...errors, image:''}); 
      }
      reader.readAsDataURL(input.target.files[0]);
      
  }
}
 }else{
displayMessage = 'Only JPEG|PNG|JPG  file format are allowed with maximum of 5 mb'
inputElement.value = '';
setProject({...project, banner_url:''})
}    

if(displayMessage.length > 0){
Alerts("danger", displayMessage)
}
}


useEffect(()=>{

    try{
     if (settings!==undefined && settings.data.length!==0){
     setStation(JSON.parse(settings.data[0].contacts))
     }
 
    }catch(err) {
 
    }
     
     }, [settings.data]) 



     const fetchProject =()=>{

      LoadingModal()
      
      let url = ServerUrl+'/api/project/edit_project/'+COMPANY_CODE+'/'+params.code
      axios.get(url, config).then(result=>{
        if(result.data.status===200){
          try {
              setProject(result.data.data[0])
    
          }catch(e){
    
          }
         
        }
      })
      .catch((error)=>{
       Alerts('danger', error.message)
      }).finally(()=>{
          closeModal()
      })
      }
    
    useEffect(()=>{
      fetchProject()


    }, [])

    useEffect(()=>{
     
      if(quill && project.length!==0){
        quill.root.innerHTML=project.description
        }

    }, [project])

    const getFieldName =(table:any, field:string, code:string)=>{

      if(Array.isArray(table)){
      const rs = table&&table.filter((item:any)=>item.code===code)
      return rs.length!==0?rs[0][field]:''
      }else{
          return ''
      }
     
   }

    useEffect(()=>{
        fetchClient()
        fetchStaff()
    },[])

  return ( <>
   <Breadcrumb 
   title="Update Project"
   summary="Modify Project Details"
   >
    <li className="breadcrumb-item">
    <Link to="/project/reports">Project</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Update Project</a> </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Register New Project</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                              
                                    
                                    
         <div className="content-wrappers ">
                            
         <div className=''>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Update Project Information</h5>
                <span>Project Details</span>
              
            </div>
            <div className="card-block">
    
        
                       

                <div className="row">



                <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Project Title <span className='text-danger'>*</span></label>

            <input type="text" className={errors.title?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  value={project.title} name="title" onChange={handleChange} />
                              

                {errors.title&&
    <div className="col-form-label has-danger">{errors.title&&errors.title} </div>}

                                 </div>
                                 </div>

                                 <div className="col-md-6">



<div className="form-group">
<label className="col-form-label">Select Client <span className='text-danger'>*</span></label>

<div className="input-group  ">

<Select 
   options={
                  clients&&clients.map((list:any, idx:number)=> {
                   return {key:idx, value: list.code, label: list.client_name }
                   })}
                   className={errors.client?"form-control form-control-danger":""} 
onChange={handleSelect}  name="client" value={{value:project.client, label:getFieldName(clients, 'client_name', project.client)}} 

             
             styles={group_styles}  
             /> 
            <div className="input-group-append  ">
       <span className="input-group-text "><a href="/project/clients" data-tooltip-id="my-tooltip" data-tooltip-content='Add more client'  ><i className="fa  fa-plus-circle text-white" id="addIcon"></i></a></span>
     </div>

   </div> 

   {errors.client&&
<div className="col-form-label has-danger">{errors.client&&errors.client} </div>}

                    </div>
                    </div>



                    <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Select Project Lead </label>


            <Select 
                options={
                               staff&&staff.map((list:any, idx:number)=> {
                                return {key:idx, value: list.code, label: list.first_name, last_name:list.last_name, middle_name:list.middle_name, title:list.title }
                                })}
                      getOptionLabel={option =>`${option.title} ${option.label} ${option.last_name}`} 
                  onChange={handleSelect}  name="team_lead" value={{value:project.team_lead, 
                    label:getTitleName(project.team_lead, 'first_name'),
                    last_name:getTitleName(project.team_lead, 'last_name'),
                    title:getTitleName(project.team_lead, 'title')
                
                }} 
                styles={selectStyles} 
                  /> 
                              
                                 </div>
                                 </div>


                                 <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Select Team Member </label>


            <Select 
            isMulti
                options={
                               staff&&staff.map((list:any, idx:number)=> {
                                return {key:idx, value: list.code, label: list.first_name, last_name:list.last_name, middle_name:list.middle_name, title:list.title }
                                })}
                     getOptionLabel={option =>`${option.title} ${option.label} ${option.last_name}`}
    onChange={handleSelect}  name="team_member" value={getTeam(project.team_member)} 
   styles={selectStyles}
    /> 
                              
                                 </div>
                                 </div>

                                 <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Start Date <span className='text-danger'>*</span></label>
           
           
            <input type="date" className="form-control form-control-lg"  value={project.start_date} name="start_date" onChange={handleChange} />

          {/*   <DatePicker 
            selected={project.start_date}      
             isClearable
             dateFormat="yyyy-MM-dd"
             className={errors.start_date?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}
             placeholderText="Select date"
             withPortal
 onChange={(date:any) => handleChangeDate(date, 'start_date')} /> */}


          

            {errors.start_date&&
    <div className="col-form-label has-danger">{errors.start_date&&errors.start_date} </div>}
             </div>  </div>



             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Deadline </label>
           
            <input type="date" className="form-control form-control-lg"  value={project.deadline} name="deadline" onChange={handleChange} />

             </div>
             </div>


             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Completion Date </label>
           
            <input type="date" className="form-control form-control-lg"  value={project.complete_date} name="complete_date" onChange={handleChange} />


             </div>
             </div>



             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Duration (In Months)</label>
                               
            <input type="text" className="form-control form-control-lg"  value={project.duration} name="duration" onChange={handleChange} />
                              

                                 </div>
                                 </div>


             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Budget</label>

            <input type="number" className="form-control form-control-lg"  value={project.budget} name="budget" onChange={handleChange} />
                              


                                 </div>
                                 </div>


                                 <div className="col-md-6">
         <div className="form-group">
        <label className="col-form-label"> Department/Unit <span className='text-danger'>*</span></label>
       
           
    <Select  options={
            department.data&&department.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.department_name}
                    })}   
			onChange={handleSelect}    
            name="department" 
            value={{label:getFieldName(department.data, 'department_name', project.department), value:project.department}}
         
            styles={styles}
               />

      
         </div>

         </div>


         <div className="col-md-6">
         <div className="form-group">
        <label className="col-form-label">Work Station</label>
       
           


    <Select  options={
            station&&station.map((list:any, id:number)=>{
                return {key:id, value:list.location.toUpperCase(), label:list.location.toUpperCase()}
                    })}   
                    styles={styles}
			onChange={handleSelect}   name="work_station" value={{label:project.work_station, value:project.work_station}}    />

      
         </div>

         </div>


         <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Completion Rate (In %)</label>
                               
            <input type="number" max='100' min='0' className="form-control form-control-lg"  value={project.completion_rate} name="completion_rate" onChange={handleChange} />
                              

                                 </div>
                                 </div>


                                 <div className="col-md-6">
<div className="form-group">
                           <label >Banner</label><br/>
                  <input id="photo"  onChange={handleFile('photo')} className='form-control' name="photo" type="file" accept="image/*" /> <span style={{color:'red'}}>{errors.image && errors.image}</span>
          <div className="note" style={{color:'red'}}>
          <strong>Size 900 kb,</strong>gif|jpg|png|jpeg Only Allow
      </div>	   	
              </div>
					
              </div>
                                




    <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Select Status <span className='text-danger'>*</span></label>


                                <Select   options={[
                    { value: 'Ongoing', label: 'Ongoing' },
                    { value: 'Processing', label: 'Processing' },
					{ value: 'Completed', label: 'Completed' },
					{ value: 'Pending', label: 'Pending' },
					
                ]} 
                                    
                          onChange={handleSelect} 
                          name="status" 
                          value={{label:project.status, value:project.status}} 
                          
                          className={errors.status? 'form-control form-control-danger' : ''} 
                          styles={styles}  
                          /> 
                              

                              {errors.status&&
    <div className="col-form-label has-danger">{errors.status&&errors.status} </div>}
                                 </div>
                                 </div>



    <div className="col-md-12">
  <div className="form-group">
  <label className="col-form-label">Description</label>

 
  <div style={{ width: '100%', height: 150 }}>
                        <div ref={quillRef} />

              </div>
              <div style={{ height: 40}}></div>
  </div>
</div>


                                





    </div>

    
            </div>
        </div>
    </div>
    </div>
    

    <div className="row">
        <div className="col-md-12 text-right">
    
        <button className="btn btn-outline-info btn-round pr-5 pl-5 mr-3" type='button'>Reset</button>&nbsp;
             <button type='button' onClick={handleSubmit} className="btn btn-success btn-round pr-5 pl-5">Save</button>
       
         </div>
        </div>

                              
    </div>

</div>
               
            </section>
        </div>
    </div>
</div>
</div>
                            </div>
                        </div>
                    </div>
      </div>
                                    </div>

                                    <Tooltip id="my-tooltip" place="top" />
                                </div>
                            </div>  
                           
        </>     
  )
}

export default ProjectUpdate