import React from 'react';
import {
    Document,
    Page,
    Text,
    View,
    Font,
    Image,
    StyleSheet,
    PDFViewer,
  } from "@react-pdf/renderer";
import { CURRENCY, ImagesUrl } from '../../components/Includes';
import { FormatNumber, getBritishDate, getMonthYear } from '../../components/GlobalFunction';

/* 
Font.register({
  family: "Roboto",
  fonts: [
    { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf", fontWeight: 300 },
    { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf", fontWeight: 400 },
    { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf", fontWeight: 500 },
    { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf", fontWeight: 600 },
  ],
}) */

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  }); 


  const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 20
const COLN_WIDTH = (100 - COL1_WIDTH) / 3

// Create styles
const styles = StyleSheet.create({
 
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  body: {
    paddingTop: 10,
    paddingBottom: 20,
    paddingHorizontal: 10,
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
    fontFamily: 'Oswald',
    marginBottom:5
  },

  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },

  table: { 
    display: "flex", 
    width: "auto", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableCol1Header: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },     
  tableColHeader: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },   
  tableCol1: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },   
  tableCol: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCellHeader: {
    margin: 5, 
    fontSize: 12,
    fontWeight: 'bold',
    color:'#fff',
  },  
  tableCell: { 
    margin: 5, 
    fontSize: 10 
  },
      company_info_wrapper:{
        marginBottom:10
      },

      h1:{
      fontSize:20,
      fontWeight:'bold',
      fontFamily: 'Oswald',
      textAlign:'center'
      },

      h2:{
      fontSize:12,
      textAlign:'center'
      },
      logo:{
      height:100,
      width:100
      },
});

const StaffLeaveDocument =(props:any)=> {

  const dataList:any = props.data;
    return (
        <Document
        
        title='Employee Payment History'
        creator='Huris by Deep Socket'

        >
    <Page 
    orientation='portrait'
    style={styles.body}
    
    >


<View style={styles.company_info_wrapper}>
<Image
        style={styles.logo}
        src= {ImagesUrl+'/logo.png'}
      />
<Text style={styles.h1}>Green Concern For Development</Text>
<Text style={styles.h2}>Suit 018, 2nd Floor Transpharm Plaza, Jabi, Abuja</Text>
<Text style={styles.h2}>info@greencodeng.org, 0803711477, 08098599821</Text>
</View>

<Text style={styles.title}>Employee Payment History</Text>
      



<View style={styles.table}> 



        <View style={[styles.tableRow, {backgroundColor:'#084cdf'} ]} fixed> 
        <View style={[styles.tableCol1Header, {width:'5%'}]}> 
            <Text style={styles.tableCellHeader}>SN</Text> 
          </View> 
          <View style={[styles.tableCol1Header, {width:'20%'}]}> 
            <Text style={styles.tableCellHeader}>Fullname</Text> 
          </View> 
          <View style={[styles.tableColHeader, {width:'15%'}]}> 
            <Text style={styles.tableCellHeader}>Month</Text> 
          </View> 
          <View style={[styles.tableColHeader,  {width:'17%'}]}> 
            <Text style={styles.tableCellHeader}>Earnings</Text> 
          </View> 
          <View style={[styles.tableColHeader,  {width:'17%'}]}> 
            <Text style={styles.tableCellHeader}>Deductions</Text> 
          </View> 
          <View style={[styles.tableColHeader, {width:'11%'}]}> 
            <Text style={styles.tableCellHeader}>Net Pays </Text> 
          </View>
          <View style={[styles.tableColHeader, {width:'15%'}]}> 
            <Text style={styles.tableCellHeader}>Date Paid</Text> 
          </View>
        </View>

       

{dataList.length!==0?dataList.map((row:any, id:number)=>
        <View style={styles.tableRow} key={id}> 
        <View style={[styles.tableCol, {width:'5%'}]}> 
            <Text style={styles.tableCell}>{id+1}</Text> 
          </View> 

          <View style={[styles.tableCol1, {width:'20%'}]}> 
            <Text style={styles.tableCell}>{row.staff_name}</Text> 
          </View> 
          <View style={[styles.tableCol, {width:'15%'}]}> 
            <Text style={styles.tableCell}>{getMonthYear(row.payment_month)}</Text> 
          </View> 
          <View style={[styles.tableCol, {width:'17%'} ]}>
            <Text style={styles.tableCell}>{FormatNumber(row.earning)}</Text> 
          </View>
          
          <View style={[styles.tableCol, {width:'17%'}]}> 
            <Text style={styles.tableCell}>{FormatNumber(row.deduction)}</Text> 
          </View> 
          <View style={[styles.tableCol, {width:'11%'}]}> 
            <Text style={styles.tableCell}>{FormatNumber(row.net_pay)}</Text> 
          </View>  
          <View style={[styles.tableCol, {width:'15%'}]}> 
            <Text style={styles.tableCell}>{row.payment_date}</Text> 
          </View> 
        </View>):[]}
       
      </View>

      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber}`
      )} fixed /> 
    </Page>
  </Document>
    );
  }


  export const ViewDocument =(props:any)=>{
    return <PDFViewer style={styles.viewer}>
      <StaffLeaveDocument data={props.data}  />
    </PDFViewer>
  }


  export const DownloadDocument =(props:any)=>{
    return <StaffLeaveDocument data={props.data}  />
  }