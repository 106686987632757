import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, ImagesUrl, STAFF_CODE, ServerUrl, config, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'


import { useQuery } from 'react-query'
import Select from 'react-select'
import { FetchProfile, fetchDepartment} from '../../api/ReactQuery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertDate, convertDateTime, convertTime, getBritishDate, getMonthYear, getTime, shortText } from '../../components/GlobalFunction';
import { Link } from 'react-router-dom';

const AttendanceLog =()=> {
    
  const profile = useQuery(["profile"], FetchProfile)
    const [head, setHead]= useState([] as any)
    const [content, setContent] = useState([] as any);
    
    const [sqlQuery, setSqlQuery] = useState('');
    const department = useQuery(["department"], fetchDepartment)
    const [search, setSearch] = useState({
     
        department:[] as any,
        start_date:null,
        end_date:null,
     })

           const [errors, setErrors] = useState({
            start_date:'',
            end_date:'',
           
         })


         const handleSelect = (option:any, action:any)=>{
            setSearch({...search, [action.name]:option});
              setErrors({...errors, [action.name]:''})
              setContent([])
    
               }


               const handleChangeDate = (data:any, name:string)=>{
                setSearch({...search, [name]:data});
                setErrors({...errors, [name]:''})
                setContent([])

                if(name==='start_date'){
                   getDaysBetween(data, search.end_date)
                   }else{
                    getDaysBetween(search.start_date, data)
                   } 

             }

             


         const getDays=(date:any)=>{
            let day;
            switch (new Date(date).getDay()){
                case 0:
                    day =" Sun";
                    break;
                case 1:
                    day =" Mon";
                    break;
                case 2:
                    day =" Tue";
                   break;
                case 3:
                    day =" Wed";
                        break;
                case 4:
                    day =" Thu";
                        break;
                case 5:
                    day =" Fri";
                    break;
                case 6:
                    day =" Sat"
                    break;
                default:
                    day=" Sun"
            }
                return day
            }

         const getDaysBetween =(startDate:any, endDate:any)=>{
            const start = new Date(startDate)
            const end =  new Date(endDate)
        
            var result = []
            while (start <= end){
                result.push(new Date(start).toISOString().slice(0,10))
                start.setDate(start.getDate()+1)
            }
    
           
            
    
    
            let final =   'SELECT e.first_name, e.last_name, a.attendance, cast(TIMESTAMPDIFF(minute, time_in, time_out)/60.0 as decimal(10,2)) as totalHours, a.time_in, a.time_out, a.attendance_date, a.ip_address  from tbl_employee e, tbl_employee_attendance a where e.code = a.staff_code and e.termination_reason = "" and a.attendance_date >= "'+convertDate(startDate)+'" and a.attendance_date <= "'+convertDate(endDate)+'" order by a.attendance_date'


            setSqlQuery(final)
        }

               

const fetchContent = async()=>{


    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';
              

        if(!search.start_date){
            error.start_date =msg;
            formIsValid = false;
        } 

        if(!search.end_date){
            error.end_date =msg;
            formIsValid = false;
        } 

        if(!formIsValid){
        setErrors(error) 
        }else{
    LoadingModal()
 
         
    var final = sqlQuery


    if(search.department.length!==0){
        final = final + " and e.department = '"+search.department.value+"'"
    }


 
          const fd = new FormData();
          fd.append('sql', final); 
          let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
        await  axios.post(url, fd, config).then((result:any)=>{
          if(Array.isArray(result.data.data) && result.data.data.length!==0){
            setContent(result.data.data)

let dates = result.data.data.map((item:any)=>item.attendance_date)
let unique_date = dates.filter((item:any, index:number, arr:any) => arr.indexOf(item) === index)
          
setHead(unique_date)

            window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
            }else{
                setContent([]) 
                Alerts('danger',' There are no attendance found for the selected date range')
            }
          })
          .catch((error)=>{
              Alerts('danger',error.message)
          }).finally(()=>{
              closeModal()
          })
        }
          } 

const getHistory =(data:any)=>{
const rs = content&&content.filter((item:any)=>item.attendance_date ===data)

return rs;
}  



useEffect(()=>{
    //fetchSettings()
}, [])

  return ( <>
   <Breadcrumb 
   title="Employee Working Days"
   summary="Display Employee Working days"
   >
    <li className="breadcrumb-item">
    <Link to="/attendance/display_attendance">Attendance</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Employee Working Days</a> </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
<div className="main-body">
<div className="page-wrapper">
<div className="page-body">


<div className="row">
<div className=" col-md-12">
<div className="card z-depth-0">
    <div className="card-header">
        <h5>DISPLAY STAFF WORKING HOURS</h5>
        <span  ><a href="#"> Display Employee</a></span>
        <div className="card-header-right">

            <ul className="list-unstyled card-option">
                <li><i className="fa fa fa-wrench open-card-option"></i></li>
                <li><i className="fa fa-window-maximize full-card"></i></li>
                <li><i className="fa fa-minus minimize-card"></i></li>
                <li><i className="fa fa-refresh reload-card"></i></li>
                <li><i className="fa fa-trash close-card"></i></li>
            </ul>
            
        </div>
    </div>
    <div className="card-block ">
       


        <div className="row">
        <div className="col-md-4">
    <div className="form-group">
    <label className="col-form-label">Search by Department</label>
    
    
    <Select  options={
            department.data&&department.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.department_name}
                    })}   
                    styles={styles} 
			onChange={handleSelect}    
            name="department" 
            value={search.department} 
               />
    
    </div>
    
    </div>



    <div className="col-md-4">
             <div className="form-group">
            <label className="col-form-label">Attendance Start Date <span className='text-danger'>*</span></label>
           
            <DatePicker 
            selected={search.start_date}      
             isClearable
             dateFormat="yyyy-MM-dd"
             className={errors.start_date?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}
             placeholderText="Select date"
           
 onChange={(date:any) => handleChangeDate(date, 'start_date')} />


        
{errors.start_date&&
    <div className="col-form-label has-danger">{errors.start_date&&errors.start_date} </div>}
             </div>

             </div>


             <div className="col-md-4">
             <div className="form-group">
            <label className="col-form-label">Attendance End Date <span className='text-danger'>*</span></label>
           
            <DatePicker 
            selected={search.end_date}      
             isClearable
             dateFormat="yyyy-MM-dd"
             className={errors.end_date?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}
             placeholderText="Select date"
           
 onChange={(date:any) => handleChangeDate(date, 'end_date')} />


        
{errors.end_date&&
    <div className="col-form-label has-danger">{errors.end_date&&errors.end_date} </div>}
             </div>

             </div>



        </div>


<hr/>
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={fetchContent}>Display Attendnace</button>
</div>
</div>





    </div>
</div>
</div>
</div>






{content.length!==0?
  <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-block">
    

            <div className='text-center '>
                        <img 
           src={ImagesUrl+'/about/'+profile.data.logo}  alt="Click to change" 
           className="m-b-10 img-100" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/logo.png'}} />


                        <h4>{profile.data.company_name} </h4>
                        <h5>{profile.data.company_address}</h5>
                        <h6 className='m-b-20'>{profile.data.email}, {profile.data.phone}</h6>


</div>

    <div className="row">
        <div className="table-responsive ">
       

        {head&&head.map((data:any, id:number)=>
        <table className="table table-sm table-bordered table-hover" key={id}>
                <thead>
                    <tr>
                        <th colSpan={9} className='text-center '> 
                        <h5>Attendnace Log Of  {getBritishDate(data)} </h5>
                        </th>
                    </tr>
                
                  
                    <tr style={{backgroundColor:'#E7E0EE'}}>
                     <th>S/N</th>
                    <th style={{backgroundColor:'#E7E0EE'}} className='fixed-column'>Employee Name</th>
                    <th >Time In</th>
                    <th >Time Out</th>
                    <th >Attendance</th>
                    <th >IP Address</th>
                    <th >Worked Hours</th>
                  </tr>
           
                </thead>
                <tbody>

               {getHistory(data).map((list:any, index:number)=>  <tr key={index}>
                        <td >{index+1}</td>
                        <td className='fixed-column'>{list.first_name+ ' '+ list.last_name}</td>
                       
                        <td >{list.attendance!=='A'?getTime(list.time_in):''}</td>
                        <td >{list.attendance!=='A'?getTime(list.time_out):''}</td>
                        <td>{list.attendance==='P'?'Present':list.attendance==='H'?'Half day':'Absent'}</td>
                        <td >{list.attendance!=='A'?list.ip_address:''}</td>
                        <td >{list.attendance!=='A'?list.totalHours + ' Hours':''}</td>

                </tr>)}
                          

                </tbody>
                   
            </table>
        )}

        </div>

        <button className="btn btn-primary btn-sm text-right" type='button' ><i className='fa fa-print'></i> Print</button>


        </div>

            </div>
        </div>
    </div>
    </div>:[]}

</div>
</div>


</div>
</div>  
      </>               
 
  )
}

export default AttendanceLog