import { useEffect, useState, FormEvent} from 'react'
import { COMPANY_CODE, STAFF_CODE, ServerUrl, config } from '../../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal, CustomLoader } from '../../../components/notify';
import Select from 'react-select'
import { fetchEmployee } from '../../../api/ReactQuery';
import { useQuery } from 'react-query'

import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'

const ReportLine =({code}:{code:any})=> {

    const [disabled, setDisabled] = useState(false);
    const [openSub, setOpenSub] = useState(false);
    const [staff, setStaff] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState([] as any);
    const [subordinate, setSubordinate] = useState([] as any);
    const {data} = useQuery(["employee", code], fetchEmployee)
    const [errors, setErrors] = useState({
        supervisor:'',
        subordinate:'',
     })
    const [prof, setProf] = useState({
      subordinate:{value:'', label:'', title:'', last_name:''},
    });


const fetchStaff =async()=>{
    let url = ServerUrl+'/api/employees/view/'+COMPANY_CODE
    const {data} = await axios.get(url, config)
   
    setStaff(data.data)
    }

    
const getTitleName = (code:string, field:string) =>{ 
    const result = staff&&staff.filter((list:any)=>list.code===code);
   const answer = result.length !==0?result[0][field]:''
     return  answer;
   }


    const getSupervisor=(temp:string)=>{

        try{
          
       let value:any = [];
     JSON.parse(temp).map((itm:any, idx:number)=>{
            value.push({value:itm, label:getTitleName(itm, 'first_name'), last_name:getTitleName(itm, 'last_name'), middle_name:getTitleName(itm, 'middle_name'), title:getTitleName(itm, 'title'), key:idx});
        } );
        
    return value;
    
}catch(err){
            
}
    }


    const handleSupervisor =(event: FormEvent<HTMLButtonElement>)=>{
        event.preventDefault();
    
        let error = {...errors}; 
        let formIsValid = true;
    
    let msg ='This field is required';
    
    if(!content[0].supervisor){
        error.supervisor =msg;
        formIsValid = false;
    } 
    
    
    if(!formIsValid){
    setErrors(error) 
    }else{
        handleSubmit(content)
    }

}


const handleSubordinate =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!prof.subordinate){
    error.subordinate =msg;
    formIsValid = false;
} 


if(!formIsValid){
setErrors(error) 
}else{

    const details = subordinate.concat(prof.subordinate.value)
    const subord = JSON.stringify(details,null, 2)

    const newProfile = content.map((item:any)=>{
      
            return {...item, 
                subordinate:subord
            }
          })
          
          setContent(newProfile)
          setSubordinate(details)



    handleSubmit(newProfile)
}

}

const handleSubmit =(newProfile:any)=>{
   

    LoadingModal()
 const fd = new FormData();
    Object.entries(newProfile[0]).forEach(([key, value]) => {
            fd.append(key, String(value));
        });

        const log = JSON.parse(newProfile[0].update_log)
        const item = log.concat( {
            code:STAFF_CODE,
            action:'Report Line: Updated report line',
            date_time: new Date()
        })


const logs = JSON.stringify(item,null, 2)

        fd.append('logs', logs);
       

   let url = ServerUrl+'/api/employee/update/'+code;
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                ErrorModal('', response.data.message)
              }   
          })
          .catch((error)=>{
           ErrorModal('oops', error.message)
          }).finally(()=>{
            setProf({
                subordinate:{value:'', label:'', title:'', last_name:''},
            })
          }) 
  

}


const DeleteModal =(code:string)=>{
    
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading(),
    confirmButtonText: 'Yes, I am!',

    preConfirm: async() => {

const newContact = subordinate.filter((item:any)=>item!==code)



const log = JSON.parse(content[0].update_log)
const item = log.concat( {
    code:STAFF_CODE,
    action:'Report Line: Removed one subordinate',
    date_time: new Date()
})

const logs = JSON.stringify(item,null, 2)
const newProfile = content.map((item:any)=>{
    return {...item, 
        subordinate:JSON.stringify(newContact,null, 2)
    }
  })
  
  setContent(newProfile)
  setSubordinate(newContact)

      const fd = new FormData();
fd.append('logs', logs);
Object.entries(newProfile[0]).forEach(([key, value]) => {
                    fd.append(key, String(value));
                }); 

let url = ServerUrl+'/api/employee/update/'+code;
return await axios.post(url, fd, config)
.then(response =>{

if(response.data.status !== 200){
    throw new Error(response.statusText)

      } else{
        return response.data
      }   
  })
  .catch((error)=>{
    Swal.showValidationMessage(
        `Request failed: ${error}`
      )
  })
    }
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }






        const TableData =()=>{

           
            const columns = [
                {
                    name: 'Employee',
                    cell: (row:any) => getTitleName(row, 'title') +' '+ getTitleName(row, 'first_name')+ ' '+getTitleName(row, 'last_name') + ' ' +getTitleName(row, 'middle_name'), 
                    sortable: true,
                },
               
                {
                    name: 'Action',
                    cell: (row:any, index:any, column:any, id:any) => <div className='row'>  
                       
                    <label className="label label-danger pointer" onClick={()=>DeleteModal(row)}><i className='ti-trash'></i> Delete</label></div>,
                        left:true
                              
        
                },
            ];
            
        
          
        
            return <DataTable
            title='All Suboardinates'
            columns={columns}
            data={subordinate}
            dense
            highlightOnHover
            pagination
            selectableRowsHighlight
            striped
            progressPending={loading}
            progressComponent={<CustomLoader />}
        />
        
        }

        const handleSelectSenior = (option:any, action:any)=>{

            try{

          let supervisor:any = [];
                for (var i = 0, l = option.length; i < l; i++) {
                    supervisor.push(option[i].value);
                }
        
                const newProfile = content.map((item:any)=>{
                    return {...item, 
                        supervisor:JSON.stringify(supervisor,null, 2)
                    }
                  })
                  
                  setContent(newProfile)
                setErrors({...errors, [action.name]:''})
            }catch(err){
                
            }
        }



     const handleSelect = (option:any, action:any)=>{

        setProf({...prof, [action.name]:option});
       setErrors({...errors, [action.name]:''});
           }

useEffect(()=>{
    fetchStaff()
}, [])





useEffect(()=>{

    try{
 
     if (data!==undefined && data.length!==0){
     setContent(data)
     setSubordinate(JSON.parse(data[0].subordinate))
     window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
         
     }
 
    }catch(err) {
 
    }
     
     }, [data])

    return (
<div>
<div className="card z-depth-0">

<div className="card-header">
<h5>Report Line</h5>

</div>
{content.length!==0? content.map((profile:any, index:number)=>  <div key={index} className="card-block">


<div className="row">

<div className="col-md-12">
<div className="form-group">
<label className="col-form-label">Supervisors <span className='text-danger'>*</span></label>

<Select isMulti options={
                               staff&&staff.filter((item:any)=>item.code!==code).map((list:any, idx:number)=> {
                                return {key:idx, value: list.code, label: list.first_name, last_name:list.last_name, middle_name:list.middle_name, title:list.title }
                                })}
                                isDisabled={disabled}
            className={errors.supervisor?"form-control form-control-danger":""}  
                     getOptionLabel={option =>`${option.title} ${option.label} ${option.last_name}`}
    onChange={handleSelectSenior}  name="supervisor" value={getSupervisor(profile.supervisor)}  /> 

{errors.supervisor&&
    <div className="col-form-label has-danger">{errors.supervisor&&errors.supervisor} </div>}
</div>
</div>

</div>



<div className="row">
<div className="col-md-12 text-right">
<button className="btn btn-success btn-round" type='button' onClick={handleSupervisor}>Save Details</button>
</div>
</div>


<div className="row">
<div className="col-md-6">

<div className="d-flex justify-content-start">
<label className="sub-titles">
Assign Subordinates
</label>
<label className="switch ml-3">
<input type="checkbox" name='openSub' checked={openSub} onChange={()=>setOpenSub(!openSub)} />
<span className="slider round"></span>
</label>
</div>


</div>
</div>

{openSub?
<div  >


<div className="row "style={{backgroundColor: 'rgba(68, 138, 255, 0.1)'}} >


<div className="col-md-12">
<div className="form-group">
<label className="col-form-label">Select Subordinate <span className='text-danger'>*</span></label>


<Select options={
                               staff&&staff.filter((item:any)=>item.code!==code).map((list:any, idx:number)=> {
                                return {key:idx, value: list.code, label: list.first_name, last_name:list.last_name, middle_name:list.middle_name, title:list.title }
                                })}
                                className={errors.subordinate?"form-control form-control-danger":""} 
                     getOptionLabel={option =>`${option.title} ${option.label} ${option.last_name}`}
    onChange={handleSelect}  name="subordinate" value={prof.subordinate} 
    isDisabled={disabled}
    /> 


{errors.subordinate&&
    <div className="col-form-label has-danger">{errors.subordinate&&errors.subordinate} </div>}
</div>


</div>



<div className="col-md-12 m-b-10 text-right">
<button className="btn btn-inverse btn-sm" onClick={handleSubordinate} type='button'>Save </button>
</div>


</div>


<div className="table-responsive m-t-10">
    <TableData />
</div>


</div>:[]}

</div>):''}
</div>


</div>
  
    
  )
}

export default ReportLine