import Cookies from 'js-cookie'
import { decrypt } from './authentication'
var CryptoJS = require('crypto-js')

export const Token:any = Cookies.get('hrtk')?Cookies.get('hrtk'):undefined
export const GROUP_CODE =  Cookies.get('hrgpcd')?decrypt(Cookies.get('hrgpcd')):'null' 

/* 
export const ImagesUrl:any ='http://localhost:8000/images'; 
export const ServerUrl:any ='http://localhost:8000';  */
 

export const ImagesUrl:any ='https://api.greencodeng.org/public/images';
export const ServerUrl:any ='https://api.greencodeng.org';    

export const BearerToken = Token;

export const config = {
    headers: { 'content-type': 'multipart/form-data',
    'Authorization': `Bearer ${Token}`}
} 


export const CURRENCY = '₦';
export const configFLW = {
    headers: { 
        'content-type': 'application/json',
    'Authorization': `Bearer FLWSECK-aed05886d2886cce20d998480b009fea-X`}
} 


export const configDownload:any = {
  responseType: 'blob', 
  headers: {
  'Authorization': `Bearer ${Token}`}
}


export const COMPANY_CODE = 'wed';

export const STAFF_CODE = Cookies.get('hrucd')?decrypt(Cookies.get('hrucd')):'null'

export   const styles = {
    container: (css:any) => ({ ...css, flex: "1 1 auto", alignSelf: "stretch" }),
    menu: (css: any) => ({ ...css, zIndex: 999 }),
    control: (css:any) => ({ ...css,  height:45 })
  };

  export   const group_styles = {
    container: (css:any) => ({ ...css, flex: "1 1 auto", alignSelf: "stretch" }),
    menu: (css: any) => ({ ...css, zIndex: 999 }),
    control: (css:any) => ({ ...css,  borderTopLeftRadius:5, borderBottomLeftRadius:5, borderTopRightRadius:0, borderBottomRightRadius:0 })
  };

export const selectStyles = { menu: (styles: any) => ({ ...styles, zIndex: 999 }) };