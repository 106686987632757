import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo} from 'react'
import { COMPANY_CODE, ImagesUrl, STAFF_CODE, ServerUrl, config, selectStyles } from '../../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal, CustomLoader, closeModal } from '../../../components/notify';
import Select, { components } from 'react-select'
import { fetchEmployee, fetchCountry, handleFetchState } from '../../../api/ReactQuery';
import { useQuery } from 'react-query'
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'

import { useSelector, useDispatch } from 'react-redux'
const Passport =({code}:{code:any})=> {


    const country  = useSelector((state:any) => state.rootReducer.country);
    //const country = useQuery(["country"], fetchCountry)
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState([] as any);
    const [contact, setContact] = useState([] as any);
    const {data} = useQuery(["employee", code], fetchEmployee)
    const [errors, setErrors] = useState({
        document_type:'',
       document_number:'',
     })
    const [profile, setProfile] = useState({
       document_type:'',
       document_number:'',
       issuer:'',
       issued_date:'',
       expiry_date:'',
       renewed_date:'',
       country:'',
        code:Math.random().toString(36).substring(2, 9),
        date: new Date()
    });

    const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	
        setProfile({...profile, [name]:value});
       setErrors({...errors, [name]:''});
     }

     const handleSelect = (option:any, action:any)=>{

        setProfile({...profile, [action.name]:option.value});
       setErrors({...errors, [action.name]:''});
           }



   
        const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
            event.preventDefault();
        
            let error = {...errors}; 
            let formIsValid = true;
        
        let msg ='This field is required';
        

        if(!profile.document_type){
            error.document_type =msg;
            formIsValid = false;
        } 
        
        
        
        if(!profile.document_number){
            error.document_number =msg;
            formIsValid = false;
        } 

        if(!formIsValid){
        setErrors(error) 
        }
        
        
        if(formIsValid) {
           LoadingModal()

                const visa =  JSON.parse(content[0].passport_visa)
                const contacts = visa.concat(profile)

                const log = JSON.parse(content[0].update_log)
                const item = log.concat( {
                    code:STAFF_CODE,
                    action:'Passport & Visa: Addedd one document',
                    date_time: new Date()
                })
    
             const logs = JSON.stringify(item,null, 2)


                const newProfile = content.map((item:any)=>{
                const passport_visa = JSON.stringify(contacts,null, 2)


                    return {...item, 
                        passport_visa:passport_visa
                    }
                  })
                  
                  setContent(newProfile)
                  setContact(contacts)

                      const fd = new FormData();
               fd.append('logs', logs);
                Object.entries(newProfile[0]).forEach(([key, value]) => {
                                    fd.append(key, String(value));
                                }); 

        let click = document.getElementById('close')?.click();

           let url = ServerUrl+'/api/employee/update/'+code;
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status === 200){
             SuccessModal(response.data.message)
           
                      } else{
                        ErrorModal('', response.data.message)
                      }   
                  })
                  .catch((error)=>{
                   ErrorModal('oops', error.message)
                  }).finally(()=>{

                    setProfile({
                        document_type:'',
                        document_number:'',
                        issuer:'',
                        issued_date:'',
                        expiry_date:'',
                        renewed_date:'',
                        country:'',
                        code:Math.random().toString(36).substring(2, 9),
                        date: new Date()
                    })

                  })  
            }
        
        }



const DeleteModal =(code:string)=>{
    
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading(),
    confirmButtonText: 'Yes, I am!',

    preConfirm: async() => {

const newContact = contact.filter((item:any)=>item.code !==code)



const log = JSON.parse(content[0].update_log)
const item = log.concat( {
    code:STAFF_CODE,
    action:'Passport & Visa: Removed one document',
    date_time: new Date()
})

const logs = JSON.stringify(item,null, 2)
const newProfile = content.map((item:any)=>{
    return {...item, 
       passport_visa:JSON.stringify(newContact,null, 2)
    }
  })
  
  setContent(newProfile)
  setContact(newContact)

      const fd = new FormData();
fd.append('logs', logs);
Object.entries(newProfile[0]).forEach(([key, value]) => {
                    fd.append(key, String(value));
                }); 

let url = ServerUrl+'/api/employee/update/'+code;
return await axios.post(url, fd, config)
.then(response =>{

if(response.data.status !== 200){
    throw new Error(response.statusText)

      } else{
        return response.data
      }   
  })
  .catch((error)=>{
    Swal.showValidationMessage(
        `Request failed: ${error}`
      )
  })
    }
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }






        const TableData =()=>{

           
            const columns = [
                {
                    name: 'Document',
                    cell: (row:any) => row.document_type, 
                    sortable: true,
                },
                {
                    name: 'Number',
                    selector: (row:any) => row.document_number,
                    sortable: true,
                },
                {
                    name: 'Duration',
                    cell: (row:any) => <p>{ row.issued_date + '  to '+ row.expiry_date}</p>,
                    sortable: true,
                    wrap:false
                },
                {
                    name: 'Issuer',
                    cell: (row:any) => <p title={row.issuer}>{row.issuer}</p>, 
                    sortable: true,
                },
                {
                    name: 'Renew Date',
                    selector: (row:any) => row.renewed_date,
                    sortable: true,
                },
                {
                    name: 'Action',
                    cell: (row:any, index:any, column:any, id:any) => <div className='row'>  
                        <label className="label label-info pointer"  onClick={()=>EditItem(row)} ><i className='ti-pencil'></i> Edit</label>
                    <label className="label label-danger pointer" onClick={()=>DeleteModal(row.code)}><i className='ti-trash'></i> Delete</label></div>,
                        left:true
                              
        
                },
            ];
            
        
          
        
            return <DataTable
            columns={columns}
            data={contact}
            dense
            highlightOnHover
            pagination
            selectableRowsHighlight
            striped
            progressPending={loading}
            progressComponent={<CustomLoader />}
        />
        
        }


        const handleUpdate =(event: FormEvent<HTMLButtonElement>)=>{
            event.preventDefault();
        
            let error = {...errors}; 
            let formIsValid = true;
        
        let msg ='This field is required';
        

        if(!profile.document_type){
            error.document_type =msg;
            formIsValid = false;
        } 
        
        
        
        if(!profile.document_number){
            error.document_number =msg;
            formIsValid = false;
        } 

        if(!formIsValid){
        setErrors(error) 
        }
        
        
        if(formIsValid) {
           LoadingModal()

              
                const visa = contact.map((item:any)=>{
                   
                    if(item.code ===profile.code){
                        return profile
                    }
    
                     return item
                      })


                const log = JSON.parse(content[0].update_log)
                const item = log.concat( {
                    code:STAFF_CODE,
                    action:'Passpot& Visa: Updated one document',
                    date_time: new Date()
                })
    
             const logs = JSON.stringify(item,null, 2)

                const newProfile = content.map((item:any)=>{
                const passport_visa = JSON.stringify(visa,null, 2)
                    return {...item, 
                        passport_visa:passport_visa
                    }
                  })
                  
                  setContent(newProfile)
                  setContact(visa)

                      const fd = new FormData();
               fd.append('logs', logs);
                Object.entries(newProfile[0]).forEach(([key, value]) => {
                                    fd.append(key, String(value));
                                }); 

        let click = document.getElementById('closeEdit')?.click();

           let url = ServerUrl+'/api/employee/update/'+code;
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status === 200){
             SuccessModal(response.data.message)
           
                      } else{
                        ErrorModal('', response.data.message)
                      }   
                  })
                  .catch((error)=>{
                   ErrorModal('oops', error.message)
                  }).finally(()=>{

                    setProfile({
                        document_type:'',
                        document_number:'',
                        issuer:'',
                        issued_date:'',
                        expiry_date:'',
                        renewed_date:'',
                        country:'',
                        code:Math.random().toString(36).substring(2, 9),
                        date: new Date()
                    })

                  })  
            }
        
        }


        const EditItem =(data:any)=>{
            setProfile({
                        document_type:data.document_type,
                        document_number:data.document_number,
                        issuer:data.issuer,
                        issued_date:data.issued_date,
                        expiry_date:data.expiry_date,
                        renewed_date:data.renewed_date,
                        country:data.country,
                        code:data.code,
                        date: data.date
            });
            setErrors({
                document_type:'',
                document_number:'',
            })
            let click = document.getElementById('openEdit')?.click()
        
        }

        const handleReset =()=>{
            setProfile({
                document_type:'',
                        document_number:'',
                        issuer:'',
                        issued_date:'',
                        expiry_date:'',
                        renewed_date:'',
                        country:'',
                code:Math.random().toString(36).substring(2, 9),
                date: new Date()
            })
            let click = document.getElementById('openAdd')?.click()
        }

useEffect(()=>{

   try{

    if (data!==undefined && data.length!==0){
    setContent(data)
    setContact(JSON.parse(data[0].passport_visa))
    window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
         
    }

   }catch(err) {

   }
    
    }, [data])

  return (
                        
<div>

 
<div className="card z-depth-0">

<div className="card-header">
<h5>Passport, Visa & Licenses</h5>

<div className="card-header-right">
<label className="label label-primary pointer " onClick={handleReset}> <i className='fa fa-plus text-white'></i> Add Document</label>
<label  id='openAdd' data-toggle="modal" data-target="#large-Modal"> </label>    
               
</div>

</div>






<label data-toggle="modal" data-target="#editPassport" id='openEdit' ></label>
<div className="modal fade push-modal-right" id="editPassport" tabIndex={-1} role="dialog" data-backdrop="static" >
    <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
            <div className="modal-header bg-dark">
                <h4 className="modal-title text-white">Update Document</h4>
              

        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <i className='fa fa-ban text-danger'></i>
        </button>

            </div>
            <div className="modal-body">
            <div className="row">
<div className="col-md-4">
<div className="form-group">
<label className="col-form-label">Document Type <span className='text-danger'>*</span></label>


<Select  options={[
                    { value: 'Passport', label: 'Passport' },
                    { value: 'Visa', label: 'Visa'},
                    { value: 'Work Permit', label: 'Work Permit'},
                    { value: 'Driving Licensest', label: 'Driving Licenses'},
                                ]} 
                       

            className={errors.document_type?"form-control form-control-danger":""}
                                onChange={handleSelect}  name="document_type"
                        value={{value: profile.document_type, label: profile.document_type}} 
                        styles={selectStyles} 
                 />

{errors.document_type&&
    <div className="col-form-label has-danger">{errors.document_type&&errors.document_type} </div>}

</div>

</div>


<div className="col-md-4">
<div className="form-group">
<label className="col-form-label">Document Number <span className='text-danger'>*</span></label>

<input type="text" className={errors.document_number?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='document_number' value={profile.document_number} onChange={handleChange} />
    
    {errors.document_number&&
    <div className="col-form-label has-danger">{errors.document_number&&errors.document_number} </div>}

</div>

</div>


<div className="col-md-4">
<div className="form-group">
<label className="col-form-label">Issuing Authority</label>


<input type="text" className="form-control form-control-lg"  name='issuer' value={profile.issuer} onChange={handleChange} />

</div>

</div>

<div className="col-md-4">
<div className="form-group">
<label className="col-form-label">Issued Date</label>


<input type="date" className="form-control form-control-lg"  name='issued_date' value={profile.issued_date} onChange={handleChange} />
</div>

</div>


<div className="col-md-4">
<div className="form-group">
<label className="col-form-label">Expiry Date</label>

<input type="date" className="form-control form-control-lg" name='expiry_date' value={profile.expiry_date} onChange={handleChange} />

</div>

</div>


<div className="col-md-4">
<div className="form-group">
<label className="col-form-label">Renew Date</label>

<input type="date" className="form-control form-control-lg"  name='renewed_date' value={profile.renewed_date} onChange={handleChange} />

</div>

</div>




<div className="col-md-4">
<div className="form-group">
<label className="col-form-label">Issuing Country</label>
<Select   options={country&&country.map((list:any, id:number)=> {
                                    return {key:id, value: list.name.common, label: list.name.common };
                                  })}
onChange={handleSelect}  name="country" value={{value: profile.country, label: profile.country}} styles={selectStyles} />  

</div>

</div>

</div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-default waves-effect " data-dismiss="modal" id='closeEdit'>Close</button>
                <button type="button" className="btn btn-outline-success " onClick={handleUpdate} >Update</button>
            </div>
        </div>
    </div>
</div>




<div className="modal fade push-modal-right" id="large-Modal" tabIndex={-1} role="dialog" data-backdrop="static" >
    <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
            <div className="modal-header bg-dark">
                <h4 className="modal-title text-white">Add Document</h4>
              

        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <i className='fa fa-ban text-danger'></i>
        </button>

            </div>
            <div className="modal-body">
            <div className="row">
<div className="col-md-4">
<div className="form-group">
<label className="col-form-label">Document Type <span className='text-danger'>*</span></label>


<Select  options={[
                    { value: 'Passport', label: 'Passport' },
                    { value: 'Visa', label: 'Visa'},
                    { value: 'Work Permit', label: 'Work Permit'},
                    { value: 'Driving Licensest', label: 'Driving Licenses'},
                                ]} 
                       

            className={errors.document_type?"form-control form-control-danger":""}
                                onChange={handleSelect}  name="document_type"
                        value={{value: profile.document_type, label: profile.document_type}} 
                        styles={selectStyles} 
                 />

{errors.document_type&&
    <div className="col-form-label has-danger">{errors.document_type&&errors.document_type} </div>}

</div>

</div>


<div className="col-md-4">
<div className="form-group">
<label className="col-form-label">Document Number <span className='text-danger'>*</span></label>

<input type="text" className={errors.document_number?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='document_number' value={profile.document_number} onChange={handleChange} />
    
    {errors.document_number&&
    <div className="col-form-label has-danger">{errors.document_number&&errors.document_number} </div>}

</div>

</div>


<div className="col-md-4">
<div className="form-group">
<label className="col-form-label">Issuing Authority</label>


<input type="text" className="form-control form-control-lg"  name='issuer' value={profile.issuer} onChange={handleChange} />

</div>

</div>

<div className="col-md-4">
<div className="form-group">
<label className="col-form-label">Issued Date</label>


<input type="date" className="form-control form-control-lg"  name='issued_date' value={profile.issued_date} onChange={handleChange} />
</div>

</div>


<div className="col-md-4">
<div className="form-group">
<label className="col-form-label">Expiry Date</label>

<input type="date" className="form-control form-control-lg" name='expiry_date' value={profile.expiry_date} onChange={handleChange} />

</div>

</div>


<div className="col-md-4">
<div className="form-group">
<label className="col-form-label">Renew Date</label>

<input type="date" className="form-control form-control-lg"  name='renewed_date' value={profile.renewed_date} onChange={handleChange} />

</div>

</div>




<div className="col-md-4">
<div className="form-group">
<label className="col-form-label">Issuing Country</label>
<Select   options={country&&country.map((list:any, id:number)=> {
                                    return {key:id, value: list.name.common, label: list.name.common };
                                  })}
onChange={handleSelect}  name="country" value={{value: profile.country, label: profile.country}} styles={selectStyles} />  

</div>

</div>

</div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-default waves-effect " data-dismiss="modal" id='close'>Close</button>
                <button type="button" className="btn btn-primary waves-effect waves-light " onClick={handleSubmit}>Save Document</button>
            </div>
        </div>
    </div>
</div>





<div className="card-block">


<div className="table-responsive m-t-10">
    <TableData />

</div>

</div>
</div>
</div>
  )
}

export default Passport