import { useEffect, useState, FormEvent, ChangeEvent} from 'react'
import { COMPANY_CODE, ImagesUrl, STAFF_CODE, ServerUrl, config, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, closeModal, Alerts, SuccessModal } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'


import { useParams} from 'react-router'
import { useQuery } from 'react-query'
import Select from 'react-select'
import { FetchProfile, fetchCriteria, fetchDepartment} from '../../api/ReactQuery';
import "react-datepicker/dist/react-datepicker.css";
import { FormatNumber, getMonthYear } from '../../components/GlobalFunction';
import { Link } from 'react-router-dom';

const PreviewAppraisal =()=> {
    let params = useParams()

   
    const settings = useQuery(["profile"], FetchProfile)
    const [profile, setProfile] = useState([] as any);


    const {data} = useQuery(["criteria"], fetchCriteria)
    const [indicator, setIndicator] = useState([] as any);
    const [assessment, setAssessment] = useState([] as any);
    const [staff, setStaff] = useState([] as any);
    const [search, setSearch] = useState({
     
        staff:[] as any,
        appraisal_month:'',
        employee_comment:'',
        maximum:0,
        score:0,
        total_score:0,
        percentage:0
     })

     const [goals, setGoals] = useState(
        [{ 
            code:Math.random().toString(36).substring(2, 9),
            goal:'',
            period:''

        
     }]);


     const [development, setDevelopment] = useState(
        [{ 
            code:Math.random().toString(36).substring(2, 9),
            improvement:'',
            outcome:'',
            person:''

        
     }]);


         const fetchStaff =async()=>{
            let url = ServerUrl+'/api/employees/view/'+COMPANY_CODE
            const {data} = await axios.get(url, config)
            setStaff(data.data)
            }

       
        const getTitleName = (code:string, field:string) =>{ 
            const result = staff&&staff.filter((list:any)=>list.code===code);
           const answer = result.length !==0?result[0][field]:''
             return  answer;
           }

        const fetchReport =()=>{

            LoadingModal()
            
            let url = ServerUrl+'/api/performance/appraisal/show/'+params.code+'/'+COMPANY_CODE
            axios.get(url, config).then(result=>{
                
              if(result.data.status===200){
              
                try {
                    let indicator  = JSON.parse(result.data.data.indicator)
                    let development  = JSON.parse(result.data.data.development)
                    let assessment = JSON.parse(result.data.data.assessment)
                    let goals  = JSON.parse(result.data.data.goals)

                    setIndicator(indicator)
                    setDevelopment(development)
                    setAssessment(assessment)
                    setGoals(goals)
                    setSearch({...search,
                        percentage:result.data.data.percentage,
                        total_score:result.data.data.total_score,
                        maximum:result.data.data.maximum,
                        score:result.data.data.score,
                        employee_comment:result.data.data.employee_comment,
                        appraisal_month:result.data.data.appraisal_month,
                         staff:{value:result.data.data.staff_code, 
                            label:getTitleName(result.data.data.staff_code, 'first_name'),
                            last_name:getTitleName(result.data.data.staff_code, 'last_name')
                        
                        }})

                }catch(e){
                }
               
              }
            })
            .catch((error)=>{
              Alerts('danger', error.message)
            }).finally(()=>{
                closeModal()
            })
            }


          useEffect(()=>{
           fetchReport()
          }, [staff])



          useEffect(()=>{

            try{
         
             if (settings.data!==undefined && settings.data.length!==0){
             setProfile(settings.data)
                 
             }
         
            }catch(err) {
         
            }
             
             }, [settings.data]) 

             
          useEffect(()=>{
         
            fetchStaff() 
          }, [])
       
  return ( <>
   <Breadcrumb 
   title="Preview Appraisal"
   summary="View performance appraisal for employee"
   >
    <li className="breadcrumb-item">
    <Link to="/performance/manage_appraisal">Performance</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Employee Appraisal</a> </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
<div className="main-body">
<div className="page-wrapper">
<div className="page-body">






  <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-block">
    

    <div className="row">
        <div className="col-md-12 table-responsive ">
       
        <div className='text-center '>
       
<img 
src={ImagesUrl+'/about/'+profile.logo}  alt="Click to change" 
className="m-b-10 img-100" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/logo.png'}} />


<h4>{profile.company_name} </h4>
<h5>{profile.company_address}</h5>
<h6 className='m-b-5'>{profile.email}, {profile.phone}</h6>
                        <div className="alert alert-info text-center mb-1 mt-2">

                        <h5> {getMonthYear(search.appraisal_month)} PERFOMANCE APPRAISAL </h5>
                        </div>
            </div>



            <table className="table table-bordered table-sm ">
<thead>
          
<tr>

<td >Employee Name: <b>{ search.staff.label + ' ' + search.staff.last_name }</b></td>
    <td>Department: <b>{/* list.department_name */}</b></td>
</tr>
<tr>
    <td>Position: <b>{/* list.job_title */}</b></td>
    <td >Appraised By: <b>{/* list.account_number */}</b></td>
</tr>
</thead>
</table>


<h5 className='m-t-20 mb-20 text-muted' > <i> Using the following rating scale, please give a critical evaluation of the employee's performance throughout the review period. The appropriate instances, give illustrations. If necessary, please use a separate sheet.</i></h5>

            <table className="table table-bordered table-xs w-65">
<thead>
<tr>
    {indicator&&indicator.map((item:any, index:number)=><th key={index}>{item.indicator}</th>)}

</tr>


</thead>
<tbody>
<tr>
{indicator&&indicator.map((item:any, index:number)=><td key={index}>{item.title}</td>)}

</tr>
</tbody>
</table>

{assessment&&assessment.map((item:any, id:number)=><div key={id}>
<h5 className="mb-20 text-primary">{id+1}. {item.title.toUpperCase()}</h5>

<table className="table table-bordered table-sm table-css  ">
<thead>
<tr>
<th className='w-30'>Criteria</th>
{indicator&&indicator.map((item:any, index:number)=><th className='w-8 text-center' key={index}>{item.indicator} <br/> ({item.point})</th>)}
<th >Score</th>
<th className='w-20'>Comments</th>
</tr>
</thead>
<tbody>

     {item.criteria.map((data:any, key:number)=>
<tr key={key}>

<td  ><p >{data.title}</p>
</td>


{indicator&&indicator.map((itx:any, index:number)=>
<td className="text-center" key={index}> 
{data.indicator_point ===Number(itx.point)?data.indicator_point:''}</td>
)}



<td><b>{data.score}</b></td>
<td>{data.comment}</td>
</tr>)} 


<tr>
<td></td>
<td colSpan={5}>Total Score ( Maximum = {Number(search.maximum)* item.criteria.length} )</td>

<td colSpan={2}><b>{FormatNumber(item.criteria.reduce((acc:number, item:any)=>Number(item.score)+Number(acc), 0))}</b></td>
</tr>
</tbody>
</table>

</div>)}

<h5 className="mb-20 text-primary">OVERALL PERCENTAGE SCORE  : {search.percentage}%</h5>

<hr/>

<h5 className="mb-20 ">A. COMMENTS BY EMPLOYEE</h5>
<p>{search.employee_comment}</p>




<h5 className="mb-20 ">B. DEVELOPMENT PLAN</h5>

<table className="table table-bordered table-css table-sm" >
<thead>
<tr>
<th  className='w-30'> <p> Recommended Areas for Improvement / Development</p></th>
 <th  className='w-30'>Expected Outcome(s)</th>
 <th  className='w-20'> <p>Responsible Person(s) to assist in the achievement of the Plan</p></th>

</tr>
</thead>
<tbody>
{development.map((item:any, idx:number)=>
<tr key={idx}>
<td>{item.improvement}
</td>
<td>{item.outcome}
</td>
<td>
{item.person}
</td>
</tr>)}
</tbody>
</table>

<h5 className="mb-20 ">C. KEY GOALS FOR NEXT REVIEW PERIOD</h5>

<table className="table table-bordered table-sm" id="request_table">
<thead>
<tr>
<th>Goal (s) Set and Agreed on with Employee</th>
<th className='w-20'>Proposed Completion Period</th>
</tr>
</thead>
<tbody >

{goals.map((item:any, idx:number)=>
<tr key={idx}>

<td> {item.goal}  </td>
<td>{item.period}  </td>
</tr>)}
</tbody>
</table>

        </div>

        </div>
        <div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-success " type='button'  > <i className='fa fa-print'></i> Print</button>
</div>
</div>
            </div>
        </div>
    </div>
    </div>

</div>
</div>


</div>
</div>  
      </>               
 
  )
}

export default PreviewAppraisal