import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, ImagesUrl, STAFF_CODE, ServerUrl, config, selectStyles, styles } from '../../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal, CustomLoader, Alerts } from '../../../components/notify';
import Select from 'react-select'
import { fetchEmployee, fetchDepartment, fetchJobs, fetchSettings } from '../../../api/ReactQuery';
import { useQuery } from 'react-query'

import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'

const JobHistory =({code}:{code:any})=> {

    const [loading, setLoading] = useState(false);

    const settings = useQuery(["settings"], fetchSettings, {
        staleTime: 10 * (60 * 1000), // 10 mins
      })

      const [usergroup, setUsergroup]=useState([])
    const [station, setStation] = useState([] as any);
    const [disabled, setDisabled] = useState(false);
    const Jobs = useQuery(["jobs"], fetchJobs)
    const {data} = useQuery(["employee", code], fetchEmployee)
    const department = useQuery(["department"], fetchDepartment)
   
    const [openContract, setOpenContract] = useState(false);
    const [contract, setContract] = useState([] as any);

    const [photo, setPhoto] = useState('');
    const [job, setJob] = useState({
        title:'',
        letter_url:'',
        start_date:'',
        end_date:'',
        code:Math.random().toString(36).substring(2, 9),
        date: new Date()
    });
    const [content, setContent] = useState([] as any);

    const [errors, setErrors] = useState({
       
        department:'',
        job_title:'',
        employment_status:'',
        joined_date:'',
        work_station:'',
       
        title:'',
        letter_url:'',
        start_date:'',
        end_date:'',
        group_code:''
        
     })

     const handleChangeJob =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	
        setJob({...job, [name]:value});
       setErrors({...errors, [name]:''});
     }

     const handleSelectJob = (option:any, action:any)=>{

        setJob({...job, [action.name]:option.value});
       setErrors({...errors, [action.name]:''});
           }



           const  handleFile = function(fieldName:any){
            return function(newValue:any){                 
           readURL(newValue);
           }
          } 
        
         
        
        const readURL = (input:any)=>{
         
        let displayMessage = '';
        
        const PreviewImage = input.target.name;
        const inputElement = document.getElementById('photo') as HTMLInputElement
        const fileName = inputElement.value
        
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substring(idxDot, fileName.length).toLowerCase();
        
        /* if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){ */
            //TO DO    
            
             var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
        if (FileSize > 1) {
            displayMessage = ' File uploaded is larger than maximum size Allow (5 mb).';
            inputElement.value = '';
            setJob({...job, letter_url:''})
        } else {
            if (input.target.files && input.target.files[0]) { //Check if input has files.
                var reader = new FileReader();//Initialize FileReader.
                reader.onload = function (e:any) {
                    
                //Check if input has files.
                let Id = Math.random().toString(36).substr(2, 9)
                let fileUrl = Id+'_'+input.target.files[0].name
    
                let f_ext = fileName.substring(fileName.lastIndexOf('\\')+1);
    
                setJob({...job,  
                    letter_url:fileUrl
                })
                setPhoto(input.target.files[0])
                  setErrors({...errors, letter_url:''}); 
                }
                reader.readAsDataURL(input.target.files[0]);
                
            }
        }
        /* }else{
        displayMessage = 'Only JPEG|PNG|JPG  file format are allowed with maximum of 5 mb'
        inputElement.value = '';
        setItem({...item, file_url:'', file_name:''})
        }    */
        
        if(displayMessage.length > 0){
            setErrors({...errors, letter_url:displayMessage})
      //  ErrorModal("", displayMessage)
        }
        }
    
        

    const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	
      const newProfile = content.map((item:any)=>{
        return {...item, [name]:value}
      })
      setContent(newProfile)
       //setErrors({...errors, [name]:''});
     }


    const handleSelect = (option:any, action:any)=>{
        const newProfile = content.map((item:any)=>{
            return {...item, [action.name]:option.value}
          })
          setContent(newProfile)
         
           }


   
           const handleSave =(event: FormEvent<HTMLButtonElement>)=>{
            event.preventDefault();
        
            let error = {...errors}; 
            let formIsValid = true;
        
        let msg ='This field is required';
        
        if(!job.title){
            error.title =msg;
            formIsValid = false;
        } 
        
        
        if(!job.start_date){
            error.start_date =msg;
            formIsValid = false;
        } 
        
        if(!job.letter_url){
            error.letter_url =msg;
            formIsValid = false;
        } 
        
        
        if(!formIsValid){
        setErrors(error) 
        }
        
        
        if(formIsValid) {
        
            LoadingModal()
         const fd = new FormData();
        
        const details = contract.concat(job)
        const contract_details = JSON.stringify(details,null, 2)


        const log = JSON.parse(content[0].update_log)
        const item = log.concat( {
            code:STAFF_CODE,
            action:'Job Details: Added one job history',
            date_time: new Date()
        })

         const logs = JSON.stringify(item,null, 2)


         if(photo){
            fd.append('imageFile', photo, job.letter_url); 
        } 


        fd.append('id', content[0].id);
        fd.append('code', code);
        fd.append('logs', logs);
        fd.append('contract_details', contract_details);
        
           let url = ServerUrl+'/api/appointment/employee/'+code;
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status === 200){
             SuccessModal(response.data.message)
           
                      } else{
                        ErrorModal('', response.data.message)
                      }   
                  })
                  .catch((error)=>{
                   ErrorModal('oops', error.message)
                  }).finally(()=>{
                    window.location.reload()
                    setJob({
                        title:'',
                        letter_url:'',
                        start_date:'',
                        end_date:'',
                        code:Math.random().toString(36).substring(2, 9),
                        date: new Date()
                    })

                    let inputElement = document.getElementById('photo') as HTMLInputElement
                    inputElement.value = ''
                  }) 
            }
        
        }


   
const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!content[0].department){
    error.department =msg;
    formIsValid = false;
} 


if(!content[0].employment_status){
    error.employment_status =msg;
    formIsValid = false;
} 

if(!content[0].joined_date){
    error.joined_date =msg;
    formIsValid = false;
} 

if(!content[0].job_title){
    error.job_title =msg;
    formIsValid = false;
} 

if(!content[0].work_station){
    error.work_station =msg;
    formIsValid = false;
}

if(!content[0].group_code){
    error.group_code =msg;
    formIsValid = false;
}


if(!formIsValid){
setErrors(error) 
}


if(formIsValid) {

    LoadingModal()
 const fd = new FormData();
    Object.entries(content[0]).forEach(([key, value]) => {
            fd.append(key, String(value));
        });

        const log = JSON.parse(content[0].update_log)
        const item = log.concat( {
            code:STAFF_CODE,
            action:'Updated Job details',
            date_time: new Date()
        })


const logs = JSON.stringify(item,null, 2)

        fd.append('logs', logs);
       

   let url = ServerUrl+'/api/employee/update/'+code;
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                ErrorModal('', response.data.message)
              }   
          })
          .catch((error)=>{

            Alerts('danger',error.message)
          }) 
    }

}

const getFieldName =(table:any, field:string, code:string)=>{

    if(Array.isArray(table)){
    const rs = table&&table.filter((item:any)=>item.code===code)
    return rs.length!==0?rs[0][field]:''
    }else{
        return ''
    }
   
 }

 

const DeleteModal =(code:string)=>{
    
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading(),
    confirmButtonText: 'Yes, I am!',

    preConfirm: async() => {

const newContact = contract.filter((item:any)=>item.code !==code)



const log = JSON.parse(content[0].update_log)
const item = log.concat( {
    code:STAFF_CODE,
    action:'Job: Removed one appointment',
    date_time: new Date()
})

const logs = JSON.stringify(item,null, 2)
const newProfile = content.map((item:any)=>{
    return {...item, 
        contract_details:JSON.stringify(newContact,null, 2)
    }
  })
  
  setContent(newProfile)
  setContract(newContact)

      const fd = new FormData();
fd.append('logs', logs);
Object.entries(newProfile[0]).forEach(([key, value]) => {
                    fd.append(key, String(value));
                }); 

let url = ServerUrl+'/api/employee/update/'+code;
return await axios.post(url, fd, config)
.then(response =>{

if(response.data.status !== 200){
    throw new Error(response.statusText)

      } else{
        return response.data
      }   
  })
  .catch((error)=>{
    Swal.showValidationMessage(
        `Request failed: ${error}`
      )
  })
    }
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }


 const TableData =()=>{

           
    const columns = [
        {
            name: 'Title',
            cell: (row:any) => getFieldName(Jobs.data, 'title', row.title), 
            sortable: true,
        },
        {
            name: 'Duration',
            selector: (row:any) => row.start_date + ' to '+ row.end_date,
            sortable: true,
        },
       
        {
            name: 'Action',
            cell: (row:any, index:any, column:any, id:any) => <div className='row'>  
            <a href={ImagesUrl+"/employees/letters/"+row.letter_url} target='_blank' className="label label-primary pointer"> <i className='ti-link'></i> View</a>

            <label className="label label-danger pointer" onClick={()=>DeleteModal(row.code)}><i className='ti-trash'></i> Delete</label></div>,
                left:true
                      

        },
    ];
    

  

    return <DataTable

    title='All Job History'
    columns={columns}
    data={contract}
    dense
    highlightOnHover
    pagination
    selectableRowsHighlight
    striped
/>

}


const fetchGroup = async()=>{
    setLoading(true)
    let url = ServerUrl+'/api/setup/group/'+COMPANY_CODE
  await  axios.get(url, config).then((result:any)=>{
    if(Array.isArray(result.data.data) && result.data.data.length!==0){
      setUsergroup(result.data.data)
      }
    })
    .catch((error)=>{
        Alerts('danger', error.message)
    })
    } 


useEffect(()=>{

    try{
     if (settings!==undefined && settings.data.length!==0){
     setStation(JSON.parse(settings.data[0].contacts))
     }
 
    }catch(err) {
 
    }
     
     }, [settings.data]) 


 useEffect(()=>{

    try{
 
     if (data!==undefined && data.length!==0){
     setContent(data)
     setContract(JSON.parse(data[0].contract_details))
     window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
         
     }
 
    }catch(err) {
 
    }
     
     }, [data])


     useEffect(()=>{
        fetchGroup()
     },[])

  return (
   

<div>
  <div className="card z-depth-0">

<div className="card-header">
    <h5>Job Details</h5>

</div>


{content.length!==0? content.map((profile:any, index:number)=>  <div key={index}  className="card-block">


<div className="row">
        
        <div className="col-md-4">
         <div className="form-group">
        <label className="col-form-label">Joined Date <span className='text-danger'>*</span></label>
       
           
            <input type="date" className="form-control form-control-lg"  name='joined_date' value={profile.joined_date} onChange={handleChange} disabled={disabled} />

         </div>
         </div>
     

         <div className="col-md-4">
         <div className="form-group">
        <label className="col-form-label">Confirmation Date </label>
       
        <input type="date" className="form-control form-control-lg"  name='confirmation_date' value={profile.confirmation_date} onChange={handleChange} disabled={disabled} />
      
         </div>
         </div>



         <div className="col-md-4">
         <div className="form-group">
        <label className="col-form-label">Date of Leaving</label>
       
        <input type="date" className="form-control form-control-lg" disabled={disabled}  name='leaving_date' value={profile.leaving_date} onChange={handleChange} />
         </div>
         </div>

         </div>

         <hr/>
         <div className="row">
         <div className="col-md-6">
         <div className="form-group">
        <label className="col-form-label">Current Department/Unit <span className='text-danger'>*</span></label>
       
           
    <Select  options={
            department.data&&department.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.department_name}
                    })}   
                    isDisabled={disabled}
			onChange={handleSelect} className={errors.department?"form-control form-control-danger":""}   
            name="department" 
            value={{label:getFieldName(department.data, 'department_name', profile.department), value:profile.department}} 
            styles={styles}
               />

{errors.department&&
    <div className="col-form-label has-danger">{errors.department&&errors.department} </div>}
      
         </div>

         </div>
         <div className="col-md-6">
         <div className="form-group">
        <label className="col-form-label">Employment Status <span className='text-danger'>*</span></label>
       
           
<Select  options={[
                    { value: 'Full-Time Contract', label: 'Full-Time Contract' },
                    { value: 'Full-Time Permanent', label: 'Full-Time Permanent'},
                    { value: 'Full-Time Probation', label: 'Full-Time Probation'},

                    { value: 'Part-Time Contract', label: 'Part-Time Contract' },
                    { value: 'Part-Time Internship', label: 'Part-Time Internship'},
                    { value: 'Freelance', label: 'Freelance'},
                    { value: 'Volunteer', label: 'Volunteer'}
                                ]} 
                       
                                isDisabled={disabled}
            className={errors.employment_status?"form-control form-control-danger":""}
                                onChange={handleSelect}  name="employment_status"
                        value={{value: profile.employment_status, label: profile.employment_status}} 
                        styles={styles}
                 />

{errors.employment_status&&
    <div className="col-form-label has-danger">{errors.employment_status&&errors.employment_status} </div>}
         </div>

         </div>


         <div className="col-md-6">
         <div className="form-group">
        <label className="col-form-label">Job Title <span className='text-danger'>*</span></label>
       
           
    <Select options={
            Jobs.data&&Jobs.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.title}
                    })}   
                    isDisabled={disabled}
			onChange={handleSelect} 
            className={errors.job_title?"form-control form-control-danger":""} 
            name="job_title" value={{label:getFieldName(Jobs.data, 'title', profile.job_title), value:profile.job_title}} 
            styles={styles}
              />
    
{errors.job_title&&
    <div className="col-form-label has-danger">{errors.job_title&&errors.job_title} </div>}
         </div>

         </div>



         <div className="col-md-6">
         <div className="form-group">
        <label className="col-form-label">Access Group</label>
       

    <Select  options={ usergroup&&usergroup.map((list:any, idx:number)=> {
                               return {key:idx, value: list.code, label: list.group_name}
                             })}   
                    isDisabled={disabled}
                    styles={styles}
			onChange={handleSelect} className={errors.group_code?"form-control form-control-danger":""}   name="group_code" value={{label:getFieldName(usergroup, 'group_name', profile.group_code), value:profile.group_code}}   />

{errors.group_code&&
    <div className="col-form-label has-danger">{errors.group_code&&errors.group_code} </div>}

    

      
         </div>

         </div>


         <div className="col-md-6">
         <div className="form-group">
        <label className="col-form-label">Work Station</label>
       
           


    <Select  options={
            station&&station.map((list:any, id:number)=>{
                return {key:id, value:list.location.toUpperCase(), label:list.location.toUpperCase()}
                    })}   
                    isDisabled={disabled}
                    styles={styles}
			onChange={handleSelect} className={errors.work_station?"form-control form-control-danger":""}   name="work_station" value={{label:profile.work_station, value:profile.work_station}}   />

{errors.work_station&&
    <div className="col-form-label has-danger">{errors.work_station&&errors.work_station} </div>}

    

      
         </div>

         </div>

        </div><hr/>


        <div className="row">
<div className="col-md-12 text-right">
<button className="btn btn-success btn-round" type='button' onClick={handleSubmit}>Save Details</button>
</div>
</div>



<div className="row">
<div className="col-md-6">

<div className="d-flex justify-content-start">
<label className="sub-titles">
Add Job History
</label>
<label className="switch ml-3">
<input type="checkbox" name='openContract' checked={openContract} onChange={()=>setOpenContract(!openContract)} />

  <span className="slider round"></span>
</label>
</div>

</div>
</div>

{openContract?
<div  >

<div className="row "style={{backgroundColor: 'rgba(68, 138, 255, 0.1)'}} >
        

<div className="col-md-6">
         <div className="form-group">
        <label className="col-form-label">Job Title <span className='text-danger'>*</span></label>
       
           
    <Select options={
            Jobs.data&&Jobs.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.title}
                    })}   
                    isDisabled={disabled}
			onChange={handleSelectJob} 
            className={errors.title?"form-control form-control-danger":""} 
            name="title" value={{label:getFieldName(Jobs.data, 'title', job.title), value:job.title}} 
              
              />
    
{errors.title&&
    <div className="col-form-label has-danger">{errors.title&&errors.title} </div>}
         </div>

         </div>

<div className="col-md-6">
         <div className="form-group">
        <label className="col-form-label">Appointment Letter <span className='text-danger'>*</span></label>
       
            <input type="file" className={errors.letter_url?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} id="photo" onChange={handleFile('photo')}  name="photo"  />
                <div className="col-form-label has-danger"> {errors.letter_url&&errors.letter_url}</div>

      <span>Accepts upto 1MB</span>
         </div>

         </div>


        <div className="col-md-6">
         <div className="form-group">
        <label className="col-form-label">Job Start Date <span className='text-danger'>*</span></label>
       
            <input type="date" className={errors.start_date?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} name='start_date' value={job.start_date} onChange={handleChangeJob} />
      
         </div>


         </div>
     


         <div className="col-md-6">
         <div className="form-group">
        <label className="col-form-label">Job End Date</label>
       
            <input type="date" className="form-control form-control-lg" name='end_date' value={job.end_date} onChange={handleChangeJob} />
      
         </div>

         </div>
         




         <div className="col-md-12 m-b-10 text-right">
<button className="btn btn-inverse btn-sm" type='button' onClick={handleSave}>Save File</button>
</div>


        </div>

     
        <div className="table-responsive m-t-10">

            <TableData />


                    </div>


</div>:[]}

        </div>):''}
            </div>

                                </div>

  )
}

export default JobHistory