import { useEffect, useState} from 'react'
import { COMPANY_CODE, ServerUrl, config } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, CustomLoader, closeModal, Alerts } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'

import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';

const ViewLeaveTemplate =()=> {

    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);



    const DeleteModal =(row:any)=>{
        let url = ServerUrl+'/api/leave/report/remove/'+row.id
      
        Swal.fire({
          title: 'Are you really sure?',
          text: "This is very dangerous, you shouldn't do it!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          showLoaderOnConfirm: true,
        preConfirm: async() => {
          return await axios.get(url, config).then(response=>{
           if(response.data.status!==200){
                throw new Error(response.statusText)
              }
              return response.data
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Request failed: ${error}`
              )
            })
        },
        allowOutsideClick: () => !Swal.isLoading(),
          confirmButtonText: 'Yes, I am!'
        }).then((result:any) => {
          if (result.isConfirmed && result.value.status===200) {
           
            let rs =content.filter((item:{id:number})=>item.id !==row.id)
                setContent(rs)
             Swal.fire(
                        'Deleted!',
                        'Record has been deleted.',
                        'success'
                      ) 
          }
        })
      }

const TableData =()=>{


    const columns = [
        {
            name: 'Report Title',
            selector: (row:any) => row.title,
            sortable: true,
            wrap:true
        },
       
        {
            name: 'Action',
            cell: (row:any, index:any, column:any, id:any) => <div className='row'> 





            <label className="label label-danger pointer" onClick={()=>DeleteModal(row)}><i className='ti-trash'></i> Delete</label>

            <a href={'/leave/report/template/edit/'+row.code} className="label label-info pointer"  ><i className='ti-pencil'></i> Edit</a>

            <a href={'/leave/report/preview/'+row.code} target='_blank' className="label label-success"  ><i className='ti-file'></i> Preview</a>

            
            </div>,
                left:true
                      
        },
    ];
    
  

    return <DataTable
    columns={columns}
    data={content}
    dense
    highlightOnHover
    pagination
    selectableRowsHighlight
    striped
    progressPending={loading}
	progressComponent={<CustomLoader />}
/>

}

        const fetchReport =()=>{

            LoadingModal()
            setLoading(true)
            let url = ServerUrl+'/api/leave/report/view/'+COMPANY_CODE
            axios.get(url, config).then(result=>{
              if(result.data.status===200){
                setContent(result.data.data)
             
              }
            })
            .catch((error)=>{
              Alerts('danger', error.message)
            }).finally(()=>{
                closeModal()
                setLoading(false)
            })
            }


            useEffect(()=>{
                fetchReport()
            },[])

  return ( <>
   <Breadcrumb 
   title="View Template"
   summary="Select any of the report to view"
   >
    <li className="breadcrumb-item">
    <Link to="#!">Report</Link>
                    </li>
   <li className="breadcrumb-item">
    <Link to="#!">View Template</Link>
                    </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
<div className="main-body">
<div className="page-wrapper">
<div className="page-body">



<div className="row">
<div className=" col-md-12">
<div className="card z-depth-0">
    <div className="card-header">
        <h5>Leave Report Templates</h5>
        <span className="text-muted">Description on  <a href="#!" target="_blank">amCharts</a> how and what this page does here.</span>
       
       
       
        <div className="card-header-right">

<a href='/leave/report/create' className="btn btn-primary  "><i className='fa fa-circle-plus text-white'></i> Add New</a>
            
        </div>
    </div>
    <div className="card-block ">
       


<div className="table-responsive m-t-10">
    <TableData />
    </div>


    </div>
</div>
</div>
</div>


</div>
</div>


</div>
</div>  
        </>               
 
  )
}

export default ViewLeaveTemplate