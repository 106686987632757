import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, ImagesUrl, STAFF_CODE, ServerUrl, config, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'


import { useQuery } from 'react-query'
import Select from 'react-select'
import { fetchDepartment} from '../../api/ReactQuery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertDate, convertDateTime, convertTime, getTime, shortText } from '../../components/GlobalFunction';
import { Link } from 'react-router-dom';

const MarkAttendance =()=> {

    const [content, setContent] = useState([] as any);
    const department = useQuery(["department"], fetchDepartment)
    const [search, setSearch] = useState({
        time_in:'',
        time_out:'',
        department:[] as any,
        attendance_date:new Date(),
     })

     const isWeekday = (date:any) => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
      };

           const [errors, setErrors] = useState({
            time_in:'',
            time_out:'',
            attendance_date:'',
           
         })


         const handleSelect = (option:any, action:any)=>{
            setSearch({...search, [action.name]:option});
              setErrors({...errors, [action.name]:''})
              setContent([])
    
               }


         const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
            setSearch({...search, [name]:value});
            setContent([])
           setErrors({...errors, [name]:''});
         }

         const handleChangeDate = (data:any, name:string)=>{
            fetchHoliday(data)
            setContent([])
         }
         const handleChangeTime=(e:ChangeEvent<HTMLInputElement>, index:number)=>{
              const std =JSON.parse(JSON.stringify(content))  
             std[index][e.target.name] = e.target.value
             setContent(std);
         }


         const handleMarkAttendance=(code:string)=>{


            const newContent = content.map((item:any)=>{
                if(item.code === code){
                    return {...item, 
                        attendance:item.attendance ==='P'?'H':item.attendance ==='H'?'A':item.attendance ==='A'?'P':'P'
                    }
                }
                return item
                
              })
           setContent(newContent); 


        }

    const handleMarkAll=()=>{
        var options = content;
                var value = [];
                for (var i = 0, l = options.length; i < l; i++) {
                  value.push({
                    ...options[i],
                    
                    attendance:options[i].attendance ==='P'?'H':options[i].attendance ==='H'?'A':options[i].attendance ==='A'?'P':'P'});
                }
           setContent(value);
    }
       




         const fetchSettings = async()=>{
            LoadingModal()
            let url = ServerUrl+'/api/attendance/settings/view/'+COMPANY_CODE
          await  axios.get(url, config).then((result:any)=>{
            if(Array.isArray(result.data.data) && result.data.data.length!==0){
               
              let absent:any = new Date(result.data.data[0].auto_absent)
            
              setSearch({
                ...search,
                time_out:result.data.data[0].time_out,
                time_in:result.data.data[0].time_in,
              })  
              }
            })
            .catch((error)=>{
                Alerts('danger', error.message)
            }).finally(()=>{
                closeModal()
            })
            } 






         const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
            event.preventDefault();
        



            let error = {...errors}; 
            let formIsValid = true;
        
        let msg ='This field is required';
        
        if(!search.time_in){
            error.time_in =msg;
            formIsValid = false;
        } 
        
        
        if(!search.time_out){
            error.time_out =msg;
            formIsValid = false;
        } 
        
        if(!search.attendance_date){
            error.attendance_date =msg;
            formIsValid = false;
        } 
        
        
        
        if(!formIsValid){
        setErrors(error) 
        }
        
        if(formIsValid) {
        
            LoadingModal()
         const fd = new FormData();
          
                 fd.append('company_code', COMPANY_CODE);
                fd.append('code', 'AT'+Math.random().toString(36).substring(2, 9)); 
                fd.append('employee_list', JSON.stringify(content, null, 2));
                 fd.append('added_by', STAFF_CODE);

        let url = ServerUrl+'/api/attendance/create_attendance';
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status === 200){
            // SuccessModal(response.data.message)

             Alerts('default',response.data.message)
             setContent([])
           
                      } else{
                        Alerts('danger',response.data.message)
                        
                      }   
                  })
                  .catch((error)=>{
                    Alerts('danger',error.message)
              
                  }).finally(()=>{
                    closeModal()
                  })
            }
        
        }



        const fetchHoliday= async(date:any)=>{
            LoadingModal()
     
            let sql = 'Select start_date from tbl_holidays where event_type= "Holiday" and start_date = "'+convertDate(date)+'"'
                 
                 const fd = new FormData();
                 fd.append('sql', sql); 

                 let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
                
               await  axios.post(url, fd, config).then((result:any)=>{
           
                 if(Array.isArray(result.data.data) && result.data.data.length!==0){
                    
                  
                    setSearch({...search, attendance_date:new Date()});
                    setErrors({...errors, attendance_date:'Selected date is mark as an Holiday'});

                   }else{
                    setSearch({...search, attendance_date:date});
                    setErrors({...errors, attendance_date:''});
                   }
                 })
                 .catch((error)=>{
                     Alerts('danger',error.message)
                 }).finally(()=>{
                     closeModal()
                 })
                 } 



               

const fetchContent = async()=>{


    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';
              

        if(!search.time_in){
            error.time_in=msg;
            formIsValid = false;
        } 

        if(!search.time_out){
            error.time_out=msg;
            formIsValid = false;
        } 

        if(!search.attendance_date){
            error.attendance_date =msg;
            formIsValid = false;
        } 


      

        if(!formIsValid){
        setErrors(error) 
        }else{
    LoadingModal()
 
          let sql = " SELECT  e.code, e.passport,  e.first_name, e.gender, e.last_name,  e.file_number,  j.title as job_title, d.department_name from tbl_employee e left join tbl_job_title j on j.code = e.job_title  left JOIN tbl_departments d on d.code = e.department WHERE  e.termination_reason = '' "
 
 
              if(search.department.length!==0){
                  sql = sql + " and e.department = '"+search.department.value+"'"
              }
 
          const fd = new FormData();
          fd.append('sql', sql); 
          let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
        await  axios.post(url, fd, config).then((result:any)=>{
          if(Array.isArray(result.data.data) && result.data.data.length!==0){
              
            var options = result.data.data;
            var value = [];
            for (var i = 0, l = options.length; i < l; i++) {
              value.push({
                code:options[i].code, 
                unique_code:Math.random().toString(36).substring(2, 9),
                staff_name:options[i].first_name + ' '+ options[i].last_name, job_title:options[i].job_title, 
                gender:options[i].gender, 
                passport:options[i].passport, 
                time_in: search.time_in, 
                time_out:search.time_out, 
                attendance_date:convertDate(search.attendance_date),
                attendance:'P',
                staff_code:options[i].code
            });
            }

            setContent(value)
            }
          })
          .catch((error)=>{
              Alerts('danger',error.message)
          }).finally(()=>{
              closeModal()
          })
        }
          } 


          
useEffect(()=>{
    fetchSettings()
}, [])

  return ( <>
  <Breadcrumb 
   title="Mark Attendance"
   summary="Record Employee Attendance"
   >
    <li className="breadcrumb-item">
    <Link to="/attendance/display_attendance">Attendance</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Mark Attendance</a> </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
<div className="main-body">
<div className="page-wrapper">
<div className="page-body">


<div className="row">
<div className=" col-md-12">
<div className="card z-depth-0">
    <div className="card-header">
        <h5>CREATE ATTENDANCE</h5>
        <span  ><a href="#"> Click here to modify public holidays</a></span>
        <div className="card-header-right">

            <ul className="list-unstyled card-option">
                <li><i className="fa fa fa-wrench open-card-option"></i></li>
                <li><i className="fa fa-window-maximize full-card"></i></li>
                <li><i className="fa fa-minus minimize-card"></i></li>
                <li><i className="fa fa-refresh reload-card"></i></li>
                <li><i className="fa fa-trash close-card"></i></li>
            </ul>
            
        </div>
    </div>
    <div className="card-block ">
       


        <div className="row">
        <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Search by Department</label>
    
    
    <Select  options={
            department.data&&department.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.department_name}
                    })}   
                    styles={styles} 
			onChange={handleSelect}    
            name="department" 
            value={search.department} 
               />
    
    </div>
    
    </div>



    <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Attendnace Date </label>
           
            <DatePicker 
            selected={search.attendance_date}      
             isClearable
             dateFormat="yyyy-MM-dd"
             filterDate={isWeekday}
             className={errors.attendance_date?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}
             placeholderText="Select date"
           
 onChange={(date:any) => handleChangeDate(date, 'attendnace_date')} />


        
{errors.attendance_date&&
    <div className="col-form-label has-danger">{errors.attendance_date&&errors.attendance_date} </div>}
             </div>

             </div>



        <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Time IN <span className='text-danger'>*</span></label>
           

            <input type="time"  className={errors.time_in?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='time_in' value={search.time_in} onChange={handleChange}  />


            {errors.time_in&&
    <div className="col-form-label has-danger">{errors.time_in&&errors.time_in} </div>}
             </div>

             </div>


             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Time OUT <span className='text-danger'>*</span></label>
           

            <input type="time"  className={errors.time_out?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='time_out' value={search.time_out} onChange={handleChange}  />

            {errors.time_out&&
    <div className="col-form-label has-danger">{errors.time_out&&errors.time_out} </div>}
             </div>

             </div>


          
    


        </div>


<hr/>
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={fetchContent}>Create Attendnace</button>
</div>
</div>





    </div>
</div>
</div>
</div>

{content&&content.length!==0?<div>
<div className="row">
<div className=" col-md-12">
<div className="card z-depth-0">
   

<section className="col-md-10 m-b-10 m-t-10">
        <span className="label bg-danger">Color Icon For Absent</span>
        <span className=" label label-primary">Color Icon For Half Day</span>
        <span className=" label bg-success">Color Icon For Present</span>
        </section>

    <div className="card-block" style={{backgroundColor: 'rgba(68, 138, 255, 0.1)'}}>


    <div className="row" >
        {content&&content.map((stf:any, id:number)=>
        <section key={id} className="col-md-3">

            <button type="button" onClick={()=>handleMarkAttendance(stf.code)} 
            className={stf.attendance==='P'?'btn btn-success':stf.attendance==='A'?'btn btn-danger':stf.attendance==='H'?'btn btn-primary':'btn btn-default'} style={{width:'100%'}}> 


            <img className="img-100" style={{height:'100px', width:'100px'}} onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src=ImagesUrl+'/'+stf.gender+'.png'}} src={`${ImagesUrl}/employees/passport/${stf.passport}`}  alt="" />

            <div ><b>{shortText(stf.staff_name, 20)}</b></div>
            <label>({stf.job_title})</label>
            <div>Office Hours</div>
            
<div style={{width:'50%', textAlign:'left', float:'left', fontSize:'13px'}}>From <br/>{getTime(stf.time_in)}<br/>Time In</div>

<div style={{width:'50%', textAlign:'right', float:'right', fontSize:'13px'}}>To <br/>{getTime(stf.time_out)} <br/> Time Out</div>
            


</button>
                <div className="input-group" style={{width:'100%'}}>
                    <input type="time" name="time_in" onChange={(e)=>handleChangeTime(e, id)}  className='text-left form-controls'  style={{height: '30px'}} value={stf.time_in}  />&nbsp;&nbsp;&nbsp;&nbsp;

                    <input type="time" name="time_out" onChange={(e)=>handleChangeTime(e, id)} style={{height: '30px'}} className='text-right form-controls'  value={stf.time_out}   />
                </div>
            </section>
            
)}
     
</div>



    </div>
</div>
</div>
</div>

<div className="row">
        <div className="col-md-12 ">
	
	<button type="button"  onClick={handleMarkAll} className="btn btn-inverse "><i className="fa fa-edit"></i> PresentAll/HalfdayAll/AbsentAll</button>&nbsp;&nbsp;
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-success "><i className="fa fa-save"></i> Save Attendance</button>
								</div>
				</div>

                </div>:[]}
</div>
</div>


</div>
</div>  
      </>               
 
  )
}

export default MarkAttendance