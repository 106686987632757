import { useState, ChangeEvent, FormEvent, useEffect} from 'react'
import { COMPANY_CODE, STAFF_CODE, ServerUrl, config, selectStyles, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'
import { useQuery } from 'react-query'

import { fetchDepartment, fetchJobs } from '../../api/ReactQuery';
import { Link } from 'react-router-dom';

const CreateLeaveReport =()=> {

 
    const department = useQuery(["department"], fetchDepartment)
    const Jobs = useQuery(["jobs"], fetchJobs)

    const [loading, setLoading] = useState(false);
    const [criteria, setCriteria] =useState([] as any);
    const [group, setGroup] =useState([] as any);
    const [content, setContent] = useState([] as any);
    const [template, setTemplate] = useState([] as any);
    const [errors, setErrors] = useState({
        search_paramter:'',
        field_group:'',
        title:'',
        includes:'',
        start_date:'',
        end_date:'',
        leave_type:'',
        application_status:'',
        employee_name:'',
        department:'',
        job_title:'',
       
     })
   

     const item_list = [
        "Employee Name",
        "Start Date",
        "End Date",
        "Job Title",
        "Leave Type",
        "Application Status",
        "Department",
        
     ]



     const field_group = [
        { index:1, isSelected:false, title:'Personal Details', value:'Personal', section:[
            {isSelected:false, value:'title', label:'Title'},
            {isSelected:false, value:'first_name', label:'First Name'},
            {isSelected:false, value:'last_name', label:'Last Name'},
            {isSelected:false, value:'middle_name', label:'Middle Name'},
            {isSelected:false, value:'nickname', label:'Nickname'},
            {isSelected:false, value:'file_number', label:'Employee File Number'},
            {isSelected:false, value:'passport_no', label:'Passport ID'},
            {isSelected:false, value:'nin', label:'NIN'},

            {isSelected:false, value:'birth_cert', label:'Birth Certificate No.'},
            {isSelected:false, value:'driver_license', label:'Driving License No'},
            {isSelected:false, value:'other_id', label:'Other ID'},
            {isSelected:false, value:'gender', label:'Gender'},
            {isSelected:false, value:'is_smoker', label:'Is Smoker'},
            {isSelected:false, value:'tribe', label:'Tribe'},
            {isSelected:false, value:'marital_status', label:'Marital Status'},
            {isSelected:false, value:'religion', label:'Religion'},
            {isSelected:false, value:'date_of_birth', label:'Date of Birth'},
            {isSelected:false, value:'language', label:'Language'},
            {isSelected:false, value:'blood_group', label:'Blood Group'},
            {isSelected:false, value:'place_of_birth', label:'Place of Birth'},
            
        ]},
        { index:2, isSelected:false, title:'Leave Details', value:'Leave',  section:[
            {isSelected:false, value:'no_days', label:'No Days'},
            {isSelected:false, value:'leave_code', label:'Leave Name'},
            {isSelected:false, value:'time_cycle', label:'Time Cycle'},
            {isSelected:false, value:'start_date', label:'Start Date'},
            {isSelected:false, value:'end_date', label:'End Date'},
            {isSelected:false, value:'reason', label:'Reason'},
            {isSelected:false, value:'created_at', label:'Date Applied'},
            {isSelected:false, value:'is_approve', label:'Status'},
            {isSelected:false, value:'approve_by', label:'Approved By'},
            {isSelected:false, value:'added_by', label:'Created By'},
            {isSelected:false, value:'current_year', label:'Year Applied'},
            
            
        ]}

     ]

     const [item, setItem] = useState(item_list.sort());
     const [item_group, setItem_group] = useState(field_group.sort((a:any, b:any)=>a.index > b.index?1:-1));
    const [report, setReport] = useState({
        title:'',
        search_paramter:{value:'', label:''},
        includes: { value: 'All', label: 'All Employees'},

        field_group:{value:'', label:'', section:[]},
        display_filed:[],
     })

     const fetchLeave = async()=>{
        let url = ServerUrl+'/api/leave/view_leave_type/'+COMPANY_CODE
      await  axios.get(url, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){
          setTemplate(result.data.data)
          }
        })
        .catch((error)=>{
            Alerts('danger', error.message)
        })
        } 
    
const handleResetReport =()=>{

    setReport({
        title:'',
        search_paramter:{value:'', label:''},
        includes:  { value: 'All', label: 'All Employees'},

        field_group:{value:'', label:'', section:[]},
        display_filed:[],
     })

     setItem(item_list.sort())
     setItem_group(field_group.sort((a:any, b:any)=>a.index > b.index?1:-1))
     setContent([])
     setGroup([])
     setCriteria([])
}

     const handleAdd =()=>{
        if(report.search_paramter.value!==''){
        let newCriteria = criteria.concat({
            code:Math.random().toString(36).substring(2, 9),
            field_name:report.search_paramter.value,
            field_value:'',
            data:''
        })
        setCriteria(newCriteria.sort())

        setReport({...report,
            search_paramter:{value:'', label:''},
        })
        setErrors({...errors, search_paramter:''})
    }else{
        setErrors({...errors, search_paramter:'This field is required'})
    }
    }

    const handleRemove =(code:string, list:string)=>{
        let newCriteria = criteria.filter((item:any)=>item.code!==code)
        let new_item= item.concat(list)
        setItem(new_item.sort())
        setCriteria(newCriteria)
    }



 const handleAddGroup =()=>{
        if(report.field_group.value!==''){
            let newGroup =[]
            if(report.display_filed.length!==0){

            newGroup = group.concat({
            code:Math.random().toString(36).substring(2, 9),
            field_name:report.field_group.label,
            field_value:report.field_group.value,
            data:report.display_filed,
            isHeader:true,
        })
    }else{
        newGroup = group.concat({
            code:Math.random().toString(36).substring(2, 9),
            field_name:report.field_group.label,
            field_value:report.field_group.value,
            data:report.field_group.section,
            isHeader:true,
    })

}

        setGroup(newGroup.sort())

        setReport({...report,
            field_group:{value:'', label:'', section:[]},
            display_filed:[]
        })
 let newItem = item_group.filter((list:any)=>list.value!==report.field_group.value)
        //set field_group to selected
        setItem_group(newItem.sort((a:any, b:any)=>a.index > b.index?1:-1))
        setContent([])
        setErrors({...errors, field_group:''})
    }else{
        setErrors({...errors, field_group:'This field is required'})
    }

    
    }

    const handleRemoveField =(list:any, value:string)=>{

        const newGroup = group.map((item:any)=>{
            if(item.code === list.code){
                return {...item, 
                    data:item.data.filter((dt:any)=>dt.value!==value),
                }
                };
                return item;
          })
    
          setGroup(newGroup)
    }

const handleHeaderChange =(list:any)=>{
    const newGroup = group.map((item:any)=>{
        if(item.code === list.code){
            return {...item, 
                isHeader:!item.isHeader,
            }
            };
            return item;
      })

      setGroup(newGroup)

}


    const handleRemoveGroup =(code:string, value:string)=>{
        let newGroup = group.filter((item:any)=>item.code!==code)
        let ls = field_group.filter((data:any)=>data.value===value)
       let newItem= item_group.concat(ls)
       setItem_group(newItem.sort((a:any, b:any)=>a.index > b.index?1:-1))
        setGroup(newGroup)
    }



    const handleChangeCriteria =(event:ChangeEvent<HTMLInputElement>, field:string)=>{
        let {name, value} = event.target;	

        const newCriteria = criteria.map((item:any)=>{

            if(name === item.code){
                return {...item, field_value:value}
            }
            return item
          })

          setCriteria(newCriteria)
          setErrors({...errors, [field]:''})
       
     }



    const handleSelectCriteria = (option:any, action:any, name:any)=>{

        const newCriteria = criteria.map((item:any)=>{

            if(action.name === item.code){
                return {...item, data:option.value}
            }
            return item
          })

          setCriteria(newCriteria)
         setErrors({...errors, [name]:''})
           }


           const handleSelectIt = (option:any, action:any)=>{
            setReport({...report, [action.name]:option});
            setErrors({...errors, [action.name]:''})
    
               }


            const handleSelectField = (option:any, action:any)=>{
        setReport({...report, [action.name]:option, display_filed:[]});
        setErrors({...errors, [action.name]:''})

      setContent(option.section)
           }


     const handleSelect = (option:any, action:any)=>{
        let new_item = item.filter((list:any)=>list!==option.value)
        setReport({...report, [action.name]:option});
        setErrors({...errors, [action.name]:''})
        setItem(new_item)
           }




           const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
            setReport({...report, [name]:value});
            setErrors({...errors, [name]:''})
         }

       
         const getFieldName =(table:any, field:string, code:string)=>{

            if(Array.isArray(table)){
            const rs = table&&table.filter((item:any)=>item.code===code)
            return rs.length!==0?rs[0][field]:''
            }else{
                return ''
            }
           
         }



         const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
            event.preventDefault();
            let error = {...errors}; 
            let formIsValid = true;
        
        let msg ='This field is required';

        if(!report.title){
            error.title =msg;
            formIsValid = false;
        } 

        if(report.includes.value===''){
            error.includes =msg;
            formIsValid = false;
        } 



        if(group.length===0){
            formIsValid = false;
            error.field_group =msg;
            Alerts('danger', 'Please select at least one field group')
        } 



        let sql =" '' ";

      
        if(report.includes.value==='Current'){
            sql = sql + " and e.termination_reason = ''"
        }else if(report.includes.value==='Past'){
            sql = sql + " and e.termination_reason <> ''"
        }

        let sqlString = criteria.map((list:any)=>{

            if (list.field_name === 'Start Date'){

                if(!list.field_value){
                    error.start_date =msg;
                    formIsValid = false;
                } 

                sql = sql + " and a.start_date= '"+list.field_value+"'"
            }


            if (list.field_name === 'End Date'){

                if(!list.field_value){
                    error.end_date =msg;
                    formIsValid = false;
                } 

                sql = sql + " and a.end_date= '"+list.field_value+"'"
            }




            if (list.field_name === 'Job Title'){

                if(!list.data){
                    error.job_title =msg;
                    formIsValid = false;
                }

                sql = sql + " and e.job_title = '"+list.data+"'"
            }


            if (list.field_name === 'Department'){

                if(!list.data){
                    error.department =msg;
                    formIsValid = false;
                }
                sql = sql + " and e.department = '"+list.data+"'"
                
            }

              if (list.field_name === 'Application Status'){
                if(!list.data){
                    error.application_status =msg;
                    formIsValid = false;
                }
                sql = sql + " and a.is_approved = '"+list.data+"'"  
             } 


             if (list.field_name === 'Leave Type'){
                if(!list.data){
                    error.leave_type =msg;
                    formIsValid = false;
                }
                sql = sql + " and a.leave_code = '"+list.data+"'"   
             } 


           
             if (list.field_name === 'Employee Name'){

                if(!list.field_value){
                    error.employee_name =msg;
                    formIsValid = false;
                }

                sql = sql + " and e.first_name Like  '%"+list.field_value+"%' OR  e.last_name Like  '%"+list.field_value+"%'"
   
             }


            

        }) 
        

if(!formIsValid){
    setErrors(error) 

window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
    }
    
    if(formIsValid) {

        
  LoadingModal()
        const fd = new FormData();

        fd.append('added_by', STAFF_CODE);
        fd.append('company_code', COMPANY_CODE);
        fd.append('code', 'L'+Math.random().toString(36).substring(2, 9));

        fd.append('title', report.title);
        fd.append('includes', report.includes.value);
        fd.append('criteria_sql', sql);
        fd.append('group', JSON.stringify(group, null, 2));
        fd.append('criteria', JSON.stringify(criteria, null, 2));
        fd.append('item', JSON.stringify(item, null, 2));
        fd.append('item_group', JSON.stringify(item_group, null, 2));
        

let url = ServerUrl+'/api/leave/create_template';
axios.post(url, fd, config)
.then(response =>{
  if(response.data.status === 200){
   SuccessModal(response.data.message)
   Alerts('default', response.data.message)
   handleResetReport()
            } else{
              Alerts('danger',response.data.message)
            }   
        })
        .catch((error)=>{
          Alerts('danger',error.message)
        }).finally(()=>{
      closeModal()
        })   

         }

        }


        useEffect(()=>{
            fetchLeave()
          }, [])
  return ( <>
  <Breadcrumb 
   title="Template"
   summary="Define field and criterias of employee leave report here"
   >
    <li className="breadcrumb-item">
    <Link to="#!">Report</Link>
                    </li>
   <li className="breadcrumb-item">
    <Link to="#!">Create Template</Link>
                    </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
<div className="main-body">
<div className="page-wrapper">
<div className="page-body">



<div className="row">
<div className=" col-md-12">
<div className="card z-depth-0">
    <div className="card-header">
        <h5>Create Leave Report Template</h5>
        <span className="text-muted">Description on  <a href="#!" target="_blank">amCharts</a> how and what this page does here.</span>
        <div className="card-header-right">

            <ul className="list-unstyled card-option">
                <li><i className="fa fa fa-wrench open-card-option"></i></li>
                <li><i className="fa fa-window-maximize full-card"></i></li>
                <li><i className="fa fa-minus minimize-card"></i></li>
                <li><i className="fa fa-refresh reload-card"></i></li>
                <li><i className="fa fa-trash close-card"></i></li>
            </ul>
            
        </div>
    </div>
    <div className="card-block ">
       


        <div className="row">
            
    <div className="col-md-12">
    <div className="form-group">
    <label className="col-form-label">Report Title <span className='text-danger'>*</span></label>
    
  
    <input type="text"  className={errors.title?"form-control form-control-danger":"form-control form-control-lg "}  name='title' placeholder='Enter Report Title' value={report.title} onChange={handleChange} />


    {errors.title&&
    <div className="col-form-label has-danger">{errors.title&&errors.title} </div>}
    </div>
    
    </div>
    </div>
    <hr/>
    
    <h6 className='mt-4'>Choose Search Criteria</h6>

    <div className="row">
    <div className="col-md-4">
    <div className="form-group">
    <label className="col-form-label">Select Searching Parameter</label>
    
    
<Select  options={
            item.map((list:any, id:number)=>{
                return {key:id, value:list, label:list}
                    })} 
                    
            className={errors.search_paramter?"form-control form-control-danger":""} 
            onChange={handleSelect}  name="search_paramter"
            value={report.search_paramter} 
            styles={styles} 
                 />
    
    </div>
    
    </div>
    
    <div className="col-md-4">
      
         <button type='button' onClick={handleAdd} className='btn btn-primary btn-round m-t-30'><i className='fa fa-plus'></i></button>
   
    
    </div>



    <div className="col-md-4">
    <div className="form-group">
    <label className="col-form-label">Includes</label>
    
    
  <Select  options={[
                    { value: 'Current', label: 'Current Employee Only' },
                    { value: 'Past', label: 'Past Employee Only'},
                    { value: 'All', label: 'All Employees'}
                                ]} 
                onChange={handleSelect}  
                name="includes"
                className={errors.includes?"form-control form-control-danger":""} 
                 value={report.includes} 
                 styles={styles} 
                 
                 />
    
    </div>
    
    </div></div>







{criteria.length!==0?criteria.map((list:any, index:number)=>
    <div key={index} className="row">
    
    <div className="col-md-3">
    
        <button className='btn btn-danger btn-sm' type='button' onClick={()=>handleRemove(list.code, list.field_name)}><i className='fa fa-trash'></i></button>

    <label className="col-form-label m-l-15 form-control-bold">{list.field_name}</label>
    
    
    
    </div>
    
    {list.field_name === 'Start Date'?
    <div className="col-md-4">
    <div className="form-group">    
    
    <input type="date" 
    
  
    
className={errors.start_date?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} 
name={list.code} value={list.field_value} onChange={(e)=>handleChangeCriteria(e, 'start_date')} />
    
    </div>
    
    </div>: 


list.field_name === 'End Date'?
    <div className="col-md-4">
    <div className="form-group">    
    
    <input type="date" 
    
  
    
className={errors.end_date?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} 
name={list.code} value={list.field_value} onChange={(e)=>handleChangeCriteria(e, 'end_date')} />
    
    </div>
    
    </div>: 

    list.field_name === 'Job Title'?
    <div className="col-md-4">
    <div className="form-group">
    
    <Select options={
            Jobs.data&&Jobs.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.title}
                    })}   
                    className={errors.job_title?"form-control form-control-danger":""} 
			onChange={(option, action)=>handleSelectCriteria(option, action, 'job_title')} 
            styles={styles} 
            name={list.code} 
            value={{label:getFieldName(Jobs.data, 'title', list.data), value:list.data}} 
              
              />
    
    </div>
    
    </div>

    :

    list.field_name === 'Leave Type'?
    <div className="col-md-4">
    <div className="form-group">
    

    <Select  options={
                template&&template.map((list:any, idx:number)=> {
                    return {key:idx, value: list.code, label: list.leave_name}
                    })}
                    className={errors.leave_type?"form-control form-control-danger":""}              
                    onChange={(option, action)=>handleSelectCriteria(option, action, 'leave_type')} 
                     name={list.code} 
                     value={{label:getFieldName(template, 'leave_name', list.data), value:list.data}} 
    
                    styles={styles} 
                    />   
    
    </div>
    
    </div>

    :

    list.field_name === 'Application Status'?
    <div className="col-md-4">
    <div className="form-group">
    

    <Select  options={[
                     { value: 'Approved', label: 'Approved' },
                     { value: 'Pending', label: 'Pending'},
                     { value: 'Rejected', label: 'Rejected'},
                     { value: 'All', label: 'All'}
                                ]} 
                                className={errors.application_status?"form-control form-control-danger":""} 
                                onChange={(option, action)=>handleSelectCriteria(option, action, 'application_status')} 
                name={list.code} 
                value={{label:list.data, value:list.data}}
                 styles={styles} 
                 
                 
                 /> 
    
    </div>
    
    </div>

    :
    list.field_name === 'Department'?


    <div className="col-md-4">
    <div className="form-group">
    
    <Select  options={
            department.data&&department.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.department_name}
                    })}   
                    styles={styles} 
                    onChange={(option, action)=>handleSelectCriteria(option, action, 'department')}  
                    className={errors.department?"form-control form-control-danger":""} 
            name={list.code} 
            value={{label:getFieldName(department.data, 'department_name', list.data), value:list.data}}

               />
    
    </div>
    
    </div>

:

<div className="col-md-4">
<div className="form-group">

<input type="text" 

className={errors.employee_name?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} 


name={list.code} 
value={list.field_value}

onChange={(e)=>handleChangeCriteria(e, 'employee_name')}
 />

</div>

</div>}



        </div>):[]}
 

    <hr/>





    <h6 className='mt-4'>Display Fields</h6>

    <div className="row">
    <div className="col-md-4">
    <div className="form-group">
    <label className="col-form-label">Field Group</label>
    
    
<Select  options={
            item_group.map((list:any, id:number)=>{
                return {key:id, value:list.value, label:list.title, isSelected:list.isSelected, section:list.section}
                    })} 
                    
            className={errors.field_group?"form-control form-control-danger":""} 
            onChange={handleSelectField}  name="field_group"
            value={report.field_group} 
            styles={selectStyles}  
                 />
    
    </div>
    
    </div>
    

    <div className="col-md-4">
    <div className="form-group">
    <label className="col-form-label">Display Field</label>
    
    
    <Select  options={
            content.map((list:any, id:number)=>{
                return {key:id, value:list.value, label:list.label, isSelected:list.isSelected}
                    })} 
                    isMulti
            onChange={handleSelectIt}  name="display_filed"
            value={report.display_filed} 
            styles={selectStyles} 
                 />
    
    </div>
    
    </div>

    <div className="col-md-4">
      
         <button type='button' onClick={handleAddGroup} className='btn btn-primary btn-round m-t-30'><i className='fa fa-plus'></i></button>
   
    
    </div>
    
    </div>



   
{group.length!==0?group.map((list:any, index:number)=>
    <div key={index}  className="row">
    
    <div className="col-md-3">
    
        <button className='btn btn-danger btn-sm'  onClick={()=>handleRemoveGroup(list.code, list.field_value)} type='button'><i className='fa fa-trash'></i></button>

    <label className="col-form-label m-l-15 form-control-bold">{list.field_name}</label>
    
    
    
    </div>
    

    <div className="col-md-6">

    <div className="main-wrapper">
   
    {list.data.map((data:any, id:number)=><div key={id} className="wrapper"> 
   
   <span className='item'>{data.label}</span>
   <span className="item-icon pointer pl-2 pr-2" onClick={()=>handleRemoveField(list, data.value)}> <i className=' fa fa-times  '></i></span>
   
   
   </div>)}
   </div>

    
    </div>


    
    <div className="col-md-3">
    <div className="form-group">
    
    <label className="switch-md">
                    <input type="checkbox" name={list.code} checked={list.isHeader} onChange={()=>handleHeaderChange(list)}   />
                    <span className="round slider-md"></span>
                  </label>&nbsp;
                  Include header
    
    </div>
    
    </div>

        </div>):[]}



<hr/>
<div className="row">
<div className="col-md-12 ">

<button className="btn btn-outline-info btn-round pr-5 pl-5 mr-3" type='button' onClick={handleResetReport}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={handleSubmit} >Save</button>
</div>
</div>



    </div>
</div>
</div>
</div>


</div>
</div>


</div>
</div>  
        </>               
 
  )
}

export default CreateLeaveReport