import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo, useRef} from 'react'
import { COMPANY_CODE, CURRENCY, STAFF_CODE, ServerUrl, config, configDownload, selectStyles, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts, CustomLoader } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'
import { useQuery } from 'react-query'
import { usePaginated, DOTS } from '../../components/usePagination';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { FormatNumber, convertDate, getBritishDate, getMonthYear } from '../../components/GlobalFunction';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import { takeScreenshot } from '../../api/ReactQuery';
const  RecordExpenses =()=> {
   
    const [director, setDirector] = useState({
       
        isName:false,
        isSpotType:false,
        isDate:false,
        isDescription:false,
        isCriteria:false,
        isPublic:false,
        isPoint:false,
        isAction:false,
        
    });

    const [expenses, setExpenses]=useState({
      expenses_date:null,
      expenses_head:[] as any,
      amount:0,
      remark:''
  });




    const [search, setSearch] = useState({
        rowperpage:{value:'10', label:'10'},
        filterText:'',
        selectAll:false,
        totalSelected:0,
     })
     
     const [editItem, setEditItem] = useState({
      id:'',
       code:'', 
      expenses_head:'', 
      expenses_date:new Date(),
       amount:'',
        remark:''
     });
     const [criteria, setCriteria] = useState([]);
    const [filterContent, setFilterContent] = useState([] as any);
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
      expenses_date:'',
      expenses_head:'',
      amount:'',

      edit_expenses_date:'',
      edit_expenses_head:'',
      edit_amount:'',
       
     })
    
    
  
   
        const fetchCriteria =async()=>{
    let url = ServerUrl+'/api/finance/expenses_head/view/'+COMPANY_CODE
          const {data} = await axios.get(url, config)
          setCriteria(data.data)
          }

     const handleSelect = (option:any, action:any)=>{
      setExpenses({...expenses, [action.name]:option});
          setErrors({...errors, [action.name]:''})
           }


           const handleChangeDate = (data:any, name:string)=>{
   
            setExpenses({...expenses, [name]:data});
             setErrors({...errors, [name]:''});
          }

          

  const handleChangeDateEdit = (data:any, name:string)=>{
   
    setEditItem({...editItem, [name]:data});
     setErrors({...errors, edit_expenses_date:''});
  }
 
           const handleSelectEdit = (option:any, action:any)=>{
            setEditItem({...editItem, [action.name]:option});
                setErrors({...errors, edit_expenses_head:''})
                 }

           const handleChangeArea = (event:ChangeEvent<HTMLTextAreaElement>)=>{
           
            let {name, value} = event.target;	
            setEditItem({...editItem, [name]:value});
           setErrors({...errors, [name]:''});
        }


           const handleChangeText = (event:ChangeEvent<HTMLTextAreaElement>)=>{
           
            let {name, value} = event.target;	
            setExpenses({...expenses, [name]:value});
           setErrors({...errors, edit_amount:''});
        }


        const handleChangeEdit = (event:ChangeEvent<HTMLInputElement>)=>{
           
          let {name, value} = event.target;	
          setEditItem({...editItem, [name]:value});
         setErrors({...errors, [name]:''});
      }


        const handleChange = (event:ChangeEvent<HTMLInputElement>)=>{
           
          let {name, value} = event.target;	
          setExpenses({...expenses, [name]:value});
         setErrors({...errors, [name]:''});
      }
        
      

      const handleUpdate =(event: FormEvent<HTMLButtonElement>)=>{
        event.preventDefault();
    
        let error = {...errors}; 
        let formIsValid = true;
    
    let msg ='This field is required';
  
    
  if(!editItem.expenses_head){
    error.edit_expenses_head=msg;
    formIsValid = false;
} 

if(!editItem.expenses_date){
  error.edit_expenses_date=msg;
  formIsValid = false;
}
    

if(!editItem.amount){
  error.edit_amount=msg;
  formIsValid = false;
}


if(Number(editItem.amount) <=0){
  error.edit_amount=msg;
  formIsValid = false;
}
    
    if(!formIsValid){
    setErrors(error) 
    }
    
    if(formIsValid) {
    
        LoadingModal()
       
        const fd = new FormData();
       let amount:any = editItem.amount;
        fd.append('id', editItem.id);
        fd.append('code', editItem.code);
        fd.append('amount', amount);
        fd.append('expenses_head', editItem.expenses_head);
        fd.append('expenses_date', convertDate(editItem.expenses_date));
        fd.append('remark', editItem.remark);
    
    
    let url = ServerUrl+'/api/finance/expenses/update_expenses';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.status === 200){
         SuccessModal(response.data.message)
         let click = document.getElementById('closeEdit')?.click()
                  } else{
                    Alerts('danger', response.data.message)
                  }   
              })
              .catch((error)=>{
                Alerts('danger', error.message)
              }).finally(()=>{
             handleReset()
    
              })
        }
    
    }



      const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
        event.preventDefault();
    
        let error = {...errors}; 
        let formIsValid = true;
    
    let msg ='This field is required';
  
    
  if(expenses.expenses_head.length===0){
    error.expenses_head=msg;
    formIsValid = false;
} 

if(!expenses.expenses_date){
  error.expenses_date=msg;
  formIsValid = false;
}
    

if(!expenses.amount){
  error.amount=msg;
  formIsValid = false;
}


if(expenses.amount <=0){
  error.amount=msg;
  formIsValid = false;
}
    
    if(!formIsValid){
    setErrors(error) 
    }
    
    if(formIsValid) {
    
        LoadingModal()
       
        const fd = new FormData();
       let amount:any = expenses.amount;
        let code = 'ER'+Math.random().toString(36).substring(2, 9)
        fd.append('added_by', STAFF_CODE);
        fd.append('company_code', COMPANY_CODE);
        fd.append('code', code);
        fd.append('amount', amount);
        fd.append('expenses_head', expenses.expenses_head.value);
        fd.append('expenses_date', convertDate(expenses.expenses_date));
        fd.append('remark', expenses.remark);
    
    
    let url = ServerUrl+'/api/finance/expenses/add';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.status === 200){
         SuccessModal(response.data.message)
       
                  } else{
                    Alerts('danger', response.data.message)
                  }   
              })
              .catch((error)=>{
                Alerts('danger', error.message)
              }).finally(()=>{
             handleReset()
             fetchContent()
              })
        }
    
    }
    

const handleReset =()=>{
    setExpenses({
      expenses_date:null,
      expenses_head:[] as any,
      amount:0,
      remark:''
  });
}




const DeleteModal =(row:any)=>{
    let url = ServerUrl+'/api/finance/expenses/delete_one/'+row.id;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =content.filter((item:{code:string})=>item.code !==row.code)
            setFilterContent(rs)
            setContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }



  const handleSelectAll =(list:[])=>{
      
    let is_check = list.every((element:any) => {
     return element.is_check === 'true'
    });
    var i = 0;
        list.forEach((element:any) => {
          if(element.is_check === 'true')
         i++
        });
    setSearch({...search, selectAll:is_check, totalSelected:i})
    
       }


const handleCheck =(code:string)=>{
const newContent = filterContent.map((item:any)=>{
   
if(item.code === code){
return {...item, 
    is_check:item.is_check==='false'?'true':'false',
}
};
return item;
})
setFilterContent(newContent)
handleSelectAll(newContent)
}


const handleSelectChange =()=>{
    let status = search.selectAll ===false?'true':'false';
    const newContent = filterContent.map((item:any)=>{
        return {...item, 
            is_check:status,
        }
  })

  var i = 0;
  newContent.forEach((element:any) => {
      if(element.is_check=== 'true')
     i++
    });

    setFilterContent(newContent)

    setSearch({...search, selectAll:!search.selectAll, totalSelected:i})

   }



  const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	
    setSearch({...search, [name]:value});

    if(value!==''){
        const filteredItems = content.filter(
            (item:any) => item.remark.toLowerCase().includes(value.toLowerCase()) || item.title.toLowerCase().includes(value.toLowerCase()) ||
            item.expenses_date.toLowerCase().includes(value.toLowerCase()),
        );

        setFilterContent(filteredItems)
    }else{
        setFilterContent(content)
    } 

 }


const handleClear =()=>{
setSearch({...search, filterText:''});
setFilterContent(content)
}



const EditItem =(data:any)=>{
    setEditItem({
      id:data.id,
       code:data.code, 
      expenses_head:data.expenses_head, 
      expenses_date:new Date(data.expenses_date),
       amount:data.amount,
        remark:data.remark
    }); 
   let click = document.getElementById('openEdit')?.click()

}

        const TableData =()=>{

            

            const PaginationWrapper =(props:any)=>{
            
                const handleRowsPerPage = useCallback(
                    (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
                    [props.currentPage, props.onChangeRowsPerPage],
                );
                const handleSelectRowsPerPage = useCallback((option:any, action:any)=> props.onChangeRowsPerPage(Number(option.value), props.currentPage),
                [props.currentPage, props.onChangeRowsPerPage],
                )
               
                function getNumberOfPages(rowCount:number, rowsPerPage:number) {
                    return Math.ceil(rowCount / rowsPerPage);
                  }
                const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
                const lastIndex = props.currentPage * props.rowsPerPage;
                const firstIndex = lastIndex - props.rowsPerPage + 1;
            
            
                    const range =
                    props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
            
                    `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
            
                
               
                const Pagination = () => {
                   
                    const pageSize = props.rowsPerPage;
                    const totalCount = content.length
                    const siblingCount = 1
                    const currentPage = props.currentPage
                    
                    const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
                    
                    if (currentPage === 0 ) {
                      return null;
                    }
                    
                    let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
                    return (
                    <nav className="" aria-label="Page navigation sample ">
                      <ul  className="pagination pagination-sm"  >
                        <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
                         <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Previous</a>
                        </li>
                        {paginationRange&&paginationRange.map((pageNumber, index) => {
                          if (pageNumber === DOTS) {
                            return <li className="pagination-item dots" key={index}>
                             <a className="page-link pointer"  >&#8230;</a>
                            </li>
                          }
                          return (
                            <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                              <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                              
                            </li>
                          );
                        })}
                    
                    <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
                    <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
                        </li>
                    
                      </ul> </nav>
                    );
                    
                        }
            
                      
            
                      
            
             return <div className="row mt-3">
                                
            <div className="col-md-5 ">
            <div className="row ml-3" >
            
               Rows per page: &nbsp; <select name="rowPerPage" id="" className='w-40 h-30 ' onChange={handleRowsPerPage}>
                    <option value="10"> 10</option>
                    <option value="20"> 20</option>
                    <option value="30"> 30</option>
                    <option value="40"> 40</option>
                    <option value="50"> 50</option>
                    <option value={content.length}> All</option>
                </select> &nbsp;&nbsp;
                {/*  {range} */} </div>
            </div>
              
            <div className="col-md-7  justify-content-end d-flex">
               <Pagination  />
                </div>
                
            </div>}
            
            
            
const CheckboxAll =()=>{ 

    return <div className="chk-option">
    <div className="checkbox-fade fade-in-primary">
        <label className="check-task">
    
        <input type="checkbox"  id="selectAll" checked={search.selectAll} onChange={handleSelectChange} />
                    
        <span className="cr">
            <i className="cr-icon fa fa-check txt-default"></i>
        </span>
        </label>
    </div>
    </div>}


const Checkbox =({code, is_check}:{code:string, is_check:string})=>{ 

return <div className="chk-option">
<div className="checkbox-fade fade-in-primary">
    <label className="check-task">

    <input type="checkbox"  name={code} id={code} checked={is_check==='true'?true:false} onChange={()=>handleCheck(code)} />
                
    <span className="cr">
        <i className="cr-icon fa fa-check txt-default"></i>
    </span>
    </label>
</div>
</div>}
            
            
            
                        const columns = [
            
                          
                            {
                                name: <CheckboxAll />,
                                cell: (row:any) => <Checkbox code={row.code} is_check={row.is_check} />, 
                                width:'60px'
                            },
                            {
                                name: 'Expenses',
                                selector: (row:any) =>row.title,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isName,
                            },
                            {
                                name: `Amount ${CURRENCY}`,
                                selector: (row:any) => FormatNumber(row.amount),
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isSpotType,
                            },
                            {
                              name: 'Expenses Date',
                              selector: (row:any) => getBritishDate(row.expenses_date),
                              sortable: true,
                              reorder:true,
                              wrap:true,
                              omit: director.isCriteria,
                              
                          },
                            {
                                name: 'Remark',
                                selector: (row:any) => row.remark,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isPoint,
                                
                            },
                           
                            {
                              name: 'Action',
                              cell: (row:any, index:any, column:any, id:any) => <div className='row'>  
                                  <label className="label label-info pointer" onClick={()=>EditItem(row)}><i className='ti-pencil'></i> Edit</label>
                              <label className="label label-danger pointer" onClick={()=>DeleteModal(row.id)}><i className='ti-trash'></i> Delete</label></div>,
                                  left:true,
                                  width:'200px',
                                  omit: director.isAction,
                                        
                  
                          },
                        ];
                        
                    
                    
                    
                        return <DataTable
                        columns={columns}
                        data={filterContent}
                        highlightOnHover
                        pagination
                        paginationComponent={PaginationWrapper}
                        selectableRowsHighlight
                        striped
                        responsive
                        fixedHeader
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                    />
                    }
            


                    const sqlQuery = ()=>{
                      let sql = "SELECT 'false' as is_check, i.id, i.code, i.expenses_head, h.title, i.amount, i.remark, i.expenses_date  FROM tbl_expenses i, tbl_expenses_head h where h.code =  i.expenses_head and i.company_code = '"+COMPANY_CODE+"'" 
                      return sql;
                    }

         const fetchContent = async()=>{
            setLoading(true)
     
                
            let sql = sqlQuery()
                 const fd = new FormData();
                 fd.append('sql', sql); 

                 let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE

                 
               await  axios.post(url, fd, config).then((result:any)=>{

                 if(Array.isArray(result.data.data) && result.data.data.length!==0){
                    setFilterContent(result.data.data)
                   setContent(result.data.data)
                   }else{

                    setContent([])
                    setFilterContent([])
                   }
                 })
                 .catch((error)=>{
                     Alerts('danger',error.message)
                 }).finally(()=>{
                     setLoading(false)
                 })
                 } 





                 const handleDeleteAll =()=>{

                    if(search.totalSelected<1){
                      Alerts('danger','Please select at least one record to delete ')
                    }else{
                  
                      let employeeList =[] as any;
                      let selectedEmployee = filterContent.filter((item:any)=>item.is_check ==='true')
                  
                      if(Array.isArray(selectedEmployee)){
                        selectedEmployee.forEach((list:any) => {
                            employeeList.push(list.code)
                        });
                      }
                  
                      const fd = new FormData();
                      fd.append('employeeList', JSON.stringify(employeeList, null, 2));
                      let url = ServerUrl+'/api/finance/expenses/destroy_multiple/'+COMPANY_CODE;
                      Swal.fire({
                        title: 'Are you really sure?',
                        text: "This is very dangerous, you shouldn't do it!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        showLoaderOnConfirm: true,
                      preConfirm: async() => {
                        return await axios.post(url, fd, config).then(response=>{
                         if(response.data.status!==200){
                              throw new Error(response.statusText)
                            }
                            return response.data
                          })
                          .catch(error => {
                            Swal.showValidationMessage(
                              `Request failed: ${error}`
                            )
                          })
                      },
                      allowOutsideClick: () => !Swal.isLoading(),
                        confirmButtonText: 'Yes, I am!'
                      }).then((result:any) => {
                        if (result.isConfirmed && result.value.status===200) {
                  
                          let rs =filterContent.filter((item:any)=>item.is_check ==='false')
                         setContent(rs)
                         setFilterContent(rs)
                           Swal.fire(
                                      'Deleted!',
                                      'Record has been deleted.',
                                      'success'
                                    ) 
                        }
                      })
                  
                    }
                  
                   }

const getCriteria =(code:string)=>{
  const rs:any = criteria&&criteria.filter((item:any)=>item.code===code)

  const answer =  rs.length!==0?rs[0].title:''
  return answer;

}

const handleDownload = async(extension:string)=>{
  let sql = sqlQuery()
  const fd = new FormData();
  fd.append('sql', sql); 
  fd.append('extension', extension); 
  let url = ServerUrl+'/api/download_file/expenses'
  await  axios.post(url, fd, configDownload).then((response:any)=>{
   const url = window.URL.createObjectURL(new Blob([response.data]));
     
       const link = document.createElement('a');
       link.href = url;
       link.download = '_expenses.'+extension
       link.click();
       })
  }


    useEffect(()=>{
      fetchContent()
        fetchCriteria()
      }, [])


    

  return ( <>
    <Breadcrumb 
   title="Record Expenses"
   summary="Record  Expenses"
   >
    <li className="breadcrumb-item">
    <Link to="/finance/account_statement">Finance</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Expenses</a> </li>
         <li className="breadcrumb-item"><a href="#!">Record Expenses</a> </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Record Expenses</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                              
                                    
                                    
         <div className="content-wrappers ">
                            
         <div className=''>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Expenses</h5>
                <span>Add all offcie department</span>
              
            </div>
            <div className="card-block">
    
        
                       

                <div className="row">
             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Expenses Date <span className='text-danger'>*</span></label>



            <DatePicker 
            selected={expenses.expenses_date}      
             isClearable
             dateFormat="yyyy-MM-dd"
             className={errors.expenses_date?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}
             placeholderText="Select date"
 onChange={(date:any) => handleChangeDate(date, 'expenses_date')} />


          

            {errors.expenses_date&&
    <div className="col-form-label has-danger">{errors.expenses_date&&errors.expenses_date} </div>}
            </div>  
            
            </div> 

    <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Expenses Head <span className='text-danger'>*</span></label>



                               
                                <Select options={
                                criteria&&criteria.map((list:any, idx:number)=> {
                                    return {key:idx, value: list.code, label: list.title}
                                    })} 
                                    
                          onChange={handleSelect} 
                          className={errors.expenses_head? 'form-control form-control-danger' : ''} 
                          name="expenses_head" 
                          value={expenses.expenses_head} 
                          
                          styles={styles}  
                          /> 
                              

                {errors.expenses_head&&
    <div className="col-form-label has-danger">{errors.expenses_head&&errors.expenses_head} </div>}

                                 </div>
                                 </div>



                                 <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Amount  <span className='text-danger'>*</span></label>



                               
            <input type="number" className={errors.amount?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  value={expenses.amount} name="amount" onChange={handleChange} />
                              

                {errors.amount&&
    <div className="col-form-label has-danger">{errors.amount&&errors.amount} </div>}

                                 </div>
                                 </div>

<div className="col-md-6">
  <div className="form-group">
  <label className="col-form-label">Remark</label>

  <textarea name="remark" value={expenses.remark} onChange={handleChangeText}  className="form-control"  placeholder="Maximum 100 words" >{expenses.remark}

</textarea>
  </div>
</div>

    </div>


        <hr />
            <div className="row">
        <div className="col-md-12 text-right">
    
        <button className="btn btn-outline-info btn-round pr-5 pl-5 mr-3" type='button'>Reset</button>&nbsp;
             <button type='button' onClick={handleSubmit} className="btn btn-success btn-round pr-5 pl-5">Save</button>
       
         </div>
        </div>

    
            </div>
        </div>
    </div>
    </div>
    
    
    
<label data-toggle="modal" data-target="#editItem" id='openEdit' ></label>

<div className="modal fade " id="editItem" tabIndex={-1} role="dialog" data-backdrop="static" >
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header bg-dark">
                    <h4 className="modal-title text-white"><i className=' fa fa-info'></i> Update Expenses </h4>
                  
    
            <button type="button" className="close" id='closeEdit' data-dismiss="modal" aria-label="Close">
            <i className='fa fa-ban text-danger'></i>
            </button>
    
                </div>
                <div className="modal-body">
                
    
                           

                <div className="row">
             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Expenses Date <span className='text-danger'>*</span></label>

            <DatePicker 
            selected={editItem.expenses_date}      
             isClearable
             dateFormat="yyyy-MM-dd"
             className={errors.edit_expenses_date?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}
             placeholderText="Select date"
        onChange={(date:any) => handleChangeDateEdit(date, 'expenses_date')} />

            {errors.edit_expenses_date&&
    <div className="col-form-label has-danger">{errors.edit_expenses_date&&errors.edit_expenses_date} </div>}
            </div>  
            
            </div> 

    <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Expenses Head <span className='text-danger'>*</span></label>


                                <Select options={
                                criteria&&criteria.map((list:any, idx:number)=> {
                                    return {key:idx, value: list.code, label: list.title}
                                    })} 
                                    
                          onChange={handleSelectEdit} 
                          className={errors.edit_expenses_head? 'form-control form-control-danger' : ''} 
                          name="expenses_head" 
                          value={{value:editItem.expenses_head, label:getCriteria(editItem.expenses_head)}} 
                          
                          styles={styles}  
                          /> 
                              

                {errors.edit_expenses_head&&
    <div className="col-form-label has-danger">{errors.edit_expenses_head&&errors.edit_expenses_head} </div>}

                                 </div>
                                 </div>



                                 <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Amount  <span className='text-danger'>*</span></label>



                               
            <input type="number" className={errors.edit_amount?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  value={editItem.amount} name="amount" onChange={handleChangeEdit} />
                              

                {errors.edit_amount&&
    <div className="col-form-label has-danger">{errors.edit_amount&&errors.edit_amount} </div>}

                                 </div>
                                 </div>

<div className="col-md-6">
  <div className="form-group">
  <label className="col-form-label">Remark<span className='text-danger'>*</span></label>

  <textarea name="remark" value={editItem.remark} onChange={handleChangeArea}  className="form-control"  placeholder="Maximum 500 words" >{expenses.remark}

</textarea>
  </div>
</div>

    </div>


                </div>
                <div className="modal-footer">
                   
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={handleUpdate} >Update</button>
                </div>
            </div>
        </div>
    </div>





              {content.length!==0?              <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Expenses History</h5>
                <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
              
                <div className="card-header-right">

                <div className="dropdown-info dropdown">
        <button type="button" className="btn btn-success active dropdown-toggle dropdown-icon btn-sm " data-toggle="dropdown"><i className='fas fa-eye text-white'></i> Export</button>
                    <div className="dropdown-menu" aria-labelledby="dropdown-4" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut" x-placement="bottom-start" >

                        <a className="dropdown-item waves-light waves-effect" onClick={()=>handleDownload('pdf')} href="#!"><i className="fas fa-file-pdf "></i> Export as PDF</a>

                        <a className="dropdown-item waves-light waves-effect" href="#!" onClick={()=>handleDownload('xlsx')}><i className="fas fa-file-excel "></i> Export as Excel</a>

                        <a className="dropdown-item waves-light waves-effect" href="#!" onClick={()=>takeScreenshot('takeshot')}><i className="fas fa-image "></i> Export as Image</a>
                        <a className="dropdown-item waves-light waves-effect" href="#!"><i className="fas fa-print "></i> Print Preview</a>
                    </div>
                </div>&nbsp;

        </div> 
            </div>
            <div className="card-block">
    




            <div className="row">

<div className=" col-md-8 ">


                  &nbsp;

                  <a href='#!' className="btn btn-primary btn-sm "><i className='fa fa-print text-white'></i></a>&nbsp;


  <button type="button" className="btn btn-outline-danger btn-sm " onClick={handleDeleteAll} ><i className="fas fa-trash"></i> Delete Selected ({search.totalSelected})</button>&nbsp;

                  </div>

    <div className=" col-md-4 ">
    <div className="input-group  " >
                    <input type="text" name="filterText" id="filterText" key="filterText"  onChange={handleSearch} value={search.filterText} className="form-control float-right form-control-sm" placeholder="Type to search" />
                   
                    <div className="input-group-append">
                        <button type="button" onClick={handleClear} className="btn btn-default btn-sm" >
                          <i className="fas fa-close"></i>
                        </button>
                      </div>
                  </div>
      

    </div>
</div>

    <div className="row" id='takeshot'>
        <div className="table-responsive ">
        <TableData />
        </div>
        </div>

            </div>
        </div>
    </div>
    </div>:[]}


                          
    </div>

</div>
               
            </section>
        </div>
    </div>
</div>
</div>
                            </div>
                        </div>
                    </div>
      </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default RecordExpenses