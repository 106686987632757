import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, CURRENCY, STAFF_CODE, ServerUrl, config, selectStyles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts, CustomLoader } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'


import Select, { components } from 'react-select'
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';

const  AccessRoles =()=> {
   
const [menu, setMenu]=useState([])
const [usergroup, setUsergroup]=useState([])

    const [loading, setLoading] = useState(false);
    
const [errors, setErrors] = useState({
  role:'',
 
})
const [permission, setPermission]=useState({
  role:[] as any,
  priviledge:[] as any,
  status:true
})

const handleSelect = (option:any, action:any)=>{
  
  setPermission({...permission, [action.name]: option});
  setErrors({...errors, [action.name]:''})
setMenu(JSON.parse(option.roles))
     }


     const selectAll =()=>{          
      var newMenu = JSON.parse(JSON.stringify(menu));
      for (var i in newMenu){
      newMenu[i].status =!permission.status
      for(var k in newMenu[i].submenu){
        newMenu[i].submenu[k].status =  !permission.status 
        for(var m in newMenu[i].submenu[k].subsubmenu){
          newMenu[i].submenu[k].subsubmenu[m].status =  !permission.status 
      }}
      }
  
    setMenu(newMenu)
    setPermission({...permission, status:!permission.status})
      }



	const  handleSubSubChange = (status:boolean, subsub:number, subIndex:number, mainIndex:number) => {                
    const newmenu =JSON.parse(JSON.stringify(menu))
      newmenu[mainIndex].submenu[subIndex].subsubmenu[subsub].status = !status;      
      setMenu(newmenu); 
    }
  
    const  handleChangeSubmenu = (status:boolean, subIndex:number, mainIndex:number) => {                 
    const newmenu =JSON.parse(JSON.stringify(menu))
      newmenu[mainIndex].submenu[subIndex].status = !status;      
      setMenu(newmenu); 
    }
  


           const  handleChangeMenu=(status:boolean, i:number)=>{
            let newmenu:any = [...menu];
            newmenu[i] = {...newmenu[i], status: !status};
            setMenu(newmenu);
          } 




          
const fetchData = async()=>{
    setLoading(true)
    let url = ServerUrl+'/api/setup/group/'+COMPANY_CODE
  await  axios.get(url, config).then((result:any)=>{
    if(Array.isArray(result.data.data) && result.data.data.length!==0){
      setUsergroup(result.data.data)
      }
    })
    .catch((error)=>{
        Alerts('danger', error.message)
    }).finally(()=>{
        setLoading(false)
    })
    } 




  const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

 if(permission.role.length==0){
    error.role =msg;
    formIsValid = false;
}  


if(menu.length==0){
  error.role ='There are no item assigned to the role selected, kindly reset user group';
  formIsValid = false;
}  


if(!formIsValid){
setErrors(error) 
}

if(formIsValid) {

    LoadingModal()

    const fd = new FormData();



    fd.append("code", permission.role.value)
	fd.append("id", permission.role.value)
	fd.append("roles", JSON.stringify(menu, null, 2))
    
  let url = ServerUrl+'/api/setup/group/update_roles';

  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
              Alerts('danger', response.data.message)
              }   
          })
          .catch((error)=>{
            Alerts('danger', error.message)
          }).finally(()=>{
        fetchData()

          })
    }
}




  


    useEffect(()=>{
        fetchData()
      }, [])


    

  return ( <>
   <Breadcrumb 
   title="Access Roles"
   summary="Create new project"
   >
    <li className="breadcrumb-item">
    <Link to="#!">Account Access</Link>
                    </li>
          
          <li className="breadcrumb-item">
    <Link to="#!">Access Roles</Link>
                    </li>

                 
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">


                        <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Add/edit User privileges information to already created group</h5>
                <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
              
               

            </div>
            <div className="card-block">
    
          




            <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-block">
    
          

            <div className="form-group row">
                  <label htmlFor="role" className="col-sm-2 col-form-label">Select Group</label>
                  <div className="col-sm-10">
                  <Select  options={
                            usergroup&&usergroup.map((list:any, idx:number)=> {
                               return {key:idx, value: list.id, label: list.group_name, roles:list.roles }
                             })} 
onChange={handleSelect} name="role" value={permission.role} styles={selectStyles} /> 


{errors.role&&
    <div className="col-form-label has-danger">{errors.role&&errors.role} </div>}
                  </div>
                </div>
                {menu.length!==0? <div className="form-group row">
                  <label htmlFor="priviledge"  className="col-sm-6 col-form-label">Check/Uncheck all privileges</label>
                  <div className="col-sm-6">
                       
     <button type="button"  id="saveClass" onClick={selectAll} className="btn btn-primary "  >Check All Priviledges</button>
                  </div>
                </div>:''}
                <hr style={{borderTop:'solid 2px'}}/>
                <br/>



    








<div className="col-md-12">
                <div className="card">



                {menu&&menu.map((main:any, k:number)=>main.order!=='0'?
              <div key={k} className="card mb-2">
                <div className="card-header">  
                      <div className="form-group row">
                          <div className="col-sm-1">

                          <div className="checkbox-fade fade-in-primary ">
                                    <label className="check-task">
    <input type="checkbox"  name={main.ID} checked={main.status}  id={main.code} onChange={()=>handleChangeMenu(main.status, k)} />
    <span className="cr">
    <i className="cr-icon fa fa-check txt-default"></i>
    </span> 
</label>
                                </div>


 </div>
                          <div className="col-sm-3 text-left btn btn-link shadow-none collapsed" data-toggle="collapse" data-target={'#'+main.ID}><i className="fa fa-sitemap fa-2x"></i> {main.menuName}  </div>
                          <div className="col-sm-8 btn shadow-none text-left">{main.description}</div>
                      </div>
                    
                </div>

                <div id={main.ID} className="collapse" data-parent="#accordion1">
                  <div className="card-body">
                  {main.submenu && main.submenu.map((smenu:any, id:number)=><div key={id}>
                  <div className="form-group row">
                          <div className="col-sm-1">   




                           <div className="checkbox-fade fade-in-primary ">
                                    <label className="check-task">
    <input type="checkbox"  name={smenu.ID} checked={smenu.status}  id={smenu.ID} onChange={()=>handleChangeSubmenu(smenu.status, id, k)}/>
    <span className="cr">
    <i className="cr-icon fa fa-check txt-default"></i>
    </span> 
</label>
                                </div>



                    </div>
                          <div className="col-sm-5">{smenu.subName}  </div>
                          <div className="col-sm-6">{smenu.subDescription}</div>
                    </div>
                    {smenu.subsubmenu && smenu.subsubmenu.map((subsub:any, idx:number)=>
                          <div key={idx} className="row">
                          <div className="col-sm-1"> </div>

                          <div className="col-sm-1"> 

                          <div className="checkbox-fade fade-in-primary ">
                                    <label className="check-task">
    <input type="checkbox" checked={subsub.status}  name={subsub.ID}  id={subsub.ID} onChange={()=>handleSubSubChange(subsub.status, idx, id, k)} />
    <span className="cr">
    <i className="cr-icon fa fa-check txt-default"></i>
    </span> 
</label>
                                </div>


 </div>
                          <div className="col-sm-5">{subsub.subsubName}  </div>
                          <div className="col-sm-4">{subsub.subsubDescription}</div>
                          <div className="col-sm-1"> </div>
                      </div>)}</div>)}
                  </div>
                </div>
              </div>:'')}



    </div>
</div>

{menu.length!==0?
      <div className="form-footer pull-left">
                  <button type="button"  className="btn btn-dark shadow-dark m-1"><i className="fa fa-arrow-left"></i> Back</button>
                  <button type="button" onClick={handleSubmit} className="btn btn-success shadow-success m-1"><i className="fa fa-check-square"></i> Saved Priviledges for the selected Group</button>
                </div>:''}

            </div>
        </div>
    </div>
    </div>


    
            </div>
        </div>
    </div>
    </div>


            </div>
        </div>


    </div>
</div>  
        </>     
  )
}

export default AccessRoles