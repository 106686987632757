import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo, useRef} from 'react'
import { COMPANY_CODE, CURRENCY, ImagesUrl, STAFF_CODE, ServerUrl, config, selectStyles, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts, CustomLoader } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'

import { useQuery } from 'react-query'
import { fetchDepartment } from '../../api/ReactQuery';
import { usePaginated, DOTS } from '../../components/usePagination';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { FormatNumber, getMonthYear } from '../../components/GlobalFunction';
import { Link } from 'react-router-dom';

const  RepairHistory =()=> {
   
  const department = useQuery(["department"], fetchDepartment)
    const [director, setDirector] = useState({
       
        isName:false,
        isDate:false,
        isCost:false,
        isReason:false,
        isComplete:false,
        isAction:false,
    });
    const [search, setSearch] = useState({

        asset_name:'',
        from_date:'',
        to_date:'',
        status:{label:'All', value:'All'},
       

        rowperpage:{value:'10', label:'10'},
        filterText:'',
        selectAll:false,
        totalSelected:0,
     })

     const handleResetColumn =()=>{
      setDirector({
         
        isName:false,
        isDate:false,
        isCost:false,
        isReason:false,
        isComplete:false,
        isAction:false,
      })
  }
    const [filterContent, setFilterContent] = useState([] as any);
    const [staff, setStaff] = useState([] as any);
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
         staff:'',
        cost:'',
        date_repair:'',
        reason:'',
        date_assign:''
       
     })
    


     const fetchStaff =async()=>{
        let url = ServerUrl+'/api/employees/view/'+COMPANY_CODE
        const {data} = await axios.get(url, config)
        setStaff(data.data)
        }
      
   
     const handleSelect = (option:any, action:any)=>{
        setSearch({...search, [action.name]:option});
          setErrors({...errors, [action.name]:''})

           }

          

           const handleChange = (event:ChangeEvent<HTMLInputElement>)=>{
           
            let {name, value} = event.target;	
            setSearch({...search, [name]:value});
           setErrors({...errors, [name]:''});
        }

        
const handleReset =()=>{
    setSearch({
      asset_name:'',
      from_date:'',
      to_date:'',
      status:{label:'All', value:'All'},
      rowperpage:{value:'10', label:'10'},
      filterText:'',
      selectAll:false,
      totalSelected:0,
    })
}



  const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	
    setSearch({...search, [name]:value});

    if(value!==''){
        const filteredItems = content.filter(
            (item:any) => item.reason.toLowerCase().includes(value.toLowerCase()) || item.asset_name.toLowerCase().includes(value.toLowerCase()) ||
            item.cost.toLowerCase().includes(value.toLowerCase()),
        );

        setFilterContent(filteredItems)
    }else{
        setFilterContent(content)
    } 

 }


const handleClear =()=>{
setSearch({...search, filterText:''});
setFilterContent(content)
}





const DeleteModal =(code:string)=>{

    let url = ServerUrl+'/api/assets/repair/delete/'+code;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =filterContent.filter((item:{code:string})=>item.code !==code)
            setContent(rs)
            setFilterContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }


const handleSelectChange =()=>{
    let status = search.selectAll ===false?'true':'false';
    const newContent = filterContent.map((item:any)=>{
        return {...item, 
            is_check:status,
        }
  })

  var i = 0;
  newContent.forEach((element:any) => {
      if(element.is_check=== 'true')
     i++
    });

    setFilterContent(newContent)

    setSearch({...search, selectAll:!search.selectAll, totalSelected:i})

   }
   
   

   const handleSelectAll =(list:[])=>{
      
    let is_check = list.every((element:any) => {
     return element.is_check === 'true'
    });
    var i = 0;
        list.forEach((element:any) => {
          if(element.is_check === 'true')
         i++
        });
    setSearch({...search, selectAll:is_check, totalSelected:i})
    
       }



const handleCheck =(code:string)=>{
    const newContent = filterContent.map((item:any)=>{
       
    if(item.code === code){
    return {...item, 
        is_check:item.is_check==='false'?'true':'false',
    }
    };
    return item;
    })
    setFilterContent(newContent)
    handleSelectAll(newContent)
    }
    
            
const CheckboxAll =()=>{ 

    return <div className="chk-option">
    <div className="checkbox-fade fade-in-primary">
        <label className="check-task">
    
        <input type="checkbox"  id="selectAll" checked={search.selectAll} onChange={handleSelectChange} />
                    
        <span className="cr">
            <i className="cr-icon fa fa-check txt-default"></i>
        </span>
        </label>
    </div>
    </div>}


const Checkbox =({code, is_check}:{code:string, is_check:string})=>{ 

return <div className="chk-option">
<div className="checkbox-fade fade-in-primary">
    <label className="check-task">

    <input type="checkbox"  name={code} id={code} checked={is_check==='true'?true:false} onChange={()=>handleCheck(code)} />
                
    <span className="cr">
        <i className="cr-icon fa fa-check txt-default"></i>
    </span>
    </label>
</div>
</div>}

        const TableData =()=>{

            

            const PaginationWrapper =(props:any)=>{
            
                const handleRowsPerPage = useCallback(
                    (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
                    [props.currentPage, props.onChangeRowsPerPage],
                );
                const handleSelectRowsPerPage = useCallback((option:any, action:any)=> props.onChangeRowsPerPage(Number(option.value), props.currentPage),
                [props.currentPage, props.onChangeRowsPerPage],
                )
               
                function getNumberOfPages(rowCount:number, rowsPerPage:number) {
                    return Math.ceil(rowCount / rowsPerPage);
                  }
                const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
                const lastIndex = props.currentPage * props.rowsPerPage;
                const firstIndex = lastIndex - props.rowsPerPage + 1;
            
            
                    const range =
                    props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
            
                    `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
            
                
               
                const Pagination = () => {
                   
                    const pageSize = props.rowsPerPage;
                    const totalCount = content.length
                    const siblingCount = 1
                    const currentPage = props.currentPage
                    
                    const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
                    
                    if (currentPage === 0 ) {
                      return null;
                    }
                    
                    let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
                    return (
                    <nav className="" aria-label="Page navigation sample ">
                      <ul  className="pagination pagination-sm"  >
                        <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
                         <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Previous</a>
                        </li>
                        {paginationRange&&paginationRange.map((pageNumber, index) => {
                          if (pageNumber === DOTS) {
                            return <li className="pagination-item dots" key={index}>
                             <a className="page-link pointer"  >&#8230;</a>
                            </li>
                          }
                          return (
                            <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                              <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                              
                            </li>
                          );
                        })}
                    
                    <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
                    <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
                        </li>
                    
                      </ul> </nav>
                    );
                    
                        }
            
                      
            
                      
            
             return <div className="row mt-3">
                                
            <div className="col-md-5 ">
            <div className="row ml-3" >
            
               Rows per page: &nbsp; <select name="rowPerPage" id="" className='w-40 h-30 ' onChange={handleRowsPerPage}>
                    <option value="10"> 10</option>
                    <option value="20"> 20</option>
                    <option value="30"> 30</option>
                    <option value="40"> 40</option>
                    <option value="50"> 50</option>
                    <option value={content.length}> All</option>
                </select> &nbsp;&nbsp;
                {/*  {range} */} </div>
            </div>
              
            <div className="col-md-7  justify-content-end d-flex">
               <Pagination  />
                </div>
                
            </div>}
            
            
            
            
                        const columns = [
            
                            {
                                name: <CheckboxAll />,
                                cell: (row:any) => <Checkbox code={row.code} is_check={row.is_check} />, 
                                width:'60px'
                            },
                         
                            {
                                name: 'Name',
                                selector: (row:any) => row.asset_name,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isName,
                                
                            },
                            {
                                name: 'Repair Date',
                                selector: (row:any) => row.repair_date,
                                sortable: true,
                                reorder:true,
                                omit: director.isDate,
                                wrap:true,
                            },
                            {
                                name: `Cost (${CURRENCY})`,
                                selector: (row:any) => FormatNumber(row.cost),
                                sortable: true,
                                reorder:true,
                                omit: director.isCost,
                            },
                            {
                                name: 'Is Complete',
                                cell: (row:any) => row.is_complete==='Yes'? <span className="pcoded-badge label label-success"> <i className="fa fa-envelope-o"></i> Yes</span>: <span className="pcoded-badge label label-danger"> <i className="fa fa-clock-o"></i> Pending</span>,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isComplete,
                                
                            },
                            {
                                name: 'Reason',
                                selector: (row:any) => row.reason,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isReason,
                                
                            },
                            {
                              name: 'Action',
                              cell: (row:any, index:any, column:any, id:any) => <div className='row'>  
                                  <label className="label label-info pointer" ><i className='ti-pencil'></i> Edit</label>
                              <label className="label label-danger pointer" onClick={()=>DeleteModal(row.code)}><i className='ti-trash'></i> Delete</label></div>,
                                  left:true,
                                  width:'200px',
                                  omit: director.isAction,
                                        
                  
                          },
                        ];
                        
                    
                    
                    
                        return <DataTable
                        columns={columns}
                        data={filterContent}
                        highlightOnHover
                        pagination
                        paginationComponent={PaginationWrapper}
                        selectableRowsHighlight
                        striped
                        responsive
                        fixedHeader
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                    />
                    }
            




         const fetchContent = async()=>{
            setLoading(true)
     

          
                 let sql = " SELECT 'false' as is_check, r.code, a.asset_name, r.cost, r.reason, r.is_complete, r.repair_date FROM tbl_assets a LEFT JOIN tbl_repair_asset r on a.code = r.asset_code WHERE  a.asset_name <> '' "
     
                 

                if(search.from_date && search.to_date ){
                  sql = sql + ' and r.repair_date between  "'+search.from_date+'" and "'+search.to_date+'"'
              }


                if(search.asset_name!==''){
                    sql = sql + " and a.asset_name = '"+search.asset_name+"'"
                }

                if(search.status.value!=='All'){
                    sql = sql + " and r.is_complete = '"+search.status.value+"'"
                }
                sql = sql + 'order by r.id DESC  '


                 const fd = new FormData();
                 fd.append('sql', sql); 
                 let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
               await  axios.post(url, fd, config).then((result:any)=>{

                 if(Array.isArray(result.data.data) && result.data.data.length!==0){
                    setFilterContent(result.data.data)
                   setContent(result.data.data)

                   }else{

                    setContent([])
                    setFilterContent([])
                   }
                 })
                 .catch((error)=>{
                     Alerts('danger',error.message)
                 }).finally(()=>{
                     setLoading(false)
                 })
                 } 





                 const handleDeleteAll =()=>{

                    if(search.totalSelected<1){
                      Alerts('danger','Please select at least one record to delete ')
                    }else{
                  
                      let assetsList =[] as any;
                      let selectedAssets = filterContent.filter((item:any)=>item.is_check ==='true')
                  
                      if(Array.isArray(selectedAssets)){
                        selectedAssets.forEach((list:any) => {
                            assetsList.push(list.code)
                        });
                      }
                  
                      const fd = new FormData();
                      fd.append('assetsList', JSON.stringify(assetsList, null, 2));
                      let url = ServerUrl+'/api/assets/repairs/destroy_multiple/'+COMPANY_CODE;
                      Swal.fire({
                        title: 'Are you really sure?',
                        text: "This is very dangerous, you shouldn't do it!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        showLoaderOnConfirm: true,
                      preConfirm: async() => {
                        return await axios.post(url, fd, config).then(response=>{
                         if(response.data.status!==200){
                              throw new Error(response.statusText)
                            }
                            return response.data
                          })
                          .catch(error => {
                            Swal.showValidationMessage(
                              `Request failed: ${error}`
                            )
                          })
                      },
                      allowOutsideClick: () => !Swal.isLoading(),
                        confirmButtonText: 'Yes, I am!'
                      }).then((result:any) => {
                        if (result.isConfirmed && result.value.status===200) {
                  
                          let rs =filterContent.filter((item:any)=>item.is_check ==='false')
                         setContent(rs)
                         setFilterContent(rs)
                           Swal.fire(
                                      'Deleted!',
                                      'Record has been deleted.',
                                      'success'
                                    ) 
                        }
                      })
                  
                    }
                  
                   }

    useEffect(()=>{
        fetchStaff()
       fetchContent()
      }, [])


    

  return ( <>
<Breadcrumb 
   title="Repair History"
   summary="Asset Repair History"
   >
    <li className="breadcrumb-item">
    <Link to="/assets/display_assets">Assets</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Report</a> </li>
         <li className="breadcrumb-item"><a href="#!">Repair History</a> </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">



    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Repair History</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                              
                                    
                                    
         <div className="content-wrappers ">
                            
         <div className=''>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Assets Repair History</h5>
                <span>Add all offcie department</span>
              
            </div>
            <div className="card-block">
    
        
                        <div className="row">
                  

    <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Search By Repair Status </label>


                                <Select  options={[
                                  { value: 'All', label: 'All' },
                                  { value: 'Pending', label: 'Pending' },
                        { value: 'Completed', label: 'Completed' }
                              ]}
                                    
                          onChange={handleSelect} 
                          name="status" 
                          value={search.status} 
                          
                          styles={styles}  
                          /> 
                        

                                 </div>
                                 </div>

                             



                                 <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">From Date</label>
           
            <input type="date"   className="form-control form-control-lg"  name='from_date' value={search.from_date} onChange={handleChange}  />

             </div>

             </div>


             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">To Date</label>
           
            <input type="date"   className="form-control form-control-lg"  name='to_date' value={search.to_date} onChange={handleChange}  />

             </div>

             </div>



                     <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Search By Asset Name </label>
           
<input type="text"  className="form-control form-control-lg"   name='asset_name' value={search.asset_name} onChange={handleChange}  />
          

             </div>

             </div>

   </div>

          
<hr />
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-outline-danger btn-round pr-5 pl-5 mr-3" type='button' onClick={handleReset}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={fetchContent}>Search</button>
</div>
</div>
    
            </div>
        </div>
    </div>
    </div>
    
    
    

    
                </div>

        </div>
                       
                    </section>
                </div>
            </div>
        </div>
    </div>
                                    </div>
                                </div>
                            </div>







                            <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Assets Repair History</h5>
                <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
              
            </div>
            <div className="card-block">
    




            <div className="row">

<div className=" col-md-8 ">


                  &nbsp;

                  <a href='#!' className="btn btn-primary btn-sm "><i className='fa fa-print text-white'></i></a>&nbsp;

                  <button type="button" className="btn btn-outline-danger btn-sm " onClick={handleDeleteAll} ><i className="fas fa-trash"></i> Delete Selected ({search.totalSelected})</button>&nbsp;

  <div className="btn-group ">
                   
                    <button type="button" className="btn btn-inverse dropdown-toggle dropdown-icon  btn-sm" data-toggle="dropdown">
                      <span className="sr-only">Toggle Dropdown</span> <i className='fas fa-fw fa-filter'></i> Visibility &nbsp;
                    </button>
                    <div className="dropdown-menu" role="menu">

                      <a className={!director.isName?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isName:!director.isName})}>Name</a>

                      <a className={!director.isDate?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isDate:!director.isDate})}>Repair Date</a>

                      <a className={!director.isCost?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isCost:!director.isCost})}>Cost</a>


                      <a className={!director.isComplete?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isComplete:!director.isComplete})}>Is Complete</a>

                      <a className={!director.isReason?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isReason:!director.isReason})}>Reason</a>

                        <a className={!director.isAction?"dropdown-item":"dropdown-item active"} href="#!" onClick={()=>setDirector({...director, isAction:!director.isAction})}>Action</a>

                    <div className="dropdown-divider"></div>
                      <a className={"dropdown-item"} href="#!" onClick={handleResetColumn}>Reset Column</a>
                  
                    </div>
                  </div>
                  </div>

    <div className=" col-md-4 ">
    <div className="input-group  " >
                    <input type="text" name="filterText" id="filterText" key="filterText"  onChange={handleSearch} value={search.filterText} className="form-control float-right form-control-sm" placeholder="Type to search" />
                   
                    <div className="input-group-append">
                        <button type="button" onClick={handleClear} className="btn btn-default btn-sm" >
                          <i className="fas fa-close"></i>
                        </button>
                      </div>
                  </div>
      

    </div>
</div>

    <div className="row">
        <div className="table-responsive ">
        <TableData />
        </div>
        </div>

            </div>
        </div>
    </div>
    </div>


                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default RepairHistory