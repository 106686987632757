import { useEffect, useState} from 'react'
import { COMPANY_CODE, CURRENCY, ImagesUrl, ServerUrl, config, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, closeModal, Alerts } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'

import { useQuery } from 'react-query'
import { FormatNumber } from '../../components/GlobalFunction';

import { DownloadDocument } from './UsageReportPDF';
import { PDFDownloadLink} from '@react-pdf/renderer';
import { FetchProfile, fetchDepartment, fetchJobs } from '../../api/ReactQuery';
import { Link } from 'react-router-dom';
const  UsageReport =()=> {

    const profile = useQuery(["profile"], FetchProfile)
    const department = useQuery(["department"], fetchDepartment)
    const Jobs = useQuery(["jobs"], fetchJobs)
    const [template, setTemplate] = useState([] as any);
    const [search, setSearch] = useState({
     
        employee:[] as any,
        leave_type:[] as any,
        includes:{value:'', label:''},
        job_title:{value:'', label:''},
        department:{value:'', label:''},
        date_range:  {label:'2023-01-01 - 2023-12-31', value:'2023-01-01 - 2023-12-31', start_date:'2023-01-01', end_date:'2023-12-31'},
     })


    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        
        employee:'',
         date_range:'',
         leave_type:''
       
     })
   
   
      


     const handleSelect = (option:any, action:any)=>{
        setSearch({...search, [action.name]:option});
          setErrors({...errors, [action.name]:''})
setContent([])
          

           }


       

const DateList = [
    {label:'2023-01-01 - 2023-12-31', value:'2023-01-01 - 2023-12-31', start_date:'2023-01-01', end_date:'2023-12-31'},
    {label:'2024-01-01 - 2024-12-31', value:'2024-01-01 - 2024-12-31',  start_date:'2024-01-01', end_date:'2023-12-31'},
    {label:'2025-01-01 - 2025-12-31', value:'2025-01-01 - 2025-12-31', start_date:'2025-01-01', end_date:'2023-12-31'},
    {label:'2026-01-01 - 2026-12-31', value:'2026-01-01 - 2026-12-31', start_date:'2026-01-01', end_date:'2023-12-31'},
]
        
const handleReset =()=>{
    setSearch({
        employee:[] as any,
        leave_type:[] as any,
        includes:{value:'', label:''},
        job_title:{value:'', label:''},
        department:{value:'', label:''},
        date_range:  {label:'2023-01-01 - 2023-12-31', value:'2023-01-01 - 2023-12-31', start_date:'2023-01-01', end_date:'2023-12-31'},
    })
    setContent([])
}

            

                       const fetchTemplate =async()=>{
                        let url = ServerUrl+'/api/leave/view_leave_type/'+COMPANY_CODE
                        await  axios.get(url, config).then((result:any)=>{
                          if(Array.isArray(result.data.data) && result.data.data.length!==0){
                            setTemplate(result.data.data)
                            }
                          })
                          .catch((error)=>{
                              Alerts('danger', error.message)
                          })
                        } 




                    const fetchContent= async()=>{
                        
                   
                            
                let error = {...errors}; 
                let formIsValid = true;

            let msg ='This field is required';
                          
           
                    if(search.leave_type.length===0){
                        error.leave_type=msg;
                        formIsValid = false;
                    } 


                    if(search.date_range.value===''){
                        error.date_range =msg;
                        formIsValid = false;
                    } 


                  

                    if(!formIsValid){
                    setErrors(error) 
                    }else{

                    
                         LoadingModal()                           

               let sqlString = "  IFNULL((SELECT sum(r.no_days) from tbl_leave_application r where  r.staff_code = e.code and  r.company_code = '"+COMPANY_CODE+"' and r.leave_code = '"+search.leave_type.value+"' and r.start_date >= '"+search.date_range.start_date+"' and r.end_date <= '"+search.date_range.end_date+"' and r.is_approved = 'Pending'),0) as  Pending "


               let sqlStringb = "  IFNULL((SELECT sum(r.no_days) from tbl_leave_application r where  r.staff_code = e.code and  r.company_code = '"+COMPANY_CODE+"' and r.leave_code = '"+search.leave_type.value+"' and r.start_date >= '"+search.date_range.start_date+"' and r.end_date <= '"+search.date_range.end_date+"' and r.is_approved = 'Approved'),0) as  Approved "

              // "+String(sqlString)+ ", "+String(sqlStringb)+ "

                let  final =   "SELECT e.first_name, e.last_name, a.staff_code, t.no_days AS assigned_days, l.leave_name, "+String(sqlString)+ ", "+String(sqlStringb)+ " FROM tbl_employee e, tbl_leave_application a, tbl_assign_leave t, tbl_leave_type l WHERE a.staff_code = e.code and l.code = a.leave_code and a.staff_code = t.staff_code  and  a.company_code = '"+COMPANY_CODE+"' and a.leave_code = '"+search.leave_type.value+"' and l.code = '"+search.leave_type.value+"' and t.leave_type_code = '"+search.leave_type.value+"' "




                if(search.department.value!==''){
                    final = final + " and e.department = '"+search.department.value+"'"
                }

                if(search.job_title.value!==''){
                    final = final + " and e.job_title = '"+search.job_title.value+"'"
                }

                if(search.includes.value!==''){

                    if(search.includes.value==='Current'){
                        final = final + " and e.termination_reason = ''"
                    }else if(search.includes.value==='Past'){
                        final = final + " and e.termination_reason <> ''"
                    }
                   
                }

                const fd = new FormData();
                 fd.append('sql', final); 
                 let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
               await  axios.post(url, fd, config).then((result:any)=>{

                 if(Array.isArray(result.data.data) && result.data.data.length!==0){
                    setContent(result.data.data)

window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
                   }else{
                    Alerts('default', 'No record found')
                    setContent([])
                   }
                 })
                 .catch((error)=>{
                     Alerts('danger',error.message)
                 }).finally(()=>{
                     closeModal()
                 })

                    }
                             }
                             
                  
useEffect(()=>{
fetchTemplate()
},[])
  return ( <>
    <Breadcrumb 
   title="Report"
   summary="Select any leave type to view its allocation and usage history"
   >
   <li className="breadcrumb-item">
    <Link to="/leave/leave_list">Leave</Link>
                    </li>
   <li className="breadcrumb-item">
    <Link to="#!">Usage Report</Link>
                    </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Leave Allocation and Usage Report</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                              
                                    
                                    
         <div className="content-wrappers ">
                            
         <div className=''>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Search Report</h5>
                <span>Add all offcie department</span>
              
            </div>
            <div className="card-block">
    
        
                        <div className="row">
                  

                        <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Select Leave Type</label>

                    <Select  options={
                template&&template.map((list:any, idx:number)=> {
                    return {key:idx, value: list.code, label: list.leave_name}
                    })}
                    className={errors.leave_type?"form-control form-control-danger":""}              
                    onChange={handleSelect}
                     name="leave_type" 
                     value={search.leave_type} 
    
                    styles={styles} 
                    />  
                

                {errors.employee&&
    <div className="col-form-label has-danger">{errors.employee&&errors.employee} </div>}
                    </div>
    
                     </div>


                     <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Date Range </label>
           
            <Select  options={
                               DateList&&DateList.map((list:any, idx:number)=> {
                                return {key:idx, value: list.value, label: list.label, start_date:list.start_date, end_date:list.end_date}
                                })} 
                        className={errors.date_range?"form-control form-control-danger":""} 
                        onChange={handleSelect}  name="date_range"
                value={search.date_range} 
                styles={styles} 
            />
          
          {errors.date_range&&
    <div className="col-form-label has-danger">{errors.date_range&&errors.date_range} </div>}

             </div>

             </div>


                     <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Search by Department</label>
    
    
    <Select  options={
            department.data&&department.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.department_name}
                    })}   
                    styles={styles} 
			onChange={handleSelect}    
            name="department" 
            value={search.department} 
               />
    
    </div>
    
    </div>

    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Search by Job Title</label>
    
    
    <Select options={
            Jobs.data&&Jobs.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.title}
                    })}   
                  
			onChange={handleSelect} 
            styles={styles} 
            name="job_title" value={search.job_title} 
              
              />
    
    </div>
    
    </div>

                    
   

                    


             <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Includes</label>
    
    
  <Select  options={[
                    { value: 'Current', label: 'Current Employee Only' },
                    { value: 'Past', label: 'Past Employee Only'},
                    { value: 'All', label: 'All Employee'}
                                ]} 
                onChange={handleSelect}  
                name="includes"
                 value={search.includes} 
                 styles={styles} 
                 
                 />
    
    </div>
    
    </div>


           

   </div>

          
<hr />
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-outline-danger btn-round pr-5 pl-5 mr-3" type='button' onClick={handleReset}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={fetchContent}>Search</button>
</div>
</div>
    
            </div>
        </div>
    </div>
    </div>
    
    
    
                </div>

        </div>
                       
                    </section>
                </div>
            </div>
        </div>
    </div>
                                    </div>
                                </div>
                            </div>




{content.length!==0?
  <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-block">
    

    <div className="row">
        <div className="table-responsive ">
       
        <table className="table table-sm table-bordered table-hover">
                <thead>
                    <tr>
                        <th colSpan={6} className='text-center '>
                        <img 
           src={ImagesUrl+'/about/'+profile.data.logo}  alt="Click to change" 
           className="m-b-10 img-100" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/logo.png'}} />


                        <h4>{profile.data.company_name} </h4>
                        <h5>{profile.data.company_address}</h5>
                        <h6 className='m-b-5'>{profile.data.email}, {profile.data.phone}</h6>

                        <div className="alert alert-info text-center mb-1 mt-2">

                        <h5> LEAVE ALLOCATION REPORT</h5>
                        </div>
                        </th>
                    </tr>
                    <tr>
                        <td colSpan={4}>  <h6>Leave Name: {search.leave_type.label }</h6></td>
                        <td colSpan={2}>  <h6>Date Range: {search.date_range.label}</h6></td>
                    </tr>
                    <tr style={{backgroundColor:'#E7E0EE'}}>
                    <th >SN</th>
                        <th >Staff Name</th>
                        <th >Assigned Days</th>
                        <th >Pending Approval</th>
                        <th >Approved Leave</th>
                        <th >Balance</th>

                    </tr>
                </thead>
                <tbody>
                    {content&&content.map((list:any, index:number)=>
                    <tr key={index}>
                        <td >{index+1}</td>
                        <td >{list.first_name+ ' '+ list.last_name}</td>
                        <td >{list.assigned_days}</td>
                        <td >{list.Pending}</td>
                        <td >{list.Approved}</td>
                        <td >{Number(list.assigned_days) - Number(list.Approved)}</td>
                          
                    </tr>)}

                </tbody>

                   
            </table>

        </div>
        <PDFDownloadLink 
        
        document={<DownloadDocument 
        
    dateRange={search.date_range.label}
    data={content} 
    name={search.leave_type.label}

        />} 
        fileName={search.leave_type.label+'.pdf'}
        className="btn btn-primary btn-sm text-right no-print"  
                    >
                  {({ blob, url, loading, error }) => (loading ? 'Loading document...' :  <span   ><i className='fa fa-download'></i> Download</span>)}
                </PDFDownloadLink>


        </div>

            </div>
        </div>
    </div>
    </div>:[]}
    

                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default UsageReport