import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo} from 'react'
import { COMPANY_CODE, ImagesUrl, STAFF_CODE, ServerUrl, config, configFLW } from '../../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal, CustomLoader } from '../../../components/notify';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { useQuery } from 'react-query'
import { fetchEmployee } from '../../../api/ReactQuery';

import Select, { components } from 'react-select'
const Termination =({code}:{code:any})=> {


    const [disabled, setDisabled] = useState(false);

    const {data} = useQuery(["employee", code], fetchEmployee)
    
    const [termination, setTermination] = useState([] as any);
    const [content, setContent] = useState([] as any);

     const handleSelect = (option:any, action:any)=>{
        const newProfile = content.map((item:any)=>{
            return {...item, 
                [action.name]:option.value}
          })
          setContent(newProfile)

           }



        const handleChangeArea =(event:ChangeEvent<HTMLTextAreaElement>)=>{
            let {name, value} = event.target;	
          const newProfile = content.map((item:any)=>{
            return {...item, [name]:value}
          })
          setContent(newProfile)
         }

        const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
          const newProfile = content.map((item:any)=>{
            return {...item, [name]:value}
          })
          setContent(newProfile)
         }




   
const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    LoadingModal()
 const fd = new FormData();
    Object.entries(content[0]).forEach(([key, value]) => {
            fd.append(key, String(value));
        });

        const log = JSON.parse(content[0].update_log)
        const item = log.concat( {
            code:STAFF_CODE,
            action:'Termination: Updated Termination',
            date_time: new Date()
        })


const logs = JSON.stringify(item,null, 2)

        fd.append('logs', logs);
       

   let url = ServerUrl+'/api/employee/update/'+code;
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                ErrorModal('', response.data.message)
              }   
          })
          .catch((error)=>{
           ErrorModal('oops', error.message)
          }) 

}




       


 useEffect(()=>{
    if (data!==undefined)
    setContent(data)
    window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
         
    }, [data])

    const getTitleName = (code:string, field:string) =>{ 
        const result = termination&&termination.filter((list:any)=>list.code===code);
       const answer = result.length !==0?result[0][field]:''
         return  answer;
       }

    const fetchData = async()=>{
       
        let sql = " "
        const fd = new FormData();
        fd.append('sql', sql); 
        let url = ServerUrl+'/api/employees/settings/termination/view/'+COMPANY_CODE
      await  axios.get(url, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){
          setTermination(result.data.data)
          }
        })
        .catch((error)=>{
         // ErrorModal('error', error.message)
        })
        } 

        useEffect(()=>{
            fetchData()
         }, [])
 

  return (
   
    <div>
    <div className="card z-depth-0">
  
  <div className="card-header">
      <h5>Terminate Account</h5>
  
  </div>
  {content.length!==0? content.map((profile:any, index:number)=>  <div key={index}  className="card-block">
 
  
  
           <div className="row">
           <div className="col-md-6">
           <div className="form-group">
          <label className="col-form-label">Termination Reason </label>
         
           
<Select options={
                              termination&&termination.map((list:any, idx:number)=> {
                                return {key:idx, value: list.code, label: list.reason }
                                })}
                                 
    onChange={handleSelect}  name="termination_reason" value={{label:getTitleName(profile.termination_reason, 'reason'), value:profile.termination_reason}} 
    isDisabled={disabled}
    /> 
           </div>
  
           </div>


           <div className="col-md-6  m-b-30">
        <h4 className="sub-title">Account Status</h4>
        <div className="form-radio">
                <div className="radio radio-inline">
                    <label>
                        <input type="radio" name="account_status" value="Enabled" onChange={handleChange} checked={profile.account_status ==='Enabled'?true:false}  />
                        <i className="helper"></i>Enabled
                    </label>
                </div>
                <div className="radio radio-inline">
                    <label>
                        <input type="radio" name="account_status" value="Disabled" onChange={handleChange} checked={profile.account_status ==='Disabled'?true:false} />
                        <i className="helper"></i>Disabled
                    </label>
                </div>
        </div>
        </div>
  
  
           <div className="col-md-12">
           <div className="form-group">
          <label className="col-form-label">Termination Note <span className='text-danger'>*</span></label>
         
          <textarea rows={5} className="form-control"
            name='termination_note' value={profile.termination_note} 
         
            onChange={handleChangeArea} disabled={disabled} >

            </textarea>
        
        
           </div>
  
           </div>
  
  
  
  
  
          </div><hr/>
  
  
          <div className="row">
  <div className="col-md-12 text-right">
  <button className="btn btn-success btn-round" type='button' onClick={handleSubmit}>Save Details</button>
  </div>
  </div>
  
  
  
             </div>):''}
             </div>
      </div>
  )
}

export default Termination