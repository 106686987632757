import { useEffect, useState} from 'react'
import { COMPANY_CODE, ImagesUrl, ServerUrl, config } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, closeModal, Alerts } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'
import { useQuery } from 'react-query'

import { useParams} from 'react-router'
import { FetchProfile, fetchTermination } from '../../api/ReactQuery';
import { Link } from 'react-router-dom';

const PreviewLeave =()=> {
  let params = useParams()
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [criteria, setCriteria] =useState('');
    const [group, setGroup] =useState([] as any);
    const [title, setTitle] = useState([] as any);


  const [staff, setStaff] = useState([] as any);
  const {data} = useQuery(["profile"], FetchProfile)
    const [profile, setProfile] = useState([] as any);

const fetchReport =()=>{

  LoadingModal()
  
  let url = ServerUrl+'/api/leave/edit_report/'+COMPANY_CODE+'/'+params.code
  axios.get(url, config).then(result=>{
    if(result.data.status===200){
      try {
          
          let group_item  = JSON.parse(result.data.data.group)
          setGroup(group_item)
          setTitle(result.data.data.title)
          setCriteria(result.data.data.criteria_sql)

      }catch(e){

      }
     
    }
  })
  .catch((error)=>{
   Alerts('danger', error.message)
  }).finally(()=>{
      closeModal()
  })
  }

  const fetchStaff =async()=>{
        let url = ServerUrl+'/api/employees/view/'+COMPANY_CODE
        const {data} = await axios.get(url, config)
        setStaff(data.data)
        }


  const getTitleName = (code:string, field:string) =>{ 
    const result = staff&&staff.filter((list:any)=>list.code===code);
   const answer = result.length !==0?result[0][field]:''
     return  answer;
   }




  const fetchContent = async()=>{
    setLoading(true)



         let sql = "SELECT e.title, e.first_name, e.last_name, e.middle_name, e.nickname, e.file_number, e.passport_no, e.nin, e.birth_cert, e.driver_license, e.other_id, e.gender, e.tribe, e.marital_status, e.religion, e.blood_group, e.language, e.date_of_birth, e.place_of_birth, e.is_smoker,  a.staff_code, a.no_days, l.leave_name, l.time_cycle, a.start_date, a.end_date, a.reason, a.created_at, a.is_approved,  a.current_year, a.approved_by, a.added_by  FROM tbl_employee e, tbl_leave_application a, tbl_leave_type l WHERE l.code = a.leave_code and e.code = a.staff_code and a.company_code = '"+COMPANY_CODE+"'"


         if(criteria !== null){
          sql =  sql + criteria;
         }
         
         const fd = new FormData();
         fd.append('sql', sql); 
         let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
       await  axios.post(url, fd, config).then((result:any)=>{
         if(Array.isArray(result.data.data) && result.data.data.length!==0){
          
           setContent(result.data.data)
           }
         })
         .catch((error)=>{
            Alerts('danger',error.message)
         }).finally(()=>{
             setLoading(false)
         })
         } 

         useEffect(()=>{
          fetchContent()
      },[criteria])

      const getHeaderName = (name:string)=>{
        const field = group.map((item:any)=>item.field_name)
        const header = group.filter((item:any)=>item.field_name == name)
        let isHeader = header.length!==0?header[0].isHeader:false

        if(isHeader && field.includes(name)){
          return true
        }else{
          return false
        }
          
      }


      const getSubHeaderName = (field_name:string, name:string)=>{
        const field = group.filter((item:any)=>item.field_name == field_name)
        let data = field.length!==0?field[0].data:[]
        const all_data = data.map((item:any)=>item.label)

       return all_data.includes(name)
          
      }



      useEffect(()=>{

        try{
     
         if (data!==undefined && data.length!==0){
         setProfile(data)
             
         }
     
        }catch(err) {
     
        }
         
         }, [data]) 



      const PrintMe = () =>{     
        let printContents:any = document.getElementById('printablediv')?.innerHTML;
          let originalContents = document.body.innerHTML;
          document.body.innerHTML = printContents;
          window.print();
         document.body.innerHTML = originalContents; 
        }


  useEffect(()=>{
      fetchReport()
      fetchStaff()
  },[])

  return ( <>
 <Breadcrumb 
   title=" Template"
   summary={title}
   >
    <li className="breadcrumb-item">
    <Link to="#!">Report</Link>
                    </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
<div className="main-body">
<div className="page-wrapper">
<div className="page-body">



<div className="row">
<div className=" col-md-12">
<div className="card z-depth-0">
    <div className="card-block " >
       


<div className="table-responsives m-t-10">




          <div className="row">
            
            <div className="col-md-12" id='printablediv'>
            
<div className="table-responsive ">


<div className='text-center '>

<img 
src={ImagesUrl+'/about/'+profile.logo}  alt="Click to change" 
className="m-b-10 img-100" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/logo.png'}} />


<h4>{profile.company_name} </h4>
<h5>{profile.company_address}</h5>
<h6 className='m-b-5'>{profile.email}, {profile.phone}</h6>

<div className="alert alert-info text-center mb-1 mt-2">

<h5> {title}</h5>
</div>
</div>

              <table id="simpletable" className="table  table-bordered table-sm" role="grid" >
                
            <thead>
            <tr >
          {getHeaderName('Personal Details')?
                  <th colSpan={20} className='text-center'>Personal Details</th>:[]}
                  {getHeaderName('Contact Address')?
                  <th colSpan={16} className='text-center'>Leave Details</th>:[]}
                 
                 
                  
                  </tr>

                <tr style={{backgroundColor:'#E7E0EE'}}>
          {getSubHeaderName('Personal Details', 'Title')?
                  <th >Title</th>:[]}

        {getSubHeaderName('Personal Details', 'First Name')?
                  <th >First Name</th>:[]}

                  {getSubHeaderName('Personal Details', 'Last Name')?
                  <th >Last Name</th>:[]}

                  {getSubHeaderName('Personal Details', 'Middle Name')?
                  <th>Middle Name</th>:[]}

                  {getSubHeaderName('Personal Details', 'Nickname')?
                  <th >Nickname</th>:[]}

                  {getSubHeaderName('Personal Details', 'File Number')?
                  <th >File Number</th>:[]}

                  {getSubHeaderName('Personal Details', 'Passport ID')?
                  <th >Passport ID</th>:[]}

                  {getSubHeaderName('Personal Details', 'NIN')?
                  <th >NIN</th>:[]}

                  {getSubHeaderName('Personal Details', 'Birth Certificate No.')?
                  <th>Birth Cert. No.</th>:[]}

                  {getSubHeaderName('Personal Details', 'Driving License No')?
                  <th >Driving License No</th>:[]}

                  {getSubHeaderName('Personal Details', 'Other ID')?
                  <th >Other ID</th>:[]}

                  {getSubHeaderName('Personal Details', 'Gender')?
                  <th >Gender</th>:[]}

                  {getSubHeaderName('Personal Details', 'Is Smoker')?
                  <th >Smoker</th>:[]}
                  {getSubHeaderName('Personal Details', 'Tribe')?
                  <th>Tribe</th>:[]}

                  {getSubHeaderName('Personal Details', 'Marital Status')?
                  <th >Marital Status</th>:[]}

                  {getSubHeaderName('Personal Details', 'Religion')?
                  <th >Religion</th>:[]}

                  {getSubHeaderName('Personal Details', 'Language Spoken')?
                  <th >Language Spoken</th>:[]}

                  {getSubHeaderName('Personal Details', 'Blood Group')?
                  <th >Blood Group</th>:[]}

                  {getSubHeaderName('Personal Details', 'Date of Birth')?
                  <th>Date of Birth</th>:[]}

                  {getSubHeaderName('Personal Details', 'Place of Birth')?
                  <th >Place of Birth</th>:[]}





            {getSubHeaderName('Leave Details', 'Leave Name')?
                  <th >Leave Name</th>:[]}

                  {getSubHeaderName('Leave Details', 'Time Cycle')?
                  <th >Time Cycle</th>:[]}

                  {getSubHeaderName('Leave Details', 'Start Date')?
                  <th >Start Date</th>:[]}

                  {getSubHeaderName('Leave Details', 'End Date')?
                  <th>End Date</th>:[]}

                  {getSubHeaderName('Leave Details', 'Reason')?
                  <th >Reason</th>:[]}

                  {getSubHeaderName('Leave Details', 'Date Applied')?
                  <th >Date Applied</th>:[]}

                  {getSubHeaderName('Leave Details', 'Status')?
                  <th >Status</th>:[]}

                  {getSubHeaderName('Leave Details', 'Approved By')?
                  <th >Approved By</th>:[]}

                  {getSubHeaderName('Leave Details', 'Created By')?
                  <th>Created By</th>:[]}

                  {getSubHeaderName('Leave Details', 'Year Applied')?
                  <th >Year Applied</th>:[]}


                  
                  </tr>
            </thead>
            <tbody>
                
            {content.length!==0? content.map((list:any, index:number)=>  <tr key={index} >



          {getSubHeaderName('Personal Details', 'Title')?
                  <td >{list.title}</td>:[]}

        {getSubHeaderName('Personal Details', 'First Name')?
                  <td>{list.first_name}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Last Name')?
                 <td>{list.last_name}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Middle Name')?
                  <td >{list.middle_name}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Nickname')?
                  <td>{list.nickname}</td>:[]}

                  {getSubHeaderName('Personal Details', 'File Number')?
                 <td>{list.file_number}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Passport ID')?
                  <td >{list.passport_no}</td>:[]}

                  {getSubHeaderName('Personal Details', 'NIN')?
                   <td>{list.nin}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Birth Certificate No.')?
                  <td>{list.birth_cert}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Driving License No')?
                   <td >{list.driver_license}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Other ID')?
                   <td>{list.other_id}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Gender')?
                 <td>{list.gender}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Is Smoker')?
                    <td >{list.is_smoker}</td>:[]}
                  {getSubHeaderName('Personal Details', 'Tribe')?
                  <td>{list.tribe}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Marital Status')?
                  <td>{list.marital_status}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Religion')?
                   <td >{list.religion}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Language Spoken')?
                   <td>{list.language}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Blood Group')?
                 <td>{list.blood_group}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Date of Birth')?
                <td >{list.date_of_birth}</td>:[]}

                  {getSubHeaderName('Personal Details', 'Place of Birth')?
                 <td >{list.place_of_birth}</td>:[]}

                  


        {getSubHeaderName('Leave Details', 'Leave Name')?
                 <td>{list.leave_name}</td>:[]}

                  {getSubHeaderName('Leave Details', 'Time Cycle')?
                 <td>{list.time_cycle}</td>:[]}

                  {getSubHeaderName('Leave Details', 'Start Date')?
                   <td>{list.start_date}</td>:[]}

                  {getSubHeaderName('Leave Details', 'End Date')?
                 <td>{list.end_date}</td>:[]}

                  {getSubHeaderName('Leave Details', 'Reason')?
                  <td>{list.reason}</td>:[]}

                  {getSubHeaderName('Leave Details', 'Date Applied')?
                  <td>{list.created_at}</td>:[]}

                  {getSubHeaderName('Leave Details', 'Status')?
                   <td>{ list.is_approved}</td>:[]}

                {getSubHeaderName('Leave Details', 'Approved By')?
                   <td>{ getTitleName(list.approved_by, 'first_name') + ' '+ getTitleName(list.approved_by, 'last_name')}</td>:[]}

                  {getSubHeaderName('Leave Details', 'Created By')?
                  <td>{getTitleName(list.added_by, 'first_name')  + ' '+ getTitleName(list.added_by, 'last_name')}</td>:[]}

                  {getSubHeaderName('Leave Details', 'Year Applied')?
                  <td>{list.current_year}</td>:[]}

                 


                </tr>):[]}
                
               
               
                </tbody>

        </table>
        
        </div>
        </div></div>

    </div>

    <div className="row">
        <div className="col-md-12">
        <button type="button"  className="btn btn-success  no-print" onClick={PrintMe}> <i className="fa fa-print"></i> Print</button>
        </div></div>
    </div>
</div>
</div>
</div>


</div>
</div>


</div>
</div>  
        </>               
 
  )
}

export default  PreviewLeave