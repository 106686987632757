import { useState, ChangeEvent, FormEvent, useEffect} from 'react'
import { COMPANY_CODE, ImagesUrl, STAFF_CODE, ServerUrl, config, group_styles, styles } from '../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal, Alerts, closeModal } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'

import { useQuery } from 'react-query'
import Select from 'react-select'
import { fetchDepartment, fetchJobs, fetchSettings } from '../../api/ReactQuery';
import { Link } from 'react-router-dom';
import { WORK_STATUS } from '../../components/Data';
const AddEmployee =()=> {

    const {data} = useQuery(["department"], fetchDepartment)
    const Jobs = useQuery(["jobs"], fetchJobs)

    const settings = useQuery(["settings"], fetchSettings, {
        staleTime: 10 * (60 * 1000), // 10 mins
      })

    const [station, setStation] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        department:'',
        job_title:'',
        first_name:'',
        last_name:'',
        middle_name:'',
        title:'',
        work_email:'',
        mobile_phone:'',
        password:'',
        confirm_password:'',
        imagesFile:'',
        work_station:'',
        employment_status:'',
        group_code:''
     })
const Initial = {
    department:{value:'', label:''},
    job_title:{value:'', label:''},
    work_station:{value:'', label:''},
    employment_status:{value:'', label:''},
    group:{value:'', label:''},
    personal_email:'',
    first_name:'',
    last_name:'',
    middle_name:'',
    title:{value:'', label:''},
    work_email:'',
    mobile_phone:'',
    includeLogin:false,
    password:'',
    confirm_password:'',
    gender:'Male',
    account_status:'Enabled',
    passport:'',
    imagesFile:'' as any,
    filePath:'',
    update_log: JSON.stringify([{
        code:STAFF_CODE,
        action:'Created this account',
        date_time: new Date()
    }],null, 2),

    file_number:Math.floor(Math.random() * 100) + 1

 }

 

     const [profile, setProfile] = useState(Initial);
     const [usergroup, setUsergroup]=useState([])

    const handleSelect = (option:any, action:any)=>{
        setProfile({...profile, [action.name]:option});
          setErrors({...errors, [action.name]:''})
           }


           const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
            setProfile({...profile, [name]:value});
           setErrors({...errors, [name]:''});
         }


    const handlePhoto=()=>{
        var doc = document.getElementById('photo')
        doc?.click()
    }

   const  handleFile = function(fieldName:any){
    return function(newValue:any){                 
   readURL(newValue);
   }
  } 

 

const readURL = (input:any)=>{
 
let displayMessage = '';

const PreviewImage = input.target.name;
const inputElement = document.getElementById('photo') as HTMLInputElement
const fileName = inputElement.value

var idxDot = fileName.lastIndexOf(".") + 1;
var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){
    //TO DO    
    
     var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
if (FileSize > 1) {
    displayMessage = ' File uploaded is larger than maximum size Allow (500 kb).';
    inputElement.value = '';
 
    setProfile({...profile, filePath:'', imagesFile:''})

} else {
    if (input.target.files && input.target.files[0]) { //Check if input has files.
        var reader = new FileReader();//Initialize FileReader.
        reader.onload = function (e:any) {
            
        //Check if input has files.
        let Id = Math.random().toString(36).substring(2, 9)
         let fileUrl = Id+'.png'

        setProfile({...profile, 
            filePath:fileUrl, 
            passport:e.target.result, 
            imagesFile:input.target.files[0]})
          setErrors({...errors, imagesFile:''}); 

        }
        reader.readAsDataURL(input.target.files[0]);
        
    }
}
}else{
displayMessage = 'Only JPEG|PNG|JPG  file format are allowed with maximum of 500 kb'
inputElement.value = '';
setProfile({...profile, filePath:'', imagesFile:''})
}   

if(displayMessage.length > 0){
ErrorModal("", displayMessage)
}
}


const handleReset =()=>{
    setProfile(Initial)
    let inputElement = document.getElementById('photo') as HTMLInputElement
        inputElement.value = ''
}




const fetchGroup = async()=>{
    let url = ServerUrl+'/api/setup/group/'+COMPANY_CODE
  await  axios.get(url, config).then((result:any)=>{
    if(Array.isArray(result.data.data) && result.data.data.length!==0){
      setUsergroup(result.data.data)
      }
    })
    .catch((error)=>{
        Alerts('danger', error.message)
    })
    } 

const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!profile.first_name){
    error.first_name =msg;
    formIsValid = false;
} 


if(!profile.last_name){
    error.last_name =msg;
    formIsValid = false;
} 


if(!profile.work_email){
    error.work_email =msg;
    formIsValid = false;
} 

if(!profile.mobile_phone){
    error.mobile_phone =msg;
    formIsValid = false;
} 


if(!profile.department.value){
    error.department=msg;
    formIsValid = false;
} 

if(!profile.employment_status.value){
    error.employment_status=msg;
    formIsValid = false;
} 


if(!profile.work_station.value){
    error.work_station=msg;
    formIsValid = false;
} 


if(!profile.job_title.value){
    error.job_title=msg;
    formIsValid = false;
} 



if(profile.includeLogin){

    if(profile.password.length < 6){
        error.password ='Password not strong enough, minimum of 6 letters required';
        formIsValid = false;
    } 


    if(profile.confirm_password !== profile.password){
        error.confirm_password ='Password do not match';
        formIsValid = false;
    } 


    if(!profile.password){
        error.password =msg;
        formIsValid = false;
    } 

    if(!profile.group.value){
        error.group_code=msg;
        formIsValid = false;
    } 

    if(!profile.confirm_password){
        error.confirm_password =msg;
        formIsValid = false;
    } 


   
}



if(!formIsValid){
setErrors(error) 
}


if(formIsValid) {

    LoadingModal()
 const fd = new FormData();
    Object.entries(profile).forEach(([key, value]) => {
            fd.append(key, String(value));
        });


        fd.append('added_by', STAFF_CODE);
        fd.append('company_code', COMPANY_CODE);
        fd.append('code', 'E'+Math.random().toString(36).substring(2, 9));
        fd.append('station', profile.work_station.value);
        fd.append('employment', profile.employment_status.value);
        fd.append('departments', profile.department.value);
        fd.append('job_titles', profile.job_title.value);
        fd.append('titles', profile.title.value);
        fd.append('group_code', profile.group.value);

        if(profile.imagesFile){
            fd.append('imageFiles', profile.imagesFile, profile.filePath); 
        } 


let url = ServerUrl+'/api/employee/add_new';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                Alerts('danger',response.data.message)
                closeModal()
              }   
          })
          .catch((error)=>{
            Alerts('danger',error.message)
            closeModal()
          }).finally(()=>{
        handleReset()

          }) 
    }

}


const validateEmail =()=>{
    if(profile.work_email!==''){
    let email = profile.work_email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if(!email){
      setErrors({...errors, work_email:profile.work_email+' not a valid email address'});
      setProfile({...profile, work_email:''});
    }else{
        ValidateEmailExistence()
    }
    }

  }

const ValidateEmailExistence=()=>{
    if(profile.work_email!==''){
         const fd = new FormData();
         fd.append("data", profile.work_email)
         let url = ServerUrl+'/api/employee/existence/work_email';
         axios.post(url, fd, config).then(response=>{
             if(response.data.data===true){
                 setErrors({...errors, work_email:profile.work_email + ' already register'})
                 setProfile({...profile, work_email:''});
                 return '';
             }
       })
     } 
 } 


 useEffect(()=>{

    try{
     if (settings!==undefined && settings.data.length!==0){
     setStation(JSON.parse(settings.data[0].contacts))
     }
 
    }catch(err) {
 
    }
     
     }, [settings.data]) 


     useEffect(()=>{
        fetchGroup()
     }, [])

  return ( <>
<Breadcrumb 
   title="Add Employee"
   summary="You can create more Departments, and Job title under employee settings."
   >
   <li className="breadcrumb-item">
    <Link to="/employees/view">Employees</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Add Employee</a> </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

                        <div className="row">
<div className=" col-md-12">
<div className="card z-depth-0">
    <div className="card-header">
        <h5>Add Employee</h5>
        <div className="card-header-right">
    <ul>
        <li><i className="fa fa-minus minimize-card"></i></li>
        </ul>

</div>
    </div>
    <div className="card-block ">
       


        <div className="row">
            <div className="col-md-4">


            <div className="text-center">

<input id="photo"  onChange={handleFile('photo')} className="d-none" name="photo" type="file" accept="image/*" />              

 {profile.imagesFile?
 <img id="viewPassport2" onClick={handlePhoto} 
 className='img-100  pointer img-radiuss' 
			onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+profile.gender+'.png'}} src={profile.passport} alt="preview" /> : 
            
            <img src={ImagesUrl+'/'+profile.gender+'.png'}  alt="" className='img-fluid staff-image pointer img-radiuss' onClick={handlePhoto}  /> }           

		    {/* <button onClick={handlePhoto} type='button' className='btn btn-round btn-primary btn-sm'><i className='fa fa-plus text-white'></i></button> */}


       </div>
       <label className="col-form-label text-danger">Accepts jpg, .png, .gif up to 1MB. Recommended dimensions: 200px X 200px</label>
            </div>




            <div className="col-md-8">

<div className="row">


    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Title</label>
    
    <Select  options={[ 

        {value:'Mr.', label:'Mr.'},
        {value:'Mrs.', label:'Mrs.'}, 
        {value:'Ms.', label:'Ms.'}, 
         {value:'Dr', label:'Dr.'}, 
        {value:'Esquire', label:'Esquire'},
        {value:'Honorable', label:'Hon.'}, 
        {value:'Mallam', label:'Mallam'},
        {value:'Pst.', label:'Pst.'},
        {value:'Junior', label:'Jr.'}, 
        {value:'Messrs.', label:'Messrs.'},
        {value:'Mmes.', label:'Mmes.'}, 
        {value:'Msgr.', label:'Msgr.'},
        {value:'Prof.', label:'Prof.'}, 
        {value:'Rev.', label:'Rev.'},
        {value:'Rt. Hon.', label:'Rt. Hon.'}, 
        {value:'Sr.', label:'Sr.'}, 
        {value:'St.', label:'St.'}
                   ]}   

onChange={handleSelect}  name="title" value={profile.title} 
styles={styles}
/>
    
    </div>

    </div>


    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">First Name <span className='text-danger'>*</span></label>
    
    <input type="text" className={errors.first_name?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='first_name' value={profile.first_name} onChange={handleChange} />
    
    {errors.first_name&&
    <div className="col-form-label has-danger">{errors.first_name&&errors.first_name} </div>}
    </div>

    </div>
  

    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Last Name <span className='text-danger'>*</span></label>
    
    
    <input type="text" className={errors.last_name?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='last_name' value={profile.last_name} onChange={handleChange} />
    {errors.last_name&&
    <div className="col-form-label has-danger">{errors.last_name&&errors.last_name} </div>}
    
    </div>

    </div>


    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Middle Name </label>
    
    <input type="text" className="form-control form-control-lg"  name='middle_name' value={profile.middle_name} onChange={handleChange} />
    
    </div>

    </div>

    </div>
    <hr/>
<div className="row">

    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Department <span className='text-danger'>*</span> </label>
    
    <div className="input-group  ">
    <Select  options={
            data&&data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.department_name}
                    })}   
                    
			onChange={handleSelect} className={errors.department?"form-control form-control-danger":""}   name="department" value={profile.department}  
            styles={group_styles} 
            />


<div className="input-group-append  " onClick={()=>window.location.href='/employees/settings'}>
                    <span className="input-group-text "><a href='#' data-tip="Add Department" title="Add Department"  ><i className="fa  fa-plus-circle text-white" id="addIcon"></i></a></span>
                  </div>

            	</div>

{errors.department&&
    <div className="col-form-label has-danger">{errors.department&&errors.department} </div>}
    
    </div>
    
    </div>


    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Job Title <span className='text-danger'>*</span> </label>
    <div className="input-group  ">
    <Select options={
            Jobs.data&&Jobs.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.title}
                    })}   
                 
			onChange={handleSelect} 
            className={errors.job_title?"form-control form-control-danger":""} 
            name="job_title" value={profile.job_title} 
            styles={group_styles} 
              />
    
<div className="input-group-append  " onClick={()=>window.location.href='/employees/settings'} >
                    <span className="input-group-text "><a href='#!' data-tip="Add Job Title" title="Add Job Title"  ><i className="fa  fa-plus-circle text-white" id="addIcon"></i></a></span>
                  </div>

            	</div>


{errors.job_title&&
    <div className="col-form-label has-danger">{errors.job_title&&errors.job_title} </div>}
    </div>
    
    </div>



    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Work Email <span className='text-danger'>*</span></label>
    
    
    <input type="text" className={errors.work_email?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} onBlur={validateEmail} name='work_email' value={profile.work_email} onChange={handleChange} />
    
    {errors.work_email&&
    <div className="col-form-label has-danger">{errors.work_email&&errors.work_email} </div>}
    
    </div>
    
    </div>

    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Mobile Phone <span className='text-danger'>*</span></label>
    
    
    <input type="text" className={errors.mobile_phone?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='mobile_phone' value={profile.mobile_phone} onChange={handleChange} />

    {errors.mobile_phone&&
    <div className="col-form-label has-danger">{errors.mobile_phone&&errors.mobile_phone} </div>}
    
    </div>
    
    </div>

    </div>
   


    <div className="row">

    <div className="col-md-6">
         <div className="form-group">
        <label className="col-form-label">Employment Status <span className='text-danger'>*</span></label>
       
           
<Select  
options={
    WORK_STATUS.map((list:any, id:number)=>{
        return {key:id, value:list, label:list}
            })} 
                       
            className={errors.employment_status?"form-control form-control-danger":""}
                                onChange={handleSelect}  name="employment_status"
                        value={profile.employment_status} 
                        styles={styles} 
                 />

{errors.employment_status&&
    <div className="col-form-label has-danger">{errors.employment_status&&errors.employment_status} </div>}
         </div>

         </div>




        <div className="col-md-6">
    <div className="form-group">
    <label >Work Station <span className='text-danger'>*</span> <a href="#" className="text-right">(Add More)</a></label>
    
    <Select  options={
            station&&station.map((list:any, id:number)=>{
                return {key:id, value:list.location.toUpperCase(), label:list.location.toUpperCase()}
                    })}   
                    styles={styles}
			onChange={handleSelect} className={errors.work_station?"form-control form-control-danger":""}   name="work_station" value={profile.work_station}   />

{errors.work_station&&
    <div className="col-form-label has-danger">{errors.work_station&&errors.work_station} </div>}
    
    </div>
    
    </div>



    <div className="col-md-6  m-b-30">
        <h4 className="sub-title">Select Gender</h4>
        <div className="form-radio">
            <form>
                <div className="radio radio-inline">
                    <label>
                        <input type="radio" name="gender" value="Male" onChange={handleChange} checked={profile.gender ==='Male'?true:false} />
                        <i className="helper"></i>Male
                    </label>
                </div>
                <div className="radio radio-inline">
                    <label>
                        <input type="radio" name="gender" value="Female" onChange={handleChange} checked={profile.gender ==='Female'?true:false} />
                        <i className="helper"></i>Female
                    </label>
                </div>
            </form>
        </div>
        </div>


        <div className="col-md-6  m-b-30">
        <h4 className="sub-title">Account Status</h4>
        <div className="form-radio">
                <div className="radio radio-inline">
                    <label>
                        <input type="radio" name="account_status" value="Enabled" onChange={handleChange} checked={profile.account_status ==='Enabled'?true:false}  />
                        <i className="helper"></i>Enabled
                    </label>
                </div>
                <div className="radio radio-inline">
                    <label>
                        <input type="radio" name="account_status" value="Disabled" onChange={handleChange} checked={profile.account_status ==='Disabled'?true:false} />
                        <i className="helper"></i>Disabled
                    </label>
                </div>
        </div>
        </div>

    
</div>

<hr/>
    <div className="row">
<div className="col-md-12">

<div className="d-flex justify-content-start">
<label className="sub-titles">
Create Login Details
</label>
<label className="switch-md ml-3">
  <input type="checkbox" name='includeLogin' checked={profile.includeLogin} onChange={()=>setProfile({...profile, includeLogin:!profile.includeLogin})} />
  <span className="slider-md round"></span>
</label>
</div>


</div>


    
</div>
            

  {profile.includeLogin&& 

<div className="row "style={{backgroundColor: 'rgba(68, 138, 255, 0.1)'}} >

<div className="col-md-6">
         <div className="form-group">
        <label className="col-form-label">Access Group <span className='text-danger'>*</span></label>
       

    <Select  options={ usergroup&&usergroup.map((list:any, idx:number)=> {
                               return {key:idx, value: list.code, label: list.group_name}
                             })}   
                    styles={styles}
			onChange={handleSelect} className={errors.group_code?"form-control form-control-danger":""}   name="group" value={profile.group}   />

{errors.group_code&&
    <div className="col-form-label has-danger">{errors.group_code&&errors.group_code} </div>}

    

      
         </div>

         </div>

        
         <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Personal Email </label>
    
    
    <input type="email" placeholder='optional' className="form-control form-control-lg"  name='personal_email' value={profile.personal_email} onChange={handleChange} />
    
    
    </div>
    
    </div>


        <div className="col-md-6">
         <div className="form-group">
        <label className="col-form-label">Password <span className='text-danger'>*</span></label>
       
          

    <input type="password" className={errors.password?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='password' value={profile.password} onChange={handleChange} />

    {errors.password&&
    <div className="col-form-label has-danger">{errors.password&&errors.password} </div>}
      
         </div>


         </div>
     


         <div className="col-md-6">
         <div className="form-group">
        <label className="col-form-label">Confirm Password <span className='text-danger'>*</span></label>
       
           
    <input type="password" className={errors.confirm_password?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='confirm_password' value={profile.confirm_password} onChange={handleChange} />

    {errors.confirm_password&&
    <div className="col-form-label has-danger">{errors.confirm_password&&errors.confirm_password} </div>}
      
         </div>

         </div>

         <div className="col-md-12">
            <span > Please choose a difficult-to-guess combination of text containing upper- and lowercase letters, symbols, and digits for the password.</span>
         </div>
         

        </div>}


    </div>
        </div>




       
       


<hr/>
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-outline-danger btn-round pr-5 pl-5 mr-3" type='button' onClick={handleReset}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={handleSubmit}>Save</button>
</div>
</div>





    </div>
</div>
</div>
</div>





                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
                            </>               
 
  )
}

export default AddEmployee