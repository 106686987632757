import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo} from 'react'
import { COMPANY_CODE, ImagesUrl, STAFF_CODE, ServerUrl, config, selectStyles, styles } from '../../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal, CustomLoader, closeModal } from '../../../components/notify';
import Select, { components } from 'react-select'

const Personal =({code}:{code:any})=> {

    const [content, setContent] = useState([] as any);

    const [disabled, setDisabled] = useState(false);
    const [errors, setErrors] = useState({
       
        first_name:'',
        last_name:'',
     })

    // const [profile, setProfile] = useState(Initial);

    const handleSelect = (option:any, action:any)=>{
        const newProfile = content.map((item:any)=>{
            return {...item, [action.name]:option.value}
          })
          setContent(newProfile)
          setErrors({...errors, [action.name]:''})
           }


           const handleChangeBox =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
        
            value= value==='Yes'?'No':'Yes'

          const newProfile = content.map((item:any)=>{
            return {...item, is_smoker:value}
          })

          setContent(newProfile)
           setErrors({...errors, [name]:''});
         }

           const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
          const newProfile = content.map((item:any)=>{
            return {...item, [name]:value}
          })
          setContent(newProfile)
           setErrors({...errors, [name]:''});
         }



         const fetchEmployee =()=>{

            LoadingModal()
            
            let url = ServerUrl+'/api/employee/view/'+COMPANY_CODE+'/'+code
            axios.get(url, config).then(result=>{
             
              if(result.data.status===200){
                setContent(result.data.data)
              closeModal()
              }else{
                setContent([])
                //ErrorModal('error', result.data.message)
              }
            })
            .catch((error)=>{
              ErrorModal('error', error.message)
            })
            }



const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!content[0].first_name){
    error.first_name =msg;
    formIsValid = false;
} 


if(!content[0].last_name){
    error.last_name =msg;
    formIsValid = false;
} 



if(!formIsValid){
setErrors(error) 
}


if(formIsValid) {

    LoadingModal()
 const fd = new FormData();
    Object.entries(content[0]).forEach(([key, value]) => {
            fd.append(key, String(value));
        });

        const log = JSON.parse(content[0].update_log)
        const item = log.concat( {
            code:STAFF_CODE,
            action:'Updated profile details',
            date_time: new Date()
        })

const logs = JSON.stringify(item,null, 2)

        fd.append('logs', logs);
       

   let url = ServerUrl+'/api/employee/update/'+code;
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                ErrorModal('', response.data.message)
              }   
          })
          .catch((error)=>{
           ErrorModal('oops', error.message)
          }) 
    }

}



    useEffect(()=>{
        fetchEmployee()
        }, [])
        

  return (
     <div className="card z-depth-0">

                                    <div className="card-header">
                                        <h5>Personal Details</h5>

                                    </div>


                                 {content.length!==0? content.map((profile:any, index:number)=>  <div key={index} className="card-block">
                       
                            <div className='row'>
                                <div className="col-md-4">

                                <div className="form-group">
                                <label className="col-form-label">Title</label>
                               
                                <Select  options={[ 

                            {value:'Mr.', label:'Mr.'},
                            {value:'Mrs.', label:'Mrs.'}, 
                            {value:'Ms.', label:'Ms.'}, 
                            {value:'Dr', label:'Dr.'}, 
                            {value:'Esquire', label:'Esquire'},
                            {value:'Honorable', label:'Hon.'}, 
                            {value:'Mallam', label:'Mallam'},
                            {value:'Pst.', label:'Pst.'},
                            {value:'Junior', label:'Jr.'}, 
                            {value:'Messrs.', label:'Messrs.'},
                            {value:'Mmes.', label:'Mmes.'}, 
                            {value:'Msgr.', label:'Msgr.'},
                            {value:'Prof.', label:'Prof.'}, 
                            {value:'Rev.', label:'Rev.'},
                            {value:'Rt. Hon.', label:'Rt. Hon.'}, 
                            {value:'Sr.', label:'Sr.'}, 
                            {value:'St.', label:'St.'}
                                    ]}   
                                    isDisabled={disabled}
                            onChange={handleSelect}  name="title" value={{label:profile.title, value:profile.title}} 
                        styles={styles}
                            />
                              
                                 </div>
                                 </div>


                                 <div className="col-md-4">

                                 <div className="form-group">
                                <label className="col-form-label">First Name <span className='text-danger'>*</span></label>
                               
                                <input type="text" className={errors.first_name?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='first_name' value={profile.first_name} onChange={handleChange} disabled={disabled} />
    
    {errors.first_name&&
    <div className="col-form-label has-danger">{errors.first_name&&errors.first_name} </div>}
                              
                                 </div>

                                 </div>


                    <div className="col-md-4">

                                 <div className="form-group">
                                <label className="col-form-label">Last Name <span className='text-danger'>*</span></label>
                               
                                   
    <input type="text" className={errors.last_name?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='last_name' value={profile.last_name} onChange={handleChange} disabled={disabled} />
    {errors.last_name&&
    <div className="col-form-label has-danger">{errors.last_name&&errors.last_name} </div>}
                              
                                 </div>

                                 </div>


            <div className="col-md-4">
                                 <div className="form-group">
                                <label className="col-form-label">Middle Name</label>
                               
                                <input type="text" className="form-control form-control-lg"  name='middle_name' value={profile.middle_name} onChange={handleChange} disabled={disabled} />
                                 </div>

                                 </div>


            <div className="col-md-4">
                                 <div className="form-group">
                                <label className="col-form-label">Nickname </label>
                               
                                <input type="text" className="form-control form-control-lg"  name='nickname' value={profile.nickname} onChange={handleChange} disabled={disabled} />
                              
                                 </div>

                                 </div>

                                
                                </div>
                                <hr/>


                                <div className="row">
                                <div className="col-md-4">

                                <div className="form-group">
                                <label className="col-form-label">Employee File Number</label>
                               
                                <input type="text" className="form-control form-control-lg"  name='file_number' value={profile.file_number} onChange={handleChange} disabled={disabled} />
                              
                                 </div>
                                    
                                 </div>

                                 <div className="col-md-4">
                                 <div className="form-group">
                                <label className="col-form-label">Passport ID</label>
                               
                                <input type="text" className="form-control form-control-lg"  name='passport_no' value={profile.passport_no} onChange={handleChange} disabled={disabled} />
                              
                              
                                 </div>


                                 </div>
                                 
                                 <div className="col-md-4">
                                 <div className="form-group">
                                <label className="col-form-label">National Identity No</label>
                               
                                <input type="text" className="form-control form-control-lg"  name='nin' value={profile.nin} onChange={handleChange} disabled={disabled} />
                              
                              
                                 </div>

                                 </div>
                                 
                                 <div className="col-md-4">
                                 <div className="form-group">
                                <label className="col-form-label">Birth Certificate No.</label>
                               
                                <input type="text" className="form-control form-control-lg"  name='birth_cert' value={profile.birth_cert} onChange={handleChange} disabled={disabled} />
                              
                                 </div>

                                 </div>
                                 
                                 <div className="col-md-4">
                                 <div className="form-group">
                                <label className="col-form-label">Driving License No</label>
                               
                                <input type="text" className="form-control form-control-lg"  name='driver_license' value={profile.driver_license} onChange={handleChange} disabled={disabled} />
                              
                              
                                 </div>

                                 </div>
                                 
                                 <div className="col-md-4">
                                 <div className="form-group">
                                <label className="col-form-label">Other ID</label>
                               
                                <input type="text" className="form-control form-control-lg"  name='other_id' value={profile.other_id} onChange={handleChange} disabled={disabled} />
                              
                                 </div>

                                 </div>
                                </div><hr/>





                                <div className="row">
    <div className="col-md-6  m-b-30">
        <h4 className="sub-title">Select Gender</h4>
        <div className="form-radio">
            <form>
                <div className="radio radio-inline">
                    <label>
                        <input type="radio" name="gender" value="Male" onChange={handleChange} checked={profile.gender ==='Male'?true:false}  />
                        <i className="helper"></i>Male
                    </label>
                </div>
                <div className="radio radio-inline">
                    <label>
                        <input type="radio" name="gender" value="Female" onChange={handleChange} checked={profile.gender ==='Female'?true:false} />
                        <i className="helper"></i>Female
                    </label>
                </div>
            </form>
        </div>
        </div>

        <div className="col-md-6  m-b-30">
        <h4 className="sub-title">Is Smoker</h4>

        <div className="chk-option">
                                <div className="checkbox-fade fade-in-primary">
                                    <label className="check-task">
    <input type="checkbox" value={profile.is_smoker} name="is_smoker" checked={profile.is_smoker ==='Yes'?true:false}  onChange={handleChangeBox} />
    <span className="cr">
    <i className="cr-icon fa fa-check txt-default"></i>
    </span> {profile.is_smoker} 
</label>
                                </div>
                            </div>


        </div>

    
</div>

<hr/>




                                <div className="row">
                                
                            
                                 <div className="col-md-4">
                                 <div className="form-group">
                                <label className="col-form-label">Tribe</label>
                               
                                <input type="text" className="form-control form-control-lg"  name='tribe' value={profile.tribe} onChange={handleChange} disabled={disabled} />
                              
                              
                                 </div>

                                 </div>
                                 <div className="col-md-4">
                                 <div className="form-group">
                                <label className="col-form-label">Marital Status</label>
                               
                                <Select  options={[
                                    { value: 'Single', label: 'Single' },
                                    { value: 'Married', label: 'Married'},
                                    { value: 'Widowed', label: 'Widowed'},
                                    { value: 'Divorced', label: 'Divorced'},
                                    { value: 'Separated', label: 'Separated'},
                                ]} 
                onChange={handleSelect}  
                name="marital_status"
              isDisabled={disabled}
                 value={{value: profile.marital_status, label: profile.marital_status}} 
                 styles={styles}
                 
                 />
                              
                                 </div>

                                 </div>


                                 <div className="col-md-4">
                                 <div className="form-group">
                                <label className="col-form-label">Religion</label>
                               
                                <Select  options={[
                                    { value: 'Christianity', label: 'Christianity' },
                                    { value: 'Islam', label: 'Islam'},
                                ]} 
                       
                                onChange={handleSelect}  name="religion"
                        value={{value: profile.religion, label: profile.religion}} 
                        styles={styles}
                        isDisabled={disabled}
                 />
                              
                                 </div>

                                 </div>

                                </div><hr/>




                                <div className="row">
                                <div className="col-md-4">

                                <div className="form-group">
                                <label className="col-form-label">Language Spoken</label>
                               
                                <input type="text" className="form-control form-control-lg"  name='language' value={profile.language} onChange={handleChange} disabled={disabled} />
                              
                                 </div>
                                    
                                 </div>

                                
                                 
                                 <div className="col-md-4">
                                 <div className="form-group">
                                <label className="col-form-label">Blood Group</label>
                               
                                <Select  options={[
                { value: 'A Plus', label: 'A+' },
                { value: 'A Minus', label: 'A-'},
                { value: 'AB Plus', label: 'AB+'},
                { value: 'AB Minus', label: 'AB-' },
                { value: 'B Plus', label: 'B+'  },
                { value: 'B Minus', label: 'B-' },
                { value: 'O Plus', label: 'O+' },
                { value: 'O Minus', label: 'O-' }
                ]} 
onChange={handleSelect}  name="blood_group"
 value={{value: profile.blood_group, label: profile.blood_group}} 
 isDisabled={disabled}
 styles={styles}
 
 /> 
                              
                                 </div>

                                 </div>
                                 
                                 <div className="col-md-4">

                                <div className="form-group">
                                <label className="col-form-label">Date of Birth</label>
                               
                                  
                                    <input type="date" className="form-control form-control-lg"  name='date_of_birth' value={profile.date_of_birth}
                                    disabled={disabled}
                                    onChange={handleChange} />
                              
                                 </div>
                                    
                                 </div>

                                 <div className="col-md-4">
                                 <div className="form-group">
                                <label className="col-form-label">Place of Birth</label>
                               
                                <input type="text" className="form-control form-control-lg" 
                                 name='place_of_birth' value={profile.place_of_birth} 
                                 disabled={disabled}
                                 onChange={handleChange} />
                              
                                 </div>


                                 </div>
                                
                                 
                                </div><hr/>

<div className="row">
    <div className="col-md-12 text-right">
    <button className="btn btn-success btn-round" type='button' onClick={handleSubmit}>Save Details</button>
    </div>
</div>
                            </div>):''}



                                </div>
  )
}

export default Personal