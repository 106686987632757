import React, { useEffect, useState } from 'react'

import { ServerUrl,  config, COMPANY_CODE, STAFF_CODE, ImagesUrl, Token } from './Includes'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { Alerts } from './notify'
import { useQuery } from 'react-query'
import { FetchProfile } from '../api/ReactQuery'
import { getTime } from './GlobalFunction'
import { Link } from 'react-router-dom'

const Topbar =()=> {
    const dispatch = useDispatch()
    const [isFull, setIsFull] = useState(false)
    const [user, setUser] = useState([]as any);

    const [loading, setLoading] = useState(false);
    const [settings, setSettings] = useState([]as any);
    const fetchCountry = async ()=>{

        let url = 'https://restcountries.com/v3.1/all'
        const {data} = await axios.get(url, config)
    
        let response =data.sort((a:any, b:any) =>(a.name.common > b.name.common) ? 1 : -1)

        dispatch({
            type:'SET_RECORDS',
            name:'country',
            data:response
        })

        }


      

            const handleAttendanceSetting = async()=>{
                let url = ServerUrl+'/api/attendance/settings/view/'+COMPANY_CODE
                const {data} = await axios.get(url, config)
                let response =data.data[0]
                setSettings(response)
        
             }


const handleFetchState = async()=>{
        let url = 'https://locus.fkkas.com/api/states'
        const {data} = await axios.get(url, config)
        let response =data.data.sort((a:any, b:any) =>(a.alias > b.alias) ? 1 : -1)
        dispatch({
            type:'SET_RECORDS',
            name:'states',
            data:response
        })

     }
     var today = new Date();
     var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
     const TimeIn =async()=>{
setLoading(true)
        const fd = new FormData();
        fd.append('company_code', COMPANY_CODE);
        fd.append('staff_code', STAFF_CODE);
        fd.append('time', time);

let url = ServerUrl+'/api/attendance/clock_in';
 axios.post(url, fd, config)
 .then(response =>{
   if(response.data.status === 200){
    Alerts('default',response.data.message, 'Success')
             } else{
               Alerts('danger',response.data.message, 'Error')
             }   
         })
         .catch((error)=>{
           Alerts('danger',error.message)
     
         }).finally(()=>{
            setLoading(false)
         })
   }

   const TimeOut =async()=>{
    setLoading(true)
    const fd = new FormData();
    fd.append('company_code', COMPANY_CODE);
    fd.append('staff_code', STAFF_CODE);
    fd.append('time', time);

let url = ServerUrl+'/api/attendance/clock_out';
axios.post(url, fd, config)
.then(response =>{
if(response.data.status === 200){
Alerts('default',response.data.message)
         } else{
           Alerts('danger',response.data.message)
         }   
     })
     .catch((error)=>{
       Alerts('danger',error.message, 'Error')
 
     }).finally(()=>{
        setLoading(false)
     })
}


 const fetchUser =  async()=>{

    if(Token !== undefined){
         
    var sql ="SELECT  e.id, e.mobile_phone, e.work_email,  e.passport, e.company_code, IFNULL(e.title, '') as title, e.first_name, e.gender, e.last_name, IFNULL(e.middle_name, '') as middle_name, e.file_number,  e.employment_status, e.account_status, j.title as job_title, d.department_name from tbl_employee e left join tbl_job_title j on j.code = e.job_title  left JOIN tbl_departments d on d.code = e.department WHERE e.code  ='"+STAFF_CODE+"'";
     
    const fd = new FormData();
    fd.append('sql', sql); 

    let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE

  await  axios.post(url, fd, config).then((result:any)=>{
    if(Array.isArray(result.data.data) && result.data.data.length!==0){
        setUser(result.data.data[0])
            dispatch({
                type:'SET_RECORDS',
                name:'users',
                data:result.data.data[0]
            })
        }
        
    })
    .catch((error)=>{
        Alerts('danger', error.message)
    })
    
    }else{
     window.location.href='/logout'
    }

}


/* Get the element you want displayed in fullscreen */ 
var elem:any = document.documentElement;

const IsFullMode =()=>{
     const windowWidth = window.innerWidth * window.devicePixelRatio;
    const windowHeight = window.innerHeight * window.devicePixelRatio;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    if (((windowWidth/screenWidth)>=0.95) && ((windowHeight/screenHeight)>=0.95)) {
        closeFullscreen()
        return true;
    }
    else {
        openFullscreen()
        return false
    } 
}

/* Function to open fullscreen mode */
function openFullscreen() {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) { /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
    elem.webkitRequestFullscreen();
  }
  setIsFull(true);
}

/* Function to close fullscreen mode */
function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  }
  
  /* else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    window.top.document.msExitFullscreen();
  } */
  
  setIsFull(false);
}


useEffect(()=>{
    fetchUser()
    fetchCountry()
    handleFetchState()
    handleAttendanceSetting()
}, [])

  return (
        
   
    <nav className="navbar header-navbar pcoded-header ">
    <div className="navbar-wrapper no-print">
        <div className="navbar-logo">
            <a className="mobile-menu waves-effect waves-light" id="mobile-collapse" href="#!">
                <i className="ti-menu"></i>
            </a>
            <div className="mobile-search waves-effect waves-light">
                <div className="header-search">
                    <div className="main-search morphsearch-search">
                        <div className="input-group">
                            <span className="input-group-prepend search-close"><i className="ti-close input-group-text"></i></span>
                            <input type="text" className="form-control" placeholder="Enter Keyword" />
                            <span className="input-group-append search-btn"><i className="ti-search input-group-text"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <a href="/dashboard">

               <img className="img-fluid long-logo" src= {ImagesUrl+'/glogo.png'} alt="HURIS"  /> 
            </a>
            <a className="mobile-options waves-effect waves-light">
                <i className="ti-more"></i>
            </a>
        </div>

        <div className="navbar-container container-fluid">
            <ul className="nav-left">
                <li>
                    <div className="sidebar_toggle"><a href="#!"><i className="ti-menu"></i></a></div>
                </li>
                <li className="header-search">
                    <div className="main-search morphsearch-search">
                        <div className="input-group">
                            <span className="input-group-prepend search-close"><i className="ti-close input-group-text"></i></span>
                            <input type="text" className="form-control" placeholder="Enter Keyword" />
                            <span className="input-group-append search-btn"><i className="ti-search input-group-text"></i></span>
                        </div>
                    </div>
                </li>

                <li>
                <a href="#!"  onClick={!isFull?()=>openFullscreen():()=>IsFullMode()} >
                        <i className={`feather ${!isFull?'ti-fullscreen':'ti-close'} `}></i>
                    </a> 
                </li>
            </ul>


            <ul className="nav-right">
                <li>{loading?<button  type="button" disabled className="btn btn-sm btn-primary m-b-5">
                                                                    <i className="fa fa-clock fa-spin"></i>
                                                                   please wait ...
                                                                </button>:
                    <button  type="button" onClick={TimeIn} className="btn btn-sm btn-primary m-b-5">
                                                                    <i className="fa fa-clock "></i>
                                                                    Clock In ({getTime(settings.time_in)})
                                                                </button>}
                                                                </li>

                  <li>{loading?
                    
                    <button  type="button" disabled className="btn btn-sm btn-danger m-b-5">
                                                                    <i className="fa fa-clock fa-spin"></i>
                                                                    Please wait ...
                                                                </button>:
                                                                <button  type="button" onClick={TimeOut} className="btn btn-sm btn-danger m-b-5">
                                                                <i className="fa fa-clock"></i>
                                                                Clock Out ({getTime(settings.time_out)})
                                                            </button>}
                                                                
                                                                </li>


                <li className="header-notification">
                    <a href="#!" className="waves-effect waves-light">
                        <i className="ti-bell"></i>
                        
                        <span className="badge bg-c-red"></span>
                    </a>
                    <ul className="show-notification">
                        <li>
                            <h6>Notifications</h6>
                            <label className="label label-danger">New</label>
                        </li>
                      
                        <li className="waves-effect waves-light">
                            <div className="media">
                                <img className="d-flex align-self-center img-radius" src= {ImagesUrl+'/logo.png'} alt="Generic placeholder image" />
                                <div className="media-body">
                                    <h5 className="notification-user">Joseph William</h5>
                                    <p className="notification-msg">Lorem ipsum dolor sit amet, consectetuer elit.</p>
                                    <span className="notification-time">30 minutes ago</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </li>
                <li className="">
                    <a href="#!" className=" waves-effect waves-light">
                        <i className="ti-comments"></i>
                        <span className="badge bg-c-green"></span>
                    </a>
                </li>
                <li className="user-profile header-notification">
                    <a href="#!" className="waves-effect waves-light">


            <img 
            src={ImagesUrl+'/employees/passport/'+user.passport}  alt={user.first_name} 
            className="img-radius" 
            onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+user.gender+'.png'}}  /> 

                        <span>{user.first_name+ ' '+user.last_name}</span>
                        <i className="ti-angle-down"></i>
                    </a>
                    <ul className="show-notification profile-notification">
                        <li className="waves-effect waves-light">
                            <a href="#!">
                                <i className="ti-settings"></i> Settings
                            </a>
                        </li>
                        <li className="waves-effect waves-light">
                            <Link to='/employee/view_profile'>
                                <i className="ti-user"></i> Profile
                            </Link>
                        </li>
                        <li className="waves-effect waves-light">
                            <a href="#!">
                                <i className="ti-email"></i> My Messages
                            </a>
                        </li>
                        <li className="waves-effect waves-light">
                            <a href="auth-lock-screen.html">
                                <i className="ti-lock"></i> Lock Screen
                            </a>
                        </li>
                        <li className="waves-effect waves-light">
                            <a href="/logout">
                                <i className="ti-layout-sidebar-left"></i> Logout
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>
  )
}

export default Topbar