import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, CURRENCY, STAFF_CODE, ServerUrl, config, selectStyles, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts, CustomLoader } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'

import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { FormatNumber, getMonthYear } from '../../components/GlobalFunction';
import { Link } from 'react-router-dom';

const  SalaryPayment =()=> {
   
    const [payment, setPayment] = useState({
       
        template:[] as any,
        pay_mode:[]as any,
        pay_month:'',
        pay_date:'',
        remarks:''
    })


    const [salaryStructure, setSalaryStructure]=useState([] as any);

    const [template, setTemplate] = useState([] as any);
    const [title, setTitle] = useState([] as any);
    const [staff, setStaff] = useState([] as any);
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
         staff:'',
         pay_mode:'',
         pay_date:'',
         pay_month:'',
         template:''
       
     })
    

     const fetchStaff =async()=>{
        let url = ServerUrl+'/api/employees/view/'+COMPANY_CODE
        const {data} = await axios.get(url, config)
        setStaff(data.data)
        }
      
        const fetchTemplate =async()=>{
            let url = ServerUrl+'/api/payroll/structure/view_structure/'+COMPANY_CODE
            const {data} = await axios.get(url, config)
            setTemplate(data.data)
            }


     const handleSelect = (option:any, action:any)=>{
        setPayment({...payment, [action.name]:option});
          setErrors({...errors, [action.name]:''})
          if(action.name==='template'){          
            fetchSalaryStructure(option.value);
            fetchContent(option.value)
            }

           }



           const handleChange = (event:ChangeEvent<HTMLInputElement>)=>{
           
            let {name, value} = event.target;	
            setPayment({...payment, [name]:value});
           setErrors({...errors, [name]:''});
        }

        
const getTitle = (code:string, field:string) =>{ 
    const result = title.filter((list:any)=>list.code===code);
   const answer = result.map((c:any)=>c[field] ); 
     return  String(answer);
   }

        


    const  fetchSalaryStructure =async(template_code:string)=>{    
       
            LoadingModal()

            //get all the staff that is using this template


            let url = ServerUrl+'/api/payroll/assign_structure/get_staff/'+template_code
          await  axios.get(url, config).then((result:any)=>{
            if(Array.isArray(result.data.data) && result.data.data.length!==0){
              
                try{

                    const staffList = result.data.data

                    
                    var allStaff = [];
                    for (var k = 0, m = staffList.length; k < m; k++) {


                 let structure = JSON.parse(staffList[k].salary_structure)
               
                    var options = structure
                    for (var i = 0, l = options.length; i < l; i++) {
                      allStaff.push({
                        unique_code:Math.random().toString(36).substring(2, 9),
                        code:options[i].code, 
                        title:getTitle(options[i].code, 'title'),
                         amount:options[i].amount,
                         item_code:getTitle(options[i].code, 'item_code'),
                         item_type:getTitle(options[i].code, 'item_type'),
                         amount_topay:options[i].amount, 
                         due:'No Dues',
                         paid:options[i].amount, 
                         balance:0,
                         template_code:template_code,
                         staff_code:staffList[k].staff_code
                        
                        });
                    }
            
                }

                setSalaryStructure(allStaff) 

                }catch(e){

                }
             
              }else{
                setSalaryStructure([])
              }
            })
            .catch((error)=>{
                Alerts('danger', error.message)
            }).finally(()=>{
                closeModal()
            }) 
        } 




const handleReset =()=>{
    setPayment({
        template:[] as any,
        pay_mode:[]as any,
        pay_month:'',
        pay_date:'',
        remarks:''
    })
setContent([])
    setSalaryStructure([])
}




  const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';




if(salaryStructure.length===0){
    error.template ='Please assign staff to this template';
    formIsValid = false;
} 

if(payment.template.length===0){
    error.template =msg;
    formIsValid = false;
} 

if(payment.pay_mode.length===0){
    error.pay_mode =msg;
    formIsValid = false;
}  
 
if(!payment.pay_month){
    error.pay_month =msg;
    formIsValid = false;
}  

if(!payment.pay_date){
    error.pay_date =msg;
    formIsValid = false;
}  

if(!formIsValid){
setErrors(error) 

window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
}

if(formIsValid) {
  LoadingModal()
   
    const fd = new FormData();
    let code = 'SP'+Math.random().toString(36).substring(2, 9)

    fd.append('salary_structure', JSON.stringify(salaryStructure, null, 2)); 
    fd.append('company_code', COMPANY_CODE);
    fd.append('p_code', code);
    fd.append('added_by', STAFF_CODE);
    fd.append('payment_mode', payment.pay_mode.value);
    fd.append('payment_month', payment.pay_month);
    fd.append('payment_date', payment.pay_date);
    fd.append('remarks', payment.remarks);
let url = ServerUrl+'/api/payroll/auto_salary_payment/add_payment';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                Alerts('danger', response.data.message)
                closeModal()
              }   
          })
          .catch((error)=>{
            Alerts('danger', error.message)
            closeModal()
          }).finally(()=>{
        handleReset()

          })  
    }

}


const DeleteModal =(row:any)=>{
    let url = ServerUrl+'/api/payroll/remove_template_payment/'+row.payment_month+'/'+row.code;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =content.filter((item:any)=>item.payment_month !==row.payment_month)
            setContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }



    const fetchTitle = async()=>{
        let url = ServerUrl+'/api/payroll/structure/view_title/'+COMPANY_CODE
      await  axios.get(url, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){
          setTitle(result.data.data)
          }
        })
        .catch((error)=>{
            Alerts('danger', error.message)
        })
        } 



const TableData =()=>{


    const columns = [
        {
            name: 'Template',
            selector: (row:any) =>row.level,
            sortable: true,
            reorder:true,
            wrap:true,
        },
        {
            name: 'Payment Month',
            selector: (row:any) => getMonthYear(row.payment_month),
            sortable: true,
            reorder:true,
            wrap:true,
            
        },
        {
            name: `Earning (${CURRENCY})`,
            selector: (row:any) => FormatNumber(row.earning),
            sortable: true,
            reorder:true,
        },
        {
            name: `Deduction (${CURRENCY})`,
            selector: (row:any) => FormatNumber(row.deduction),
            sortable: true,
            reorder:true,
        },
        {
            name: `Net Pay (${CURRENCY})`,
            cell: (row:any) =>FormatNumber(row.net_pay),
            sortable: true,
            reorder:true,
        },
        {
            name: 'Approved',
            cell: (row:any) => <label className="switch-md">
            <input type="checkbox" checked={row.is_approved==='Yes'?true:false}   />
            <span className="round slider-md"></span>
          </label>,
          width:'100px'
        },
        {
            name: 'Action',
            cell: (row:any, index:any, column:any, id:any) => <div className='row'>  

               <label className="label label-primary pointer" ><i className='fa fa-print'></i> Print</label>

               <label className="label label-danger pointer" onClick={()=>DeleteModal(row)}><i className='ti-trash'></i> Delete</label>
            </div>,
                left:true,
                width:'150px'
        },
    ];
    
  

    return <DataTable
    columns={columns}
    data={content}
    dense
    highlightOnHover
    pagination
    selectableRowsHighlight
    striped
    progressPending={loading}
	progressComponent={<CustomLoader />}
/>

}




const fetchContent = async(template_code:string)=>{
    setLoading(true)
    let sql = "SELECT t.level, t.code, p.is_approved, p.payment_month, COALESCE(sum(case when p.item_type ='DEDUCTION' then p.amount_topay else 0 end), 0) as deduction,  COALESCE(sum(case when p.item_type ='EARNING' then p.amount_topay else 0 end),0) as earning, (COALESCE(sum(case when p.item_type ='EARNING' then p.amount_topay else 0 end),0)- COALESCE(sum(case when p.item_type ='DEDUCTION' then p.amount_topay else 0 end), 0)) as net_pay FROM tbl_salary_payment p, tbl_payroll_structure t  where p.company_code = '"+COMPANY_CODE+"' and p.template_code = t.code  and p.template_code = '"+template_code+"'"

                sql = sql + " GROUP BY p.payment_month, t.level, t.code, p.is_approved "
                     
                 const fd = new FormData();
                 fd.append('sql', sql); 
                 let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
               await  axios.post(url, fd, config).then((result:any)=>{


      if(Array.isArray(result.data.data) && result.data.data.length!==0){
        setContent(result.data.data)
        }else{
            setContent([])
        }
      })
         .catch((error)=>{
             Alerts('danger',error.message)
         }).finally(()=>{
             setLoading(false)
         })
         } 


    useEffect(()=>{
        fetchTemplate()
        fetchTitle()
        fetchStaff() 
      }, [])


    

  return ( <>
  <Breadcrumb 
   title="Salary Payment"
   summary="Generate payment slip for all employee under the selected template "
   >
    <li className="breadcrumb-item">
    <Link to="/payroll/staff_salary">Payroll</Link>
                    </li>
   <li className="breadcrumb-item">
    <Link to="#!">Salary Payment</Link>
                    </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Salary Payment</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                              
                                    
                                    
         <div className="content-wrappers ">
                            
         <div className=''>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Employee Salary</h5>
                <span>Payment details will be generated for all the employees assign to the  <a href="#" className='text-info'>selected template</a> </span>
              
            </div>
            <div className="card-block">
    
        
                        <div className="row">
                  
                        <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Select Template <span className='text-danger'>*</span></label>


                 
                    <Select  options={
                                template&&template.map((list:any, idx:number)=> {
                                    return {key:idx, value: list.code, label: list.level}
                                    })}
                                    
                     onChange={handleSelect} 
                     className={errors.template ? 'form-control form-control-danger' : ''} name="template"
                      value={payment.template}
    
    styles={styles} 
    />        
                

                    {errors.template&&
    <div className="col-form-label has-danger">{errors.template&&errors.template} </div>}
                    </div>
    
                     </div>


                    <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Payment Mode <span className='text-danger'>*</span></label>

                    <Select  options={[
                                    { value: 'CASH', label: 'CASH' },
                                    { value: 'CHEQUE', label: 'CHEQUE'},
                                    { value: 'TRANSFER', label: 'TRANSFER'},
                                    { value: 'BANK', label: 'BANK'},
                                    { value: 'OTHERS', label: 'OTHERS'},
                                ]} 
                       
                                onChange={handleSelect}  name="pay_mode"
                                className={errors.pay_mode?"form-control form-control-danger":""} 
                        value={payment.pay_mode} 
                        styles={styles} 
                        
                 />
                

                    {errors.pay_mode&&
    <div className="col-form-label has-danger">{errors.pay_mode&&errors.pay_mode} </div>}
                    </div>
    
                     </div>



                     <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Payment Month <span className='text-danger'>*</span></label>
           
            <input type="month" className={errors.pay_month?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='pay_month' value={payment.pay_month} onChange={handleChange}  />
          

            {errors.pay_month&&
    <div className="col-form-label has-danger">{errors.pay_month&&errors.pay_month} </div>}
             </div>

             </div>

             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Payment Date <span className='text-danger'>*</span></label>
           
            <input type="date"   className={errors.pay_date?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='pay_date' value={payment.pay_date} onChange={handleChange}  />


            {errors.pay_date&&
    <div className="col-form-label has-danger">{errors.pay_date&&errors.pay_date} </div>}
          
             </div>

             </div>


             <div className="col-md-12">
             <div className="form-group">
            <label className="col-form-label">Remarks</label>
           
            <input type="text" className="form-control form-control-lg"  name='remarks' value={payment.remarks} onChange={handleChange}  />
          
             </div>

             </div>

                    </div>


<hr />
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-outline-danger btn-round pr-5 pl-5 mr-3" type='button' onClick={handleReset}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={handleSubmit}>Generate Payment</button>
</div>
</div>
    
            </div>
        </div>
    </div>
    </div>
    
    
    

    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Payment History</h5>
                <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
              
            </div>
            <div className="card-block">
    
        <div className="table-responsive ">
        <TableData />
        </div>
    
            </div>
        </div>
    </div>
    </div>
    
                </div>

        </div>
                       
                    </section>
                </div>
            </div>
        </div>
    </div>
                                    </div>
                                </div>
                            </div>





                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default SalaryPayment