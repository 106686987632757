import { useState, ChangeEvent, FormEvent, useEffect} from 'react'
import Breadcrumb from '../../components/Breadcrumb'
import { COMPANY_CODE, ImagesUrl, ServerUrl, Token, config, configFLW, selectStyles, styles } from '../../components/Includes'

import { ErrorModal, LoadingModal, SuccessModal, CustomLoader, closeModal, Alerts } from '../../components/notify';
import Select, { components } from 'react-select'
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import Photos from './Photos';
import Offices from './Offices';
import { Link } from 'react-router-dom';
const BasicDetails =()=> {


    const country  = useSelector((state:any) => state.rootReducer.country);
    const state  = useSelector((state:any) => state.rootReducer.states);

    const [lga, setLga] = useState([] as any);
    const [settings, setSettings] = useState({
        code:'',
        company_code:'',
        company_name:'',
        phone:'',
        email:'',
        company_address:'',
        city:'',
        state:'',
        country:'',
        logo_url:'',
        banner_url:'',
        lga:'',
        website:'',
        images_url:ImagesUrl,
        server_url:ServerUrl,
        is_edit:false
        
     })


     const [errors, setErrors] = useState({
        company_name:'',
        phone:'',
        email:'',
        company_address:'',
       
     })


     const handleSelect = (option:any, action:any)=>{
        setSettings({...settings, [action.name]:option.value});
          setErrors({...errors, [action.name]:''})

          if(action.name==='state'){
            handleFetchLga(option.value)
          }

           }


  const handleFetchLga =async(alias:string)=>{

            let url = 'https://locus.fkkas.com/api/regions/'+alias
           await  axios.get(url, config).then(result =>{
              if(result.data.data.length!==0){
                  let response =result.data.data.sort((a:any, b:any) =>(a.name > b.name) ? 1 : -1)
                  setLga(response)
                }else{
                    setLga([])
                }
          
            })
      
         }

     const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	
        setSettings({...settings, [name]:value});
       setErrors({...errors, [name]:''});
     }


     const getState =(alias:string)=>{
        const rs = state&&state.filter((item:any)=>item.alias===alias)
        return rs.length!==0?rs[0].name:''
     }





const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!settings.company_name){
    error.company_name =msg;
    formIsValid = false;
} 

if(!settings.phone){
    error.phone =msg;
    formIsValid = false;
} 


if(!settings.email){
    error.email =msg;
    formIsValid = false;
} 

if(!settings.company_address){
    error.company_address =msg;
    formIsValid = false;
} 


let emailAddress = settings.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 

if(!emailAddress){
    error.email =msg;
    formIsValid = false;
} 



if(!formIsValid){
setErrors(error) 
}


if(formIsValid) {

    LoadingModal()
 const fd = new FormData();
    Object.entries(settings).forEach(([key, value]) => {
            fd.append(key, String(value));
        });


        fd.append('company_code', COMPANY_CODE);
        fd.append('imagesUrl', ImagesUrl);
        fd.append('serverUrl', ServerUrl);
       // fd.append('code', 'S'+Math.random().toString(36).substring(2, 9));

        //let url = ServerUrl+'/api/settings/create_new';
let url = ServerUrl+'/api/settings/update_one';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                Alerts('danger',response.data.message)
              }   
          })
          .catch((error)=>{
            Alerts('danger',error.message)
          })
    }

}



const fetchContent = async()=>{
    LoadingModal()
    let url = ServerUrl+'/api/settings/view/'+COMPANY_CODE
  await  axios.get(url, config).then((result:any)=>{
    if(Array.isArray(result.data.data) && result.data.data.length!==0){
     setSettings(result.data.data[0]) 
      }
    })
    .catch((error)=>{
        Alerts('danger', error.message)
    }).finally(()=>{
        closeModal()
    })
    } 



useEffect(()=>{
    fetchContent()
   }, [])

  return ( <>
   <Breadcrumb 
   title="Basic Details"
   summary="Create new project"
   >
    <li className="breadcrumb-item">
    <Link to="#!">Setup</Link>
                    </li>


                    <li className="breadcrumb-item">
    <Link to="#!">Basic Details</Link>
                    </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
                                <div className="main-body">
                                    <div className="page-wrapper">
                                        <div className="page-body">




<div className="row">
    <div className="col-md-12">
        
        <div className="tab-header card">
            <ul className="nav nav-tabs md-tabs tab-timeline" role="tablist" id="mytab">
                <li className="nav-item">
                    <a className="nav-link active show" data-toggle="tab" href="#personal" role="tab" aria-selected="false">Company Details</a>
                    <div className="slide"></div>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#binfo" role="tab" aria-selected="false">Logo & Banner</a>
                    <div className="slide"></div>
                </li>
                <li className="nav-item">
                    <a className="nav-link " data-toggle="tab" href="#contacts" role="tab" aria-selected="true">Work Station</a>
                    <div className="slide"></div>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#review" role="tab" aria-selected="false">IP Security</a>
                    <div className="slide"></div>
                </li>
            </ul>
        </div>
        <div className="tab-content">
            <div className="tab-pane active show" id="personal" role="tabpanel">
          
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-header-text">About Organization</h5>
                        <span>Organization details here</span>
                        
                        <div className="card-header-right">
                        {!settings.is_edit?  <button onClick={()=>setSettings({...settings, is_edit:!settings.is_edit})} type="button" className="btn btn-sm btn-primary active ">
                            <i className="fa fa-edit text-white"></i> Edit
                        </button>:

                        <button  type="button" onClick={()=>setSettings({...settings, is_edit:!settings.is_edit})} className="btn btn-sm btn-primary active ">
                            <i className="fa fa-times text-white"></i> 
                        </button>}

                        </div>
                    </div>
                    <div className="card-block">
                        <div className="view-info">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="general-info">
                                        <div className="row">
        <div className="col-lg-12">
            <div className="table-responsive">
                <table className="table m-0">
                    <tbody>
                        <tr>
                            <th scope="row">Company Name</th>
                            <td>
                                
                            {!settings.is_edit?    <span>{settings.company_name} </span> :
                            <div>
                           
    <input type="text" className={errors.company_name?"form-control form-control-lg form-control-danger":"form-control form-control-lg "}  name='company_name' value={settings.company_name} onChange={handleChange} />

                            {errors.company_name&&
    <div className="col-form-label has-danger">{errors.company_name&&errors.company_name} </div>}
</div>}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Phone</th>
                            <td>


                            {!settings.is_edit?    <span>{settings.phone}</span> 
                            :
                            <div>
    <input type="text" className={errors.phone?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='phone' value={settings.phone} onChange={handleChange} />

{errors.phone&&
<div className="col-form-label has-danger">{errors.phone&&errors.phone} </div>}
</div>}

                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Email Address</th>
                            <td>
                                
                             
                            {!settings.is_edit?    <span>{settings.email}</span> 
                            :
                            <div>
    <input type="email" className={errors.email?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='email' value={settings.email} onChange={handleChange} />

{errors.email&&
<div className="col-form-label has-danger">{errors.email&&errors.email} </div>}

</div>}

                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Company Address</th>
                            <td>
                                
                            
                            {!settings.is_edit?    <span>{settings.company_address}</span> 
                            :
                            <div>
    <input type="text" className={errors.company_address?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='company_address' value={settings.company_address} onChange={handleChange} />

{errors.company_address&&
<div className="col-form-label has-danger">{errors.company_address&&errors.company_address} </div>}

</div>}

                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Country</th>
                            <td>
                            {!settings.is_edit?    <span>{settings.country}</span> 
                            :
                            <div>

                            <Select   options={country&&country.map((list:any, id:number)=> {
                                    return {key:id, value: list.name.common, label: list.name.common };
                                  })}
onChange={handleSelect}  name="country" value={{value: settings.country, label: settings.country}}
 styles={styles} /> 
</div>}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">State</th>
                            <td>
                                
                            {!settings.is_edit?    <span>{getState(settings.state)}</span> 
                            :
                            <div>
                            
                            
   <Select   options={
            state&&state.map((list:any, id:number)=> {
                                    return {key:id, value: list.alias, label: list.name };
                                  })}
onChange={handleSelect}  name="state" value={{value: settings.state, label: getState(settings.state)}} 
styles={styles} />  
</div>}
</td>
                        </tr>
                        <tr>
                            <th scope="row">LGA</th>
                            <td>
                                
                            {!settings.is_edit?    <span>{settings.lga}</span> 
                            :
                            <div>

                            <Select   options={lga&&lga.map((list:any, id:number)=> {
                                    return {key:id, value: list.name, label: list.name };
                                  })}
onChange={handleSelect}  name="lga" value={{value: settings.lga, label:settings.lga}}
 styles={styles} />  
</div>}
                            </td>
                        </tr>

                        <tr>
                            <th scope="row">City</th>
                            <td>
                                
                            
                            {!settings.is_edit?    <span>{settings.city}</span> 
                            :
                            <div>
    <input type="text" className="form-control form-control-lg "  name='city' value={settings.city} onChange={handleChange} />

</div>}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Frontend Url</th>
                            <td>
                                
                            
                            {!settings.is_edit?    <span>{settings.website}</span> 
                            :
                            <div>
    <input type="text" className="form-control form-control-lg "  name='website' value={settings.website} onChange={handleChange} />

</div>}
                            </td>
                        </tr>

<tr>
    <td colSpan={2}>
    {settings.is_edit?
    <div className="text-center">


<button className="btn btn-primary active btn-round pr-5 pl-5 m-r-20" type='button' onClick={handleSubmit}>Save</button>


                                        <a href="#!" onClick={()=>setSettings({...settings, is_edit:false})} className="btn btn-default waves-effect">Cancel</a>
                                    </div>:''}
    </td>
</tr>
                    </tbody>
                </table>
            </div>
        </div>
                                      
                                            
                                        </div>
                                     
                                    </div>
                                   
                                </div>
                               
                            </div>
                        
                        </div>
                    
                </div>
            </div>
            </div>
            <div className="tab-pane" id="binfo" role="tabpanel">
             
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-header-text">Company Images</h5>
                    </div>
                    <div className="card-block">

                        <Photos settings ={settings} />

                    </div>
                </div>
            </div>
            <div className="tab-pane " id="contacts" role="tabpanel">

            <Offices settings={settings} />

            </div>
            <div className="tab-pane" id="review" role="tabpanel">
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-header-text">Block IP Access</h5>
                    </div>
                    <div className="card-block">


                       
                                <form className="form-material right-icon-control">
                                    <div className="form-group form-primary">
                                        <input type="text" className="form-control"  />
                                        <span className="form-bar"></span>
                                        <label className="float-label">Coming Soon.....</label>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
                            </>               
 
  )
}

export default BasicDetails