import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { STAFF_CODE, ServerUrl, config } from '../../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal, CustomLoader } from '../../../components/notify';
import { fetchEmployee } from '../../../api/ReactQuery';
import { useQuery } from 'react-query'
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'


const Qualification =({code}:{code:any})=> {
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState([] as any);
    const [contact, setContact] = useState([] as any);
    const {data} = useQuery(["employee", code], fetchEmployee)
    const [errors, setErrors] = useState({
        certificate:'',
        institution:'',
        grade:'',
     })
    const [profile, setProfile] = useState({
      certificate:'',
      institution:'',
      grade:'',
      year_start:'',
      year_end:'',
        code:Math.random().toString(36).substring(2, 9),
        date: new Date()
    });

    const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	
        setProfile({...profile, [name]:value});
       setErrors({...errors, [name]:''});
     }

    

           const handleChangeSelect =(event:ChangeEvent<HTMLSelectElement>)=>{
            let {name, value} = event.target;	
            setProfile({...profile, [name]:value});
           setErrors({...errors, [name]:''});
         }

   
        const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
            event.preventDefault();
        
            let error = {...errors}; 
            let formIsValid = true;
        
        let msg ='This field is required';
        

        if(!profile.certificate){
            error.certificate =msg;
            formIsValid = false;
        } 
        
        
        
        if(!profile.institution){
            error.institution =msg;
            formIsValid = false;
        } 

        if(!formIsValid){
        setErrors(error) 
        }
        
        
        if(formIsValid) {
           LoadingModal()

                const visa =  JSON.parse(content[0].education_history)
                const contacts = visa.concat(profile)

                const log = JSON.parse(content[0].update_log)
                const item = log.concat( {
                    code:STAFF_CODE,
                    action:'Education: Added one qualification',
                    date_time: new Date()
                })
    
             const logs = JSON.stringify(item,null, 2)


                const newProfile = content.map((item:any)=>{
                const education_history = JSON.stringify(contacts,null, 2)


                    return {...item, 
                        education_history:education_history
                    }
                  })
                  
                  setContent(newProfile)
                  setContact(contacts)

                      const fd = new FormData();
               fd.append('logs', logs);
                Object.entries(newProfile[0]).forEach(([key, value]) => {
                                    fd.append(key, String(value));
                                }); 

        let click = document.getElementById('close')?.click();

           let url = ServerUrl+'/api/employee/update/'+code;
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status === 200){
             SuccessModal(response.data.message)
           
                      } else{
                        ErrorModal('', response.data.message)
                      }   
                  })
                  .catch((error)=>{
                   ErrorModal('oops', error.message)
                  }).finally(()=>{

                    setProfile({
                        certificate:'',
                        institution:'',
                        grade:'',
                        year_start:'',
                        year_end:'',
                          code:Math.random().toString(36).substring(2, 9),
                          date: new Date()
                    })

                  })  
            }
        
        }



const DeleteModal =(code:string)=>{
    
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading(),
    confirmButtonText: 'Yes, I am!',

    preConfirm: async() => {

const newContact = contact.filter((item:any)=>item.code !==code)



const log = JSON.parse(content[0].update_log)
const item = log.concat( {
    code:STAFF_CODE,
    action:'Passport & Visa: Removed one document',
    date_time: new Date()
})

const logs = JSON.stringify(item,null, 2)
const newProfile = content.map((item:any)=>{
    return {...item, 
        education_history:JSON.stringify(newContact,null, 2)
    }
  })
  
  setContent(newProfile)
  setContact(newContact)

      const fd = new FormData();
fd.append('logs', logs);
Object.entries(newProfile[0]).forEach(([key, value]) => {
                    fd.append(key, String(value));
                }); 

let url = ServerUrl+'/api/employee/update/'+code;
return await axios.post(url, fd, config)
.then(response =>{

if(response.data.status !== 200){
    throw new Error(response.statusText)

      } else{
        return response.data
      }   
  })
  .catch((error)=>{
    Swal.showValidationMessage(
        `Request failed: ${error}`
      )
  })
    }
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }






        const TableData =()=>{

           
            const columns = [
                {
                    name: 'Institution',
                    cell: (row:any) => <p title={row.institution}>{row.institution}</p>, 
                    sortable: true,
                },
                {
                    name: 'Certificate',
                    selector: (row:any) => row.certificate,
                    sortable: true,
                },
                {
                    name: 'Duration',
                    selector: (row:any) => row.year_start + ' - '+ row.year_end,
                    sortable: true,
                },
                {
                    name: 'Grade',
                    cell: (row:any) => row.grade, 
                    sortable: true,
                },
                {
                    name: 'Action',
                    cell: (row:any, index:any, column:any, id:any) => <div className='row'>  
                        <label className="label label-info pointer"  onClick={()=>EditItem(row)} ><i className='ti-pencil'></i> Edit</label>
                    <label className="label label-danger pointer" onClick={()=>DeleteModal(row.code)}><i className='ti-trash'></i> Delete</label></div>,
                        left:true
                              
        
                },
            ];
            
        
          
        
            return <DataTable
            columns={columns}
            data={contact}
            dense
            highlightOnHover
            pagination
            selectableRowsHighlight
            striped
            progressPending={loading}
            progressComponent={<CustomLoader />}
        />
        
        }


        const handleUpdate =(event: FormEvent<HTMLButtonElement>)=>{
            event.preventDefault();
        
            let error = {...errors}; 
            let formIsValid = true;
        
        let msg ='This field is required';
        

        if(!profile.certificate){
            error.certificate =msg;
            formIsValid = false;
        } 
        
        if(!profile.grade){
            error.grade=msg;
            formIsValid = false;
        } 
        
        if(!profile.institution){
            error.institution =msg;
            formIsValid = false;
        } 

        if(!formIsValid){
        setErrors(error) 
        }
        
        
        if(formIsValid) {
           LoadingModal()

              
                const visa = contact.map((item:any)=>{
                   
                    if(item.code ===profile.code){
                        return profile
                    }
    
                     return item
                      })


                const log = JSON.parse(content[0].update_log)
                const item = log.concat( {
                    code:STAFF_CODE,
                    action:'Education: Updated one document',
                    date_time: new Date()
                })
    
             const logs = JSON.stringify(item,null, 2)

                const newProfile = content.map((item:any)=>{
                const education_history = JSON.stringify(visa,null, 2)
                    return {...item, 
                        education_history:education_history
                    }
                  })
                  
                  setContent(newProfile)
                  setContact(visa)

                      const fd = new FormData();
               fd.append('logs', logs);
                Object.entries(newProfile[0]).forEach(([key, value]) => {
                                    fd.append(key, String(value));
                                }); 

        let click = document.getElementById('closeEdit')?.click();

           let url = ServerUrl+'/api/employee/update/'+code;
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status === 200){
             SuccessModal(response.data.message)
           
                      } else{
                        ErrorModal('', response.data.message)
                      }   
                  })
                  .catch((error)=>{
                   ErrorModal('oops', error.message)
                  }).finally(()=>{

                    setProfile({
                        certificate:'',
                        institution:'',
                        grade:'',
                        year_start:'',
                        year_end:'',
                          code:Math.random().toString(36).substring(2, 9),
                          date: new Date()
                    })

                  })  
            }
        
        }


        const EditItem =(data:any)=>{
            setProfile({

                certificate:data.certificate,
                institution:data.institution,
                grade:data.grade,
                year_start:data.year_start,
                year_end:data.year_end,
                  code:data.code,
                  date: data.date,

            });
            setErrors({
               certificate:'',
                institution:'',
                grade:'',
            })
            let click = document.getElementById('openEdit')?.click()
        
        }

        const handleReset =()=>{
            setProfile({
                certificate:'',
                institution:'',
                grade:'',
                year_start:'',
                year_end:'',
                  code:Math.random().toString(36).substring(2, 9),
                  date: new Date()
            })
            let click = document.getElementById('openAdd')?.click()
        }

useEffect(()=>{

   try{

    if (data!==undefined && data.length!==0){
    setContent(data)
    setContact(JSON.parse(data[0].education_history))
    window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
         
    }

   }catch(err) {

   }
    
    }, [data])


  return (                             
    <div>
    
     
    <div className="card z-depth-0">
    
    <div className="card-header">
    <h5>Education History</h5>
    
    <div className="card-header-right">
    
            
<label className="label label-primary pointer " onClick={handleReset}> <i className='fa fa-plus text-white'></i> Add Qualification</label>
<label  id='openAdd' data-toggle="modal" data-target="#large-Modal"> </label>         
    </div>
    
    </div>



    <label data-toggle="modal" data-target="#editEducation" id='openEdit' ></label>
    <div className="modal fade push-modal-right" id="editEducation" tabIndex={-1} role="dialog" data-backdrop="static" >
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header bg-dark">
                    <h4 className="modal-title text-white">Update Qualification</h4>
                  
    
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i className='fa fa-ban text-danger'></i>
            </button>
    
                </div>
                <div className="modal-body">
                <div className="row">
    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Certificate <span className='text-danger'>*</span></label>
    
    <select  className={errors.certificate?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='certificate' value={profile.certificate} onChange={handleChangeSelect}>

    <option value="" selected disabled>--Select--</option>
    <option value="SSCE">SSCE</option>
    <option value="Diploma">Diploma</option>
    <option value="NCE">NCE</option>
    <option value="ND">ND</option>
    <option value="HND">HND</option>
        <option value="BSc">BSc</option>
        <option value="PGD">PGD</option>
        <option value="MSc">MSc</option>
        <option value="MSc">MBA</option>
        <option value="PhD">PhD</option>
        <option value="Others">Others</option>
    </select>

    {errors.certificate&&
    <div className="col-form-label has-danger">{errors.certificate&&errors.certificate} </div>}
    </div>
    
    </div>
    
    
    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Institution <span className='text-danger'>*</span></label>
    
    
<input type="text" className={errors.institution?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='institution' value={profile.institution} onChange={handleChange} />
    
    {errors.institution&&
    <div className="col-form-label has-danger">{errors.institution&&errors.institution} </div>}
    
    </div>
    
    </div>
    
    
    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Grade <span className='text-danger'>*</span></label>

    <select   className={errors.certificate?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} name='grade' value={profile.grade} onChange={handleChangeSelect}>
    <option value="" selected disabled>--Select--</option>
    <option value="Distinction">Distinction</option>
    <option value="Upper Credit">Upper Credit</option>
    <option value="Lower Credit">Lower Credit</option>

    <option value="First Class">First Class</option>
    <option value="Second Class Upper">Second Class Upper</option>
    <option value="Second Class Lower">Second Class Lower </option>
    <option value="Third Class">Third Class </option>
    <option value="Pass">Pass </option>

    <option value="Not Grading">Not Graded </option>
        </select>
    </div>
    
    </div>
    
    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Year Start</label>
    

<input type="number" min='1900' max='2099' step='1' className="form-control form-control-lg"  name='year_start' value={profile.year_start} onChange={handleChange} />
    
    </div>
    
    </div>
    
    
    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Year End</label>
    
    
<input type="number" min='1900' max='2099' step='1' className="form-control form-control-lg"  name='year_end' value={profile.year_end} onChange={handleChange} />
    
    </div>
    
    </div>
    
    
    
    
    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-default waves-effect " data-dismiss="modal" id='closeEdit'>Close</button>
                    <button type="button" className="btn btn-outline-success " onClick={handleUpdate} >Update</button>
                </div>
            </div>
        </div>
    </div>
    

    
    
    <div className="modal fade push-modal-right" id="large-Modal" tabIndex={-1} role="dialog" data-backdrop="static" >
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header bg-dark">
                    <h4 className="modal-title text-white">Add Qualification</h4>
                  
    
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i className='fa fa-ban text-danger'></i>
            </button>
    
                </div>
                <div className="modal-body">
                <div className="row">
    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Certificate <span className='text-danger'>*</span></label>
    
    <select  className={errors.certificate?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='certificate' value={profile.certificate} onChange={handleChangeSelect}>

    <option value="" selected disabled>--Select--</option>
    <option value="SSCE">SSCE</option>
    <option value="Diploma">Diploma</option>
    <option value="NCE">NCE</option>
    <option value="ND">ND</option>
    <option value="HND">HND</option>
        <option value="BSc">BSc</option>
        <option value="PGD">PGD</option>
        <option value="MSc">MSc</option>
        <option value="MSc">MBA</option>
        <option value="PhD">PhD</option>
        <option value="Others">Others</option>
    </select>

    {errors.certificate&&
    <div className="col-form-label has-danger">{errors.certificate&&errors.certificate} </div>}
    </div>
    
    </div>
    
    
    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Institution <span className='text-danger'>*</span></label>
    
    
<input type="text" className={errors.institution?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='institution' value={profile.institution} onChange={handleChange} />
    
    {errors.institution&&
    <div className="col-form-label has-danger">{errors.institution&&errors.institution} </div>}
    
    </div>
    
    </div>
    
    
    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Grade <span className='text-danger'>*</span></label>

    <select   className={errors.certificate?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} name='grade' value={profile.grade} onChange={handleChangeSelect}>
    <option value="" selected disabled>--Select--</option>
    <option value="Distinction">Distinction</option>
    <option value="Upper Credit">Upper Credit</option>
    <option value="Lower Credit">Lower Credit</option>

    <option value="First Class">First Class</option>
    <option value="Second Class Upper">Second Class Upper</option>
    <option value="Second Class Lower">Second Class Lower </option>
    <option value="Third Class">Third Class </option>
    <option value="Pass">Pass </option>

    <option value="Not Grading">Not Graded </option>
        </select>
    </div>
    
    </div>
    
    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Year Start</label>
    

<input type="number" min='1900' max='2099' step='1' className="form-control form-control-lg"  name='year_start' value={profile.year_start} onChange={handleChange} />
    
    </div>
    
    </div>
    
    
    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Year End</label>
    
    
<input type="number" min='1900' max='2099' step='1' className="form-control form-control-lg"  name='year_end' value={profile.year_end} onChange={handleChange} />
    
    </div>
    
    </div>
    
    
    
    
    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-default waves-effect " data-dismiss="modal" id='close'>Close</button>
                    <button type="button" className="btn btn-primary waves-effect waves-light " onClick={handleSubmit}>Save Education</button>
                </div>
            </div>
        </div>
    </div>
    
    
    
    
    
    <div className="card-block">
    
    
    <div className="table-responsive m-t-10">
        <TableData />


    </div>
    
    </div>
    </div>
    </div>
                  
 
  )
}

export default Qualification