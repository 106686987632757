import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo, useRef} from 'react'
import { COMPANY_CODE, CURRENCY, ImagesUrl, STAFF_CODE, ServerUrl, config, selectStyles, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts, CustomLoader } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'

import { useQuery } from 'react-query'
import { usePaginated, DOTS } from '../../components/usePagination';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { FormatNumber, getMonthYear } from '../../components/GlobalFunction';
import { Link } from 'react-router-dom';
import { FetchProfile } from '../../api/ReactQuery';

const  PaymentStructure =()=> {
   
    const profile = useQuery(["profile"], FetchProfile)
    const [director, setDirector] = useState({
       
        isName:false,
        isMonth:false,
        isPayDate:false,
        isEarning:false,
        isDeduction:false,

        isNetPay:false,
        isApproved:false,
        
        isAction:false,
    });

    const [level, setLevel] = useState('');
    const [salaryHead, setSalaryHead] = useState([] as any);
    const [payment, setPayment] = useState([] as any);
    const [title, setTitle] = useState([] as any);
    const [template, setTemplate] = useState([] as any);
    const [search, setSearch] = useState({
        template:[] as any,


        employee:[] as any,
        payment_date:'',
        payment_month:'',
        payment_status:{value:'All', label:'All'},
        rowperpage:{value:'10', label:'10'},
        filterText:'',
        selectAll:false,
        totalSelected:0,
     })


    const [filterContent, setFilterContent] = useState([] as any);
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        
         staff:'',
         pay_mode:'',
         pay_date:'',
         pay_month:''
       
     })
    
   
        const fetchTemplate =async()=>{
            let url = ServerUrl+'/api/payroll/structure/view_structure/'+COMPANY_CODE
            const {data} = await axios.get(url, config)
            setTemplate(data.data)
            }

            const fetchTitle = async()=>{
                let url = ServerUrl+'/api/payroll/structure/view_title/'+COMPANY_CODE
              await  axios.get(url, config).then((result:any)=>{
                if(Array.isArray(result.data.data) && result.data.data.length!==0){
                  setTitle(result.data.data)
                  }
                })
                .catch((error)=>{
                    Alerts('danger', error.message)
                })
                } 

     const handleSelect = (option:any, action:any)=>{
        setSearch({...search, [action.name]:option});
          setErrors({...errors, [action.name]:''})

           }


           const handleChange = (event:ChangeEvent<HTMLInputElement>)=>{
           
            let {name, value} = event.target;	
            setSearch({...search, [name]:value});
           setErrors({...errors, [name]:''});
        }

        
const handleReset =()=>{
    setSearch({
        template:[] as any,
        employee:[] as any,
        payment_date:'',
        payment_month:'',
        payment_status:{value:'All', label:'All'},
        rowperpage:{value:'10', label:'10'},
        filterText:'',
        selectAll:false,
        totalSelected:0,
    })
    setSalaryHead([])
    setPayment([])
}




        const TableData =()=>{

            

            const PaginationWrapper =(props:any)=>{
            
                const handleRowsPerPage = useCallback(
                    (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
                    [props.currentPage, props.onChangeRowsPerPage],
                );
                const handleSelectRowsPerPage = useCallback((option:any, action:any)=> props.onChangeRowsPerPage(Number(option.value), props.currentPage),
                [props.currentPage, props.onChangeRowsPerPage],
                )
               
                function getNumberOfPages(rowCount:number, rowsPerPage:number) {
                    return Math.ceil(rowCount / rowsPerPage);
                  }
                const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
                const lastIndex = props.currentPage * props.rowsPerPage;
                const firstIndex = lastIndex - props.rowsPerPage + 1;
            
            
                    const range =
                    props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
            
                    `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
            
                
               
                const Pagination = () => {
                   
                    const pageSize = props.rowsPerPage;
                    const totalCount = content.length
                    const siblingCount = 1
                    const currentPage = props.currentPage
                    
                    const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
                    
                    if (currentPage === 0 ) {
                      return null;
                    }
                    
                    let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
                    return (
                    <nav className="" aria-label="Page navigation sample ">
                      <ul  className="pagination pagination-sm"  >
                        <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
                         <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Previous</a>
                        </li>
                        {paginationRange&&paginationRange.map((pageNumber, index) => {
                          if (pageNumber === DOTS) {
                            return <li className="pagination-item dots" key={index}>
                             <a className="page-link pointer"  >&#8230;</a>
                            </li>
                          }
                          return (
                            <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                              <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                              
                            </li>
                          );
                        })}
                    
                    <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
                    <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
                        </li>
                    
                      </ul> </nav>
                    );
                    
                        }
            
                      
            
                      
            
             return <div className="row mt-3">
                                
            <div className="col-md-5 ">
            <div className="row ml-3" >
            
               Rows per page: &nbsp; <select name="rowPerPage" id="" className='w-40 h-30 ' onChange={handleRowsPerPage}>
                    <option value="10"> 10</option>
                    <option value="20"> 20</option>
                    <option value="30"> 30</option>
                    <option value="40"> 40</option>
                    <option value="50"> 50</option>
                    <option value={content.length}> All</option>
                </select> &nbsp;&nbsp;
                {/*  {range} */} </div>
            </div>
              
            <div className="col-md-7  justify-content-end d-flex">
               <Pagination  />
                </div>
                
            </div>}
            
            
            
            
            
            
                        const columns = [
            
                          
                         
                            {
                                name: 'Template',
                                selector: (row:any) =>row.level,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isName,
                            },
                            {
                                name: 'Payment Month',
                                selector: (row:any) => getMonthYear(row.payment_month),
                                sortable: true,
                                reorder:true,
                                wrap:true,
                                omit: director.isMonth,
                                
                            },
                            {
                                name: `Earning (${CURRENCY})`,
                                selector: (row:any) => FormatNumber(row.earning),
                                sortable: true,
                                reorder:true,
                                omit: director.isEarning,
                            },
                            {
                                name: `Deduction (${CURRENCY})`,
                                selector: (row:any) => FormatNumber(row.deduction),
                                sortable: true,
                                reorder:true,
                                omit: director.isDeduction,
                            },
                            {
                                name: `Net Pay (${CURRENCY})`,
                                cell: (row:any) =>FormatNumber(row.net_pay),
                                sortable: true,
                                reorder:true,
                                omit: director.isNetPay,
                            },
                            {
                                name: 'Approved',
                                cell: (row:any) => <label className="switch-md">
                                <input type="checkbox" checked={row.is_approved==='Yes'?true:false}   />
                                <span className="round slider-md"></span>
                              </label>,
                              omit: director.isApproved,
                              width:'100px'
                            },
                            {
                                name: 'Action',
                                cell: (row:any, index:any, column:any, id:any) => <div className='row'>  

                                   <label className="label label-primary pointer" ><i className='fa fa-print'></i> Print</label>

                                   <label onClick={()=>fetchPayment(row)} className="label label-inverse pointer" ><i className='fa fa-eye'></i> View</label>
                                </div>,
                                    left:true,
                                    omit: director.isAction,
                                    width:'150px'
                            },
                        ];
                        
                    
                    
                    
                        return <DataTable
                        columns={columns}
                        data={filterContent}
                        highlightOnHover
                        pagination
                        paginationComponent={PaginationWrapper}
                        selectableRowsHighlight
                        striped
                        responsive
                        fixedHeader
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                    />
                    }
            

                    const getTitle = (code:string, field:string) =>{ 
                        const result = title.filter((list:any)=>list.code===code);
                       const answer = result.map((c:any)=>c[field] ); 
                         return  String(answer);
                       }
                    const fetchPayment = async(record:any)=>{
                        
                        const data = template.filter((list:any)=>list.code===record.code)[0].template

                        try{
                            let temp = JSON.parse(data)
                            var itemList = [];
                            for (var i = 0; i< temp.length;  i++) {
                                itemList.push(
                                    {  
                                        title_code:temp[i].title_code,
                                        title:'"'+getTitle(temp[i].title_code, 'title')+'"',
                                        item_code:getTitle(temp[i].title_code, 'item_code'),
                                        item_type:getTitle(temp[i].title_code, 'item_type')
                                    });
                                    }
                                    setSalaryHead(itemList)
                                    
                                setLevel(record.level)
                let sqlString = itemList.map((item:any)=>"sum(case when p.item_code = '"+item.item_code+"'  then p.amount_topay else 0 end) AS "+item.title)


                let  final =   "SELECT p.staff_code, concat(e.first_name, ' ', e.last_name) as staff_name, "+String(sqlString)+ ", COALESCE(sum(case when p.item_type ='DEDUCTION' then p.amount_topay else 0 end), 0) as deduction,  COALESCE(sum(case when p.item_type ='EARNING' then p.amount_topay else 0 end),0) as earning, (COALESCE(sum(case when p.item_type ='EARNING' then p.amount_topay else 0 end),0)- COALESCE(sum(case when p.item_type ='DEDUCTION' then p.amount_topay else 0 end), 0)) as net_pay from tbl_employee e, tbl_salary_payment p where e.code = p.staff_code and p.company_code = '"+COMPANY_CODE+"' and p.payment_month = '"+record.payment_month+"' and p.template_code = '"+record.code+"'  group by p.template_code, p.staff_code, e.first_name, e.last_name order by e.first_name ASC "


                const fd = new FormData();
                 fd.append('sql', final); 
                 let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
               await  axios.post(url, fd, config).then((result:any)=>{

                 if(Array.isArray(result.data.data) && result.data.data.length!==0){
                    setPayment(result.data.data)

window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
                   }else{

                    setPayment([])
                   }
                 })
                 .catch((error)=>{
                     Alerts('danger',error.message)
                 }).finally(()=>{
                     setLoading(false)
                 })


                        }catch(e){

                        }

                             } 

         const fetchContent = async()=>{
            setLoading(true)
     
                 let sql = "SELECT t.level, t.code, p.is_approved, p.payment_month, COALESCE(sum(case when p.item_type ='DEDUCTION' then p.amount_topay else 0 end), 0) as deduction,  COALESCE(sum(case when p.item_type ='EARNING' then p.amount_topay else 0 end),0) as earning, (COALESCE(sum(case when p.item_type ='EARNING' then p.amount_topay else 0 end),0)- COALESCE(sum(case when p.item_type ='DEDUCTION' then p.amount_topay else 0 end), 0)) as net_pay FROM tbl_salary_payment p, tbl_payroll_structure t  where p.company_code = '"+COMPANY_CODE+"' and p.template_code = t.code  "
     
                 if(search.template.length!==0){
                    sql = sql + " and p.template_code = '"+search.template.value+"'"
                }

                if(search.payment_month!==''){
                    sql = sql + " and p.payment_month = '"+search.payment_month+"'"
                   
                }


                sql = sql + " GROUP BY p.payment_month, t.level, t.code, p.is_approved "
                     
                 const fd = new FormData();
                 fd.append('sql', sql); 
                 let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
               await  axios.post(url, fd, config).then((result:any)=>{

                 if(Array.isArray(result.data.data) && result.data.data.length!==0){
                    setFilterContent(result.data.data)
                   setContent(result.data.data)
                   }else{

                    setContent([])
                    setFilterContent([])
                   }
                 })
                 .catch((error)=>{
                     Alerts('danger',error.message)
                 }).finally(()=>{
                     setLoading(false)
                 })
                 } 



                

    useEffect(()=>{
        fetchTitle()
        fetchContent()
        fetchTemplate()
      }, [])



  return ( <>
   <Breadcrumb 
   title="Payment Structure"
   summary="View breakdown of payment by structure "
   >
    <li className="breadcrumb-item">
    <Link to="/payroll/staff_salary">Payroll</Link>
                    </li>
   <li className="breadcrumb-item">
    <Link to="#!">Payment Structure</Link>
                    </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Payment History</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                              
                                    
                                    
         <div className="content-wrappers ">
                            
         <div className=''>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Employee Salary</h5>
                <span>Add all offcie department</span>
              
            </div>
            <div className="card-block">
    
        
                        <div className="row">
                  

                        <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Select Template</label>

                    <Select  options={
                                template&&template.map((list:any, idx:number)=> {
                                    return {key:idx, value: list.code, label: list.level}
                                    })}
                                    
                     onChange={handleSelect} 
                     name="template"
                      value={search.template}
    
                                        styles={styles} 
                                        />        
                
                    </div>
    
                     </div>


                    
    <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Select Payment Month </label>
           
            <input type="month"  className="form-control form-control-lg"   name='payment_month' value={search.payment_month} onChange={handleChange}  />
          

             </div>

             </div>

                    




           

   </div>

          
<hr />
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-outline-danger btn-round pr-5 pl-5 mr-3" type='button' onClick={handleReset}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={fetchContent}>Search</button>
</div>
</div>
    
            </div>
        </div>
    </div>
    </div>
    
    
    
    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Payment History</h5>
               
                
                <span>Add all offcie department</span>
            </div>
            <div className="card-block">
    

    <div className="row">
        <div className="table-responsive ">
        <TableData />
        </div>
        </div>

            </div>
        </div>
    </div>
    </div>
    
                </div>

        </div>
                       
                    </section>
                </div>
            </div>
        </div>
    </div>
                                    </div>
                                </div>
                            </div>







               {payment.length!==0?             <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-block">
    

    <div className="row">
        <div className="table-responsive ">
       

        <table className="table table-sm table-bordered table-hover">
                <thead>
                    <tr>
                        <th colSpan={5+salaryHead.length} className='text-center '>
                        <img 
           src={ImagesUrl+'/about/'+profile.data.logo}  alt="Click to change" 
           className="m-b-10 img-100" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/logo.png'}} />


                        <h4>{profile.data.company_name} </h4>
                        <h5>{profile.data.company_address}</h5>
                        <h6 className='m-b-5'>{profile.data.email}, {profile.data.phone}</h6>
<div className="alert alert-info text-center mb-1 mt-2">

<h5> {level} Payment Structure</h5>
</div>

                        </th>
                    </tr>
                    <tr style={{backgroundColor:'#E7E0EE'}}>
                    <th >SN</th>
                        <th >Employee Name</th>
                        {salaryHead&&salaryHead.map((data:any, id:number)=><th key={id} className="vertical-header"><span>{data.title.replace(/"/g, '')}</span></th>)}
                        <th className="vertical-header">Deduction ({CURRENCY})</th>
                        <th className="vertical-header">Earnings ({CURRENCY})</th>
                        <th className="vertical-header">Net Pay ({CURRENCY})</th>

                    </tr>
                </thead>
                <tbody>
                    {payment&&payment.map((list:any, index:number)=>
                    <tr key={index}>
                        <th >{index+1}</th>
                        <th >{list.staff_name}</th>

                        {salaryHead&&salaryHead.map((data:any, id:number)=><td key={id} >{FormatNumber(list[data.title.replace(/"/g, '')])}</td>)}
                        <th >{FormatNumber(list.deduction)}</th>
                        <th >{FormatNumber(list.earning)}</th>
                        <th >{FormatNumber(list.net_pay)}</th>
                        
                    </tr>)}

                </tbody>

                   
<tfoot>
<tr >
<th colSpan={salaryHead.length +2} className='text-right'>TOTAL</th>
                        <th>{FormatNumber(payment.reduce((acc:number, item:any)=>Number(item.deduction)+Number(acc), 0))}</th>
                        <th>{FormatNumber(payment.reduce((acc:number, item:any)=>Number(item.earning)+Number(acc), 0))}</th>
                        <th>{FormatNumber(payment.reduce((acc:number, item:any)=>Number(item.net_pay)+Number(acc), 0))}</th>
                    </tr>

                  {/*   <tr>
                        <th colSpan={salaryHead.length +5}>
                            
                            <div>
                                <p >Prepared By:  <span className='text-bold'>Opeyemi Mansoor</span></p>
                                <p >Prepared By:  <span className='text-bold'>Opeyemi Mansoor</span></p>
                            </div>
                            
                            
                            
                            </th>
                    </tr> */}

</tfoot>
            </table>

        </div>

        <button className="btn btn-primary btn-sm text-right" type='button' ><i className='fa fa-print'></i> Print</button>


        </div>

            </div>
        </div>
    </div>
    </div>:[]}
    

                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default PaymentStructure