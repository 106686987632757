import { useState} from 'react'
import { COMPANY_CODE, ImagesUrl, ServerUrl, config, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, closeModal, Alerts } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'


import Swal from 'sweetalert2'
import { useQuery } from 'react-query'
import Select from 'react-select'
import { FetchProfile, fetchDepartment} from '../../api/ReactQuery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertDate, getBritishDate } from '../../components/GlobalFunction';
import { Link } from 'react-router-dom';

const DeleteAttendance =()=> {
    
  const profile = useQuery(["profile"], FetchProfile)
    const [content, setContent] = useState([] as any);
    const department = useQuery(["department"], fetchDepartment)
    const [search, setSearch] = useState({
     
        department:[] as any,
        staff:[] as any,
        start_date:null,
        end_date:null
     })

    
           const [errors, setErrors] = useState({
            start_date:'',
            end_date:''
           
         })

       
         const handleSelect = (option:any, action:any)=>{
            setSearch({...search, [action.name]:option});
              setErrors({...errors, [action.name]:''})
              setContent([])
    
               }


               const handleChangeDate = (data:any, name:string)=>{
                setSearch({...search, [name]:data});
                setErrors({...errors, [name]:''})
                setContent([])
             }


         
    

               

const fetchContent = async()=>{


    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';
              

        if(!search.start_date){
            error.start_date =msg;
            formIsValid = false;
        }  


        if(!formIsValid){
        setErrors(error) 
        }else{
    LoadingModal()

    let final =   'SELECT e.first_name, e.last_name,  sum(case when a.attendance  = "P" then 1 else 0 end) as P, sum(case when a.attendance  = "A" then 1 else 0 end) as A, sum(case when a.attendance  = "H" then 1 else 0 end) as H  from tbl_employee e, tbl_employee_attendance a where e.code = a.staff_code and e.termination_reason = "" and a.attendance_date = "'+convertDate(search.start_date)+'" '


    if(search.department.length!==0){
        final = final + " and e.department = '"+search.department.value+"'"
    }

    final = final + " GROUP BY a.staff_code,  e.first_name, e.last_name"

          const fd = new FormData();
          fd.append('sql', final); 
          let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
        await  axios.post(url, fd, config).then((result:any)=>{
          if(Array.isArray(result.data.data) && result.data.data.length!==0){
            setContent(result.data.data)
            window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
            }else{
                setContent([]) 
                Alerts('default',' There are no attendance found for the selected date ')
            }
          })
          .catch((error)=>{
              Alerts('danger',error.message)
          }).finally(()=>{
              closeModal()
          })
        }
          } 




          const DeleteModal =()=>{
            let url = ServerUrl+'/api/attendance/delete_attendance/'+convertDate(search.start_date);
          
            Swal.fire({
              title: 'Are you really sure?',
              text: "This is very dangerous, you shouldn't do it!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              showLoaderOnConfirm: true,
            preConfirm: async() => {
              return await axios.get(url, config).then(response=>{
               if(response.data.status!==200){
                    throw new Error(response.statusText)
                  }
                  return response.data
                })
                .catch(error => {
                  Swal.showValidationMessage(
                    `Request failed: ${error}`
                  )
                })
            },
            allowOutsideClick: () => !Swal.isLoading(),
              confirmButtonText: 'Yes, I am!'
            }).then((result:any) => {
              if (result.isConfirmed && result.value.status===200) {
               
                      setContent([])
                 Swal.fire(
                            'Deleted!',
                            'Record has been deleted.',
                            'success'
                          ) 
              }
            })
          }


  return ( <>
   <Breadcrumb 
   title="Delete Attendance"
   summary="Remove any wrongly mark attendance"
   >
    <li className="breadcrumb-item">
    <Link to="/attendance/display_attendance">Attendance</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Delete Attendance</a> </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
<div className="main-body">
<div className="page-wrapper">
<div className="page-body">


<div className="row">
<div className=" col-md-12">
<div className="card z-depth-0">
    <div className="card-header">
        <h5>DELETE ATTENDANCE</h5>
        <span  ><a href="#">Delete Employee Attendnace</a></span>
        <div className="card-header-right">

            <ul className="list-unstyled card-option">
                <li><i className="fa fa fa-wrench open-card-option"></i></li>
                <li><i className="fa fa-window-maximize full-card"></i></li>
                <li><i className="fa fa-minus minimize-card"></i></li>
                <li><i className="fa fa-refresh reload-card"></i></li>
                <li><i className="fa fa-trash close-card"></i></li>
            </ul>
            
        </div>
    </div>
    <div className="card-block ">
       
    <div className="alert alert-primary">
        <h3><p className="alert alert-danger border-danger background-danger" >Important : Choose Date Carefully</p></h3>

     <p> This should be  used <strong >when you have to delete attendance. </strong></p>
        
 <p >Attendance deleted here cannot be recovered it can only be remarked.</p>
						
	</div>

        <div className="row">
        <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Search by Department</label>
    
    
    <Select  options={
            department.data&&department.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.department_name}
                    })}   
                    styles={styles} 
			onChange={handleSelect}    
            name="department" 
            value={search.department} 
               />
    
    </div>
    
    </div>

    <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Attendance Date <span className='text-danger'>*</span></label>
           
            <DatePicker 
            selected={search.start_date}      
             isClearable
             dateFormat="yyyy-MM-dd"
             className={errors.start_date?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}
             placeholderText="Select date"
           
 onChange={(date:any) => handleChangeDate(date, 'start_date')} />


        
{errors.start_date&&
    <div className="col-form-label has-danger">{errors.start_date&&errors.start_date} </div>}
             </div>

             </div>

        </div>


<hr/>
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={fetchContent}>Get Attendnace</button>
</div>
</div>


    </div>
</div>
</div>
</div>






{content.length!==0?
  <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-block">
    

    <div className="row">
        <div className="table-responsive ">
       
        <table className="table table-sm table-bordered table-hover">
                <thead>
                    <tr>
                        <th colSpan={5} className='text-center '>
                        <img src={ImagesUrl+'/logo.png'}className="m-b-10 img-100" alt="" />
                        <img 
           src={ImagesUrl+'/about/'+profile.data.logo}  alt="Click to change" 
           className="m-b-10 img-100" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/logo.png'}} />


                        <h4>{profile.data.company_name} </h4>
                        <h5>{profile.data.company_address}</h5>
                        <h6 className='m-b-5'>{profile.data.email}, {profile.data.phone}</h6>
                        <div className="alert alert-info text-center mb-1 mt-2">

                        <h5> EMPLOYEE ATTENDANCE SUMMARY</h5>
                        </div>
                        </th>
                    </tr>
                  

                  <tr>
                        <td colSpan={5}>  <h6>Attendance Date: {getBritishDate(convertDate(search.start_date)) } </h6></td>
                    </tr> 


                    <tr style={{backgroundColor:'#E7E0EE'}}>
                <th >S/N</th>
                    <th className='fixed-column' style={{backgroundColor:'#E7E0EE'}}>Staff Name</th>
                    <th>P</th>
                    <th>H</th>
                    <th>A</th>
                </tr>

                </thead>
                <tbody>
                    {content&&content.map((st:any, index:number)=>
                    <tr key={index}>
                        <td >{index+1}</td>
                        <td >{st.first_name+ ' '+ st.last_name}</td>
                        <td>{st.P}</td>
                        <td>{st.H}</td> 
                        <td>{st.A}</td>  
                </tr>)}
                          

                </tbody>

                   
            </table>

        </div>


        <div className="row">
        <div className="col-md-12">
        <button onClick={DeleteModal} type="button" className="btn btn-danger  no-print" > <i className="fa fa-trash"></i> Delete Attendnace</button>
        </div></div>


        </div>

            </div>
        </div>
    </div>
    </div>:[]}

</div>
</div>


</div>
</div>  
      </>               
 
  )
}

export default DeleteAttendance