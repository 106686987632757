import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, STAFF_CODE, ServerUrl, config, group_styles, selectStyles, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'

import Swal from 'sweetalert2'
import { convertDate, getNumWorkDays } from '../../components/GlobalFunction';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
const  LeaveApplication =()=> {
   
    const [item, setItem] = useState({
       staff:[] as any,
       start_date:null,
       end_date:null,
       no_days:0,
       reason:'',
       leave_type:[] as any,
    });

    const [history, setHistory] = useState({
        staff_code:'',
        total_used:0,
        leave_code:'',
        balance:0,
       holidays:0,
       no_days:0
        
    });

    const [template, setTemplate] = useState([] as any);
    const [staff, setStaff] = useState([] as any);
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        staff:'',
       start_date:'',
       end_date:'',
       no_days:'',
       reason:'',
       leave_type:'',
       
     })
    

     const fetchStaff =async()=>{
        let url = ServerUrl+'/api/employees/view/'+COMPANY_CODE
        const {data} = await axios.get(url, config)
        setStaff(data.data)
        }


        const handleChangeDate = (data:any, name:string)=>{
          let no:any  = 0;
           
          
          if(name==='start_date'){
           no= getNumWorkDays(data, item.end_date)
           fetchAllHolidays(data, item.end_date)
          }else{
           no = getNumWorkDays(item.start_date, data)
           fetchAllHolidays(item.start_date, data)
          } 
         setItem({...item, [name]:data, no_days:no});
          setErrors({...errors, [name]:''});
       }


        const fetchAllHolidays =async(start_date:any, end_date:any)=>{
            let WORKING_DAYS = Number(getNumWorkDays(start_date, end_date));

            let sql = 'Select count(id) as total from tbl_holidays where event_type= "Holiday" and start_date >= "'+convertDate(start_date)+'" and start_date <= "'+convertDate(end_date)+'"'
        

                const fd = new FormData();
                fd.append('sql', sql); 
                let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
              await  axios.post(url, fd, config).then((result:any)=>{

                if(Array.isArray(result.data.data) && result.data.data.length!==0){
                  setHistory({...history, 
                    holidays:result.data.data[0].total, 
                    no_days:WORKING_DAYS - Number(result.data.data[0].total)})

                  }else{
                    setHistory({...history, 
                      holidays:0, 
                      no_days:WORKING_DAYS})
                  }

                  setErrors({...errors, no_days:''})
                })
                .catch((error)=>{
                    Alerts('danger',error.message)
                }) 
        }


        const fetchTemplate =async(staff_code:string)=>{
            LoadingModal()
            let url = ServerUrl+'/api/leave/show_assign_leave/'+staff_code+'/'+COMPANY_CODE
          await  axios.get(url, config).then((result:any)=>{
            if(Array.isArray(result.data.data) && result.data.data.length!==0){
                setTemplate(result.data.data)
              }else{
                setTemplate([])
                setErrors({...errors, staff:'There are no leave entitlements assigned to this employee yet'})
              }
            })
            .catch((error)=>{
                Alerts('danger', error.message)
            }).finally(()=>{
                closeModal()
            })
            } 



     const fetchHistory =async(option:any)=>{
            LoadingModal()
            let url = ServerUrl+'/api/leave/show_leave_history/'+item.staff.value+'/'+COMPANY_CODE+'/'+option.value
          await  axios.get(url, config).then((result:any)=>{

            console.log(result.data.data, url)
            if(Array.isArray(result.data.data) && result.data.data.length!==0){
                setHistory({
                    ...history,
                    staff_code:result.data.data[0].staff_code,
                    total_used:result.data.data[0].total_used,
                    leave_code:result.data.data[0].leave_code,
                    balance:option.no_days - result.data.data[0].total_used
                   
                })
              }else{
                setHistory({...history,
                    balance:option.no_days,
                    staff_code:'',
                    total_used:0,
                    leave_code:'',
                })
              }
            })
            .catch((error)=>{
                Alerts('danger', error.message)
            }).finally(()=>{
                closeModal()
            })
            } 

            const handleSelectLeave = (option:any, action:any)=>{
                setItem({...item, [action.name]:option});
                  setErrors({...errors, [action.name]:''})
                  fetchHistory(option)

                   }
   
     const handleSelect = (option:any, action:any)=>{
        setItem({...item, [action.name]:option, leave_type:[]});
          setErrors({...errors, [action.name]:''})
          fetchTemplate(option.value)

           }

           const handleChange = (event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
            setItem({...item, [name]:value});
           setErrors({...errors, [name]:''});
        }

        const handleChangeArea = (event:ChangeEvent<HTMLTextAreaElement>)=>{
            let {name, value} = event.target;	
            setItem({...item, [name]:value});
           setErrors({...errors, [name]:''});
        }

          
      


const handleReset =()=>{
    setItem({
        staff:[] as any,
       start_date:null,
       end_date:null,
       no_days:0,
       reason:'',
       leave_type:[] as any,
    })

    setHistory({
      staff_code:'',
      total_used:0,
      leave_code:'',
      balance:0,
     holidays:0,
     no_days:0
    })
}




  const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';


if(Number(history.no_days)<=0){
  error.no_days ="Please select a different date range"
  formIsValid = false;
}

if(Number(history.no_days) > Number(history.balance)){
  error.no_days ="Estimated days cannot be more than leave balance"
  formIsValid = false;
}

if(item.staff.length===0){
    error.staff =msg;
    formIsValid = false;
} 
if(item.leave_type.length===0){
  error.leave_type =msg;
  formIsValid = false;
} 

if(!item.start_date){
  error.start_date =msg;
  formIsValid = false;
} 
 
if(!item.end_date){
  error.end_date =msg;
  formIsValid = false;
} 

if(!item.reason){
  error.reason =msg;
  formIsValid = false;
} 


if(!formIsValid){
setErrors(error) 
}
if(formIsValid) {

    LoadingModal()
   

    const fd = new FormData();

let day:any = history.no_days
    let code = 'LA'+Math.random().toString(36).substring(2, 9)
    fd.append('company_code', COMPANY_CODE);
    fd.append('code', code);
    fd.append('added_by', STAFF_CODE);
    fd.append('days', day);
    fd.append('staff_code', item.staff.value);
    fd.append('leave_code', item.leave_type.value);
    fd.append('start_date', convertDate(item.start_date));
    fd.append('end_date', convertDate(item.end_date));
    fd.append('reason', item.reason);

let url = ServerUrl+'/api/leave/application/create';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                Alerts('danger', response.data.message)
                closeModal()
              }   
          })
          .catch((error)=>{
            Alerts('danger', error.message)
            closeModal()
          }).finally(()=>{
         handleReset()

          })
    }

}



const DeleteModal =(staff_code:string)=>{
    let url = ServerUrl+'/api/leave/delete_assigned_leave/'+staff_code;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =content.filter((item:{staff_code:string})=>item.staff_code !==staff_code)
            setContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }





    useEffect(()=>{
      
        fetchStaff() 
      }, [])


    

  return ( <>
  
<Breadcrumb 
   title="Leave Application"
   summary=" If you want to allocate 1 day leave - Start Date date and End Date date will be same"
   >
   <li className="breadcrumb-item">
    <Link to="/leave/leave_list">Leave</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Leave Application</a> </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Leave Application </h5>
                    <span>Estimated Days (Exc. Weekends and Holidays) Will be subtracted from Leave Balance</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                              
                                    
                                    
         <div className="content-wrappers ">
                            
         <div className=''>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-block">
    
        
                        <div className="row">
                  
                        <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Select Employee</label>


                 
                <Select 
                options={
                               staff&&staff.map((list:any, idx:number)=> {
                                return {key:idx, value: list.code, label: list.first_name, last_name:list.last_name, middle_name:list.middle_name, title:list.title }
                                })}
                                className={errors.staff?"form-control form-control-danger":""} 
                     getOptionLabel={option =>` ${option.label} ${option.last_name}`}
    onChange={handleSelect}  name="staff" value={item.staff} 
   styles={selectStyles}
    /> 
                    
                    {errors.staff&&
    <div className="col-form-label has-danger">{errors.staff&&errors.staff} </div>}
                    </div>
    
                     </div>




                     <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Select Leave Type <span className='text-danger'>*</span></label>


                    <div className="input-group  ">
                 
                    <Select  options={
                                template&&template.map((list:any, idx:number)=> {
                                    return {key:idx, value: list.leave_type_code, label: list.leave_name, effective_month:list.effective_month, no_days:list.no_days, time_cycle:list.time_cycle}
                                    })}
                                    
                     onChange={handleSelectLeave} 
                     className={errors.leave_type ? 'form-control form-control-danger' : ''} name="leave_type"
                      value={item.leave_type}
    
                      styles={group_styles} 
    />       

<div className="input-group-append  " onClick={()=>window.location.href='/leave/leave_type'}>
                    <span className="input-group-text "><a href='#' data-tip="Add Department" title="Add Department"  ><i className="fa  fa-plus-circle text-white" id="addIcon"></i></a></span>
                  </div>

            	</div>
                    
                    {errors.leave_type&&
    <div className="col-form-label has-danger">{errors.leave_type&&errors.leave_type} </div>}
                    </div>
    
                     </div>

                     </div>    

{item.leave_type.length!==0?
                     <div className="row">
                      <div className="col-md-12">
<div className="alert alert-info background-info"> Leave Balance : <b>{history.balance} Days</b></div>
</div>
</div>:[]}


<div className="row">
             <div className="col-md-4">
             <div className="form-group">
            <label className="col-form-label">Start Date <span className='text-danger'>*</span></label>
           
            <DatePicker 
            selected={item.start_date}      
             isClearable
             dateFormat="yyyy-MM-dd"
             className={errors.start_date?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}
             placeholderText="Select date"
             withPortal
 onChange={(date:any) => handleChangeDate(date, 'start_date')} />


          

            {errors.start_date&&
    <div className="col-form-label has-danger">{errors.start_date&&errors.start_date} </div>}
             </div>

             </div>


             <div className="col-md-4">
             <div className="form-group">
            <label className="col-form-label">End Date <span className='text-danger'>*</span></label>
           
            <DatePicker 
            selected={item.end_date}      
             isClearable
             dateFormat="yyyy-MM-dd"
             className={errors.end_date?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}
             placeholderText="Select date"
             withPortal
 onChange={(date:any) => handleChangeDate(date, 'end_date')} />
          

            {errors.end_date&&
    <div className="col-form-label has-danger">{errors.end_date&&errors.end_date} </div>}
             </div>

             </div>



             <div className="col-md-4">
             <div className="form-group">
            <label className="col-form-label">Estimated Days <span className='text-danger'>*</span> (Exc. Holidays {'=>'}{history.holidays})</label>
           
            <input type="number" disabled className={errors.no_days?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='no_days' value={history.no_days} onChange={handleChange}  />
          

            {errors.no_days&&
    <div className="col-form-label has-danger">{errors.no_days&&errors.no_days} </div>}
             </div>

             </div>



             <div className="col-md-12">
             <div className="form-group">
            <label className="col-form-label">Reason <span className='text-danger'>*</span></label>
           
            <textarea rows={10} cols={10} className={errors.reason?"form-control form-control-lgform-control-danger":"form-control form-control-lg"}  name='reason' onChange={handleChangeArea} value={item.reason}  >{item.reason}</textarea>
          

            {errors.reason&&
    <div className="col-form-label has-danger">{errors.reason&&errors.reason} </div>}
             </div>

             </div>

                    </div>


<hr />
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-outline-danger btn-round pr-5 pl-5 mr-3" type='button' onClick={handleReset}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={handleSubmit}>Apply</button>
</div>
</div>
    
            </div>
        </div>
    </div>
    </div>
    

    
                </div>

        </div>
                       
                    </section>
                </div>
            </div>
        </div>
    </div>
                                    </div>
                                </div>
                            </div>





                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default LeaveApplication 