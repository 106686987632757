
import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo} from 'react'
import { COMPANY_CODE, STAFF_CODE, ServerUrl, config, selectStyles, styles } from '../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal, CustomLoader, Alerts, closeModal } from '../../components/notify';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'

import Breadcrumb from '../../components/Breadcrumb'
import Select, { components } from 'react-select'
import { Link } from 'react-router-dom';

const  LeaveType =()=> {
    

    const [item, setItem] = useState({
        leave_name:'',
        time_cycle:{value:'', label:''},
        num_days:'',
        code:''
    });

    const [editItem, setEditItem] = useState({
        leave_name:'',
        time_cycle:'',
        num_days:'',
        code:'',
        id:''
    });


    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        leave_name:'',
        time_cycle:'',
        num_days:'',

        edit_leave_name:'',
        edit_time_cycle:'',
        edit_num_days:'',
       
     })
    

   
     const handleSelect = (option:any, action:any)=>{
        setItem({...item, [action.name]:option});
          setErrors({...errors, [action.name]:''})
           }


    const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;		
    setItem({...item, [name]:value})
   setErrors({...errors, [name]:''});
     }


const handleReset =()=>{
    setItem({
        leave_name:'',
        time_cycle:{value:'', label:''},
        num_days:'',
        code:''
    })
}


const TableData =()=>{


    const columns = [
        {
            name: 'Leave Name',
            selector: (row:any) => row.leave_name,
            sortable: true,
        },
        {
            name: 'No Days',
            selector: (row:any) => row.no_days,
            sortable: true,
        },
        {
            name: 'Time Cycle',
            selector: (row:any) => row.time_cycle,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row:any, index:any, column:any, id:any) => <div className='row'>  
                <label className="label label-info pointer" onClick={()=>EditItem(row)} ><i className='ti-pencil'></i> Edit</label>
            <label className="label label-danger pointer" onClick={()=>DeleteModal(row.id)}><i className='ti-trash'></i> Delete</label></div>,
                left:true
                      

        },
    ];
    
  

    return <DataTable
    columns={columns}
    data={content}
    dense
    highlightOnHover
    pagination
    selectableRowsHighlight
    striped
    progressPending={loading}
	progressComponent={<CustomLoader />}
/>

}



const handleChangeEdit =(event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	
    setEditItem({...editItem, [name]:value})
   setErrors({...errors, [name]:''});
 }


 const handleSelectEdit = (option:any, action:any)=>{
    setItem({...item, [action.name]:option.value});
      setErrors({...errors, [action.name]:''})
       }


const EditItem =(data:any)=>{
    setEditItem({
        leave_name:data.leave_name,
        time_cycle:data.time_cycle,
        num_days:data.no_days,
        code:data.code,
        id:data.id,
    }); 
    let click = document.getElementById('openEdit')?.click()

}



  const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!item.leave_name){
    error.leave_name =msg;
    formIsValid = false;
} 


if(!item.num_days){
    error.num_days=msg;
    formIsValid = false;
} 


if(!item.time_cycle.value){
    error.time_cycle =msg;
    formIsValid = false;
} 


content.map((data:any)=>{
    if(data.leave_name === item.leave_name){
        error.leave_name='The leave name already exist';
        formIsValid = false;
        }
  })

if(!formIsValid){
setErrors(error) 
}

if(formIsValid) {

    LoadingModal()
   
    const fd = new FormData();
    let code = 'LT'+Math.random().toString(36).substring(2, 9)
  
    fd.append('company_code', COMPANY_CODE);
    fd.append('code', code);


    fd.append('added_by', STAFF_CODE);
    fd.append('leave_name', item.leave_name);
    fd.append('no_days', item.num_days);
    fd.append('time_cycle', item.time_cycle.value);


let url = ServerUrl+'/api/leave/add_leave_type';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                Alerts('danger', response.data.message)
              }   
          })
          .catch((error)=>{
            Alerts('danger', error.message)
          }).finally(()=>{
        fetchData()
         handleReset()

          })
    }

}



const handleUpdate =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!editItem.leave_name){
    error.edit_leave_name =msg;
    formIsValid = false;
} 



if(!editItem.num_days){
    error.edit_num_days =msg;
    formIsValid = false;
} 


if(!editItem.time_cycle){
    error.edit_time_cycle =msg;
    formIsValid = false;
} 



if(!formIsValid){
setErrors(error) 
}


if(formIsValid){

     LoadingModal()
   
    const fd = new FormData();
    Object.entries(editItem).forEach(([key, value]) => {
        fd.append(key, String(value));
    });

let url = ServerUrl+'/api/leave/update_leave_type';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                Alerts('danger', response.data.message)
              }   
          })
          .catch((error)=>{
            Alerts('danger', error.message)
          }).finally(()=>{
         let click = document.getElementById('closeModal')?.click()
         fetchData()
        
          }) 
    }

}

const DeleteModal =(id:number)=>{
    let url = ServerUrl+'/api/leave/delete_leave_type/'+id;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =content.filter((item:{id:number})=>item.id !==id)
            setContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }

const fetchData = async()=>{
    setLoading(true)
    let url = ServerUrl+'/api/leave/view_leave_type/'+COMPANY_CODE
  await  axios.get(url, config).then((result:any)=>{
    if(Array.isArray(result.data.data) && result.data.data.length!==0){
      setContent(result.data.data)
      }
    })
    .catch((error)=>{
        Alerts('danger', error.message)
    }).finally(()=>{
        setLoading(false)
    })
    } 




    useEffect(()=>{
        fetchData()
      }, [])




    

  return ( <>
   <Breadcrumb 
   title="Leave Type"
   summary="Define leave type employee can apply for here"
   >
    <li className="breadcrumb-item">
    <Link to="#!">Configure</Link>
                    </li>
   <li className="breadcrumb-item">
    <Link to="#!">Leave Type</Link>
                    </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Leave Types</h5>
                    <span>Create different type of leave here</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                  
  
                                    
         <div className="content-wrappers mt-3">
                            
           
<label data-toggle="modal" data-target="#editItem" id='openEdit' ></label>

<div className="modal fade " id="editItem" tabIndex={-1} role="dialog" data-backdrop="static" >
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header bg-dark">
                    <h4 className="modal-title text-white">Update Leave Type </h4>
                  
    
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i className='fa fa-ban text-danger'></i>
            </button>
    
                </div>
                <div className="modal-body">
                
    
                <div className="row">
                  
                <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Leave Name</label>


                    <input type="text" className={errors.edit_leave_name?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='leave_name' value={editItem.leave_name} onChange={handleChangeEdit} />
                    
                    {errors.edit_leave_name&&
    <div className="col-form-label has-danger">{errors.edit_leave_name&&errors.edit_leave_name} </div>}
                    </div>
    
                     </div>


                     <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Number of Days</label>

                    <input type="number" className={errors.edit_num_days?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='num_days' value={editItem.num_days} onChange={handleChangeEdit} />

                    {errors.edit_num_days&&
    <div className="col-form-label has-danger">{errors.edit_num_days&&errors.edit_num_days} </div>}
                    </div>
    
                     </div>

                     <div className="col-md-6">
                                 <div className="form-group">
                                <label className="col-form-label">Time Cycle</label>
                               
                        <Select  options={[
                            { value: 'MONTHLY', label: 'MONTHLY' },
                            { value: 'YEARLY', label: 'YEARLY'},
                        ]} 
                        className={errors.edit_time_cycle?"form-control form-control-danger":""} 
                        onChange={handleSelectEdit}  name="time_cycle"
                value={{label:editItem.time_cycle, value:editItem.time_cycle}} 
                styles={styles} 
            />
                              
                                 </div>
                                 </div>
              </div> 
                </div>
                <div className="modal-footer">
                    <button type="button" id='closeModal' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-outline-success " onClick={handleUpdate} >Update</button>
                </div>
            </div>
        </div>
    </div>

    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Create Leave Type</h5>
                <span>Add all leave type</span>
              
            </div>
            <div className="card-block">
    
        
                        <div className="row">
                  
                        <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Leave Name</label>


                    <input type="text" className={errors.leave_name?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='leave_name' value={item.leave_name} onChange={handleChange} />
                    
                    {errors.leave_name&&
    <div className="col-form-label has-danger">{errors.leave_name&&errors.leave_name} </div>}
                    </div>
    
                     </div>


                    <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Number of Days</label>

                    <input type="number" className={errors.num_days?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='num_days' value={item.num_days} onChange={handleChange} />

                    {errors.num_days&&
    <div className="col-form-label has-danger">{errors.num_days&&errors.num_days} </div>}
                    </div>
    
                     </div>

                     <div className="col-md-6">
                                 <div className="form-group">
                                <label className="col-form-label">Time Cycle</label>
                               
                        <Select  options={[
                            { value: 'MONTHLY', label: 'MONTHLY' },
                            { value: 'YEARLY', label: 'YEARLY'},
                        ]} 
                        className={errors.time_cycle?"form-control form-control-danger":""} 
                        onChange={handleSelect}  name="time_cycle"
                value={item.time_cycle} 
                styles={styles} 
            />
                              
                                 </div>
                                 </div>
                    </div>


<hr/>
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-outline-danger btn-round pr-5 pl-5 mr-3" type='button' onClick={handleReset}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={handleSubmit}>Save</button>
</div>
</div>
    
            </div>
        </div>
    </div>
    </div>
    
    
    

    
    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>All Leave Type</h5>
                <span>List of all leave available for allocation</span>
              
            </div>
            <div className="card-block">
    
        <div className="table-responsive m-t-10">
        <TableData />
        </div>
    
            </div>
        </div>
    </div>
    </div>

        </div>
                        
                     
                    </section>
                </div>
            </div>
        </div>
    </div>
                                    </div>
                                </div>
                            </div>





                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default LeaveType