import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, STAFF_CODE, ServerUrl, config } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, Alerts, closeModal } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'

import { useQuery } from 'react-query'
import { fetchDepartment, fetchJobs, fetchSettings } from '../../api/ReactQuery';
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import { WORK_STATUS } from '../../components/Data';
const  BulkEmployee =()=> {
   

  const settings = useQuery(["settings"], fetchSettings, {
    staleTime: 10 * (60 * 1000), // 10 mins
  })
  const {data} = useQuery(["jobs"], fetchJobs)
  const [station, setStation] = useState([] as any);
  const department = useQuery(["department"], fetchDepartment)
  const Initials = 
  { 
    personal_email:'',
    first_name:'',
    job_title:'',
    department:'',
    last_name:'',
    employment_status:'',
    work_email:'',
    mobile_phone:'',
    gender:'Male',
    work_station:'',
    account_status:'Enabled',
    file_number:Math.floor(Math.random() * 100) + 1,
    update_log: JSON.stringify([{
      code:STAFF_CODE,
      action:'Created this account',
      date_time: new Date()
  }],null, 2),
  }

    const [errors, setErrors] = useState({
      first_name:'',
      last_name:'',
      errorMessage:''
       
     })
     const [profile, setProfile]= useState([] as any)
    

     const  GenerateRow =()=>{  
      let value:any = [];
      for (var i = 1; i <= 10; i++) {
        value.push({...Initials,  code:Math.random().toString(36).substr(2, 9)});
      }      
      setProfile(value)

  } 

  const createNewRow =()=>{
    setProfile(profile.concat({...Initials, code:Math.random().toString(36).substr(2, 9)}))
    setErrors({...errors,  errorMessage:''})

}

const removeRow=(index:number)=>{

  if(profile.length!==1){
    let item = profile.filter((_:any,idx:number)=>idx!==index)
    setProfile(item)
    }
   }


   const handleChange =(event:ChangeEvent<HTMLInputElement>, code:string)=>{
    const {name, value} = event.target;

    const newprofile = profile.map((data:any)=>{
        if(data.code === code){
            return {...data, 
                [name]:value
            }
            };
            return data;
    })
    setProfile(newprofile)
}

const handleChangeSelect =(event:ChangeEvent<HTMLSelectElement>, code:string)=>{
  const {name, value} = event.target;

  const newprofile = profile.map((data:any)=>{
      if(data.code === code){
          return {...data, 
              [name]:value
          }
          };
          return data;
  })
  setProfile(newprofile)
}

   


      const handleSubmit =(event: FormEvent<HTMLFormElement>)=>{
        event.preventDefault();
    
    
        LoadingModal()
       
        const fd = new FormData();
        fd.append('added_by', STAFF_CODE);
        fd.append('company_code', COMPANY_CODE);
        fd.append('profileList',  JSON.stringify(profile, null, 2))
    
    let url = ServerUrl+'/api/employee/bulk_registration';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.status === 200){
         SuccessModal(response.data.message)
       
                  } else{
                    Alerts('danger', response.data.message)
                    closeModal()
                  }   
              })
              .catch((error)=>{
                Alerts('danger', error.message)
              }).finally(()=>{
               GenerateRow()
    
              })
        
    
    }
    

    useEffect(()=>{

      try{
       if (settings!==undefined && settings.data.length!==0){
       setStation(JSON.parse(settings.data[0].contacts))
       }
   
      }catch(err) {
   
      }
       
       }, [settings.data]) 



    useEffect(()=>{
  
      GenerateRow()
      }, [])


    

  return ( <>
  <Breadcrumb 
   title="Bulk Registration"
   summary="You can create more Departments, and Job title under employee settings."
   >
   <li className="breadcrumb-item">
    <Link to="/employees/view">Employees</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Bulk Registration</a> </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Record Bulk Employee</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                    <ul>
        <li><i className="fa fa-minus minimize-card"></i></li>
        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">

          
<div className="well">You can use this page to add bulk staff. Please be careful that you select the right title, department and work group. You can enter as many rows as you like by clicking on Add more.</div>


            <div className="col-md-12">
                   
    
        
{profile.length!==0?

<div  className="card z-depth-0" >
		<div  className="card-block">
        <div className="row">
       
     
        <div className="table-responsive">
        <form action="#" onSubmit={handleSubmit}>
        <table className="table">
            <thead>
                <tr style={{background:'#404E67', color:'#fff'}}>
                <th>SNo</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Department</th>
                    <th>Job Title</th>  
                    <th>Status</th> 
                    <th>Station</th> 
                    <th>Email</th> 
                    <th>Phone</th> 
                    <th>Gender</th>
                    
    <th ><a onClick={createNewRow} href="#!" style={{color:'#fff'}}><i className="fa fa-plus"></i> Add More</a></th>
                </tr>
            </thead>
            <tbody>

            
                {profile&&profile.map((pl:any, id:number)=>
            <tr key={id}>
                
                    <td style={{backgroundColor:'#d2d6de'}}>{id+1}</td>


                    <td><input type="text"  required className="form-control" name='first_name' 
                    value={pl.first_name}  onChange={(e)=>handleChange(e, pl.code)} /></td>

<td><input type="text"  required className="form-control" name='last_name' 
                    value={pl.last_name}  onChange={(e)=>handleChange(e, pl.code)} /></td>


                   <td > 
                        <select onChange={(e)=>handleChangeSelect(e, pl.code)} required name="department"  value={pl.department} className="form-control">

                <option value="">Select</option>
               
{  department.data&&department.data.map((list:any, idx:number)=> {
           return  <option key={idx} value={list.code} >{list.department_name}</option> })}
                    </select>
                     </td>


                     <td > 
                        <select onChange={(e)=>handleChangeSelect(e, pl.code)} required name="job_title"  value={pl.job_title} className="form-control">

                <option value="">Select</option>
               
{  data&&data.map((list:any, idx:number)=> {
           return  <option key={idx} value={list.code} >{list.title}</option> })}
                    </select>
                     </td>


                     <td > 
                        <select onChange={(e)=>handleChangeSelect(e, pl.code)} required name="employment_status"  value={pl.employment_status} className="form-control">

                <option value="">Select</option>
               
{  WORK_STATUS.map((list:any, idx:number)=> {
           return  <option key={idx} value={list} >{list}</option> })}
                    </select>
                     </td>




                     <td > 
                        <select onChange={(e)=>handleChangeSelect(e, pl.code)} required name="work_station"  value={pl.work_station} className="form-control">

                <option value="">Select</option>
               
{  station&&station.map((list:any, idx:number)=> {
           return  <option key={idx} value={list.code} >{list.location.toUpperCase()}</option> })}
                    </select>
                     </td>


                  

                     <td><input type="text"  required className="form-control" name='work_email' 
                    value={pl.work_email}  onChange={(e)=>handleChange(e, pl.code)} /></td>



<td><input type="text"  required className="form-control" name='mobile_phone' 
                    value={pl.mobile_phone}  onChange={(e)=>handleChange(e, pl.code)} /></td>


<td > 

                        <select onChange={(e)=>handleChangeSelect(e, pl.code)} required name="gender"  value={pl.gender} className="form-control">

                <option value="Male">Male</option>
                <option value="Female">Female</option>
               
                    </select>
                     </td>
                   
                    <td > <button type="button" onClick={()=>removeRow(id)} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></button></td>
                </tr>)}
               
            </tbody> 
            <tfoot>
                <tr >
                    <td colSpan={9}>

                    
                <div className="pull-right">
	
	<button type="submit" id="submit"   className="btn btn-success"><i className="fa fa-save"></i> Submit </button>
								</div>
                                </td>
                </tr>
            </tfoot>
           
        </table>
        </form>
        </div>
       
        </div>
        </div>
</div>:''}
                       

    
    </div>
</div>
</div>
                            </div>
                        </div>
                    </div>
      </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default BulkEmployee