import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, CURRENCY, STAFF_CODE, ServerUrl, config, selectStyles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'

import Swal from 'sweetalert2'
import { FormatNumber, toWords } from '../../components/GlobalFunction';
import { Link } from 'react-router-dom';

const  AssignStructure =()=> {
   
    const [item, setItem] = useState({
       staff:[] as any,
       template:[] as any
    });


    const [salaryStructure, setSalaryStructure]=useState({
        template_name:'',
        id:0,
        structure:[] as any
    });

    const [title, setTitle] = useState([] as any);
const [salary, setSalary]=useState([]);
   

    const [template, setTemplate] = useState([] as any);
    const [staff, setStaff] = useState([] as any);
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
         staff:'',
         template:''
       
     })
    

     const fetchStaff =async()=>{
        let url = ServerUrl+'/api/employees/view/'+COMPANY_CODE
        const {data} = await axios.get(url, config)
        setStaff(data.data)
        }
        const fetchTemplate =async()=>{
            let url = ServerUrl+'/api/payroll/structure/view_structure/'+COMPANY_CODE
            const {data} = await axios.get(url, config)
            setTemplate(data.data)
            }

   
     const handleSelect = (option:any, action:any)=>{
        setItem({...item, [action.name]:option});
          setErrors({...errors, [action.name]:''})

          if(action.name==='template'){
            fetchTemplateTitle(option.value)            
        }
        if(action.name==='staff'){          
        fetchSalaryStructure(option.value);
        }
           }

         
           const handleChange = (e:ChangeEvent<HTMLInputElement>, code:string)=>{
            let  value = e.target.value.replace(/[^0-9.]/g, '');
            let newSalary:any = salary.map((item:any)=>{
                if(item.code === code){
                    return {...item, 
                        amount:value,
                    }
                    };
                    return item;
              })
              setSalary(newSalary); 
        }

        
const getTitle = (code:string, field:string) =>{ 
    const result = title.filter((list:any)=>list.code===code);
   const answer = result.map((c:any)=>c[field] ); 
     return  String(answer);
   }

           const  fetchTemplateTitle =(code:string)=>{  
            
            try{
            const result = template.filter((list:any)=>list.code===code);
           const answer = result[0].template 
           const template_code = result[0].code
        
           let value:any = []
           JSON.parse(answer).map((itm:any, idx:number)=>{
               value.push(
                
                {
                    code:itm.title_code,  
                    title:getTitle(itm.title_code, 'title'), 
                    amount:0,
                    template_code:template_code,
                    item_code:getTitle(itm.title_code, 'item_code'), 
                    item_type:getTitle(itm.title_code, 'item_type'),
                });
           } );
           setSalary(value)
        }catch(e){
                
        }
         } 


         const getTemplateName =(code:string)=>{
            const result = template.filter((list:any)=>list.code===code);
          
            const answer = result.map((c:any)=>c.level ); 
              return  String(answer);
         }


         const  fetchSalaryStructure =async(staff:string)=>{    
       
            LoadingModal()
            let url = ServerUrl+'/api/payroll/assign_structure/get_structure/'+staff
          await  axios.get(url, config).then((result:any)=>{
            if(Array.isArray(result.data.data) && result.data.data.length!==0){
              
                try{
                    let structure = JSON.parse(result.data.data[0].salary_structure)
                    setSalaryStructure({
                        structure:structure,
                        template_name:getTemplateName(result.data.data[0].template_code),
                         id:result.data.data[0].id
                    })
                }catch(e){

                }
             
              }else{
                setSalaryStructure({
                    structure:[],
                    template_name:'', 
                    id:0
                })
              }
            })
            .catch((error)=>{
                Alerts('danger', error.message)
            }).finally(()=>{
                closeModal()
            }) 
        } 




const handleReset =()=>{
    setItem({
        staff:[] as any,
        template:[] as any
    })
    setSalary([])
    setSalaryStructure({
        template_name:'',
        id:0,
        structure:[] as any
    })
}




  const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(item.staff.length===0){
    error.staff =msg;
    formIsValid = false;
} 


if(item.template.length===0){
    error.template =msg;
    formIsValid = false;
} 
 

if(!formIsValid){
setErrors(error) 
}

if(formIsValid) {

    LoadingModal()
   
    const fd = new FormData();
    let code = 'AS'+Math.random().toString(36).substring(2, 9)
  
    fd.append('company_code', COMPANY_CODE);
    fd.append('code', code);
    fd.append('added_by', STAFF_CODE);
    fd.append('staff', item.staff.value);
    fd.append('template_code', item.template.value);
    fd.append('salary_structure', JSON.stringify(salary, null, 2)); 

let url = ServerUrl+'/api/payroll/structure/assign_structure';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                Alerts('danger', response.data.message)
              }   
          })
          .catch((error)=>{
            Alerts('danger', error.message)
          }).finally(()=>{
         handleReset()

          })
    }

}



const DeleteModal =(id:number)=>{
    let url = ServerUrl+'/api/payroll/assign_structure/delete_structure/'+id;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =content.filter((item:{id:number})=>item.id !==id)
            setContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }

const fetchData = async()=>{
    setLoading(true)
    let url = ServerUrl+'/api/payroll/structure/view_title/'+COMPANY_CODE
  await  axios.get(url, config).then((result:any)=>{
    if(Array.isArray(result.data.data) && result.data.data.length!==0){
      setContent(result.data.data)
      }
    })
    .catch((error)=>{
        Alerts('danger', error.message)
    }).finally(()=>{
        setLoading(false)
    })
    } 


    const fetchTitle = async()=>{
        let url = ServerUrl+'/api/payroll/structure/view_title/'+COMPANY_CODE
      await  axios.get(url, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){
          setTitle(result.data.data)
          }
        })
        .catch((error)=>{
            Alerts('danger', error.message)
        })
        } 


        const getTotalDeduction =()=>{
            var amount =0
            salaryStructure.structure.map((sl:any)=>{
                if(sl.item_type==='DEDUCTION'){
                    amount += Number(sl.amount)
                }
            } )
            return amount
        }

           const getTotalAmount =()=>{
            var amount =0
            salaryStructure.structure.map((sl:any)=>{
                if(sl.item_type==='EARNING'){
                    amount += Number(sl.amount)
                }
            } )
            return amount
        }

        const getTotalSalaryDeduction =()=>{
            var amount =0
            salary.map((sl:any)=>{
                if(sl.item_type==='DEDUCTION'){
                    amount += Number(sl.amount)
                }
            } )
            return amount
        }

           const getTotalSalaryAmount =()=>{
            var amount =0
            salary.map((sl:any)=>{
                if(sl.item_type==='EARNING'){
                    amount += Number(sl.amount)
                }
            } )
            return amount
        }

    useEffect(()=>{
        fetchData()
        fetchTitle()
        fetchTemplate()
        fetchStaff() 
      }, [])


    

  return ( <>
   <Breadcrumb 
   title="Assign Salary Structure"
   summary="Assign salary template to each employee "
   >
    <li className="breadcrumb-item">
    <Link to="/payroll/staff_salary">Payroll</Link>
                    </li>
   <li className="breadcrumb-item">
    <Link to="#!">Assign Salary Structure</Link>
                    </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Assign Salary Structure</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                              
                                    
                                    
         <div className="content-wrappers ">
                            
         <div className=''>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Staff Salary</h5>
                <span>Add all offcie department</span>
              
            </div>
            <div className="card-block">
    
        
                        <div className="row">
                  
                        <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Select Staff</label>


                 
                <Select 
                options={
                               staff&&staff.map((list:any, idx:number)=> {
                                return {key:idx, value: list.code, label: list.first_name, last_name:list.last_name, middle_name:list.middle_name, title:list.title }
                                })}
                                className={errors.staff?"form-control form-control-danger":""} 
                     getOptionLabel={option =>`${option.title} ${option.label} ${option.last_name}`}
    onChange={handleSelect}  name="staff" value={item.staff} 
   
    /> 
                    
                    {errors.staff&&
    <div className="col-form-label has-danger">{errors.staff&&errors.staff} </div>}
                    </div>
    
                     </div>


                    <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Select Template</label>

                    <Select  options={
                                template&&template.map((list:any, idx:number)=> {
                                    return {key:idx, value: list.code, label: list.level}
                                    })}
                                    
                     onChange={handleSelect} 
                     className={errors.template ? 'form-control form-control-danger' : ''} name="template"
                      value={item.template}
    
    styles={selectStyles} 
    />        
                

                    {errors.template&&
    <div className="col-form-label has-danger">{errors.template&&errors.template} </div>}
                    </div>
    
                     </div>

                    </div>


                <div style={{display:salary.length===0?'none':'block'}}className="row">
               
                    <div className="col-md-10">
                        <div className="table-responsive">
                            <table className="table m-0">
                                <tbody>

                                
     {salary&&salary.map((tit:any, id:number)=>

            <tr key={id}>
                <th scope="row" style={{color:tit.item_type==='EARNING'?'green':'red'}}>{'['+tit.item_code +'] '+ tit.title}</th>
                <td> 
                <div className="input-group">
        <span className="input-group-prepend">
            <label className="input-group-text">{CURRENCY}</label>
        </span>
        <input type="text"
        pattern='[0-9]'
        className="form-control form-control-bold" placeholder='Amount' min="0" name='amount' value={tit.amount} onFocus={(e)=>e.target.select()}  onChange={(e)=>handleChange(e, tit.code)} />
                                    </div>
            </td>
            </tr>)}
            <tr className='text-primary'>
        <th>Total Net Pay</th>
        <th>{FormatNumber(getTotalSalaryAmount()-getTotalSalaryDeduction()) + ' (' + toWords((getTotalSalaryAmount()-getTotalSalaryDeduction()).toFixed(0)) + ')'}</th>

       </tr>
                </tbody>
                </table>
            </div>
        </div>
                 
                   
</div>

<hr />
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-outline-danger btn-round pr-5 pl-5 mr-3" type='button' onClick={handleReset}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={handleSubmit}>Save</button>
</div>
</div>
    
            </div>
        </div>
    </div>
    </div>
    
    
    

    
    <div className="row" style={{display:salaryStructure.structure.length===0?'none':'block'}}>
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>All Structure</h5>
                <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
              
            </div>
            <div className="card-block">
    
        <div className="table-responsive m-t-10">
    

        <div style={{display:salaryStructure.structure.length===0?'none':'block'}} className="row">
               
               <div className="col-md-12">
                   <div className="table-responsive">

                   <table className="table m-0">
                        <thead>
                            <tr>
                            <th>Salary Template </th>
                            <th>Assigned Salary</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                           <tbody>
<tr>
<td>{salaryStructure.template_name}</td>
<td>


<table className="table table-sm">
                        <thead>
                        <tr>
                            <th>Title</th>
                            <th>Amount ({CURRENCY})</th>
                            </tr>
                        </thead>
                           <tbody>

                           
{salaryStructure&&salaryStructure.structure.map((tit:any, id:number)=>

       <tr key={id}>
           <th scope="row" style={{color:tit.item_type==='EARNING'?'green':'red'}}>{'['+tit.item_code +'] '+ tit.title}</th>
           <td> {FormatNumber(tit.amount)}
       </td>
       </tr>)}

       <tr>
        <th>Total Net Pay</th>
        <th>{FormatNumber(getTotalAmount()-getTotalDeduction())}</th>

       </tr>
           </tbody>
           </table>
</td>
<td> <button type='button' className='btn btn-danger btn-sm' onClick={()=>DeleteModal(salaryStructure.id)}> <i className='fa fa-trash'></i></button>


</td>
</tr>
</tbody>
</table>


                      
       </div>
   </div>
            
              
</div>

        </div>
    
            </div>
        </div>
    </div>
    </div>
    
                </div>

        </div>
                       
                    </section>
                </div>
            </div>
        </div>
    </div>
                                    </div>
                                </div>
                            </div>





                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default AssignStructure