import React, {useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from './modules/Dashboard';
import Profile from './modules/employees/Profile';
import Employees from './modules/employees/Employees';
import AddEmployee from './modules/employees/AddEmployee';
import Directory from './modules/employees/Directory';
import Settings from './modules/employees/Settings';
import CreateTemplate from './modules/employees/CreateTemplate';
import ViewReportTemplate from './modules/employees/ViewReportTemplate';
import EditReportTemplate from './modules/employees/EditReportTemplate';
import PreviewEmployee from './modules/employees/PreviewEmployee';
import Structure from './modules/Payroll/Structure';
import AssignStructure from './modules/Payroll/Assign_structure';
import SalaryPayment from './modules/Payroll/Salary_Payment';
import StaffSalary from './modules/Payroll/Staff_Salary';
import PaymentStructure from './modules/Payroll/Payment_Structure';
import ModifyPayment from './modules/Payroll/Modify_Payment';
import PaySlip from './modules/Payroll/Pay_Slip';
import LeaveType from './modules/Leave/Leave_Type';
import AssignLeave from './modules/Leave/Assign_Leave';
import LeaveApplication from './modules/Leave/Leave_Application';
import Holidays from './modules/Leave/Holidays';
import EventCalendar from './modules/Leave/Event_Calendar';
import LeaveList from './modules/Leave/Leave_list';
import CreateLeaveReport from './modules/Leave/CreateTemplate';
import ViewLeaveTemplate from './modules/Leave/ViewLeaveTemplate';
import EditTemplate from './modules/Leave/EditTemplate';
import PreviewLeave from './modules/Leave/PreviewLeave';
import UsageReport from './modules/Leave/Usage_Report';
import StaffLeave from './modules/Leave/Staff_Leave';
import AttendanceSettings from './modules/Attendance/Settings';
import MarkAttendance from './modules/Attendance/Mark_Attendnace';
import UploadAttendance from './modules/Attendance/Upload_Attendance';
import DisplayAttendance from './modules/Attendance/Display_Attendance';
import DeleteAttendance from './modules/Attendance/Delete_Attendance';
import WorkingHours from './modules/Attendance/Working_Hours';
import AttendanceSummary from './modules/Attendance/Attendance_Summary';
import AttendanceLog from './modules/Attendance/Attendance_Log';
import PerformanceSettings from './modules/Performance/Settings';
import Appraisal from './modules/Performance/Appraisal';
import Performance from './modules/Performance/Performance';
import EditAppraisal from './modules/Performance/Edit_Appraisal';
import PreviewAppraisal from './modules/Performance/PreviewApraisal';
import Disciplenary from './modules/Performance/Disciplinary';
import FinanceSettings from './modules/Finance/Settings';
import RecordIncome from './modules/Finance/Income';
import BulkIncome from './modules/Finance/Bulk_Income';
import UploadIncome from './modules/Finance/Upload_Income';
import RecordExpenses from './modules/Finance/Expenses';
import BulkExpenses from './modules/Finance/Bulk_Expenses';
import UploadExpenses from './modules/Finance/Upload_Expenses';
import DailyRegister from './modules/Finance/Daily_Register';
import AccountStatement from './modules/Finance/Account_Statement';
import AssetRegistration from './modules/Assets/Registration';
import BulkAssets from './modules/Assets/Bulk_Assets';
import UploadAssets from './modules/Assets/Upload_Assets';
import DisplayAssets from './modules/Assets/Display_assets';
import BasicDetails from './modules/Setup/Basic_Details';
import Navigation from './modules/Setup/Navigation';
import AssetUpdate from './modules/Assets/Update_asset';
import Allocation from './modules/Assets/Allocation';
import RepairHistory from './modules/Assets/Repair_History';
import Financial from './modules/Assets/Financial';
import Waranty from './modules/Assets/Waranty';
import AccessGroup from './modules/Setup/Access_Group';
import AccessRoles from './modules/Setup/Access_Roles';
import { Token } from './components/Includes';
import Error404 from './modules/maintenance/Error404';
import Clients from './modules/Project/Clients';
import ProjectRegistration from './modules/Project/Registration';
import ProjectUpdate from './modules/Project/Update_Project';
import ProjectReport from './modules/Project/Project_Report';
import ProjectDetails from './modules/Project/Project_Details';
import EmployeeDashboard from './modules/employees/Dashboard';
import MyProfile from './modules/employees/My_Profile';
import EditProfile from './modules/employees/Edit_Profile';
import MyLeave from './modules/Leave/MyLeave';
import LeaveDashboard from './modules/Leave/Dashboard';
import PayrollDashboard from './modules/Payroll/Dashboard';
import AttendanceDashboard from './modules/Attendance/Dashboard';
import AssetsDashboard from './modules/Assets/Dashboard';
import PerformanceDashboard from './modules/Performance/Dashboard';
import BulkEmployee from './modules/employees/Bulk_Employee';
import UploadEmployee from './modules/employees/UploadData';
import FinanceDashboard from './modules/Finance/Dashboard';
import ProjectDashboard from './modules/Project/Dashboard';
import MessageTemplate from './modules/Setup/MessageTemplate';
import EditMessageTemplate from './modules/Setup/EditMessageTemplate';
import NewMessageTemplate from './modules/Setup/NewMessageTemplate';
import EmailSetup from './modules/Setup/EmailSetup';
import SMSSetup from './modules/Setup/SmsSetup';
import BasicDocument from './modules/employees/ReactPDF';

const ProtectedView =()=>{


    return ( <>
       
        <Routes>

        <Route  path="/dashboard" element={<Dashboard /> } /> 
        <Route  path="/employee/view_profile" element={<MyProfile/>} />
        <Route  path="/employee/update_profile" element={<EditProfile />} /> 
        <Route  path="/employee/profile/:code" element={<Profile />} /> 
        <Route  path="/employees/view" element={<Employees />} /> 
        <Route  path="/employees/add" element={<AddEmployee />} /> 
        <Route  path="/employees/directory" element={<Directory />} /> 
        <Route  path="/employees/uploadData" element={<UploadEmployee />} /> 
        <Route  path="/employees/settings" element={<Settings />} /> 
        <Route  path="/employees/dashboard" element={<EmployeeDashboard/>} /> 
        <Route  path="/employees/report/template/edit/:code" element={<EditReportTemplate />} /> 
        <Route  path="/employees/report/template/add" element={<CreateTemplate />} /> 
        <Route  path="/employees/report/view" element={<ViewReportTemplate />} /> 
        <Route  path="/employees/report/preview/:code" element={<PreviewEmployee />} /> 
        <Route  path="/employees/registration/bulk" element={<BulkEmployee/>} /> 
       
        <Route  path="/payroll/salary_structure" element={<Structure />} /> 
        <Route  path="/payroll/assign_structure" element={<AssignStructure />} /> 
        <Route  path="/payroll/salary_payment" element={<SalaryPayment />} /> 
        <Route  path="/payroll/staff_salary" element={<StaffSalary />} /> 
        <Route  path="/payroll/payment_structure" element={<PaymentStructure />} /> 
        <Route  path="/payroll/modify_salary_payment" element={<ModifyPayment />} /> 
        <Route  path="/payroll/pay_slip" element={<PaySlip />} /> 
        <Route  path="/payroll/dashboard" element={<PayrollDashboard />} /> 

        <Route  path="/leave/leave_type" element={<LeaveType />} /> 
        <Route  path="/leave/assign_leave" element={<AssignLeave />} /> 
        <Route  path="/leave/leave_application" element={<LeaveApplication />} /> 
        <Route  path="/leave/create_holidays" element={<Holidays />} /> 
        <Route  path="/leave/event_calendar" element={<EventCalendar />} /> 
        <Route  path="/leave/leave_list" element={<LeaveList />} /> 
        <Route  path="/leave/report/create" element={<CreateLeaveReport />} /> 
        <Route  path="/leave/report/view" element={<ViewLeaveTemplate />} /> 
        <Route  path="/leave/report/template/edit/:code" element={<EditTemplate />} /> 
        <Route  path="/leave/report/preview/:code" element={<PreviewLeave />} /> 
        <Route  path="/leave/staff_leave" element={<StaffLeave />} /> 
        <Route  path="/leave/usage_report" element={<UsageReport />} /> 
        <Route  path="/leave/my_leave" element={<MyLeave />} /> 
        <Route  path="/leave/dashboard" element={<LeaveDashboard/>} /> 

        <Route  path="/attendance/settings" element={<AttendanceSettings />} /> 
        <Route  path="/attendance/mark_employee_attendance" element={<MarkAttendance />} /> 
        <Route  path="/attendance/upload_attendance" element={<UploadAttendance />} /> 
        <Route  path="/attendance/display_attendance" element={<DisplayAttendance />} /> 
        <Route  path="/attendance/delete_attendance" element={<DeleteAttendance />} /> 
        <Route  path="/attendance/employee_working_hours" element={<WorkingHours />} /> 
        <Route  path="/attendance/attendance_summary" element={<AttendanceSummary />} /> 
        <Route  path="/attendance/attendance_log" element={<AttendanceLog />} /> 
        <Route  path="/attendance/dashboard" element={<AttendanceDashboard />} /> 

        <Route  path="/performance/settings" element={<PerformanceSettings />} /> 
        <Route  path="/performance/appraisal" element={<Appraisal />} /> 
        <Route  path="/performance/manage_appraisal" element={<Performance />} /> 
        <Route  path="/performance/edit_appraisal/:code" element={<EditAppraisal />} /> 
        <Route  path="/performance/preview_appraisal/:code" element={<PreviewAppraisal />} /> 
        <Route  path="/performance/review" element={<Disciplenary />} /> 
        <Route  path="/performance/dashboard" element={<PerformanceDashboard />} /> 


        <Route  path="/finance/settings" element={<FinanceSettings />} /> 
        <Route  path="/finance/record_income" element={<RecordIncome />} /> 
        <Route  path="/finance/bulk_income" element={<BulkIncome />} /> 
        <Route  path="/finance/upload_income" element={<UploadIncome />} /> 
        <Route  path="/finance/record_expenses" element={<RecordExpenses />} /> 
        <Route  path="/finance/bulk_expenses" element={<BulkExpenses />} /> 
        <Route  path="/finance/upload_expenses" element={<UploadExpenses />} /> 
        <Route  path="/finance/display_register" element={<DailyRegister />} /> 
        <Route  path="/finance/account_statement" element={<AccountStatement />} /> 
        <Route  path="/finance/dashboard" element={<FinanceDashboard />} /> 
        
        <Route  path="/assets/registration" element={<AssetRegistration />} /> 
        <Route  path="/assets/bulk_registration" element={<BulkAssets />} /> 
        <Route  path="/assets/upload_assets" element={<UploadAssets />} /> 
        <Route  path="/assets/display_assets" element={<DisplayAssets />} /> 
        <Route  path="/assets/update/:code" element={<AssetUpdate />} />
        <Route  path="/assets/allocation_history" element={<Allocation />} /> 
        <Route  path="/assets/repair_history" element={<RepairHistory />} /> 
        <Route  path="/assets/financial" element={<Financial />} /> 
        <Route  path="/assets/warranty" element={<Waranty />} /> 
        <Route  path="/assets/dashboard" element={<AssetsDashboard />} />

        
        <Route  path="/setup/basic_details" element={<BasicDetails />} /> 
        <Route  path="/setup/navigation" element={<Navigation />} /> 
        <Route  path="/setup/access_group" element={<AccessGroup />} /> 
        <Route  path="/setup/access_roles" element={<AccessRoles />} /> 


        <Route  path="/setup/configure/message_template" element={<MessageTemplate />} /> 
        <Route  path="/setup/configure/message_template/edit/:code" element={<EditMessageTemplate />} /> 
        <Route  path="/setup/configure/message_template/create" element={<NewMessageTemplate />} /> 
        <Route  path="/setup/configure/email_setup" element={<EmailSetup/>} /> 
        <Route  path="/setup/configure/sms_setup" element={<SMSSetup/>} /> 



        <Route  path="/project/clients" element={<Clients />} /> 
        <Route  path="/project/new_project" element={<ProjectRegistration />} /> 
        <Route  path="/project/update/:code" element={<ProjectUpdate />} /> 
        <Route  path="/project/reports" element={<ProjectReport />} />
        <Route  path="/project/details/:code" element={<ProjectDetails />} />
        <Route  path="/project/dashboard" element={<ProjectDashboard />} />


   

        <Route  path="*" element={<Error404 />} /> 

        </Routes>
       
    </>)
}

export default ProtectedView