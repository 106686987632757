import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { ImagesUrl, STAFF_CODE, ServerUrl, config } from '../../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal } from '../../../components/notify';
import { fetchEmployee } from '../../../api/ReactQuery';
import { useQuery } from 'react-query'


const Setup =({code}:{code:any})=> {
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState([] as any);
    const [disabled, setDisabled] = useState(false);
    const {data} = useQuery(["employee", code], fetchEmployee)

    const [profile, setProfile] = useState({
        passport:'',
        passport_url:'',
        signature:'',
        signature_url:'',
        display_passport:'',
        display_signature:'',
        password:'',
        confirm_password:''
    });

    const [errors, setErrors] = useState({
        passport:'',
        signature:'',
        password:'',
        confirm_password:'',
        error_message:''

    })

    const  handleFile = function(fieldName:any){
        return function(newValue:any){                 
       readURL(newValue);
       }
      } 
    
     
      const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	
        setProfile({...profile, [name]:value});
       setErrors({...errors, [name]:''});
     }


    
    const readURL = (input:any)=>{
     
    let displayMessage = '';
    
    const PreviewImage = input.target.name;
    const inputElement = document.getElementById(PreviewImage) as HTMLInputElement
    const fileName = inputElement.value
    
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    
    if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 1) {
        displayMessage = ' File uploaded is larger than maximum size Allow (500 kb).';
        inputElement.value = '';
        if(PreviewImage==='signature'){
        setProfile({...profile, [PreviewImage]:'', display_signature:''})
        }else{
            setProfile({...profile, [PreviewImage]:'', display_passport:''}) 
        }
    
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
            var reader = new FileReader();//Initialize FileReader.
            reader.onload = function (e:any) {
                
            //Check if input has files.
            let Id = Math.random().toString(36).substr(2, 9)
             let fileUrl = Id+'.png'
    
              setErrors({...errors, [PreviewImage]:'', error_message:''}); 


              if(PreviewImage==='signature'){
                setProfile({...profile,  
                    signature:input.target.files[0],
                    signature_url:fileUrl,
                    display_signature:e.target.result
                
                })

            }else{
                setProfile({...profile,  
                    passport:input.target.files[0],
                    passport_url:fileUrl,
                    display_passport:e.target.result
                
                })
            }

    
            }
            reader.readAsDataURL(input.target.files[0]);
            
        }
    }
    }else{
    displayMessage = 'Only JPEG|PNG|JPG  file format are allowed with maximum of 500 kb'
    inputElement.value = '';
    if(PreviewImage==='signature'){
        setProfile({...profile, [PreviewImage]:'', display_signature:''})
        }else{
            setProfile({...profile, [PreviewImage]:'', display_passport:''}) 
        }
    }   
    
    if(displayMessage.length > 0){
    //ErrorModal("", displayMessage)
    setErrors({...errors, error_message:displayMessage})
    }
    }

    const handleSubmitPassport =(event: FormEvent<HTMLButtonElement>)=>{
        event.preventDefault();

        let error = {...errors}; 
        let formIsValid = true;
    
    let msg ='This field is required';
    
    if(!profile.passport){
        error.passport =msg;
        error.error_message = "Please select passport"
        formIsValid = false;
    } 


    if(!formIsValid){
        setErrors(error) 
        }
        
        
        if(formIsValid) {

            LoadingModal()
         const fd = new FormData();
        
        const log = JSON.parse(content[0].update_log)
        const item = log.concat( {
            code:STAFF_CODE,
            action:'Account Setup: Changed user passport',
            date_time: new Date()
        })

         const logs = JSON.stringify(item,null, 2)

         if(profile.passport){
            fd.append('imageFile', profile.passport, profile.passport_url); 
        } 


        fd.append('id', content[0].id);
        fd.append('code', code);
        fd.append('logs', logs);
        fd.append('passport_url', profile.passport_url);
        
           let url = ServerUrl+'/api/employee/change_passport/'+code;
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status === 200){
             SuccessModal(response.data.message)
           
                      } else{
                        ErrorModal('', response.data.message)
                      }   
                  })
                  .catch((error)=>{
                   ErrorModal('oops', error.message)
                  }) 
            }

    }


    const handleSubmitSignature =(event: FormEvent<HTMLButtonElement>)=>{
        event.preventDefault();

        let error = {...errors}; 
        let formIsValid = true;
    
    let msg ='This field is required';
    
    if(!profile.signature){
        error.signature =msg;
        error.error_message = "Please select signature"
        formIsValid = false;
    } 


    if(!formIsValid){
        setErrors(error) 
        }
        
        
        if(formIsValid) {

            LoadingModal()
            const fd = new FormData();
           
           const log = JSON.parse(content[0].update_log)
           const item = log.concat( {
               code:STAFF_CODE,
               action:'Account Setup: Changed user signature',
               date_time: new Date()
           })
   
            const logs = JSON.stringify(item,null, 2)
   
            if(profile.signature){
               fd.append('imageFile', profile.signature, profile.signature_url); 
           } 
   
   
           fd.append('id', content[0].id);
           fd.append('code', code);
           fd.append('logs', logs);
           fd.append('signature_url', profile.signature_url);
           
              let url = ServerUrl+'/api/employee/change_signature/'+code;
             axios.post(url, fd, config)
             .then(response =>{
               if(response.data.status === 200){
                SuccessModal(response.data.message)
              
                         } else{
                           ErrorModal('', response.data.message)
                         }   
                     })
                     .catch((error)=>{
                      ErrorModal('oops', error.message)
                     }) 

        }

    }


    const handleSubmitPassword =(event: FormEvent<HTMLButtonElement>)=>{
        event.preventDefault();

        let error = {...errors}; 
        let formIsValid = true;
    
    let msg ='This field is required';
    
    if(profile.password.length < 6){
        error.password ='Password not strong enough, minimum of 6 letters required';
        formIsValid = false;
    } 


    if(profile.confirm_password !== profile.password){
        error.confirm_password ='Password do not match';
        formIsValid = false;
    } 


    if(!profile.password){
        error.password =msg;
        formIsValid = false;
    } 


    if(!profile.confirm_password){
        error.confirm_password =msg;
        formIsValid = false;
    } 
    if(!formIsValid){
        setErrors(error) 
        }
        
        
        if(formIsValid) {

            handleSubmit()

        }

    }




const handleSubmit =()=>{
    

    LoadingModal()

    const log = JSON.parse(content[0].update_log)
        const item = log.concat( {
            code:STAFF_CODE,
            action:'Account Setup: Change user password',
            date_time: new Date()
        })

         const logs = JSON.stringify(item,null, 2)



 const fd = new FormData();
    
 fd.append('id', content[0].id);
 fd.append('code', code);
 fd.append('logs', logs);
 fd.append('password', profile.password);

let url = ServerUrl+'/api/employee/change_password';
  axios.post(url, fd, config)
  .then(response =>{
  
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                ErrorModal('', response.data.message)
              }   
          })
          .catch((error)=>{
           ErrorModal('oops', error.message)
          }).finally(()=>{
      setProfile({...profile, password:'', confirm_password:''})

          }) 
}




    const handlePhotSign=()=>{
        var doc = document.getElementById('signature')
        doc?.click()
    }
    const handlePhoto=()=>{
        var doc = document.getElementById('passport')
        doc?.click()
    }

    useEffect(()=>{

        try{
     
         if (data!==undefined && data.length!==0){
         setContent(data)
         window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
         }
     
        }catch(err) {
     
        }
         
         }, [data])

  return (
    
<div>
<div className="card z-depth-0">

<div className="card-header">
  <h5>Account Setup</h5>

</div>
{content.length!==0? content.map((list:any, index:number)=>  <div key={index} className="card-block">

{errors.error_message?
<div className="alert alert-danger background-danger">
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <i className="fa fa-close text-white"></i>
            </button>
            <span>{errors.error_message}</span>
        </div>:''}

<h6 className='mt-4 pointer' onClick={handlePhoto}>(Click to Change Profile Picture ) </h6>
                        <hr/>

       <div className="row">
       <div className="col-md-12 text-center">


          

<input id="passport"  onChange={handleFile('passport')} className="d-none" name="passport" type="file" accept="image/*" />              

 {profile.display_passport?
 <img id="viewPassport2" onClick={handlePhoto} 
 className='img-100  pointer img-radius' 
			onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+list.gender+'.png'}} 
            src={profile.display_passport} alt="preview" /> : 
            
            <img 
            src={ImagesUrl+'/employees/passport/'+list.passport}  alt="Click to change" 
            className='img-100  pointer img-radius' 
            onClick={handlePhoto} onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+list.gender+'.png'}} /> }  
 <br/>           

		    
       <label className="col-form-label text-danger">Accepts jpg, .png, .gif up to 500 kB. Recommended dimensions: 200px X 200px</label>


       </div>

       </div>
       <hr/>


<div className="col-md-12 text-right">
<button className="btn btn-success btn-round pl-5 pr-5 pt-2 pb-2" type='button' onClick={handleSubmitPassport}>Upload Image </button>
</div>

      





<h6 className='mt-4' onClick={handlePhotSign} >Change Signature</h6>
                        <hr/>

       <div className="row">
       <div className="col-md-12 text-center">


     

<input id="signature"  onChange={handleFile('signature')} className="d-none" name="signature" type="file" accept="image/*" />              

 {profile.display_signature?
 <img id="viewPassport2" 
 onClick={handlePhotSign} 
 className='img-fluid img-100 pointer ' 
			onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/no.jpeg'}} 
            src={profile.display_signature} alt="preview" /> : 
            
            <img 
            src={ImagesUrl+'/employees/signature/'+list.signature}  alt="Click to change" 
            className='img-fluid img-100 pointer ' 
            onClick={handlePhotSign} onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/no.jpeg'}} /> }

  <br/>           

		    
       <label className="col-form-label text-danger">Accepts jpg, .png, .gif up to 500 kb. Recommended dimensions: 100px X 100px</label>


       </div>

       </div>
       <hr/>


<div className="col-md-12 text-right">
<button className="btn btn-success btn-round pl-5 pr-5 pt-2 pb-2" type='button' onClick={handleSubmitSignature}>Save </button>
</div>






<h6 className='mt-4'>Change Password</h6>
                        <hr/>


       <div className="row">

       <div className="col-md-6">

       <div className="form-group">
        <label className="col-form-label">New Password <span className='text-danger'>*</span></label>
       
          

    <input type="password" className={errors.password?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} disabled={disabled} name='password' value={profile.password} onChange={handleChange} />

    {errors.password&&
    <div className="col-form-label has-danger">{errors.password&&errors.password} </div>}
      
         </div>


       </div>


       <div className="col-md-6">
       <div className="form-group">
      <label className="col-form-label">Repeat New Password</label>
     
         
    <input type="password" className={errors.confirm_password?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} disabled={disabled} name='confirm_password' value={profile.confirm_password} onChange={handleChange} />

{errors.confirm_password&&
<div className="col-form-label has-danger">{errors.confirm_password&&errors.confirm_password} </div>}
       </div>

       </div>

       <div className="col-md-12">
            <span > Please choose a difficult-to-guess combination of text containing upper- and lowercase letters, symbols, and digits for the password.</span>
         </div>

      </div><hr/>


      <div className="row">
<div className="col-md-12 text-right">
<button className="btn btn-success btn-round" type='button' onClick={handleSubmitPassword}>Change Password</button>
</div>
</div>



      </div>):[]}
          </div>

  </div>
  )
}

export default Setup