import { useEffect, useState} from 'react'
import { COMPANY_CODE, STAFF_CODE, ServerUrl, config } from '../../../components/Includes';
import axios from 'axios';
import { CustomLoader } from '../../../components/notify';
import { fetchEmployee } from '../../../api/ReactQuery';
import { useQuery } from 'react-query'
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { convertDateTime } from '../../../components/GlobalFunction';

const Logs =({code}:{code:any})=> {

    const [staff, setStaff] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState([] as any);
    const [contact, setContact] = useState([] as any);
    const {data} = useQuery(["employee", code], fetchEmployee)
   
   
const DeleteModal =()=>{
    
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading(),
    confirmButtonText: 'Yes, I am!',

    preConfirm: async() => {


const item = [{
    code:STAFF_CODE,
    action:'Log: Clear logs',
    date_time: new Date()
}]

const logs = JSON.stringify(item,null, 2)
const newProfile = content.map((item:any)=>{
    return {...item, 
        update_log:logs
    }
  })
  
  setContent(newProfile)
  
      const fd = new FormData();

fd.append('logs', logs);
Object.entries(newProfile[0]).forEach(([key, value]) => {
    fd.append(key, String(value));
}); 


let url = ServerUrl+'/api/employee/update/'+code;
return await axios.post(url, fd, config)
.then(response =>{

if(response.data.status !== 200){
    throw new Error(response.statusText)

      } else{
        return response.data
      }   
  })
  .catch((error)=>{
    Swal.showValidationMessage(
        `Request failed: ${error}`
      )
  })
    }
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 

                  window.location.reload()
      }
    })
  }




        const TableData =()=>{

        
            const columns = [
                {
                    name: 'Staff',
                    cell: (row:any) => row.staff, 
                    sortable: true,
                },
                {
                    name: 'Actions',
                    selector: (row:any) => row.action,
                    sortable: true,
                    wrap:true
                },
                {
                    name: 'Date',
                    selector: (row:any) => row.date_time,
                    sortable: true,
                }
            ];
            
        
          
        
            return <DataTable
            columns={columns}
            data={contact}
            dense
            highlightOnHover
            pagination
            selectableRowsHighlight
            striped
            progressPending={loading}
            progressComponent={<CustomLoader />}
        />
        
        }


    
const getTitleName = (code:string, field:string) =>{ 
    const result = staff&&staff.filter((list:any)=>list.code===code);
   const answer = result.length !==0?result[0][field]:''
     return  answer;
   }


const fetchStaff =async()=>{
    let url = ServerUrl+'/api/employees/view/'+COMPANY_CODE
    const {data} = await axios.get(url, config)
   
    setStaff(data.data)
    }

    
        useEffect(()=>{
            fetchStaff()
        }, [])



useEffect(()=>{

   try{

    if (data!==undefined && data.length!==0){
    setContent(data)

    let option = JSON.parse(data[0].update_log)

    let list = []

    for (var i =0; i < option.length; i++){
        list.push({
            staff: getTitleName(option[i].code, 'title') +' '+ getTitleName(option[i].code, 'first_name')+ ' '+getTitleName(option[i].code, 'last_name') + ' ' +getTitleName(option[i].code, 'middle_name'),

            code:option[i].code,
            date_time: convertDateTime(option[i].date_time),
            action:option[i].action

        })

    }
    setContact(list)
    window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
         
    }

   }catch(err) {

   }
    
    }, [data, staff])


  return (                             
    <div>
    
     
    <div className="card z-depth-0">
    
    <div className="card-header">
    <h5>Action Logs</h5>
    
    <div className="card-header-right">
    
<label className="label label-danger pointer " onClick={DeleteModal}> <i className='fa fa-trash text-white'></i> Clear Log </label> 
                   
    </div>
    
    </div>
    
    
    <div className="card-block">
    
    <div className="table-responsive m-t-10">
        <TableData />
   
    </div>
    
    </div>
    </div>
    </div>
                  
 
  )
}

export default Logs 