import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, ServerUrl, config } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, Alerts, SuccessModal, closeModal } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'
import { Link } from 'react-router-dom';



const  NewMessageTemplate =()=> {
  

    const [content, setContent] = useState({
            tokens:'',
            template_name:'',
            subject:'',
            html_body:'',
            bcc:'',
            email:'',
            status:'Yes',
            sms_sender:'',
            sms_body:''
    });

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        
        tokens:'',
        template_name:'',
        subject:'',
        html_body:'',
        bcc:'',
        email:'',
        sms_sender:'',
        sms_body:''
       
     })
    

       

        
const handleReset =()=>{
    setContent({
        tokens:'',
            template_name:'',
            subject:'',
            html_body:'',
            bcc:'',
            email:'',
            status:'Yes',
            sms_sender:'',
            sms_body:''
    })
}

            


                           
                       
    const handleChangeText =(event:ChangeEvent<HTMLTextAreaElement>)=>{
    let {name, value} = event.target;	
    
   
setContent({...content, [name]:value})
    setErrors({...errors, [name]:''});
    
    }

    


const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{


let {name, value} = event.target;	

if(name==='status'){
    value = value==='Yes'?'No':'Yes'
}

setContent({...content, [name]:value})
setErrors({...errors, [name]:''});

}
             

const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!content.tokens){
    error.tokens =msg;
    formIsValid = false;
} 

if(!content.subject){
    error.subject =msg;
    formIsValid = false;
} 

let email = content.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

if(!content.email){
    error.email =msg;
    formIsValid = false;
} 

if(!email){
    error.email ='Please enter valid email';
    formIsValid = false;
} 

if(!content.template_name){
    error.template_name =msg;
    formIsValid = false;
} 

if(!content.html_body){
    error.html_body =msg;
    formIsValid = false;
} 


if(!formIsValid){
setErrors(error) 
}

if(formIsValid) {

    LoadingModal()
   

    

    const fd = new FormData();
    let code = Math.random().toString(36).substring(2, 9)

    Object.entries(content).forEach(([key, value]) => {
      fd.append(key, String(value));
  });

    fd.append('company_code', COMPANY_CODE);
    fd.append('code', code);
    
    let url = ServerUrl+'/api/setup/message_template/create';
   
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
              Alerts('danger', response.data.message)
              closeModal()
              }   
          })
          .catch((error)=>{
            Alerts('danger', error.message)
            closeModal()
          }).finally(()=>{
        handleReset()

          })
    }
}


    useEffect(()=>{
        //fetchStaff()
       
      }, [])



  return ( <>
  <Breadcrumb 
   title="Message Template"
   summary="Create a new communication template"
   >
     <li className="breadcrumb-item">
    <Link to="/setup/configure/message_template">Template</Link>
                    </li>

   <li className="breadcrumb-item">
    <Link to="#!">Create</Link>
                    </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Email & SMS Template</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                              
                                    
                                    
         <div className="content-wrappers ">
                            
         <div className=''>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Email Template</h5>
                <span>Create new email template here</span>
              
            </div>
            <div className="card-block">

    

            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">Message Tokens <span className='text-danger'>*</span></label>
            <div className="col-sm-10">
                <textarea rows={4} className={errors.tokens?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  value={content.tokens} name="tokens" onChange={handleChangeText} >
                {content.tokens}
                    </textarea>


                {errors.tokens&&
    <div className="col-form-label has-danger">{errors.tokens&&errors.tokens} </div>}


            </div>  
            
            </div> 



            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">Template Name <span className='text-danger'>*</span></label>
            <div className="col-sm-10">
                <input type="text" className={errors.template_name?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  value={content.template_name} name="template_name" onChange={handleChange} />


                {errors.template_name&&
    <div className="col-form-label has-danger">{errors.template_name&&errors.template_name} </div>}


            </div>  
            
            </div> 
      
            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">Subject <span className='text-danger'>*</span></label>
            <div className="col-sm-10">
                <input type="text" className={errors.subject?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  value={content.subject} name="subject" onChange={handleChange} />


                {errors.subject&&
    <div className="col-form-label has-danger">{errors.subject&&errors.subject} </div>}


            </div>  
            
            </div> 



            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">Html Body <span className='text-danger'>*</span></label>
            <div className="col-sm-10">

                <textarea className={errors.html_body?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} rows={20} value={content.html_body} name="html_body" onChange={handleChangeText} > {content.html_body} </textarea>


                {errors.html_body&&
    <div className="col-form-label has-danger">{errors.html_body&&errors.html_body} </div>}


            </div>  
            
            </div> 



            <div className="form-group row">
                
            <label className="col-sm-2 col-form-label text-right">Preview <span className='text-danger'>*</span></label>
            <div className="col-sm-10">
<br/>

							<div  dangerouslySetInnerHTML={{__html:  content.html_body}} ></div>



            </div>  
            
            </div> 


            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">BCC </label>
            <div className="col-sm-10">
                <input type="text" className={errors.bcc?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  value={content.bcc} name="bcc" onChange={handleChange} />


                {errors.bcc&&
    <div className="col-form-label has-danger">{errors.bcc&&errors.bcc} </div>}


            </div>  
            
            </div> 

          
            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">Sender Email <span className='text-danger'>*</span></label>
            <div className="col-sm-10">
                <input type="text" className={errors.email?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  value={content.email} name="email" onChange={handleChange} />


                {errors.email&&
    <div className="col-form-label has-danger">{errors.email&&errors.email} </div>}


            </div>  
            
            </div> 


            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">Is Active</label>
            <div className="col-sm-10">
              
            <div className="checkbox-fade fade-in-primary ">
                                    <label className="check-task">
              <input type="checkbox" value={content.status} name="status" checked={content.status ==='Yes'?true:false}  onChange={handleChange} />
    <span className="cr">
    <i className="cr-icon fa fa-check txt-default"></i>
    </span> 
</label>
                                </div>
            </div>  
            
            </div> 
    
            </div>
        </div>
    </div>
    </div>
    
    







    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>SMS Template info</h5>
                <span>Add all offcie department</span>
              
            </div>
            <div className="card-block">

    

            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">Sender <span className='text-danger'>*</span></label>
            <div className="col-sm-10">
                <input type="text" className={errors.sms_sender?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  value={content.sms_sender} name="sms_sender" onChange={handleChange} />


                {errors.sms_sender&&
    <div className="col-form-label has-danger">{errors.sms_sender&&errors.sms_sender} </div>}


            </div>  
            
            </div> 



        
      


            <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">Content <span className='text-danger'>*</span></label>
            <div className="col-sm-10">

                <textarea className={errors.sms_body?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} rows={5} value={content.sms_body} name="sms_body" onChange={handleChangeText} > {content.sms_body} </textarea>


               <span style={{color:'green'}}>

{content.sms_body.length} Characters,  {(Math.ceil(Number(content.sms_body.length)/160))}  Pages, Remaining {(160 -(Number(content.sms_body.length)%160))}</span> 

                {errors.sms_body&&
    <div className="col-form-label has-danger">{errors.sms_body&&errors.sms_body} </div>}


            </div>  
            
            </div> 


<hr />
 <div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-outline-danger btn-round pr-5 pl-5 mr-3" type='button' onClick={handleReset}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={handleSubmit}>Create Template</button>
</div>
</div> 
    
            </div>
        </div>
    </div>
    </div>

    
                </div>

        </div>
                       
                    </section>
                </div>
            </div>
        </div>
    </div>
                                    </div>
                                </div>
                            </div>





                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default NewMessageTemplate