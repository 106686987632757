import React from 'react'

function Sidebar() {
  return (

    <>
    
    
    <div id="sidebar" className="users p-chat-user showChat ">
                    <div className="had-container">
                        <div className="card card_main p-fixed users-main">
                            <div className="user-box">
                                <div className="chat-search-box">
                                    <a className="back_friendlist">
                                        <i className="fa fa-chevron-left"></i>
                                    </a>
                                    <div className="right-icon-control">
                                        <form className="form-material">
                                            <div className="form-group form-primary">
                                                <input type="text" name="footer-email" className="form-control" id="search-friends"  />
                                                <span className="form-bar"></span>
                                                <label className="float-label"><i className="fa fa-search m-r-10"></i>Search Friend</label>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="main-friend-list">
                                    <div className="media userlist-box waves-effect waves-light" data-id="1" data-status="online" data-username="Josephin Doe" data-toggle="tooltip" data-placement="left" title="Josephin Doe">
                                        <a className="media-left" href="#!">
                                            <img className="media-object img-radius img-radius" src="../files/assets/images/avatar-3.jpg" alt="Generic placeholder image " />
                                            <div className="live-status bg-success"></div>
                                        </a>
                                        <div className="media-body">
                                            <div className="f-13 chat-header">Josephin Doe</div>
                                        </div>
                                    </div>
                                    <div className="media userlist-box waves-effect waves-light" data-id="2" data-status="online" data-username="Lary Doe" data-toggle="tooltip" data-placement="left" title="Lary Doe">
                                        <a className="media-left" href="#!">
                                            <img className="media-object img-radius" src="../files/assets/images/avatar-2.jpg" alt="Generic placeholder image" />
                                            <div className="live-status bg-success"></div>
                                        </a>
                                        <div className="media-body">
                                            <div className="f-13 chat-header">Lary Doe</div>
                                        </div>
                                    </div>
                                    <div className="media userlist-box waves-effect waves-light" data-id="3" data-status="online" data-username="Alice" data-toggle="tooltip" data-placement="left" title="Alice">
                                        <a className="media-left" href="#!">
                                            <img className="media-object img-radius" src="../files/assets/images/avatar-4.jpg" alt="Generic placeholder image" />
                                            <div className="live-status bg-success"></div>
                                        </a>
                                        <div className="media-body">
                                            <div className="f-13 chat-header">Alice</div>
                                        </div>
                                    </div>
                                    <div className="media userlist-box waves-effect waves-light" data-id="4" data-status="online" data-username="Alia" data-toggle="tooltip" data-placement="left" title="Alia">
                                        <a className="media-left" href="#!">
                                            <img className="media-object img-radius" src="../files/assets/images/avatar-3.jpg" alt="Generic placeholder image" />
                                            <div className="live-status bg-success"></div>
                                        </a>
                                        <div className="media-body">
                                            <div className="f-13 chat-header">Alia</div>
                                        </div>
                                    </div>
                                    <div className="media userlist-box waves-effect waves-light" data-id="5" data-status="online" data-username="Suzen" data-toggle="tooltip" data-placement="left" title="Suzen">
                                        <a className="media-left" href="#!">
                                            <img className="media-object img-radius" src="../files/assets/images/avatar-2.jpg" alt="Generic placeholder image" />
                                            <div className="live-status bg-success"></div>
                                        </a>
                                        <div className="media-body">
                                            <div className="f-13 chat-header">Suzen</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="showChat_inner">
                    <div className="media chat-inner-header">
                        <a className="back_chatBox">
                            <i className="fa fa-chevron-left"></i> Josephin Doe
                        </a>
                    </div>
                    <div className="media chat-messages">
                        <a className="media-left photo-table" href="#!">
                            <img className="media-object img-radius img-radius m-t-5" src="../files/assets/images/avatar-3.jpg" alt="Generic placeholder image" />
                        </a>
                        <div className="media-body chat-menu-content">
                            <div className="">
                                <p className="chat-cont">I'm just looking around. Will you tell me something about yourself?</p>
                                <p className="chat-time">8:20 a.m.</p>
                            </div>
                        </div>
                    </div>
                    <div className="media chat-messages">
                        <div className="media-body chat-menu-reply">
                            <div className="">
                                <p className="chat-cont">I'm just looking around. Will you tell me something about yourself?</p>
                                <p className="chat-time">8:20 a.m.</p>
                            </div>
                        </div>
                        <div className="media-right photo-table">
                            <a href="#!">
                                <img className="media-object img-radius img-radius m-t-5" src="../files/assets/images/avatar-4.jpg" alt="Generic placeholder image" />
                            </a>
                        </div>
                    </div>
                    <div className="chat-reply-box">
                        <div className="right-icon-control">
                            <form className="form-material">
                                <div className="form-group form-primary">
                                    <input type="text" name="footer-email" className="form-control" />
                                    <span className="form-bar"></span>
                                    <label className="float-label"><i className="fa fa-search m-r-10"></i>Share Your Thoughts</label>
                                </div>
                            </form>
                            <div className="form-icon ">
                                <button className="btn btn-success btn-icon  waves-effect waves-light">
                                    <i className="fa fa-paper-plane "></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                </>
  )
}

export default Sidebar