import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { ServerUrl, config, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, CustomLoader, Alerts } from '../../components/notify';
import Select from 'react-select'
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'

import { useSelector } from 'react-redux'
const Offices =(props:any)=>{


    const [loading, setLoading] = useState(false);
    const [settings, setSettings] = useState([] as any);
    const [contact, setContact] = useState([] as any);
   
    const state  = useSelector((state:any) => state.rootReducer.states);
    const [errors, setErrors] = useState({
        location:'',
        address:'',
     })
    const [profile, setProfile] = useState({
        location:'',
       address:'',
       telephone:'',
        code:Math.random().toString(36).substring(2, 9)
    });

    const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	
        setProfile({...profile, [name]:value});
       setErrors({...errors, [name]:''});
     }


     const handleSelect = (option:any, action:any)=>{

        setProfile({...profile, [action.name]:option.value});
       setErrors({...errors, [action.name]:''});
           }

   

           const getState =(alias:string)=>{
            const rs = state&&state.filter((item:any)=>item.alias===alias)
            return rs.length!==0?rs[0].name:''
         }


        const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
            event.preventDefault();
        
            let error = {...errors}; 
            let formIsValid = true;
        
        let msg ='This field is required';
        

        if(!profile.location){
            error.location =msg;
            formIsValid = false;
        } 
        
    
        if(!profile.address){
            error.address =msg;
            formIsValid = false;
        } 

        if(!formIsValid){
        setErrors(error) 
        }
        
        
        if(formIsValid) {
           LoadingModal()

                const all_contacts = contact.concat(profile)
               
                  setContact(all_contacts)
           
                      const fd = new FormData();
               fd.append('contacts', JSON.stringify(all_contacts,null, 2));
               fd.append('id', settings.id);

        let click = document.getElementById('close')?.click();

           let url = ServerUrl+'/api/settings/update_contact'
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status === 200){
             SuccessModal(response.data.message)
           
                      } else{
                        Alerts('danger', response.data.message)
                      }   
                  })
                  .catch((error)=>{
                    Alerts('danger', error.message)
                  }).finally(()=>{

                    setProfile({
                        location:'',
                        address:'',
                        telephone:'',
                        code:Math.random().toString(36).substring(2, 9)
                    })

                  })  
            }
        
        }



const DeleteModal =(code:string)=>{
    
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading(),
    confirmButtonText: 'Yes, I am!',

    preConfirm: async() => {

const newContact = contact.filter((item:any)=>item.code !==code)

const fd = new FormData();
fd.append('contacts', JSON.stringify(newContact,null, 2));
fd.append('id', settings.id);

let url = ServerUrl+'/api/settings/update_contact'


  setContact(newContact)

return await axios.post(url, fd, config)
.then(response =>{

if(response.data.status !== 200){
    throw new Error(response.statusText)

      } else{
        return response.data
      }   
  })
  .catch((error)=>{
    Swal.showValidationMessage(
        `Request failed: ${error}`
      )
  })
    }
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }






        const TableData =()=>{

        
            const columns = [
                {
                    name: 'State',
                    cell: (row:any) => row.location.toUpperCase(), 
                    sortable: true,
                },
                {
                    name: 'Telephone',
                    selector: (row:any) => row.telephone,
                    sortable: true,
                },
                {
                    name: 'Address',
                    selector: (row:any) => row.address,
                    sortable: true,
                    wrap:true
                },
                
                {
                    name: 'Action',
                    cell: (row:any, index:any, column:any, id:any) => <div className='row'>  
                        <label className="label label-info pointer"  onClick={()=>EditItem(row)} ><i className='ti-pencil'></i> Edit</label>
                    <label className="label label-danger pointer" onClick={()=>DeleteModal(row.code)}><i className='ti-trash'></i> Delete</label></div>,
                        left:true
                              
        
                },
            ];
            
        
          
        
            return <DataTable
            columns={columns}
            data={contact}
            dense
            highlightOnHover
            pagination
            selectableRowsHighlight
            striped
            progressPending={loading}
            progressComponent={<CustomLoader />}
        />
        
        }


        const handleUpdate =(event: FormEvent<HTMLButtonElement>)=>{
            event.preventDefault();
        
            let error = {...errors}; 
            let formIsValid = true;
        
        let msg ='This field is required';
        

        if(!profile.location){
            error.location =msg;
            formIsValid = false;
        } 
        
        
        if(!profile.address){
            error.address =msg;
            formIsValid = false;
        } 

        if(!formIsValid){
        setErrors(error) 
        }
        
        
        if(formIsValid) {
           LoadingModal()

              
                const newContact = contact.map((item:any)=>{
                   
                    if(item.code ===profile.code){
                        return profile
                    }
    
                     return item
                      })






const fd = new FormData();
fd.append('contacts', JSON.stringify(newContact,null, 2));
fd.append('id', settings.id);

let url = ServerUrl+'/api/settings/update_contact'


                  setContact(newContact)

                    
        let click = document.getElementById('closeEdit')?.click();

          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status === 200){
             SuccessModal(response.data.message)
           
                      } else{
                        Alerts('danger', response.data.message)
                      }   
                  })
                  .catch((error)=>{
                    Alerts('danger', error.message)
                  }).finally(()=>{

                    setProfile({
                        location:'',
                        address:'',
                        telephone:'',
                        code:Math.random().toString(36).substring(2, 9)
                    })

                  })  
            }
        
        }


        const EditItem =(data:any)=>{
            setProfile({
                code:data.code,
                location:data.location,
                telephone:data.telephone,
                address:data.address,
            });
            setErrors({
                location:'',
                address:''
            })
            let click = document.getElementById('openEdit')?.click()
        
        }

       


               const handleReset =()=>{
                setProfile({
                    
                    location:'',
                    address:'',
                    telephone:'',
                    code:Math.random().toString(36).substring(2, 9)
                })
                let click = document.getElementById('openAdd')?.click()
            }


            useEffect(()=>{

                try{
             
                 if (props.settings!==undefined && props.settings.length!==0){
                 setSettings(props.settings)
                 setContact(JSON.parse(props.settings.contacts))
                 }
             
                }catch(err) {
             
                }
                 
                 }, [props])

  return (
   <div>

    
<div className="card z-depth-0">

<div className="card-header">
<h5>Zonal Offices</h5>

<div className="card-header-right">
<label className="label label-primary pointer " onClick={handleReset}> <i className='fa fa-plus text-white'></i> Add Work Station</label>
<label  id='openAdd' data-toggle="modal" data-target="#large-Modal"> </label>        
</div>

</div>




<label data-toggle="modal" data-target="#editFamily" id='openEdit' ></label>

<div className="modal fade push-modal-right" id="editFamily" tabIndex={-1} role="dialog" data-backdrop="static" >
       <div className="modal-dialog modal-lg" role="document">
           <div className="modal-content">
               <div className="modal-header bg-dark">
                   <h4 className="modal-title text-white">Update Work Station</h4>
                 

           <button type="button" className="close" data-dismiss="modal" aria-label="Close">
           <i className='fa fa-ban text-danger'></i>
           </button>

               </div>
               <div className="modal-body">
               <div className="row">
<div className="col-md-6">
<div className="form-group">
          <label className="col-form-label">State <span className='text-danger'>*</span></label>
         
              
   <Select   options={
            state&&state.map((list:any, id:number)=> {
                                    return {key:id, value: list.alias, label: list.name };
                                  })}
onChange={handleSelect}  name="location" value={{value: profile.location, label: getState(profile.location)}} 
styles={styles} /> 
    
    {errors.location&&
    <div className="col-form-label has-danger">{errors.location&&errors.location} </div>}
        
           </div>

</div>


<div className="col-md-6">
<div className="form-group">
<label className="col-form-label">Telephone </label>

<input type="text" className="form-control form-control-lg"  name='telephone' value={profile.telephone} onChange={handleChange} />


</div>

</div>



<div className="col-md-12">
<div className="form-group">
<label className="col-form-label">Address <span className='text-danger'>*</span></label>

<input type="text" className={errors.address?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='address' value={profile.address} onChange={handleChange} />

{errors.address&&
<div className="col-form-label has-danger">{errors.address&&errors.address} </div>}

</div>

</div>


</div>
               </div>
               <div className="modal-footer">
                   <button type="button" className="btn btn-default waves-effect " data-dismiss="modal" id="closeEdit">Close</button>
                   <button type="button" className="btn btn-outline-success " onClick={handleUpdate} >Update</button>
               </div>
           </div>
       </div>
   </div>



<div className="modal fade push-modal-right" id="large-Modal" tabIndex={-1} role="dialog" data-backdrop="static" >
       <div className="modal-dialog modal-lg" role="document">
           <div className="modal-content">
               <div className="modal-header bg-dark">
                   <h4 className="modal-title text-white">Add Work Station</h4>
                 

           <button type="button" className="close" data-dismiss="modal" aria-label="Close">
           <i className='fa fa-ban text-danger'></i>
           </button>

               </div>
               <div className="modal-body">
               <div className="row">
               <div className="col-md-6">
<div className="form-group">
          <label className="col-form-label">State <span className='text-danger'>*</span></label>
         
              
   <Select   options={
            state&&state.map((list:any, id:number)=> {
                                    return {key:id, value: list.alias, label: list.name };
                                  })}
onChange={handleSelect}  name="location" value={{value: profile.location, label: getState(profile.location)}} 
styles={styles} /> 
    
    {errors.location&&
    <div className="col-form-label has-danger">{errors.location&&errors.location} </div>}
        
           </div>

</div>


<div className="col-md-6">
<div className="form-group">
<label className="col-form-label">Telephone </label>

<input type="text" className="form-control form-control-lg"  name='telephone' value={profile.telephone} onChange={handleChange} />


</div>

</div>

<div className="col-md-12">
<div className="form-group">
<label className="col-form-label">Address <span className='text-danger'>*</span></label>

<input type="text" className={errors.address?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='address' value={profile.address} onChange={handleChange} />

{errors.address&&
<div className="col-form-label has-danger">{errors.address&&errors.address} </div>}

</div>

</div>


</div>
               </div>
               <div className="modal-footer">
                   <button type="button" className="btn btn-default waves-effect " data-dismiss="modal" id="close">Close</button>
                   <button type="button" className="btn btn-primary waves-effect waves-light " onClick={handleSubmit}>Save </button>
               </div>
           </div>
       </div>
   </div>





<div className="card-block">


<div className="table-responsive m-t-10">
<TableData />
</div>

</div>
</div>
   </div>

  )
}

export default Offices