import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, CURRENCY, STAFF_CODE, ServerUrl, config, selectStyles, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts, CustomLoader } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'

import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { FormatNumber, getMonthYear } from '../../components/GlobalFunction';
import { Link } from 'react-router-dom';

const  ModifyPayment =()=> {
   
    const [payment, setPayment] = useState({
        staff:[] as any,
        pay_mode:[]as any,
        pay_month:'',
        pay_date:'',
        remarks:''
    })


    const [salaryStructure, setSalaryStructure]=useState([] as any);

    const [title, setTitle] = useState([] as any);
    const [staff, setStaff] = useState([] as any);
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
         staff:'',
         pay_mode:'',
         pay_date:'',
         pay_month:''
       
     })
    

     const fetchStaff =async()=>{
        let url = ServerUrl+'/api/employees/view/'+COMPANY_CODE
        const {data} = await axios.get(url, config)
        setStaff(data.data)
        }
      
   
     const handleSelect = (option:any, action:any)=>{
        setPayment({...payment, [action.name]:option});
          setErrors({...errors, [action.name]:''})
          if(action.name==='staff'){          
            fetchSalaryStructure(option.value);
            fetchContent(option.value)
            }

           }

           const handleChangeAmount = (e:ChangeEvent<HTMLInputElement>, code:string)=>{
           
            let newSalary:any = salaryStructure.map((item:any)=>{
              
               let  value = e.target.value.replace(/[^0-9.]/g, '');
                if(item.code === code){

                    if(Number(value)>item.amount){
                        Alerts('danger', 'Amount to pay cannot be more than Amount')
                        value= item.amount
                    }

                    return {...item, 
                        amount_topay:value,
                        balance:Number(item.amount)- Number(value),
                        due: Number(item.amount)== Number(value)?'No Dues':'Due',
                        paid:Number(value)
                    }
                    };
                    return item;
              })
              setSalaryStructure(newSalary); 
        }


           const handleChange = (event:ChangeEvent<HTMLInputElement>)=>{
           
            let {name, value} = event.target;	
            setPayment({...payment, [name]:value});
           setErrors({...errors, [name]:''});
        }

        
const getTitle = (code:string, field:string) =>{ 
    const result = title.filter((list:any)=>list.code===code);
   const answer = result.map((c:any)=>c[field] ); 
     return  String(answer);
   }

        


    const  fetchSalaryStructure =async(staff:string)=>{    
       
            LoadingModal()
            let url = ServerUrl+'/api/payroll/assign_structure/get_structure/'+staff
          await  axios.get(url, config).then((result:any)=>{
            if(Array.isArray(result.data.data) && result.data.data.length!==0){
              
                try{
                    let structure = JSON.parse(result.data.data[0].salary_structure)
                let template_code = result.data.data[0].template_code;
                    var options = structure
                    var value = [];
                    for (var i = 0, l = options.length; i < l; i++) {
                      value.push({
                        unique_code:Math.random().toString(36).substring(2, 9),
                        code:options[i].code, 
                        title:getTitle(options[i].code, 'title'),
                         amount:options[i].amount,
                         item_code:getTitle(options[i].code, 'item_code'),
                         item_type:getTitle(options[i].code, 'item_type'),
                         amount_topay:options[i].amount, 
                         due:'No Dues',
                         paid:options[i].amount, 
                         balance:0,
                         template_code:template_code
                        
                        });
                    }


                    setSalaryStructure(value)
                }catch(e){

                }
             
              }else{
                setSalaryStructure([])
              }
            })
            .catch((error)=>{
                Alerts('danger', error.message)
            }).finally(()=>{
                closeModal()
            }) 
        } 




const handleReset =()=>{
    setPayment({
        staff:[] as any,
        pay_mode:[]as any,
        pay_month:'',
        pay_date:'',
        remarks:''
    })
setContent([])
    setSalaryStructure([])
}




  const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';




if(salaryStructure.length===0){
    error.staff ='Please assign salary structure to this employee';
    formIsValid = false;
} 

if(payment.staff.length===0){
    error.staff =msg;
    formIsValid = false;
} 

if(payment.pay_mode.length===0){
    error.pay_mode =msg;
    formIsValid = false;
}  
 
if(!payment.pay_month){
    error.pay_month =msg;
    formIsValid = false;
}  

if(!payment.pay_date){
    error.pay_date =msg;
    formIsValid = false;
}  

if(!formIsValid){
setErrors(error) 

window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
}

if(formIsValid) {
  LoadingModal()
   
    const fd = new FormData();
    let code = 'SP'+Math.random().toString(36).substring(2, 9)
   
    

    fd.append('salary_structure', JSON.stringify(salaryStructure, null, 2)); 
    fd.append('company_code', COMPANY_CODE);
    fd.append('p_code', code);
    fd.append('added_by', STAFF_CODE);
    fd.append('staff_code', payment.staff.value);
    fd.append('payment_mode', payment.pay_mode.value);
    fd.append('payment_month', payment.pay_month);
    fd.append('payment_date', payment.pay_date);
    fd.append('remarks', payment.remarks);
    

let url = ServerUrl+'/api/payroll/salary_payment/add';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                Alerts('danger', response.data.message)
                closeModal()
              }   
          })
          .catch((error)=>{
            Alerts('danger', error.message)
            closeModal()
          }).finally(()=>{
        handleReset()

          })  
    }

}

const getTotalAmountToPay =()=>{
    var amount =0
    salaryStructure.map((sl:any)=>
    sl.item_type==='EARNING'?
        amount += Number(sl.amount_topay):amount -=Number(sl.amount_topay)
    )
    return amount
}
const getTotalBalance =()=>{
    var amount =0
    salaryStructure.map((sl:any)=>
    sl.item_type==='EARNING'?
        amount += Number(sl.balance):amount -=Number(sl.balance)
    )
    return amount
}
   const getTotalAmount =()=>{
    var amount =0
    salaryStructure.map((sl:any)=>
    sl.item_type==='EARNING'?
        amount += Number(sl.amount):amount -=Number(sl.amount)
    )
    return amount
}


const DeleteModal =(row:any)=>{
    let url = ServerUrl+'/api/payroll/salary_payment/delete/'+payment.staff.value+'/'+row.payment_month;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =content.filter((item:any)=>item.payment_month !==row.payment_month)
            setContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }



    const fetchTitle = async()=>{
        let url = ServerUrl+'/api/payroll/structure/view_title/'+COMPANY_CODE
      await  axios.get(url, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){
          setTitle(result.data.data)
          }
        })
        .catch((error)=>{
            Alerts('danger', error.message)
        })
        } 



const TableData =()=>{


    const columns = [
        {
            name: 'Payment Month',
            selector: (row:any) => getMonthYear(row.payment_month),
            sortable: true,
        },
        {
            name: 'Date Paid',
            selector: (row:any) => row.payment_date,
            sortable: true,
        },
        {
            name: `Earning (${CURRENCY})`,
            cell: (row:any) =>FormatNumber(row.earning),
            wrap:true
        },
        {
            name: `Deduction (${CURRENCY})`,
            cell: (row:any) =>FormatNumber(row.deduction),
            wrap:true
        },
        {
            name: `Net Pay (${CURRENCY})`,
            cell: (row:any) =>FormatNumber(row.net_pay),
            wrap:true
        },
        {
            name: 'Action',
            cell: (row:any, index:any, column:any, id:any) => <div className='row'>  
                <label className="label label-primary pointer" ><i className='fa fa-print'></i> Print</label>
            <label className="label label-danger pointer" onClick={()=>DeleteModal(row)}><i className='ti-trash'></i> Delete</label></div>,
                left:true
                      

        },
    ];
    
  

    return <DataTable
    columns={columns}
    data={content}
    dense
    highlightOnHover
    pagination
    selectableRowsHighlight
    striped
    progressPending={loading}
	progressComponent={<CustomLoader />}
/>

}




const fetchContent = async(staff:string)=>{
    setLoading(true)
    let url = ServerUrl+'/api/payroll/salary/get_payment/'+staff
    await  axios.get(url, config).then((result:any)=>{
      if(Array.isArray(result.data.data) && result.data.data.length!==0){
        setContent(result.data.data)
        }else{
            setContent([])
        }
      })
         .catch((error)=>{
             Alerts('danger',error.message)
         }).finally(()=>{
             setLoading(false)
         })
         } 


    useEffect(()=>{
        
        fetchTitle()
        fetchStaff() 
      }, [])


    

  return ( <>
   <Breadcrumb 
   title="Update Payment"
   summary="Modify payment made or generate a new one for the selected employee "
   >
    <li className="breadcrumb-item">
    <Link to="/payroll/staff_salary">Payroll</Link>
                    </li>
   <li className="breadcrumb-item">
    <Link to="#!">Update Payment</Link>
                    </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Salary Payment</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                              
                                    
                                    
         <div className="content-wrappers ">
                            
         <div className=''>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Employee Salary</h5>
                <span>Add all offcie department</span>
              
            </div>
            <div className="card-block">
    
        
                        <div className="row">
                  
                        <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Select Employee <span className='text-danger'>*</span></label>


                 
                <Select 
                options={
                               staff&&staff.map((list:any, idx:number)=> {
                                return {key:idx, value: list.code, label: list.first_name, last_name:list.last_name, middle_name:list.middle_name, title:list.title }
                                })}
                                className={errors.staff?"form-control form-control-danger":""} 
                     getOptionLabel={option =>`${option.title} ${option.label} ${option.last_name}`}
    onChange={handleSelect}  name="staff" value={payment.staff} 
    styles={styles} 
    /> 
                    
                    {errors.staff&&
    <div className="col-form-label has-danger">{errors.staff&&errors.staff} </div>}
                    </div>
    
                     </div>


                    <div className="col-md-6">
                    <div className="form-group">
                    <label className="col-form-label">Payment Mode <span className='text-danger'>*</span></label>

                    <Select  options={[
                                    { value: 'CASH', label: 'CASH' },
                                    { value: 'CHEQUE', label: 'CHEQUE'},
                                    { value: 'TRANSFER', label: 'TRANSFER'},
                                    { value: 'BANK', label: 'BANK'},
                                    { value: 'OTHERS', label: 'OTHERS'},
                                ]} 
                       
                                onChange={handleSelect}  name="pay_mode"
                                className={errors.pay_mode?"form-control form-control-danger":""} 
                        value={payment.pay_mode} 
                        styles={styles} 
                        
                 />
                

                    {errors.pay_mode&&
    <div className="col-form-label has-danger">{errors.pay_mode&&errors.pay_mode} </div>}
                    </div>
    
                     </div>



                     <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Payment Month <span className='text-danger'>*</span></label>
           
            <input type="month" className={errors.pay_month?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='pay_month' value={payment.pay_month} onChange={handleChange}  />
          

            {errors.pay_month&&
    <div className="col-form-label has-danger">{errors.pay_month&&errors.pay_month} </div>}
             </div>

             </div>

             <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Payment Date <span className='text-danger'>*</span></label>
           
            <input type="date"   className={errors.pay_date?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  name='pay_date' value={payment.pay_date} onChange={handleChange}  />


            {errors.pay_date&&
    <div className="col-form-label has-danger">{errors.pay_date&&errors.pay_date} </div>}
          
             </div>

             </div>


             <div className="col-md-12">
             <div className="form-group">
            <label className="col-form-label">Remarks</label>
           
            <input type="text" className="form-control form-control-lg"  name='remarks' value={payment.remarks} onChange={handleChange}  />
          
             </div>

             </div>



                    </div>




    
                    <div className="table-responsive m-t-10">
    

    <div style={{display:salaryStructure.length===0?'none':'block'}} className="row">
           
           <div className="col-md-12">
               <div className="table-responsive">

             
<table className="table table-sm">
                    <thead>
                        <tr>
                            <th colSpan={6} className='text-primary'> <h4>Salary Structure - {payment.staff.label + ' ' +payment.staff.last_name + ' '+ payment.staff.middle_name}</h4></th>
                        </tr>
                        <tr>
                        <th>Title</th>
                        <th>Amount ({CURRENCY})</th>
                        <th>Amount To Pay  ({CURRENCY})</th>
                        <th>Due</th>
                        {/* <th>Paid ({CURRENCY})</th> */}
                        <th>Balance  ({CURRENCY})</th>
                        </tr>
                    </thead>
                       <tbody>

                       
{salaryStructure&&salaryStructure.map((tit:any, id:number)=>

   <tr key={id} style={{color:tit.item_type==='EARNING'?'green':'red'}}>
       <th scope="row" >{'['+tit.item_code +'] '+ tit.title}</th>
       <td> {FormatNumber(tit.amount)}</td>
   <td> 
                <div className="input-group">
        <span className="input-group-prepend">
            <label className="input-group-text">{CURRENCY}</label>
        </span>
        <input type="text"
        
        pattern='[0-9]'
        className="form-control form-control-bold" placeholder='Amount' min="0" name='amount_topay' onFocus={(e)=>e.target.select()}  value={tit.amount_topay}  onChange={(e)=>handleChangeAmount(e, tit.code)} />
                                    </div>
            </td>
            <td>{tit.due}</td>
           {/*  <td> {FormatNumber(tit.paid)}</td> */}
            <td> {FormatNumber(tit.balance)}</td>

   </tr>)}
   <tr>
    <th>Total</th>
    <th>{FormatNumber(getTotalAmount())}</th>
    <th>{FormatNumber(getTotalAmountToPay())}</th>
    <th>-</th>
{/* 
    <th>{FormatNumber(salaryStructure.reduce((acc:number, item:any)=>Number(item.paid)+Number(acc), 0))}</th> */}
    <th>{FormatNumber(getTotalBalance())}</th>
   </tr>
       </tbody>
       </table>


                  
   </div>
</div>
        
          
</div>

    </div>


          

<hr />
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-outline-danger btn-round pr-5 pl-5 mr-3" type='button' onClick={handleReset}>Reset</button>
<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={handleSubmit}>Save</button>
</div>
</div>
    
            </div>
        </div>
    </div>
    </div>
    
    
    

    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Payment History</h5>
                <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
              
            </div>
            <div className="card-block">
    
        <div className="table-responsive ">
        <TableData />
        </div>
    
            </div>
        </div>
    </div>
    </div>
    
                </div>

        </div>
                       
                    </section>
                </div>
            </div>
        </div>
    </div>
                                    </div>
                                </div>
                            </div>





                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default ModifyPayment