import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent, useMemo} from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { COMPANY_CODE, ServerUrl, config } from '../../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal, CustomLoader } from '../../../components/notify';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
//https://codesandbox.io/s/react-data-table-sandbox-z6gtg?file=/src/index.js:259-487
//https://react-data-table-component.netlify.app/?path=/docs/api-custom-conditional-formatting--page

const IncomeHead =()=> {
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
      title:''
     })
  
    const [ item, setItem ] = useState({
      id:'',
      code:Math.random().toString(36).substring(2, 9),
        title:'',
        isEdit:false
    });

    const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	
        setItem({...item, [name]:value});
       setErrors({...errors, [name]:''});
     }



const handleReset =()=>{
  setItem({
    id:'',
    code:Math.random().toString(36).substring(2, 9),
      title:'',
      isEdit:false
  })
  setErrors({
    title:'',
    
     
   })
}


const TableData =()=>{


    const columns = [
      {
        name: 'SN',
        cell: (row:any, index:number) => index+1,
        sortable: true,
        width:'30px'
    },
        {
            name: 'Title',
            selector: (row:any) => row.title,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row:any, index:any, column:any, id:any) => <div className='row'>  
                <label className="label label-info pointer" onClick={()=>EditItem(row)} ><i className='ti-pencil'></i> Edit</label>
            <label className="label label-danger pointer" onClick={()=>DeleteModal(row.id)}><i className='ti-trash'></i> Delete</label></div>,
                left:true
                      

        },
    ];
    

    return <DataTable
    columns={columns}
    data={content}
    dense
    highlightOnHover
    pagination
    selectableRowsHighlight
    striped
    progressPending={loading}
	progressComponent={<CustomLoader />}
/>

}




const EditItem =(data:any)=>{
    setItem({
        id:data.id,
        title:data.title,
        code:data.code,
        isEdit:true
    });

    setErrors({
      title:'',
      
       
     })
   
window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
}

  const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!item.title){
    error.title =msg;
    formIsValid = false;
} 



if(!formIsValid){
setErrors(error) 
}

if(formIsValid) {

    LoadingModal()
   
    const fd = new FormData();
    let code = Math.random().toString(36).substring(2, 9)
    Object.entries(item).forEach(([key, value]) => {
      fd.append(key, String(value));
  });
    fd.append('company_code', COMPANY_CODE);
    fd.append('code', code); 

let url = ServerUrl+'/api/finance/income_head/add';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                ErrorModal('', response.data.message)
              }   
          })
          .catch((error)=>{
           ErrorModal('oops', error.message)
          }).finally(()=>{
            fetchData()
         handleReset()

          })
    }

}



const handleUpdate =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';


if(!item.title){
  error.title =msg;
  formIsValid = false;
} 


if(!formIsValid){
setErrors(error) 
}


if(formIsValid){

     LoadingModal()
   
    const fd = new FormData();
    Object.entries(item).forEach(([key, value]) => {
            fd.append(key, String(value));
        });

let url = ServerUrl+'/api/finance/income_head/update';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                ErrorModal('', response.data.message)
              }   
          })
          .catch((error)=>{
           ErrorModal('oops', error.message)
          }).finally(()=>{
         fetchData()
         handleReset()
          }) 
    }

}

const DeleteModal =(id:number)=>{
    let url = ServerUrl+'/api/finance/income_head/delete_one/'+id;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =content.filter((item:{id:number})=>item.id !==id)
            setContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }

const fetchData = async()=>{
    setLoading(true)
    
    let url = ServerUrl+'/api/finance/income_head/view/'+COMPANY_CODE
  await  axios.get(url, config).then((result:any)=>{
    if(Array.isArray(result.data.data) && result.data.data.length!==0){
      setContent(result.data.data)
      }else{
        setContent([])
      }
    })
    .catch((error)=>{
     // ErrorModal('error', error.message)
    }).finally(()=>{
        setLoading(false)
    })
    } 


    useEffect(()=>{
        fetchData()
      }, [])

  return (  <div className=''>



    <div className="row">
    <div className="col-sm-12">
    <div className="card z-depth-0">
    <div className="card-header">
    <h5>Income Head</h5>
    <span>Add all Criteria and Description</span>
    
    </div>
    
    <div className="card-block">
    
    <div className="form-group row">
            <label className="col-sm-2 col-form-label">Income Title</label>
            <div className="col-sm-10">
                <input type="text" className={errors.title?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}  value={item.title} name="title" onChange={handleChange} />

            <div className="col-form-label has-danger">{errors.title&&errors.title} </div>
            </div>  
            
            </div> 
    
            <hr />
            <div className="row">
        <div className="col-md-12 text-right">
    
        {item.isEdit?<>
            <button type="button" className="btn btn-outline-danger " onClick={handleReset} >Cancel</button>&nbsp;
            
     <button type="button" className="btn btn-outline-success " onClick={handleUpdate} >Update</button>
            </>
       : <button type='button' onClick={handleSubmit} className="btn btn-success btn-round pr-5 pl-5">Save</button>}
       
         </div>
        </div>
    
    </div>
    </div>
    </div>
    </div>
    
    
    
    
    
    
    
    <div className="row">
    <div className="col-sm-12">
    <div className="card z-depth-0">
    <div className="card-header">
    <h5>All Income</h5>
    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
    
    </div>
    <div className="card-block">
    
    <div className="table-responsive m-t-10">

    <TableData />

    </div>
    
    
    </div>
    </div>
    </div>
    </div>
    
    </div>
  )
}

export default IncomeHead