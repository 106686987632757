import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, CURRENCY, STAFF_CODE, ServerUrl, config, selectStyles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts, CustomLoader } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'

import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { FormatNumber, convertDateTime, getBritishDate, getMonthYear, getNumWorkDays, getTime, toWords } from '../../components/GlobalFunction';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction'
import { Link } from 'react-router-dom';


const  EventCalendar =()=> {
   
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    
   
const fetchData = async()=>{
    setLoading(true)
    let url = ServerUrl+'/api/leave/view_holidays/'+COMPANY_CODE
  await  axios.get(url, config).then((result:any)=>{
    if(Array.isArray(result.data.data) && result.data.data.length!==0){
      setContent(result.data.data)
      }
    })
    .catch((error)=>{
        Alerts('danger', error.message)
    }).finally(()=>{
        setLoading(false)
    })
    } 




const handleDateClick = (arg:any) => { // bind with an arrow function
   // alert(arg.dateStr)
  }


function renderEventContent(eventInfo:any){ 

    return (
        <>
         <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
        </>
      )
         
 }


    useEffect(()=>{
        fetchData()
      }, [])


    

  return ( <>
    <Breadcrumb 
   title="Event Calendar"
   summary="See All Events Here "
   >
    <li className="breadcrumb-item">
    <Link to="/leave/leave_list">Leave</Link>
                    </li>
   <li className="breadcrumb-item">
    <Link to="#!">Event Calendar</Link>
                    </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">


                        <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Event Calendar</h5>
                <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                <div className="card-header-right">


        <Link to='/leave/create_holidays' className="btn btn-primary btn-sm "><i className='fa fa-circle-plus text-white'></i> Add New</Link>
        </div> 
            </div>
            <div className="card-block">
    
        <div className="table-responsive ">


        <FullCalendar
              events={[
                { title: 'event 1', date: '2019-04-01' },
                { title: 'event 2', date: '2019-04-02' }
              ]}        
             eventContent={renderEventContent}
        plugins={[ dayGridPlugin, interactionPlugin ]}
        initialView="dayGridMonth"
        dateClick={handleDateClick}
        headerToolbar = { {
          left: "prev, today",
          center: "title",
          right: "next"
        }}
      />


        </div>
    
            </div>
        </div>
    </div>
    </div>


            </div>
        </div>


    </div>
</div>  
        </>     
  )
}

export default EventCalendar