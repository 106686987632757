import { useState, ChangeEvent, FormEvent, useEffect} from 'react'
import Topbar from './Topbar'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import ProtectedView from '../Protected_view';
import Breadcrumb from './Breadcrumb';
import { COMPANY_CODE, ServerUrl, config, GROUP_CODE, Token } from './Includes';
import { compare } from './GlobalFunction';
import { Alerts } from './notify';

const  Template = (props:{children?:React.ReactNode})=> {
  const dispatch = useDispatch()
  



  const fetchGroup = async ()=>{

    var sql ="Select code, allowEdit, allowDelete from tbl_usertype WHERE code ='"+GROUP_CODE+"'";

    const fd = new FormData();
    fd.append('sql', sql); 
    let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
        const {data} = await axios.post(url, fd, config)

        dispatch({
            type:'SET_RECORDS',
            name:'group',
            data:data.data[0]
        })
}


  const fetchNavigation =async()=>{

    var sql ="Select r.roles, n.menu from tbl_usertype r, tbl_navigations n  where r.code ='"+GROUP_CODE+"'";

    const fd = new FormData();
    fd.append('sql', sql); 
    let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
    await  axios.post(url, fd, config).then((result:any)=>{
      if(Array.isArray(result.data.data) && result.data.data.length!==0){
        try{

          let response = JSON.parse(result.data.data[0].menu).sort(compare)
            let newMenu = JSON.parse(result.data.data[0].roles)
      
          let roles = []
          let menuLink = []

           for (var i in newMenu){
            if(newMenu[i].status===true){
            roles.push(newMenu[i].ID)
            menuLink.push(newMenu[i].menuUrl)
            }
    
     for(var k in newMenu[i].submenu){
            if(newMenu[i].submenu[k].status===true){
            roles.push(newMenu[i].submenu[k].ID)
            menuLink.push(newMenu[i].submenu[k].subUrl)
            }
    
         for(var m in newMenu[i].submenu[k].subsubmenu){
            if(newMenu[i].submenu[k].subsubmenu[m].status===true){
            roles.push(newMenu[i].submenu[k].subsubmenu[m].ID)
            menuLink.push(newMenu[i].submenu[k].subsubmenu[m].subsubUrl) 
            }
         }
        }
    }


    dispatch({
      type:'SET_RECORDS',
      name:'menuLink',
      data:menuLink
  })
  dispatch({
      type:'SET_RECORDS',
      name:'roles',
      data:roles
  })
  dispatch({
      type:'SET_RECORDS',
      name:'sidebar',
      data:response
  })

  }catch(e){
    //console.log(e)
  }

        }

      
      })
      
      .catch((error)=>{
          Alerts('danger', error.message)
      })

  }

useEffect(()=>{
  fetchGroup()
    fetchNavigation()
},[])

  return (   <Router>
    <div id="pcoded" className="pcoded " sidebar-img="true" sidebar-img-type="img3" layout-type="img"  >
            <div className="pcoded-overlay-box"></div>
            <div className="pcoded-container navbar-wrapper">

                <Topbar />
                <Sidebar />

                <div className="pcoded-main-container">
                    <div className="pcoded-wrapper">
                      <Navbar />
                        <div className="pcoded-content">
                         


                        
                             <ProtectedView />

                            </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer no-print">
        <p className="text-center m-b-0">Copyright © {new Date().getFullYear()} HURIS - GREENCODENG, All rights reserved.</p>
    </div>
                    </Router>
        
  )
}

export default Template