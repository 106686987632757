import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo} from 'react'
import { COMPANY_CODE, ImagesUrl, STAFF_CODE, ServerUrl, config, selectStyles, styles } from '../../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal, CustomLoader, closeModal } from '../../../components/notify';
import Select, { components } from 'react-select'
import { fetchEmployee, fetchCountry, handleFetchState } from '../../../api/ReactQuery';
import { useQuery } from 'react-query'

import { useSelector, useDispatch } from 'react-redux'

const Contact =({code}:{code:any})=> {

    const [disabled, setDisabled] = useState(false);

    const {data} = useQuery(["employee", code], fetchEmployee)
    const country  = useSelector((state:any) => state.rootReducer.country);
    const state  = useSelector((state:any) => state.rootReducer.states);

    const [lga, setLga] = useState([] as any);
    const [rlga, setRLga] = useState([] as any);
    const [isResidence, setIsResidence] = useState('Yes');
    const [content, setContent] = useState([] as any);

    const [errors, setErrors] = useState({
       
        work_email:'',
        mobile_phone:'',
        
     })



     const handleResidence =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	
    
        value= value==='Yes'?'No':'Yes'
        setIsResidence(value)

        if(isResidence==='Yes'){
            const newProfile = content.map((item:any)=>{
                return {...item, 
                    r_country:item.country,
                    r_state:item.state,
                    r_lga:item.lga,
                    r_city:item.city,
                    r_house_no:item.house_no
                }
              })
              setContent(newProfile)
        }
     }


    const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	
      const newProfile = content.map((item:any)=>{
        return {...item, [name]:value}
      })
      setContent(newProfile)
       //setErrors({...errors, [name]:''});
     }


    const handleSelect = (option:any, action:any)=>{
        const newProfile = content.map((item:any)=>{
            return {...item, [action.name]:option.value}
          })
          setContent(newProfile)
          if(action.name==='state'){
            handleFetchLga(option.value)
          }
          if(action.name === 'r_state'){
            handleFetchRLga(option.value)
          }
           }


           const handleFetchRLga =async(alias:string)=>{

            let url = 'https://locus.fkkas.com/api/regions/'+alias
           await  axios.get(url, config).then(result =>{
              if(result.data.data.length!==0){
                  let response =result.data.data.sort((a:any, b:any) =>(a.name > b.name) ? 1 : -1)
                  setRLga(response)
                }else{
                    setRLga([])
                }
          
            })
      
         }

           const handleFetchLga =async(alias:string)=>{

            let url = 'https://locus.fkkas.com/api/regions/'+alias
           await  axios.get(url, config).then(result =>{
              if(result.data.data.length!==0){
                  let response =result.data.data.sort((a:any, b:any) =>(a.name > b.name) ? 1 : -1)
                  setLga(response)
                }else{
                    setLga([])
                }
          
            })
      
         }


   
const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!content[0].mobile_phone){
    error.mobile_phone =msg;
    formIsValid = false;
} 


if(!content[0].work_email){
    error.work_email =msg;
    formIsValid = false;
} 



if(!formIsValid){
setErrors(error) 
}


if(formIsValid) {

    LoadingModal()
 const fd = new FormData();
    Object.entries(content[0]).forEach(([key, value]) => {
            fd.append(key, String(value));
        });

        const log = JSON.parse(content[0].update_log)
        const item = log.concat( {
            code:STAFF_CODE,
            action:'Updated contact details',
            date_time: new Date()
        })


const logs = JSON.stringify(item,null, 2)

        fd.append('logs', logs);
       

   let url = ServerUrl+'/api/employee/update/'+code;
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
                ErrorModal('', response.data.message)
              }   
          })
          .catch((error)=>{
           ErrorModal('oops', error.message)
          }) 
    }

}


const validateEmail =()=>{
  /*   if(profile.work_email!==''){
    let email = profile.work_email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if(!email){
      setErrors({...errors, work_email:profile.work_email+' not a valid email address'});
      setProfile({...profile, work_email:''});
    }else{
        ValidateEmailExistence()
    }
    } */

  }

const ValidateEmailExistence=()=>{
 /*    if(profile.work_email!==''){
         const fd = new FormData();
         fd.append("data", profile.work_email)
         let url = ServerUrl+'/api/employee/existence/work_email';
         axios.post(url, fd, config).then(response=>{
             if(response.data.data===true){
                 setErrors({...errors, work_email:profile.work_email + ' already register'})
                 setProfile({...profile, work_email:''});
                 return '';
             }
       })
     }  */
 } 

 const getState =(alias:string)=>{
    const rs = state&&state.filter((item:any)=>item.alias===alias)
    return rs.length!==0?rs[0].name:''
 }


 useEffect(()=>{
    if (data!==undefined)
    setContent(data)
    window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
         
    }, [data])

  return (
    <div>


    <div className="card z-depth-0">

    <div className="card-header">
        <h5>Contact Address</h5>

    </div>
    {content.length!==0? content.map((profile:any, index:number)=>  <div key={index}  className="card-block">
   

    <h6>Permanent Address</h6>
    <hr/>

    <div className="row">
            
            <div className="col-md-4">
             <div className="form-group">
            <label className="col-form-label">Country</label>
           
         <Select   options={country&&country.map((list:any, id:number)=> {
                                    return {key:id, value: list.name.common, label: list.name.common };
                                  })}
onChange={handleSelect}  name="country" value={{value: profile.country, label: profile.country}}
styles={styles} />   
          
             </div>


             </div>
         


             <div className="col-md-4">
             <div className="form-group">
            <label className="col-form-label">State</label>
           
   <Select   options={
            state&&state.map((list:any, id:number)=> {
                                    return {key:id, value: list.alias, label: list.name };
                                  })}
onChange={handleSelect}  name="state" value={{value: profile.state, label: getState(profile.state)}} 
styles={styles} />  
          
             </div>

             </div>


             <div className="col-md-4">
             <div className="form-group">
            <label className="col-form-label">Local Area</label>
           
           <Select   options={lga&&lga.map((list:any, id:number)=> {
                                    return {key:id, value: list.name, label: list.name };
                                  })}
onChange={handleSelect}  name="lga" value={{value: profile.lga, label:profile.lga}}
styles={styles} />   
          
             </div>

             </div>


             <div className="col-md-4">
             <div className="form-group">
            <label className="col-form-label">City</label>
           
            <input type="text" className="form-control form-control-lg"  name='city' value={profile.city} onChange={handleChange} disabled={disabled} />
          
             </div>

             </div>

             <div className="col-md-4">
             <div className="form-group">
            <label className="col-form-label">Street & House No.</label>
           
                <input type="text" className="form-control form-control-lg"  name='house_no' value={profile.house_no} onChange={handleChange} disabled={disabled} />
             </div>

             </div>


            </div>

<div className="row">
<div className="col-md-12">

<div className="chk-option">
                                <div className="checkbox-fade fade-in-primary">
                                    <label className="check-task">
    <input type="checkbox" value={isResidence} name="isResidence" checked={isResidence ==='Yes'?false:true}  onChange={handleResidence} />
    <span className="cr">
    <i className="cr-icon fa fa-check txt-default"></i>
    </span> Same As Residential Address
</label>
                                </div>
                            </div>

</div>
</div>


    <h6 className='mt-4'>Resident Address</h6>
    <hr/>

    <div className="row">
            
            <div className="col-md-4">
             <div className="form-group">
            <label className="col-form-label">Country</label>
           
            <Select   options={country&&country.map((list:any, id:number)=> {
                                    return {key:id, value: list.name.common, label: list.name.common };
                                  })}
onChange={handleSelect}  name="r_country" value={{value: profile.r_country, label: profile.r_country}}

styles={styles} />  
          
             </div>


             </div>
         


             <div className="col-md-4">
             <div className="form-group">
            <label className="col-form-label">State</label>
           
          <Select   options={
                
                state&&state.map((list:any, id:number)=> {
                                    return {key:id, value: list.alias, label: list.name };
                                  })}
onChange={handleSelect}  name="r_state" value={{value: profile.r_state, label: getState(profile.r_state)}} 
styles={styles} />  
           
             </div>

             </div>
             <div className="col-md-4">
             <div className="form-group">
            <label className="col-form-label">Local  Area</label>
           
            <Select   options={rlga&&rlga.map((list:any, id:number)=> {
                                    return {key:id, value: list.name, label: list.name };
                                  })}
onChange={handleSelect}  name="r_lga" value={{value: profile.r_lga, label:profile.r_lga}} 
styles={styles} />  
          
             </div>

             </div>


             <div className="col-md-4">
             <div className="form-group">
            <label className="col-form-label">City</label>
           
            <input type="text" className="form-control form-control-lg"  name='r_city' value={profile.r_city} onChange={handleChange} disabled={disabled} />
          
             </div>

             </div>

             <div className="col-md-4">
             <div className="form-group">
            <label className="col-form-label">Street & House No.</label>
           
            <input type="text" className="form-control form-control-lg"  name='r_house_no' value={profile.r_house_no} onChange={handleChange} disabled={disabled} />
             </div>

             </div>


            </div>



            <h6 className='mt-4'>Telephone</h6>
    <hr/>

    <div className="row">
            
            <div className="col-md-4">
             <div className="form-group">
            <label className="col-form-label">Home Phone</label>
           
            <input type="text" className="form-control form-control-lg"  name='home_phone' value={profile.home_phone} onChange={handleChange} disabled={disabled} />
          
             </div>


             </div>
         


             <div className="col-md-4">
             <div className="form-group">
            <label className="col-form-label">Mobile Phone <span className='text-danger'>*</span></label>
           
            <input type="text" className="form-control form-control-lg"  name='mobile_phone' value={profile.mobile_phone} onChange={handleChange} disabled={disabled} />
          
             </div>

             </div>
             <div className="col-md-4">
             <div className="form-group">
            <label className="col-form-label">Work Phone</label>
           
            <input type="text" className="form-control form-control-lg"  name='work_phone' value={profile.work_phone} onChange={handleChange} disabled={disabled} />
          
             </div>

             </div>


             <div className="col-md-4">
             <div className="form-group">
            <label className="col-form-label">Other Phone</label>
           
            <input type="text" className="form-control form-control-lg"  name='other_phone' value={profile.other_phone} onChange={handleChange} disabled={disabled} />
          
             </div>

             </div>


            </div>





            <h6 className='mt-4'>Email Address</h6>
    <hr/>

    <div className="row">
            
            <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Personal Email</label>
           
            <input type="text" className="form-control form-control-lg"  name='personal_email' value={profile.personal_email} onChange={handleChange} disabled={disabled} />
             </div>


             </div>
         


             <div className="col-md-6">

             
             <div className="form-group">
            <label className="col-form-label">Work Email <span className='text-danger'>*</span></label>
           
            <div className="input-group input-group-success input-group-lg">
    <input type="text" className={errors.work_email?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} onBlur={validateEmail} name='work_email'  disabled={true} value={profile.work_email} onChange={handleChange} />
    <span className="input-group-prepend "><label className="input-group-text "><i className="fa fa-check"></i></label></span>
    </div>

    {errors.work_email&&
    <div className="col-form-label has-danger">{errors.work_email&&errors.work_email} </div>}
          
             </div>

             </div>
            



            </div><hr/>

            <div className="row">
<div className="col-md-12 text-right">
<button className="btn btn-success btn-round" type='button' onClick={handleSubmit}>Save Details</button>
</div>
</div>

   
            </div>):''}
                </div>




            </div>
  )
}

export default Contact