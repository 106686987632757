import React from 'react'

function Breadcrumb(props:any) {
  return (

    <div className="page-header">
    <div className="page-block">
        <div className="row align-items-center">
            <div className="col-md-8">
                <div className="page-header-title">
                    <h5 className="m-b-10">{props.title}</h5>
                    <p className="m-b-0">{props.summary}</p>
                </div>
            </div>
            <div className="col-md-4">
                <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a href="/dashboard"> <i className="fa fa-home"></i> </a>
                    </li>
                    {props.children}
                </ul>
            </div>
        </div>
    </div>
</div>
  )
}

export default Breadcrumb