import Cookies from 'js-cookie'

var CryptoJS = require('crypto-js')


   export const encrypt=data=>CryptoJS.AES.encrypt(data, 'huris').toString();
 
  export const decrypt=data=>{
  var byte =  CryptoJS.AES.decrypt(data, 'huris')
  return byte.toString(CryptoJS.enc.Utf8)
  } 

  
  export const verifyRouter =(route)=>{
    return true
    
      }

      