import { useState, ChangeEvent, FormEvent, useEffect} from 'react'
import { COMPANY_CODE, CURRENCY, ImagesUrl, ServerUrl, config, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, closeModal, Alerts, SuccessModal } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'

import { useQuery } from 'react-query'
import { FormatNumber, compare } from '../../components/GlobalFunction';

import { fetchDepartment, fetchJobs } from '../../api/ReactQuery';
import { Link } from 'react-router-dom';
const  Navigation =()=> {
   

    const [menu, setMenu]=useState([] as any)
    const [restore, setRestore]=useState([])  
    const [isDisplay, setIsDisplay] = useState({  } as any); 
   
    const [loading, setLoading] = useState(false);
   
            

                       const fetchNavigation =async()=>{
                        let url = ServerUrl+'/api/navigations/view/'+COMPANY_CODE
                        await  axios.get(url, config).then((result:any)=>{
                          if(Array.isArray(result.data.data) && result.data.data.length!==0){
                            try{
                                setRestore(JSON.parse(result.data.data.map((data:any)=>data.original)))
                                setMenu(JSON.parse(result.data.data.map((data:any)=>data.menu)).sort(compare))
                              
                              }catch(e){
                                //console.log(e)
                              }

                            }
                          })
                          .catch((error)=>{
                              Alerts('danger', error.message)
                          })
                        } 




                   
                             
      
        const  handleChangeMenu=(e:ChangeEvent<HTMLInputElement>, i:number)=>{
        const { name, value } = e.target;
        let newmenu:any = [...menu];
        newmenu[i] = {...newmenu[i], [name]: value};
        setMenu(newmenu);
    }



      const handleToggleDisplay=(id:number)=>{
        setIsDisplay({...isDisplay, [id]: !isDisplay[id]});
    }


    const handleAddSubmenu=(mainIndex:number)=>{
        setMenu(menu.map((list:any, id:number) => 
           mainIndex === id
             ? { ...list, submenu: [...list.submenu, {subName:'',  subUrl:'', subDescription:'', isActive:'Yes', status:true, ID:Math.random().toString(36).substring(2, 9),  
             subsubmenu:[{subsubName: "", status:true, subsubUrl: "", isActive:'Yes', subsubDescription:'', ID:Math.random().toString(36).substring(2, 9)}] }] }
             : list
       )); 
	 }



    const createMenuInterface =()=>{
    
        return menu && menu.map((data:any, index:number) => (
     
            <div key={index} className="row mt-4">
        <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
          <div className="card  shadow-primary">
            <div className="card-body p-4">

                <div className="form-group">
                       <div className="input-group">
                       
            {isDisplay[index] ? <button type="button" onClick={()=>handleToggleDisplay(index)} className="btn btn-secondary btn-sm"> <i className="fa fa-minus"></i></button>:
         <button type="button" onClick={()=>handleToggleDisplay(index)} className="btn btn-primary btn-sm"> <i className="fa fa-plus"></i></button>
                    }
                    
                    &nbsp; 
                 <input type="text" name="menuName" value={data.menuName} onChange={(e)=>handleChangeMenu(e, index)} className="form-control" placeholder="Menu Name" />&nbsp;
                 <input type="text" name="description" value={data.description} onChange={(e)=>handleChangeMenu(e, index)} className="form-control" placeholder="Description" />
                 &nbsp;
                 <input type="text" name="menuUrl" value={data.menuUrl} onChange={(e)=>handleChangeMenu(e, index)} className="form-control" placeholder="Menu Url" />
                 &nbsp;
                 <input type="text" name="menuIcon" value={data.menuIcon} onChange={(e)=>handleChangeMenu(e, index)} className="form-control" placeholder="Menu Icon Class" />&nbsp;
                 <input type="number" name="order" value={data.order} onChange={(e)=>handleChangeMenu(e, index)} className="form-control" placeholder="Order" />&nbsp;&nbsp;
                  <button type="button"  className="btn btn-dark btn-sm" onClick={()=>handleAddSubmenu(index)} > Add Sub Menu</button>&nbsp;
                 <button type="button" onClick={()=>removeMenu(index)} className="btn btn-danger btn-sm delete"><i className="fa fa-trash"></i></button>



                 {data.isActive==='Yes'?
                 <button type="button" onClick={()=>handleChangeMenuToggle('No', index)} className="btn ml-1 btn-success btn-sm "><i className="fa fa-check"></i></button>:
                 <button type="button" onClick={()=>handleChangeMenuToggle('Yes',  index)} className="btn btn-warning ml-1 btn-sm "><i className="fa fa-times"></i></button>}
                 </div>
                 </div>

                 <div className="row">
                <div className="col-sm-1 " ></div>
                <div className="col-sm-10">
                 {/* create submenu here */}
                 {data.submenu && data.submenu.map((smenu:any, i:number)=>{
                             return (   
                                <div  key={i} style={{display: isDisplay[index] ? 'block': 'none'}} >               
           <div  className="form-group">
    <div className="input-group">
               {smenu.status ? <button type="button" onClick={()=>handleToggleSub(smenu.status, i, index)} className="btn btn-secondary btn-sm "> <i className="fa fa-minus"></i></button>:
         <button type="button" onClick={()=>handleToggleSub(smenu.status, i, index)} className="btn btn-primary btn-sm"> <i className="fa fa-plus"></i></button>
                    }&nbsp; 
    
                 <input type="text" name="subName" value={smenu.subName} onChange={(e)=>handleSectionChange(e, i, index)} className="form-control" placeholder="Submenu Name" />&nbsp;

                 <input type="text" name="subUrl" value={smenu.subUrl} onChange={(e)=>handleSectionChange(e, i, index)} className="form-control" placeholder="Submenu Url" />&nbsp;

                 <input type="text" name="subDescription" value={smenu.subDescription} onChange={(e)=>handleSectionChange(e, i, index)} className="form-control" placeholder="Description" />&nbsp;&nbsp;
                 
                  <button type="button"  className="btn btn-dark btn-sm" onClick={()=>handleAddSubSubmenu(i, index)} > Add Sub Submenu</button>&nbsp;
           <button type="button" onClick={()=>removeSubmenu(i, index)} className="btn btn-danger btn-sm delete"><i className="fa fa-trash"></i></button>
           
{smenu.isActive==='Yes'?
                 <button type="button" onClick={()=>handleSectiontoggle('No',  i, index)} className="btn ml-1 btn-success btn-sm "><i className="fa fa-check"></i></button>:
                 <button type="button" onClick={()=>handleSectiontoggle('Yes', i, index)} className="btn btn-warning ml-1 btn-sm "><i className="fa fa-times"></i></button>}
           </div></div>
           
           <div className="row">
                <div className="col-sm-1" > </div>
                 <div className="col-sm-10" >
                 
                 {smenu.subsubmenu && smenu.subsubmenu.map((subsub:any, k:number)=>{
                             return (
                            <div  key={k} style={{display: smenu.status ? 'block': 'none'}} > 
<div className="form-group" >
                       <div className="input-group">
                    
                    &nbsp; 
                 <input type="text" name="subsubName" value={subsub.subsubName} onChange={(e)=>handleSubSubChange(e, k, i, index)} className="form-control" placeholder="Sub submenu Name" />&nbsp;
                 <input type="text" name="subsubUrl" value={subsub.subsubUrl} onChange={(e)=>handleSubSubChange(e, k, i, index)} className="form-control" placeholder="sub submenu Url" />
                 &nbsp;
                 <input type="text" name="subsubDescription" value={subsub.subsubDescription} onChange={(e)=>handleSubSubChange(e, k, i, index)} className="form-control" placeholder="Description" />&nbsp;&nbsp;
                 <button type="button" onClick={()=>removeSubSubMenu(k, i, index)} className="btn btn-danger btn-sm delete"><i className="fa fa-trash"></i></button>
{subsub.isActive==='Yes'?
                 <button type="button" onClick={()=>handleSubSubtoggle('No', k, i, index)} className="btn ml-1 btn-success btn-sm "><i className="fa fa-check"></i></button>:
                 <button type="button" onClick={()=>handleSubSubtoggle('Yes', k, i, index)} className="btn ml-1 btn-warning btn-sm "><i className="fa fa-times"></i></button>}

                 </div>
                 </div></div>
                 )})}

                 </div>
                 <div className="col-sm-1" ></div>
                 </div> 
                 
                  </div>   
                             )})}                         
                 {/* <!-- it end here --> */}
                 </div> 
                 <div className="col-sm-1" ></div>
                 </div>

                 </div>
                 </div>
                {/* sub submenu start here */}



</div></div>   
  ))
    
    }
    
    
   

  const  handleChangeMenuToggle=(value:string, i:number)=>{
  let newmenu = [...menu];
  newmenu[i] = {...newmenu[i], isActive: value};
  setMenu(newmenu);
}


    const  handleSectiontoggle = (value:string, subIndex:number, mainIndex:number) => {
      const newmenu =JSON.parse(JSON.stringify(menu))
       newmenu[mainIndex].submenu[subIndex].isActive = value;      
       setMenu(newmenu); 
      }
      
      const  handleSubSubtoggle = (value:string, subsub:number, subIndex:number, mainIndex:number) => {     
          const newmenu =JSON.parse(JSON.stringify(menu))
           newmenu[mainIndex].submenu[subIndex].subsubmenu[subsub].isActive = value;      
           setMenu(newmenu); 
          }



      const  handleSectionChange = (e:ChangeEvent<HTMLInputElement>, subIndex:number, mainIndex:number) => {
        const { name, value } = e.target;      
        const newmenu =JSON.parse(JSON.stringify(menu))
         newmenu[mainIndex].submenu[subIndex][name] = value;      
         setMenu(newmenu); 
        }
        
        const  handleSubSubChange = (e:ChangeEvent<HTMLInputElement>, subsub:number, subIndex:number, mainIndex:number) => {
            const { name, value } = e.target;      
            const newmenu =JSON.parse(JSON.stringify(menu))
             newmenu[mainIndex].submenu[subIndex].subsubmenu[subsub][name] = value;      
             setMenu(newmenu); 
            }

  		

    const handleRestore =()=>{
        setMenu(restore)
    }
   
    

const  handleToggleSub = (status:string, subIndex:number, mainIndex:number) => {
        const newmenu =JSON.parse(JSON.stringify(menu))
         newmenu[mainIndex].submenu[subIndex].status = !status;      
         setMenu(newmenu); 
        }


	const addMenu =()=>{
        setMenu(menu.concat(
            
        {menuName: "", description: "", menuIcon:'', status:true, isActive:'Yes', menuUrl:'', order:Number(menu.length)+1, ID:Math.random().toString(36).substr(2, 9), 

        submenu:[{subName: "", subUrl: "", status:true, isActive:'Yes', subDescription:'', ID:Math.random().toString(36).substring(2, 9), 

        subsubmenu:[{subsubName: "", subsubUrl: "", status:true, isActive:'Yes', subsubDescription:'', ID:Math.random().toString(36).substring(2, 9)}]
    
    }]
    
    }))
    window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
       
	  }
	  
	 
	 
    const handleAddSubSubmenu=(subIndex:number, mainIndex:number)=>{      
            var newMenu = menu.filter((d:any, i:number) => i === mainIndex)[0]
             var newSubmenu = newMenu.submenu.map((list:any, id:number) => 
            subIndex === id
              ? { ...list, subsubmenu: [...list.subsubmenu, {subsubName: "", status:true, isActive:'Yes', subsubUrl: "", subsubDescription:'', ID:Math.random().toString(36).substr(2, 9)} ]}
              : list
        ); 
            setMenu(menu.map((list:any, id:number) => 
            mainIndex === id
            ? { ...list, submenu: newSubmenu}:list))
     }

  

     const removeSubSubMenu=(k:number, sub:number, main:number)=>{
        var newMenu = menu.filter((d:any, i:number) => i === main)[0]
        var newSubmenu = newMenu.submenu.map((list:any, id:number) => 
       sub === id
         ? { ...list, subsubmenu: list.subsubmenu.filter((o:any, id:number)=>id!==k)}
         : list
   ); 

   setMenu(menu.map((list:any, id:number) => 
   main === id
   ? { ...list, submenu: newSubmenu}:list))
       }

     const removeMenu=(index:number)=>{
        setMenu(menu.filter((d:any, i:number) => i !== index)); 
       }
    

      const removeSubmenu =(sub:number, main:number) =>{
        setMenu(menu.map((list:any, id:number) => 
        main === id
          ? { ...list, submenu: list.submenu.filter((d:any, i:number)=>i!==sub) }
          : list
    )); 
      }




function handleSubmit(){  
    
     LoadingModal() 
   
	const fd = new FormData();

	fd.append("menu", JSON.stringify(menu, null, 2));
    fd.append('company_code', COMPANY_CODE);
   // fd.append('code', 'N'+Math.random().toString(36).substring(2, 9));

  //let url = ServerUrl+'/api/navigations/create_new';

  let url = ServerUrl+'/api/navigations/update_one';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
        SuccessModal(response.data.message)
        window.location.reload()
                 } else{
                   Alerts('danger',response.data.message)
                 }   
             })
  .catch((error)=>{
      Alerts('danger', JSON.stringify(error.message)) 
  }).finally(()=>{   
   fetchNavigation() 
}) 
}


useEffect(()=>{
    fetchNavigation()
},[])
  return ( <>
   <Breadcrumb 
   title="Navigation"
   summary="Create new project"
   >
    <li className="breadcrumb-item">
    <Link to="#!">Setup</Link>
                    </li>
                    <li className="breadcrumb-item">
    <Link to="#!">Configuration</Link>
                    </li>

                    <li className="breadcrumb-item">
    <Link to="#!">Navigation</Link>
                    </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">

    <div className="row">
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h5>Sidebar Navigation Settings</h5>
                    <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="fa fa fa-wrench open-card-option"></i></li>
                            <li><i className="fa fa-window-maximize full-card"></i></li>
                            <li><i className="fa fa-minus minimize-card"></i></li>
                            <li><i className="fa fa-refresh reload-card"></i></li>
                            <li><i className="fa fa-trash close-card"></i></li>
                        </ul>
                    </div>
</div>
<div className="card-block">
        <div className="row">
            <div className="col-md-12">
                <div id="wizard">
                    <section>

                              
                                    
                                    
         <div className="content-wrappers ">
                            
         <div className=''>



    <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Navigations</h5>
                <span>Add all offcie department</span>
              
            </div>
            <div className="card-block">
    
        
                        <div className="row">
                  <div className="col-sm-12 ">
                        <div className="alert alert-default ">
               
               <button type="button" onClick={handleRestore}  id="saveClass" className="btn btn-danger shadow-primary "  >Restore Default</button>&nbsp;&nbsp;
               <button type="button" onClick={handleSubmit}   className="btn btn-success shadow-success"  >Update Navigation</button>
               &nbsp;&nbsp;
               <button type="button" onClick={addMenu}   className="btn btn-dark shadow-dark "  ><i className="fa fa-plus"></i> Add Menu</button>
                               </div>
                               </div>

   </div>

          
    
            </div>
        </div>
    </div>
    </div>
    
    
    


  <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-block">
    

            {createMenuInterface()} 



            </div>
        </div>
    </div>
    </div>


                </div>

        </div>
                       
                    </section>
                </div>
            </div>
        </div>
    </div>
                                    </div>
                                </div>
                            </div>




    

                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default Navigation