import { useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, CURRENCY, STAFF_CODE, ServerUrl, config, selectStyles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, SuccessModal, closeModal, Alerts, CustomLoader } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'



import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';

const  AccessGroup =()=> {
   
const [menu, setMenu]=useState([] as any)
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    
const [group, setGroup] = useState({
	group_name:'',
	description:'',
	status:'Yes',
  id:'',
  code:'',
  isEdit:false
})
const [errors, setErrors] = useState({
  group_name:'',
 
})

   
const fetchData = async()=>{
    setLoading(true)
    let url = ServerUrl+'/api/setup/group/'+COMPANY_CODE
  await  axios.get(url, config).then((result:any)=>{
    if(Array.isArray(result.data.data) && result.data.data.length!==0){
      setContent(result.data.data)
      }
    })
    .catch((error)=>{
        Alerts('danger', error.message)
    }).finally(()=>{
        setLoading(false)
    })
    } 


    const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
      let {name, value} = event.target;	

      if(name==='status'){
          value= value==='Yes'?'No':'Yes'
      }	

      setGroup({...group, [name]:value})
     setErrors({...errors, [name]:''});
   }

   const handleReset=()=>{
    setGroup({
      group_name:'',
      description:'',
      status:'Yes',
      id:'',
      code:'',
      isEdit:false
    })
   }

    const EditItem =(data:any)=>{
      setGroup({
          id:data.id,
          code:data.code,
          group_name:data.group_name,
          description:data.description,
          status:data.status,
          isEdit:true
      });
      let click = document.getElementById('openEdit')?.click()
  
  }




  const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!group.group_name){
    error.group_name =msg;
    formIsValid = false;
} 


if(!formIsValid){
setErrors(error) 
}

if(formIsValid) {

    LoadingModal()
   

    let click = document.getElementById('closeModal')?.click()


    const fd = new FormData();
    let code = Math.random().toString(36).substring(2, 9)

    Object.entries(group).forEach(([key, value]) => {
      fd.append(key, String(value));
  });

  fd.append('roles', menu);
    fd.append('company_code', COMPANY_CODE);
    fd.append('codes', code);
    
    let url = ''
    if(group.isEdit){

      
    url = ServerUrl+'/api/setup/access_group/update';
    }else{
      url = ServerUrl+'/api/setup/access_group/add';
    }


  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     SuccessModal(response.data.message)
   
              } else{
              Alerts('danger', response.data.message)
              }   
          })
          .catch((error)=>{
            Alerts('danger', error.message)
          }).finally(()=>{
        fetchData()
         handleReset()

          })
    }
}


const fetchNavigation =async()=>{
  let url = ServerUrl+'/api/navigations/view/'+COMPANY_CODE
  await  axios.get(url, config).then((result:any)=>{
    if(Array.isArray(result.data.data) && result.data.data.length!==0){
      try{
          setMenu(result.data.data[0].menu)
        }catch(e){
          //console.log(e)
        }
      }
    })
    .catch((error)=>{
        Alerts('danger', error.message)
    })
  } 



  const  updateMenu=(row:any)=>{


    const fd = new FormData();
    fd.append('id', row.id);
   fd.append('code', row.code);
   fd.append('roles', menu);
        
    let url = ServerUrl+'/api/setup/group/update_roles';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){
     
      Alerts('default',response.data.message)
              } else{
                Alerts('danger',response.data.message)
              }   
          })
          .catch((error)=>{
            Alerts('danger',error.message)
          })  
  }




const  handleChangeStatus=(row:any, field:string)=>{


  const fd = new FormData();
 let status = row[field] ==='Yes'?'No':'Yes'

     fd.append('code', row.code);
     fd.append('status', status);
     fd.append('field', field);
     fd.append('id', row.id);
      

  let url = ServerUrl+'/api/setup/group/change_status';
axios.post(url, fd, config)
.then(response =>{
  if(response.data.status === 200){
   
      const newContent = content.map((item:any)=>{
 
          if(item.code === row.code){
          return {...item, 
              [field]:status,
          }
          };
          return item;
          })
          setContent(newContent)
            } else{
              Alerts('danger',response.data.message)
            }   
        })
        .catch((error)=>{
          Alerts('danger',error.message)
        })  
}


    const DeleteModal =(id:number)=>{
      let url = ServerUrl+'/api/setup/destroy/'+id;
    
      Swal.fire({
        title: 'Are you really sure?',
        text: "This is very dangerous, you shouldn't do it!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        showLoaderOnConfirm: true,
      preConfirm: async() => {
        return await axios.get(url, config).then(response=>{
         if(response.data.status!==200){
              throw new Error(response.statusText)
            }
            return response.data
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading(),
        confirmButtonText: 'Yes, I am!'
      }).then((result:any) => {
        if (result.isConfirmed && result.value.status===200) {
         
          let rs =content.filter((item:{id:number})=>item.id !==id)
              setContent(rs)
           Swal.fire(
                      'Deleted!',
                      'Record has been deleted.',
                      'success'
                    ) 
        }
      })
    }

    const TableData =()=>{


      const columns = [
          {
              name: 'Name',
              selector: (row:any) => row.group_name,
              sortable: true,
          },
          {
            name: 'Description',
            selector: (row:any) => row.description,
            sortable: true,
        },
        {
          name: 'Allow Edit',
          cell: (row:any) => <label className="switch-md">
          <input type="checkbox" checked={row.allowEdit==='Yes'?true:false} onChange={()=>handleChangeStatus(row, 'allowEdit')}   />
          <span className="round slider-md"></span>
        </label>,
        width:'120px'
      },
      {
        name: 'Allow Delete',
        cell: (row:any) => <label className="switch-md">
        <input type="checkbox" checked={row.allowDelete==='Yes'?true:false} onChange={()=>handleChangeStatus(row, 'allowDelete')}   />
        <span className="round slider-md"></span>
      </label>,
      width:'120px'
    },
        {
          name: 'Is Active',
          cell: (row:any) => <label className="switch-md">
          <input type="checkbox" checked={row.status==='Yes'?true:false} onChange={()=>handleChangeStatus(row, 'status')}   />
          <span className="round slider-md"></span>
        </label>,
        width:'100px'
      },
          {
              name: 'Action',
              cell: (row:any, index:any, column:any, id:any) => <div className='row'>  
         
	  <button type="button" className="btn btn-success btn-sm" onClick={()=>updateMenu(row)}   ><i className="fa fa-refresh"></i> Reset</button>&nbsp;


    <button type="button" className="btn btn-danger btn-sm" onClick={()=>DeleteModal(row.id)} ><i className="ti-trash"></i> Delete</button> &nbsp;


    <button type="button" className="btn btn-info btn-sm" onClick={()=>EditItem(row)}   ><i className="ti-pencil"></i> Edit</button> &nbsp;
  </div>,
                  left:true
                        
  
          },
      ];
      
    
  
      return <DataTable
      columns={columns}
      data={content}
      dense
      highlightOnHover
      pagination
      selectableRowsHighlight
      striped
      progressPending={loading}
    progressComponent={<CustomLoader />}
  />
  
  }
  


    useEffect(()=>{
        fetchData()
        fetchNavigation()
      }, [])


    

  return ( <>
    <Breadcrumb 
   title="Access Group"
   summary="Create new project"
   >
    <li className="breadcrumb-item">
    <Link to="#!">Account Access</Link>
                    </li>
                    <li className="breadcrumb-item">
    <Link to="#!">Access Group</Link>
                    </li>

                 
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">


<div className="modal fade " id="editItem" tabIndex={-1} role="dialog" data-backdrop="static" >
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header bg-dark">
                    <h4 className="modal-title text-white">Add Group</h4>
                  
    
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i className='fa fa-ban text-danger'></i>
            </button>
    
                </div>
                <div className="modal-body">
                
     <div className="form-group row">
            <label className="col-sm-2 col-form-label">Group Name <span className='text-danger'>*</span></label>
            <div className="col-sm-10">
                <input type="text" className={errors.group_name?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  value={group.group_name} name="group_name" onChange={handleChange} />


                {errors.group_name&&
    <div className="col-form-label has-danger">{errors.group_name&&errors.group_name} </div>}


            </div>  
            
            </div> 

            <div className="form-group row">
            <label className="col-sm-2 col-form-label">Description</label>
            <div className="col-sm-10">
                <input type="text" className="form-control form-control-lg"  value={group.description} name="description" onChange={handleChange} />
            </div>  
            
            </div> 


            <div className="form-group row">
            <label className="col-sm-2 col-form-label">Is Active</label>
            <div className="col-sm-10">
              
            <div className="checkbox-fade fade-in-primary ">
                                    <label className="check-task">
    <input type="checkbox" value={group.status} name="status" checked={group.status ==='Yes'?true:false}  onChange={handleChange} />
    <span className="cr">
    <i className="cr-icon fa fa-check txt-default"></i>
    </span> 
</label>
                                </div>
            </div>  
            
            </div> 



                </div>
                <div className="modal-footer">
                    <button type="button" id='closeModal' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>

                    <button type="button" className="btn btn-outline-success " onClick={handleSubmit}  >{group.isEdit?'Update': 'Save'}</button>
                </div>
            </div>
        </div>
    </div>



                        <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-header">
                <h5>Access Group</h5>
                <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
              
                <div className="card-header-right">

        <a href='#!' data-toggle="modal" id='openEdit' data-target="#editItem" className="btn btn-primary btn-sm "><i className='fa fa-circle-plus text-white'></i> Add New Group</a>
        </div>

            </div>
            <div className="card-block">
    
            <div className="table-responsive m-t-10">
        <TableData />
        </div>
    
            </div>
        </div>
    </div>
    </div>


            </div>
        </div>


    </div>
</div>  
        </>     
  )
}

export default AccessGroup