import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo} from 'react'
import { COMPANY_CODE, ImagesUrl, STAFF_CODE, ServerUrl, config, selectStyles } from '../../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal, CustomLoader, closeModal } from '../../../components/notify';
import Select, { components } from 'react-select'
import { fetchEmployee, fetchCountry, handleFetchState } from '../../../api/ReactQuery';
import { useQuery } from 'react-query'
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'

const Experience =({code}:{code:any})=> {

    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState([] as any);
    const [contact, setContact] = useState([] as any);
    const {data} = useQuery(["employee", code], fetchEmployee)
    const [errors, setErrors] = useState({
        company:'',
        job_title:''
     })
    const [profile, setProfile] = useState({
        company:'',
        job_title:'',
        start_date:'',
        end_date:'',
        responsibility:'',
        code:Math.random().toString(36).substring(2, 9),
        date: new Date()
    });



    const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	
        setProfile({...profile, [name]:value});
       setErrors({...errors, [name]:''});
     }



 const handleChangeExtra =(event:ChangeEvent<HTMLTextAreaElement>)=>{
        let {name, value} = event.target;	
        setProfile({...profile, [name]:value});
       setErrors({...errors, [name]:''});
     }

   
        const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
            event.preventDefault();
        
            let error = {...errors}; 
            let formIsValid = true;
        
        let msg ='This field is required';
        

        if(!profile.company){
            error.company =msg;
            formIsValid = false;
        } 
        
        
        if(!profile.job_title){
            error.job_title =msg;
            formIsValid = false;
        } 
        

        if(!formIsValid){
        setErrors(error) 
        }
        
        
        if(formIsValid) {
           LoadingModal()

                const emergency =  JSON.parse(content[0].work_history)
                const contacts = emergency.concat(profile)

                const log = JSON.parse(content[0].update_log)
                const item = log.concat( {
                    code:STAFF_CODE,
                    action:'Work History: Added onework history',
                    date_time: new Date()
                })
    
             const logs = JSON.stringify(item,null, 2)


                const newProfile = content.map((item:any)=>{
                const work_history = JSON.stringify(contacts,null, 2)


                    return {...item, 
                        work_history:work_history
                    }
                  })
                  
                  setContent(newProfile)
                  setContact(contacts)

                      const fd = new FormData();
               fd.append('logs', logs);
                Object.entries(newProfile[0]).forEach(([key, value]) => {
                                    fd.append(key, String(value));
                                }); 

        let click = document.getElementById('close')?.click();

           let url = ServerUrl+'/api/employee/update/'+code;
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status === 200){
             SuccessModal(response.data.message)
           
                      } else{
                        ErrorModal('', response.data.message)
                      }   
                  })
                  .catch((error)=>{
                   ErrorModal('oops', error.message)
                  }).finally(()=>{

                    setProfile({
                        company:'',
                        job_title:'',
                        start_date:'',
                        end_date:'',
                        responsibility:'',
                        code:Math.random().toString(36).substring(2, 9),
                        date: new Date()
                    })

                  })  
            }
        
        }



const DeleteModal =(code:string)=>{
    
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading(),
    confirmButtonText: 'Yes, I am!',

    preConfirm: async() => {

const newContact = contact.filter((item:any)=>item.code !==code)



const log = JSON.parse(content[0].update_log)
const item = log.concat( {
    code:STAFF_CODE,
    action:'Work History: Removed one work history',
    date_time: new Date()
})

const logs = JSON.stringify(item,null, 2)
const newProfile = content.map((item:any)=>{
    return {...item, 
        work_history:JSON.stringify(newContact,null, 2)
    }
  })
  
  setContent(newProfile)
  setContact(newContact)

      const fd = new FormData();
fd.append('logs', logs);
Object.entries(newProfile[0]).forEach(([key, value]) => {
                    fd.append(key, String(value));
                }); 

let url = ServerUrl+'/api/employee/update/'+code;
return await axios.post(url, fd, config)
.then(response =>{

if(response.data.status !== 200){
    throw new Error(response.statusText)

      } else{
        return response.data
      }   
  })
  .catch((error)=>{
    Swal.showValidationMessage(
        `Request failed: ${error}`
      )
  })
    }
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }




        const TableData =()=>{

        
            const columns = [
                {
                    name: 'Company',
                    cell: (row:any) => row.company, 
                    sortable: true,
                },
                {
                    name: 'Job Title',
                    selector: (row:any) => row.job_title,
                    sortable: true,
                },
                {
                    name: 'Duration',
                    cell: (row:any) => row.start_date + ' to  ' + row.end_date,
                    sortable: true,
                    wrap:false
                },
                {
                    name: 'Responsibility',
                    selector: (row:any) => row.responsibility,
                    sortable: true,
                    wrap:true
                },
                {
                    name: 'Action',
                    cell: (row:any, index:any, column:any, id:any) => <div className='row'>  
                        <label className="label label-info pointer"  onClick={()=>EditItem(row)} ><i className='ti-pencil'></i> Edit</label>
                    <label className="label label-danger pointer" onClick={()=>DeleteModal(row.code)}><i className='ti-trash'></i> Delete</label></div>,
                        left:true
                              
        
                },
            ];
            
        
          
        
            return <DataTable
            columns={columns}
            data={contact}
            dense
            highlightOnHover
            pagination
            selectableRowsHighlight
            striped
            progressPending={loading}
            progressComponent={<CustomLoader />}
        />
        
        }


        const handleUpdate =(event: FormEvent<HTMLButtonElement>)=>{
            event.preventDefault();
        
            let error = {...errors}; 
            let formIsValid = true;
        
        let msg ='This field is required';
        

        if(!profile.company){
            error.company =msg;
            formIsValid = false;
        } 
        
        
        if(!profile.job_title){
            error.job_title =msg;
            formIsValid = false;
        } 
        

        if(!formIsValid){
        setErrors(error) 
        }
        
        
        if(formIsValid) {
           LoadingModal()

              
                const newEmergency = contact.map((item:any)=>{
                   
                    if(item.code ===profile.code){
                        return profile
                    }
    
                     return item
                      })


                const log = JSON.parse(content[0].update_log)
                const item = log.concat( {
                    code:STAFF_CODE,
                    action:'Work Experience: Updated one work Experience',
                    date_time: new Date()
                })
    
             const logs = JSON.stringify(item,null, 2)


                const newProfile = content.map((item:any)=>{
                const work_history = JSON.stringify(newEmergency,null, 2)


                    return {...item, 
                        work_history:work_history
                    }
                  })
                  
                  setContent(newProfile)
                  setContact(newEmergency)

                      const fd = new FormData();
               fd.append('logs', logs);
                Object.entries(newProfile[0]).forEach(([key, value]) => {
                                    fd.append(key, String(value));
                                }); 

        let click = document.getElementById('closeEdit')?.click();

           let url = ServerUrl+'/api/employee/update/'+code;
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status === 200){
             SuccessModal(response.data.message)
           
                      } else{
                        ErrorModal('', response.data.message)
                      }   
                  })
                  .catch((error)=>{
                   ErrorModal('oops', error.message)
                  }).finally(()=>{

                    setProfile({
                        company:'',
                        job_title:'',
                        start_date:'',
                        end_date:'',
                        responsibility:'',
                        code:Math.random().toString(36).substring(2, 9),
                        date: new Date()
                    })

                  })  
            }
        
        }


        const EditItem =(data:any)=>{
            setProfile({
                code:data.code,
                company:data.company,
                job_title:data.job_title,
                start_date:data.start_date,
                end_date:data.rend_date,
                date:data.date,
                responsibility:data.responsibility
            });
            setErrors({
                company:'',
               job_title:'',
            })
            let click = document.getElementById('openEdit')?.click()
        
        }

        const handleReset =()=>{
            setProfile({
                company:'',
                job_title:'',
                start_date:'',
                end_date:'',
                responsibility:'',
                code:Math.random().toString(36).substring(2, 9),
                date: new Date()
            })
            let click = document.getElementById('openAdd')?.click()
        }

useEffect(()=>{

   try{

    if (data!==undefined && data.length!==0){
    setContent(data)
    setContact(JSON.parse(data[0].work_history))
    window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
         
    }

   }catch(err) {

   }
    
    }, [data])

  return (                             
    <div>
    
     
    <div className="card z-depth-0">
    
    <div className="card-header">
    <h5>Work Experience</h5>
    
    <div className="card-header-right">

                   


<label className="label label-primary pointer " onClick={handleReset}> <i className='fa fa-plus text-white'></i> Add History</label>
<label  id='openAdd' data-toggle="modal" data-target="#large-Modal"> </label>  


    </div>
    
    </div>
    
    



    <label data-toggle="modal" data-target="#editEducation" id='openEdit' ></label>

    
    <div className="modal fade push-modal-right" id="editEducation" tabIndex={-1} role="dialog" data-backdrop="static" >
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header bg-dark">
                    <h4 className="modal-title text-white">Add Work History</h4>
                  
    
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i className='fa fa-ban text-danger'></i>
            </button>
    
                </div>
                <div className="modal-body">
                <div className="row">
   
    
    
    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Organization <span className='text-danger'>*</span></label>
    
    <input type="text" className={errors.company?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}   name='company' value={profile.company} onChange={handleChange} />
        
        {errors.company&&
        <div className="col-form-label has-danger">{errors.company&&errors.company} </div>}
    
    </div>
    
    </div>
    
    
    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Job Title <span className='text-danger'>*</span></label>
    
    <input type="text" className={errors.job_title?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}   name='job_title' value={profile.job_title} onChange={handleChange} />
        
        {errors.job_title&&
        <div className="col-form-label has-danger">{errors.job_title&&errors.job_title} </div>}
    
    </div>
    
    </div>
    
    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Start Date</label>
    
    <input type="date" className="form-control form-control-lg"   name='start_date' value={profile.start_date} onChange={handleChange} />

    
    </div>
    
    </div>
    
    
    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">End Date</label>

    <input type="date" className="form-control form-control-lg"   name='end_date' value={profile.end_date} onChange={handleChange} />
    
    </div>
    
    </div>
    
    
    <div className="col-md-12">
    <div className="form-group">
    <label className="col-form-label">Responsibilities</label>
    
    <textarea className="form-control " name='responsibility'
     value={profile.responsibility} rows={5} onChange={handleChangeExtra} >
        {profile.responsibility}
        </textarea>
    
    </div>
    
    </div>
    
    
    
    </div>
                </div>
                <div className="modal-footer">

                    <button type="button" className="btn btn-default waves-effect " data-dismiss="modal" id="closeEdit">Close</button>

                    <button type="button" className="btn btn-outline-success " onClick={handleUpdate} >Update</button>
                </div>
            </div>
        </div>
    </div>






    <div className="modal fade push-modal-right" id="large-Modal" tabIndex={-1} role="dialog" data-backdrop="static" >
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header bg-dark">
                    <h4 className="modal-title text-white">Add Work History</h4>
                  
    
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i className='fa fa-ban text-danger'></i>
            </button>
    
                </div>
                <div className="modal-body">
                <div className="row">
   
    
    
    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Organization <span className='text-danger'>*</span></label>
    
    <input type="text" className={errors.company?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}   name='company' value={profile.company} onChange={handleChange} />
        
        {errors.company&&
        <div className="col-form-label has-danger">{errors.company&&errors.company} </div>}
    
    </div>
    
    </div>
    
    
    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Job Title <span className='text-danger'>*</span></label>
    
    <input type="text" className={errors.job_title?"form-control form-control-lg form-control-danger":"form-control form-control-lg"}   name='job_title' value={profile.job_title} onChange={handleChange} />
        
        {errors.job_title&&
        <div className="col-form-label has-danger">{errors.job_title&&errors.job_title} </div>}
    
    </div>
    
    </div>
    
    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Start Date</label>
    
    <input type="date" className="form-control form-control-lg"   name='start_date' value={profile.start_date} onChange={handleChange} />

    
    </div>
    
    </div>
    
    
    <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">End Date</label>

    <input type="date" className="form-control form-control-lg"   name='end_date' value={profile.end_date} onChange={handleChange} />
    
    </div>
    
    </div>
    
    
    <div className="col-md-12">
    <div className="form-group">
    <label className="col-form-label">Responsibilities</label>
    
    <textarea className="form-control " name='responsibility'
     value={profile.responsibility} rows={5} onChange={handleChangeExtra} >
        {profile.responsibility}
        </textarea>
    
    </div>
    
    </div>
    
    
    
    </div>
                </div>
                <div className="modal-footer">

                    <button type="button" className="btn btn-default waves-effect " data-dismiss="modal" id="close">Close</button>

                    <button type="button" className="btn btn-primary waves-effect waves-light"  onClick={handleSubmit}>Save History</button>
                </div>
            </div>
        </div>
    </div>
    
    
    
    
    
    <div className="card-block">
    
    
    <div className="table-responsive m-t-10">
        <TableData />
     
    </div>
    
    </div>
    </div>
    </div>
                  
 
  )
}

export default Experience