import { useEffect, useState, FormEvent} from 'react'
import { ImagesUrl, ServerUrl, config } from '../../components/Includes';
import axios from 'axios';
import { ErrorModal, LoadingModal, SuccessModal } from '../../components/notify';

const Photos =(props:any)=> {

    
    const [content, setContent] = useState(props.settings);

    const [loading, setLoading] = useState(false);

    const [profile, setProfile] = useState({
        logo:'',
        logo_url:'',
        banner:'',
        banner_url:'',
        display_logo:'',
        display_banner:'',
    });

    const [errors, setErrors] = useState({
        logo:'',
        banner:'',
        error_message:''

    })

    const  handleFile = function(fieldName:any){
        return function(newValue:any){                 
       readURL(newValue);
       }
      } 
    

    
    const readURL = (input:any)=>{
     
    let displayMessage = '';
    
    const PreviewImage = input.target.name;
    const inputElement = document.getElementById(PreviewImage) as HTMLInputElement
    const fileName = inputElement.value
    
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    
    if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 1) {
        displayMessage = ' File uploaded is larger than maximum size Allow (500 kb).';
        inputElement.value = '';
        if(PreviewImage==='banner'){
        setProfile({...profile, [PreviewImage]:'', display_banner:''})
        }else{
            setProfile({...profile, [PreviewImage]:'', display_logo:''}) 
        }
    
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
            var reader = new FileReader();//Initialize FileReader.
            reader.onload = function (e:any) {
                
            //Check if input has files.
            let Id = Math.random().toString(36).substr(2, 9)
             let fileUrl = Id+'.png'
    
              setErrors({...errors, [PreviewImage]:'', error_message:''}); 

              if(PreviewImage==='banner'){
                setProfile({...profile,  
                    banner:input.target.files[0],
                    banner_url:fileUrl,
                    display_banner:e.target.result
                
                })

            }else{
                setProfile({...profile,  
                   logo:input.target.files[0],
                   logo_url:fileUrl,
                    display_logo:e.target.result
                
                })
            }

    
            }
            reader.readAsDataURL(input.target.files[0]);
            
        }
    }
    }else{
    displayMessage = 'Only JPEG|PNG|JPG  file format are allowed with maximum of 500 kb'
    inputElement.value = '';
    if(PreviewImage==='banner'){
        setProfile({...profile, [PreviewImage]:'', display_banner:''})
        }else{
            setProfile({...profile, [PreviewImage]:'', display_logo:''}) 
        }
    }   
    
    if(displayMessage.length > 0){
    //ErrorModal("", displayMessage)
    setErrors({...errors, error_message:displayMessage})
    }
    }

    const handleSubmitPassport =(event: FormEvent<HTMLButtonElement>)=>{
        event.preventDefault();

        let error = {...errors}; 
        let formIsValid = true;
    
    let msg ='This field is required';
    
    if(!profile.logo){
        error.logo =msg;
        error.error_message = "Please select logo"
        formIsValid = false;
    } 


    if(!formIsValid){
        setErrors(error) 
        }
        
        
        if(formIsValid) {

            LoadingModal()
         const fd = new FormData();
        

         if(profile.logo){
            fd.append('imageFile', profile.logo, profile.logo_url); 
        } 

        fd.append('code', content.code);
        fd.append('id', content.id);
        fd.append('passport_url', profile.logo_url);
        
           let url = ServerUrl+'/api/settings/change_logo'
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status === 200){
             SuccessModal(response.data.message)
           
                      } else{
                        ErrorModal('', response.data.message)
                      }   
                  })
                  .catch((error)=>{
                   ErrorModal('oops', error.message)
                  }) 
            }

    }

useEffect(()=>{

    try{
 
     if (props.settings!==undefined && props.settings.length!==0){
     setContent(props.settings)
     }
 
    }catch(err) {
 
    }
     
     }, [props])


    const handleSubmitBanner =(event: FormEvent<HTMLButtonElement>)=>{
        event.preventDefault();

        let error = {...errors}; 
        let formIsValid = true;
    
    let msg ='This field is required';
    
    if(!profile.banner){
        error.banner =msg;
        error.error_message = "Please select banner"
        formIsValid = false;
    } 


    if(!formIsValid){
        setErrors(error) 
        }
        
        
        if(formIsValid) {

            LoadingModal()
            const fd = new FormData();
           

            if(profile.banner){
               fd.append('imageFile', profile.banner, profile.banner_url); 
           } 
   
   
           fd.append('id', content.id);
           fd.append('banner_url', profile.banner_url);
           
              let url = ServerUrl+'/api/settings/change_banner'
             axios.post(url, fd, config)
             .then(response =>{
               if(response.data.status === 200){
                SuccessModal(response.data.message)
              
                         } else{
                           ErrorModal('', response.data.message)
                         }   
                     })
                     .catch((error)=>{
                      ErrorModal('oops', error.message)
                     }) 

        }

    }




    const handlePhotBanner=()=>{
        var doc = document.getElementById('banner')
        doc?.click()
    }
    const handlePhoto=()=>{
        var doc = document.getElementById('logo')
        doc?.click()
    }

  


  return (
<div className="row">
                            <div className="col-md-6">
                                <div className="card b-l-success business-info services m-b-20 " >
                                    <div className="card-header">
                                        <div className="service-header">
                                            <a href="#">
                                                <h5 className="card-header-text"  >Logo (Click to Change)</h5>
                                            </a>
                                        </div>
                                        <span className="dropdown-toggle addon-btn text-muted f-right service-btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" role="tooltip">
                                        </span>
                                        <div className="dropdown-menu dropdown-menu-right b-none services-list">
                                            <a className="dropdown-item" href="#!"><i className="icofont icofont-edit"></i> Edit</a>
                                            <a className="dropdown-item" href="#!"><i className="icofont icofont-ui-delete"></i> Delete</a>
                                            <a className="dropdown-item" href="#!"><i className="icofont icofont-eye-alt"></i> View</a>
                                        </div>
                                    </div>
                                    <div className="card-block">
                                        <div className="row">
                                            <div className="col-sm-12">
                                               
<input id="logo"  onChange={handleFile('logo')} className="d-none" name="logo" type="file" accept="image/*" />              

{profile.display_logo?
<img id="viewPassport2" onClick={handlePhoto} 
className='img-100  pointer ' 
           onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/logo.png'}} 
           src={profile.display_logo} alt="preview" /> : 
           
           <img 
           src={ImagesUrl+'/about/'+content.logo}  alt="Click to change" 
           className='img-100  pointer' 
           onClick={handlePhoto} onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/logo.png'}} /> }  
<br/>           

           
      <label className="col-form-label text-danger">Accepts jpg, .png, .gif up to 500 kB. Recommended dimensions: 200px X 200px</label>
                                            </div>
                                          
                                        </div>
                             
                                    </div>
                            
                                </div>

                                <button className="btn btn-success active btn-round pl-5 pr-5 pt-2 pb-2" type='button' onClick={handleSubmitPassport}>Save </button>
                            </div>
                            <div className="col-md-6">
                                <div className="card b-l-danger business-info services" >
                                    <div className="card-header">
                                        <div className="service-header">
                                            <a href="#">
                                                <h5 className="card-header-text"  >Banner (Click to Change)</h5>
                                            </a>
                                        </div>
                                        <span className="dropdown-toggle addon-btn text-muted f-right service-btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" role="tooltip">
                                        </span>
                                        <div className="dropdown-menu dropdown-menu-right b-none services-list">
                                            <a className="dropdown-item" href="#!"><i className="icofont icofont-edit"></i> Edit</a>
                                            <a className="dropdown-item" href="#!"><i className="icofont icofont-ui-delete"></i> Delete</a>
                                            <a className="dropdown-item" href="#!"><i className="icofont icofont-eye-alt"></i> View</a>
                                        </div>
                                    </div>








                                    <div className="card-block">
                                        <div className="row">
                                            <div className="col-sm-12">
                                               
<input id="banner"  onChange={handleFile('banner')} className="d-none" name="banner" type="file" accept="image/*" />              

{profile.display_banner?
<img id="viewPassport" onClick={handlePhotBanner} 
className='banner-image  pointer ' 
           onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/no.jpeg'}} 
           src={profile.display_banner} alt="preview" /> : 
           
           <img 
           src={ImagesUrl+'/about/'+content.banner}  alt="Click to change" 
           className='banner-image  pointer ' 
           onClick={handlePhotBanner} onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/no.jpeg'}} /> }  
<br/>           

           
      <label className="col-form-label text-danger">Accepts jpg, .png, .gif up to 1 Mb. Recommended dimensions: 200px X 500px</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <button className="btn btn-success active btn-round pl-5 pr-5 pt-2 pb-2" type='button' onClick={handleSubmitBanner}>Save </button>


                            </div>
                           
                           
                        </div>
  )
}

export default Photos