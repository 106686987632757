import { useEffect, useState} from 'react'
import { COMPANY_CODE, ImagesUrl, ServerUrl, config, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, closeModal, Alerts } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'


import Swal from 'sweetalert2'
import { useQuery } from 'react-query'
import Select from 'react-select'
import { fetchDepartment, FetchProfile} from '../../api/ReactQuery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertDate, getBritishDate } from '../../components/GlobalFunction';
import { Link } from 'react-router-dom';

const Financial =()=> {
  const [staff, setStaff] = useState([] as any);
  const [assets, setAssets] = useState([] as any);
    const [content, setContent] = useState([] as any);
    const profile = useQuery(["profile"], FetchProfile)
    const [search, setSearch] = useState({
     
      asset_name:[] as any,
     })

    
           const [errors, setErrors] = useState({
            start_date:'',
            end_date:''
           
         })

       

              



         const fetchContent = async()=>{
         LoadingModal()

         let sql = "SELECT a.ID, e.first_name, e.last_name, a.code, a.barcode, a.asset_name, a.assign_to, (select sum(r.cost) from tbl_repair_asset r where r.asset_code = a.code) as repairs, a.life_span, a.purchase_date, a.purchase_amount from tbl_assets a left join tbl_employee e on a.assign_to = e.code"


   

               const fd = new FormData();
               fd.append('sql', sql); 
               let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
             await  axios.post(url, fd, config).then((result:any)=>{

               if(Array.isArray(result.data.data) && result.data.data.length!==0){
                
                 setContent(result.data.data)

                 }else{

                  setContent([])
                 }
               })
               .catch((error)=>{
                   Alerts('danger',error.message)
               }).finally(()=>{
                 closeModal()
               })
               }


useEffect(()=>{
  fetchContent()
}, [])

  return ( <>
    <Breadcrumb 
   title="Financail"
   summary="Asset Finance History"
   >
    <li className="breadcrumb-item">
    <Link to="/assets/display_assets">Assets</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Report</a> </li>
         <li className="breadcrumb-item"><a href="#!">Financial</a> </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
<div className="main-body">
<div className="page-wrapper">
<div className="page-body">








{content.length!==0?
  <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-block">
    

    <div className="row">
        <div className="table-responsive ">
       
        <table className="table table-sm table-bordered table-hover">
                <thead>
                    <tr>
                        <th colSpan={6} className='text-center '>

                        <img 
           src={ImagesUrl+'/about/'+profile.data.logo}  alt="Click to change" 
           className="m-b-10 img-100" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/logo.png'}} />


                        <h4>{profile.data.company_name} </h4>
                        <h5>{profile.data.company_address}</h5>
                        <h6 className='m-b-5'>{profile.data.email}, {profile.data.phone}</h6>

                        <div className="alert alert-info text-center mb-1 mt-2">

                        <h5> ASSET FINANCE HISTORY</h5>
                        </div>
                        </th>
                    </tr>
                  


                    <tr style={{backgroundColor:'#E7E0EE'}}>
                <th >S/N</th>
                    <th  >Assign To</th>
                    <th>Asset</th>
                    <th>Purchase Date</th>
                    <th>Life Span</th>
                    <th>Cost of Repairs</th>
                </tr>

                </thead>
                <tbody>
                    {content&&content.map((st:any, index:number)=>
                    <tr key={index}>
                        <td >{index+1}</td>
                        <td >{st.first_name +' '+st.last_name}</td>
                        <td>{st.asset_name}</td>
                        <td>{st.purchase_date}</td> 
                        <td>{st.life_span}</td> 
                        <td>{st.repairs}</td> 
                </tr>)} 
                          

                </tbody>

                   
            </table>

        </div>


        <div className="row">
        <div className="col-md-12">
        <button type="button" className="btn btn-success  no-print" > <i className="fa fa-print"></i> Print</button>
        </div></div>


        </div>

            </div>
        </div>
    </div>
    </div>:[]}

</div>
</div>


</div>
</div>  
      </>               
 
  )
}

export default Financial