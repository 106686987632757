
export  const ValidateIsOpen =(start:any, end:any)=>{

  var begin = new Date(start);
  var expire = new Date(end);
  var current= new Date();
  
  var msStart:any = Date.UTC(begin.getFullYear(), begin.getMonth()+1, begin.getDate(), begin.getHours(), begin.getMinutes()); 
  var msExpire:any = Date.UTC(expire.getFullYear(), expire.getMonth()+1, expire.getDate(), expire.getHours(), expire.getMinutes()); 
  var msNow:any = Date.UTC(current.getFullYear(), current.getMonth()+1, current.getDate(), current.getHours(), current.getMinutes()); 
  
  if (parseFloat(msStart) <= parseFloat(msNow) && parseFloat(msExpire) >= parseFloat(msNow)) 
        return true;  // start date is less than today and expiredate is greater than today
      else if (parseFloat(msStart) === parseFloat(msExpire)) 
        return false;  // same date & time choosing 
      else if ( !parseFloat(msStart) && !parseFloat(msExpire)) 
        return false;  // gt 
      else 
        return false;
  
  }



export const getFileSize =(bytes:number)=> {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    const i = Math.min(Math.floor(Math.log(bytes) / Math.log(1024)), sizes.length - 1);
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
}


export const  compare=(a:any,b:any)=>{
  let result = 0;
  if(Number(a.order)>Number(b.order)){
      result = 1
  }else if (Number(a.order)<Number(b.order)){
      result = -1
  }
  return result
  }


export const convertDateTime=(date:any)=>{
 
    return new Date(date).toISOString().slice(0,19)
  }

 

  export const convertDate =(date:any)=>{
            var day:any = date.getDate();
            var month:any = date.getMonth() + 1;
            var year = date.getFullYear();
            
            if (month < 10) month = "0" + month;
            if (day < 10) day = "0" + day;
            
            var today = year + "-" + month + "-" + day; 
  
            return today
  }


export const FormatNumber = (num:Number)=>{
    let newNumber = Number(num)
    return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


export const getMonthYear=(dateString:string)=>{
    var months = ['January', 'February', 'March', 
                 'April', 'May', 'June', 'July', 
                 'August', 'September', 'October', 'November', 'December'];
    
var splitDate = dateString.split('-');
var month = Number(splitDate[1])-1; //Javascript months are 0-11


   var nDate =  months[month] +', '+ splitDate[0]
   return nDate
  }


  export const getMonth=(dateString:string)=>{
    var months = ['January', 'February', 'March', 
                 'April', 'May', 'June', 'July', 
                 'August', 'September', 'October', 'November', 'December'];
    
var splitDate = dateString.split('-');
var month = Number(splitDate[1])-1; //Javascript months are 0-11

   return months[month]
  }


export const toWords = (n:any = 0) => {


  const Ones  = ["","One","Two","Three","Four","Five","Six","Seven","Eight","Nine","Ten",
  "Eleven","Twelve","Thirteen","Fourteen","Fifteen","Sixteen","Seventeen","Eighteen","Nineteen"],
Tens  = ["","","Twenty","Thirty","Forty","Fifty","Sixty","Seventy","Eighty","Ninety","Hundred"],
Scale = ["","Thousand","Million","Billion","Trillion","Quadrillion","Quintillion","Sextillion"];
//==================================


if (n == 0) return "Zero";  
    // check for zero
n = ("0".repeat(2*(n+="").length % 3) + n).match(/.{3}/g);   // create triplets array

if (n.length > Scale.length) return "Too Large";             // check if larger than scale array

let out= ""; 
return n.forEach((Triplet:any,pos:any) => {   
            // loop into array for each triplet
if (+Triplet) { 
  out+=' ' +(+Triplet[0] ? Ones[+Triplet[0]]+' '+ Tens[10] : "") +
' ' + (+Triplet.substr(1)< 20 ? Ones[+Triplet.substr(1)] :
Tens[+Triplet[1]] + (+Triplet[2] ? "-" : "") + Ones[+Triplet[2]]) +
' ' +  Scale[n.length-pos-1];
 }
}), out.replace(/\s+/g,' ').trim();

}; 


export const getNumWorkDays =(startDate:any, endDate:any)=>{
   
  var numWorkDays = 0;
  const theDate = new Date(startDate)
  const theEnd =  new Date(endDate)
  while (theDate <= theEnd){

       // Skips Sunday and Saturday
       if (theDate.getDay() !== 0 && theDate.getDay() !== 6) {
          numWorkDays++;
      }
      theDate.setDate(theDate.getDate()+1)
  }
  return numWorkDays 
}

export const convertTime =(date:any)=>{
  const theDate:any = new Date(date)
return theDate.toLocaleTimeString().replace('AM','').replace('PM','');
}




const unitlist = ['', 'K', 'M', 'B']
export function abbreviate (number: number) {
  const sign = Math.sign(number)
  let unit = 0

  while (Math.abs(number) > 1000) {
    unit = unit + 1
    number = Math.floor(Math.abs(number) / 100) / 10
  }
  return sign * Math.abs(number) + unitlist[unit]
}



export const getTime =(value:any)=>{
  var timeString = String(value)
 var hourEnd = timeString.indexOf(":");
 var H:any = timeString.substr(0,2);
 var h:any = H % 12 || 12;
 var ampm =  (H<12 || H===24)?"AM":"PM";
  if (h < 10) h = "0" + h
 var result = h + timeString.substr(hourEnd, 3)+" "+ampm


return result;
}

export const getRank=(i:number)=> {
  var j = i % 10,
      k = i % 100;
  if (j == 1 && k != 11) {
      return  "ST";
  }
  if (j == 2 && k != 12) {
      return  "ND";
  }
  if (j == 3 && k != 13) {
      return  "RD";
  }
  return  "TH";
}

export const getBritishDate=(dateString:any)=>{
  var months = ['January', 'February', 'March', 
  'April', 'May', 'June', 'July', 
  'August', 'September', 'October', 'November', 'December'];

var splitDate = dateString.split('-');
var month = Number(splitDate[1])-1; //Javascript months are 0-11
var surfix = getRank(splitDate[2]).toLowerCase()

let newDate = parseInt(splitDate[2])+surfix+ ' '+ months[month]+ ', '+splitDate[0]

return newDate
}

export const shortText = function(text:string, limit:number){
  if (text.length > limit){
      for (let i = limit; i > 0; i--){
          if(text.charAt(i) === ' ' && (text.charAt(i-1) !== ','||text.charAt(i-1) !== '.'||text.charAt(i-1) !== ';')) {
              return text.substring(0, i) + '...';
          }
      }
       return text.substring(0, limit) + '...';
  }
  else
      return text;
};