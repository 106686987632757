import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent} from 'react'
import { COMPANY_CODE, CURRENCY, ImagesUrl, STAFF_CODE, ServerUrl, config, styles } from '../../components/Includes';
import axios from 'axios';
import { Alerts, CustomLoader, LoadingModal, SuccessModal, closeModal } from '../../components/notify';
import Select from 'react-select'
import Breadcrumb from '../../components/Breadcrumb'
import { useQuery } from 'react-query'
import { usePaginated, DOTS } from '../../components/usePagination';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { FormatNumber, getBritishDate, getRank, shortText } from '../../components/GlobalFunction';
import { fetchDepartment, fetchJobs } from '../../api/ReactQuery';

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction'

import { useParams } from 'react-router'
import { Link } from 'react-router-dom';
const  ProjectDetails =()=> {
   
  let params = useParams();

  const [staff, setStaff] = useState([] as any);
   
    const [search, setSearch] = useState({
        
        rowperpage:{value:'10', label:'10'},
        filterText:'',
        selectAll:false,
        totalSelected:0,
     })

     const department = useQuery(["department"], fetchDepartment)

    const [filterContent, setFilterContent] = useState([] as any);
    const [content, setContent] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
         
         client_name:'',
         email_address:''
       
     })

  const fetchStaff =async()=>{
    let url = ServerUrl+'/api/employees/view/'+COMPANY_CODE
    const {data} = await axios.get(url, config)
    setStaff(data.data)
    }

     



  const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	
    setSearch({...search, [name]:value});

    if(value!==''){
        const filteredItems = content.filter(
            (item:any) => item.client_name.toLowerCase().includes(value.toLowerCase()) || item.title.toLowerCase().includes(value.toLowerCase()) ||
            item.work_station.toLowerCase().includes(value.toLowerCase()),
        );

        setFilterContent(filteredItems)
    }else{
        setFilterContent(content)
    } 

 }


const handleClear =()=>{
setSearch({...search, filterText:''});
setFilterContent(content)
}


const getTitleName = (code:string, field:string) =>{ 
  const result = staff&&staff.filter((list:any)=>list.code===code);
 const answer = result.length !==0?result[0][field]:''
   return  answer;
 }


  

 const getTeam=(temp:string)=>{

  try{
  if(temp!=='[]'){
 let value:any = [];
JSON.parse(temp).map((itm:any, idx:number)=>{
      value.push({
        code:itm.staff_code, 
         passport:getTitleName(itm.staff_code, 'passport'), 
      gender:getTitleName(itm.staff_code, 'gender'),
      first_name:getTitleName(itm.staff_code, 'first_name'), 
      last_name:getTitleName(itm.staff_code, 'last_name'), 
      middle_name:getTitleName(itm.staff_code, 'middle_name'),
      work_station:getTitleName(itm.staff_code, 'work_station'), 
      work_email:getTitleName(itm.staff_code, 'work_email'),
      telephone:getTitleName(itm.staff_code, 'telephone'),
      title:getTitleName(itm.staff_code, 'title'), key:idx});
  } );
return value;
}
else{
return []
}
}catch(err){
      
}
}





const handleDateClick = (arg:any) => { // bind with an arrow function
  // alert(arg.dateStr)
 }


function renderEventContent(eventInfo:any){ 

   return (
       <>
        <b>{eventInfo.timeText}</b>
     <i>{eventInfo.event.title}</i>
       </>
     )
        
}


               
     const fetchProject =async()=>{

      LoadingModal()
      
      let sql = "SELECT p.code, c.client_name, p.added_by, p.title, p.team_lead, p.team_member, p.project_summary, p.start_date, p.complete_date, p.deadline, p.status, p.team_lead, p.description, p.budget, p.work_station, p.department, p.duration, p.banner_url, p.budget, p.rank, p.description, p.completion_rate	 FROM tbl_clients c left join tbl_projects p on p.client = c.code where p.company_code = '"+COMPANY_CODE+"' and p.code = '"+params.code+"'"
                 
                 
      const fd = new FormData();
      fd.append('sql', sql); 

      let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE

    await  axios.post(url, fd, config).then((result:any)=>{

      if(Array.isArray(result.data.data) && result.data.data.length!==0 &&result.data.status===200){
      
          try {
              setContent(result.data.data[0])
    
          }catch(e){
    
          }
         
        }
      })
      .catch((error)=>{
       Alerts('danger', error.message)
      }).finally(()=>{
          closeModal()
      })
      }
   
      const getFieldName =(table:any, field:string, code:string)=>{

        if(Array.isArray(table)){
        const rs = table&&table.filter((item:any)=>item.code===code)
        return rs.length!==0?rs[0][field]:''
        }else{
            return ''
        }
       
     }


    useEffect(()=>{
      fetchProject()
       fetchStaff()
      }, [])


    

  return ( <>
   <Breadcrumb 
   title="Project Details"
   summary="Create new project"
   >
    <li className="breadcrumb-item">
    <Link to="/project/reports">Project</Link>
                    </li>
   </Breadcrumb>

      <div className="pcoded-inner-content">
                                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">



                        {content.length!==0?<div>
                          

                        <div className="row"> 
                        <div className="col-md-12"> 
                        <div className="card "> 
                        <div className="card-header"> 
                        <h5>{content.title}</h5> 
                        </div> 
                        
                        <div className="card-body p-4"> <div className="row"> 
      
      <div className="col-md-5"> 
      <div className="wideget-user-desc d-flex"> 
      <div className="wideget-user-img">

          <img className="img-80 img-radius align-top m-r-15" 
          
          src={ImagesUrl+'/employees/passport/'+getFieldName(staff, 'passport', content.team_lead)} onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+getFieldName(staff, 'gender', content.team_lead)+'.png'}}

          
          
          alt="img" />
          
           </div> <div className="user-wrap mt-0"> 

          <h4 className="mb-2">{getFieldName(staff, 'title', content.team_lead) +  ' ' +getFieldName(staff, 'first_name', content.team_lead) + ' '+ getFieldName(staff, 'last_name', content.team_lead)}</h4> 
          <h6 className="text-muted mb-3 fw-400">Member Since: November 2018</h6> 


          <a href="/project/reports" className="btn btn-primary mt-1 mb-1 mr-2"><i className="fa fa-briefcase mr-1"></i> Projects</a> 
          <a href={`mailto:${getFieldName(staff, 'work_email', content.team_lead)}`} className="btn btn-secondary mt-1 mb-1"><i className="fa fa-envelope mr-1"></i> Message</a> </div> 
          
          </div>
          
          </div> 
                           
                           <div className="col-md-7 "> 
                           
                           <div className="row ml-3  ml-auto mt-lg-0"> 
                           
                           <div className="col-md-3"> <div className="border-right pr-4 mt-xl-3 mt-lg-0 d-xl-block"> <p className="text-muted mb-3 fw-400 h5">Budget</p><h4 className="font-weight-semibold mb-0 ">{CURRENCY+FormatNumber(content.budget)}</h4> </div> </div>
                           
                            <div className="col-md-5"> <div className="border-right pl-xl-4 pr-4 pl-md-0 pl-sm-0 pl-lg-0 mt-2 d-xl-block"> <p className="text-muted mb-3 fw-400 h5">Team Lead</p><h4 className="font-weight-semibold mb-0 ">{getFieldName(staff, 'first_name', content.team_lead) + ' '+ getFieldName(staff, 'last_name', content.team_lead)}</h4> </div> </div> 
                            
                            <div className="col-md-4 mt-3 mt-xl-0"> <p className="text-muted mb-3 mt-2 fw-400 h5">Team Members</p>
                            
                            
                            <div className="mb-0 avatar-list avatar-list-stacked"> 
  
  {getTeam(content.team_member).slice(0, 5).map((list:any, index:number)=><span><img key={index} className="avatar brround" src={ImagesUrl+'/employees/passport/'+list.passport} onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+list.gender+'.png'}}  alt="image" /></span>)}
{getTeam(content.team_member).length>5?
  <span className="avatar brround">+{getTeam(content.team_member).slice(5, getTeam(content.team_member).length)}</span>:''}
  
  </div> 
                            </div> 
                            
                            </div> </div> </div> </div> </div> </div> </div>



<div className="row"> 

<div className="col-lg-12"> 


 <div className="tab-header card">
            <ul className="nav nav-tabs md-tabs tab-timeline" role="tablist" id="mytab">
                <li className="nav-item">
                    <a className="nav-link active show" data-toggle="tab" href="#tab-5"  role="tab" aria-selected="false">Details</a>
                    <div className="slide"></div>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tab-6" role="tab" aria-selected="false">Team Memebers</a>
                    <div className="slide"></div>
                </li>
                <li className="nav-item">
                    <a className="nav-link " data-toggle="tab" href="#tab-7" role="tab" aria-selected="true"> Messages</a>
                    <div className="slide"></div>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tab-8" role="tab" aria-selected="false">Files</a>
                    <div className="slide"></div>
                </li>

                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tab-9" role="tab" aria-selected="false">Task</a>
                    <div className="slide"></div>
                </li>


                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tab-11" role="tab" aria-selected="false">Payments</a>
                    <div className="slide"></div>
                </li>
            </ul>
        </div>








 <div className="bg-white widget-user mb-5"> <div className="card-body"> <div className="border-0"> <div className="tab-content"> 
 <div className="tab-pane active show" id="tab-5"> 
 <div className="profile-log-switch"> <div className="row"> <div className="col-xl-8 col-lg-12 col-md-12"> 
 <div className="card z-depth-0"> 
 <div className="card-header"> <h3 className="card-title">Description</h3> </div> 
 <div className="card-body"> 

<div className="mb-0"> <p dangerouslySetInnerHTML={{__html:content.description}}></p></div> 

  </div> </div> 
  
  
  
  <div className="row"> 
  <div className="col-md-4"> 
  <div className="card mb-xl-0 z-depth-0"> 
  <div className="card-body text-center"> 
  <p className=" mb-1 ">Overall Budget</p>
  <h3 className="mb-1">4500,00</h3>  
  
  <span className="mb-1 text-muted fs-13">
  <span className="text-info mr-1"><i className="fa fa-caret-down  mr-1"></i> 0.8% </span> than last month</span> 
  </div> </div> </div>


 <div className="col-md-4 "> 
 <div className="card mb-xl-0 z-depth-0 text-center"> 
 <div className="card-body text-center"> 
 <p className=" mb-1 ">Overall Expenses</p>
 <h3 className="mb-1">5678,20</h3>
 
 <span className="mb-1 text-muted fs-13">
  <span className="text-info mr-1"><i className="fa fa-caret-down  mr-1"></i> 0.8% </span> than last month</span> 
   </div> </div> 
  </div>
 
 <div className="col-md-4 "> 
 <div className="card mb-xl-0 z-depth-0 text-center"> 
 <div className="card-body text-center"> 
 <p className=" mb-1 ">Differences</p>
 <h3 className="mb-1">25,256</h3> 
 <span className="mb-1 text-muted fs-13">
  <span className="text-info mr-1"><i className="fa fa-caret-down  mr-1"></i> 0.8% </span> than last month</span> </div> </div>
 
  </div>
  
  </div> </div> 
 
 
 
 
 
 
 <div className="col-xl-4 col-lg-12 col-md-12">
  
   <div className="card overflow-hidden mb-0 z-depth-0"> 
   <div className="card-header">
     <h3 className="card-title">Project</h3> </div> 

     <div className="project text-white Project-status "  style={{backgroundImage:`url(${ImagesUrl+'/projects/'+content.banner_url})`}}> 
     <div className="card-body">
      
       <div className="row"> 
     <div className="col"> 
     <div className="">Project status</div> 
     <div className="h3 mt-2 mb-2"><b>{content.completion_rate}%</b>
     <span className="text-success fs-13 ml-2">({content.status})</span></div> </div> 
     <div className="col-auto align-self-center "> 
     <div className="feature mt-0 mb-0"> <i className="fe fe-briefcase  project text-white"></i> </div> </div> </div>
     
      <div className=""> 
     <p className="mb-1">Overview of Project Status</p>

     <div className="progress  h-2 mt-1 mb-1"> 
     <div className= {`progress-bar progress-bar-success `} style={{width: `${content.completion_rate}%`}} role="progressbar"></div> 
     </div> 
     <small className="mb-0 text-white-50">Ongoing</small> </div> 
     </div> 
     </div> 
     
     
     <div className="p-0"> 
     
     <div className="row pt-3 pb-3 pl-5 pr-5 mt-0"> 
     <div className="col"> <span className="mb-0 mt-1 fs-15">Work Station:</span> </div> 
     <div className="col col-auto font-weight-semibold fs-15"> {content.work_station} </div> 
     </div> 

     <div className="row pt-3 pb-3 pl-5 pr-5 bg-light"> 
 
 
 <div className="col"> 
 <span className="mb-0 mt-1 fs-15">Client:</span> </div> 
 <div className="col col-auto font-weight-semibold fs-15"> {content.client_name}</div> 
 
 </div>



  <div className="row pt-3 pb-3 pl-5 pr-5"> 
  <div className="col"> 
  <span className="mb-0 mt-1 fs-15">Budget:</span> </div>
   <div className="col col-auto font-weight-semibold fs-15">{CURRENCY+FormatNumber(content.budget)} </div> </div> 
  
  <div className="row pt-3 pb-3 pl-5 pr-5 bg-light"> <div className="col"> <span className="mb-0 mt-1 fs-15">Department:</span> </div> <div className="col col-auto font-weight-semibold fs-15"> {getFieldName(department.data, 'department_name', content.department)}</div> </div>
  


   <div className="row pt-3 pb-3 pl-5 pr-5"> <div className="col"> <span className="mb-0 mt-1 fs-15">Duration:</span> </div> <div className="col col-auto font-weight-semibold fs-15">{content.duration} months</div> </div> 
   
   <div className="row pt-3 pb-3 pl-5 pr-5 bg-light"> <div className="col"> <span className="mb-0 mt-1 fs-15">Status:</span> </div> <div className="col col-auto font-weight-semibold text-success fs-15">{content.status}</div> </div> 
   
   <div className="row pt-3 pb-3 pl-5 pr-5"> <div className="col"> <span className="mb-0 mt-1 fs-15">Deadline:</span> </div> <div className="col col-auto font-weight-semibold fs-15">{content.deadline}</div> </div> 
   
   <div className="row pt-3 pb-3 pl-5 pr-5 bg-light"> <div className="col mt-1"> <span className="mb-0 mt-1 fs-15">Team:</span> </div> 
   
   <div className=" col-auto"> <div className="mb-0 d-flex"> 
   {getTeam(content.team_member).map((list:any, index:number)=><span><img key={index} className="avatar brround" src={ImagesUrl+'/employees/passport/'+list.passport} onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+list.gender+'.png'}}  alt="image" /></span>)}
   
   </div> </div> </div>


   
    </div> </div> </div> </div> </div> 
 
 </div>
 
 
  <div className="tab-pane" id="tab-6"> 
 
 <div className="row">
  
 {getTeam(content.team_member).map((list:any, index:number)=><div key={index} className=" col-md-4 "> 
   
   <div className="card z-depth-0 mb-5"> 
   
   <div className="card-body"> 
   <div className="media mt-0"> 

   <figure className="rounded-circle align-self-start mb-0">
     <img src={ImagesUrl+'/employees/passport/'+list.passport} onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+list.gender+'.png'}} alt="Generic placeholder image" className="avatar brround avatar-md mr-3" /> 
     </figure> 


     <div className="media-body"> 
     <h5 className="time-title p-0 mb-0 font-weight-semibold leading-normal">{list.first_name+' '+ list.last_name}</h5> {list.work_station} </div> 
     
     <button  className="btn btn-outline-primary d-none pl-3 pr-3 d-sm-block mr-2 mt-1"><i className="fa fa-comments"></i> </button> 
     <button className="btn btn-outline-secondary pl-3 pr-3 d-none d-sm-block mt-1"><i className="fa fa-phone"></i> </button> 
 
 </div> </div> <div className="card-footer bg-light  border-top"> Email: <span className="text-primary">{list.work_email}</span> </div> </div> </div>)}
 
 
  
  </div> </div>
  
  
  
  
  
   <div className="tab-pane" id="tab-7"> <h4 className="card-title fs-14">PROJECT RELATED MESSAGES</h4> <div className="row chatbox"> 
   <div className="col-md-12 col-lg-5 col-xl-4"> 
   <div className="card">
     <div className="p-4 chat-header"> 
     <div className="input-group"> 
 <input type="text" className="form-control" placeholder="Search" /> 
 
 
 <div className="input-group-append "> <button type="button" className="btn btn-primary "> <i className="fa fa-search" aria-hidden="true"></i> </button> </div> </div> 
 
 </div> 
 
 
 <div className="contacts_body p-0"> 
 soon
   
   
   
   </div> 
   
   
   
   </div> </div>
   
   <div className="col-md-12 col-lg-7 col-xl-8 "> 
   <div className="card"> 
soon
   </div>
     </div> </div> </div> 
     
     
     
     
     
     
     
     <div className="tab-pane userprof-tab" id="tab-8"> 
     
     <div className="card-body border"> <div> 
      <div className="form-group"> 
      <div className="form-label">Short Note</div> 
     
     <textarea className="form-control" rows={4} name="example-textarea-input"  placeholder="Comment"></textarea> 
     
     </div> 
     <div className="form-group"> 
     <div className="form-label">Attachment</div>
      <div className="custom-file"> 

   <input type="file" className="custom-file-input" name="example-file-input-custom" />
    
    
     <label className="custom-file-label">Choose file</label> 
     </div> </div>
     
     
     
      <a href="#" className="btn btn-primary">Upload</a> 
      </div> 
      </div> 
      
      
      

      <div className="m-t-30"> 
      
      <h3 className="card-title m-b-10">PROJECT FILES</h3>
      
       <div id="table3" className="table-responsive "> 
       <div className="table-responsive"> 
       
       <table className="table table-bordered table-responsive-md table-striped text-center mb-0 text-nowrap"> <thead className="border-top">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Uploaded By</th>
          <th scope="col">Short Note</th>
          <th className="text-center">View</th>
          <th className="text-center">Remove</th>
          </tr> 
          </thead> 
          <tbody>
            <tr>
              <td className="pt-3-half" >1</td>
   <td className="pt-3-half" >John Wisely</td>
   <td className="pt-3-half" >Consectetur</td>
   <td className="pt-3-half"> 
   <span className="table-up"><a href="#!" className="indigo-text"><i className="fa fa-download" aria-hidden="true"></i></a></span> 
   </td>
   <td> 
    <button type="button" className="btn btn-danger btn-sm my-0"><i className="fa fa-trash" aria-hidden="true"></i> Remove</button> </td>
    </tr> 
    </tbody>
    </table> </div> 
    </div> </div> </div> 
   
   
   
   
   <div className="tab-pane userprof-tab" id="tab-9"> 
   <div className="row"> <div className="col-lg-6 col-xl-3 col-sm-12 "> 
   
   <div className=""> <div className="card-header border-bottom-0"> 
   <div className="card-title">Upcoming Events</div> </div> 
   <div className="card-body border coming-events p-0">
    
     <img src={ImagesUrl+'/svg/calander2.svg'} alt="" className="p-3 h-100 w-100" />
     
      <div className="list-group list-group-flush "> 


      <div className="list-group-item d-flex  align-items-center pt-3 pb-3"> <div className="mr-2"> <i className="fa fa-calendar text-primary mr-2 fa-2x"></i> </div> <div className=""> <div className=" h6 mb-0">Strategy Planning</div> <small className="text-muted">25th sept,Delhi </small> </div> </div> 
      
      <div className="list-group-item d-flex  align-items-center pt-3 pb-3"> <div className="mr-2"> <i className="fa fa-calendar text-primary mr-2 fa-2x"></i> </div> <div className=""> <div className=" h6 mb-0">Hiring Employees</div> <small className="text-muted">31st jan,Mumbai </small> </div> </div> 
      
      <div className="list-group-item d-flex  align-items-center pt-3 pb-3"> <div className="mr-2"> <i className="fa fa-calendar text-primary mr-2 fa-2x"></i> </div> <div className=""> <div className=" h6 mb-0">Traning</div> <small className="text-muted">5th feb,Pune </small> </div> </div> 
      
      <div className="list-group-item d-flex  align-items-center pt-3 pb-3"> <div className="mr-2"> <i className="fa fa-calendar text-primary mr-2 fa-2x"></i> </div> <div className=""> <div className=" h6 mb-0">Finance policy </div> <small className="text-muted">12th mar,Banglore </small> </div> </div> 
      
      <div className="list-group-item d-flex  align-items-center pt-3 pb-3"> <div className="mr-2"> <i className="fa fa-calendar text-primary mr-2 fa-2x"></i> </div> <div className=""> <div className=" h6 mb-0">Marketing Policy</div> <small className="text-muted">17th Mar,Spain </small> </div> </div> 
      
      <div className="list-group-item d-flex  align-items-center pt-3 pb-3"> <div className="mr-2"> <i className="fa fa-calendar text-primary mr-2 fa-2x"></i> </div> <div className=""> <div className=" h6 mb-0">Marketing Policy</div> <small className="text-muted">17th Mar,Spain </small> </div> </div> </div> </div> </div> </div> 
     
     <div className="col-xl-9"> <div className=""> 
     
     <div className="card-body pt-5 p-0 pb-5">
      
   
   
     <FullCalendar
              events={[
                { title: 'event 1', date: '2019-04-01' },
                { title: 'event 2', date: '2019-04-02' }
              ]}        
             eventContent={renderEventContent}
        plugins={[ dayGridPlugin, interactionPlugin ]}
        initialView="dayGridMonth"
        dateClick={handleDateClick}
        headerToolbar = { {
          left: "prev, today",
          center: "title",
          right: "next"
        }}
      />


   </div> </div> </div> 
   </div>  
   
   
   <div className="row"> 
   <div className="col-12"> 
   <div className="mt-4"> 


   
<div className="card z-depth-0">

<div className="card-header">
<h5>Create New Task</h5>

<div className="card-header-right">
<label className="label label-primary pointer " > <i className='fa fa-plus text-white'></i> Add </label>
<label  id='openAdd' data-toggle="modal" data-target="#large-Modal"> </label>        
</div>

</div>
   
   
   <div className="card-body"> 
   <div id="table" className="table-responsive table-editable border-top-0"> 
   
   
   <table className="table table-bordered table-responsive-md table-striped mb-0 text-nowrap">
    <tbody>
      <tr>
        <th className="">Title</th>
        <th className="">Description</th>
        <th className="">Assign Staff</th>
        <th className="">Start Date</th>
        <th className="">End Date</th>
        <th className="">Sort</th>
        <th className="">Remove</th>
        </tr>
        <tr><td className="pt-3-half" >Payments</td><td className="pt-3-half" >Project</td><td className="pt-3-half" >5 members</td><td className="pt-3-half" >Thu,15-Jun-2019</td><td className="pt-3-half" >Mon,23-Sept-2019</td><td className="pt-3-half"> <span className="table-up"><a href="#!" className="indigo-text"><i className="fa fa-long-arrow-up" aria-hidden="true"></i></a></span> <span className="table-down"><a href="#!" className="indigo-text"><i className="fa fa-long-arrow-down" aria-hidden="true"></i></a></span> </td>
   
   <td> 
   
   <span className="table-remove"><button type="button" className="btn btn-danger btn-sm my-0">Remove</button></span> </td></tr>
   
   </tbody></table> </div> </div> </div> </div> </div> </div>  </div> 
   
   
   
   
   
    <div className="tab-pane userprof-tab" id="tab-11"> 
   
   <div className="row">
    

   <div className="col-md-12">
<div className="card z-depth-0 mt-4">

<div className="card-header">
<h5>Create New Payment</h5>

<div className="card-header-right">
<label className="label label-primary pointer " > <i className='fa fa-plus text-white'></i> Add </label>
<label  id='openAdd' data-toggle="modal" data-target="#large-Modal"> </label>        
</div>

</div>

       
     
       <div  className="table-responsive"> 
       
       <table className="table table-bordered table-responsive-md table-striped mb-0 text-nowrap">
        <tbody>
          <tr>
            <th className="">#</th>
            <th className="">Title</th>
            <th className="">Date</th>
            <th className="">Amount</th>
            <th className="">Status</th>
            <th className="">Payments</th>
            <th className="">Sort</th>
            <th className="">Remove</th>
            </tr>
   
   <tr>
    <td className="pt-3-half" >1</td>
    <td className="pt-3-half" >Project Management</td>
    <td className="pt-3-half" >13-01-2019</td>
    <td className="pt-3-half" >$3200</td>
    <td className="pt-3-half" ><span><button type="button" className="btn btn-success btn-sm my-0">Paid</button></span></td>
    <td className="pt-3-half" ><span><button type="button" className="btn btn-info btn-sm my-0">Invoice</button></span></td>
    <td className="pt-3-half"> <span className="table-up"><a href="#!" className="indigo-text"><i className="fa fa-long-arrow-up" aria-hidden="true"></i></a></span> <span className="table-down"><a href="#!" className="indigo-text"><i className="fa fa-long-arrow-down" aria-hidden="true"></i></a></span> </td>
    <td> <span className="table-remove"><button type="button" className="btn btn-danger btn-sm my-0">Remove</button></span> 
    </td>
    </tr>
   </tbody>
   </table> 
   </div> </div> </div> 
   </div> 
   
   
   </div> </div> </div> </div> </div> </div> </div> 



</div>:''}

                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
        </>     
  )
}

export default ProjectDetails