import { useEffect, useState, ChangeEvent} from 'react'
import { COMPANY_CODE, ImagesUrl, ServerUrl, config, styles } from '../../components/Includes';
import axios from 'axios';
import { LoadingModal, closeModal, Alerts } from '../../components/notify';
import Breadcrumb from '../../components/Breadcrumb'


import { useQuery } from 'react-query'
import Select from 'react-select'
import { FetchProfile, fetchDepartment} from '../../api/ReactQuery';
import "react-datepicker/dist/react-datepicker.css";
import { getMonthYear } from '../../components/GlobalFunction';
import { Link } from 'react-router-dom';

const DisplayAttendance =()=> {
    const profile = useQuery(["profile"], FetchProfile)
    const [head, setHead]= useState([] as any)
    const [content, setContent] = useState([] as any);
    
    const [sqlQuery, setSqlQuery] = useState('');
    const department = useQuery(["department"], fetchDepartment)
    const [search, setSearch] = useState({
     
        department:[] as any,
        attendance_date:'',
     })

    

           const [errors, setErrors] = useState({
            time_in:'',
            time_out:'',
            attendance_date:'',
           
         })


         const handleSelect = (option:any, action:any)=>{
            setSearch({...search, [action.name]:option});
              setErrors({...errors, [action.name]:''})
              setContent([])
    
               }



         const handleChangeDate = (event:ChangeEvent<HTMLInputElement>)=>{
            const {name, value} = event.target;
            let data:any = value
            setSearch({...search, attendance_date:data});
            setErrors({...errors, attendance_date:''});
          

            const days =  new Date(data.slice(0,4), data.slice(5,7), 0).getDate();
            const start = data+'-01';
            const end = data+'-'+days;
            getDaysBetween(start, end)
            setContent([])


         }


         const getDays=(date:any)=>{
            let day;
            switch (new Date(date).getDay()){
                case 0:
                    day =" Sun";
                    break;
                case 1:
                    day =" Mon";
                    break;
                case 2:
                    day =" Tue";
                   break;
                case 3:
                    day =" Wed";
                        break;
                case 4:
                    day =" Thu";
                        break;
                case 5:
                    day =" Fri";
                    break;
                case 6:
                    day =" Sat"
                    break;
                default:
                    day=" Sun"
            }
                return day
            }

         const getDaysBetween =(startDate:any, endDate:any)=>{
            const start = new Date(startDate)
            const end =  new Date(endDate)
        
            var result = []
            while (start <= end){
                result.push(new Date(start).toISOString().slice(0,10))
                start.setDate(start.getDate()+1)
            }
    
            let sqlString = result.map(data=>" max(case when a.attendance_date  = '"+data+"'  then a.attendance else '**' end) AS '"+data.slice(8,10)+getDays(data)+"'")

            setHead(result.map(data=>data.slice(8,10)+getDays(data)))

    
            let final =   'SELECT e.first_name, e.last_name, sum(case when a.attendance  = "P" then 1 else 0 end) as P, sum(case when a.attendance  = "H" then 1 else 0 end) as H, sum(case when a.attendance  = "A" then 1 else 0 end) as A, '+String(sqlString)+ ' from tbl_employee e, tbl_employee_attendance a where e.code = a.staff_code and e.termination_reason = "" and a.attendance_date >= "'+startDate+'" and a.attendance_date <= "'+endDate+'" '


            setSqlQuery(final)
        }


       

    

               

const fetchContent = async()=>{


    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';
              

        if(!search.attendance_date){
            error.attendance_date =msg;
            formIsValid = false;
        } 



        if(!formIsValid){
        setErrors(error) 
        }else{
    LoadingModal()
 
    var final = sqlQuery


    if(search.department.length!==0){
        final = final + " and e.department = '"+search.department.value+"'"
    }

    final = final + ' group by a.staff_code, e.first_name, e.last_name'


 
          const fd = new FormData();
          fd.append('sql', final); 
          let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
        await  axios.post(url, fd, config).then((result:any)=>{
          if(Array.isArray(result.data.data) && result.data.data.length!==0){
            setContent(result.data.data)
            window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
            }else{
                setContent([]) 
                Alerts('danger',' There are no attendance found for the selected month')
            }
          })
          .catch((error)=>{
              Alerts('danger',error.message)
          }).finally(()=>{
              closeModal()
          })
        }
          } 


          

  return ( <>
   <Breadcrumb 
   title="Attendance"
   summary="Display employee attendance"
   >
    <li className="breadcrumb-item">
    <Link to="#!">Attendance</Link>
                    </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
<div className="main-body">
<div className="page-wrapper">
<div className="page-body">


<div className="row">
<div className=" col-md-12">
<div className="card z-depth-0">
    <div className="card-header">
        <h5>DISPLAY ATTENDANCE</h5>
        <span  ><a href="#"> Display Employee</a></span>
        <div className="card-header-right">

            <ul className="list-unstyled card-option">
                <li><i className="fa fa fa-wrench open-card-option"></i></li>
                <li><i className="fa fa-window-maximize full-card"></i></li>
                <li><i className="fa fa-minus minimize-card"></i></li>
                <li><i className="fa fa-refresh reload-card"></i></li>
                <li><i className="fa fa-trash close-card"></i></li>
            </ul>
            
        </div>
    </div>
    <div className="card-block ">
       

    <section className="col-md-10 m-b-10 m-t-10">
        <span className="label bg-danger">Color Icon For Absent</span>
        <span className=" label label-primary">Color Icon For Half Day</span>
        <span className=" label bg-success">Color Icon For Present</span>
        </section>

        <div className="row">
        <div className="col-md-6">
    <div className="form-group">
    <label className="col-form-label">Search by Department</label>
    
    
    <Select  options={
            department.data&&department.data.map((list:any, id:number)=>{
                return {key:id, value:list.code, label:list.department_name}
                    })}   
                    styles={styles} 
			onChange={handleSelect}    
            name="department" 
            value={search.department} 
               />
    
    </div>
    
    </div>



    <div className="col-md-6">
             <div className="form-group">
            <label className="col-form-label">Attendance Month </label>
           
            <input type="month"   className={errors.attendance_date?"form-control form-control-lg form-control-danger":"form-control form-control-lg"} name="attendance_date"  onChange={handleChangeDate} value={search.attendance_date} />

        
{errors.attendance_date&&
    <div className="col-form-label has-danger">{errors.attendance_date&&errors.attendance_date} </div>}
             </div>

             </div>



        </div>


<hr/>
<div className="row">
<div className="col-md-12 text-right">

<button className="btn btn-success btn-round pr-5 pl-5" type='button' onClick={fetchContent}>Display Attendnace</button>
</div>
</div>





    </div>
</div>
</div>
</div>






{content.length!==0?
  <div className="row">
    <div className="col-sm-12">
        <div className="card z-depth-0">
            <div className="card-block">
    

    <div className="row">
        <div className="table-responsive ">
       
        <table className="table table-sm table-bordered table-hover">
                <thead>
                    <tr>
                        <th colSpan={9} className='text-center '>
                        <img 
           src={ImagesUrl+'/about/'+profile.data.logo}  alt="Click to change" 
           className="m-b-10 img-100" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/logo.png'}} />


                        <h4>{profile.data.company_name} </h4>
                        <h5>{profile.data.company_address}</h5>
                        <h6 className='m-b-5'>{profile.data.email}, {profile.data.phone}</h6>

                        <div className="alert alert-info text-center mb-1 mt-2">

                        <h5> EMPLOYEE ATTENDANCE</h5>
                        </div>
                        </th>
                    </tr>
                  

                  <tr>
                        <td colSpan={4}>  <h6>Month: {getMonthYear(search.attendance_date) }</h6></td>
                        <td colSpan={5}>  <h6>Date Printed: {/* {getBritishDate(new Date()) } */}</h6></td>
                    </tr> 


                    <tr style={{backgroundColor:'#E7E0EE'}}>
                <th >S/N</th>
                    <th className='fixed-column' style={{backgroundColor:'#E7E0EE'}}>Staff Name</th>
                    <th ><span className=" label label-success">P</span></th>
                    <th ><span className=" label label-primary">H</span></th>            
                    <th ><span className="label label-danger">A</span></th>
                    {head&&head.map((data:any, id:number)=><th key={id} >{data}</th>)}
                </tr>

                </thead>
                <tbody>
                    {content&&content.map((list:any, index:number)=>
                    <tr key={index}>
                        <td >{index+1}</td>
                        <td className='fixed-column'>{list.first_name+ ' '+ list.last_name}</td>
                        <td><span className=" label label-success">{list.P}</span></td>
                    <td><span className=" label label-primary">{list.H}</span></td>            
                    <td><span className=" label label-danger">{list.A}</span></td>


                    {head&&head.map((data:any, id:number)=><td key={id} >
                    {list[data]==='P'?
                    <span className=" label label-success">{list[data]}</span>:
                    list[data]==='A'?
                    <span className=" label label-danger">{list[data]}</span>:
                    list[data]==='H'?
                    <span className="label label-info">{list[data]}</span>:
                    list[data]} </td>)} 
                </tr>)}
                          

                </tbody>

                   
            </table>

        </div>

        <button className="btn btn-success btn-sm text-right" type='button' ><i className='fa fa-print'></i> Export To Excel</button>


        </div>

            </div>
        </div>
    </div>
    </div>:[]}

</div>
</div>


</div>
</div>  
      </>               
 
  )
}

export default DisplayAttendance