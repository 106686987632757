import React from 'react';
import Template from './components/Template';

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { QueryClientProvider, QueryClient } from 'react-query';
import Login from './modules/Users/Login';
import { Token } from './components/Includes';

import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Logout from './modules/Users/Logout';
import Error404 from './modules/maintenance/Error404';
import ProtectedView from './Protected_view';

const queryClient = new QueryClient()

function App() {

  return ( <QueryClientProvider client={queryClient} >
<ReactNotifications />


{Token !==undefined? <Template />: '' } 
        <Router>
          <Routes>

          <Route  path="/" element={<Login />} /> 
          <Route  path="/logout" element={<Logout />} /> 
          <Route  path="/error404" element={<Error404 />} /> 

          <Route path="*" element={Token ===undefined? <Navigate to="/" /> :
         ''}/>

         </Routes>

        </Router> 

        

         </QueryClientProvider>)

}

export default App;
