
import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo, useRef} from 'react'
import { COMPANY_CODE, ImagesUrl, STAFF_CODE, ServerUrl, config, selectStyles, styles } from '../components/Includes';
import axios from 'axios';
import {Chart,ArcElement, registerables } from 'chart.js';
import {Bar, Line, Pie, Doughnut} from 'react-chartjs-2'
import { convertDate, getMonthYear } from '../components/GlobalFunction';


import { useQuery } from 'react-query'
import { fetchSettings } from '../api/ReactQuery';
import { Link } from 'react-router-dom';
//https://react-chartjs-2.js.org/examples/

const Dashboard =()=> {
    let thisMonth = getMonthYear(new Date().toISOString().slice(0,10));
let month = new Date().toISOString().slice(0,7)
    Chart.register(ArcElement, ...registerables)

    var today = new Date().toISOString().slice(0,10);
    var date = new Date()
    var first = new Date(date.getFullYear(), date.getMonth(), 1, 23, 59, 59)
    var last = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)
  
    var firstDayOfYear = new Date(date.getFullYear(), 0, 1, 23, 59, 59)
    var lastDayOfYear = new Date(date.getFullYear()+1, 0, 0, 23, 59, 59)
    const settings = useQuery(["settings"], fetchSettings, {
        staleTime: 10 * (60 * 1000), // 10 mins
      })

    const [station, setStation] = useState([] as any);

    const [department, setDepartment] = useState([])
    const [gender, setGender] = useState([])
    const [attendance, setAttendance] = useState([])
    const [appraisal, setAppraisal] = useState([])
    const [statistics, setStatistics] = useState({
        employee:0,
        departments:0,
        present:0,
        onLeave:0,

        pending:0,
        passport:0,
        group_code:0,
        address:0,
        account:0,
    });
    const fetchGender =async()=>{
        var sql ="Select gender, count(*) as data from tbl_employee where termination_reason = '' group by gender" ;
        const fd = new FormData();
        fd.append('sql', sql); 
        let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
      await  axios.post(url, fd, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
          setGender(result.data.data)

          }
        })
    }


    useEffect(()=>{

        try{
         if (settings!==undefined && settings.data.length!==0){
         let list = JSON.parse(settings.data[0].contacts)


         let sqlString = list.map((data:any)=>"sum(case when work_station = '"+data.location.toUpperCase()+"'  then 1 else 0 end) AS '"+ data.location.toUpperCase()+"'")

         let sql =   'SELECT '+String(sqlString)+ ' from tbl_employee where termination_reason = ""'

         const fd = new FormData();
         fd.append('sql', sql); 
         let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
       axios.post(url, fd, config).then((result:any)=>{
         if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
           

            let responseData = []

                      for (var i = 0; i< list.length;  i++) {
                        responseData.push(
                            {  
                                label:list[i].location.toUpperCase(),
                                data:result.data.data[0][list[i].location.toUpperCase()]
                            });
                            } 
                            setStation(responseData)
 
        }
    })


         }
     
        }catch(err) {
     
        }
         
         }, [settings.data]) 

    const fetchStatistics=()=>{
       
        var sql ="Select (select count(*) from tbl_employee where employment_status = 'On Leave' and termination_reason = '') as onLeave, (select count(*) from tbl_employee where passport IS NULL) as passport, (select count(*) from tbl_employee where supervisor = '[]') as supervisor, (select count(*) from tbl_employee where group_code = '') as group_code, (select count(*) from tbl_employee where termination_reason = '') as staff, (select count(*) from tbl_employee where account_number = '') as account, (select count(*) from tbl_departments ) as departments, (Select IFNULL(sum(case when attendance  = 'P' then 1 else 0 end),0) from tbl_employee_attendance where attendance_date >= '"+today+"' and attendance_date <= '"+today+"') as present, (select count(*) from tbl_leave_application where start_date >=  '"+convertDate(firstDayOfYear)+"' and end_date  <= '"+convertDate(lastDayOfYear)+"' and is_approved = 'Pending') as pending "


        const fd = new FormData();
        fd.append('sql', sql); 
        let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
    axios.post(url, fd, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
          setStatistics({
            ...statistics,
            group_code:result.data.data[0].group_code,
            passport:result.data.data[0].passport,

            pending:result.data.data[0].pending,
            account:result.data.data[0].account,
            employee:result.data.data[0].staff,
            departments:result.data.data[0].departments,
            present:result.data.data[0].present,
            onLeave:result.data.data[0].onLeave,
        
        })
          }
        })
             }


    const fetchAppraisal=()=>{
       
        var sql = "SELECT IFNULL(e.title, '') as title, e.first_name, e.gender, e.last_name, IFNULL(e.middle_name, '') as middle_name, e.passport, a.percentage  from tbl_employee e, tbl_appraisal a where a.staff_code =  e.code and a.appraisal_month ='"+month+"' order by rand() LIMIT 5"
        
        const fd = new FormData();
        fd.append('sql', sql); 
        let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
    axios.post(url, fd, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
          setAppraisal(result.data.data)
          }
        })
             }


    const fetchDepartment=()=>{
       
        var sql = "SELECT d.department_name as label, count(e.id) as data FROM tbl_employee e, tbl_departments d WHERE d.code = e.department group by d.department_name "
        
        const fd = new FormData();
        fd.append('sql', sql); 
        let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
    axios.post(url, fd, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
          setDepartment(result.data.data)
          }
        })
             }



    const fetchStaffAttendance =async()=>{
       
        var sql = "select attendance_date as label, sum(case when attendance = 'P'   then 1 else 0 end) AS 'present', sum(case when attendance = 'A'   then 1 else 0 end) AS 'absent', sum(case when attendance = 'H'   then 1 else 0 end) AS 'half' from tbl_employee_attendance where attendance_date >=  '"+convertDate(firstDayOfYear)+"' and attendance_date  <= '"+convertDate(lastDayOfYear)+"' group by attendance_date  order by label  "
        
        const fd = new FormData();
        fd.append('sql', sql); 

        let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
      await  axios.post(url, fd, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
          setAttendance(result.data.data)

          }
        })
             }



             const dataStaffAttendance = {
                labels: attendance.map((data:any)=>data.label),
                datasets: [
                 
                  {
                    label: 'Total Present',
                    data: attendance.map((data:any)=>data.present),
                    fill: false,
                    backgroundColor: 'rgb(12, 206, 138)',
                    borderColor: 'rgb( 11, 218, 147)',
                    yAxisID: 'y-axis-2',
                  },
                  {
                    label: 'Total Absent',
                    data: attendance.map((data:any)=>data.absent),
                    fill: false,
                    backgroundColor: 'rgb(255, 99, 132)',
                    borderColor: 'rgb( 173, 24, 39)',
                    yAxisID: 'y-axis-2',
                  },
                  {
                    label: 'Total Half Day',
                    data: attendance.map((data:any)=>data.half),
                    fill: false,
                    backgroundColor: 'rgb(1, 190, 193)',
                    borderColor: 'rgb(0, 191, 195)',
                    yAxisID: 'y-axis-2',
                  }
                ],
              }; 

              const optionsStaffAttendance = {
                scales: {
                  yAxes: [
                    {
                      type: 'linear',
                      display: true,
                      position: 'left',
                      id: 'y-axis-1',
                    },
                    {
                      type: 'linear',
                      display: true,
                      position: 'right',
                      id: 'y-axis-2',
                      gridLines: {
                        drawOnArea: false,
                      },
                    },
                  ],
                },
              } 

    const genderStaff= {
        labels: gender.map((data:any)=>data.gender),
        datasets: [
          {
            label: 'Total',
            backgroundColor: [ '#404E67', '#5555ff', ],
            hoverBackgroundColor: ['#CACACA', '#3a8300'],
            data: gender.map((data:any)=>data.data), //[65, 59, 80, 81, 56]
          }
        ]
      }



      const departmentList = {
        labels: department.map((data:any)=>data.label),
        datasets: [
          {
                     
            label: 'Total Employee',
            backgroundColor: [
              '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507', '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b'],
            hoverBackgroundColor: [
            '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507', '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b', '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507'],
            data: department.map((data:any)=>data.data)
          }
        ]
      }



      const stationList = {
        labels: station.map((data:any)=>data.label),
        datasets: [
          {
                     
            label: 'Total Employee',
            hoverBackgroundColor: ['#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507', '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b'],
             backgroundColor: [ '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b', '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507'],
            data: station.map((data:any)=>data.data)
          }
        ]
      }



      useEffect(()=>{
        fetchGender()
        fetchStaffAttendance()
        fetchDepartment()
        fetchAppraisal()
        fetchStatistics()
    }, [])

  return ( <>
   
      <div className="pcoded-inner-content">
                                <div className="main-body">
                                    <div className="page-wrapper">
                                        <div className="page-body">




                                        <div className="row">

                                      



<div className="col-md-3">
    <div className="card">
        <div className="card-block">
            <div className="row align-items-center m-l-0">
                <div className="col-auto">
                    <i className="fa fa-users f-30 text-c-purple"></i>
                </div>
                <div className="col-auto">
                    <h6 className="text-muted m-b-10">Employee</h6>
                    <h2 className="m-b-0">{statistics.employee}</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<div className="col-md-3">
    <div className="card">
        <div className="card-block">
            <div className="row align-items-center m-l-0">
                <div className="col-auto">
                    <i className="fa fa-rocket f-30 text-c-green"></i>
                </div>
                <div className="col-auto">
                    <h6 className="text-muted m-b-10">Today's Present</h6>
                    <h2 className="m-b-0">{statistics.present}</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<div className="col-md-3">
    <div className="card">
        <div className="card-block">
            <div className="row align-items-center m-l-0">
                <div className="col-auto">
                    <i className="fa fa-user f-30 text-c-red"></i>
                </div>
                <div className="col-auto">
                    <h6 className="text-muted m-b-10">On Leave</h6>
                    <h2 className="m-b-0">{statistics.onLeave}</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<div className="col-md-3">
    <div className="card">
        <div className="card-block">
            <div className="row align-items-center m-l-0">
                <div className="col-auto">
                    <i className="fa fa-cogs f-30 text-c-blue"></i>
                </div>
                <div className="col-auto">
                    <h6 className="text-muted m-b-10">Departments</h6>
                    <h2 className="m-b-0">{statistics.departments}</h2>
                </div>
            </div>
        </div>
    </div>
</div>
</div>


<div className="row">



        <div className="col-md-4 ">
<div className="card feed-card">
<div className="card-header">
    <h5>Employee By Gender</h5>
</div>
<div className="card-block">

<Pie data={genderStaff} 


/>



</div>
</div>
</div>


<div className="col-md-8 ">
<div className="card feed-card">
<div className="card-header">
    <h5>Employee By department</h5>
</div>
<div className="card-block">

   
<Bar
          data={departmentList}
        />  


</div>
</div>
</div>

</div>

<div className="row ">
<div className="col-md-4 ">
        <div className="card office-card">
            <div className="card-header">
                <h5>Quick Link</h5>
            </div>

            

            <div className="card-block">
<div className="col-md-12">

<div className="row mb-2 ">
            <div className=" col-md-3  ">
<button className="btn btn-inverse" onClick={()=>window.open('/general/sitemap', '_self')}  ><i className="fa fa-user fa-2x"></i> <br/>
  Attendance </button>
    </div>

    <div className=" col-md-3 ">
<button className="btn btn-inverse" onClick={()=>window.open('/general/sitemap', '_self')}  ><i className="fa fa-map fa-2x"></i> <br/>
   Payroll </button>
    </div>
</div>

<div className="row">
    <div className=" col-sm-3 mb-2 ml-2">
<button className="btn btn-inverse" onClick={()=>window.open('/general/sitemap', '_self')}  ><i className="fa fa-map fa-2x"></i> 
    <br/>Leave </button>
    </div>

    <div className=" col-sm-3 mb-2 mr-4">
<button className="btn btn-inverse" onClick={()=>window.open('/general/sitemap', '_self')}  ><i className="fa fa-map fa-2x"></i> 
    <br/>Employee</button>
    </div>
</div>

    <div className="row">
    <div className=" col-sm-3 mb-2 ml-2 mr-1">
<button className="btn btn-inverse" onClick={()=>window.open('/general/sitemap', '_self')}  ><i className="fa fa-map fa-2x"></i> 
    <br/>Assets </button>
    </div>

   
    <div className=" col-sm-3 mb-2 ml-2">
<button className="btn btn-inverse" onClick={()=>window.open('/general/sitemap', '_self')}  ><i className="fa fa-map fa-2x"></i> 
    <br/>Finance </button>
    </div>
</div>

<div className="row">
    <div className=" col-sm-3 mb-2 mr-3">
<button className="btn btn-inverse" onClick={()=>window.open('/general/sitemap', '_self')}  ><i className="fa fa-map fa-2x"></i> 
    <br/>Appraisal </button>
    </div>

    <div className=" col-sm-3 mb-2 ml-3 mr-2">
<button className="btn btn-inverse" onClick={()=>window.open('/general/sitemap', '_self')}  ><i className="fa fa-users fa-2x"></i> 
    <br/>Profile </button>
    </div>

   
    </div>
    </div>

            </div>
        </div>
    </div>




    <div className="col-md-4 ">
<div className="card feed-card">
<div className="card-header">
    <h5>To Do List</h5>
</div>
<div className="card-block">

{statistics.group_code>=1? <div className="row m-b-30">
        <div className="col-auto p-r-0">
            <i className="fa fa-two bg-c-red feed-icon">{statistics.group_code}</i>
        </div>
        <div className="col">
        <h6 className="m-b-5"> <a href="/employees/view"> Employee to assign a User group </a></h6>
        </div>
    </div>:[]}


    {statistics.account>=1?
    <div className="row m-b-30">
        <div className="col-auto p-r-0">
            <i className="fa fa-one bg-c-green feed-icon">{statistics.account}</i>
        </div>
        <div className="col">
           
        <h6 className="m-b-5"> <a href="/employees/view"> Employee without Bank Account details </a></h6>
        </div>
    </div>:[]}

    {statistics.passport>=1? <div className="row m-b-30">
        <div className="col-auto p-r-0">
            <i className="fa fa-one bg-c-green feed-icon">{statistics.passport}</i>
        </div>
        <div className="col">
           
        <h6 className="m-b-5"> <a href="/employees/view"> Employee to  upload their passport </a></h6>
        </div>
    </div>:[]}
    
    
{statistics.pending>=1? <div className="row m-b-30">
        <div className="col-auto p-r-0">
            <i className="fa fa-one bg-c-blue feed-icon">{statistics.pending}</i>
        </div>
        <div className="col">
            <h6 className="m-b-5"> <Link to="/leave/leave_list"> Leave awaiting approval </Link></h6>
        </div>
    </div>:[]}
</div>
</div>
</div>


<div className="col-md-4 ">
                <div className="card ">
                    <div className="card-header">
                        <h5>Performance ({thisMonth})</h5>
                    </div>
                    <div className="card-block">

                       {appraisal&&appraisal.map((data:any, id:number)=> 
                        <div className="align-middle m-b-30" key={id}>
                            <img src={ImagesUrl+'/employees/passport/'+data.passport} alt="user image" className="img-radius img-40 align-top m-r-15" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+data.gender+'.png'}} />
                            <div className="d-inline-block">
                                <h6>{data.title + ' '+ data.first_name + ' ' + data.last_name + ' '+ data.middle_name}</h6>
                                <p className="text-muted m-b-0">{data.percentage+'%'}</p>
                            </div>
                        </div>)}


                        <div className="text-center">
                            <a href="#!" className="b-b-primary text-primary">View all Performance</a>
                        </div>
                    </div>
                </div>
            </div>



</div>



<div className="row">
<div className="col-md-7 ">
<div className="card ">
<div className="card-header">
    <h5>WORK STATION EMPLOYEES</h5>
</div>
<div className="card-block">

   
<Bar
          data={stationList}
          /* options={optionsStaffAttendance} */
          
        /> 


</div>
</div>
</div>




<div className="col-md-5 ">
<div className="card ">
<div className="card-header">
    <h5>Department Asset</h5>
</div>
<div className="card-block">
<Doughnut data={departmentList} 


/>


</div>
</div>
</div>

         </div>



<div className="row">
<div className="col-md-12 ">
<div className="card feed-card">
<div className="card-header">
    <h5>Employee Attendance ({thisMonth})</h5>
</div>
<div className="card-block">

   
<Line
          data={dataStaffAttendance}
          /* options={optionsStaffAttendance} */
          
        /> 


</div>
</div>
</div>

         </div>
           







                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
                            </>               
 
  )
}

export default Dashboard