
import { useEffect, useState} from 'react'
import { COMPANY_CODE, ServerUrl, config, group_styles } from '../../components/Includes';
import axios from 'axios';
import {Chart,ArcElement, registerables } from 'chart.js';
import {Bar, Pie, Line} from 'react-chartjs-2'
import { convertDate, getBritishDate, getMonth, getMonthYear } from '../../components/GlobalFunction';

import { useQuery } from 'react-query'
import { fetchSettings } from '../../api/ReactQuery';
import Breadcrumb from '../../components/Breadcrumb';
import { Link } from 'react-router-dom';
import { DateList } from '../../components/Data';
import Select from 'react-select'

const AttendanceDashboard =()=> {
  
    let thisMonth = getMonthYear(new Date().toISOString().slice(0,10));
let month = new Date().toISOString().slice(0,7)
    Chart.register(ArcElement, ...registerables)

    var today = new Date().toISOString().slice(0,10);
    var date = new Date()
    var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1, 23, 59, 59)
    var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)

    var firstDayOfYear = new Date(date.getFullYear(), 0, 1, 23, 59, 59)
    var lastDayOfYear = new Date(date.getFullYear()+1, 0, 0, 23, 59, 59)

    const [search, setSearch] = useState({
      date_range:  {label:'2023-01-01 - 2023-12-31', value:'2023-01-01 - 2023-12-31', start_date:'2023-01-01', end_date:'2023-12-31'},
   })

      const [staffList, setStaffList] = useState([] as any);
      const [yearList, setYearList] = useState([] as any);
    const [event, setEvent] = useState(0);
    const [departmentType, setDepartmentType] = useState([])
    const [holiday, setHoliday] = useState([])
    const [statistics, setStatistics] = useState({
       
        staff:0,
        absent:0,
        present:0,
        onleave:0,
        absent_staff:0,
        yettosign:0
    });



    const fetchEvent =async()=>{
      var sql ="SELECT title, IFNULL(count(id),0) as data FROM tbl_holidays WHERE event_type = 'Event' and start_date >=  '"+convertDate(firstDayOfMonth)+"' and end_date  <= '"+convertDate(lastDayOfMonth)+"' group by title " ;

      const fd = new FormData();
      fd.append('sql', sql); 
      
      let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
    await  axios.post(url, fd, config).then((result:any)=>{

      if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
        setEvent(result.data.data[0].data)
        }else{
          setEvent(0)
        }
      })
  }

    const fetchHoliday =async()=>{
        var sql ="SELECT title, sum(no_days) as data FROM tbl_holidays WHERE event_type = 'Holiday' and start_date >=  '"+search.date_range.start_date+"' and end_date  <= '"+search.date_range.end_date+"' group by title " ;

        const fd = new FormData();
        fd.append('sql', sql); 
        let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
      await  axios.post(url, fd, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
          setHoliday(result.data.data)

          }else{
            setHoliday([])
          }
        })
    }



    const fetchStatistics=async()=>{
    

        var sql =" SELECT (SELECT count(*) from tbl_employee where termination_reason = '') as staff, (SELECT count(*) from tbl_employee where employment_status = 'On Leave') as onLeave, (select count(*) from tbl_departments ) as departments, (Select IFNULL(sum(case when attendance  = 'P' then 1 else 0 end),0) from tbl_employee_attendance where attendance_date = '"+today+"') as present, (Select IFNULL(sum(case when attendance  = 'A' then 1 else 0 end),0) from tbl_employee_attendance where attendance_date = '"+today+"') as absent"

        const fd = new FormData();
        fd.append('sql', sql); 
        let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
   await axios.post(url, fd, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
          setStatistics({
            ...statistics,
            present:result.data.data[0].present,
            onleave:result.data.data[0].onLeave,
            staff:result.data.data[0].staff,
            absent_staff:result.data.data[0].absent,
            yettosign:result.data.data[0].staff - result.data.data[0].absent -result.data.data[0].present  - result.data.data[0].onLeave,
            absent:result.data.data[0].staff - result.data.data[0].present
        
        })
          }
        })
             }




    const fetchDepartmentType=()=>{
       
        var sql = "SELECT d.department_name as label, sum(case when a.attendance = 'P'   then 1 else 0 end) AS 'present', sum(case when a.attendance = 'A'   then 1 else 0 end) AS 'absent', sum(case when a.attendance = 'H'   then 1 else 0 end) AS 'halfday'  FROM tbl_departments d, tbl_employee_attendance a , tbl_employee e WHERE d.code = e.department and e.code = a.staff_code  and attendance_date =  '"+today+"' group by d.department_name "
        
        const fd = new FormData();
        fd.append('sql', sql); 
        let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
    axios.post(url, fd, config).then((result:any)=>{
        if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
          setDepartmentType(result.data.data)
          }else{
            setDepartmentType([])
          }
        })
             }


             const fetchStaffList=()=>{
       
              var sql = "SELECT attendance_date as label, sum(case when attendance = 'P'   then 1 else 0 end) AS 'present', sum(case when attendance = 'A'   then 1 else 0 end) AS 'absent', sum(case when attendance = 'H'   then 1 else 0 end) AS 'halfday' from tbl_employee_attendance where attendance_date >=  '"+convertDate(firstDayOfMonth)+"' and attendance_date  <= '"+convertDate(lastDayOfMonth)+"' group by attendance_date  order by label  "
              
              const fd = new FormData();
              fd.append('sql', sql); 
              let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
          axios.post(url, fd, config).then((result:any)=>{
              if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
                setStaffList(result.data.data)

                }else{
                  setStaffList([])
                }
              })
                   }

                   const staffListStark = {
                    labels: staffList.map((data:any)=>data.label),
                    datasets: [
                      {
                        label: '# of Present',
                        data: staffList.map((data:any)=>data.present),
                        fill: false,
                    backgroundColor: 'rgb(12, 206, 138)',
                    borderColor: 'rgb( 11, 218, 147)',
                    yAxisID: 'y-axis-2',
                      },
                      
                      {
                        label: '# of Absent',
                        data: staffList.map((data:any)=>data.absent),
                        fill: false,
                        backgroundColor: 'rgb(255, 99, 132)',
                        borderColor: 'rgb( 173, 24, 39)',
                        yAxisID: 'y-axis-2',
                      },
                      {
                        label: '# of Half Day',
                        data: staffList.map((data:any)=>data.halfday),
                        fill: false,
                        backgroundColor: 'rgb(1, 190, 193)',
                        borderColor: 'rgb(0, 191, 195)',
                        yAxisID: 'y-axis-2',
                      }
                    ],
                  };



             const fetchYearly=()=>{
       
              var sql = "SELECT DATE_FORMAT(attendance_date, '%Y-%m') as label, sum(case when attendance = 'P'   then 1 else 0 end) AS 'present', sum(case when attendance = 'A'   then 1 else 0 end) AS 'absent', sum(case when attendance = 'H'   then 1 else 0 end) AS 'halfday' from tbl_employee_attendance where attendance_date >=  '"+convertDate(firstDayOfYear)+"' and attendance_date  <= '"+convertDate(lastDayOfYear)+"' group by DATE_FORMAT(attendance_date, '%Y-%m')  "
              
              console.log(sql)
              const fd = new FormData();
              fd.append('sql', sql); 
              let url = ServerUrl+'/api/employees/query/'+COMPANY_CODE
          axios.post(url, fd, config).then((result:any)=>{
              if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
                setYearList(result.data.data)
                }
              })
                   }


                   const yearListStark = {
                    labels: yearList.map((data:any)=>getMonth(data.label)),
                    datasets: [
                      {
                        label: '# of Present',
                        data: yearList.map((data:any)=>data.present),
                        backgroundColor: 'rgb(12, 178, 12)',
                      },
                      
                      {
                        label: '# of Absent',
                        data: yearList.map((data:any)=>data.absent),
                        backgroundColor: 'rgb(255, 99, 132)',
                        
                      },
                      {
                        label: '# of Halfday',
                        data: yearList.map((data:any)=>data.halfday),
                        backgroundColor: 'rgb(1, 190, 193)',
                      }
                    ],
                  };




      const departmentTypeList = {
        labels: departmentType.map((data:any)=>data.label),
        datasets: [
          {
            label: '# of Present',
            data: departmentType.map((data:any)=>data.present),
            backgroundColor: 'rgb(12, 178, 12)',
          },
          
          {
            label: '# of Absent',
            data: departmentType.map((data:any)=>data.absent),
           
            backgroundColor: 'rgb(255, 99, 132)',
          },
          {
            label: '# of Half Day',
            data: departmentType.map((data:any)=>data.halfday),
            backgroundColor: 'rgb(1, 190, 193)',
          }
        ],
      }


     


      const optionStark = {
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      };

     


           

      useEffect(()=>{
        fetchEvent()
        fetchDepartmentType()
        fetchHoliday()
        fetchStaffList()
        fetchStatistics()
        fetchYearly()
    }, [])

  return ( <>
   <Breadcrumb 
   title="Attendance Dashboard"
   summary="Statistical display of leave event"
   >
    <li className="breadcrumb-item">
    <Link to="/attendance/display_attendance">Attendance</Link>
                    </li>
         <li className="breadcrumb-item"><a href="#!">Dashboard</a> </li>
   </Breadcrumb>
      <div className="pcoded-inner-content">
                                <div className="main-body">
                                    <div className="page-wrapper">
                                        <div className="page-body">




                                        <div className="row">


                                    



<div className="col-md-3">
    <div className="card">
        <div className="card-block">
            <div className="row align-items-center m-l-0">
                <div className="col-auto">
                    <i className="fa fa-users f-30 text-c-purple"></i>
                </div>
                <div className="col-auto">
                    <h6 className="text-muted m-b-10">Employee</h6>
                    <h2 className="m-b-0">{statistics.staff}</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<div className="col-md-3">
    <div className="card">
        <div className="card-block">
            <div className="row align-items-center m-l-0">
                <div className="col-auto">
                    <i className="fa fa-rocket f-30 text-c-green"></i>
                </div>
                <div className="col-auto">
                    <h6 className="text-muted m-b-10">Today's Present</h6>
                    <h2 className="m-b-0">{statistics.present}</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<div className="col-md-3">
    <div className="card">
        <div className="card-block">
            <div className="row align-items-center m-l-0">
                <div className="col-auto">
                    <i className="fa fa-user f-30 text-c-red"></i>
                </div>
                <div className="col-auto">
                    <h6 className="text-muted m-b-10">On Leave</h6>
                    <h2 className="m-b-0">{statistics.onleave}</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<div className="col-md-3">
    <div className="card">
        <div className="card-block">
            <div className="row align-items-center m-l-0">
                <div className="col-auto">
                    <i className="fa fa-cogs f-30 text-c-blue"></i>
                </div>
                <div className="col-auto">
                    <h6 className="text-muted m-b-10">Todays' Absent</h6>
                    <h2 className="m-b-0">{statistics.absent}</h2>
                </div>
            </div>
        </div>
    </div>
</div>
                                
</div>

<div className="row ">


<div className="col-md-12 ">
                <div className="card ">
                    <div className="card-header">
                        <h5>Employee Attendance ({getMonthYear(month)})</h5>
                    </div>
                    <div className="card-block">

                   
<Line
          data={staffListStark}
        /> 

                    </div>
                </div>
            </div>



</div>

<div className="row">



       


    <div className="col-md-4 ">
<div className="card feed-card">
<div className="card-header">
    <h5>To Do List</h5>
</div>
<div className="card-block">

 {statistics.yettosign>=1? <div className="row m-b-30">
        <div className="col-auto p-r-0">
            <i className="fa fa-one bg-c-blue feed-icon">{statistics.yettosign}</i>
        </div>
        <div className="col">
            <h6 className="m-b-5"> <Link to="/attendance/mark_employee_attendance"> Employees yet to clock in </Link></h6>
        </div>
    </div>:[]} 


   {statistics.absent_staff>=1? <div className="row m-b-30">
        <div className="col-auto p-r-0">
            <i className="fa fa-two bg-c-red feed-icon">{statistics.absent_staff}</i>
        </div>
        <div className="col">
        <h6 className="m-b-5"> <a href="/attendance/mark_employee_attendance"> Employee is absent today </a></h6>
        </div>
    </div>:[]}

    {event>=1?  <div className="row m-b-30">
        <div className="col-auto p-r-0">
            <i className="fa fa-one bg-c-green feed-icon">{event}</i>
        </div>
        <div className="col">
           
        <h6 className="m-b-5"> <a href="/leave/event_calendar"> Days events upcoming</a></h6>
        </div>
    </div>:[]}

   
</div>
</div>
</div>


<div className="col-md-8 ">
<div className="card feed-card">
<div className="card-header">
    <h5>Attendance By Department ({getBritishDate(today)})</h5>
</div>
<div className="card-block">

   
<Bar
          data={departmentTypeList}
          options={optionStark}
        />  


</div>
</div>
</div>

</div>




<div className="row">
<div className="col-md-12 ">
<div className="card ">
<div className="card-header">
    <h5>Employee Monthly Attendance</h5>
</div>
<div className="card-block">

   
<Bar
          data={yearListStark}

        /> 


</div>
</div>
</div>


         </div>


                                        </div>
                                    </div>

                         
                                </div>
                            </div>  
                            </>               
 
  )
}

export default AttendanceDashboard